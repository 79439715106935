import React from 'react'
import Banner from './component/Banner'
import FaqSection from './component/FaqSection'
import NewsLetter from './component/NewsLetter'

const Faq = () => {
  return (
    <>
          <Banner />
          <FaqSection />
          <NewsLetter/>
      </>
  )
}

export default Faq