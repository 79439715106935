import React from 'react'

const BlueBadge = ({badgeInfo}) => {
  return (
    <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
    <i className='fa fa-check-circle'></i>
        <p className='font-semibold'>{badgeInfo}</p>
    </div>
  )
}

export default BlueBadge