import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Modal from "react-modal";
import FormSpinner from '../../../components/Spinners/FormSpinner';
import { fetchTripsList, deleteTripsById, getTripList, getTripdataById } from '../../discover/modules/DiscoverModule';
import language from '../../../helpers/language';
import CustomUseEffect from '../../../components/constants';

export default function TripView({ isUniversalSearchTrue, visitorId }) {
    const dispatch = useDispatch();
    const params = useParams()
    const [deleteModel, setDeleteModel] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isTrip, setIsTrip] = useState(true);
    const [tripId, setTripId] = useState('');
    const [lang] = useState(language.getLang());

    CustomUseEffect(async () => {
        window.scrollTo(0, 0)
        await setIsTrip(isUniversalSearchTrue ? isUniversalSearchTrue?.hide : isTrip);
        if (window.location.pathname === "/profile" || window.location.pathname === `${"/visitor-profile/"}${params?.id}`) {
            if (visitorId) {
                await dispatch(fetchTripsList(visitorId));
            } else {
                await dispatch(fetchTripsList())
            }
        }
        await dispatch(getTripdataById([]));
    }, []);

    const { tripListData, awardSpinner } = useSelector((state) => state.discoverReducerState);

    const deleteTripitem = async (id) => {
        await setIsDelete(true);
        const response = await dispatch(deleteTripsById(id));
        if (response.success === 200) {
            let tripsdata = tripListData?.filter((item) => item.trip_id !== id);
            await dispatch(getTripList(tripsdata));
            await setDeleteModel(false);
            await setIsDelete(false);
        } else {
            await setDeleteModel(false);
        }
    }
    return (
        <Fragment>
            <div className="discover_page ">
                {/* <div className={isTrip ? "blogView gridColOne" : 'blogView'}> */}
                <div className={isTrip ? "blogView gridColOne" : 'blogView'}>
                    {awardSpinner ? <FormSpinner /> : tripListData?.length > 0 ? tripListData?.map((item, index) => {
                        return (
                            <div className='imgBlog' key={index}>
                                <div className='imgBlock'>
                                    <img src={`${item.attachment?.base_url}${item.attachment?.attachment_url}`} alt='Blog Profile' />
                                </div>
                                {visitorId !== undefined || isTrip && <div className="flex justify-between items-center editOption mt-2">
                                    <Link to={`/edit-trips/${item.trip_id}`}><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                                    <Link to="#" ><div onClick={() => { setDeleteModel(true); setTripId(item?.trip_id); }}><i className="fa fa-trash" aria-hidden="true"></i></div></Link>
                                </div>}
                                <div className='blogProfile'>
                                    <div className="feed-user-info">
                                        <div className="user__pic">
                                            <img src={`${item?.user?.avatar_id?.base_url}${item?.user?.avatar_id?.attachment_url}`} alt='Blog Profile' />
                                        </div>
                                        <div className="user__detail-data">
                                            <span className="user__name">
                                                <Link to="#">{lang?.Trip_Name} : {item?.trip_name}</Link>
                                            </span>
                                            <p className="Host intensity flex items-center">
                                                <span>{lang?.intensity}: </span>
                                                {[...Array(5)].map((v, i) => {
                                                    return (
                                                        <div className="ml-2 intensityIcon">
                                                            <span><i className={item?.intensity?.intensity_id > i ? "fa fa-circle" : "fa fa-circle gray"} aria-hidden="true"></i></span>
                                                        </div>
                                                    )
                                                })}
                                            </p>

                                        </div>
                                    </div>
                                    <div className='flex flex-wrap justify-between mt-2'>
                                        <div className="flex flex-wrap">
                                            <span>{lang?.Duration}:&nbsp;</span>
                                            <span className='intensity font-bold'>{item?.duration}
                                            </span>
                                        </div>
                                        <div className="flex flex-wrap">
                                            <span>{lang?.Trip_Price}:&nbsp;</span>
                                            <span className='intensity font-bold'>{item?.currency?.toString() === "USD" ? `$${item.price}` : `€${item.price}`}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap justify-between mt-2'>
                                        <div className="flex travel-agency">
                                            <span>{lang?.Travel_Agency}:&nbsp;</span>
                                            <span className='intensity font-bold'>{item?.travel_agency}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap justify-between mt-2'>
                                        <div className="flex flex-wrap travel-agency-region">
                                            <span>{lang?.RegionTo} :&nbsp;</span>
                                            <span className='intensity font-bold'>{item?.region?.map(({name},i)=>item?.region?.length >i+1 ?`${name},  ` :name)}
                                            </span>
                                        </div>
                                    </div>
                                    <p className='readMore mt-4'>
                                        <Link to={{ pathname: `/view-trips/${item.trip_id}/${item.user_id}`, state: (isTrip ? 1 : 3) }}>More info</Link>
                                    </p>
                                </div>
                            </div>
                        )
                    }) :
                        <div className="card card--block">
                            <div className="post--form emptyData">
                                <div className="empty_notification">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="68.197" height="55.959" viewBox="0 0 68.197 55.959">
                                        <path id="icons8_alps" d="M27.1,2.039A3.032,3.032,0,0,0,24.51,3.413l-.006.01L17.52,16.067l-.2-.284a2.565,2.565,0,0,0-2.189-1.075,2.588,2.588,0,0,0-2.293,1.115l-.081.129L5.8,38.983C4.357,40.193,3.135,41.3,2.486,41.81L2,42.193V58H70.2V41.158l-.539-.383a28.724,28.724,0,0,0-3.191-1.9L58.684,23.567l-.023-.035a2.838,2.838,0,0,0-4.534,0L47.264,11.066l-.006-.01a3.016,3.016,0,0,0-2.594-1.368,3.068,3.068,0,0,0-2.67,1.259l-.006,0-1.2,1.906L38.9,9.519l-.046-.065a2.807,2.807,0,0,0-2.241-1,3.3,3.3,0,0,0-2.021.776l-.185.159-.463.831L29.8,3.313l-.017-.025A3.108,3.108,0,0,0,27.1,2.039Zm.081,2.448a.139.139,0,0,0,.023.07l6.839,11.421,2.566-4.658L53.819,41.67a28.363,28.363,0,0,1-6.44-3.986c-1.1-.965-4.7-5.285-9.851-5.121-2.82.09-5.756,1.483-8.542,2.747-1.39.632-2.716,1.249-3.839,1.687a9.168,9.168,0,0,1-2.392.662c-.822,0-1.564-.572-2.647-1.528a7.02,7.02,0,0,0-4.766-2.294,10.066,10.066,0,0,0-5.56,2.075l5.484-18.134,2.577,3.643L27.168,4.547C27.192,4.5,27.192,4.488,27.186,4.488Zm17.408,7.639c-.006,0-.017.02.006.06l9.451,17.158,2.311-3.981,6.278,12.351a7.985,7.985,0,0,0-.921-.055,22.146,22.146,0,0,0-6.179,1.234L42.376,15.669l2.206-3.483C44.606,12.151,44.6,12.126,44.594,12.126ZM37.633,35.112c3.017-.095,6.069,2.986,7.627,4.354v0c5.189,4.553,14.455,8.057,21.972,8.48v7.5H4.965V43.293c1.234-1.015,2.884-2.553,4.83-4.041,2.143-1.642,4.558-2.866,5.548-2.866.822,0,1.564.572,2.647,1.528a7.02,7.02,0,0,0,4.766,2.294,9.9,9.9,0,0,0,3.619-.891c1.268-.493,2.623-1.125,4-1.752C33.128,36.316,36.052,35.162,37.633,35.112Zm24.085,5.1a5.659,5.659,0,0,1,1.332.154l.017,0h.023a7.032,7.032,0,0,1,1.616.567,17.593,17.593,0,0,1,2.525,1.483V45.5a37.335,37.335,0,0,1-9.121-2.075L56.854,41.2A20.718,20.718,0,0,1,61.719,40.207Z" transform="translate(-2 -2.039)" />
                                    </svg>
                                    <h3 className="font-20 font-bold mt-5 mb-3">{lang?.Trips}</h3>
                                    <h4 className="font-18 text-normal text-black">{lang?.There_is_no_trips_at_this_moment}</h4>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {/* Delete Popup */}
            {deleteModel && (
                <div>
                    <Modal
                        ariaHideApp={false}
                        isOpen={deleteModel}
                        style={{
                            overlay: {
                                position: "fixed",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: "rgba(255, 255, 255, 0.75)",
                            },
                            content: {
                                position: "absolute", top: "0", left: "0%", right: "0%", bottom: "200px", border: "1px solid #ccc", background: "#fff", overflow: "auto", WebkitOverflowScrolling: "touch", borderRadius: "10px", padding: "0",
                                // outline: 'none',
                            },
                        }}
                    >
                        <div className="popup_header flex justify-between items-center popupheaderBg">
                            <h4 className="page__title">{lang?.Delete}</h4>
                            <button
                                className="close_btn--modal"
                                onClick={() => setDeleteModel(!deleteModel)}
                            >
                                {lang?.close}
                            </button>
                        </div>
                        <div className="card card--block modal_box">
                            <p className="warning">{lang?.are_you_sure}</p>
                            <div className="right__btn text-right">
                                <Link
                                    className="btn btn1"
                                    to="#"
                                    onClick={() => setDeleteModel(false)}
                                >
                                    {lang?.Cancel}
                                </Link>
                                <Link className="btn btn2 ml-3" to="#" onClick={() => deleteTripitem(tripId)}> {isDelete ? <FormSpinner /> : `${lang?.ok}`} </Link>
                            </div>
                        </div>
                        <div></div>
                    </Modal>
                </div>
            )}
        </Fragment>
    );
}
