
import React, { useState } from "react";
import Accordian from "./Accordian";
import AnimateFromBottom from "./AnimateFromBottom";
import language from "../../../helpers/language";
import { Link } from "react-router-dom";
const Faq = () => {
  const [lang] = useState(language.getLang());
  const faqArray = lang.faqItems;
  
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
 
  return (
    <div className="faq-container">
      <div className="md:px-14 px-4 md:py-8 py-5">
        <AnimateFromBottom delay={0.2}>
        <p className='text-lg text-royal mb-2 uppercase font-medium'>
          Faq
                  </p>
      </AnimateFromBottom>
        <AnimateFromBottom delay={0.3}>
        <p className='font-bold text-2xl pb-4'>{lang.faq} 
        </p>
      </AnimateFromBottom>
      
        {faqArray?.map((item, index) => {
        return (
          <AnimateFromBottom delay={0.4}  key={index}>
            <Accordian
             
              faq={item.title}
              answer={item.description}
              index={index}
              isActive={activeIndex === index}
              onClickHandler={handleAccordionClick}
            />
          </AnimateFromBottom>
        );
      })}
     
     <AnimateFromBottom delay={0.5}>
          <div className="flex justify-center mt-3">
          <Link to="/faq" className='border-btn-royal px-3 py-2 font-semibold'>{lang.see_more} 
          </Link>
      </div>
       
      </AnimateFromBottom>
                  

    
    </div>
    </div>
   
  );
};

export default Faq;
