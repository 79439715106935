import React from "react";
import { Helmet } from "react-helmet"
const ItalianFood=()=>{
    return (
<Helmet>
<meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />

{/* <!-- Primary Meta Tags --> */}
<title>Italian Food & Beverage Producers USA | Alysei</title>
<meta name="description" content="Explore top Italian food and beverage producers in the USA with Alysei. Source high-quality, authentic Italian products directly from manufacturers for your business, including artisan foods and wines." />
<meta name="keywords" content="Italian beverage manufacturers USA, Authentic Italian food producers USA, Italian food manufacturers USA, Italian wine producers USA, Italian artisan food producers USA, Wholesale Italian food producers USA, Italian food and beverage suppliers USA, Italian culinary product manufacturers USA, Authentic Italian food sourcing USA" />
<link rel="canonical" href="https://alysei.com/services/italian-food-beverage-producers" />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:title" content="Italian Food & Beverage Producers USA | Alysei" />
<meta property="og:description" content="Explore top Italian food and beverage producers in the USA with Alysei. Source high-quality, authentic Italian products directly from manufacturers for your business, including artisan foods and wines." />
<meta property="og:url" content="https://alysei.com/services/italian-food-beverage-producers" />
<meta property="og:site_name" content="Alysei" />
<meta property="og:image" content="https://alysei.com/dist/images/producer-thumbnail.png" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary" />
<meta property="twitter:url" content="https://alysei.com/services/italian-food-beverage-producers" />
<meta property="twitter:title" content="Italian Food & Beverage Producers USA | Alysei" />
<meta property="twitter:description" content="Explore top Italian food and beverage producers in the USA with Alysei. Source high-quality, authentic Italian products directly from manufacturers for your business, including artisan foods and wines." />
<meta property="twitter:image" content="https://alysei.com/dist/images/producer-thumbnail.png" />

<script type="application/ld+json">
{`{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Italian Food & Beverage Producers USA | Alysei",
  "url": "https://alysei.com/services/italian-food-beverage-producers",
  "description": "Explore top Italian food and beverage producers in the USA with Alysei. Source high-quality, authentic Italian products directly from manufacturers for your business, including artisan foods and wines.",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://alysei.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Italian Food & Beverage Producers",
        "item": "https://alysei.com/services/italian-food-beverage-producers"
      }
    ]
  },
  "about": {
    "@type": "Service",
    "name": "Services for Italian Food & Beverage Producers",
    "serviceType": "Marketing and Distribution Solutions",
    "provider": {
      "@type": "Organization",
      "name": "Alysei",
      "url": "https://alysei.com"
    },
    "areaServed": {
      "@type": "Country",
      "name": "US"
    },
    "audience": {
      "@type": "Audience",
      "audienceType": "Italian Food & Beverage Producers, Exporters"
    }
  },
  "publisher": {
    "@type": "Organization",
    "name": "Alysei",
    "url": "https://alysei.com",
    "logo": "https://alysei.com/dist/images/logo.svg",
    "sameAs": [
      "https://www.facebook.com/AlyseiB2B",
      "https://www.instagram.com/alyseiplatform/",
      "https://www.linkedin.com/company/alysei",
      "https://www.youtube.com/@alysei/",
      "https://apps.apple.com/in/app/alysei/id1634783265",
      "https://play.google.com/store/apps/details?id=com.alysei"
    ]
  }
}`}
</script>

</Helmet>

    )
}

export default ItalianFood;