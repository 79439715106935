import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormSpinner from '../Spinners/FormSpinner';
import constant from '../../helpers/constant';
import {
  getConnectionData,
  getHubMemberConnectionData,
  getImpAndDistHubs,
  getProducer,
  getRestaurentUsers,
  getTravelAgenciesUsers,
  getUsersData,
  getVoiceOfExportUsers,
} from '../../routes/B2B/modules/B2B';
import {
  acceptRejectRequest,
  acceptRejectRequestWithVisitorId,
  getConnectionTabsData,
  sendConnectionRequest,
} from '../../routes/NetworkConnection/modules/networkConnection';
import auth from '../../helpers/auth';
import { visitorProfileData } from '../../routes/profile/modules/profile';
import language from '../../helpers/language';
import CustomUseEffect from '../constants';

export default function ConnectionModal({ openModelData }) {
  const [lang] = useState(language.getLang());
  const [connectPopup, setConnectPopup] = useState(false);
  const [removeConnection, setRemoveConnection] = useState(false);
  const [connectText, setConnectText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [userType, setuserType] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  CustomUseEffect(async () => {
    const userType = await auth.getUserType();
    await setuserType(JSON.parse(userType));
    await setConnectPopup(openModelData.openModel);
    await setRemoveConnection(
      openModelData?.network ? openModelData?.network : false
    );
    return () => {
      setuserType();
      setConnectPopup();
      setRemoveConnection();
    };
  }, [openModelData.openModel]);

  const { sendRequestSpinner } = useSelector(
    (state) => state.networkConnectionState
  );

  const handleModelOfConnection = async () => {
    await setConnectPopup(false);
    (await openModelData) && openModelData?.handleOpenModel(false);
  };
  const sendRequest = async () => {
    console.log("hello",openModelData?.user_id)
    const sendRequestData = {
      user_id: parseInt(openModelData?.user_id),
      reason_to_connect: connectText,
    };
    if (constant.rolesId['importer&distributer'] === openModelData.type) {
      sendRequestData.product_ids = JSON.parse(
        localStorage.getItem('productTypeArr')
      );
    }
    const response = await dispatch(sendConnectionRequest(sendRequestData));
    if (response?.success === 200) {
      await setConnectPopup(false);
      // for producer
      if (openModelData.type === constant.rolesId.producer) {
        const newdata = openModelData.userData?.data?.data?.map((item) =>
          item.user_id === openModelData?.user_id
            ? { ...item, connection_flag: 2 }
            : item
        );
        const finalValue = {
          ...openModelData.userData,
          data: { ...openModelData.userData.data, data: newdata },
        };
        await dispatch(getProducer(finalValue));
        await dispatch(getUsersData(finalValue));
        userType?.role_id === constant.rolesId.producer &&
          (await dispatch(getConnectionData(finalValue)));
        userType?.role_id === constant.rolesId.producer &&
          openModelData?.from === 'hubsMember' &&
          (await dispatch(getConnectionData(finalValue)));
        await dispatch(getHubMemberConnectionData(finalValue));
      }
      //for restaurants
      if (openModelData.type === constant.rolesId.restaurant) {
        const newdata = openModelData.userData?.data?.data?.map((item) =>
          item.user_id === openModelData?.user_id
            ? { ...item, connection_flag: 2 }
            : item
        );
        const finalValue = {
          ...openModelData.userData,
          data: { ...openModelData.userData?.data, data: newdata },
        };
        await dispatch(getRestaurentUsers(finalValue));
        await dispatch(getUsersData(finalValue));
        userType?.role_id === constant.rolesId.restaurant &&
          (await dispatch(getConnectionData(finalValue)));
        userType?.role_id === constant.rolesId.restaurant &&
          openModelData?.from === 'hubsMember' &&
          (await dispatch(getConnectionData(finalValue)));
        await dispatch(getHubMemberConnectionData(finalValue));
      }
      // for voiceOfExpert
      if (openModelData.type === constant.rolesId.voiceOfExpert) {
        const newdata = openModelData.userData?.data?.data?.map((item) =>
          item.user_id === openModelData?.user_id
            ? { ...item, connection_flag: 2 }
            : item
        );
        const finalValue = {
          ...openModelData.userData,
          data: { ...openModelData.userData?.data, data: newdata },
        };
        await dispatch(getVoiceOfExportUsers(finalValue));
        await dispatch(getUsersData(finalValue));
        userType?.role_id === constant.rolesId.voiceOfExpert &&
          (await dispatch(getConnectionData(finalValue)));
        userType?.role_id === constant.rolesId.voiceOfExpert &&
          openModelData?.from === 'hubsMember' &&
          (await dispatch(getConnectionData(finalValue)));
        await dispatch(getHubMemberConnectionData(finalValue));
      }
      // for travelAgencies
      if (openModelData.type === constant.rolesId.travelAgencies) {
        const newdata = openModelData.userData?.data?.data?.map((item) =>
          item.user_id === openModelData?.user_id
            ? { ...item, connection_flag: 2 }
            : item
        );
        const finalValue = {
          ...openModelData.userData,
          data: { ...openModelData.userData?.data, data: newdata },
        };
        await dispatch(getTravelAgenciesUsers(finalValue));
        await dispatch(getUsersData(finalValue));
        userType?.role_id === constant.rolesId.travelAgencies &&
          (await dispatch(getConnectionData(finalValue)));
        userType?.role_id === constant.rolesId.travelAgencies &&
          openModelData?.from === 'hubsMember' &&
          (await dispatch(getConnectionData(finalValue)));
        await dispatch(getHubMemberConnectionData(finalValue));
      }
      // importer&distributer
      if (constant.rolesId['importer&distributer'] === openModelData.type) {
        const newdata = openModelData.userData?.data?.data?.map((item) =>
          item.user_id === openModelData?.user_id
            ? { ...item, connection_flag: 2 }
            : item
        );
        const finalValue = {
          ...openModelData.userData,
          data: { ...openModelData.userData?.data, data: newdata },
        };
        await dispatch(getImpAndDistHubs(finalValue));
        await dispatch(getUsersData(finalValue));
        userType?.role_id === constant.rolesId['importer&distributer'] &&
          (await dispatch(getConnectionData(finalValue)));
        userType?.role_id === constant.rolesId['importer&distributer'] &&
          openModelData?.from === 'hubsMember' &&
          (await dispatch(getConnectionData(finalValue)));
        await dispatch(getHubMemberConnectionData(finalValue));
        // history.goBack();
        history.push('/b2b');
      }

      (await openModelData) && openModelData?.handleOpenModel(false);
    } else if (response?.success === 422) {
      setErrorText(response?.errors);
    }
  };

  const removeUserConnection = async () => {
    if (connectText !== '') {
      const response = await dispatch(
        acceptRejectRequestWithVisitorId({
          connection_id: openModelData?.visitor_id,
          accept_or_reject: 2,
          reason: connectText,
        })
      );
      if (response?.success === 200) {
        openModelData?.visitor &&
          (await dispatch(visitorProfileData(openModelData.user_id)));
        openModelData?.view && history.push('/networking');
        await openModelData?.handleOpenModel(false, response);
      } else {
        setErrorText(response?.errors?.exception);
      }
    } else {
      setErrorText(lang?.Enter_your_reason);
    }
    setTimeout(() => {
      setErrorText('');
    }, 3000);
  };
  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={connectPopup}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
          content: {
            position: 'absolute',
            top: '0',
            left: '0%',
            right: '0%',
            bottom: '200px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            padding: '0',
            // outline: 'none',
          },
        }}
      >
        <div className="popup_header flex justify-between items-center">
          <h4 className="page__title">
            {' '}
            {removeConnection
              ? openModelData?.from
              : `${lang?.Connect_Request}`}{' '}
          </h4>
          <button
            className="close_btn--modal"
            onClick={() => handleModelOfConnection()}
          >
            {lang?.close}
          </button>
        </div>
        <div className="card card--block modal_box modalLessGap">
          {/* <h4 className='font-16 mb-2'> {lang?.Sending_a_request_to} {removeConnection ? openModelData?.from : `${lang?.connect_with}`}  <strong>@{openModelData.name}
          </strong></h4> */}
          <p style={{ color: 'red' }}>{errorText}</p>
          <p className="font-16 mb-2">
            {lang?.Reason_to}{' '}
            {removeConnection ? openModelData?.from : `${lang?.Connect}`}
          </p>
          <form>
            <textarea
              className="write_post-area w-full"
              placeholder={lang?.Enter_your_text}
              rows="2"
              value={connectText}
              onChange={(e) => setConnectText(e.target.value)}
            />
          </form>
          <div className="view__hub__btn">
            <button
              type="button"
              className="btn btnTeal"
              onClick={() =>
                removeConnection ? removeUserConnection() : sendRequest()
              }
            >
              {sendRequestSpinner ? (
                <FormSpinner />
              ) : removeConnection ? (
                `${lang?.Send_Reason}`
              ) : (
                `${lang?.Send_Request}`
              )}
            </button>
          </div>
        </div>
        <div></div>
      </Modal>
    </div>
  );
}
