import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { fetchRestarantType, fetchItalianRestHubs, fetchAllHubs } from "../modules/B2B";
import constant from "../../../helpers/constant"
import MultiSelecter from "../../../components/MutiSelect/MultiSelect";
import language from "../../../helpers/language";
import CustomUseEffect from "../../../components/constants";

export default function RestaurantSearchBar(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [hubsId, setHubsId] = useState([]);
    const [roleSearchType, setroleSearchType] = useState(2);

    const [restaurantType, setRestaurantType] = useState("");
    const [pickup, setPickup] = useState("");
    const [delivery, setDelivery] = useState("");
    const [lang] = useState(language.getLang());
    const { getAllHubs, getAllRestaurantType, } = useSelector((state) => state.b2bState);

    CustomUseEffect(async () => {
        // await getRestaurantSearch();
        await dispatch(fetchRestarantType(10));
        // await dispatch(fetchAllHubs());
        document.getElementById('restaurant_hubs_input').placeholder = lang?.select;
        document.getElementById('restaurant_types_input').placeholder = lang?.select;
    }, []);

    CustomUseEffect(async() => {
        await setHubsId(props?.hubsId != undefined ? props?.hubsId : '');
    },[]);

    const getRestaurantSearch = async () => {
        const model = {
            searchType: roleSearchType,
            roleId: constant.rolesId.restaurant,
            hubsId: hubsId,
            restaurantType: restaurantType,
            pickup: pickup,
            delivery: delivery,
            page: 1,
        };
        props.getStatesOfSearchBar(model);
        await dispatch(fetchItalianRestHubs(model));
        console.log(model,"modelmodel");
    };

    const handRestaurantSearch = async (event) => {
        event.preventDefault();
        await getRestaurantSearch();
    };

    const handleMultiSelect = async (id, selectedList) => {
        const arr = [];
        await selectedList && selectedList.map((item) => arr.push(item.id));
        setHubsId(arr)
    };

    const handleMultiSelectRestaurentType = async (id, selectedList) => {
        const arr = [];
        await selectedList && selectedList.map((item) => arr.push(item.user_field_option_id));
        setRestaurantType(arr)
    };

    return (
        <div className="left__section-block sm:mb-2 md:mb-2">
            <div className="card card--block ">
                <div className="hubsTitle">
                    <h4 className="font-18">{lang?.Italian_Restaurants_in_USB2B}</h4>
                </div>
                <div className="card-form p-3">
                    <form onSubmit={(event) => handRestaurantSearch(event)}>
                    {props.hubShow && 
                        <div className="form__item">
                            <MultiSelecter
                                displayValue={"title"}
                                Options={getAllHubs}
                                label={"Hubs"}
                                placeholder={lang?.select}
                                id="restaurant_hubs"
                                required={"no"}
                                fireData={(id, selectedList) => handleMultiSelect(id, selectedList)}
                            />
                        </div>
                    }
                        <div className="form__item">
                            <MultiSelecter
                                displayValue={"option"}
                                placeholder={lang?.select}
                                id="restaurant_types"
                                Options={getAllRestaurantType && getAllRestaurantType.options}
                                label={lang?.Restaurant_SearchBar_Type}
                                required={"no"}
                                fireData={(id, selectedList) => handleMultiSelectRestaurentType(id, selectedList)}
                            />
                        </div>


                        <div className="form__item">
                            <label>{lang?.Provide_pick_up_and_or_Delivery}</label>
                            <div className="form-checkbox-container">
                                <div className="form-checkbox-container-items">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="pic"
                                            onChange={(event) => {
                                                event.target.checked
                                                    ? setPickup(628)
                                                    : setPickup("");
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor="pic">
                                            {lang?.Pick_up}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="del"
                                            onChange={(event) => {
                                                event.target.checked
                                                    ? setDelivery(629)
                                                    : setDelivery("");
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor="del">
                                            {lang?.Delivery}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fomr-actions">
                            <button className="btn btnBgPrimary" type="submit">
                                {lang?.Search}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
