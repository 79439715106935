import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import FormSpinner from "../Spinners/FormSpinner";
import { fetchMemberProgress } from "../../routes/membership/modules/membership";
import language from "../../helpers/language";

export default function MembershipBar() {
    const dispatch = useDispatch();
    useEffect(()=>{
        const freeCall = async () => { await dispatch(fetchMemberProgress()) }
        freeCall()
    }, []);
    const [lang] = useState(language.getLang());
    const { getMemberProgressData, memberProgressSpinner } = useSelector((state) => state.memberProgressState);

    return (
        <div className="card card--block setting_member_w">
            <h5 className="card-title flex items-center">{lang?.Your_Progress}</h5>
            {memberProgressSpinner ? <FormSpinner /> :
                <div className="card-body mt-4 mb-4">
                    {getMemberProgressData?.alysei_progress?.map((item, index) => {
                        return (
                            <div className="AppWidgetProgressItem" key={index}>
                                <div className="AppWidgetProgressIcon">
                                    <span className="fa-icon" data-icon-id="far fa-circle"><i className={item.status ? "fa fa-check-circle" : "far fa-circle"}></i></span>
                                </div>
                                <div className="title flex items-center">{item.title}
                                    {/* <div className="ml-auto">
                                        <span className="fa-icon" data-icon-id="far fa-question-circle"> <i className="far fa-question-circle"></i></span>
                                    </div> */}
                                </div>
                                <div className="Description"><p>{item.description}</p></div></div>
                        );
                    })}
                </div>
            }
        </div>
    );
}
