import React, { useState, useEffect, useRef } from 'react';
import '../../../assets/marketplacecss/mrktplace-style.css';
import default_Image from '../../../assets/images/default_image.jpg';
import imgs from '../../../assets/images/download.svg';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import constant from '../../../helpers/constant';
import auth from '../../../helpers/auth';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import Modal from 'react-modal';
import ImporterInquiryForm from './ImporterInquiryForm';
import language from '../../../helpers/language';
import CustomUseEffect from '../../../components/constants';

export function ImporterInquiry() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [lang] = useState(language.getLang());

  CustomUseEffect(async () => {
    const user = await auth.getUserType(); 
    const myRole = await (JSON.parse(user).role_id);
    const invisibleInquiry = [10,3,8];
    let obj = invisibleInquiry.find(role => role === myRole);
    console.log(obj,"myRole");
    if(obj){
      history.push('/marketplace/error');
    }
    // (await (JSON.parse(user).role_id !== constant.rolesId.importer)) &&
    //   JSON.parse(user).role_id !== constant.rolesId.distributer &&
    //   JSON.parse(user).role_id !== constant.rolesId['importer&distributer'] &&
    //   //history.push('/marketplace/error');
    //   await console.log(constant.rolesId.importer,"inquiry role");
  }, []);

  return (
    <div className="marketplace dashboard importer">
      {/* Banner Section */}
      <div>
        <div className="section__wrapper">
          <div className="flex justify-between items-center mb-4">
            <h6 className="back-btn-small">
              <Link to="/marketplace">
                <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;{' '}
                {lang?.Go_back}
              </Link>
            </h6>
          </div>
          {/* <div className="bg--white page-main"> */}
          <div className="page-main">
            <div className="configure header-align">
              <div className="banner__form mt-5">
                <div className="tabs middle-long__section-block">
                  {/* <div className="tabs__wrapper">
                    <span tab={4}>
                      <span className="iconWidth">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="18"
                          viewBox="0 0 14.332 10.319"
                        >
                          <path
                            id="icons8_secured_letter"
                            d="M0,14V24.319H14.332V14Zm.573.573H13.759v.735c-.128.111-5.212,4.531-5.634,4.9a1.524,1.524,0,0,1-.959.389,1.524,1.524,0,0,1-.959-.389L4.787,18.97l-.006-.007h0L.573,15.308Zm0,1.492,3.75,3.26L.573,22.288Zm13.185,0v6.224L10,19.332Zm-9,3.643,1.069.929a2.074,2.074,0,0,0,1.334.528A2.074,2.074,0,0,0,8.5,20.637l1.06-.922,4.2,3.3v.727H.573v-.727Z"
                            transform="translate(0 -14)"
                            fill="#666"
                          />
                        </svg>
                      </span>
                      <em>{lang?.Inquiries}</em>
                    </span>
                  </div> */}

                  <div className="tab home-section no-padding active-tab">
                    <ImporterInquiryForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
