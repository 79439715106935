import React, { useState, useEffect } from 'react'
import BuyerHeader from './DashboardView/BuyerHeader'
import SideBar from './SideBar'
import InfoiconBlockView from './CreateRequestView/InfoiconBlock/InfoiconBlockView'
import NoData from './CreateRequestView/NoData'
import Modal from 'react-modal';
import language from '../../../helpers/language'
import { getBuyerFilters, getFilterResults, createRequestAPI, checkProfileApprovedStatus } from '../modules/Buyermodule';
import { useSelector, useDispatch } from 'react-redux';
import FormSpinner from '../../../components/Spinners/FormSpinner'; 
import FormErrors from "../../../components/Errors/FormError";
import { useHistory } from "react-router-dom";
import CustomUseEffect from '../../../components/constants'

export default function CreateRquest() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [sendRequest, setSendRequest] = useState(false);
    const [lang] = useState(language.getLang());
    const [productTypeId, setProductTypeId] = useState('');
    const [conservationId, setConservationId] = useState('');
    const [propertyId, setpropertyId] = useState('');
    const [productName, setProductName] = useState('');
    const [fdaOptionId, setFdaOptionId] = useState('');
    const [italianRegionId, setItalianRegionId] = useState('');
    const [shareButtonEnable, setShareButtonEnable] = useState(true);
    const[isReviewed, setIsReviewed] = useState(false);
    const intialValues = {
        title : '',
        description : '',
    }

    const [isRequestValues, setIsRequestValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [disabledTrue, setDisabledTrue] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");

    useEffect(()=>{
        if(Object.keys(formErrors).length === 0 && isSubmitting){
            saveRequestForm();
        }
    },[formErrors])

    CustomUseEffect(async() => {
        let review = localStorage.getItem('alysei_review');
        if(review === '0'){
            const status = await checkProfileApprovedStatus();
            if(status.success){
                if(status?.alysei_review == 0){
                    await setIsReviewed(true);
                }
                else{
                    await setIsReviewed(false);
                }
               await localStorage.setItem('alysei_review', status?.alysei_review);
            }
            
        }
    },[]);

    CustomUseEffect(async() => {
        const filtersValues = {
            product_type_id : productTypeId,
            conservation_id : conservationId,
            property_id : propertyId,
            product_name : productName,
            fda_option_id : fdaOptionId,
            italian_region_id : italianRegionId,
        }
        await getResults(filtersValues);
    },[productTypeId, conservationId, propertyId, productName, fdaOptionId, italianRegionId]);
    
    CustomUseEffect(async() => {
        defaultProductTypes && defaultProductTypes.length === 0 && (dispatch(getBuyerFilters()));
    },[])

    let { defaultProductTypes, defaultItalianRegionOPtions, defaultFdaOptions, createRequestSpinner, conservationLabelName, conservationMethods, propertyLabelName, productProperties, producers } = useSelector((state) => state.buyerModuleFilterData);

    console.log(producers,"producer33   ")
    // Change filter result
    const getProducerResult = async(e) => {
        let {name, value} = e.target;
        if(name === 'product_name') {
            await setProductName(value);
        }
        else if(name === 'product_type_id') {
            await setProductTypeId(value);
            await setConservationId('');
            await setpropertyId('');
        }
        else if(name === 'conservation_id') {
            await setConservationId(value);
        }
        else if(name === 'property_id') {
            await setpropertyId(value);
        }
        else if(name === 'fda_option_id') {
            await setFdaOptionId(value);
        }
        else if(name === 'italian_region_id') {
            await setItalianRegionId(value);
        }
        
    }

    CustomUseEffect(async() => {
        if(productName != '' || productTypeId != '' || conservationId != '' || propertyId != '' || fdaOptionId != '' || italianRegionId != ''){
            await setShareButtonEnable(false);
        }
        else{
            await setShareButtonEnable(true);
        } 
        //console.log({productName:productName,productTypeId:productTypeId,conservationId:conservationId,propertyId:propertyId,fdaOptionId:fdaOptionId,italianRegionId:italianRegionId},"optionsss");
    },[productName,productTypeId,conservationId,propertyId,fdaOptionId,italianRegionId])

    const getResults = async(filtersValues) => {
        await dispatch(getFilterResults(filtersValues));
    }

    // Options set in states
  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsRequestValues({ ...isRequestValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

    // Check request validation
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormErrors(validate(isRequestValues));
    setIsSubmitting(true);
  };

  // Form validation
  const validate = (values) => {
    let errors = {};
    if (!values.title) {
      errors.title = `${lang?.title_required}`;
    }
    if (!values.description) {
      errors.description = `${lang?.message_required}`;
    }
    return errors;
  };

  const saveRequestForm = async () => {
    await setDisabledTrue(true);
    await setIsLoader(true);
    const formValues = {
        product_name : productName,
        product_type_id : productTypeId,
        conservation_id : conservationId,
        property_id : propertyId,
        fda_option_id : fdaOptionId,
        italian_region_id : italianRegionId,
        title : isRequestValues?.title,
        description : isRequestValues?.description,
        producer : producers,
    }

    const response = await createRequestAPI(formValues);
    if(response?.success){
        const resetValues = {
            title : '',
            description : '',
          };
        await setIsRequestValues(resetValues);
        await setSendRequest(false);
        await setResponseMessage(response?.message);
        setTimeout(() => {
              history.push("/recent-request");
            }, 3000);
    }
    else{
        await setResponseMessage(lang?.SomeThingsWrong);
    }
    setTimeout(async() => {
        await setDisabledTrue(false);
        await setIsLoader(false);
        setResponseMessage("");
      }, 2500);
    
  }
    //console.log(conservationMethods,"conservationMethods");
    return (
        <>
            <section className='buyerDashboard forModal'>
                <div className='dashboardLayout'>
                    <div className='headerBlock'>
                        <BuyerHeader
                            pageTitle="Create Request"
                        />
                    </div>
                    <div className='sideBarBlock'>
                        <SideBar />
                    </div>
                    <div className='centerContent'>
                        <div className={`${isReviewed ? "pageContent admin_approval approved" : "pageContent admin_approval"}`}>
                            <div className='card card--block'>
                                <div className='newDashboard'>
                                <div className="message"><h4 className="font-bold text-center text-green">{responseMessage}</h4></div>
                                    <div className='innerTitle'>
                                        <h4>Search for Products</h4>
                                        <p>Search for Products within Alysei world that meet your needs.</p>
                                        {/* <p>Select a product type, conservation method, properties, FDA certification and italian region to discover producers.</p> */}
                                    </div>
                                    <form>
                                        <div className='filterData grid3'>
                                            <div className='form__item whiteBg'>
                                                <label>Product</label>
                                                <input type='text' name='product_name' disabled={isReviewed} placeholder="Product Name" value={productName} onChange={(e) => getProducerResult(e)}/>
                                            </div>

                                            <div className='form__item whiteBg'>
                                                <label>Product Type</label>
                                                <select disabled={isReviewed} name="product_type_id" value={productTypeId} onChange={(e) => getProducerResult(e)}>
                                                <option value="">Select Product Type</option>
                                                    {
                                                    defaultProductTypes?.length > 0 && defaultProductTypes.map(({option, user_field_option_id}) => {
                                                        return (
                                                            <option key={user_field_option_id} value={user_field_option_id}>{option}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                            </div>
                                            
                                            <div className='form__item whiteBg'>
                                                <label>{conservationLabelName}</label>
                                                <select disabled={isReviewed} name="conservation_id" value={conservationId} onChange={(e) => getProducerResult(e)}>
                                                    <option value="">Select {conservationLabelName}</option>
                                                    {
                                                    conservationMethods?.length > 0 && conservationMethods.map(({option, user_field_option_id}) => {
                                                        return (
                                                            <option key={user_field_option_id} value={user_field_option_id}>{option}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                            </div>
                                            <div className='form__item whiteBg'>
                                                <label>{propertyLabelName}</label>
                                                <select disabled={isReviewed} name="property_id" value={propertyId} onChange={(e) => getProducerResult(e)}>
                                                    <option value="">Select {propertyLabelName}</option>
                                                    {
                                                    productProperties?.length > 0 && productProperties.map(({option, user_field_option_id}) => {
                                                        return (
                                                            <option key={user_field_option_id} value={user_field_option_id}>{option}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                            </div>
                                            <div className='form__item whiteBg'>
                                                <label>FDA Certification</label>
                                                <select disabled={isReviewed} name="fda_option_id" value={fdaOptionId} onChange={(e) => getProducerResult(e)}>
                                                    <option value="">Select FDA Certificate </option>
                                                    {
                                                    defaultFdaOptions?.length > 0 && defaultFdaOptions.map(({option, user_field_option_id}) => {
                                                        return (
                                                            <option key={user_field_option_id} value={user_field_option_id}>{option}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                            </div>
                                            <div className='form__item whiteBg'>
                                                <label>Italian Region</label>
                                                <select disabled={isReviewed} name="italian_region_id" value={italianRegionId} onChange={(e) => getProducerResult(e)}>
                                                    <option value="">Select Region </option>
                                                    {
                                                    defaultItalianRegionOPtions?.length > 0 && defaultItalianRegionOPtions.map(({name, id}) => {
                                                        return (
                                                            <option key={id} value={id}>{name}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {/* <NoData /> */}
                                        
                                    </form>
                                    <div className='mt-8 text-center'>
                                        {createRequestSpinner ? < FormSpinner /> : 
                                            producers.length > 0 ? 
                                            <>
                                            <p className='searchResult'>{producers.length > 1 ? `${producers.length} producers` : `${producers.length} producer`}  matched your query</p>
                                            <div className='btnBlock mt-4'>
                                                <button disabled={shareButtonEnable} type='button' className='btn btn-blue style2' onClick={()=> setSendRequest(!sendRequest)}>Send Request</button>
                                            </div>
                                            </>
                                            
                                            :
                                            
                                            <>
                                            <p className='searchResult'>{producers.length} producer matched your query</p>
                                            <div className='btnBlock mt-4'>
                                                <button disabled={shareButtonEnable} type='button' className='btn btn-blue style2' onClick={()=> setSendRequest(!sendRequest)}>{shareButtonEnable ? 'send request' : 'Send Request to Alysei Team'}</button>
                                            </div>
                                            </> 
                                            
                                        }
                                        </div>
                                    <div className='bgGray infoIconData'>
                                        <div className='grid3'>
                                            <InfoiconBlockView 
                                                Icon={<svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                <path d="M29.6875 4.6875C21.0754 4.6875 14.0625 11.7004 14.0625 20.3125C14.0625 24.054 15.3748 27.4841 17.5781 30.1758L5.12695 42.627L7.37305 44.873L19.8242 32.4219C22.5159 34.6252 25.946 35.9375 29.6875 35.9375C38.2996 35.9375 45.3125 28.9246 45.3125 20.3125C45.3125 11.7004 38.2996 4.6875 29.6875 4.6875ZM29.6875 7.8125C36.6089 7.8125 42.1875 13.3911 42.1875 20.3125C42.1875 27.2339 36.6089 32.8125 29.6875 32.8125C22.7661 32.8125 17.1875 27.2339 17.1875 20.3125C17.1875 13.3911 22.7661 7.8125 29.6875 7.8125Z" fill="#37A282"/>
                                              </svg>}
                                              Title="Discover Products"
                                              Description="Find the most authentic Italian Regional Products."
                                            />
                                            <InfoiconBlockView 
                                                Icon={<svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                <path d="M6.25 7.8125V17.1875H15.625V7.8125H6.25ZM9.375 10.9375H12.5V14.0625H9.375V10.9375ZM18.75 10.9375V14.0625H42.1875V10.9375H18.75ZM6.25 20.3125V29.6875H15.625V20.3125H6.25ZM9.375 23.4375H12.5V26.5625H9.375V23.4375ZM18.75 23.4375V26.5625H42.1875V23.4375H18.75ZM6.25 32.8125V42.1875H15.625V32.8125H6.25ZM9.375 35.9375H12.5V39.0625H9.375V35.9375ZM18.75 35.9375V39.0625H42.1875V35.9375H18.75Z" fill="#37A282"/>
                                              </svg>}
                                              Title="Create Requests"
                                              Description="Create, send and manage your requests."
                                            />
                                            <InfoiconBlockView 
                                                Icon={<svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                <path d="M26.5625 7.8125V23.4375H29.6875V27.9297L32.1289 26.3184L36.4258 23.4375H46.875V7.8125H26.5625ZM29.6875 10.9375H43.75V20.3125H35.4492L35.0586 20.5566L32.8125 22.0703V20.3125H29.6875V10.9375ZM15.625 12.5C10.4675 12.5 6.25 16.7175 6.25 21.875C6.25 24.9756 7.77588 27.7344 10.1074 29.4434C5.98755 31.488 3.125 35.7239 3.125 40.625H6.25C6.25 35.4309 10.4309 31.25 15.625 31.25C20.8191 31.25 25 35.4309 25 40.625H28.125C28.125 35.7239 25.2625 31.488 21.1426 29.4434C23.4741 27.7344 25 24.9756 25 21.875C25 16.7175 20.7825 12.5 15.625 12.5ZM15.625 15.625C19.0979 15.625 21.875 18.4021 21.875 21.875C21.875 25.3479 19.0979 28.125 15.625 28.125C12.1521 28.125 9.375 25.3479 9.375 21.875C9.375 18.4021 12.1521 15.625 15.625 15.625Z" fill="#37A282"/>
                                              </svg>}
                                              Title="Order on your Terms"
                                              Description="Zero commission orders negotiated on your own Terms."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isReviewed ?
                                <div class="overlay">
                                    <div className='overlay_content'>
                                    <   div className="width--submitation">
                                            <div className="icon">
                                                <i className="fa fa-smile-o" aria-hidden="true" />
                                            </div>
                                            <h3>Thank you for submitting your information for admin review</h3>
                                            <p className="mt-5 mb-5">
                                                {lang?.will_resp}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                                }
                            
                        </div>
                    </div>
                </div>
                
            </section>

            {/* Send a request modal */}
            {sendRequest &&
                <Modal
                    className=""
                    ariaHideApp={false}
                    isOpen={sendRequest}
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.75)',
                        },
                        content: {
                            position: 'absolute',
                            top: '0',
                            left: '0%',
                            right: '0%',
                            bottom: '200px',
                            border: '1px solid #ccc',
                            background: '#fff',
                            overflow: 'auto',
                            WebkitOverflowScrolling: 'touch',
                            borderRadius: '4px',
                            padding: '0',
                            // outline: 'none',
                        },
                    }}
                >
                    <div className="popup_header flex justify-between items-center">
                        <h4 className="page__title">Send a Request</h4>
                        <button
                            className="close_btn--modal"
                            onClick={() => setSendRequest()}
                        >
                            {lang?.close}
                        </button>
                    </div>
                    <div className='modal-body'>
                        <div className="card card--block modal_box modalLessGap">
                            <form onSubmit={(event) => handleSubmit(event)}>
                                <div className='form__item whiteBg'>
                                    <input type='text' placeholder='Title for Request' name="title" onChange={(event) => handleChange(event)} value={isRequestValues.title} />
                                    {formErrors.title ? <FormErrors msg={formErrors.title} /> : ""}
                                </div>
                                <div className='form__item whiteBg'>
                                    <textarea rows="4" placeholder='Enter Description for the request...' name="description" onChange={(event) => handleChange(event)} value={isRequestValues.description}/>
                                    {formErrors.description ? <FormErrors msg={formErrors.description} /> : ""}
                                </div>
                                <button type="submit" disabled={disabledTrue} className='btn btn-blue style2 w-full'>{isLoader ? <FormSpinner /> : 'Submit Request' }</button>
                            </form>
                        </div>
                    </div>
                </Modal>
            }

                
        </>
    )
}
