import React, { Fragment, useEffect, useState, useRef } from "react";

import Modal from "react-modal";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import auth from "../../../helpers/auth";

// import { createBrowserHistory } from "history"
// let history = createBrowserHistory()

import form from "../../../helpers/form";
import Lang from "../../../helpers/language";
import Terms from "../../../components/Terms/Terms";
import Input from "../../../components/Input/Input";
import Map from "../../../components/Map/Map_google";
import Select from "../../../components/Select/Select";
import Checkbox from "../../../components/Checkbox/Checkbox";
import FormError from "../../../components/Errors/FormError";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import MultiSelecter from "../../../components/MutiSelect/MultiSelect";
import PasswordIndicator from "../../../components/PasswordIndicator/PasswordIndicator";
import { fetchFormFields, setFieldValues, setFormErrors, getCountries, getRegion, getCity, register, voyagerRregister, setResponseErrors, verifyUserEmaiId, } from "../modules/register";
import language from "../../../helpers/language";
import constant from "../../../helpers/constant";
import OtpInput from "react-otp-input";
import $ from 'jquery';
import CustomUseEffect from "../../../components/constants";


import TravelAgencies from "../../../components/Seo-helmet/register/Travel-Agencies";
import VoiceExperts from "../../../components/Seo-helmet/register/Voice-Experts";
import ItalianRestaurant from "../../../components/Seo-helmet/register/Italian-Restaurant";
import ItalianFood from "../../../components/Seo-helmet/register/Italian-Food";
import ImporterDistributers from "../../../components/Seo-helmet/register/Importers-Distributors";
import Buyers from "../../../components/Seo-helmet/register/Buyers";
import Voyagers from "../../../components/Seo-helmet/register/Voyagers";
// import Map from "../../../components/Map/Map";



let roleObj = {
  'italian-food-beverage-producers': 3,
  'importers-distributors': 6,
  'buyers': 11,
  'italian-restaurants': 9,
  'voice-of-experts': 7,
  'travel-agencies': 8,
  'voyagers': 10,

};


export function FormView(props) {

  const dispatch = useDispatch();
  const roleParams = props?.match?.params;
  // const { role } = props.match.params;
  const role = roleObj[roleParams?.role]
  const scrollToRef = (ref) => { window.scroll(0, ref.current && ref.current.offsetTop - 200); };
  const [lang] = useState(language.getLang());
  const myRef = useRef(null);
  const history = useHistory();

  const [otp, SetOtp] = useState(false);
  const [validEmail, setValidEmail] = useState('');
  const [responseMsg, setResponseMsg] = useState('');
  const [digits, SetDigits] = useState(false);
  const [Pickup, setPickup] = useState(false);

  const [property, SetProperty] = useState(false);
  const [delevery, setDelevery] = useState(false);
  const [strLength, SetStrLength] = useState(false);
  const [otherCity, SetotherCity] = useState(false);

  const [voyagersEmail, SetVoyagersEmail] = useState();
  const [specialChar, SetSpecialChar] = useState(false);
  const [vatNoLength, SetvatNoLength] = useState(false);
  const [voyagersOtp, SetVoyagersOtp] = useState(false);
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState(42.5781);
  const [Longitude, setLongitude] = useState(-87.6298);

  const [zipCodeLength, SetzipCodeLength] = useState(false);
  const [productTypeList, setProductTypeList] = useState([]);
  const [conservationTypeList, setConservationTypeList] = useState([]);
  const [productsArrList, setProductArrList] = useState([]);
  const [isFDA, setIsFDA] = useState(false);
  const [pickupDeleveryArr, setpickupDeleveryArr] = useState([]);
  const [conservationMethod, SetConservationMethod] = useState(false);
  const [submitFormTrue, setSubmitFormTrue] = useState(false);
  const [isEmailAndOtpTrue, setIsEmailAndOtpTrue] = useState(false);
  const [productTypeArray, setProductTypeArray] = useState([]);
  const [params, setParams] = useState()
  let { role_data: { roles }, } = useSelector((state) => state.authWrapper);
  let { formFields, formValue, errMessage, countries, regions, cities, fieldNames, formSpinner, registerData, resError, } = useSelector((state) => state.register);

  useEffect(() => {
    const str = window.location.href;
    const getUrl = str.split("/");
    const bodyUrl = getUrl[getUrl.length - 1];
    document.body.classList.add(bodyUrl);
    setParams(bodyUrl)
  }, []);

  CustomUseEffect(async () => {
    formFields?.step_1?.map(async (field) => {
      if (field.type == 'checkbox') {
        field.options &&
          field.options.map((item) => {
            let opt = [];
            item.options.map((i) => {
              i.options.map((o) => {
                opt.push(o.user_field_option_id);
              });
            });
            let allCheckbox = {
              id: item.user_field_option_id,
              subopt: opt
            }
            productTypeArray.push(allCheckbox);
          });
      }
    });
    await setProductTypeArray(productTypeArray);
  }, [formFields]);

  CustomUseEffect(async () => {
    await dispatch(setFieldValues(formValue));
    await dispatch(fetchFormFields(role));
    await dispatch(getCountries(role));
    //document.getElementById('11_input').placeholder = lang?.select;
    //document.getElementById('12_input').placeholder = lang?.select;
    // await dispatch(getRegion());
    // await dispatch(getCity());
  }, []);

  const handleInput = async (e) => {
    let value = e.target?.value;
    let name = e.target.name;
    let fieldId = e.target.id;
    (value === "Other" || value === "Altra" || parseInt(fieldId) === 32) ? SetotherCity(true) : SetotherCity(false);
    name === "vat_no" && vatNoValidate(value)
    name === "password" && passValidate(value);
    parseInt(fieldId) === 39 && await setIsFDA(true);
    name === "zip_postal_code" && zipCodeValidate(value?.length === 6 ? value?.replace("-", "") : value)
    parseInt(fieldId) === 28 && await dispatch(getCity(value))
    parseInt(fieldId) === 13 && await dispatch(getRegion(value))
    formValue[fieldId] = value;
    await dispatch(setFieldValues(formValue));
  };

  const zipCodeValidate = async (zipCode) => {
    var format = /^\d{5}(-\d{4})?$/;
    var format1 = /^\d{5}(-\d{1}||-\d{2}||-\d{3})?$/;
    ((format.test(zipCode) || format1.test(zipCode)) && zipCode.length <= 10) ? SetzipCodeLength(true) : SetzipCodeLength(false)
  };

  const vatNoValidate = (vatNo) => vatNo.length == 11 ? SetvatNoLength(true) : SetvatNoLength(false)

  const passValidate = async (params) => {
    var digit = /(\d+)/;
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    format.test(params) ? SetSpecialChar(true) : SetSpecialChar(false)
    digit.test(params) ? SetDigits(true) : SetDigits(false)
    params.length >= 8 ? SetStrLength(true) : SetStrLength(false)
  };

  const handleCheckBox = async (e, isDefault, error) => {
    const targetElement = e.target;
    if (targetElement?.value === "1" && targetElement?.id === "1") {
      targetElement.id = 2;
    }

    if (isDefault != true) {
      if (targetElement.checked) {
        if (parseInt(targetElement?.value) === 628) {
          await setPickup(targetElement.checked);
          pickupDeleveryArr.push(targetElement?.value)
          formValue[targetElement.id] = pickupDeleveryArr;
          await dispatch(setFieldValues(formValue));
        } else if (parseInt(targetElement?.value) === 629) {
          await setDelevery(targetElement.checked);
          pickupDeleveryArr.push(targetElement?.value)
          formValue[targetElement.id] = pickupDeleveryArr;
          await dispatch(setFieldValues(formValue));
        } else if (targetElement.name == 0) {
          await SetConservationMethod(false);
          await SetProperty(false);
        }
        else if (targetElement.name === "conservation_method") {
          conservationTypeList.push(targetElement?.value);
          await setConservationTypeList(conservationTypeList);
          await SetConservationMethod(true)
        }
        else if (targetElement.name === "properties") {
          productsArrList.push(targetElement?.value);
          await setProductArrList(productsArrList);
          await SetProperty(true)
        }
        productTypeList.push(parseInt(targetElement?.value));
        await setProductTypeList(productTypeList);
        formValue[targetElement.id] = productTypeList;
        await dispatch(setFieldValues(formValue));
      } else {
        if (parseInt(targetElement?.value) === 628) {
          await setPickup(targetElement.checked);
          delete formValue[21]
          const index = pickupDeleveryArr.indexOf(targetElement?.value)
          if (index > -1) pickupDeleveryArr.splice(index, 1)
          await setpickupDeleveryArr(pickupDeleveryArr)
          formValue[targetElement.id] = pickupDeleveryArr;
          await dispatch(setFieldValues(formValue));
        } else if (parseInt(targetElement?.value) === 629) {
          await setDelevery(targetElement.checked);
          delete formValue[22]
          const index = pickupDeleveryArr.indexOf(targetElement?.value)
          if (index > -1) pickupDeleveryArr.splice(index, 1)
          await setpickupDeleveryArr(pickupDeleveryArr)
          formValue[targetElement.id] = pickupDeleveryArr;
          await dispatch(setFieldValues(formValue));
        }
        // Product Type....

        const index = productTypeList.indexOf(parseInt(targetElement?.value))
        if (index > -1) productTypeList.splice(index, 1)
        let found = productTypeArray.some(el => el.id === parseInt(targetElement?.value));
        if (found) {
          let resultObject = productTypeArray.find(o => o.id === parseInt(targetElement?.value));
          const iterator = resultObject.subopt.values();
          for (const value of iterator) {
            const index = productTypeList.indexOf(parseInt(value));
            if (index > -1) productTypeList.splice(index, 1);
          }
        }
        await setProductTypeList(productTypeList)
        formValue[targetElement.id] = productTypeList;
        await dispatch(setFieldValues(formValue));

        // if (targetElement.name === "properties") {
        //   const index = productsArrList.indexOf(targetElement.value)
        //   if (index > -1) {
        //     await productsArrList.splice(index, 1);
        //     await setProductArrList(productsArrList);
        //   }
        // }
        // if (targetElement.name === "conservation_method") {
        //   const index = conservationTypeList.indexOf(targetElement.value)
        //   if (index > -1) {
        //     await conservationTypeList.splice(index, 1);
        //     await setConservationTypeList(conservationTypeList);
        //   }
        // }
      }
    }
  };

  const handleMultiSelect = async (id, selectedList) => {
    let arr = [];
    selectedList.map((item) => arr.push(item.user_field_option_id));
    formValue[id] = arr;
    await dispatch(setFieldValues(formValue));
  };

  function checkProductTypeChecked() {

    var checked = true;
    var productTypeCheckboxes = document.querySelectorAll(".productTypeCheckbox");
    productTypeCheckboxes.forEach(function (checkbox) {
      if (checkbox.checked) {
        var pId = checkbox.value;
        console.log('here i am readch', pId);

        var conservationCheckboxes = document.querySelectorAll('.conservation_method-' + pId + ':checked');
        console.log(conservationCheckboxes, "conservationCheckboxes")
        var conservation = conservationCheckboxes.length;
        console.log(conservation, "conversion");

        var propertyCheckboxes = document.querySelectorAll('.properties-' + pId + ':checked');
        var property = propertyCheckboxes.length;
        console.log(property, "property");

        if (conservation === 0) {
          dispatch(setFormErrors(lang?.Please_select_at_least_one_conservation_method, "product_type"));
          scrollToRef(myRef);
          checked = false;
        } else if (property === 0) {
          dispatch(setFormErrors(lang?.Please_select_at_least_one_Properties, "product_type"));
          scrollToRef(myRef);
          checked = false;
        }
      }
    });
    if (params == 9) {
      return true
    } else {
      return checked;
    }
  }


  // function checkProductTypeChecked() {
  //   var checked = true;
  //   $(".productTypeCheckbox").each(function () {
  //     if (this.checked) {
  //       var pId = $(this).val();
  //       console.log('here i am readch',pId)

  //       var conversition = $('.conservation_method-' + pId + ':checkbox:checked').length;
  //       console.log(conversition,"converstion")
  //       var property = $('.properties-' + pId + ':checkbox:checked').length;
  //       console.log(property,"property")
  //       if (conversition === 0) {
  //         dispatch(setFormErrors(lang?.Please_select_at_least_one_conservation_method, "product_type"));
  //         scrollToRef(myRef);
  //         checked = false;
  //       } else if (property === 0) {
  //         dispatch(setFormErrors(lang?.Please_select_at_least_one_Properties, "product_type"));
  //         scrollToRef(myRef);
  //         checked = false;
  //       }

  //     }
  //   });

  //   return checked;
  // }
  const submitHandler = async (e) => {
    //console.log("you r a right place")
    // console.log("hlllo",e)
    console.log(formValue, formFields, "vvvlvlvl")
    e.preventDefault();
    await setSubmitFormTrue(true);
    let ENV = Lang.getLang();
    let values = form.getFieldName(formValue, formFields);
    if ((fieldNames.includes("email") && !values.email) || values.email === "") {
      dispatch(setFormErrors(ENV.Please_Enter_Email, "email"));
      scrollToRef(myRef);
    } else if ((fieldNames.includes("password") && !values.password) || values.password === "") {
      dispatch(setFormErrors(ENV.Password, "password"));
      scrollToRef(myRef);
    } else if ((fieldNames.includes("password") && strLength === false) || digits === false || specialChar === false) {
      dispatch(setFormErrors(ENV.ValidatePass, "password"));
      scrollToRef(myRef);
    } else if ((fieldNames.includes("company_name") && !values.company_name) || values.company_name === "") {
      scrollToRef(myRef);
      dispatch(setFormErrors(lang?.Please_enter_the_Company_Name, "company_name"));
    } else if ((fieldNames.includes("restaurant_name") && !values.restaurant_name) || values.restaurant_name === "") {
      dispatch(setFormErrors(lang?.Please_enter_the_Restaurant_Name, "restaurant_name"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("vat_no") && !values.vat_no) || values.vat_no === "") {
      dispatch(setFormErrors(lang?.Please_enter_the_VAT_no, "vat_no"));
      scrollToRef(myRef);
    } else if (fieldNames.includes("vat_no") && vatNoLength === false) {
      dispatch(setFormErrors(lang?.It_consists_of_11_numerical_digit, "vat_no"));
      scrollToRef(myRef);
    } else if (fieldNames.includes("fda_certified") && isFDA === false) {
      dispatch(setFormErrors(lang?.Please_select_FDA_certified_field, "fda_certified"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    }
    else if ((fieldNames.includes("product_type") && !values.product_type) || values.product_type === "") {
      dispatch(setFormErrors(lang?.Please_select_product_type, "product_type"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    }
    //else if (fieldNames.includes("product_type") && (conservationMethod === false || conservationTypeList?.length === 0)) {
    //   dispatch(setFormErrors(lang?.Please_select_at_least_one_conservation_method, "product_type"));
    //   await (myRef && myRef.content);
    //   scrollToRef(myRef);
    // } else if (fieldNames.includes("product_type") && (property === false || productsArrList?.length === 0)) {
    //   dispatch(setFormErrors(lang?.Please_select_at_least_one_Properties, "product_type"));
    //   await (myRef && myRef.content);
    //   scrollToRef(myRef);
    // }
    else if ((fieldNames.includes("first_name") && !values.first_name) || values.first_name === "") {
      dispatch(setFormErrors(ENV.Name, "first_name"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("last_name") && !values.last_name) || values.last_name === "") {
      dispatch(setFormErrors(ENV.LastName, "last_name"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("speciality") && !values.speciality) || values.speciality === "") {
      dispatch(setFormErrors(lang?.Please_select_at_least_one_speciality, "speciality"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("country") && !values.country) || values.country === "") {
      dispatch(setFormErrors(ENV.Please_Select_Country, "country"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("state") && !values.state) || values.state === "") {
      dispatch(setFormErrors(ENV.State, "state"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("city") && !values.city) || values.city === "") {
      dispatch(setFormErrors(lang?.SelectCity, "city"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("enter_your_city") && !values.enter_your_city && otherCity === true) || values.enter_your_city === "") {
      dispatch(setFormErrors(lang?.EnterCity, "enter_your_city"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("zip_postal_code") && !values.zip_postal_code) || values.zip_postal_code === "") {
      dispatch(setFormErrors(lang?.Zip_Postal, "zip_postal_code"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if (fieldNames.includes("zip_postal_code") && zipCodeLength === false) {
      dispatch(setFormErrors(lang?.InvailidZip_Postal, "zip_postal_code"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("expertise") && !values.expertise) || values.expertise === "") {
      dispatch(setFormErrors(lang?.Expertise, "expertise"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("title") && !values.title) || values.title === "") {
      dispatch(setFormErrors(lang?.SelectTitle, "title"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if (
      (fieldNames.includes("pick_and_delivery_option") && !values.pick_and_delivery_option) || values.pick_and_delivery_option === "") {
      dispatch(setFormErrors(lang?.DeliveryOption, "pick_and_delivery_option"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("pick_up_discount_for_alysei_voyagers") && !values.pick_up_discount_for_alysei_voyagers && Pickup === true) || values.pick_up_discount_for_alysei_voyagers === "") {
      dispatch(setFormErrors(lang?.PickUpDiscount, "pick_up_discount_for_alysei_voyagers"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("delivery_discount_for_alysei_voyagers") && !values.delivery_discount_for_alysei_voyagers && delevery === true) || values.delivery_discount_for_alysei_voyagers === "") {
      dispatch(setFormErrors(lang?.DeliveryDiscount, "delivery_discount_for_alysei_voyagers"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("restaurant_type") && !values.restaurant_type) || values.restaurant_type === "") {
      dispatch(setFormErrors(lang?.RestaurantType, "restaurant_type"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("horeca") && !values.horeca) || values.horeca === "") {
      dispatch(setFormErrors(lang?.Horeca, "horeca"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("private_label") && !values.private_label) || values.private_label === "") {
      dispatch(setFormErrors(lang?.PrivateLebel, "private_label"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("alysei_brand_label") && !values.alysei_brand_label) || values.alysei_brand_label === "") {
      dispatch(setFormErrors(lang?.AlyseiBrandLebel, "alysei_brand_label"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((fieldNames.includes("terms_and_condition") && !values.terms_and_condition) || values.terms_and_condition === "") {
      dispatch(setFormErrors(lang?.Term_Condition, "terms_and_condition"));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else {
      //console.log("check check chekc checkc jefb kefc jm cdmn cadjklbfckj asdbasj vcfjhhb adk")


      if (role != 6) {
        formValue["role_id"] = role;
      }
      let allChecked = checkProductTypeChecked();
      // let allChecked=true
      console.log(allChecked, "all checked")
      if (allChecked) {
        await dispatch(register(formValue)).then(async (res) => {
          if (res && res.success === 200) {
            await setProductArrList([]);
            await setConservationTypeList([]);
            await setProductTypeList([]);
            await SetVoyagersEmail(res.data.email);
            await SetVoyagersOtp(true);
            // if (res.message) {
            //   SetVoyagersOtp(true);
            //   SetVoyagersEmail(res.data.email)
            // }
            // else {
            //   // history.push(`/login/${role}`);
            //   document.body.classList.remove(`register`, `${role}`);
            //   // await localStorage.setItem("alysei_review", 0);
            //   history.push(`/hubs`);
            //   // history.push(`/home`);
            //   formValue = {};
            //   dispatch(setFieldValues(formValue));
            // }
          } else {
            await (myRef && myRef.content); scrollToRef(myRef)
          }
        });
      }
    }
    await setSubmitFormTrue(false);
    setTimeout(() => { dispatch(setFormErrors({ msg: "", field: "" })); dispatch(setResponseErrors("")) }, 4000);
  };

  const varifyOtp = async (event) => {
    event.preventDefault();
    await setIsEmailAndOtpTrue(true);
    const model = { email: voyagersEmail, otp: otp };
    await dispatch(voyagerRregister(model)).then(async (res) => {
      if (res && res.success === 200) {
        let user_id = res?.data?.user_id
        console.log(user_id, 'userre')
        let userData = { ...res?.data?.roles, user_id }
        console.log(userData, "userDatada")
        SetVoyagersOtp(false);
        await auth.setTokens(
          res && res.token,
          JSON.stringify(res && userData)
        );
        //console.log(res?.data?.alysei_review,'res?.data?.alysei_review..');
        console.log(res, "reeerre")
        await localStorage.setItem("alysei_review", res?.data?.alysei_review);
        if (parseInt(role) === constant.rolesId.buyer) {
          document.body.classList.remove(`register`, `${role}`);
          // localStorage.setItem()
          history.push(`/buyer-dashboard`);
        }
        else {

          if (parseInt(role) === constant.rolesId.voyagers) {
            document.body.classList.remove(`register`, `${role}`);
            history.push(`/home`);
          } else {
            document.body.classList.remove(`register`, `${role}`);
            formValue = {};
            dispatch(setFieldValues(formValue));
            history.push(`/hubs`);
          }
        }
      } else {
        setValidEmail(res?.message);
      }
    });
    setTimeout(() => {
      setValidEmail('');
      setResponseMsg('');
    }, 3000);
    await setIsEmailAndOtpTrue(false);
  };

  const resendOtp = async () => {
    const response = await dispatch(verifyUserEmaiId(voyagersEmail));
    if (response.success === 200) {
      setResponseMsg(lang?.OTP_has_been_send_successfully);
    } else {
      setValidEmail(response?.message);
    }
    setTimeout(() => {
      setValidEmail('');
      setResponseMsg('');
    }, 3000);
  }

  const closeOtpModal = () => {
    SetVoyagersOtp(!voyagersOtp);
    setValidEmail('');
    history.push('/register');
  }

  const handleFile = async (event) => {
    let imageSize = event.target.files[0].size;
    let name = event.target.name;
    let fieldId = event.target.id;
    if (imageSize > 1000000) {
      dispatch(setFormErrors(lang?.disputeImageSize, name));
      document.getElementById(fieldId).value = '';
    }
    else {
      formValue[fieldId] = event.target.files[0];
      await dispatch(setFieldValues(formValue));
    }

    setTimeout(() => { dispatch(setFormErrors("")); }, 2500);

  };

  // console.log("roleParamsrole",role);

  let roleSeOObj = {
    3: <ItalianFood />,
    6: <ImporterDistributers />,
    11: <Buyers />,
    9: <ItalianRestaurant />,
    7: <VoiceExperts />,
    8: <TravelAgencies />,
    10: <Voyagers />

  }


  //console.log(productTypeArray,"productTypeArray");
  //console.log(productTypeList,"productTypeList");
  return (
    <Fragment>
      {/* @@ seo  */}
      {roleSeOObj[role]}

      {/* @@ seo  */}

      <div className="register_form">
        {formSpinner ? <FormSpinner /> :
          <div className="card--block">
            <div className="card-title">
              <h1> <Link to="/signup"> <i className="fas fa-chevron-left" /> </Link> {roles?.find((i) => i.role_id === parseInt(role)).name}</h1>
              <hr />
            </div>
            <div className="card-form">
              <form onSubmit={(e) => submitHandler(e)}>
                <div className="small-text">{lang?.Required_Information}</div>
                {resError && resError.length > 0 && <div ref={myRef}> <FormError msg={resError} /></div>}
                {Object.keys(formFields)?.map((item, i) => {
                  return (
                    <Fragment key={i}>
                      {formFields[item]?.map((field, j) => {
                        if (field.type === "email" || field.type === "password" || field.type === "text") {
                          return (
                            <Fragment key={j}>
                              {otherCity && field.name === "enter_your_city" &&
                                <Input
                                  key={field.user_field_id}
                                  type={field.type}
                                  placeholder={field.placeholder}
                                  name={field.name}
                                  label={field.title}
                                  id={field.user_field_id}
                                  onChange={(e) => handleInput(e)}
                                />
                              }
                              {field.name !== "enter_your_city" &&

                                <Input
                                  key={field.user_field_id}
                                  type={field.type}
                                  placeholder={field.placeholder}
                                  name={field.name}
                                  label={field.name === "zip_postal_code" ? "Zip Code" : field.title}
                                  id={field.user_field_id}
                                  onChange={(e) => handleInput(e)}
                                />}
                              {errMessage.field === field.name && <div ref={myRef}> <FormError msg={errMessage.msg} /> </div>}
                              {field.type === "password" && field.name === 'password' && <PasswordIndicator specialChar={specialChar} digits={digits} strLength={strLength} />}
                            </Fragment>
                          );
                        } else if (field.type === "checkbox") {
                          return (
                            <Fragment key={j}>
                              <Checkbox
                                key={field.user_field_id}
                                label={field.title}
                                type={field.type}
                                placeholder={field.placeholder}
                                Options={field.options}
                                id={field.user_field_id}
                                Ref={field.user_field_id}
                                selectedCheckbox={productTypeList}
                                onChange={(e, isDefault) => { handleCheckBox(e, isDefault) }}
                              />
                              {errMessage.field === field.name && <div ref={myRef}> <FormError msg={errMessage.msg} /> </div>}
                            </Fragment>
                          );
                        } else if (field.type === "select") {
                          return (
                            <Fragment key={j}>
                              {(Pickup && field.name === "pick_up_discount_for_alysei_voyagers") || (delevery && field.name === "delivery_discount_for_alysei_voyagers") ? (
                                <Select
                                  key={field.user_field_id}
                                  label={field.title}
                                  type={field.type}
                                  placeholder={field.placeholder}
                                  Options={field.options}
                                  id={field.user_field_id ? field.user_field_id : field.name}
                                  apiCall={field.api_call}
                                  onChange={(e) => handleInput(e)}
                                />
                              ) : field.name !== "pick_up_discount_for_alysei_voyagers" ? (
                                <>
                                  {field.name !== "delivery_discount_for_alysei_voyagers" ? (
                                    <Select
                                      key={field.user_field_id}
                                      label={field.title}
                                      type={field.type}
                                      placeholder={field.placeholder}
                                      Options={field.api_call && field.name === "country" ? countries : field.api_call && field.name === "state" ? regions : field.api_call && field.name === "city" ? cities : field.options}
                                      id={field.user_field_id ? field.user_field_id : field.name}
                                      apiCall={field.name === "city" ? "true" : field.api_call}
                                      onChange={(e) => handleInput(e)}
                                    />
                                  ) : (Pickup && field.name === "pick_up_discount_for_alysei_voyagers") || (delevery && field.name === "delivery_discount_for_alysei_voyagers") ? (
                                    <Select
                                      key={field.user_field_id}
                                      label={field.title}
                                      type={field.type}
                                      placeholder={field.placeholder}
                                      Options={field.options}
                                      id={field.user_field_id ? field.user_field_id : field.name}
                                      apiCall={field.api_call}
                                      onChange={(e) => handleInput(e)}
                                    />
                                  ) : null}
                                </>
                              ) : null}
                              {errMessage.field === field.name && <div ref={myRef}> <FormError msg={errMessage.msg} /></div>}
                            </Fragment>
                          );
                        } else if (field.type === "radio") {
                          return (
                            <Fragment key={j}>
                              <Select
                                key={field.user_field_id}
                                label={field.title}
                                type={field.type}
                                placeholder={field.placeholder}
                                Options={field.options}
                                apiCall={field.api_call}
                                id={field.user_field_id}
                                onChange={(e) => handleInput(e)}
                              />
                              {errMessage.field === field.name && <div ref={myRef}> <FormError msg={errMessage.msg} /> </div>}
                            </Fragment>
                          );
                        } else if (field.type === "multiselect") {
                          return (
                            <Fragment key={j}>
                              <MultiSelecter
                                displayValue={"option"}
                                id={field.user_field_id}
                                Options={field.options}
                                placeholder={lang?.select}
                                label={field.title}
                                Value={formValue[field.user_field_id]}
                                Ref={field.user_field_id}
                                fireData={(id, selectedList) => handleMultiSelect(id, selectedList)}
                              />
                              {errMessage.field === field.name && <div ref={myRef}><FormError msg={errMessage.msg} /> premmm</div>}
                            </Fragment>
                          );
                        } else if (field.type === "map") {
                          let lngId = '';
                          let lntId = '';
                          formFields[item]?.map((field, j) => {
                            if (field.name === "longitude") {
                              lngId = field.user_field_id;
                            }
                            if (field.name === "lattitude") {
                              lntId = field.user_field_id;
                            }
                          });
                          return (
                            <Fragment key={j}>
                              {/* <div className="form__item" style={{ paddingBottom: 40 }}> <Map lat={28.658892800000004} lng={77.2308992} google={google} /></div> */}
                              <div className="form__item" style={{ paddingBottom: 40 }} >
                                <Map
                                  google={window.google}
                                  center={{ lat: latitude, lng: Longitude }}
                                  height="300px"
                                  zoom={5}
                                  onChange={(address, latValue, lngValue) => {
                                    setLatitude(latValue);
                                    setLongitude(lngValue);
                                    formValue[field?.user_field_id] = address;
                                    formValue[lntId] = latValue;
                                    formValue[lngId] = lngValue;
                                    setAddress(address);
                                  }}
                                  name="address" />
                              </div>
                              <hr />
                            </Fragment>
                          );
                        } else if (field.type === 'file') {
                          return (
                            <Fragment key={j}>
                              <div class="form__item relative" key={field.user_field_id}>
                                <label for={field.user_field_id}>{field.title}</label>
                                <input type="file" Ref={field.user_field_id} id={field.user_field_id} name={field.name} onChange={(e) => handleFile(e)} accept="image/*, application/pdf" />
                              </div>
                              <hr />
                              {errMessage.field === field.name && <div ref={myRef}> <FormError msg={errMessage.msg} /> </div>}
                            </Fragment>
                          )
                        }
                      })}
                    </Fragment>
                  );
                })}

                {Object.keys(formFields)?.map((item, i) => {
                  return (
                    <Fragment key={i}>
                      {formFields[item]?.map((field, j) => {
                        if (field.type === "terms") {
                          return (
                            <Fragment key={j}>
                              <Terms
                                key={field.user_field_id}
                                label={field.title}
                                type={field.type}
                                id={field.user_field_id}
                                onChange={(e) => handleInput(e)}
                              />
                              {errMessage.field === field.name && <div ref={myRef}>  <FormError msg={errMessage.msg} /> </div>}
                            </Fragment>
                          );
                        }
                      })}
                    </Fragment>
                  );
                })}
                {/* <hr /> */}
                {/*  */}
                <div className="form__action form__item">
                  <button type="submit">{submitFormTrue ? <FormSpinner /> : lang?.Sign_up}</button>
                  <hr />
                  {/* <Link className="btn bg-color-blue mt-4" to={`/login/${role}`} > Already have an account with us?</Link> */}
                  <Link className="btn bg-color-blue mt-4" to="/login"> {lang?.already_account_with_us}</Link>
                </div>
              </form>
            </div>
            {voyagersOtp && (
              <div>
                <Modal
                  ariaHideApp={false}
                  isOpen={voyagersOtp}
                  style={{
                    overlay: {
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(255, 255, 255, 0.75)",
                    },
                    content: {
                      position: "absolute",
                      top: "0",
                      left: "0%",
                      right: "0%",
                      bottom: "200px",
                      border: "1px solid #ccc",
                      background: "#fff",
                      overflow: "auto",
                      WebkitOverflowScrolling: "touch",
                      borderRadius: "4px",
                      padding: "0",
                    },
                  }}
                >
                  <div className="popup_header flex justify-between items-center">
                    <h4 className="page__title text-center">{lang?.Alysei}</h4>
                    <button
                      className="close_btn--modal"
                      onClick={() => closeOtpModal()}
                    >
                      {lang?.close}
                    </button>
                  </div>
                  <div className="card card--block modal_box popUp">
                    <p className="text-blue text-center mb-8">
                      {lang?.We_have_sent_you_a_6_digit_verification_code_OTP}{" "}
                      <strong>{voyagersEmail}</strong>
                      <p className="text-blue text-center mb-8">{lang?.SPAM_FOLDER}.</p>
                    </p>
                    <p className="text-red-500">{validEmail}</p>
                    <p className="text-green">{responseMsg}</p>
                    <form className="mb-2" onSubmit={(event) => varifyOtp(event)}>
                      <div className="form__action otp">
                        <OtpInput
                          value={otp}
                          onChange={(otp) => SetOtp(otp)}
                          numInputs={6}
                        // separator={<span>-</span>}
                        />

                        <button type="submit" className="btn w-full mt-6 mb-6">
                          {/* {isEmailAndOtpTrue ? <FormSpinner /> : "Reset Password"} */}
                          {isEmailAndOtpTrue ? <FormSpinner /> : lang?.Verify_OTP}
                        </button>
                        <p>
                          {lang?.Didnot_receive_the_OTP}{" "}
                          <Link to="#" onClick={() => resendOtp()} className="title-color font-semibold">{lang?.Resend_OTP}</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                  <div></div>
                </Modal>
              </div>
            )}
            {/* Verify Otp End */}
          </div>
        }
      </div>
    </Fragment>
  );
}
