import React, { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import MultiSelecter from '../../../components/MutiSelect/MultiSelect';
import { fetchAllHubs, fetchProducer, fetchLabels, productTypeField, getAllProductTypeData } from '../modules/B2B';
import constant from '../../../helpers/constant';
import Checkbox from '../../../components/Checkbox/Checkbox';
import language from '../../../helpers/language';
import { Multiselect } from 'multiselect-react-dropdown';
import CustomUseEffect from '../../../components/constants';

export default function ProducerSearchBar(props) {
  const dispatch = useDispatch();
  const labelRef = useRef(null);
  const [heroca, setHeroca] = useState('');
  const [hubsId, setHubsId] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [privateLabel, setPrivateLabel] = useState('');
  const [roleSearchType, setroleSearchType] = useState(2);
  const [alyseiBrandLabel, setAlyseiBrandLabel] = useState('');
  const [lang] = useState(language.getLang());
  const [searchLabels, setSearchLabels] = useState([{key: lang?.select, value: '' }]);
  const [productTypeArray, setProductTypeArray] = useState([]);
  const { getAllHubs, getAllLabels, productField } = useSelector((state) => state.b2bState);

  CustomUseEffect(async () => {
    await dispatch(fetchAllHubs());
    await dispatch(productTypeField(roleSearchType));
    await dispatch(fetchLabels(5));
    //document.getElementById('producer_hubs_input').placeholder = lang?.select;
  }, []);

  CustomUseEffect(async() => {
    await setHubsId(props?.hubsId);
  },[])

  CustomUseEffect(async() => {
    if (productField.type == 'checkbox') {
      productField.options &&
      productField.options.map((item) => {
          let opt = [];
          item.options.map((i) => {
            i.options.map((o) => {
              opt.push(o.user_field_option_id);
            });
          });
          let allCheckbox = {
            id: item.user_field_option_id,
            subopt: opt
          }
          productTypeArray.push(allCheckbox);
        });
    }
    await setProductTypeArray(productTypeArray);
  },[productField]);

  const handImporterSearch = async (event) => {
    event.preventDefault();
    await getProducerSearch();
  };

  const getProducerSearch = async () => {
    const producer = {
      searchType: roleSearchType,
      roleId: constant.rolesId.producer,
      hubsId: hubsId,
      heroca: heroca,
      privateLabel: privateLabel,
      alyseiBrandLabel: alyseiBrandLabel,
      productType: productTypeList,
      page: 1,
    };

    //console.log(producer,"producer444");
    props.getStatesOfSearchBar(producer);
    await dispatch(fetchProducer(producer));
  };

  const handleMultiSelect = async (id, selectedList) => {
    const arr = [];
    (await selectedList) && selectedList.map((item) => arr.push(item.id));
    await setHubsId(arr);
  };
  const handleCheckBox = async (e, isDefault) => {
    if (e.target.checked) {
      //console.log(e.target.value,"checked");
      productTypeList.push(parseInt(e.target.value));
      let uniqueArr = [...new Set(productTypeList)];
      await setProductTypeList(uniqueArr);
    } else {
      let uniqueArr = [...new Set(productTypeList)];
      const index = uniqueArr.indexOf(parseInt(e.target && e.target.value));
      if (index > -1) uniqueArr.splice(index, 1);
      let found = productTypeArray.some(el => el.id === parseInt(e.target.value));
        if(found){
          let resultObject = productTypeArray.find(o => o.id === parseInt(e.target.value));
          const iterator = resultObject.subopt.values();
          for (const value of iterator) {
            const index = uniqueArr.indexOf(parseInt(value));
            if (index > -1) uniqueArr.splice(index, 1);
          }
        }
      await setProductTypeList(uniqueArr);
    }
  };

  CustomUseEffect(async() => {
    if(searchLabels.length === 1){
      getAllLabels && getAllLabels?.options?.length > 0 &&  getAllLabels?.options?.map(async(option) => {
        const label = {
          key: option.option, 
          value: option.user_field_option_id 
        }
        await setSearchLabels((searchLabels) => [
          ...searchLabels,
          label,
        ]);
      })
    }
  },[getAllLabels])

  // Select Label
  const changeSearchLabel = async(e) => {
    setPrivateLabel(e[0].value);
  }

  //console.log(productTypeList,"productTypeList");
  return (
    <div className="left__section-block sm:mb-2 md:mb-2">
      <div className="card card--block ">
        <div className="hubsTitle">
          <h4 className="font-18">{lang?.Italian_F_and_B_producersB2B}</h4>
        </div>
        <div className="card-form p-3">
          {/* <h6 className="font-14 pb-3">Search Hubs</h6> */}
          <form onSubmit={(event) => handImporterSearch(event)}>
            {props.hubShow && (
              <div className="form__item">
                <MultiSelecter
                  displayValue={'title'}
                  Options={getAllHubs}
                  label={'Hubs'}
                  placeholder={lang?.select}
                  id="producer_hubs"
                  required={'no'}
                  fireData={(id, selectedList) =>
                    handleMultiSelect(id, selectedList)
                  }
                />
              </div>
            )}
            {productField !== undefined && (
              <Checkbox
                key={productField.user_field_id}
                label={productField.title}
                type={productField.type}
                placeholder={productField.placeholder}
                Options={productField.options}
                id={productField.user_field_id}
                selectedCheckbox={productTypeList}
                Ref={productField.user_field_id}
                onChange={(e, isDefault) => handleCheckBox(e, isDefault)}
              />
            )}

            <div className="form__item">
            <div className="form-desc">{lang?.Hotel_Restaurant_Café}</div>
              {/* <select
                id="hor"
                onChange={(event) => setHeroca(event.target.value)}
              >
                <option value="">{lang?.select}</option>
                <option value="yes">{lang?.yes}</option>
                <option value="no">{lang?.no}</option>
              </select> */}
              <Multiselect
                options={[{ key: lang?.select, value:''},{ key: lang?.yes, value:'yes'},{ key: lang?.no, value:'no'}]}
                displayValue="key"
                placeholder={lang?.select}
                onSelect={(event) => setHeroca(event[0].value)}
                id={'fda__certificate'}
                singleSelect
                ref={labelRef}
              />
            </div>
            <div className="form__item">
            <div className="form-desc">{lang?.Label}</div>
              <Multiselect
                options={searchLabels}
                displayValue="key"
                placeholder={lang?.select}
                onSelect={changeSearchLabel}
                id={'fda__certificate'}
                singleSelect
                ref={labelRef}
              />
            </div>
            <div className="form__item">
            <div className="form-desc">{lang?.Alysei_Brand_Label}</div>
              {/* <select
                id="ab"
                onChange={(event) => setAlyseiBrandLabel(event.target.value)}
              >
                <option value="">{lang?.select}</option>
                <option value="yes">{lang?.yes}</option>
                <option value="no">{lang?.no}</option>
              </select> */}
              <Multiselect
                options={[{ key: lang?.select, value:''},{ key: lang?.yes, value:'yes'},{ key: lang?.no, value:'no'}]}
                displayValue="key"
                placeholder={lang?.select}
                onSelect={(event) => setAlyseiBrandLabel(event[0].value)}
                id={'fda__certificate'}
                singleSelect
                ref={labelRef}
              />
            </div>
            <div className="fomr-actions">
              <button className="btn btnBgPrimary" type="submit">
                {' '}
                {lang?.Search}{' '}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
