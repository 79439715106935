import React, { useState, useEffect } from 'react'
import BuyerHeader from './DashboardView/BuyerHeader'
import SideBar from './SideBar'
import { Link } from "react-router-dom";
import action_img from '../../../assets/images/action.svg'
import delete_img from '../../../assets/images/delete.svg'
import { getMyAllRequests, deleteMyRequest } from '../modules/Buyermodule';
import { useSelector, useDispatch } from 'react-redux';
import FormSpinner from '../../../components/Spinners/FormSpinner'; 
import moment from "moment";
import NoData from './CreateRequestView/NoRequestFound'
import PaginatedComponentView from "../../../components/PaginatedComponent/PaginatedComponentView";
import Modal from "react-modal";
import CustomUseEffect from '../../../components/constants';

export default function RecentRequests() {
    const dispatch = useDispatch();
    const [productTypeId, setProductTypeId] = useState('');
    const [searchRequestId, setSearchRequestId] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [deleteRequestId, setDeleteRequestId] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [page,setPage]=useState()
    

    CustomUseEffect(async() => {
        await (dispatch(getMyAllRequests(1,'','')));
    },[]);

    let { myAllRequests, createRequestSpinner } = useSelector((state) => state.buyerModuleFilterData);

    // paginated  Function
    const handleRecentOrderPageClick = async (e) => {
        const selectedPage = e.selected + 1;
        setPage(selectedPage)
      
        await dispatch(getMyAllRequests(selectedPage, productTypeId, searchRequestId));
    };

    const getRequestByProductType = async (e) => {
        let productType = e.target.value;
        await setProductTypeId(productType);
        await dispatch(getMyAllRequests(1, productType, searchRequestId));
    }

    const searchRequests = async() => {
        await dispatch(getMyAllRequests(1, productTypeId, searchRequestId));
    }

    // Open Delete Request Modal
    const deleteRequest = async(uuid) => {
        await setDeleteRequestId(uuid);
        await setDeleteModal(true);
        
    }

    const deleteBuyerRequest = async() => {
        await setDeleteLoader(true);
        let apiResponse  = await deleteMyRequest(deleteRequestId);
        if(apiResponse?.success){
            await setDeleteMessage(apiResponse?.message);
            await (dispatch(getMyAllRequests(page,'','')));
        }
        await setDeleteLoader(false);
        await setDeleteModal(false);
        setTimeout(() => {
            setDeleteMessage("");
          }, 2500);
    }

    const handleResert =async() =>{
        if(searchRequestId !=''){
        setSearchRequestId('');
        await dispatch(getMyAllRequests(1, productTypeId, ''));
        }
    }
    //console.log(myAllRequests?.requests?.data,"myAllRequests");
    return (
        <>
            <section className='buyerDashboard'>
                <div className='dashboardLayout'>
                    <div className='headerBlock'>
                        <BuyerHeader
                            pageTitle="Recent Requests"
                        />
                    </div>
                    <div className='sideBarBlock'>
                        <SideBar />
                    </div>
                    <div className='centerContent'>
                   
                        <div className='pageContent'>
                            <div className='dashboardView noBorder'>
                                <div className='newDashboard bgWhite'>
                                    <div className='tableFilter card card--block p-3'>
                                        <div className='flexBox gap-2'>
                                            <div className='filterLeft flexBox items-center gap-2'>
                                                <div className='searchBar form__item whiteBg mb-0'>
                                                    <input type='text' placeholder='Search Request Id' value={searchRequestId} onChange={(e) => setSearchRequestId(e.target.value)}/>
                                                    <span>
                                                        <i className="fa fa-search" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                                <button type='button' className='btn btn-blue style2 rounded2' onClick={searchRequests}>Search</button>
                                                <div className=""><button type="button" className="btn btn--gray reset rounded2" onClick={handleResert}>Reset</button></div>
                                            </div>
                                            <div className='filterRight flexBox gap-2 flexEnd items-center'>
                                                <div>
                                                    <label className='font-bold'>Filter</label>
                                                </div>
                                                <div className='form__item whiteBg mb-0'>
                                                    <select value={productTypeId} onChange={(e) => getRequestByProductType(e)}>
                                                    <option value="">Select Product Type</option>
                                                    {
                                                    myAllRequests?.product_types?.length > 0 && myAllRequests?.product_types.map(({option, user_field_option_id}) => {
                                                        return (
                                                            <option key={user_field_option_id} value={user_field_option_id}>{option}</option>
                                                        )
                                                    })
                                                    }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='successmessage font-bold text-green text-center mb-2'>{deleteMessage}</div>
                                    <div className='tableView table-responsive'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Request ID</th>
                                                    <th>Title</th>
                                                    <th>Product Type</th>
                                                    <th>Total Request Send</th>
                                                    <th>Responses</th>
                                                    <th>Views</th>
                                                    <th>Created on</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {createRequestSpinner ? <tr><td colSpan="8"><FormSpinner/></td></tr> : 
                                            
                                                myAllRequests?.requests?.data?.length > 0 && myAllRequests?.requests?.data.map(({id, uuid, title,total_send_request, product_type, created_at, response, views}) => {
                                                    return (
                                                        <tr className='Active'>
                                                            <td>{id}</td>
                                                            <td>{title}</td>
                                                            <td>{product_type ? product_type : '--'}</td>
                                                            <td>{total_send_request > 0 ? total_send_request : 'Admin'}</td>
                                                            <td>{total_send_request > 0 ? response : '--'}</td>
                                                            <td>{total_send_request > 0 ? views : '--'}</td>
                                                           
                                                             <td>{moment(created_at).format("hh:mm A DD MMM YYYY")}</td>
                                                            <td>
                                                                <div className='iconGroup'>
                                                                    {
                                                                        
                                                                    }
                                                                    <Link  to={`/view-recent-request/${uuid}`}>
                                                                        <span  title="View">
                                                                            <img src={action_img} alt="View" />
                                                                        </span>
                                                                    </Link>
                                                                    <div className='delete'>
                                                                        <span title="Delete" onClick={() => deleteRequest(uuid)}>
                                                                            <img src={delete_img} alt="Delete" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                
                                            }
                                            {myAllRequests?.requests?.data?.length == 0 && createRequestSpinner ===false &&(
                                                <tr><td colSpan="8"><NoData/></td></tr>
                                                )}
                                                
                                            </tbody>
                                        </table>
                                        <PaginatedComponentView
                                            lastPage={myAllRequests?.requests?.last_page}
                                            handlePageClick={handleRecentOrderPageClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <Modal
            isOpen={deleteModal}
            style={{
                overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                padding: "0",
                border: "0",
                borderRadius: "8px",
                // outline: 'none',
                },
            }}
            >
            <div className="popup_header flex justify-between items-center popupheaderBg">
                <h4 className="page__title">Delete Request</h4>
                <button
                className="close_btn--modal"
                onClick={() => setDeleteModal(!deleteModal)}
                >
                Close
                </button>
            </div>
            <div className="card card--block modal_box">
                <p className="warning">
                Are you sure you want to delete it?
                </p>
                <div className="right__btn text-right mt-4">
                {deleteLoader ? (
                    <FormSpinner />
                ) : (
                    <>
                    <Link
                        className="btn btn1"
                        to="#"
                        onClick={() => setDeleteModal(!deleteModal)}
                    >
                        Cancel
                    </Link>
                    <Link
                        className="btn btn1 btn2 ml-3"
                        to="#"
                        onClick={() => deleteBuyerRequest()}
                    >
                        Yes
                    </Link>
                    </>
                )}
                </div>
            </div>
            </Modal>
        </>
    )
}