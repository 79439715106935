import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import default_Image from "../../../assets/images/default_image.jpg";
import PaginatedComponentView from "../../../components/PaginatedComponent/PaginatedComponentView";
import ResetButtons from "../../../components/ResetButton/ResetButtons";
import { useDispatch, useSelector } from "react-redux";
// import { checkSubscription } from "../../home/modules/HomeModule";
import Modal from "react-modal";
import { getMarketplaceMyProducts } from "../modules/marketplace";

export default function Product({
  handleProductStatus,
  message,
  filterKeys,
  filterProducts,
  history,
  setFilterKeys,
  productCategies,
  handlePageClick,
  lang,
  activeTab,
  myProductsList,
  textLimit,
  deleteProductItem,
}) {
  const dispatch=useDispatch()

  useEffect(()=>{
    dispatch(getMarketplaceMyProducts(1, {}));
  },[])
 
  const [isOpen, setIsOpen] = useState(false);
  const { get_Subscription_Status_Data } = useSelector((state) => state.activityFeedStatus);
  let subscriptionData=get_Subscription_Status_Data?.subscription?.subscription_plan
  let subscriptionDataModified=subscriptionData?.product_limit==-1?10000000000:subscriptionData?.product_limit

  console.log(get_Subscription_Status_Data,subscriptionData==undefined,"cjheck")


const handleCloseModal = () => {
  setIsOpen(false);
};

const handleOpenModal = () => {
    setIsOpen(true);
  };
  return (
    <div
      className={`tab home-section no-padding sm:mt-2 ${
        activeTab === "products" ? "active-tab" : ""
      }`}
    >
      <div className='product-title'>
        <div className='flex flex-wrap justify-between items-center'>
          <h4 className='mb-0'>{lang?.Products}</h4>
          <div className='inner-btn-box'>
            {
              get_Subscription_Status_Data?.product_count<subscriptionDataModified?(
                <Link to='/marketplace/add-product' className='btn btn-blue'>
                <i className='fa fa-plus-circle' aria-hidden='true'></i>
                {lang?.add_product}
              </Link>
              ):(
                <div className='btn btn-blue' style={{cursor:"pointer"}} onClick={()=>{handleOpenModal()}}>
                  {/* <Link to='/marketplace/add-product' className='btn btn-blue'> */}
                <i className='fa fa-plus-circle' aria-hidden='true'></i>
                {lang?.add_product}
              {/* </Link> */}
                  </div>
                
              )
            }
           
          </div>
        </div>
      </div>

      <div className='productFilter product_mgmt_filter mb--30'>
        <div className='searchBar'>
          <input
            type='text'
            value={filterKeys?.search_product ?? ""}
            onChange={(e) =>
              setFilterKeys((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
              }))
            }
            className='search form--group'
            name='search_product'
            placeholder={lang?.Search_Product_By_Id}
          />
          <span className='searchIcon'>
            <i className='fa fa-search' aria-hidden='true'></i>
          </span>
        </div>
        <div className='categoryList'>
          <select
            onChange={(e) =>
              setFilterKeys((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
              }))
            }
            name='category_id'
            value={filterKeys?.category_id ?? ""}
            className='form--group'
          >
            <option value={""}>{lang?.Select_category}</option>
            {productCategies?.map(
              ({ name, marketplace_product_category_id }) => {
                return (
                  <option
                    key={marketplace_product_category_id}
                    value={marketplace_product_category_id}
                  >
                    {name}
                  </option>
                );
              }
            )}
          </select>
        </div>
        <div className='stockList'>
          <select
            onChange={(e) =>
              setFilterKeys((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
              }))
            }
            name='stock'
            value={filterKeys?.stock ?? ""}
            className='form--group'
          > 
            <option>{lang?.Select_stock}</option>
            <option value={"instock"}>{lang?.In_stock}</option>
            <option value={"outstock"}>{lang?.out_stock}</option>
          </select>
        </div>

        <div className='btn btn--gray'>
          <button
            type='button'
            className='btn filterBtn'
            onClick={() => filterProducts(true)}
          >
            {/* <i className="fa fa-filter" aria-hidden="true"></i> */}
            <svg width='25' height='24' viewBox='0 0 25 24' fill='none'>
              <path
                d='M5.35132 2L8.35132 5L11.3513 2H5.35132ZM15.3513 2L12.3513 5H18.3513L15.3513 2ZM3.35132 7V9H4.35132V9.0332L9.35132 16.0332V22H15.3513V16.0332L20.3513 9.0332V9H21.3513V7H3.35132ZM6.78491 9H17.9177L13.3513 15.3926V20H11.3513V15.3926L6.78491 9Z'
                fill='black'
              />
            </svg>
            {lang?.Filter}
          </button>
        </div>
      </div>
      {Object.keys(filterKeys)?.length > 0 && (
        <ResetButtons
          text={"Reset Filter"}
          onclick={() => {
            filterProducts(false);
            setFilterKeys({});
          }}
        />
      )}
      {message && <p className='textGreen'> {message}</p>}
      <div className='product-list'>
        <div className='recentOrder secBlockGap'>
          {/* <h4 className='secBlockTitle'>Recent Orders</h4> */}
          <div className='itemTable itemTableScroll customer__table'>
            <table>
              <thead>
                <tr>
                  <th>{lang?.Image}</th>
                  <th className='customer__name'>{lang?.product_keywords}</th>
                  <th className='customer__name'>{lang?.Category}</th>
                  <th>{lang?.Quantity}</th>
                  <th className='customer__name'>{lang?.price}</th>
                  <th className='customer__name'>{lang?.RRP} <span><i className="fa fa-info-circle infoIcon" aria-hidden="true" title={lang ?. RRP_info}></i></span></th>
                  <th>{lang?.Action}</th>
                  
                </tr>
              </thead>
              <tbody>
                {myProductsList?.data?.length > 0
                  ? myProductsList?.data?.map((item,i) => {
                      return (
                        
                          // i<get_Subscription_Status_Data?.product_count&& item.status=="1"&& 
                          // item.status=="1"&&
                          <tr>
                          <td>
                            {item?.product_gallery?.length > 0 ? (
                              <img
                                src={`${item.product_gallery[0]?.base_url}${item.product_gallery[0]?.attachment_medium_url}`}
                              />
                            ) : (
                              <img src={default_Image} alt='default-image' />
                            )}
                          </td>
                          <td className='customer__name'>
                            {" "}
                            {item.title}
                            
                          </td>
                          <td className='customer__name category-width'>
                            {textLimit(item?.product_category_name, 25)}
                          </td>
                          <td>
                            <div className='quantity '>
                              {item.quantity_available == "null" ||
                              item.quantity_available == null
                                ? 0
                                : item.quantity_available}
                            </div>
                          </td>
                          <td className='customer__name'>
                            {item?.product_price !== null && item?.product_price > 0
                              ? `$${item?.product_price}`
                              : "--"}
                          </td>
                          <td>{item?.rrp_price  !== null  && item?.rrp_price > 0 ? `$${item?.rrp_price}`  : "--"}</td>
                          <td>
                            <div className='flexBox'>
                  
                              <span
                                className={`editBlock ${item.status=="0"&&"disable"}`}
                                title={lang?.EditAction}
                                onClick={() =>{item.status=="1"&&
                                  history.push(
                                    `/marketplace/edit-product/${item.marketplace_product_id}`
                                  )
                                }
                              }
                              >
                                <i
                                  className='fa fa-pencil'
                                  aria-hidden='true'
                                ></i>
                              </span>
                              <span
                                className='deleteBlock'
                                title={lang?.DeleteAction}
                                onClick={() =>
                                  deleteProductItem(item.marketplace_product_id)
                                }
                              >
                                <i
                                  className='fa fa-trash-o'
                                  aria-hidden='true'
                                ></i>
                              </span>

                              {item?.status == "1" ? (
                                <span
                                  className='blockList active___Btn '
                                  title={lang?.ActiveAction}
                                  onClick={() =>
                                    handleProductStatus(
                                      item.marketplace_product_id,
                                      "0"
                                    )
                                  }
                                >
                                  <i
                                    className='fa fa-ban'
                                    aria-hidden='true'
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className='blockList disable'
                                  title={lang?.InactiveAction}
                                  // onClick={() =>{item.status=="1"&&
                                  //   handleProductStatus(
                                  //     item.marketplace_product_id,
                                  //     "1"
                                  //   )
                                  // }}
                                >
                                  <i
                                    className='fa fa-ban'
                                    aria-hidden='true'
                                  ></i>
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                        
                      );
                    })
                  : `${lang?.No_product_found}`}
              </tbody>
            </table>
            <PaginatedComponentView
              lastPage={myProductsList?.last_page}
            
              handlePageClick={handlePageClick}
            />
          </div>
        </div>
      </div>
      <Modal
          isOpen={isOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Upgrade Modal"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg text-black text-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
          >
      <div className="w-16 m-auto mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 47.5 47.5" viewBox="0 0 47.5 47.5" id="diamond"><path fill="#bdddf4" d="M0 0h-6l-7-9h10l3 9z" transform="matrix(1.25 0 0 -1.25 17.5 5)"></path><path fill="#5dadec" d="m0 0-7 9h-6l3-9H0z" transform="matrix(1.25 0 0 -1.25 46.25 16.25)"></path><path fill="#4289c1" d="M0 0h10L-8-21 0 0Z" transform="matrix(1.25 0 0 -1.25 33.75 16.25)"></path><path fill="#8ccaf7" d="M0 0h-10L8-21 0 0Z" transform="matrix(1.25 0 0 -1.25 13.75 16.25)"></path><path fill="#8ccaf7" d="m0 0-3-9h16l-3 9H0Z" transform="matrix(1.25 0 0 -1.25 17.5 5)"></path><path fill="#5dadec" d="m0 0-8 21H8L0 0Z" transform="matrix(1.25 0 0 -1.25 23.75 42.5)"></path></svg></div>    
          <h2 className="text-2xl font-bold mb-4  ">Upgrade Your Plan</h2>
          
          {
            subscriptionData!==undefined?(
          //     <p>
          //     <span className=" text-black">Sorry, as per your Current Plan you can add only {subscriptionData?.product_limit==-1?"Unlimited":subscriptionData?.product_limit}  product. For more please upgrade your Plan.</span>
          // </p>
          <p>
              <span className=" text-black">As per your current Plan, you can add up to  {subscriptionData?.product_limit==-1?"Unlimited":subscriptionData?.product_limit}  Products. To add more, please upgrade your Membership Plan.</span>
          </p>
            ):
            (
              <p>
              <span className=" text-black">Sorry, You're unable to add any products at the moment as you don't have an active subscription.</span>
          </p>
            )
          }
         
          <Link to="/subscription">
          <button  className="btn btn-blue stripe text-white font-bold mt-6">Upgrade </button>
          </Link>
          
          </Modal>
    </div>
  );
}
