
import React, { useState } from "react";
import Accordian from "./Accordian";
import AnimateFromBottom from "../../LandingPage/component/AnimateFromBottom";
import language from "../../../helpers/language";
const FaqSection = () => {
  const [lang] = useState(language.getLang());
  const faqArray = lang.faqItems_Page
  
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
 
  return (
    <div className="faq-container">
      <div className="md:px-14 px-4 md:py-8 py-5">
        <AnimateFromBottom delay={0.2}>
        <p className='font-bold text-2xl pb-4'>{lang.faq} 
        </p>
      </AnimateFromBottom>
     
                  

      {faqArray?.map((item, index) => {
        return (
          <AnimateFromBottom delay={0.2}  key={index}>
             <Accordian
             
              faq={item.title}
              answer={item.description}
              index={index}
              isActive={activeIndex === index}
              onClickHandler={handleAccordionClick}
            />
          </AnimateFromBottom>
           
         
        );
      })}
    </div>
    </div>
   
  );
};

export default FaqSection;
