import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import CustomUseEffect from '../constants';

export function MultiSelecter({
  displayValue,
  id,
  Options,
  label,
  OnSelect,
  Ref,
  fireData,
  required,
  placeholder,
}) {
  Ref = useRef();

  const handleChange = (seleceted) => {
    fireData(id, seleceted);
  };

  const [selectedValues, setSelectedValues] = useState([]);
  const [userFieldId, setUserFieldId] = useState([]);

  useEffect(() => {
    Options &&
      Options.map(async (item, index) => {
        if (item.is_selected) {
          await setUserFieldId((oldId) => [...oldId, item?.user_field_id]);
          await setSelectedValues((oldArray) => [...oldArray, item]);
        }
      });
  }, []);

  CustomUseEffect(async () => {
    if (userFieldId) {
      let arr = [];
      Options &&
        Options.map(async (item, index) => {
          if (item.is_selected) {
            if (userFieldId.includes(item?.user_field_id)) {
              await arr.push(item);
            }
          }
        });
      await fireData(userFieldId[0], arr);
    }
  }, [userFieldId]);


  return (
    <Fragment>
      <div className="form__item">
        <label>
          {label}
          {required === 'no' ? '' : '*'}
        </label>
        <div className="select--field multi__select-field">
          <Multiselect
            options={Options}
            displayValue={displayValue}
            selectedValues={selectedValues}
            showCheckbox={true}
            showArrow={true}
            closeOnSelect={false}
            closeMenuOnSelect={true}
            id={id}
            ref={Ref}
            onSelect={handleChange}
            onRemove={handleChange}
            style={{
              chips: { background: '#33a386' },
              searchBox: {
                border: 'none',
                borderBottom: '1px solid #dfdfdf',
                borderRadius: '0px',
              },
            }}
            placeholder={placeholder}

            // singleSelect
          />
          {/* <div class="select--field-arrow">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>
          </div> */}
        </div>
      </div>
      <hr />
    </Fragment>
  );
}

export default MultiSelecter;
