import React, { Fragment, useEffect, useRef, useState } from "react";

export function Checkbox({ key, label, Options, childRef, parentRef, onChange, Ref, selectedCheckbox }) {
  const [productTypes, setProductTypes] = useState([]);
  const [productTypeChecked, setProductTypeChecked] = useState([]);
  useEffect(()=>{
    const freeCall = async () => {
      await setProductTypeChecked(selectedCheckbox);
    }
    freeCall()
  },[selectedCheckbox]);

  useEffect(()=>{
    const firstCall = async () => {
      Options && Options.map(async(item) => {
        item.user_field_option_id === 628 && handleChange(item, true)
        item.user_field_option_id === 629 && handleChange(item, true)
        if(item.is_selected){
          await setProductTypes((productTypes) => [
            ...productTypes,
            item.user_field_option_id,
          ]);
        }
      })
    }
    firstCall()
  }, [Options])

  const handleChange = async(e, isDefault) => {
    // if (isDefault === true && e.is_selected) {
    //   onChange(e, isDefault)
    //   // return e.is_selected
    // } else {
    //   onChange(e, isDefault)
    // }
    
    onChange(e, isDefault)

  };

  // const isCheckedFun = async(e, pTypeId) => {
  //   if(!e.target.checked){
  //       let uniqueArr = [...new Set(productTypeChecked)];
  //       const index = uniqueArr.indexOf(parseInt(e.target && e.target.value));
  //       if (index > -1) uniqueArr.splice(index, 1);
  //       let found = productType.some(el => el.id === parseInt(e.target.value));
  //       if(found){
  //         let resultObject = productType.find(o => o.id === parseInt(e.target.value));
  //         if(resultObject){
  //         const iterator = resultObject.subopt.values();
  //         for (const value of iterator) {
  //           const index = uniqueArr.indexOf(parseInt(value));
  //           if (index > -1) uniqueArr.splice(index, 1);
  //         }
  //       }
  //       console.log(uniqueArr,"uniqueArr");
  //       await setProductTypeChecked(uniqueArr);
  //       }
    
  //   }
  //   else{
  //     productTypeChecked.push(parseInt(e.target.value));
  //       let uniqueArr = [...new Set(productTypeChecked)];
  //       await setProductTypeChecked(uniqueArr);
  //   }
  // }

  // const isCheckedFunt = async(e, pTypeId) => {
  //   if(!e.target.checked){
  //     let uniqueArr = [...new Set(productTypeChecked)];
  //     const index = uniqueArr.indexOf(parseInt(e.target && e.target.value));
  //     if (index > -1) uniqueArr.splice(index, 1);
  //     await setProductTypeChecked(uniqueArr);
  
  //   }
  //   else{
  //     productTypeChecked.push(parseInt(e.target.value));
  //       let uniqueArr = [...new Set(productTypeChecked)];
  //       await setProductTypeChecked(uniqueArr);
  //   }
  // }

  //console.log(productTypeChecked,"productTypeChecked");
  return (
    <Fragment key={key}>
      <div className="form__item">
        <label>{label}</label>
        <div className="form-checkbox-container">
          <div className="form-checkbox-container-items selected">
            {Options && Options.map((item) => {
              let isChecked = productTypeChecked.includes(item.user_field_option_id);
              return (
                <Fragment key={item.user_field_map_role_id}>
                  <div className="form-check">
                    <input
                      className="form-check-input productTypeCheckbox"
                      type="checkbox"
                      checked={isChecked ? 'checked' : ''}
                      // defaultChecked={item.is_selected && handleChange(item, true)}
                      id={item.user_field_id}
                      name={item.head}
                      data="type"
                      value={item.user_field_option_id}
                      onChange={(e) => { handleChange(e); }}
                    />
                    <label className="form-check-label" htmlFor={item.user_field_option_id} > {item.option}</label>
                  </div>
                  {item.options.map((i, j) => {
                    return (
                      <Fragment key={i.user_field_option_id}>
                        {productTypeChecked.includes(i.parent) &&
                          <div className="form-checkbox-blocks">
                            <div className="form-checkbox-item">
                              <div className="form-checkbox-title"> {i.option}</div>
                              {i.options.map((o, m) => {
                                let productTypeName =
                                  "conservation_method";
                                if (j > 0) {
                                  productTypeName = "properties";
                                }
                                let isCheck = productTypeChecked.includes(o.user_field_option_id);
                                return (
                                  <Fragment key={o.user_field_option_id}>
                                    <div className="form-checkbox-contents">
                                      <div className="form-check">
                                        <input
                                          className={`${'form-check-input'} ${productTypeName+'-'+item.user_field_option_id}`}
                                          checked={isCheck ? 'checked' : ''}
                                          type="checkbox"
                                          id={o.user_field_id}
                                          name={productTypeName}
                                          value={o.user_field_option_id}
                                          onChange={(e) => {
                                            handleChange(e);
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={o.user_field_option_id}
                                        >
                                          {o.option}
                                        </label>
                                      </div>
                                    </div>
                                  </Fragment>
                                );
                              })}
                            </div>
                          </div>
                        }

                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <hr />
    </Fragment>
  );
}

export default Checkbox;

