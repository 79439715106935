import React, { Fragment, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import firebase from "../../../config/firebase";
import { Link, useParams } from "react-router-dom";
import { singlelActivityFeed } from "../modules/HomeModule";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import Parse from 'html-react-parser';
import MemberType from "../../../components/MediaMemberType";
import userProfile from "../../../assets/images/dummy-profile-pic.jpg";
import language from "../../../helpers/language";
import CustomUseEffect from "../../../components/constants";
export function PublicPostSingleViewPage(props) {
    const dispatch = useDispatch();
    const { activityId } = useParams();
    const [lang] = useState(language.getLang());
    const [fetchCommentList, setFetchCommentList] = useState([]);
    const [isOpenImage, setIsOpenImage] = useState(false);
    const [userPhotosData, setUserPhotosData] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
    const { activityPost, getActivitySpinner } = useSelector((state) => state.activityFeedStatus);

    CustomUseEffect(async () => {
        document.body.classList.remove("hubs");
        document.body.classList.remove("profile");
        document.body.classList.remove("selected_hubs");
        document.body.classList.remove("home");
    }, []);

    CustomUseEffect(async () => {
        localStorage.removeItem('post-url');
        await dispatch(singlelActivityFeed(parseInt(activityId)))
        fetchFirebaseData();
    }, []);

    const fetchFirebaseData = () => {
        const todoRef = firebase.database().ref("post");
        todoRef.on("value", (snapshot) => {
            const response = snapshot.val();
            const result = [];
            for (let id in response) result.push({ id, ...response[id] });
            setFetchCommentList(result);
            // setNewPost(true)
        });
    };
    
    useEffect(() => {
        const str = window.location.href;
        const getUrl = str.split("/");
        const bodyUrl = getUrl[getUrl.length - 1];
        document.body.classList.add(bodyUrl);

        // For Scroll
        $(document).ready(function () {
            $(window).scroll(function () {
                if ($(document).scrollTop() > 20) {
                    $("#addScrollClass").addClass("addScroll");
                } else {
                    $("#addScrollClass").removeClass("addScroll");
                }
            });
        });
    }, []);

    // photos gallery
    const handlePhotos = async (data, url) => {
        let userPhotos = [];
        await data?.attachments?.map(async (item) => {
            await userPhotos.push({
                url: `${item?.attachment_link?.base_url}${item && item?.attachment_link?.attachment_url
                    }`,
                type: "photo",
                altTag: "image",
            });
        });
        userPhotos?.map(async (item, index) => {
            if (item?.url === url) {
                await setImageIndex(index);
            }
        });

        await setUserPhotosData(userPhotos);
        await setIsOpenImage(true);
    };

    // Check post content has URL
    const Checkurl = (text) => {
        var url1 = /(^|&lt;|\s)(www\..+?\..+?)(\s|&gt;|$)/g,
            url2 = /(^|&lt;|\s)(((https?|ftp):\/\/|mailto:).+?)(\s|&gt;|$)/g;

        var html = text;
        if (html) {
            html = html
                .replace(url1, '$1<a target="_blank"  href="http://$2">$2</a>$3')
                .replace(url2, '$1<a target="_blank"  href="$2">$2</a>$5')
                .replace(/(\w+@\w+.\w+)+/g, "<a href='mailto:$1'>$1</a>");
        }
        return Parse(html?.replace(/\n/g, "<br />"));
    };

    return (
        <Fragment>
            <div className="home__page" id="addScrollClass">
                <div className="site-align top-align">
                    <div className="left__section-block md:mb-2 sm:mb-2">
                    </div>
                    {/* Middle Block */}
                    <div className="middle__section-block home_page">

                        {/* <SocialPost redirect_to={"post_screen"} /> */}
                        {!getActivitySpinner ? [activityPost]?.length > 0 && [activityPost]?.map((item, index) => {
                            {
                                fetchCommentList &&
                                    fetchCommentList?.map((comItem, comIdx) => {
                                        const res = [];
                                        if (
                                            comItem &&
                                            comItem.postId === item?.activity_action_id
                                        ) {
                                            for (let id in comItem && comItem.comment) {
                                                res.push({ id, ...comItem.comment[id] });
                                            }
                                            if (item?.length > 0) {
                                                item.comment = res;
                                                item.comment_count = comItem.commentCount;
                                                item.like_count = comItem.likeCount;
                                            }

                                            // return item
                                            return (item.comment = res);
                                        }
                                    });
                            }
                            return (
                                <div className="card card--block test" key={index}>
                                    <div className="feed-listing">
                                        <div className="feed-user-detail">
                                            <div className="feed-user-info">
                                                <Link
                                                    to=""
                                                >
                                                    <div className="user__pic">
                                                        {item?.subject_id &&
                                                            item?.subject_id?.avatar_id ? (
                                                            <img
                                                                src={`${item?.subject_id?.avatar_id?.base_url}${item?.subject_id?.avatar_id?.attachment_medium_url}`}
                                                            />
                                                        ) : (
                                                            <img src={userProfile} />
                                                        )}
                                                    </div>
                                                </Link>
                                                <div className="user__detail-data">
                                                    <span className="user__name">
                                                        <Link
                                                            to=""
                                                        >
                                                            {item?.subject_id?.company_name ||
                                                                item?.subject_id?.restaurant_name ||
                                                                (item?.subject_id?.first_name !== null &&
                                                                    item?.subject_id?.last_name !== null &&
                                                                    `${item?.subject_id?.first_name} ${item?.subject_id?.last_name}`)}
                                                        </Link>
                                                    </span>
                                                    <div className="flex">
                                                        <MemberType item={item?.subject_id} />
                                                        {item?.subject_id?.role_id === 10 ? (
                                                            ""
                                                        ) : (
                                                            <span className="text-blue">
                                                                {" "}
                                                                , {item?.follower_count} {lang?.Followers}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="user__feed user__feed-text">
                                                <p>{item?.body && Checkurl(item?.body)}</p>
                                                {/* <p>{item?.body}</p> */}
                                                {item?.attachments?.length > 0 &&
                                                    item?.shared_post_id === 0 && (
                                                        <Fragment>
                                                            {item?.attachments?.length === 1 ? (
                                                                <div className="slider__img post--img singlePost">
                                                                    {item?.attachments?.map((el, index) => {
                                                                        return (
                                                                            <div
                                                                                className="slider__item"
                                                                                key={index}
                                                                            >
                                                                                <div key={index}>
                                                                                    <img
                                                                                        className="feed_img"
                                                                                        src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                                                        onClick={() =>
                                                                                            handlePhotos(
                                                                                                item,
                                                                                                `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            ) : (
                                                                <div className="slider__img post--img ">
                                                                    {item?.attachments
                                                                        ?.slice(0, 4)
                                                                        ?.map((el, index) => {
                                                                            return (
                                                                                <div
                                                                                    className="slider__item"
                                                                                    key={index}
                                                                                >
                                                                                    <div key={index}>
                                                                                        <img
                                                                                            className="feed_img"
                                                                                            src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                                                            onClick={() =>
                                                                                                handlePhotos(
                                                                                                    item,
                                                                                                    `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        {item?.attachments?.length >
                                                                                            4 && (
                                                                                                <div className="show_all_img">
                                                                                                    <div
                                                                                                        className="show_all_icon"
                                                                                                        onClick={() =>
                                                                                                            handlePhotos(
                                                                                                                item,
                                                                                                                `${el?.attachment_link?.base_url}${item?.attachments[4].attachment_link?.attachment_url}`
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <i
                                                                                                            className="fa fa-plus"
                                                                                                            aria-hidden="true"
                                                                                                        ></i>
                                                                                                        <span className="img_count_no">
                                                                                                            {item?.attachments
                                                                                                                ?.length - 4}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                </div>
                                                            )}
                                                        </Fragment>
                                                    )}
                                            </div>
                                        </div>

                                        {/* post comments block */}
                                        <div className="user__comment-feed" >
                                            <div className="cmnt-tab">
                                                {/* <div className="cmnt-tab-left"><p>Just Now</p> </div> */}
                                                <div className="cmnt-tab-right flex justify-between items-center">
                                                    <span className="time_posted">
                                                        {" "}
                                                        {item?.posted_at}
                                                    </span>
                                                    <ul className="flex items-center" onClick={() => alert("Please Login to see more.")}>
                                                        <li>
                                                            <Link
                                                                to="#"
                                                                className="flex items-center items-center"
                                                            >
                                                                {item?.like_flag ? (
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="14"
                                                                        height="18"
                                                                        viewBox="0 0 20 17.648"
                                                                    >
                                                                        <path
                                                                            id="like_icon_active"
                                                                            d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                                                            transform="translate(-5 -9)"
                                                                            fill="#ff0046"
                                                                        />
                                                                    </svg>
                                                                ) : (
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="14"
                                                                        height="14"
                                                                        viewBox="0 0 20.653 18.099"
                                                                    >
                                                                        <path
                                                                            id="icons8_heart"
                                                                            d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                                                            transform="translate(-2 -7)"
                                                                        />
                                                                    </svg>
                                                                )}
                                                                <span className="noCount">
                                                                    {item?.like_count}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                to="#"
                                                                className="flex items-center items-center"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="14"
                                                                    height="14"
                                                                    viewBox="0 0 20.653 18.857"
                                                                >
                                                                    <path
                                                                        id="icons8_comments"
                                                                        d="M4.245,4A2.254,2.254,0,0,0,2,6.245V17.02a2.254,2.254,0,0,0,2.245,2.245H6.083A3.476,3.476,0,0,1,6,20.724a2.31,2.31,0,0,1-1.08,1.3.449.449,0,0,0,.224.828,5.571,5.571,0,0,0,5.191-3.592H20.408a2.254,2.254,0,0,0,2.245-2.245V6.245A2.254,2.254,0,0,0,20.408,4Zm0,.9H20.408a1.347,1.347,0,0,1,1.347,1.347V17.02a1.347,1.347,0,0,1-1.347,1.347H10.082a.449.449,0,0,0-.421.295A4.638,4.638,0,0,1,6.42,21.8a3.144,3.144,0,0,0,.435-.8,4.613,4.613,0,0,0,.07-2.273.45.45,0,0,0-.435-.365H4.245A1.347,1.347,0,0,1,2.9,17.02V6.245A1.347,1.347,0,0,1,4.245,4.9Z"
                                                                        transform="translate(-2 -4)"
                                                                    />
                                                                </svg>
                                                                {item?.comment_count > 0 ? (
                                                                    <span className="noCount">
                                                                        {" "}
                                                                        {item?.comment_count}{" "}
                                                                    </span>
                                                                ) : (
                                                                    <span className="noCount"> {0} </span>
                                                                )}
                                                            </Link>
                                                        </li>
                                                        {item?.shared_post_id === 0 && (
                                                            <li>
                                                                <Link to="#"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="14"
                                                                        height="14"
                                                                        viewBox="0 0 18.857 15.283"
                                                                    >
                                                                        <path
                                                                            id="icons8_forward_arrow"
                                                                            d="M15.168,11a.448.448,0,0,0-.393.449v3.171c-5.169.158-7.955,2.894-9.33,5.612A13.958,13.958,0,0,0,4,25.756v.056a.449.449,0,1,0,.9.028v-.028a5.221,5.221,0,0,1,1.389-2.848c1.263-1.419,3.727-2.85,8.489-2.946v3.1a.449.449,0,0,0,.73.351l7.184-5.837a.45.45,0,0,0,0-.7L15.5,11.095A.45.45,0,0,0,15.168,11Zm.505,1.389,6.019,4.9-6.019,4.9V19.527a.45.45,0,0,0-.449-.449c-5.277,0-8.122,1.622-9.611,3.3-.056.063-.088.119-.14.182a12.281,12.281,0,0,1,.772-1.936c1.31-2.589,3.811-5.135,8.98-5.135a.45.45,0,0,0,.449-.449Z"
                                                                            transform="translate(-4 -10.993)"
                                                                        />
                                                                    </svg>
                                                                </Link>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                                {/* .........................post comments................... */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : <FormSpinner />
                        }
                    </div>

                    {/* Right Block */}
                    {<div className="tab-content-right-section lg:order-last md:order-first sm:order-first md:mb-2 sm:mb-2"></div>}
                </div>
            </div>
            {isOpenImage && (
                <div className="image--light--pop">
                    <ReactImageVideoLightbox
                        data={userPhotosData}
                        startIndex={imageIndex}
                        showResourceCount={true}
                        onCloseCallback={() => {
                            setIsOpenImage(false);
                            setUserPhotosData([]);
                            setImageIndex(0);
                        }}
                    />
                </div>
            )}
        </Fragment>
    );
}
