import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Carousel from "react-grid-carousel";
import { Link, useHistory, useParams } from "react-router-dom";
import Picker from "emoji-picker-react";
import Modal from "react-modal";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import Parse from 'html-react-parser';
import userProfile from "../../../assets/images/dummy-profile-pic.jpg";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import firebase from "../../../config/firebase";
import {
  likePost,
  sharedPost,
  postComment,
  deletePost,
  replyComment,
  fetchAllActivityFeed,
  getActivityPost,
  likeUnlikeComment,
  removePostComment,
  updateComment,
  singlelActivityFeed,
  reportUserPost,
  resetDiscoveryFilters,
} from "../modules/HomeModule";
import { getEditUserPostDetails } from "../../Post/modules/post";
import { AddPost } from "../../Post/components/addPost";
import InfiniteScroll from "react-infinite-scroller";
import language from "../../../helpers/language";
import MemberType from "../../../components/MediaMemberType";
import {
  FacebookShareButton, TwitterShareButton, LinkedinShareButton, FacebookIcon, TwitterIcon, LinkedinIcon,
} from "react-share";
import CustomUseEffect from "../../../components/constants";

export function SocialPost(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { activityId } = useParams();
  let myRef = useRef();
  let socialRef = useRef(null);
  const [postId, setpostId] = useState('');
  const [sharePosturl, setSharePosturl] = useState('');
  const [sharePostOnMedia, setSharePostOnMedia] = useState(false)
  const [commentId, setCommentId] = useState();
  const [replyCommentId, setReplyCommentId] = useState();
  const [privacy, setprivacy] = useState("public");
  const [replytId, setReplytId] = useState();
  const [isEmoji, setIsEmoji] = useState({});
  const [isShareEmoji, setIsShareEmoji] = useState(false);
  const [replyEmoji, setReplyEmoji] = useState({});
  const [commentVal, setCommentVal] = useState();
  const [updateCommentVal, setUpdateCommentVal] = useState("");
  const [updateReplyCommentVal, setUpdateReplyCommentVal] = useState();
  const [dotClick, setDotClick] = useState(false);
  const [commentDotClick, setCommentDotClick] = useState(false);
  const [repyCommentDotClick, setRepyCommentDotClick] = useState(false);
  const [editModal, setIsEditModal] = useState(false);
  const [commentReply, setCommentReply] = useState("");
  const [shareModal, setShareModal] = useState(false);
  const [sharePostItem, setSharePostItem] = useState();
  const [sharePostText, setSharePostText] = useState('');
  const [lang] = useState(language.getLang());
  const [isWriteReply, setWriteReply] = useState(false);
  const [isViewReply, setViewReply] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [isWriteComment, setWriteComment] = useState(false);
  const [isUpdateComment, setUpdateComment] = useState(false);
  const [isUpdateReplyComment, setUpdateReplyComment] = useState(false);
  const [isWriteUpdateComment, setWriteUpdateComment] = useState(false);
  const [isWriteUpdateReplyComment, setWriteUpdateReplyComment] =
    useState(false);
  const [isReplyComment, setReplyComment] = useState(false);
  const [fetchCommentList, setFetchCommentList] = useState();
  const [commentLike, setCommentLike] = useState([]);
  const [feedActivityArr, setFeedActivityArr] = useState([]);
  const [isComment, setIsComment] = useState({ _id: 0, show: false });
  const [isUpdateCommentEmoji, setIsUpdateCommentEmoji] = useState({});
  const [updateReplyEmoji, setUpdateReplyEmoji] = useState({});
  const [commentBox, setCommentBox] = useState({});
  const [isNewPost, setNewPost] = useState(false);
  const [page, setPage] = useState(1);
  const [nextButton, setNextButton] = useState(false);
  const [isReportAndBlock, setIsReportAndBlock] = useState(false);

  const [isOpenImage, setIsOpenImage] = useState(false);
  const [userPhotosData, setUserPhotosData] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [addReason, setAddReason] = useState("");
  const [reportPostId, setReportPostId] = useState();
  const [isReportModal, setIsReportModal] = useState(false);
  const [reasonError, setReasonError] = useState(false);
  const [allowOtherReason, setAllowOtherReason] = useState(false);
  const [otherReason, setOtherReason] = useState("");
  const [otherReasonError, setOtherReasonError] = useState(false);
  const [isSubmitReport, setIsSubmitReport] = useState(false);
  const [posterUserId, setPosterUserId] = useState("");
  const [allFirebaseUsers, setAllFirebaseUsers] = useState([]);

  let nextPage = 1;
  const { getPostSpinner, getFirebaseUsers } = useSelector((state) => state.postState);
  const { profileData } = useSelector((state) => state.memberProfileData);
  const { activityPost } = useSelector((state) => state.activityFeedStatus);
  const { sharePostSpinner, removePostSpinner } = useSelector(
    (state) => state.activityFeedStatus
  );
  const { universalSearchPostsData } = useSelector(
    (state) => state.universalSearch
  );
  const [supportAction, setSupportAction] = useState(false);

  console.log(userPhotosData,"userPhotosData")

  CustomUseEffect(async () => {
    const todoRef = firebase.database().ref("users");
    todoRef.on("value", async (snapshot) => {
      const response = snapshot.val();
      const result = [];
      for (let id in response) result.push({ id, ...response[id] });
      await setAllFirebaseUsers(result);
    });

  }, []);

  CustomUseEffect(async () => {
    await fetchFirebaseData();
    const model = "";
    await dispatch(resetDiscoveryFilters(model));
    if (props.redirect_to === "post_screen") {
      //  api call single post
      await dispatch(singlelActivityFeed(parseInt(activityId))).then(
        async (data) => {
          await setFeedActivityArr({ data: [data] });
        }
      );
      await dispatch(fetchAllActivityFeed({ postType: 0, page: 1 }));
    } else if (props.redirect_to === "home_screen") {
      await dispatch(fetchAllActivityFeed({ postType: 0, page: 1 })).then(
        async (data) => {
          await setFeedActivityArr(data);
          setNewPost(false);
        }
      );
    }
    // await setLastPage(activityPost?.last_page)
  }, [activityId]);

  CustomUseEffect(async () => {
    if (props.redirect_to === "home_screen" && props?.refresh) {
      await fetchFirebaseData();
      await dispatch(fetchAllActivityFeed({ postType: 0, page: 1 })).then(
        async (data) => {
          await setFeedActivityArr(data);
          setNewPost(false);
        }
      );
    }
    // await setLastPage(activityPost?.last_page)
  }, [props?.refresh]);

  useEffect(() => {
    if (props.redirect_to === "post_screen") {
      setIsComment({ _id: parseInt(activityId), show: true });
    }
  }, [activityId]);

  CustomUseEffect(async () => {
    if (props.redirect_to === "universal_search") {
      await setFeedActivityArr(universalSearchPostsData);
    }
  }, [universalSearchPostsData]);

  // handlePost pop up..
  const mousedownCallBack = (e) => {
    if (socialRef.current && !socialRef.current.contains(e.target)) {
      setDotClick(!dotClick);
    }
  };

  useEffect(() => {
    document.body.addEventListener("onclick", mousedownCallBack);
    const scrollCallBack = window.addEventListener("scroll", () => {
      setDotClick(false);
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
      document.body.addEventListener("onclick", mousedownCallBack);
    };
  }, []);
  // end handlePost pop-up..

  const handleNewPost = async () => {
    setNewPost(false);
    // await fetchFirebaseData()
    await dispatch(fetchAllActivityFeed({ postType: 0, page: 1 })).then(
      async (data) => {
        await setFeedActivityArr(data);
        setNewPost(false);
      }
    );
  };

  const fetchFirebaseData = () => {
    const todoRef = firebase.database().ref("post");
    todoRef.on("value", (snapshot) => {
      const response = snapshot.val();
      const result = [];
      for (let id in response) result.push({ id, ...response[id] });
      setFetchCommentList(result);
      // setNewPost(true)
    });
  };

  const handlePostComment = (item) => {
    setCommentBox((commentBox) => ({
      ...commentBox,
      [item.activity_action_id]: !commentBox[item.activity_action_id],
    }));
    const todoRef = firebase.database().ref("comment_like");
    todoRef.on("value", (snapshot) => {
      const response = snapshot.val();
      const result = [];
      Object.keys(response).map((postId) => {
        if (parseInt(postId) === parseInt(item.activity_action_id)) {
          for (let id in response[postId])
            result.push({ id, ...response[postId][id] });
          setCommentLike(result);
          return;
        }
      });
    });
  };

  //post like and unlike ...............
  const handleLike = async (item) => {
    const model = {
      like_or_unlike: item.like_flag ? 0 : 1,
      post_id: item.activity_action_id,
      user_id: profileData?.data?.user_data?.user_id,
    };
    if (item.like_flag) {
      item.like_count = --item.like_count;
      item.like_flag = 0;
    } else {
      item.like_count = ++item.like_count;
      item.like_flag = 1;
    }
    let result = await fetchCommentList?.filter(
      (data) => data.postId === item.activity_action_id
    );
    await dispatch(likePost(model)).then(async (res) => {
      if (res?.success === 200) {
        const payload = {
          commentCount: item.comment_count,
          likeCount: res.total_likes,
          postId: item.activity_action_id,
        };
        if (result[0]?.comment) payload["comment"] = result[0]?.comment;
        firebase
          .database()
          .ref("post")
          .child(item.activity_action_id)
          .set(payload);
      }
    });
  };

  //post comment like and unlike ...............
  const handleCommentLike = async (item, comment) => {
    const model = {
      post_id: item.activity_action_id,
      like_or_unlike: comment.isCommentLike ? 0 : 1,
      comment_id: comment.core_comment_id,
      user_id: profileData?.data?.user_data?.user_id,
    };
    await dispatch(likeUnlikeComment(model)).then((res) => {
      if (res?.success === 200) {
        const payload = {
          core_comment_id: comment.core_comment_id,
          like_id: res.like_id,
          user_id: profileData?.data?.user_data?.user_id,
        };
        if (comment.isCommentLike) {
          firebase
            .database()
            .ref("comment_like")
            .child(item.activity_action_id)
            .child(res.like_id)
            .remove();
          delete comment.isCommentLike;
          comment.comment_like_count = res.total_likes;
          firebase
            .database()
            .ref("post")
            .child(item.activity_action_id)
            .child("comment")
            .child(comment.core_comment_id)
            .set(comment);
        } else {
          delete comment.isCommentLike;
          comment.comment_like_count = res.total_likes;
          firebase
            .database()
            .ref("comment_like")
            .child(item.activity_action_id)
            .child(res.like_id)
            .set(payload);
          firebase
            .database()
            .ref("post")
            .child(item.activity_action_id)
            .child("comment")
            .child(comment.core_comment_id)
            .set(comment);
        }
      }
    });
  };

  //post comment reply like and unlike ...............
  const handleCommentReplyLike = async (item, comment, commReply) => {
    const model = {
      post_id: item.activity_action_id,
      like_or_unlike: commReply.isCommentLike ? 0 : 1,
      comment_id: commReply.core_comment_id,
      user_id: profileData?.data?.user_data?.user_id,
    };
    await dispatch(likeUnlikeComment(model)).then((res) => {
      if (res?.success === 200) {
        const payload = {
          core_comment_id: commReply.core_comment_id,
          like_id: res.like_id,
          user_id: profileData?.data?.user_data?.user_id,
        };
        if (commReply.isCommentLike) {
          firebase
            .database()
            .ref("comment_like")
            .child(item.activity_action_id)
            .child(res.like_id)
            .remove();
          delete commReply.isCommentLike;
          commReply.comment_like_count = res.total_likes;
          firebase
            .database()
            .ref("post")
            .child(item.activity_action_id)
            .child("comment")
            .child(comment.core_comment_id)
            .child("ReplyDetails")
            .child(commReply.core_comment_id)
            .set(commReply);
        } else {
          delete commReply.isCommentLike;
          commReply.comment_like_count = res.total_likes;
          firebase
            .database()
            .ref("comment_like")
            .child(item.activity_action_id)
            .child(res.like_id)
            .set(payload);
          firebase
            .database()
            .ref("post")
            .child(item.activity_action_id)
            .child("comment")
            .child(comment.core_comment_id)
            .child("ReplyDetails")
            .child(commReply.core_comment_id)
            .set(commReply);
        }
      }
    });
  };

  const onEmojiClick = async (event, emojiObject) => {
    let msg =
      commentVal?.length > 0
        ? `${commentVal}${emojiObject?.emoji}`
        : emojiObject?.emoji;
    await setCommentVal(msg);
    // await setIsEmoji(false);
  };

  const onShareEmojiClick = async (event, emojiObject) => {
    let msg =
      sharePostText?.length > 0
        ? `${sharePostText}${emojiObject?.emoji}`
        : emojiObject?.emoji;
    //console.log(msg, "msg..");
    await setSharePostText(msg);
    // await setIsEmoji(false);
  };

  const handleChange = (event) => {
    if (event.target.name === "updateComment") {
      setUpdateCommentVal(event.target.value);
    } else {
      setCommentVal(event.target.value);
    }
  };

  const sharePost = async (item) => {
    const model = {
      action_type: "post",
      privacy: privacy,
      shared_post_id: item.activity_action_id,
      body: sharePostText?.trim()?.length > 0 ? sharePostText : "",
    };
    await dispatch(sharedPost(model)).then(async (res) => {
      if (res && res.success === 200) {
        firebase
          .database()
          .ref("post")
          .child(res && res.share_post_id)
          .set({
            likeCount: 0,
            commentCount: 0,
            postId: res && res.share_post_id,
          });
        setShareModal(false);
        setSharePostText("");
        handleNewPost();
        //await dispatch(fetchAllActivityFeed({ postType: 0, page: 1 }));
      }
    });

    setShareModal(false);
  };

  const submitCommentPost = async (event, item) => {
    event.preventDefault();
    if (commentVal === undefined || commentVal?.trim()?.length === 0) return;
    const model = {
      post_id: item.activity_action_id,
      comment: commentVal?.trim(),
      user_id: profileData?.data?.user_data?.user_id,
    };
    await dispatch(postComment(model)).then(async (res) => {
      if (res && res.success === 200) {
        setCommentVal("");
        setWriteComment(false);
        if (res?.data?.poster?.avatar_id) {
          let attachment_url = `${res.data.poster.avatar_id.base_url}${res.data.poster.avatar_id.attachment_url}`;
          res.data.poster.avatar_id.attachment_url = await attachment_url;
        }
        let result = await fetchCommentList?.filter(
          (data) => data.postId === item.activity_action_id
        );
        const payload = {
          commentCount: ++result[0].commentCount,
          likeCount: result[0].likeCount,
          postId: result[0].postId,
        };
        if (result[0]?.comment) payload["comment"] = result[0]?.comment;
        firebase
          .database()
          .ref("post")
          .child(item.activity_action_id)
          .set(payload);

        firebase
          .database()
          .ref("post")
          .child(item.activity_action_id)
          .child("comment")
          .child(res && res.data.core_comment_id)
          .set({
            body: res && res.data.body,
            core_comment_id: res && res.data.core_comment_id,
            comment_like_count: 0,
            created_at: res && res.data.created_at,
            poster: res && res.data.poster,
          });
        await fetchFirebaseData();
      }
    });
  };

  const submitCommentReply = async (event, item) => {
    event.preventDefault();
    if (commentReply?.trim()?.length === 0) return;
    const payload = {
      reply: commentReply?.trim(),
      comment_id: replytId,
      post_id: item.activity_action_id,
      user_id: profileData?.data?.user_data?.user_id,
    };
    await dispatch(replyComment(payload)).then(async (res) => {
      setCommentReply("");
      if (res?.success === 200) {
        if (res?.data?.poster?.avatar_id) {
          let attachment_url = `${res.data.poster.avatar_id?.base_url}${res.data.poster.avatar_id?.attachment_medium_url}`;
          res.data.poster.avatar_id.attachment_url = await attachment_url;
        }
        firebase
          .database()
          .ref("post")
          .child(item.activity_action_id)
          .child("comment")
          .child(replytId)
          .child("ReplyDetails")
          .child(res && res.data.core_comment_id)
          .set({
            body: res && res.data.body,
            core_comment_id: res && res.data.core_comment_id,
            created_at: res && res.data.created_at,
            poster: res && res.data.poster,
          });
        await fetchFirebaseData();
      }
    });
  };

  const removePost = async (postId) => {
    const model = { post_id: postId };
    await dispatch(deletePost(model)).then(async (res) => {
      if (res && res.success === 200) {
        await setDeletePopup(false);
        firebase.database().ref("post").child(postId).remove();
        let deleteItem = activityPost?.data.filter(
          (item) => item.activity_action_id !== postId
        );
        const data = {
          ...activityPost,
          data: (activityPost["data"] = deleteItem),
        };
        await setFeedActivityArr(data);
        //await dispatch(getActivityPost(data));
        // await setDeletePopup(false);
      }
    });
  };

  // remove comment message
  const removeComment = async (item, comment) => {
    const model = {
      post_id: item.activity_action_id,
      comment_id: comment.core_comment_id,
    };
    await dispatch(removePostComment(model)).then(async (res) => {
      if (res && res.success === 200) {
        firebase
          .database()
          .ref("post")
          .child(item.activity_action_id)
          .child("comment")
          .child(comment.core_comment_id)
          .remove();
        setCommentDotClick(false);
      }
    });
  };

  // remove reply comment message
  const removeReplyComment = async (item, comment, commReply) => {
    const model = {
      post_id: item.activity_action_id,
      comment_id: commReply.core_comment_id,
    };
    await dispatch(removePostComment(model)).then(async (res) => {
      if (res && res.success === 200) {
        firebase
          .database()
          .ref("post")
          .child(item.activity_action_id)
          .child("comment")
          .child(comment.core_comment_id)
          .child("ReplyDetails")
          .child(commReply.core_comment_id)
          .remove();
        setCommentDotClick(false);
      }
    });
  };

  // update post commment message
  const updatePostComment = async (event, item, comment) => {
    event.preventDefault();
    const model = {
      comment_id: comment.core_comment_id,
      comment:
        updateCommentVal?.trim()?.length > 0 ? updateCommentVal : comment.body,
    };
    comment.body =
      updateCommentVal?.trim()?.length > 0
        ? updateCommentVal?.trim()
        : comment.body;
    await dispatch(updateComment(model)).then(async (res) => {
      if (res && res.success === 200) {
        await firebase
          .database()
          .ref("post")
          .child(item.activity_action_id)
          .child("comment")
          .child(comment.core_comment_id)
          .set(comment);
        setWriteUpdateComment(false);
        setUpdateComment(false);
      }
    });
  };
  // update comment reply message
  const updatePostCommentReply = async (event, item, comment, commReply) => {
    event.preventDefault();
    const model = {
      comment_id: commReply.core_comment_id,
      comment:
        updateReplyCommentVal?.length > 0
          ? updateReplyCommentVal
          : comment.body,
    };
    commReply.body =
      updateReplyCommentVal?.length > 0 ? updateReplyCommentVal : comment.body;
    await dispatch(updateComment(model)).then(async (res) => {
      if (res && res.success === 200) {
        await firebase
          .database()
          .ref("post")
          .child(item.activity_action_id)
          .child("comment")
          .child(comment.core_comment_id)
          .child("ReplyDetails")
          .child(commReply.core_comment_id)
          .set(commReply);
        setWriteUpdateReplyComment(false);
        setUpdateReplyComment(false);
      }
    });
  };

  const handleEditPost = async (post_id) => {
    await dispatch(getEditUserPostDetails(post_id));
    await setIsEditModal(true);
  };

  const handleClick = async (newValue, updatedData) => {
    updatedData?.length > 0 && (await setFeedActivityArr(updatedData));
    await setDotClick(newValue);
    await setIsEditModal(newValue);
  };

  const handleEmoji = (id) => {
    setIsEmoji((isEmoji) => ({
      ...isEmoji,
      [id]: !isEmoji[id],
    }));
  };

  const handleReplyEmoji = (id) => {
    setReplyEmoji((replyEmoji) => ({
      ...replyEmoji,
      [id]: !replyEmoji[id],
    }));
  };

  const handleUpdateCommentEmoji = (id) => {
    setIsUpdateCommentEmoji((isEmoji) => ({
      ...isEmoji,
      [id]: !isEmoji[id],
    }));
  };

  const updateCommentEmoji = async (event, emojiObject) => {
    setIsUpdateCommentEmoji(true);
    let msg =
      updateCommentVal?.length > 0
        ? `${updateCommentVal}${emojiObject?.emoji}`
        : emojiObject?.emoji;
    await setUpdateCommentVal(msg);
  };

  const replyCommentEmoji = async (event, emojiObject) => {
    setReplyComment(true);
    let msg =
      commentReply?.length > 0
        ? `${commentReply}${emojiObject.emoji}`
        : emojiObject.emoji;
    await setCommentReply(msg);
  };

  const updateReplyCommentEmoji = async (event, emojiObject) => {
    setWriteUpdateReplyComment(true);
    let msg =
      updateReplyCommentVal?.length > 0
        ? `${updateReplyCommentVal}${emojiObject.emoji}`
        : emojiObject.emoji;
    await setUpdateReplyCommentVal(msg);
    // await setUpdateReplyEmoji(false);
  };

  const handleUpdateReplyEmoji = (id) => {
    setUpdateReplyEmoji((replyEmoji) => ({
      ...replyEmoji,
      [id]: !replyEmoji[id],
    }));
  };

  CustomUseEffect(async () => {
    let postId = await feedActivityArr?.data?.[0]?.activity_action_id;
    let postId1 = await fetchCommentList?.slice(-1)?.[0]?.postId;
    let postId2 = await fetchCommentList?.slice(-2)?.[0]?.postId;
    let postId3 = await fetchCommentList?.slice(-3)?.[0]?.postId;
    let postId4 = await fetchCommentList?.slice(-4)?.[0]?.postId;
    let postId5 = await fetchCommentList?.slice(-5)?.[0]?.postId;

    if (
      postId !== postId1 &&
      postId !== postId2 &&
      postId !== postId3 &&
      postId !== postId4 &&
      postId !== postId4 &&
      postId !== postId5
    ) {
      //setNewPost(true);
    }
  }, [fetchCommentList]);

  const loadMore = async () => {
    let nextPage = page + 1;
    if (
      nextPage > feedActivityArr?.current_page &&
      nextPage <= feedActivityArr?.last_page
    ) {
      setTimeout(async () => {
        await dispatch(
          fetchAllActivityFeed({ postType: 0, page: nextPage })
        ).then(async (data) => {
          //console.log(...feedActivityArr?.data,"feedActivityArr");
          let concatArr = [...feedActivityArr?.data, ...data?.data];
          ///////////console.log(...data?.data,"apiFeed");
          data["data"] = await concatArr;
          await setFeedActivityArr(data);
          await setPage(nextPage);
          setNewPost(false);
        });
      }, 1000);
    }
  };

  // photos gallery
  const handlePhotos = async (data, url) => {
    let userPhotos = [];
    await data?.attachments?.map(async (item) => {
      await userPhotos.push({
        url: `${item?.attachment_link?.base_url}${item && item?.attachment_link?.attachment_url
          }`,
        type: "photo",
        altTag: "image",
      });
    });
    userPhotos?.map(async (item, index) => {
      if (item?.url === url) {
        await setImageIndex(index);
      }
    });

    await setUserPhotosData(userPhotos);
    await setIsOpenImage(true);
  };

  const repostPost = async (activityId, userId) => {
    await setReportPostId(activityId);
    await setPosterUserId(userId);
    await setIsReportModal(true);
  };

  const addPostReason = (reason) => {
    setAddReason(reason);
    setReasonError(false);
    if (reason === "Other") {
      setAllowOtherReason(true);
    } else {
      setAllowOtherReason(false);
      setOtherReason("");
    }
  };

  // Submit Report post Function
  const reportSubmit = async (event) => {
    event.preventDefault();
    if (addReason != "") {
      let excute = true;
      let reason = addReason;
      if (addReason === "Other") {
        if (otherReason === "") {
          setOtherReasonError(true);
          excute = false;
        }
      } else {
        setOtherReasonError(false);
      }

      setIsReportModal(false);
      setSupportAction(true);
      setNextButton(true);
      if (excute && nextButton) {
        const postData = {
          activity_action_id: reportPostId,
          report_as: reason,
          message: otherReason,
        };
        if (isReportAndBlock) {
          postData.block_user_id = posterUserId;
        }
        await setIsSubmitReport(true);
        await dispatch(reportUserPost(postData))
          .then(async (res) => {
            if (res && res.success === 200) {
              let filterFeedActivity = {
                ...feedActivityArr,
                data: feedActivityArr?.data?.filter(
                  (post) => post?.activity_action_id !== reportPostId
                ),
              };
              await setFeedActivityArr(filterFeedActivity);
              await setReportPostId();
              await setAddReason("");
              await setIsReportModal(false);
              await setIsSubmitReport(false);
              await setReasonError(false);
              await setAllowOtherReason(false);
              await setOtherReason("");
              await setSupportAction(false);
              await setNextButton(false);
              await setOtherReasonError(false);
            } else {
              await setIsSubmitReport(false);
            }
          })
          .catch((error) => { });
      }
    } else {
      setReasonError(true);
    }
  };

  // Check post content has URL
  const Checkurl = (text) => {
    var url1 = /(^|&lt;|\s)(www\..+?\..+?)(\s|&gt;|$)/g,
      url2 = /(^|&lt;|\s)(((https?|ftp):\/\/|mailto:).+?)(\s|&gt;|$)/g;

    var html = text;
    if (html) {
      html = html
        .replace(url1, '$1<a target="_blank"  href="http://$2">$2</a>$3')
        .replace(url2, '$1<a target="_blank"  href="$2">$2</a>$5')
        .replace(/(\w+@\w+.\w+)+/g, "<a href='mailto:$1'>$1</a>");
    }
    return Parse(html?.replace(/\n/g, "<br />"));
  };


  // Copy Link Code
  const postCopyLink = (postId, link) => {
    navigator.clipboard.writeText(link);
    document.getElementById(`copyLink_${postId}`).innerHTML = 'Copied';
    setTimeout(() => {
      setpostId('');
      setSharePostOnMedia(false);
      setDotClick(false);
      document.getElementById(`copyLink_${postId}`).innerHTML = 'Copy Link';
    }, 800);

  }


  return (
    <Fragment>
      {(props.redirect_to === "home_screen" ||
        props.redirect_to === "post_screen") &&
        editModal && (
          <AddPost
            isOpenModal={editModal}
            onHandleChange={handleClick}
            pageNumber={nextPage}
            dataUpdate={feedActivityArr}
          />
        )}
      {getPostSpinner ? (
        <FormSpinner />
      ) : (
        <Fragment>
          {props.redirect_to !== "universal_search" && (
            <InfiniteScroll
              pageStart={0}
              threshold={1000}
              hasMore={true}
              useCapture={true}
              // useWindow={false}
              initialLoad={false}
              loadMore={loadMore}
              loader={page < feedActivityArr?.last_page && <FormSpinner />}
            >
              {/* for new post button */}

              {props.redirect_to === "home_screen" && isNewPost && (
                <div className="newPost" onClick={() => handleNewPost()}>
                  <Link to="#" className="btn btnBgPrimary">
                    {lang.New} {lang?.only_Post}
                  </Link>
                </div>
              )}
              {feedActivityArr?.data?.map((item, index) => {
                {
                  fetchCommentList &&
                    fetchCommentList?.map((comItem, comIdx) => {
                      const res = [];
                      if (
                        comItem &&
                        comItem.postId === item?.activity_action_id
                      ) {
                        for (let id in comItem && comItem.comment) {
                          res.push({ id, ...comItem.comment[id] });
                        }
                        if (item?.length > 0) {
                          item.comment = res;
                          item.comment_count = comItem.commentCount;
                          item.like_count = comItem.likeCount;
                        }

                        // return item
                        return (item.comment = res);
                      }
                    });
                }
                if (props.redirect_to === "post_screen" &&
                  parseInt(activityId) === parseInt(item?.activity_action_id)
                ) {

                  //home post
                  return (
                    <div className="card card--block test" key={index}>
                      <div className="feed-listing">
                        <div className="feed-user-detail">
                          <div className="feed-user-info">
                            <Link
                              to={
                                profileData?.data?.user_data?.user_id ===
                                  item?.subject_id?.user_id
                                  ? "profile"
                                  : `/user-profile/${item &&
                                  item.subject_id &&
                                  item.subject_id.user_id
                                  }`
                              }
                            >
                              <div className="user__pic">
                                {item?.subject_id &&
                                  item?.subject_id?.avatar_id ? (
                                  <img
                                    src={`${item?.subject_id?.avatar_id?.base_url}${item?.subject_id?.avatar_id?.attachment_medium_url}`}
                                  />
                                ) : (
                                  <img src={userProfile} />
                                )}
                              </div>
                            </Link>
                            <div className="user__detail-data">
                              <span className="user__name">
                                <Link
                                  to={
                                    profileData?.data?.user_data?.user_id ===
                                      item?.subject_id?.user_id
                                      ? "profile"
                                      : `/user-profile/${item &&
                                      item?.subject_id &&
                                      item?.subject_id?.user_id
                                      }`
                                  }
                                >
                                  {item?.subject_id?.company_name ||
                                    item?.subject_id?.restaurant_name ||
                                    (item?.subject_id?.first_name !== null &&
                                      item?.subject_id?.last_name !== null &&
                                      `${item?.subject_id?.first_name} ${item?.subject_id?.last_name}`)}
                                </Link>
                              </span>
                              <div className="flex">
                                <MemberType item={item?.subject_id} />
                                {item?.subject_id?.role_id === 10 ? (
                                  ""
                                ) : (
                                  <span className="text-blue">
                                    {" "}
                                    , {item?.follower_count} {lang?.Followers}
                                  </span>
                                )}
                              </div>
                            </div>
                            {/* <div className="about_profile" >
                          <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={() => { setDotClick(!dotClick); setpostId(item.activity_action_id); }}></i>
                        </div> */}
                            <div
                              className="about_profile"
                              onClick={() => {
                                setDotClick(!dotClick);
                                setpostId(item?.activity_action_id);
                                setSharePosturl(item?.privacy === "Public" ? `${window.location.origin}/home/public/post/activity/${item?.activity_action_id}` : `${window.location.origin}/home/post/activity/${item?.activity_action_id}`)
                              }}
                              ref={socialRef}
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                          {/* action dots block */}
                          {dotClick && postId === item.activity_action_id && (
                            <div className="postView 1">
                              {profileData?.data?.user_data?.user_id ===
                                item.subject_id.user_id ? (
                                <ul>
                                  <li>
                                    {" "}
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        handleEditPost(item.activity_action_id);
                                      }}
                                    >
                                      <span>
                                        {" "}
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                        ></i>{" "}
                                      </span>{" "}
                                      {lang?.EditPost}
                                    </Link>{" "}
                                  </li>
                                  <li
                                    onClick={() => {
                                      setDeletePopup(true);
                                      setpostId(item.activity_action_id);
                                      setDotClick(false);
                                    }}
                                  >
                                    <Link to="#">
                                      <span>
                                        {" "}
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>{" "}
                                      </span>{" "}
                                      {lang?.Delete} Post{" "}
                                    </Link>
                                  </li>
                                  {profileData?.data?.user_data?.user_id !=
                                    item.subject_id.user_id &&
                                    item?.shared_post_id === 0 && (
                                      <li
                                        onClick={() => {
                                          setShareModal(!shareModal);
                                          setSharePostItem(item);
                                          setprivacy(item.privacy);
                                          setDotClick(false);
                                        }}
                                      >
                                        <Link to="#">
                                          {" "}
                                          <span>
                                            {" "}
                                            <i
                                              className="fa fa-share"
                                              aria-hidden="true"
                                            ></i>{" "}
                                          </span>{" "}
                                          {lang?.Share_Post}{""}
                                        </Link>
                                      </li>
                                    )}
                                </ul>
                              ) : (
                                <ul>
                                  <li
                                    onClick={() =>
                                      repostPost(
                                        item.activity_action_id,
                                        item.subject_id.user_id
                                      )
                                    }
                                  >
                                    {" "}
                                    <Link to="#">
                                      {" "}
                                      <span>
                                        {" "}
                                        <i
                                          className="fa fa-flag"
                                          aria-hidden="true"
                                        ></i>{" "}
                                      </span>{" "}
                                      {lang?.Report_Post}{" "}
                                    </Link>
                                  </li>
                                  {item?.shared_post_id === 0 &&
                                    <li className=""
                                      onClick={() => { setSharePosturl(item?.privacy === "Public" ? `${window.location.origin}/home/public/post/activity/${item?.activity_action_id}` : `${window.location.origin}/home/post/activity/${item?.activity_action_id}`); setSharePostOnMedia(true) }}
                                    >
                                      <Link to="#">
                                        {" "}
                                        <span>
                                          {" "}
                                          <i
                                            className="fa fa-share"
                                            aria-hidden="true"
                                          ></i>
                                        </span>{" "}
                                        {lang?.Share_Post}{" "}
                                      </Link>
                                    </li>
                                  }
                                </ul>
                              )}
                            </div>
                          )}
                          {/* share social post facebook,linkdin,twitter icon start*/}
                          {(postId === item.activity_action_id && sharePostOnMedia) &&
                            <div className="postView shareBlock">
                              <ul>
                                {/* <li>
                                  <i className="fa fa-link" aria-hidden="true"></i>
                                  <span>Copy Link</span>
                                </li> */}
                                <li>
                                  <FacebookShareButton url={item?.directLink}>
                                    <FacebookIcon size={40} round={true} />
                                    <span >Share on Facebook</span>
                                  </FacebookShareButton>
                                  {/* <WhatsappShareButton url={`${sharePosturl}`}  >
                                    <WhatsappIcon size={40} round={true} />
                                </WhatsappShareButton>              */}
                                </li>
                                <li>
                                  <LinkedinShareButton url={item?.directLink} >
                                    <LinkedinIcon size={40} round={true} />
                                    <span>Share on LinkedIn</span>
                                  </LinkedinShareButton>

                                </li>
                                <li>
                                  <TwitterShareButton url={item?.directLink} >
                                    <TwitterIcon size={40} round={true} />
                                    <span>Share on Twitter</span>
                                  </TwitterShareButton>

                                </li>
                                <li onClick={() => postCopyLink(item.activity_action_id, item?.directLink)}>
                                  <div className="iconWidth">
                                    <i className="fa fa-link" aria-hidden="true"></i>
                                  </div>
                                  <span id={`copyLink_${item.activity_action_id}`}>Copy Link</span>
                                </li>
                              </ul>
                            </div>
                          }
                          {/* share social post facebook,linkdin,twitter icon start*/}
                          <div className="user__feed user__feed-text">
                            <p>{item?.body && Checkurl(item?.body)}</p>
                            {item?.attachments?.length > 0 &&
                              item?.shared_post_id === 0 && (
                                <Fragment>
                                  {item?.attachments?.length === 1 ? (
                                    <div className="slider__img post--img singlePost">
                                      {item?.attachments?.map((el, index) => {
                                        return (
                                          <div
                                            className="slider__item"
                                            key={index}
                                          >
                                            <div key={index}>
                                              <img
                                                className="feed_img"
                                                src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                onClick={() =>
                                                  handlePhotos(
                                                    item,
                                                    `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <div className="slider__img post--img ">
                                      {item?.attachments
                                        ?.slice(0, 4)
                                        ?.map((el, index) => {
                                          return (
                                            <div
                                              className="slider__item"
                                              key={index}
                                            >
                                              <div key={index}>
                                                <img
                                                  className="feed_img"
                                                  src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                  onClick={() =>
                                                    handlePhotos(
                                                      item,
                                                      `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                    )
                                                  }
                                                />
                                                {item?.attachments?.length >
                                                  4 && (
                                                    <div className="show_all_img">
                                                      <div
                                                        className="show_all_icon"
                                                        onClick={() =>
                                                          handlePhotos(
                                                            item,
                                                            `${el?.attachment_link?.base_url}${item?.attachments[4].attachment_link?.attachment_url}`
                                                          )
                                                        }
                                                      >
                                                        <i
                                                          className="fa fa-plus"
                                                          aria-hidden="true"
                                                        ></i>
                                                        <span className="img_count_no">
                                                          {item?.attachments
                                                            ?.length - 4}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  )}
                                </Fragment>
                              )}

                            {/* shared post block*/}
                            {item?.shared_post && (
                              <Fragment>
                                {/* inner form start */}
                                <div className="card card--block modal_box inner">
                                  <div className="feed-user-info home_page mb-3">
                                    <div
                                      className="user__pic mr-3"
                                      onClick={() =>
                                        history.push(
                                          `/user-profile/${item?.shared_post?.subject_id?.user_id}`
                                        )
                                      }
                                    >
                                      {item?.shared_post?.subject_id
                                        ?.avatar_id ? (
                                        <img
                                          src={`${item?.shared_post?.subject_id?.avatar_id?.base_url}${item?.shared_post?.subject_id?.avatar_id?.attachment_medium_url}`}
                                        />
                                      ) : (
                                        <img src={userProfile} />
                                      )}
                                    </div>
                                    <div className="user__detail-data">
                                      <span
                                        className="user__name"
                                        onClick={() =>
                                          history.push(
                                            `/user-profile/${item.shared_post?.subject_id?.user_id}`
                                          )
                                        }
                                      >
                                        <strong>
                                          {(item?.shared_post?.subject_id
                                            ?.first_name !== null &&
                                            item?.shared_post?.subject_id
                                              ?.last_name !== null &&
                                            `${item?.shared_post?.subject_id?.first_name}  ${item?.shared_post?.subject_id?.last_name}`) ||
                                            item?.shared_post?.subject_id
                                              ?.company_name ||
                                            item?.shared_post?.subject_id
                                              ?.restaurant_name}
                                        </strong>
                                        <div className="flex">
                                          <MemberType
                                            item={item?.shared_post?.subject_id}
                                          />
                                          {item?.shared_post?.subject_id
                                            ?.role_id === 10 ? (
                                            ""
                                          ) : (
                                            <span className="text-blue">
                                              {" "}
                                              ,{" "}
                                              {
                                                item?.shared_post
                                                  ?.follower_count
                                              }{" "}
                                              {lang?.Followers}
                                            </span>
                                          )}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                  <p className="mb-3">
                                    {item?.shared_post?.body && Checkurl(item?.shared_post?.body)}
                                  </p>
                                  <div className="post_img">
                                    {item?.shared_post?.attachments?.length >
                                      0 && (
                                        <Fragment>
                                          {item?.shared_post?.attachments
                                            .length === 1 ? (
                                            <div className="slider__img post--img singlePost">
                                              {item?.shared_post?.attachments?.map(
                                                (el, index) => {
                                                  return (
                                                    <div
                                                      className="slider__item"
                                                      key={index}
                                                    >
                                                      <div key={index}>
                                                        <img
                                                          className="feed_img"
                                                          src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                          onClick={() =>
                                                            handlePhotos(
                                                              item?.shared_post,
                                                              `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          ) : (
                                            <div className="slider__img post--img ">
                                              {item?.shared_post?.attachments
                                                ?.slice(0, 4)
                                                ?.map((el, index) => {
                                                  return (
                                                    <div
                                                      className="slider__item"
                                                      key={index}
                                                    >
                                                      <div key={index}>
                                                        <img
                                                          className="feed_img"
                                                          src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                          onClick={() =>
                                                            handlePhotos(
                                                              item?.shared_post,
                                                              `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                            )
                                                          }
                                                        />
                                                        {item?.shared_post
                                                          ?.attachments.length >
                                                          4 && (
                                                            <div className="show_all_img">
                                                              <div
                                                                className="show_all_icon"
                                                                onClick={() =>
                                                                  handlePhotos(
                                                                    item?.shared_post,
                                                                    `${el?.attachment_link?.base_url}${item?.shared_post?.attachments[4].attachment_link?.attachment_url}`
                                                                  )
                                                                }
                                                              >
                                                                <i
                                                                  className="fa fa-plus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                                <span className="img_count_no">
                                                                  {item?.shared_post
                                                                    ?.attachments
                                                                    .length - 4}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          )}
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          )}
                                        </Fragment>
                                      )}
                                  </div>
                                  <div className="just__Now mt-3">
                                    {" "}
                                    <p>
                                      {" "}
                                      {item.shared_post &&
                                        item.shared_post.posted_at}
                                    </p>
                                  </div>
                                </div>
                                {/* inner form end */}
                              </Fragment>
                            )}
                            {item.shared_post_deleted && (
                              <div>
                                <h4>{lang?.deletedSharePostHeading}</h4>
                                {lang?.deletedSharePostMessage}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* post comments block */}
                        <div className="user__comment-feed">
                          <div className="cmnt-tab">
                            {/* <div className="cmnt-tab-left"><p>Just Now</p> </div> */}
                            <div className="cmnt-tab-right flex justify-between items-center">
                              <span className="time_posted">
                                {" "}
                                {item?.posted_at}
                              </span>
                              <ul className="flex items-center">
                                <li>
                                  <Link
                                    to="#"
                                    className="flex items-center items-center"
                                    onClick={() => handleLike(item)}
                                  >
                                    {item?.like_flag ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="18"
                                        viewBox="0 0 20 17.648"
                                      >
                                        <path
                                          id="like_icon_active"
                                          d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                          transform="translate(-5 -9)"
                                          fill="#ff0046"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 20.653 18.099"
                                      >
                                        <path
                                          id="icons8_heart"
                                          d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                          transform="translate(-2 -7)"
                                        />
                                      </svg>
                                    )}
                                    <span className="noCount">
                                      {item?.like_count}
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    className="flex items-center items-center"
                                    onClick={() => handlePostComment(item)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 20.653 18.857"
                                    >
                                      <path
                                        id="icons8_comments"
                                        d="M4.245,4A2.254,2.254,0,0,0,2,6.245V17.02a2.254,2.254,0,0,0,2.245,2.245H6.083A3.476,3.476,0,0,1,6,20.724a2.31,2.31,0,0,1-1.08,1.3.449.449,0,0,0,.224.828,5.571,5.571,0,0,0,5.191-3.592H20.408a2.254,2.254,0,0,0,2.245-2.245V6.245A2.254,2.254,0,0,0,20.408,4Zm0,.9H20.408a1.347,1.347,0,0,1,1.347,1.347V17.02a1.347,1.347,0,0,1-1.347,1.347H10.082a.449.449,0,0,0-.421.295A4.638,4.638,0,0,1,6.42,21.8a3.144,3.144,0,0,0,.435-.8,4.613,4.613,0,0,0,.07-2.273.45.45,0,0,0-.435-.365H4.245A1.347,1.347,0,0,1,2.9,17.02V6.245A1.347,1.347,0,0,1,4.245,4.9Z"
                                        transform="translate(-2 -4)"
                                      />
                                    </svg>
                                    {item?.comment_count > 0 ? (
                                      <span className="noCount">
                                        {" "}
                                        {item?.comment_count}
                                      </span>
                                    ) : (
                                      <span className="noCount"> {0} </span>
                                    )}
                                  </Link>
                                </li>
                                {item?.shared_post_id === 0 && (
                                  <li>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        setShareModal(!shareModal);
                                        setSharePostItem(item);
                                        setprivacy(item?.privacy);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 18.857 15.283"
                                      >
                                        <path
                                          id="icons8_forward_arrow"
                                          d="M15.168,11a.448.448,0,0,0-.393.449v3.171c-5.169.158-7.955,2.894-9.33,5.612A13.958,13.958,0,0,0,4,25.756v.056a.449.449,0,1,0,.9.028v-.028a5.221,5.221,0,0,1,1.389-2.848c1.263-1.419,3.727-2.85,8.489-2.946v3.1a.449.449,0,0,0,.73.351l7.184-5.837a.45.45,0,0,0,0-.7L15.5,11.095A.45.45,0,0,0,15.168,11Zm.505,1.389,6.019,4.9-6.019,4.9V19.527a.45.45,0,0,0-.449-.449c-5.277,0-8.122,1.622-9.611,3.3-.056.063-.088.119-.14.182a12.281,12.281,0,0,1,.772-1.936c1.31-2.589,3.811-5.135,8.98-5.135a.45.45,0,0,0,.449-.449Z"
                                          transform="translate(-4 -10.993)"
                                        />
                                      </svg>
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            </div>
                            {/* .........................post comments................... */}
                            {commentBox[item?.activity_action_id] && (
                              <div className="user__comment-feed--list">
                                <div className="feed-cmnt-form add_cmnt">
                                  <div className="user_pic">
                                    {profileData?.data?.user_data?.avatar_id ? (
                                      <img
                                        src={`${profileData?.data?.user_data?.avatar_id.base_url}${profileData?.data?.user_data?.avatar_id.attachment_medium_url}`}
                                      />
                                    ) : (
                                      <img src={userProfile} />
                                    )}
                                  </div>
                                  <form
                                    className="cmnt-form replay_cmnt flex justify-between items-center"
                                    onSubmit={(event) =>
                                      submitCommentPost(event, item)
                                    }
                                  >
                                    <input
                                      className="cmnt-write"
                                      placeholder={lang?.write_comments}
                                      defaultValue={""}
                                      value={commentVal}
                                      onChange={(event) => {
                                        handleChange(event);
                                        setWriteComment(true);
                                      }}
                                    />
                                    {isWriteComment &&
                                      commentVal?.length > 0 && (
                                        <div className="btnPost 4">
                                          {" "}
                                          <div
                                            type="submit"
                                            className="btn"
                                            onClick={(event) =>
                                              submitCommentPost(event, item)
                                            }
                                          >
                                            <i
                                              className="fa fa-paper-plane"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </div>
                                      )}
                                    <div className="post-icons-option">
                                      {" "}
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          handleEmoji(item.activity_action_id);
                                          setWriteComment(true);
                                        }}
                                      >
                                        <i className="far fa-smile" />{" "}
                                      </Link>
                                    </div>
                                  </form>
                                </div>
                                <div>
                                  {isEmoji[item.activity_action_id] && (
                                    <Picker onEmojiClick={onEmojiClick} />
                                  )}{" "}
                                </div>

                                {/* ...........post comments........... */}
                                {item?.comment?.map((comm, idx) => {
                                  commentLike?.map(async (like) => {
                                    if (
                                      like?.core_comment_id ==
                                      comm?.core_comment_id &&
                                      like?.user_id ==
                                      profileData?.data?.user_data?.user_id
                                    ) {
                                      comm.isCommentLike = 1;
                                      comm.likeCommentId =
                                        like?.core_comment_id;
                                      return;
                                    } else if (
                                      like?.core_comment_id !=
                                      comm?.core_comment_id &&
                                      like?.user_id !=
                                      profileData?.data?.user_data?.user_id
                                    ) {
                                      comm.isCommentLike = 0;
                                      return;
                                    }
                                  });
                                  return (
                                    <Fragment key={idx}>
                                      <div className="feed-cmnt-form add_cmnt show_cmnt mt-2 mb-3 3">
                                        <div className="user_pic">
                                          {comm &&
                                            comm.poster &&
                                            comm.poster.avatar_id ? (
                                            <img
                                              src={comm.poster.avatar_id?.attachment_url}
                                              alt={comm.poster?.user_id}
                                            />
                                          ) : (
                                            <img src={userProfile} />
                                          )}
                                        </div>
                                        {/* comment message */}
                                        <div className="bgGrayBlock">
                                          <div className="cmnt-box p-2 flex justify-between items-center relative 1">
                                            {isUpdateComment &&
                                              commentId ===
                                              comm.core_comment_id ? (
                                              <Fragment>
                                                <form className="cmnt-form replay_cmnt flex justify-between items-center">
                                                  <input
                                                    className="cmnt-write"
                                                    name="updateComment"
                                                    defaultValue={
                                                      comm && comm.body
                                                    }
                                                    value={updateCommentVal}
                                                    onChange={(event) => {
                                                      handleChange(event);
                                                      setWriteUpdateComment(
                                                        true
                                                      );
                                                    }}
                                                  />
                                                  <div className="post-icons-option">
                                                    {" "}
                                                    <Link
                                                      to="#"
                                                      onClick={() => {
                                                        handleUpdateCommentEmoji(
                                                          comm?.id
                                                        );
                                                        setWriteUpdateComment(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      {" "}
                                                      <i className="far fa-smile" />{" "}
                                                    </Link>{" "}
                                                  </div>
                                                </form>
                                              </Fragment>
                                            ) : (
                                              <p>{comm && comm.body}</p>
                                            )}
                                            {/* emoji for update comment.. */}
                                            <div>
                                              {" "}
                                              {isUpdateCommentEmoji[comm?.id] &&
                                                commentId ===
                                                comm.core_comment_id && (
                                                  <Picker
                                                    onEmojiClick={
                                                      updateCommentEmoji
                                                    }
                                                  />
                                                )}
                                            </div>

                                            {/* save update comment */}
                                            {/* {isWriteUpdateComment && updateCommentVal?.length > 0 && commentId === comm.core_comment_id &&
                                            <div className="btnPost 5">
                                              <button type="button" className="btn" onClick={(event) => updatePostComment(event, item, comm)}>{lang?.Save_Changes}</button>
                                              <button type="button" className="btn" onClick={() => { setUpdateComment(false); setWriteUpdateComment(false); }}>{lang?.Cancel}</button>
                                            </div>} */}
                                            {/* dots icon for comment */}
                                            {profileData?.data?.user_data
                                              ?.user_id ===
                                              comm?.poster?.user_id && (
                                                <div
                                                  className="about_profile"
                                                  onClick={() => {
                                                    setCommentDotClick(
                                                      !commentDotClick
                                                    );
                                                    setCommentId(
                                                      comm?.core_comment_id
                                                    );
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-ellipsis-v"
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                              )}
                                            {/* ..............edit and remove popup................. */}
                                            {commentDotClick &&
                                              commentId ===
                                              comm?.core_comment_id && (
                                                <div className="postView editPostCmnt">
                                                  <ul>
                                                    <li>
                                                      {" "}
                                                      <Link
                                                        to="#"
                                                        onClick={() => {
                                                          setUpdateComment(
                                                            true
                                                          );
                                                          setCommentDotClick(
                                                            !commentDotClick
                                                          );
                                                          setUpdateCommentVal(
                                                            commentVal
                                                          );
                                                        }}
                                                      >
                                                        {" "}
                                                        <span>
                                                          {" "}
                                                          <i
                                                            className="fa fa-pencil"
                                                            aria-hidden="true"
                                                          ></i>{" "}
                                                        </span>{" "}
                                                        {lang?.Edit}{" "}
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      {" "}
                                                      <Link
                                                        to="#"
                                                        onClick={() =>
                                                          removeComment(
                                                            item,
                                                            comm
                                                          )
                                                        }
                                                      >
                                                        <span>
                                                          {" "}
                                                          <i
                                                            className="fa fa-trash"
                                                            aria-hidden="true"
                                                          ></i>{" "}
                                                        </span>{" "}
                                                        {lang?.Remove_comment}{" "}
                                                      </Link>
                                                    </li>
                                                  </ul>
                                                </div>
                                              )}
                                          </div>

                                          {/* save update comment */}
                                          {isWriteUpdateComment &&
                                            updateCommentVal?.length > 0 &&
                                            commentId ===
                                            comm.core_comment_id && (
                                              <div className="btnPost 5">
                                                <button
                                                  type="button"
                                                  className="btn"
                                                  onClick={(event) =>
                                                    updatePostComment(
                                                      event,
                                                      item,
                                                      comm
                                                    )
                                                  }
                                                >
                                                  {lang?.Save_Changes}
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn"
                                                  onClick={() => {
                                                    setUpdateComment(false);
                                                    setWriteUpdateComment(
                                                      false
                                                    );
                                                  }}
                                                >
                                                  {lang?.Cancel}
                                                </button>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                      {/* like and unlike comment  */}
                                      <div className="cmnt_btn flex items-center items-center cmntReply">
                                        <Link
                                          to="#"
                                          className="Like flex items-center"
                                          onClick={() =>
                                            handleCommentLike(item, comm)
                                          }
                                        >
                                          {comm.isCommentLike &&
                                            comm.likeCommentId ===
                                            comm.core_comment_id ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14"
                                              height="18"
                                              viewBox="0 0 20 17.648"
                                            >
                                              <path
                                                id="like_icon_active"
                                                d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                                transform="translate(-5 -9)"
                                                fill="#ff0046"
                                              />
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14"
                                              height="14"
                                              viewBox="0 0 20.653 18.099"
                                            >
                                              <path
                                                id="icons8_heart"
                                                d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                                transform="translate(-2 -7)"
                                              />
                                            </svg>
                                          )}
                                          <span className="noCount">
                                            {comm.comment_like_count}
                                          </span>
                                        </Link>
                                        <Link
                                          to="#"
                                          className="Reply"
                                          onClick={() => {
                                            setWriteReply(!isWriteReply);
                                            setReplytId(comm.core_comment_id);
                                          }}
                                        >
                                          {lang?.Reply}
                                        </Link>

                                        {/* comment reply message.... */}
                                        {comm?.ReplyDetails &&
                                          Object.values(comm?.ReplyDetails)
                                            ?.length && (
                                            <Link
                                              to="#"
                                              className="viewReply"
                                              onClick={() => {
                                                setViewReply(!isViewReply);
                                                setReplytId(
                                                  comm.core_comment_id
                                                );
                                              }}
                                            >
                                              {isViewReply &&
                                                replytId === comm.core_comment_id
                                                ? "Hide"
                                                : `View`}{" "}
                                              {`${comm?.ReplyDetails &&
                                                Object.values(
                                                  comm?.ReplyDetails
                                                )?.length
                                                }`}{" "}
                                              Reply
                                            </Link>
                                          )}
                                      </div>

                                      {/* reply comment */}

                                      {isViewReply &&
                                        replytId === comm.core_comment_id &&
                                        comm?.ReplyDetails &&
                                        Object.values(comm.ReplyDetails).map(
                                          (commReply, replyIdx) => {
                                            commentLike?.map(async (like) => {
                                              if (
                                                like?.core_comment_id ==
                                                commReply?.core_comment_id &&
                                                like?.user_id ==
                                                profileData?.data?.user_data
                                                  ?.user_id
                                              ) {
                                                commReply.isCommentLike = 1;
                                                commReply.likeCommentId =
                                                  like?.core_comment_id;
                                                return;
                                              } else if (
                                                like?.core_comment_id !=
                                                commReply?.core_comment_id &&
                                                like?.user_id !=
                                                profileData?.data?.user_data
                                                  ?.user_id
                                              ) {
                                                commReply.isCommentLike = 0;
                                                return;
                                              }
                                            });
                                            return (
                                              <div
                                                className="inner__cmnt"
                                                key={replyIdx}
                                              >
                                                <div className="feed-cmnt-form add_cmnt show_cmnt mt-3 mb-4 4">
                                                  <div className="user_pic">
                                                    {commReply?.poster
                                                      ?.avatar_id ? (
                                                      <img
                                                        src={`${commReply.poster?.avatar_id.attachment_url}`}
                                                      />
                                                    ) : (
                                                      <img src={userProfile} />
                                                    )}
                                                  </div>
                                                  <div className="bgGrayBlock">
                                                    <div className="cmnt-box flex justify-between items-center">
                                                      {isUpdateReplyComment &&
                                                        replyCommentId ===
                                                        commReply.core_comment_id ? (
                                                        <form
                                                          className="cmnt-form replay_cmnt flex justify-between items-center"
                                                          onSubmit={(event) =>
                                                            updatePostCommentReply(
                                                              event,
                                                              item,
                                                              comm,
                                                              commReply
                                                            )
                                                          }
                                                        >
                                                          <input
                                                            className="cmnt-write"
                                                            defaultValue={
                                                              commReply?.body
                                                            }
                                                            value={
                                                              updateReplyCommentVal
                                                            }
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              setUpdateReplyCommentVal(
                                                                event.target
                                                                  .value
                                                              );
                                                              setWriteUpdateReplyComment(
                                                                true
                                                              );
                                                            }}
                                                          />
                                                          <div className="post-icons-option">
                                                            {" "}
                                                            <Link
                                                              to="#"
                                                              onClick={() =>
                                                                handleUpdateReplyEmoji(
                                                                  commReply?.id
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <i className="far fa-smile" />{" "}
                                                            </Link>{" "}
                                                          </div>
                                                        </form>
                                                      ) : (
                                                        <p>{commReply.body}</p>
                                                      )}
                                                      {/* update reply emoji */}
                                                      <div>
                                                        {" "}
                                                        {updateReplyEmoji[
                                                          commReply?.id
                                                        ] &&
                                                          replyCommentId ===
                                                          commReply.core_comment_id && (
                                                            <Picker
                                                              onEmojiClick={
                                                                updateReplyCommentEmoji
                                                              }
                                                            />
                                                          )}
                                                      </div>

                                                      {/* dots icon for comment reply */}
                                                      {profileData?.data
                                                        ?.user_data?.user_id ===
                                                        commReply?.poster
                                                          ?.user_id && (
                                                          <div
                                                            className="about_profile"
                                                            onClick={() => {
                                                              setRepyCommentDotClick(
                                                                !repyCommentDotClick
                                                              );
                                                              setReplyCommentId(
                                                                commReply?.core_comment_id
                                                              );
                                                            }}
                                                          >
                                                            <i
                                                              className="fa fa-ellipsis-v"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </div>
                                                        )}
                                                    </div>
                                                    {/* save update reply comment */}
                                                    {
                                                      isWriteUpdateReplyComment &&
                                                      updateReplyCommentVal?.trim()
                                                        ?.length > 0 &&
                                                      replyCommentId ===
                                                      commReply.core_comment_id && (
                                                        <div className="btnPost 5">
                                                          <button
                                                            type="button"
                                                            className="btn"
                                                            onClick={(
                                                              event
                                                            ) =>
                                                              updatePostCommentReply(
                                                                event,
                                                                item,
                                                                comm,
                                                                commReply
                                                              )
                                                            }
                                                          >
                                                            {
                                                              lang?.Save_Changes
                                                            }
                                                          </button>
                                                          <button
                                                            type="button"
                                                            className="btn"
                                                            onClick={() => {
                                                              setUpdateReplyComment(
                                                                false
                                                              );
                                                              setWriteUpdateReplyComment(
                                                                false
                                                              );
                                                            }}
                                                          >
                                                            {lang?.Cancel}
                                                          </button>
                                                        </div>
                                                      )
                                                      //   <div className="btnPost pl-4">
                                                      //     {/* <button type="button" className="btn" onClick={(event) => updatePostCommentReply(event, item, comm, commReply)}>Post</button> */}
                                                      //     {/*<button type="button" className="btn" onClick={() => updatePostCommentReply(item, comm, commReply)}>{lang?.Save_Changes}</button>
                                                      // <button type="button" className="btn" onClick={() => { setUpdateReplyComment(false); setWriteUpdateReplyComment(false); }}>{lang?.Cancel}</button>*/}
                                                      //   </div>
                                                    }
                                                  </div>
                                                </div>
                                                {/* ..............edit and remove popup reply comment................. */}
                                                {repyCommentDotClick &&
                                                  replyCommentId ===
                                                  commReply?.core_comment_id && (
                                                    <div className="postView 2">
                                                      <ul>
                                                        <li>
                                                          {" "}
                                                          <Link
                                                            to="#"
                                                            onClick={() => {
                                                              setUpdateReplyComment(
                                                                true
                                                              );
                                                              setRepyCommentDotClick(
                                                                !repyCommentDotClick
                                                              );
                                                            }}
                                                          >
                                                            {" "}
                                                            <span>
                                                              {" "}
                                                              <i
                                                                className="fa fa-pencil"
                                                                aria-hidden="true"
                                                              ></i>{" "}
                                                            </span>{" "}
                                                            {lang?.Edit}{" "}
                                                          </Link>
                                                        </li>
                                                        <li>
                                                          {" "}
                                                          <Link
                                                            to="#"
                                                            onClick={() =>
                                                              removeReplyComment(
                                                                item,
                                                                comm,
                                                                commReply
                                                              )
                                                            }
                                                          >
                                                            <span>
                                                              {" "}
                                                              <i
                                                                className="fa fa-trash"
                                                                aria-hidden="true"
                                                              ></i>{" "}
                                                            </span>{" "}
                                                            {
                                                              lang?.Remove_comment
                                                            }{" "}
                                                          </Link>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  )}
                                                {/* like and unlike comment reply */}
                                                <div className="replayInnerLike">
                                                  <Link
                                                    to="#"
                                                    className="flex items-center"
                                                    onClick={() =>
                                                      handleCommentReplyLike(
                                                        item,
                                                        comm,
                                                        commReply
                                                      )
                                                    }
                                                  >
                                                    {commReply.isCommentLike &&
                                                      commReply.likeCommentId ===
                                                      commReply.core_comment_id ? (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="18"
                                                        viewBox="0 0 20 17.648"
                                                      >
                                                        <path
                                                          id="like_icon_active"
                                                          d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                                          transform="translate(-5 -9)"
                                                          fill="#ff0046"
                                                        />
                                                      </svg>
                                                    ) : (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 20.653 18.099"
                                                      >
                                                        <path
                                                          id="icons8_heart"
                                                          d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                                          transform="translate(-2 -7)"
                                                        />
                                                      </svg>
                                                    )}
                                                    <span className="noCount">
                                                      {
                                                        commReply.comment_like_count
                                                      }
                                                    </span>
                                                  </Link>
                                                  {/* <Link to="#" > View all </Link> */}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}

                                      {/* write reply message.... */}
                                      {isWriteReply &&
                                        replytId === comm.core_comment_id && (
                                          <div className="inner__cmnt">
                                            <div className="feed-cmnt-form add_cmnt">
                                              <div className="user_pic">
                                                {profileData?.data
                                                  ?.user_data ? (
                                                  <img
                                                    src={`${profileData?.data?.user_data?.avatar_id.base_url}${profileData?.data?.user_data?.avatar_id.attachment_large_url}`}
                                                  />
                                                ) : (
                                                  <img src={userProfile} />
                                                )}
                                              </div>
                                              <form
                                                className="cmnt-form replay_cmnt flex justify-between"
                                                onSubmit={(event) =>
                                                  event.preventDefault()
                                                }
                                              >
                                                <input
                                                  className="cmnt-write"
                                                  placeholder={
                                                    lang?.write_reply
                                                  }
                                                  defaultValue={""}
                                                  value={commentReply}
                                                  onChange={(event) => {
                                                    setCommentReply(
                                                      event.target.value
                                                    );
                                                    setReplyComment(true);
                                                  }}
                                                />
                                                {commentReply?.trim()?.length >
                                                  0 && (
                                                    <div className="btnPost 6">
                                                      {" "}
                                                      <div
                                                        type="submit"
                                                        className="btn"
                                                        onClick={(event) =>
                                                          submitCommentReply(
                                                            event,
                                                            item
                                                          )
                                                        }
                                                      >
                                                        <i
                                                          className="fa fa-paper-plane"
                                                          aria-hidden="true"
                                                        ></i>
                                                      </div>{" "}
                                                    </div>
                                                  )}
                                                <div className="post-icons-option">
                                                  {" "}
                                                  <Link
                                                    to="#"
                                                    onClick={() =>
                                                      handleReplyEmoji(comm?.id)
                                                    }
                                                  >
                                                    {" "}
                                                    <i className="far fa-smile" />{" "}
                                                  </Link>{" "}
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        )}
                                      <div>
                                        {" "}
                                        {replyEmoji[comm?.id] &&
                                          replytId === comm.core_comment_id && (
                                            <Picker
                                              onEmojiClick={replyCommentEmoji}
                                            />
                                          )}
                                      </div>
                                    </Fragment>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div ref={myRef}> </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="card card--block" key={index}>
                      <div className="feed-listing">
                        <div className="feed-user-detail">
                          <div className="feed-user-info">
                            <Link
                              to={
                                profileData?.data?.user_data?.user_id ===
                                  item?.subject_id?.user_id
                                  ? "profile"
                                  : `/user-profile/${item &&
                                  item.subject_id &&
                                  item.subject_id.user_id
                                  }`
                              }
                            >
                              <div className="user__pic">
                                {item?.subject_id &&
                                  item?.subject_id?.avatar_id ? (
                                  <img
                                    src={`${item?.subject_id?.avatar_id?.base_url}${item?.subject_id?.avatar_id?.attachment_medium_url}`}
                                  />
                                ) : (
                                  <img src={userProfile} />
                                )}
                              </div>
                            </Link>
                            <div className="user__detail-data">
                              <span className="user__name">
                                <Link
                                  to={
                                    profileData?.data?.user_data?.user_id ===
                                      item?.subject_id?.user_id
                                      ? "profile"
                                      : `/user-profile/${item &&
                                      item?.subject_id &&
                                      item?.subject_id?.user_id
                                      }`
                                  }
                                >
                                  {(item?.subject_id.first_name !== null &&
                                    item?.subject_id?.last_name !== null &&
                                    `${item?.subject_id?.first_name} ${item?.subject_id?.last_name}`) ||
                                    item?.subject_id?.company_name ||
                                    item?.subject_id?.restaurant_name}
                                </Link>
                              </span>
                              <div className="flex">
                                <MemberType item={item?.subject_id} />
                                {item?.subject_id?.role_id === 10 ? (
                                  ""
                                ) : (
                                  <span className="text-blue">
                                    {" "}
                                    , {item?.follower_count} {lang?.Followers}
                                  </span>
                                )}
                              </div>
                            </div>
                            {/* <div className="about_profile" >
                          <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={() => { setDotClick(!dotClick); setpostId(item.activity_action_id); }}></i>
                        </div> */}
                            <div
                              className="about_profile"
                              onClick={() => {
                                setDotClick(!dotClick);
                                setpostId(item?.activity_action_id);
                                setSharePostOnMedia(false)
                              }}
                              ref={socialRef}
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                          {/* action dots block */}
                          {dotClick && postId === item.activity_action_id && (
                            <div className="postView 3">
                              {profileData?.data?.user_data?.user_id ===
                                item.subject_id.user_id ? (
                                <ul>
                                  <li>
                                    {" "}
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        handleEditPost(item.activity_action_id);
                                      }}
                                    >
                                      <span>
                                        {" "}
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                        ></i>{" "}
                                      </span>{" "}
                                      {lang?.EditPost}
                                    </Link>{" "}
                                  </li>
                                  <li
                                    onClick={() => {
                                      setDeletePopup(true);
                                      setpostId(item?.activity_action_id);
                                      setDotClick(false);
                                      setSharePostOnMedia(false)
                                    }}>
                                    <Link to="#">
                                      <span>
                                        {" "}
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>{" "}
                                      </span>{" "}
                                      {lang?.Delete} Post{" "}
                                    </Link>
                                  </li>
                                  {item?.shared_post_id === 0 &&
                                    <li className=""
                                      onClick={() => { setSharePosturl(item?.privacy === "Public" ? `${window.location.origin}/home/public/post/activity/${item?.activity_action_id}` : `${window.location.origin}/home/post/activity/${item?.activity_action_id}`); setSharePostOnMedia(true) }}
                                    >
                                      <Link to="#">
                                        {" "}
                                        <span>
                                          {" "}
                                          <i
                                            className="fa fa-share"
                                            aria-hidden="true"
                                          ></i>
                                        </span>{" "}
                                        {lang?.Share_Post}{" "}
                                      </Link>
                                    </li>
                                  }

                                </ul>
                              ) : (
                                <ul>
                                  <li
                                    onClick={() =>
                                      repostPost(
                                        item.activity_action_id,
                                        item.subject_id.user_id
                                      )
                                    }
                                  >
                                    {" "}
                                    <Link to="#">
                                      {" "}
                                      <span>
                                        {" "}
                                        <i
                                          className="fa fa-flag"
                                          aria-hidden="true"
                                        ></i>{" "}
                                      </span>{" "}
                                      {lang?.Report_Post}
                                    </Link>
                                  </li>
                                  {item?.shared_post_id === 0 &&
                                    <li className=""
                                      onClick={() => { setSharePosturl(item?.privacy === "Public" ? `${window.location.origin}/home/public/post/activity/${item?.activity_action_id}` : `${window.location.origin}/home/post/activity/${item?.activity_action_id}`); setSharePostOnMedia(true) }}
                                    >
                                      <Link to="#">
                                        {" "}
                                        <span>
                                          {" "}
                                          <i
                                            className="fa fa-share"
                                            aria-hidden="true"
                                          ></i>
                                        </span>{" "}
                                        {lang?.Share_Post}{" "}
                                      </Link>
                                    </li>
                                  }
                                </ul>
                              )}
                            </div>
                          )}

                          {/* share social post facebook,linkdin,twitter icon start*/}
                          {(postId === item.activity_action_id && sharePostOnMedia) &&
                            <div className="postView shareBlock">
                              <ul>
                                <li>
                                  <FacebookShareButton url={item?.directLink}  >
                                    <FacebookIcon size={40} round={true} />
                                    <span>Share on Facebook</span>
                                  </FacebookShareButton>
                                  {/* <WhatsappShareButton url={`${sharePosturl}`}  >
                                    <WhatsappIcon size={40} round={true} />
                                </WhatsappShareButton>              */}
                                </li>
                                <li>
                                  <LinkedinShareButton url={item?.directLink} >
                                    <LinkedinIcon size={40} round={true} />
                                    <span>Share on LinkedIn</span>
                                  </LinkedinShareButton>

                                </li>
                                <li>
                                  <TwitterShareButton url={item?.directLink} >
                                    <TwitterIcon size={40} round={true} />
                                    <span>Share on Twitter</span>
                                  </TwitterShareButton>

                                </li>
                                <li onClick={() => postCopyLink(item.activity_action_id, item?.directLink)}>
                                  <div className="iconWidth">
                                    <i className="fa fa-link" aria-hidden="true"></i>
                                  </div>
                                  <span id={`copyLink_${item.activity_action_id}`}>Copy Link</span>
                                </li>
                              </ul>
                            </div>
                          }
                          {/* share social post facebook,linkdin,twitter icon start*/}
                          <div className="user__feed user__feed-text">
                            <p>{item?.body && Checkurl(item?.body)}</p>
                            {item?.attachments?.length > 0 &&
                              item?.shared_post_id === 0 && (
                                <Fragment>
                                  {item?.attachments?.length === 1 ? (
                                    <div className="slider__img post--img singlePost">
                                      {item?.attachments?.map((el, index) => {
                                        return (
                                          <div
                                            className="slider__item"
                                            key={index}
                                          >
                                            <div key={index}>
                                              <img
                                                className="feed_img"
                                                src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                onClick={() =>
                                                  handlePhotos(
                                                    item,
                                                    `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <div className="slider__img post--img ">
                                      {item?.attachments
                                        ?.slice(0, 4)
                                        ?.map((el, index) => {
                                          return (
                                            <div
                                              className="slider__item"
                                              key={index}
                                            >
                                              <div key={index}>
                                                <img
                                                  className="feed_img"
                                                  src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                  onClick={() =>
                                                    handlePhotos(
                                                      item,
                                                      `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                    )
                                                  }
                                                />
                                                {item?.attachments?.length >
                                                  4 && (
                                                    <div className="show_all_img">
                                                      <div
                                                        className="show_all_icon"
                                                        onClick={() =>
                                                          handlePhotos(
                                                            item,
                                                            `${el?.attachment_link?.base_url}${item?.attachments[4].attachment_link?.attachment_url}`
                                                          )
                                                        }
                                                      >
                                                        {" "}
                                                        <i
                                                          className="fa fa-plus"
                                                          aria-hidden="true"
                                                        ></i>
                                                        <span className="img_count_no">
                                                          {item?.attachments
                                                            ?.length - 4}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  )}
                                </Fragment>
                              )}
                            {/* shared post block*/}
                            {item?.shared_post && (
                              <Fragment>
                                {/* inner form start */}
                                <div className="card card--block modal_box inner">
                                  <div className="feed-user-info home_page mb-3">
                                    <div
                                      className="user__pic mr-3"
                                      onClick={() =>
                                        history.push(
                                          `/user-profile/${item?.shared_post?.subject_id?.user_id}`
                                        )
                                      }
                                    >
                                      {item?.shared_post?.subject_id
                                        ?.avatar_id ? (
                                        <img
                                          src={`${item?.shared_post?.subject_id?.avatar_id?.base_url}${item?.shared_post?.subject_id?.avatar_id?.attachment_medium_url}`}
                                        />
                                      ) : (
                                        <img src={userProfile} />
                                      )}
                                    </div>
                                    <div className="user__detail-data">
                                      <span
                                        className="user__name"
                                        onClick={() =>
                                          history.push(
                                            `/user-profile/${item.shared_post?.subject_id?.user_id}`
                                          )
                                        }
                                      >
                                        <strong>
                                          {(item?.shared_post?.subject_id
                                            ?.first_name !== null &&
                                            item?.shared_post?.subject_id
                                              ?.last_name !== null &&
                                            `${item?.shared_post?.subject_id?.first_name}  ${item?.shared_post?.subject_id?.last_name}`) ||
                                            item?.shared_post?.subject_id
                                              ?.company_name ||
                                            item?.shared_post?.subject_id
                                              ?.restaurant_name}
                                        </strong>
                                        <div className="flex">
                                          <MemberType
                                            item={item?.shared_post?.subject_id}
                                          />
                                          {item?.shared_post?.subject_id
                                            ?.role_id === 10 ? (
                                            ""
                                          ) : (
                                            <span className="text-blue">
                                              {" "}
                                              ,{" "}
                                              {
                                                item?.shared_post
                                                  ?.follower_count
                                              }{" "}
                                              {lang?.Followers}
                                            </span>
                                          )}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                  <p className="mb-3">
                                    {item?.shared_post?.body && Checkurl(item?.shared_post?.body)}
                                    {/* {item?.shared_post?.body} */}
                                  </p>
                                  <div className="post_img">
                                    {item?.shared_post?.attachments?.length >
                                      0 && (
                                        <Fragment>
                                          {item?.shared_post?.attachments
                                            .length === 1 ? (
                                            <div className="slider__img post--img singlePost">
                                              {item?.shared_post?.attachments?.map(
                                                (el, index) => {
                                                  return (
                                                    <div
                                                      className="slider__item"
                                                      key={index}
                                                    >
                                                      <div key={index}>
                                                        <img
                                                          className="feed_img"
                                                          src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                          onClick={() =>
                                                            handlePhotos(
                                                              item?.shared_post,
                                                              `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          ) : (
                                            <div className="slider__img post--img ">
                                              {item?.shared_post?.attachments
                                                ?.slice(0, 4)
                                                ?.map((el, index) => {
                                                  return (
                                                    <div
                                                      className="slider__item"
                                                      key={index}
                                                    >
                                                      <div key={index}>
                                                        <img
                                                          className="feed_img"
                                                          src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                          onClick={() =>
                                                            handlePhotos(
                                                              item?.shared_post,
                                                              `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                            )
                                                          }
                                                        />
                                                        {item?.shared_post
                                                          ?.attachments.length >
                                                          4 && (
                                                            <div className="show_all_img">
                                                              <div
                                                                className="show_all_icon"
                                                                onClick={() =>
                                                                  handlePhotos(
                                                                    item?.shared_post,
                                                                    `${el?.attachment_link?.base_url}${item?.shared_post?.attachments[4].attachment_link?.attachment_url}`
                                                                  )
                                                                }
                                                              >
                                                                <i
                                                                  className="fa fa-plus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                                <span className="img_count_no">
                                                                  {item?.shared_post
                                                                    ?.attachments
                                                                    .length - 4}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          )}
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          )}
                                        </Fragment>
                                      )}
                                  </div>
                                  <div className="just__Now mt-3">
                                    {" "}
                                    <p>
                                      {" "}
                                      {item?.shared_post &&
                                        item.shared_post.posted_at}
                                    </p>
                                  </div>
                                </div>
                                {/* inner form end */}
                              </Fragment>
                            )}
                            {item?.shared_post_deleted && (
                              <div className="deleted-post">
                                <h4 className="font-16 font-bold text-black">
                                  {lang?.deletedSharePostHeading}
                                </h4>
                                <p className="font-semi-bold">
                                  {lang?.deletedSharePostMessage}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* post comments block */}
                        <div className="user__comment-feed">
                          <div className="cmnt-tab">
                            {/* <div className="cmnt-tab-left"><p>Just Now</p> </div> */}
                            <div className="cmnt-tab-right flex justify-between items-center">
                              <span className="time_posted">
                                {" "}
                                {item?.posted_at}
                              </span>
                              <ul className="flex items-center">
                                <li>
                                  <Link
                                    to="#"
                                    className="flex items-center items-center"
                                    onClick={() => handleLike(item)}
                                  >
                                    {item?.like_flag ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="18"
                                        viewBox="0 0 20 17.648"
                                      >
                                        <path
                                          id="like_icon_active"
                                          d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                          transform="translate(-5 -9)"
                                          fill="#ff0046"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 20.653 18.099"
                                      >
                                        <path
                                          id="icons8_heart"
                                          d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                          transform="translate(-2 -7)"
                                        />
                                      </svg>
                                    )}
                                    <span className="noCount">
                                      {item?.like_count}
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    className="flex items-center items-center"
                                    onClick={() => handlePostComment(item)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 20.653 18.857"
                                    >
                                      <path
                                        id="icons8_comments"
                                        d="M4.245,4A2.254,2.254,0,0,0,2,6.245V17.02a2.254,2.254,0,0,0,2.245,2.245H6.083A3.476,3.476,0,0,1,6,20.724a2.31,2.31,0,0,1-1.08,1.3.449.449,0,0,0,.224.828,5.571,5.571,0,0,0,5.191-3.592H20.408a2.254,2.254,0,0,0,2.245-2.245V6.245A2.254,2.254,0,0,0,20.408,4Zm0,.9H20.408a1.347,1.347,0,0,1,1.347,1.347V17.02a1.347,1.347,0,0,1-1.347,1.347H10.082a.449.449,0,0,0-.421.295A4.638,4.638,0,0,1,6.42,21.8a3.144,3.144,0,0,0,.435-.8,4.613,4.613,0,0,0,.07-2.273.45.45,0,0,0-.435-.365H4.245A1.347,1.347,0,0,1,2.9,17.02V6.245A1.347,1.347,0,0,1,4.245,4.9Z"
                                        transform="translate(-2 -4)"
                                      />
                                    </svg>
                                    {item?.comment_count > 0 ? (
                                      <span className="noCount">
                                        {item?.comment_count}
                                      </span>
                                    ) : (
                                      <span className="noCount"> {0}</span>
                                    )}
                                  </Link>
                                </li>
                                {profileData?.data?.user_data?.user_id !==
                                  item?.subject_id.user_id && (
                                    <li>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          setShareModal(!shareModal);
                                          setSharePostItem(item);
                                          setprivacy(item?.privacy);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 18.857 15.283"
                                        >
                                          <path
                                            id="icons8_forward_arrow"
                                            d="M15.168,11a.448.448,0,0,0-.393.449v3.171c-5.169.158-7.955,2.894-9.33,5.612A13.958,13.958,0,0,0,4,25.756v.056a.449.449,0,1,0,.9.028v-.028a5.221,5.221,0,0,1,1.389-2.848c1.263-1.419,3.727-2.85,8.489-2.946v3.1a.449.449,0,0,0,.73.351l7.184-5.837a.45.45,0,0,0,0-.7L15.5,11.095A.45.45,0,0,0,15.168,11Zm.505,1.389,6.019,4.9-6.019,4.9V19.527a.45.45,0,0,0-.449-.449c-5.277,0-8.122,1.622-9.611,3.3-.056.063-.088.119-.14.182a12.281,12.281,0,0,1,.772-1.936c1.31-2.589,3.811-5.135,8.98-5.135a.45.45,0,0,0,.449-.449Z"
                                            transform="translate(-4 -10.993)"
                                          />
                                        </svg>
                                      </Link>
                                    </li>
                                  )}
                              </ul>
                            </div>
                            {/* .........................post comments................... */}
                            {commentBox[item?.activity_action_id] && (
                              <div className="user__comment-feed--list">
                                <div className="feed-cmnt-form add_cmnt">
                                  <div className="user_pic">
                                    {profileData?.data?.user_data?.avatar_id ? (
                                      <img
                                        src={`${profileData?.data?.user_data?.avatar_id.base_url}${profileData?.data?.user_data?.avatar_id.attachment_medium_url}`}
                                      />
                                    ) : (
                                      <img alt="dummy" src={userProfile} />
                                    )}
                                  </div>
                                  <form
                                    className="cmnt-form replay_cmnt flex justify-between items-center"
                                    onSubmit={(event) =>
                                      submitCommentPost(event, item)
                                    }
                                  >
                                    <input
                                      className="cmnt-write"
                                      placeholder={lang?.write_comments}
                                      defaultValue={""}
                                      value={commentVal}
                                      onChange={(event) => {
                                        handleChange(event);
                                        setWriteComment(true);
                                      }}
                                    />
                                    {isWriteComment &&
                                      commentVal?.length > 0 && (
                                        <div className="btnPost 4">
                                          {" "}
                                          <div
                                            type="submit"
                                            className="btn"
                                            onClick={(event) =>
                                              submitCommentPost(event, item)
                                            }
                                          >
                                            <i
                                              className="fa fa-paper-plane"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </div>
                                      )}
                                    <div className="post-icons-option">
                                      {" "}
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          handleEmoji(item.activity_action_id);
                                          setWriteComment(true);
                                        }}
                                      >
                                        <i className="far fa-smile" />{" "}
                                      </Link>
                                    </div>
                                  </form>
                                </div>
                                <div>
                                  {isEmoji[item.activity_action_id] && (
                                    <Picker onEmojiClick={onEmojiClick} />
                                  )}{" "}
                                </div>

                                {/* ...........post comments........... */}
                                {item?.comment?.map((comm, idx) => {
                                  commentLike?.map(async (like) => {
                                    if (
                                      like?.core_comment_id ==
                                      comm?.core_comment_id &&
                                      like?.user_id ==
                                      profileData?.data?.user_data?.user_id
                                    ) {
                                      comm.isCommentLike = 1;
                                      comm.likeCommentId =
                                        like?.core_comment_id;
                                      return;
                                    } else if (
                                      like?.core_comment_id !=
                                      comm?.core_comment_id &&
                                      like?.user_id !=
                                      profileData?.data?.user_data?.user_id
                                    ) {
                                      comm.isCommentLike = 0;
                                      return;
                                    }
                                  });
                                  return (
                                    <Fragment key={idx}>
                                      <div className="feed-cmnt-form add_cmnt show_cmnt mt-2 mb-3 3">
                                        <div className="user_pic">
                                          {" "}
                                          {comm &&
                                            comm.poster &&
                                            comm.poster.avatar_id ? (
                                            <img
                                              src={comm.poster.avatar_id?.attachment_url}
                                              alt={comm.poster?.user_id}
                                            />
                                          ) : (
                                            <img src={userProfile} />
                                          )}
                                        </div>
                                        {/* comment message */}
                                        <div className="bgGrayBlock">
                                          <div className="cmnt-box p-2 flex justify-between items-center relative 2">
                                            {isUpdateComment && 
                                              commentId ===
                                              comm.core_comment_id ? (
                                              <Fragment>
                                                <form className="cmnt-form replay_cmnt flex justify-between items-center">
                                                  <input
                                                    className="cmnt-write"
                                                    name="updateComment"
                                                    defaultValue={
                                                      comm && comm.body
                                                    }
                                                    value={updateCommentVal}
                                                    onChange={(event) => {
                                                      handleChange(event);
                                                      setWriteUpdateComment(
                                                        true
                                                      );
                                                    }}
                                                  />
                                                  <div className="post-icons-option">
                                                    {" "}
                                                    <Link
                                                      to="#"
                                                      onClick={() => {
                                                        handleUpdateCommentEmoji(
                                                          comm?.id
                                                        );
                                                        setWriteUpdateComment(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      {" "}
                                                      <i className="far fa-smile" />{" "}
                                                    </Link>{" "}
                                                  </div>
                                                </form>
                                              </Fragment>
                                            ) : (
                                              <p>{comm && comm.body}</p>
                                            )}
                                            {/* emoji for update comment.. */}
                                            <div>
                                              {" "}
                                              {isUpdateCommentEmoji[comm?.id] &&
                                                commentId ===
                                                comm.core_comment_id && (
                                                  <Picker
                                                    onEmojiClick={
                                                      updateCommentEmoji
                                                    }
                                                  />
                                                )}
                                            </div>

                                            {/* save update comment */}
                                            {/* {isWriteUpdateComment && updateCommentVal?.length > 0 && commentId === comm.core_comment_id &&
                                            <div className="btnPost 5">
                                              <button type="button" className="btn" onClick={(event) => updatePostComment(event, item, comm)}>{lang?.Save_Changes}</button>
                                              <button type="button" className="btn" onClick={() => { setUpdateComment(false); setWriteUpdateComment(false); }}>{lang?.Cancel}</button>
                                            </div>} */}
                                            {/* dots icon for comment */}
                                            {profileData?.data?.user_data
                                              ?.user_id ===
                                              comm?.poster?.user_id && (
                                                <div
                                                  className="about_profile"
                                                  onClick={() => {
                                                    setCommentDotClick(
                                                      !commentDotClick
                                                    );
                                                    setCommentId(
                                                      comm?.core_comment_id
                                                    );
                                                    setUpdateComment(false);
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-ellipsis-v"
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                              )}
                                            {/* ..............edit and remove popup................. */}
                                            {commentDotClick &&
                                              commentId ===
                                              comm?.core_comment_id && (
                                                <div className="postView editPostCmnt">
                                                  <ul>
                                                    <li>
                                                      {" "}
                                                      <Link
                                                        to="#"
                                                        onClick={() => {
                                                          setUpdateComment(
                                                            true
                                                          );
                                                          setCommentDotClick(
                                                            !commentDotClick
                                                          );
                                                          setUpdateCommentVal(
                                                            comm && comm.body
                                                          );
                                                          
                                                        }}
                                                      >
                                                        {" "}
                                                        <span>
                                                          {" "}
                                                          <i
                                                            className="fa fa-pencil"
                                                            aria-hidden="true"
                                                          ></i>{" "}
                                                        </span>{" "}
                                                        {lang?.Edit}{" "}
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      {" "}
                                                      <Link
                                                        to="#"
                                                        onClick={() =>
                                                          removeComment(
                                                            item,
                                                            comm
                                                          )
                                                        }
                                                      >
                                                        <span>
                                                          {" "}
                                                          <i
                                                            className="fa fa-trash"
                                                            aria-hidden="true"
                                                          ></i>{" "}
                                                        </span>{" "}
                                                        {lang?.Remove_comment}{" "}
                                                      </Link>
                                                    </li>
                                                  </ul>
                                                </div>
                                              )}
                                          </div>

                                          {/* save update comment */}
                                          {isWriteUpdateComment &&
                                            updateCommentVal?.length > 0 &&
                                            commentId ===
                                            comm.core_comment_id && (
                                              <div className="btnPost 5">
                                                <button
                                                  type="button"
                                                  className="btn"
                                                  onClick={(event) =>
                                                    updatePostComment(
                                                      event,
                                                      item,
                                                      comm
                                                    )
                                                  }
                                                >
                                                  {lang?.Save_Changes}
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn"
                                                  onClick={() => {
                                                    setUpdateComment(false);
                                                    setWriteUpdateComment(
                                                      false
                                                    );
                                                  }}
                                                >
                                                  {lang?.Cancel}
                                                </button>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                      {/* like and unlike comment  */}
                                      <div className="cmnt_btn flex items-center items-center cmntReply">
                                        <Link
                                          to="#"
                                          className="Like flex items-center"
                                          onClick={() =>
                                            handleCommentLike(item, comm)
                                          }
                                        >
                                          {comm.isCommentLike &&
                                            comm.likeCommentId ===
                                            comm.core_comment_id ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14"
                                              height="18"
                                              viewBox="0 0 20 17.648"
                                            >
                                              <path
                                                id="like_icon_active"
                                                d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                                transform="translate(-5 -9)"
                                                fill="#ff0046"
                                              />
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14"
                                              height="14"
                                              viewBox="0 0 20.653 18.099"
                                            >
                                              <path
                                                id="icons8_heart"
                                                d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                                transform="translate(-2 -7)"
                                              />
                                            </svg>
                                          )}
                                          <span className="noCount">
                                            {comm.comment_like_count}
                                          </span>
                                        </Link>
                                        <Link
                                          to="#"
                                          className="Reply"
                                          onClick={() => {
                                            setWriteReply(!isWriteReply);
                                            setReplytId(comm.core_comment_id);
                                          }}
                                        >
                                          {" "}
                                          {lang?.Reply}
                                        </Link>
                                        {/* comment reply message.... */}
                                        {comm?.ReplyDetails &&
                                          Object.values(comm?.ReplyDetails)
                                            ?.length && (
                                            <Link
                                              to="#"
                                              className="viewReply"
                                              onClick={() => {
                                                setViewReply(!isViewReply);
                                                setReplytId(
                                                  comm.core_comment_id
                                                );
                                              }}
                                            >
                                              {isViewReply &&
                                                replytId === comm.core_comment_id
                                                ? "Hide"
                                                : `View`}{" "}
                                              {`${comm?.ReplyDetails &&
                                                Object.values(
                                                  comm?.ReplyDetails
                                                )?.length
                                                }`}{" "}
                                              Reply
                                            </Link>
                                          )}
                                      </div>

                                      {/* reply comment */}

                                      {isViewReply &&
                                        replytId === comm.core_comment_id &&
                                        comm?.ReplyDetails &&
                                        Object.values(comm.ReplyDetails).map(
                                          (commReply, replyIdx) => {
                                            commentLike?.map(async (like) => {
                                              if (
                                                like?.core_comment_id ==
                                                commReply?.core_comment_id &&
                                                like?.user_id ==
                                                profileData?.data?.user_data
                                                  ?.user_id
                                              ) {
                                                commReply.isCommentLike = 1;
                                                commReply.likeCommentId =
                                                  like?.core_comment_id;
                                                return;
                                              } else if (
                                                like?.core_comment_id !=
                                                commReply?.core_comment_id &&
                                                like?.user_id !=
                                                profileData?.data?.user_data
                                                  ?.user_id
                                              ) {
                                                commReply.isCommentLike = 0;
                                                return;
                                              }
                                            });
                                            return (
                                              <div
                                                className="inner__cmnt"
                                                key={replyIdx}
                                              >
                                                <div className="feed-cmnt-form add_cmnt show_cmnt mt-3 mb-4 4">
                                                  <div className="user_pic">
                                                    {commReply?.poster
                                                      ?.avatar_id ? (
                                                      <img
                                                        src={`${commReply.poster?.avatar_id.attachment_url}`}
                                                      />
                                                    ) : (
                                                      <img src={userProfile} />
                                                    )}
                                                  </div>
                                                  <div className="bgGrayBlock">
                                                    <div className="cmnt-box flex justify-between items-center">
                                                      {isUpdateReplyComment &&
                                                        replyCommentId ===
                                                        commReply.core_comment_id ? (
                                                        <form
                                                          className="cmnt-form replay_cmnt flex justify-between items-center"
                                                          onSubmit={(event) =>
                                                            updatePostCommentReply(
                                                              event,
                                                              item,
                                                              comm,
                                                              commReply
                                                            )
                                                          }
                                                        >
                                                          <input
                                                            className="cmnt-write"
                                                            defaultValue={
                                                              commReply?.body
                                                            }
                                                            value={
                                                              updateReplyCommentVal
                                                            }
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              setUpdateReplyCommentVal(
                                                                event.target
                                                                  .value
                                                              );
                                                              setWriteUpdateReplyComment(
                                                                true
                                                              );
                                                            }}
                                                          />
                                                          <div className="post-icons-option">
                                                            {" "}
                                                            <Link
                                                              to="#"
                                                              onClick={() =>
                                                                handleUpdateReplyEmoji(
                                                                  commReply?.id
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <i className="far fa-smile" />{" "}
                                                            </Link>{" "}
                                                          </div>
                                                        </form>
                                                      ) : (
                                                        <p>{commReply.body}</p>
                                                      )}
                                                      {/* update reply emoji */}
                                                      <div>
                                                        {" "}
                                                        {updateReplyEmoji[
                                                          commReply?.id
                                                        ] &&
                                                          replyCommentId ===
                                                          commReply.core_comment_id && (
                                                            <Picker
                                                              onEmojiClick={
                                                                updateReplyCommentEmoji
                                                              }
                                                            />
                                                          )}
                                                      </div>

                                                      {/* dots icon for comment reply */}
                                                      {profileData?.data
                                                        ?.user_data?.user_id ===
                                                        commReply?.poster
                                                          ?.user_id && (
                                                          <div
                                                            className="about_profile"
                                                            onClick={() => {
                                                              setRepyCommentDotClick(
                                                                !repyCommentDotClick
                                                              );
                                                              setReplyCommentId(
                                                                commReply?.core_comment_id
                                                              );
                                                            }}
                                                          >
                                                            <i
                                                              className="fa fa-ellipsis-v"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </div>
                                                        )}
                                                    </div>
                                                    {/* save update reply comment */}
                                                    {
                                                      isWriteUpdateReplyComment &&
                                                      updateReplyCommentVal?.trim()
                                                        ?.length > 0 &&
                                                      replyCommentId ===
                                                      commReply.core_comment_id && (
                                                        <div className="btnPost 5">
                                                          <button
                                                            type="button"
                                                            className="btn"
                                                            onClick={(
                                                              event
                                                            ) =>
                                                              updatePostCommentReply(
                                                                event,
                                                                item,
                                                                comm,
                                                                commReply
                                                              )
                                                            }
                                                          >
                                                            {
                                                              lang?.Save_Changes
                                                            }
                                                          </button>
                                                          <button
                                                            type="button"
                                                            className="btn"
                                                            onClick={() => {
                                                              setUpdateReplyComment(
                                                                false
                                                              );
                                                              setWriteUpdateReplyComment(
                                                                false
                                                              );
                                                            }}
                                                          >
                                                            {lang?.Cancel}
                                                          </button>
                                                        </div>
                                                      )
                                                      //   <div className="btnPost pl-4">
                                                      //     {/* <button type="button" className="btn" onClick={(event) => updatePostCommentReply(event, item, comm, commReply)}>Post</button> */}
                                                      //     {/*<button type="button" className="btn" onClick={() => updatePostCommentReply(item, comm, commReply)}>{lang?.Save_Changes}</button>
                                                      // <button type="button" className="btn" onClick={() => { setUpdateReplyComment(false); setWriteUpdateReplyComment(false); }}>{lang?.Cancel}</button>*/}
                                                      //   </div>
                                                    }
                                                  </div>
                                                </div>
                                                {/* ..............edit and remove popup reply comment................. */}
                                                {repyCommentDotClick &&
                                                  replyCommentId ===
                                                  commReply?.core_comment_id && (
                                                    <div className="postView 4">
                                                      <ul>
                                                        <li>
                                                          {" "}
                                                          <Link
                                                            to="#"
                                                            onClick={() => {
                                                              setUpdateReplyComment(
                                                                true
                                                              );
                                                              setRepyCommentDotClick(
                                                                !repyCommentDotClick
                                                              );
                                                            }}
                                                          >
                                                            {" "}
                                                            <span>
                                                              {" "}
                                                              <i
                                                                className="fa fa-pencil"
                                                                aria-hidden="true"
                                                              ></i>{" "}
                                                            </span>{" "}
                                                            {lang?.Edit}{" "}
                                                          </Link>
                                                        </li>
                                                        <li>
                                                          {" "}
                                                          <Link
                                                            to="#"
                                                            onClick={() =>
                                                              removeReplyComment(
                                                                item,
                                                                comm,
                                                                commReply
                                                              )
                                                            }
                                                          >
                                                            <span>
                                                              {" "}
                                                              <i
                                                                className="fa fa-trash"
                                                                aria-hidden="true"
                                                              ></i>{" "}
                                                            </span>{" "}
                                                            {
                                                              lang?.Remove_comment
                                                            }{" "}
                                                          </Link>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  )}
                                                {/* like and unlike comment reply */}
                                                <div className="replayInnerLike">
                                                  <Link
                                                    to="#"
                                                    className="flex items-center"
                                                    onClick={() =>
                                                      handleCommentReplyLike(
                                                        item,
                                                        comm,
                                                        commReply
                                                      )
                                                    }
                                                  >
                                                    {commReply.isCommentLike &&
                                                      commReply.likeCommentId ===
                                                      commReply.core_comment_id ? (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="18"
                                                        viewBox="0 0 20 17.648"
                                                      >
                                                        <path
                                                          id="like_icon_active"
                                                          d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                                          transform="translate(-5 -9)"
                                                          fill="#ff0046"
                                                        />
                                                      </svg>
                                                    ) : (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 20.653 18.099"
                                                      >
                                                        <path
                                                          id="icons8_heart"
                                                          d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                                          transform="translate(-2 -7)"
                                                        />
                                                      </svg>
                                                    )}
                                                    <span className="noCount">
                                                      {
                                                        commReply.comment_like_count
                                                      }
                                                    </span>
                                                  </Link>
                                                  {/* <Link to="#" > View all </Link> */}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}

                                      {/* write reply message.... */}
                                      {isWriteReply &&
                                        replytId === comm.core_comment_id && (
                                          <div className="inner__cmnt">
                                            <div className="feed-cmnt-form add_cmnt">
                                              <div className="user_pic">
                                                {profileData?.data
                                                  ?.user_data ? (
                                                  <img
                                                    src={`${profileData?.data?.user_data?.avatar_id.base_url}${profileData?.data?.user_data?.avatar_id.attachment_medium_url}`}
                                                  />
                                                ) : (
                                                  <img src={userProfile} />
                                                )}
                                              </div>
                                              <form
                                                className="cmnt-form replay_cmnt flex justify-between"
                                                onSubmit={(event) =>
                                                  event.preventDefault()
                                                }
                                              >
                                                <input
                                                  className="cmnt-write"
                                                  placeholder={
                                                    lang?.write_reply
                                                  }
                                                  defaultValue={""}
                                                  value={commentReply}
                                                  onChange={(event) => {
                                                    setCommentReply(
                                                      event.target.value
                                                    );
                                                    setReplyComment(true);
                                                  }}
                                                />
                                                {commentReply?.trim()?.length >
                                                  0 && (
                                                    <div className="btnPost 6">
                                                      {" "}
                                                      <div
                                                        type="submit"
                                                        className="btn"
                                                        onClick={(event) =>
                                                          submitCommentReply(
                                                            event,
                                                            item
                                                          )
                                                        }
                                                      >
                                                        <i
                                                          className="fa fa-paper-plane"
                                                          aria-hidden="true"
                                                        ></i>
                                                      </div>{" "}
                                                    </div>
                                                  )}
                                                <div className="post-icons-option">
                                                  {" "}
                                                  <Link
                                                    to="#"
                                                    onClick={() =>
                                                      handleReplyEmoji(comm?.id)
                                                    }
                                                  >
                                                    {" "}
                                                    <i className="far fa-smile" />{" "}
                                                  </Link>{" "}
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        )}
                                      <div>
                                        {" "}
                                        {replyEmoji[comm?.id] &&
                                          replytId === comm.core_comment_id && (
                                            <Picker
                                              onEmojiClick={replyCommentEmoji}
                                            />
                                          )}
                                      </div>
                                    </Fragment>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div ref={myRef}> </div>
                    </div>
                  );
                }
              })}
              {/* {isLoading && <FormSpinner />} */}
            </InfiniteScroll>
          )}
        </Fragment>
      )}

      {/*post in universal search */}
      {props.redirect_to === "universal_search" && (
        <div className="discover_page">
          <div className="blogView forPost">
            {feedActivityArr?.data?.map((item, index) => {
              {
                fetchCommentList?.map((comItem, comIdx) => {
                  const res = [];
                  if (comItem?.postId === item?.activity_action_id) {
                    for (let id in comItem?.comment) {
                      res.push({ id, ...comItem?.comment[id] });
                    }
                    item.comment = res;
                    item.comment_count = comItem.commentCount;
                    item.like_count = comItem.likeCount;
                    return;
                  }
                });
              }
              return (
                <div className="imgBlog" key={index}>
                  <div className="feed-listing">
                    <div className="feed-user-detail">
                      <div className="feed-user-info">
                        <Link to={`/user-profile/${item?.subject_id?.user_id}`}>
                          <div className="user__pic">
                            {item?.subject_id?.avatar_id ? (
                              <img
                                src={`${item?.subject_id?.avatar_id?.base_url}${item?.subject_id?.avatar_id?.attachment_medium_url}`}
                              />
                            ) : (
                              <img src={userProfile} />
                            )}
                          </div>
                        </Link>
                        <div className="user__detail-data">
                          <span className="user__name">
                            <Link
                              to={`/user-profile/${item.subject_id.user_id}`}
                            >
                              {(item?.subject_id?.first_name !== null &&
                                item?.subject_id?.last_name !== null &&
                                `${item?.subject_id?.first_name} ${item?.subject_id?.last_name}`) ||
                                item?.subject_id?.company_name ||
                                item?.subject_id?.restaurant_name}
                            </Link>
                          </span>
                          <div className="flex">
                            <MemberType item={item?.subject_id} />
                            {item?.subject_id?.role_id === 10 ? (
                              ""
                            ) : (
                              <span className="text-blue">
                                {" "}
                                , {item?.follower_count} {lang?.Followers}
                              </span>
                            )}
                          </div>
                        </div>
                        <div
                          className="about_profile"
                          onClick={() => {
                            setDotClick(!dotClick);
                            setpostId(item?.activity_action_id);
                            setSharePostOnMedia(false)
                          }}
                          ref={socialRef}
                        >
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>

                      {/* action dots block */}
                      {dotClick && postId === item?.activity_action_id && (
                        <div className="postView 5">
                          {profileData?.data?.user_data?.user_id ===
                            item?.subject_id?.user_id ? (
                            <ul>
                              <li>
                                {" "}
                                <Link
                                  to="#"
                                  onClick={() => {
                                    handleEditPost(item?.activity_action_id);
                                  }}
                                >
                                  <span>
                                    {" "}
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>{" "}
                                  </span>{" "}
                                  {lang?.EditPost}
                                </Link>{" "}
                              </li>
                              <li
                                onClick={() => {
                                  setDeletePopup(true);
                                  setpostId(item?.activity_action_id);
                                  setDotClick(false);
                                  setSharePostOnMedia(false)
                                }}
                              >
                                <Link to="#">
                                  <span>
                                    {" "}
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>{" "}
                                  </span>{" "}
                                  {lang?.Delete} Post{" "}
                                </Link>
                              </li>
                              {item?.shared_post_id === 0 &&
                                <li className=""
                                  onClick={() => { setSharePosturl(item?.privacy === "Public" ? `${window.location.origin}/home/public/post/activity/${item?.activity_action_id}?` : `${window.location.origin}/home/post/activity/${item?.activity_action_id}`); setSharePostOnMedia(true) }}
                                >
                                  <Link to="#">
                                    {" "}
                                    <span>
                                      {" "}
                                      <i
                                        className="fa fa-share"
                                        aria-hidden="true"
                                      ></i>
                                    </span>{" "}
                                    {lang?.Share_Post}{" "}
                                  </Link>
                                </li>
                              }
                            </ul>
                          ) : (
                            <ul>
                              <li
                                onClick={() =>
                                  repostPost(
                                    item.activity_action_id,
                                    item.subject_id.user_id
                                  )
                                }
                              >
                                {" "}
                                <Link to="#">
                                  {" "}
                                  <span>
                                    {" "}
                                    <i
                                      className="fa fa-flag"
                                      aria-hidden="true"
                                    ></i>{" "}
                                  </span>{" "}
                                  {lang?.Report_Post}{" "}
                                </Link>
                              </li>
                              {item?.shared_post_id === 0 &&
                                <li className=""
                                  onClick={() => { setSharePosturl(item?.privacy === "Public" ? `${window.location.origin}/home/public/post/activity/${item?.activity_action_id}` : `${window.location.origin}/home/post/activity/${item?.activity_action_id}`); setSharePostOnMedia(true) }}
                                >
                                  <Link to="#">
                                    {" "}
                                    <span>
                                      {" "}
                                      <i
                                        className="fa fa-share"
                                        aria-hidden="true"
                                      ></i>
                                    </span>{" "}
                                    {lang?.Share_Post}{" "}
                                  </Link>
                                </li>
                              }
                            </ul>
                          )}
                        </div>
                      )}
                      {/* share social post facebook,linkdin,twitter icon start*/}
                      {(postId === item.activity_action_id && sharePostOnMedia) &&
                        <div className="postView shareBlock">
                          <ul>
                            {/* <li>
                                    <i className="fa fa-link" aria-hidden="true"></i>
                                    <span>Copy Link</span>
                                  </li> */}
                            <li>
                              <FacebookShareButton url={`${sharePosturl}`}  >
                                <FacebookIcon size={40} round={true} />
                                <span>Share on Facebook</span>
                              </FacebookShareButton>
                              {/* <WhatsappShareButton url={`${sharePosturl}`}  >
                                      <WhatsappIcon size={40} round={true} />
                                  </WhatsappShareButton>              */}
                            </li>
                            <li>
                              <LinkedinShareButton url={`${sharePosturl}`} >
                                <LinkedinIcon size={40} round={true} />
                                <span>Share on LinkedIn</span>
                              </LinkedinShareButton>

                            </li>
                            <li>
                              <TwitterShareButton url={`${sharePosturl}`} >
                                <TwitterIcon size={40} round={true} />
                                <span>Share on Twitter</span>
                              </TwitterShareButton>

                            </li>
                          </ul>
                        </div>
                      }
                      {/* share social post facebook,linkdin,twitter icon start*/}
                      {/* post block  */}
                      <div className="user__feed user__feed-text">
                        {item?.body?.length > 30 ? (
                          <p>{item?.body && `${Checkurl(item?.body).slice(0, 30)}...`}</p>
                        ) : (
                          <p>{item?.body && Checkurl(item?.body)}</p>
                        )}
                        {item?.attachments?.length > 0 && (
                          <Fragment>
                            {item?.attachments?.length === 1 ? (
                              <div className="slider__img post--img singlePost">
                                {item?.attachments?.map((el, index) => {
                                  return (
                                    <div className="slider__item" key={index}>
                                      <div key={index}>
                                        <img
                                          className="feed_img"
                                          src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                          onClick={() =>
                                            handlePhotos(
                                              item,
                                              `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="slider__img post--img ">
                                {item?.attachments
                                  ?.slice(0, 4)
                                  ?.map((el, index) => {
                                    return (
                                      <div className="slider__item" key={index}>
                                        <div key={index}>
                                          <img
                                            className="feed_img"
                                            src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                            onClick={() =>
                                              handlePhotos(
                                                item,
                                                `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                              )
                                            }
                                          />
                                          {item?.attachments.length > 4 && (
                                            <div className="show_all_img">
                                              <div
                                                className="show_all_icon"
                                                onClick={() =>
                                                  handlePhotos(
                                                    item,
                                                    `${el?.attachment_link?.base_url}${item?.attachments[4].attachment_link?.attachment_url}`
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fa fa-plus"
                                                  aria-hidden="true"
                                                ></i>
                                                <span className="img_count_no">
                                                  {item?.attachments?.length -
                                                    4}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            )}
                          </Fragment>
                        )}
                        {item?.shared_post && (
                          <Fragment>
                            {/* inner form start */}
                            <div className="card card--block modal_box inner">
                              <div className="feed-user-info home_page mb-3">
                                <div className="user__pic mr-3">
                                  {item?.shared_post?.subject_id?.avatar_id ? (
                                    <img
                                      src={`${item?.shared_post?.subject_id?.avatar_id?.base_url}${item?.shared_post?.subject_id?.avatar_id?.attachment_medium_url}`}
                                    />
                                  ) : (
                                    <img src={userProfile} />
                                  )}
                                </div>
                                <div className="user__detail-data">
                                  <span className="user__name">
                                    <strong>
                                      {(item?.shared_post?.subject_id
                                        ?.first_name !== null &&
                                        item?.shared_post?.subject_id
                                          ?.last_name !== null &&
                                        `${item?.shared_post?.subject_id?.first_name} ${item?.shared_post?.subject_id?.last_name}`) ||
                                        item?.shared_post?.subject_id
                                          ?.company_name ||
                                        item?.shared_post?.subject_id
                                          ?.restaurant_name}
                                    </strong>
                                  </span>
                                </div>
                              </div>
                              <p className="mb-3"> {item?.shared_post?.body}</p>
                              <div className="post_img">
                                {item?.shared_post?.attachments?.length > 0 && (
                                  <Fragment>
                                    {item?.shared_post?.attachments?.length ===
                                      1 ? (
                                      <div className="slider__img post--img singlePost">
                                        {item?.shared_post?.attachments?.map(
                                          (el, index) => {
                                            return (
                                              <div
                                                className="slider__item"
                                                key={index}
                                              >
                                                <div key={index}>
                                                  <img
                                                    className="feed_img"
                                                    src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                    onClick={() =>
                                                      handlePhotos(
                                                        item?.shared_post,
                                                        `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    ) : (
                                      <div className="slider__img post--img ">
                                        {item?.shared_post?.attachments
                                          ?.slice(0, 4)
                                          ?.map((el, index) => {
                                            return (
                                              <div
                                                className="slider__item"
                                                key={index}
                                              >
                                                <div key={index}>
                                                  <img
                                                    className="feed_img"
                                                    src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                    onClick={() =>
                                                      handlePhotos(
                                                        item?.shared_post,
                                                        `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                      )
                                                    }
                                                  />
                                                  {item?.shared_post
                                                    ?.attachments?.length >
                                                    4 && (
                                                      <div className="show_all_img">
                                                        <div
                                                          className="show_all_icon"
                                                          onClick={() =>
                                                            handlePhotos(
                                                              item?.shared_post,
                                                              `${el?.attachment_link?.base_url}${item?.shared_post?.attachments[4].attachment_link?.attachment_url}`
                                                            )
                                                          }
                                                        >
                                                          <i
                                                            className="fa fa-plus"
                                                            aria-hidden="true"
                                                          ></i>
                                                          <span className="img_count_no">
                                                            {item?.shared_post
                                                              ?.attachments
                                                              ?.length - 4}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    )}
                                  </Fragment>
                                )}
                              </div>
                              <div className="just__Now mt-3">
                                {" "}
                                <p> {item?.shared_post?.posted_at}</p>
                              </div>
                            </div>
                            {/* inner form end */}
                          </Fragment>
                        )}
                      </div>
                    </div>

                    {/* post comments block  */}
                    <div className="user__comment-feed">
                      <div className="cmnt-tab">
                        {/* <div className="cmnt-tab-left"> <p>Just Now</p></div> */}
                        <div className="cmnt-tab-right flex justify-between items-center">
                          <span className="time_posted">
                            {" "}
                            {item && item.posted_at}
                          </span>
                          <ul className="flex items-center">
                            <li>
                              <Link
                                to="#"
                                className="flex items-center items-center"
                                onClick={() => handleLike(item)}
                              >
                                {item.like_flag ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="18"
                                    viewBox="0 0 20 17.648"
                                  >
                                    <path
                                      id="like_icon_active"
                                      d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                      transform="translate(-5 -9)"
                                      fill="#ff0046"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 20.653 18.099"
                                  >
                                    <path
                                      id="icons8_heart"
                                      d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                      transform="translate(-2 -7)"
                                    />
                                  </svg>
                                )}
                                <span className="noCount">
                                  {item.like_count}
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="flex items-center items-center"
                                onClick={() => handlePostComment(item)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 20.653 18.857"
                                >
                                  <path
                                    id="icons8_comments"
                                    d="M4.245,4A2.254,2.254,0,0,0,2,6.245V17.02a2.254,2.254,0,0,0,2.245,2.245H6.083A3.476,3.476,0,0,1,6,20.724a2.31,2.31,0,0,1-1.08,1.3.449.449,0,0,0,.224.828,5.571,5.571,0,0,0,5.191-3.592H20.408a2.254,2.254,0,0,0,2.245-2.245V6.245A2.254,2.254,0,0,0,20.408,4Zm0,.9H20.408a1.347,1.347,0,0,1,1.347,1.347V17.02a1.347,1.347,0,0,1-1.347,1.347H10.082a.449.449,0,0,0-.421.295A4.638,4.638,0,0,1,6.42,21.8a3.144,3.144,0,0,0,.435-.8,4.613,4.613,0,0,0,.07-2.273.45.45,0,0,0-.435-.365H4.245A1.347,1.347,0,0,1,2.9,17.02V6.245A1.347,1.347,0,0,1,4.245,4.9Z"
                                    transform="translate(-2 -4)"
                                  />
                                </svg>
                                <span className="noCount">
                                  {" "}
                                  {item.comment_count}
                                </span>
                              </Link>
                            </li>
                            {item?.shared_post_id === 0 && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setShareModal(!shareModal);
                                    setSharePostItem(item);
                                    setprivacy(item.privacy);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 18.857 15.283"
                                  >
                                    <path
                                      id="icons8_forward_arrow"
                                      d="M15.168,11a.448.448,0,0,0-.393.449v3.171c-5.169.158-7.955,2.894-9.33,5.612A13.958,13.958,0,0,0,4,25.756v.056a.449.449,0,1,0,.9.028v-.028a5.221,5.221,0,0,1,1.389-2.848c1.263-1.419,3.727-2.85,8.489-2.946v3.1a.449.449,0,0,0,.73.351l7.184-5.837a.45.45,0,0,0,0-.7L15.5,11.095A.45.45,0,0,0,15.168,11Zm.505,1.389,6.019,4.9-6.019,4.9V19.527a.45.45,0,0,0-.449-.449c-5.277,0-8.122,1.622-9.611,3.3-.056.063-.088.119-.14.182a12.281,12.281,0,0,1,.772-1.936c1.31-2.589,3.811-5.135,8.98-5.135a.45.45,0,0,0,.449-.449Z"
                                      transform="translate(-4 -10.993)"
                                    />
                                  </svg>
                                </Link>
                              </li>
                            )}
                          </ul>
                        </div>
                        {/* .........................post comments................... */}
                        {commentBox[item.activity_action_id] && (
                          <div className="user__comment-feed--list">
                            <div className="feed-cmnt-form add_cmnt">
                              <div className="user_pic">
                                {profileData?.data?.user_data?.avatar_id ? (
                                  <img
                                    src={`${profileData?.data?.user_data?.avatar_id.base_url}${profileData?.data?.user_data?.avatar_id.attachment_medium_url}`}
                                  />
                                ) : (
                                  <img src={userProfile} />
                                )}
                              </div>
                              <form className="cmnt-form replay_cmnt flex justify-between items-center">
                                <input
                                  className="cmnt-write"
                                  placeholder={lang?.write_comments}
                                  defaultValue={""}
                                  value={commentVal}
                                  onChange={(event) => {
                                    handleChange(event);
                                    setWriteComment(true);
                                  }}
                                />
                                {isWriteComment && commentVal?.length > 0 && (
                                  <div className="btnPost 7">
                                    {" "}
                                    <button
                                      type="submit"
                                      className="btn"
                                      onClick={(event) =>
                                        submitCommentPost(event, item)
                                      }
                                    >
                                      {lang?.only_Post}
                                    </button>{" "}
                                  </div>
                                )}
                                <div className="post-icons-option">
                                  {" "}
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      handleEmoji(item.activity_action_id);
                                      setWriteComment(true);
                                    }}
                                  >
                                    <i className="far fa-smile" />{" "}
                                  </Link>
                                </div>
                              </form>
                            </div>
                            <div>
                              {isEmoji[item.activity_action_id] && (
                                <Picker onEmojiClick={onEmojiClick} />
                              )}{" "}
                            </div>

                            {/* ...........post comments........... */}
                            {item?.comment?.map((comm, idx) => {
                              commentLike?.map(async (like) => {
                                if (
                                  like?.core_comment_id ==
                                  comm?.core_comment_id &&
                                  like?.user_id ==
                                  profileData?.data?.user_data?.user_id
                                ) {
                                  comm.isCommentLike = 1;
                                  comm.likeCommentId = like?.core_comment_id;
                                  return;
                                } else if (
                                  like?.core_comment_id !=
                                  comm?.core_comment_id &&
                                  like?.user_id !=
                                  profileData?.data?.user_data?.user_id
                                ) {
                                  comm.isCommentLike = 0;
                                  return;
                                }
                              });
                              return (
                                <Fragment key={idx}>
                                  <div className="feed-cmnt-form add_cmnt show_cmnt mt-3 mb-4 5">
                                    <div className="user_pic">
                                      {" "}
                                      {comm &&
                                        comm.poster &&
                                        comm.poster.avatar_id ? (
                                        <img
                                          src={`${comm.poster.avatar_id.attachment_url}`}
                                        />
                                      ) : (
                                        <img src={userProfile} />
                                      )}
                                    </div>
                                    {/* comment message */}
                                    <div className="cmnt-box flex justify-between items-center relative">
                                      {isUpdateComment &&
                                        commentId === comm.core_comment_id ? (
                                        <Fragment>
                                          <form className="cmnt-form replay_cmnt flex justify-between items-center">
                                            <input
                                              className="cmnt-write"
                                              name="updateComment"
                                              defaultValue={comm && comm.body}
                                              value={updateCommentVal}
                                              onChange={(event) => {
                                                handleChange(event);
                                                setWriteUpdateComment(true);
                                              }}
                                            />
                                            <div className="post-icons-option">
                                              {" "}
                                              <Link
                                                to="#"
                                                onClick={() => {
                                                  handleUpdateCommentEmoji(
                                                    comm?.id
                                                  );
                                                  setWriteUpdateComment(true);
                                                }}
                                              >
                                                {" "}
                                                <i className="far fa-smile" />{" "}
                                              </Link>{" "}
                                            </div>
                                          </form>
                                        </Fragment>
                                      ) : (
                                        <p>{comm && comm.body}</p>
                                      )}
                                      {/* emoji for update comment.. */}
                                      <div>
                                        {" "}
                                        {isUpdateCommentEmoji[comm?.id] &&
                                          commentId ===
                                          comm.core_comment_id && (
                                            <Picker
                                              onEmojiClick={updateCommentEmoji}
                                            />
                                          )}
                                      </div>

                                      {/* save update comment */}
                                      {isWriteUpdateComment &&
                                        updateCommentVal?.length > 0 &&
                                        commentId === comm.core_comment_id && (
                                          <div className="btnPost 8">
                                            <button
                                              type="button"
                                              className="btn"
                                              onClick={() =>
                                                updatePostComment(item, comm)
                                              }
                                            >
                                              {lang?.Save_Changes}
                                            </button>
                                            <button
                                              type="button"
                                              className="btn"
                                              onClick={() => {
                                                setUpdateComment(false);
                                                setWriteUpdateComment(false);
                                              }}
                                            >
                                              {lang?.Cancel}
                                            </button>
                                          </div>
                                        )}
                                      {/* dots icon for comment */}
                                      {profileData?.data?.user_data?.user_id ===
                                        comm?.poster?.user_id && (
                                          <div
                                            className="about_profile"
                                            onClick={() => {
                                              setCommentDotClick(
                                                !commentDotClick
                                              );
                                              setCommentId(comm?.core_comment_id);
                                            }}
                                          >
                                            <i
                                              className="fa fa-ellipsis-v"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        )}
                                      {/* ..............edit and remove popup................. */}
                                      {commentDotClick &&
                                        commentId === comm?.core_comment_id && (
                                          <div className="postView editPostCmnt">
                                            <ul>
                                              <li>
                                                {" "}
                                                <Link
                                                  to="#"
                                                  onClick={() => {
                                                    setUpdateComment(true);
                                                    setCommentDotClick(
                                                      !commentDotClick
                                                    );
                                                  }}
                                                >
                                                  {" "}
                                                  <span>
                                                    {" "}
                                                    <i
                                                      className="fa fa-pencil"
                                                      aria-hidden="true"
                                                    ></i>{" "}
                                                  </span>{" "}
                                                  {lang?.Edit}{" "}
                                                </Link>
                                              </li>
                                              <li>
                                                {" "}
                                                <Link
                                                  to="#"
                                                  onClick={() =>
                                                    removeComment(item, comm)
                                                  }
                                                >
                                                  <span>
                                                    {" "}
                                                    <i
                                                      className="fa fa-trash"
                                                      aria-hidden="true"
                                                    ></i>{" "}
                                                  </span>{" "}
                                                  {lang?.Remove_comment}{" "}
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  {/* like and unlike comment  */}
                                  <div className="cmnt_btn flex items-center items-center">
                                    <Link
                                      to="#"
                                      className="flex items-center"
                                      onClick={() =>
                                        handleCommentLike(item, comm)
                                      }
                                    >
                                      {comm.isCommentLike &&
                                        comm.likeCommentId ===
                                        comm.core_comment_id ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="18"
                                          viewBox="0 0 20 17.648"
                                        >
                                          <path
                                            id="like_icon_active"
                                            d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                            transform="translate(-5 -9)"
                                            fill="#ff0046"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 20.653 18.099"
                                        >
                                          <path
                                            id="icons8_heart"
                                            d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                            transform="translate(-2 -7)"
                                          />
                                        </svg>
                                      )}
                                      <span className="noCount">
                                        {comm.comment_like_count}
                                      </span>
                                    </Link>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        setWriteReply(true);
                                        setReplytId(comm.core_comment_id);
                                      }}
                                    >
                                      {" "}
                                      {lang?.Reply}
                                    </Link>
                                    {/* <Link to="#" > View all </Link> */}
                                  </div>

                                  {/* comment reply message.... */}
                                  {comm?.ReplyDetails &&
                                    Object.values(comm.ReplyDetails).map(
                                      (commReply, replyIdx) => {
                                        commentLike?.map(async (like) => {
                                          if (
                                            like?.core_comment_id ==
                                            commReply?.core_comment_id &&
                                            like?.user_id ==
                                            profileData?.data?.user_data
                                              ?.user_id
                                          ) {
                                            commReply.isCommentLike = 1;
                                            commReply.likeCommentId =
                                              like?.core_comment_id;
                                            return;
                                          } else if (
                                            like?.core_comment_id !=
                                            commReply?.core_comment_id &&
                                            like?.user_id !=
                                            profileData?.data?.user_data
                                              ?.user_id
                                          ) {
                                            commReply.isCommentLike = 0;
                                            return;
                                          }
                                        });
                                        return (
                                          <div
                                            className="inner__cmnt"
                                            key={replyIdx}
                                          >
                                            <div className="feed-cmnt-form add_cmnt show_cmnt mt-3 mb-4 6">
                                              <div className="user_pic">
                                                {commReply?.poster
                                                  ?.avatar_id ? (
                                                  <img
                                                    src={`${commReply.poster?.avatar_id.attachment_medium_url}`}
                                                  />
                                                ) : (
                                                  <img src={userProfile} />
                                                )}
                                              </div>
                                              <div className="cmnt-box flex justify-between items-center">
                                                {isUpdateReplyComment &&
                                                  replyCommentId ===
                                                  commReply.core_comment_id ? (
                                                  <form className="cmnt-form replay_cmnt flex justify-between items-center">
                                                    <input
                                                      className="cmnt-write"
                                                      defaultValue={
                                                        commReply?.body
                                                      }
                                                      value={
                                                        updateReplyCommentVal
                                                      }
                                                      onChange={(event) => {
                                                        setUpdateReplyCommentVal(
                                                          event.target.value
                                                        );
                                                        setWriteUpdateReplyComment(
                                                          true
                                                        );
                                                      }}
                                                    />
                                                    <div className="post-icons-option">
                                                      {" "}
                                                      <Link
                                                        to="#"
                                                        onClick={() =>
                                                          handleUpdateReplyEmoji(
                                                            commReply?.id
                                                          )
                                                        }
                                                      >
                                                        {" "}
                                                        <i className="far fa-smile" />{" "}
                                                      </Link>{" "}
                                                    </div>
                                                  </form>
                                                ) : (
                                                  <p>{commReply.body}</p>
                                                )}
                                                {/* update reply emoji */}
                                                <div>
                                                  {" "}
                                                  {updateReplyEmoji[
                                                    commReply?.id
                                                  ] &&
                                                    replyCommentId ===
                                                    commReply.core_comment_id && (
                                                      <Picker
                                                        onEmojiClick={
                                                          updateReplyCommentEmoji
                                                        }
                                                      />
                                                    )}
                                                </div>
                                                {/* save update reply comment */}
                                                {isWriteUpdateReplyComment &&
                                                  updateReplyCommentVal?.length >
                                                  0 &&
                                                  replyCommentId ===
                                                  commReply.core_comment_id && (
                                                    <div className="btnPost 9">
                                                      <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={(event) =>
                                                          updatePostCommentReply(
                                                            event,
                                                            item,
                                                            comm,
                                                            commReply
                                                          )
                                                        }
                                                      >
                                                        Post
                                                      </button>
                                                      {/* <button type="button" className="btn" onClick={() => updatePostCommentReply(item, comm, commReply)}>{lang?.Save_Changes}</button>
                                                <button type="button" className="btn" onClick={() => { setUpdateReplyComment(false); setWriteUpdateReplyComment(false); }}>{lang?.Cancel}</button> */}
                                                    </div>
                                                  )}
                                                {/* dots icon for comment reply */}
                                                {profileData?.data?.user_data
                                                  ?.user_id ===
                                                  commReply?.poster
                                                    ?.user_id && (
                                                    <div
                                                      className="about_profile"
                                                      onClick={() => {
                                                        setRepyCommentDotClick(
                                                          !repyCommentDotClick
                                                        );
                                                        setReplyCommentId(
                                                          commReply?.core_comment_id
                                                        );
                                                      }}
                                                    >
                                                      <i
                                                        className="fa fa-ellipsis-v"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                            {/* ..............edit and remove popup reply comment................. */}
                                            {repyCommentDotClick &&
                                              replyCommentId ===
                                              commReply?.core_comment_id && (
                                                <div className="postView 6">
                                                  <ul>
                                                    <li>
                                                      {" "}
                                                      <Link
                                                        to="#"
                                                        onClick={() => {
                                                          setUpdateReplyComment(
                                                            true
                                                          );
                                                          setRepyCommentDotClick(
                                                            !repyCommentDotClick
                                                          );
                                                        }}
                                                      >
                                                        {" "}
                                                        <span>
                                                          {" "}
                                                          <i
                                                            className="fa fa-pencil"
                                                            aria-hidden="true"
                                                          ></i>{" "}
                                                        </span>{" "}
                                                        {lang?.Edit}{" "}
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      {" "}
                                                      <Link
                                                        to="#"
                                                        onClick={() =>
                                                          removeReplyComment(
                                                            item,
                                                            comm,
                                                            commReply
                                                          )
                                                        }
                                                      >
                                                        <span>
                                                          {" "}
                                                          <i
                                                            className="fa fa-trash"
                                                            aria-hidden="true"
                                                          ></i>{" "}
                                                        </span>{" "}
                                                        {lang?.Remove_comment}{" "}
                                                      </Link>
                                                    </li>
                                                  </ul>
                                                </div>
                                              )}
                                            {/* like and unlike comment reply */}
                                            <div className="cmnt_btn flex items-center items-center">
                                              <Link
                                                to="#"
                                                onClick={() =>
                                                  handleCommentReplyLike(
                                                    item,
                                                    comm,
                                                    commReply
                                                  )
                                                }
                                              >
                                                {commReply.isCommentLike &&
                                                  commReply.likeCommentId ===
                                                  commReply.core_comment_id ? (
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="14"
                                                    height="18"
                                                    viewBox="0 0 20 17.648"
                                                  >
                                                    <path
                                                      id="like_icon_active"
                                                      d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                                      transform="translate(-5 -9)"
                                                      fill="#ff0046"
                                                    />
                                                  </svg>
                                                ) : (
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="14"
                                                    height="14"
                                                    viewBox="0 0 20.653 18.099"
                                                  >
                                                    <path
                                                      id="icons8_heart"
                                                      d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                                      transform="translate(-2 -7)"
                                                    />
                                                  </svg>
                                                )}
                                                <span className="noCount">
                                                  {commReply.comment_like_count}
                                                </span>
                                              </Link>
                                              {/* <Link to="#" > View all </Link> */}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                  {/* write reply message.... */}
                                  {isWriteReply &&
                                    replytId === comm.core_comment_id && (
                                      <div className="inner__cmnt">
                                        <div className="feed-cmnt-form add_cmnt">
                                          <div className="user_pic">
                                            {profileData?.data?.user_data ? (
                                              <img
                                                src={`${profileData?.data?.user_data?.avatar_id.base_url}${profileData?.data?.user_data?.avatar_id.attachment_medium_url}`}
                                              />
                                            ) : (
                                              <img src={userProfile} />
                                            )}
                                          </div>
                                          <form className="cmnt-form replay_cmnt flex justify-between">
                                            <input
                                              className="cmnt-write"
                                              placeholder={lang?.write_reply}
                                              defaultValue={""}
                                              value={commentReply}
                                              onChange={(event) => {
                                                setCommentReply(
                                                  event.target.value
                                                );
                                                setReplyComment(true);
                                              }}
                                            />
                                            {isReplyComment &&
                                              commentReply.length > 0 && (
                                                <div className="btnPost 10">
                                                  {" "}
                                                  <button
                                                    type="submit"
                                                    className="btn"
                                                    onClick={(event) =>
                                                      submitCommentReply(
                                                        event,
                                                        item
                                                      )
                                                    }
                                                  >
                                                    {lang?.only_Post}
                                                  </button>{" "}
                                                </div>
                                              )}
                                            <div className="post-icons-option">
                                              {" "}
                                              <Link
                                                to="#"
                                                onClick={() =>
                                                  handleReplyEmoji(comm?.id)
                                                }
                                              >
                                                {" "}
                                                <i className="far fa-smile" />{" "}
                                              </Link>{" "}
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    )}
                                  <div>
                                    {" "}
                                    {replyEmoji[comm?.id] &&
                                      replytId === comm.core_comment_id && (
                                        <Picker
                                          onEmojiClick={replyCommentEmoji}
                                        />
                                      )}
                                  </div>
                                </Fragment>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div ref={myRef}> </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Share Post PopUp */}
      {shareModal && (
        <div>
          <Modal
            className="post_cmnt"
            ariaHideApp={false}
            isOpen={shareModal}
            style={{
              overlay: {
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title">{lang?.Share_Post}</h4>
              <button
                className="close_btn--modal"
                onClick={() => setShareModal(!shareModal)}
              >
                {lang?.close}{" "}
              </button>
            </div>
            <div className="card card--block modal_box share-emogy">
              <div className="feed-user-info home_page mb-3">
                <div className="user__pic mr-3">
                  {" "}
                  <img
                    src={
                      profileData &&
                        profileData.data &&
                        profileData?.data?.user_data?.avatar_id !== null
                        ? `${profileData?.data?.user_data?.avatar_id.base_url}${profileData?.data?.user_data?.avatar_id.attachment_medium_url}`
                        : `${userProfile}`
                    }
                    alt="Profile Image"
                  />{" "}
                </div>
                <div className="user__detail-data">
                  <span className="user__name">
                    <strong>
                      {(profileData?.data?.user_data?.first_name !== null &&
                        profileData?.data?.user_data?.last_name !== null &&
                        `${profileData?.data?.user_data?.first_name} ${profileData?.data?.user_data?.last_name}`) ||
                        profileData?.data?.user_data?.company_name ||
                        profileData?.data?.user_data?.restaurant_name ||
                        profileData?.data?.user_data?.username}
                    </strong>
                  </span>
                  <MemberType item={profileData?.data?.user_data} />
                  <span>
                    {/* Public  */}
                    <div className="form__item postDrop">
                      <select
                        value={privacy}
                        onChange={(event) => setprivacy(event.target.value)}
                      >
                        <option value="Public">{lang?.Public}</option>
                        <option value="Only Me">{lang?.only_me}</option>
                        <option value="Followers">{lang?.Followers}</option>
                        <option value="Connections">
                          {lang?.my_connections}
                        </option>
                      </select>
                    </div>
                  </span>
                </div>
              </div>
              <form className="feed-post-form share_post">
                <textarea
                  className="write_post-area"
                  value={sharePostText}
                  placeholder={lang?.Enter_your_text}
                  rows="2"
                  onChange={(event) => setSharePostText(event.target.value)}
                ></textarea>
                <div className="post-icons-option">
                  {" "}
                  <Link to="#" onClick={() => setIsShareEmoji(!isShareEmoji)}>
                    <i className="far fa-smile" />{" "}
                  </Link>
                </div>
              </form>
              <div>
                {isShareEmoji && <Picker onEmojiClick={onShareEmojiClick} />}{" "}
              </div>
              {/* inner form start */}
              <div className="card card--block modal_box inner">
                <div className="feed-user-info home_page mb-3">
                  <div className="user__pic mr-3">
                    {sharePostItem &&
                      sharePostItem.subject_id &&
                      sharePostItem &&
                      sharePostItem.subject_id.avatar_id ? (
                      <img
                        src={
                          sharePostItem &&
                          sharePostItem.subject_id &&
                          `${sharePostItem.subject_id.avatar_id.base_url}${sharePostItem.subject_id.avatar_id.attachment_medium_url}`
                        }
                      />
                    ) : (
                      <img src={userProfile} />
                    )}
                  </div>
                  <div className="user__detail-data">
                    <span className="user__name">
                      <strong>
                        {(sharePostItem &&
                          sharePostItem.subject_id.first_name !== null &&
                          sharePostItem &&
                          sharePostItem.subject_id.last_name !== null &&
                          `${sharePostItem && sharePostItem.subject_id.first_name
                          } ${sharePostItem && sharePostItem.subject_id.last_name
                          }`) ||
                          (sharePostItem &&
                            sharePostItem.subject_id.company_name) ||
                          (sharePostItem &&
                            sharePostItem.subject_id.restaurant_name)}
                      </strong>
                    </span>
                  </div>
                </div>
                <p className="mb-3">{sharePostItem?.body}</p>
                <div className="post_img">
                  {sharePostItem?.attachments?.length > 0 && (
                    <Fragment>
                      {sharePostItem?.attachments?.length === 1 ? (
                        <div className="slider__img post--img singlePost">
                          {sharePostItem?.attachments?.map((el, index) => {
                            return (
                              <div className="slider__item" key={index}>
                                <div key={index}>
                                  <img
                                    className="feed_img"
                                    src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                  // onClick={() => {
                                  //   handlePhotos(sharePostItem, `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`);
                                  //   setShareModal(!shareModal)
                                  // }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="slider__img post--img ">
                          {sharePostItem?.attachments
                            ?.slice(0, 4)
                            ?.map((el, index) => {
                              return (
                                <div className="slider__item" key={index}>
                                  <div key={index}>
                                    <img
                                      className="feed_img"
                                      src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                    // onClick={() => {
                                    //   handlePhotos(sharePostItem, `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`);
                                    //   setShareModal(!shareModal)
                                    // }}
                                    />
                                    {sharePostItem?.attachments?.length > 4 && (
                                      <div className="show_all_img">
                                        <div
                                          className="show_all_icon"
                                          onClick={() =>
                                            handlePhotos(
                                              sharePostItem,
                                              `${el?.attachment_link?.base_url}${sharePostItem?.attachments[4].attachment_link?.attachment_url}`
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-plus"
                                            aria-hidden="true"
                                          ></i>
                                          <span className="img_count_no">
                                            {sharePostItem?.attachments
                                              ?.length - 4}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </Fragment>
                  )}
                  {/* {sharePostItem?.shared_post?.attachments?.length > 0 &&
                    <Fragment>
                      {sharePostItem?.shared_post?.attachments?.length === 1 ?
                        <div className="slider__img post--img singlePost">
                          {sharePostItem?.shared_post?.attachments?.map((el, index) => {
                            return (
                              <div className="slider__item" key={index}>
                                <div key={index}>
                                  <img className="feed_img"
                                    src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                    onClick={() => handlePhotos(sharePostItem?.shared_post, `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`)}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div> :
                        <div className="slider__img post--img ">
                          {sharePostItem?.shared_post?.attachments?.slice(0, 4)?.map((el, index) => {
                            return (
                              <div className="slider__item" key={index}>
                                <div key={index}>
                                  <img className="feed_img"
                                    src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                    onClick={() => handlePhotos(sharePostItem?.shared_post, `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`)}
                                  />
                                  {sharePostItem?.shared_post?.attachments?.length > 4 &&
                                    <div className="show_all_img">
                                      <div className="show_all_icon"><i className="fa fa-plus" aria-hidden="true"></i><span className="img_count_no">{sharePostItem?.shared_post?.attachments?.length - 4}</span></div>
                                    </div>
                                  }
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      }
                    </Fragment>
                  } */}
                </div>
                {/* <div className="just__Now mt-3"><p>{sharePostItem && sharePostItem.posted_at}</p> </div> */}
              </div>
              {/* inner form end */}
              <div className="btn_blue_full_w">
                <button
                  type="button"
                  className="btn"
                  onClick={() => sharePost(sharePostItem)}
                >
                  {" "}
                  {sharePostSpinner ? <FormSpinner /> : lang?.Share_Post}{" "}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}

      {/* Delete Popup */}
      {deletePopup && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={deletePopup}
            style={{
              overlay: {
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "10px",
                padding: "0",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center popupheaderBg">
              <h4 className="page__title">{lang?.Delete}</h4>
              <button
                className="close_btn--modal"
                onClick={() => setDeletePopup(!deletePopup)}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box">
              <p className="warning">{lang?.are_you_sure}</p>
              <div className="right__btn text-right">
                <Link
                  className="btn btn1"
                  to="#"
                  onClick={() => setDeletePopup(false)}
                >
                  {" "}
                  {lang?.Cancel}{" "}
                </Link>
                <Link
                  className="btn btn2 ml-3"
                  to="#"
                  onClick={() => removePost(postId)}
                >
                  {removePostSpinner ? <FormSpinner /> : lang?.ok}
                </Link>
              </div>
            </div>
          </Modal>
        </div>
      )}

      {/* user photos view */}{/*check*/}
      {isOpenImage && (
        <div className="image--light--pop">
          <ReactImageVideoLightbox
            data={userPhotosData}
            startIndex={imageIndex}
            showResourceCount={true}
            onCloseCallback={() => {
              setIsOpenImage(false);
              setUserPhotosData([]);
              setImageIndex(0);
            }}
          // onNavigationCallback={(currentIndex) =>
          //     console.log(`Current index: ${currentIndex}`)
          // }
          />
        </div>
      )}

      {isReportModal && (
        <Modal
          className="reportModal"
          isOpen={isReportModal}
          ariaHideApp={false}
          contentLabel=""
          style={{
            overlay: {
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            },
            content: {
              position: "absolute",
              top: "0",
              left: "0%",
              right: "0%",
              bottom: "200px",
              border: "1px solid #ccc",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              borderRadius: "4px",
              padding: "0",
              // outline: 'none',
            },
          }}
        >
          <div className="modalHeader flex items-center">
            <div className="headerTitle w-full text-center font-22">Report</div>
            <button
              className="close"
              onClick={() => {
                setIsReportModal(false);
              }}
            >
              <svg
                id="Icons_Alerts_alarm"
                data-name="Icons/Alerts/alarm"
                xmlns="http://www.w3.org/2000/svg"
                width="21.037"
                height="21.04"
                viewBox="0 0 21.037 21.04"
              >
                <path
                  id="close"
                  d="M2.4.412A1.405,1.405,0,1,0,.412,2.4l8.117,8.117L.412,18.634A1.405,1.405,0,1,0,2.4,20.622l8.117-8.112,8.117,8.117a1.405,1.405,0,1,0,1.988-1.988L12.51,10.517,20.627,2.4A1.407,1.407,0,1,0,18.634.412L10.516,8.529Z"
                  transform="translate(0 0.001)"
                  fill="#171717"
                />
              </svg>
            </button>
          </div>
          <div className="modalBody">
            <h4 className="problem-post font-18 font-bold">
              {lang?.Report_Problem}
            </h4>
            <div className="report-post">
              {reasonError && (
                <div className="errorMsg">{lang?.One_Reason_Choose}.</div>
              )}
              <form onSubmit={reportSubmit}>
                <ul className="problems">
                  <li>{lang?.Nudity}</li>
                  <li>
                    <input
                      type="radio"
                      name="problem_with_post"
                      className="select-checkbox"
                      onClick={() => addPostReason("Nudity")}
                    />
                  </li>
                </ul>
                <ul className="problems">
                  <li>{lang?.Violence}</li>
                  <li>
                    <input
                      type="radio"
                      name="problem_with_post"
                      className="select-checkbox"
                      onClick={() => addPostReason("Violence")}
                    />
                  </li>
                </ul>
                <ul className="problems">
                  <li>{lang?.Harassment}</li>
                  <li>
                    <input
                      type="radio"
                      name="problem_with_post"
                      className="select-checkbox"
                      onClick={() => addPostReason("Harassment")}
                    />
                  </li>
                </ul>
                <ul className="problems">
                  <li>{lang?.Suicide}</li>
                  <li>
                    <input
                      type="radio"
                      name="problem_with_post"
                      className="select-checkbox"
                      onClick={() => addPostReason("Suicide")}
                    />
                  </li>
                </ul>
                <ul className="problems">
                  <li>{lang?.False_Info}</li>
                  <li>
                    <input
                      type="radio"
                      name="problem_with_post"
                      className="select-checkbox"
                      onClick={() => addPostReason("False Information")}
                    />
                  </li>
                </ul>
                <ul className="problems">
                  <li>{lang?.Others}</li>
                  <li>
                    <input
                      type="radio"
                      name="problem_with_post"
                      className="select-checkbox"
                      onClick={() => addPostReason("Other")}
                    />
                  </li>
                </ul>
                {allowOtherReason && (
                  <div className="other_reason">
                    <textarea
                      className="my_reason"
                      placeholder={lang?.Write_Reason}
                      onChange={(e) => {
                        setOtherReason(e.target.value);
                        setOtherReasonError(false);
                      }}
                    ></textarea>
                    {otherReasonError ? (
                      <div className="errorMsg field-required">
                        {lang?.Write_Reason} {lang?.is_required}.
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                <div className="submitReport">
                  <button
                    className="savedReport"
                    type="submit"
                    onClick={() => { }}
                  >
                    {isSubmitReport ? <FormSpinner /> : lang?.Next}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      )}

      {/* Next Detail Of Popup */}
      {supportAction && (
        <Modal
          className="reportModal"
          isOpen={supportAction}
          ariaHideApp={false}
          contentLabel=""
          style={{
            overlay: {
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            },
            content: {
              position: "absolute",
              top: "0",
              left: "0%",
              right: "0%",
              bottom: "200px",
              border: "1px solid #ccc",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              borderRadius: "4px",
              padding: "0",
              // outline: 'none',
            },
          }}
        >
          <div className="modalHeader flex items-center">
            <div
              className="headerTitle w-full text-center font-22"
              style={{ color: "#3296ff" }}
            >
              {lang?.SUPPORT_ACTION}
            </div>
            <button
              className="close"
              onClick={() => {
                setSupportAction(false);
                setNextButton(false);
              }}
            >
              <svg
                id="Icons_Alerts_alarm"
                data-name="Icons/Alerts/alarm"
                xmlns="http://www.w3.org/2000/svg"
                width="21.037"
                height="21.04"
                viewBox="0 0 21.037 21.04"
              >
                <path
                  id="close"
                  d="M2.4.412A1.405,1.405,0,1,0,.412,2.4l8.117,8.117L.412,18.634A1.405,1.405,0,1,0,2.4,20.622l8.117-8.112,8.117,8.117a1.405,1.405,0,1,0,1.988-1.988L12.51,10.517,20.627,2.4A1.407,1.407,0,1,0,18.634.412L10.516,8.529Z"
                  transform="translate(0 0.001)"
                  fill="#171717"
                />
              </svg>
            </button>
          </div>
          <div className="modalBody">
            <div className="report-post">
              <h4 className="problem-post font-18 font-bold">
                {lang?.BLOCK_USERS}
              </h4>
              <p className="font-11 bgPink">
                {lang?.BLOCK_USERS_HINT}
                <br />
                {lang?.PLEASE_SELECT}{" "}
                <span>
                  <i className="fa fa-check-square-o" aria-hidden="true"></i>
                </span>
                {lang?.TO_BLOCK_USER}.
              </p>
              <form>
                <ul className="problems">
                  <li>{lang?.Block}</li>
                  <li>
                    <input
                      type="checkbox"
                      name="problem_with_post"
                      className="select-checkbox"
                      onChange={() => setIsReportAndBlock(!isReportAndBlock)}
                    />
                  </li>
                </ul>
              </form>
              <div className="submitReport">
                <button
                  className="savedReport"
                  type="submit"
                  onClick={(e) => {
                    reportSubmit(e);
                  }}
                >
                  {lang?.only_Submit}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {/* Copy Link */}

    </Fragment>
  );
}

