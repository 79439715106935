import React, { Fragment, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Carousel from 'react-grid-carousel';
import { Link, useHistory } from 'react-router-dom';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import Modal from 'react-modal';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import Parse from 'html-react-parser';
import userProfile from '../../../assets/images/dummy-profile-pic.jpg';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import firebase from '../../../config//firebase';
import InfiniteScroll from "react-infinite-scroller";
import {
  likePost,
  sharedPost,
  postComment,
  deletePost,
  replyComment,
  fetchAllUserPost,
  fetchAllUserPosts,
  getEditUserPostDetails,
} from '../modules/post';
import { AddPost } from './addPost';
import constant from '../../../helpers/constant';
import DataNotFound from '../../../components/DataNotFound';
import language from '../../../helpers/language';
import MemberType from '../../../components/MemeberType';
import CustomUseEffect from '../../../components/constants';

export function UserSocialPost(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [lang] = useState(language.getLang());
  const [page, setPage] = useState(1);
  const [postId, setpostId] = useState();
  const [privacy, setprivacy] = useState('public');
  const [replytId, setReplytId] = useState();
  const [postType, setPostType] = useState(1);
  const [isEmoji, setIsEmoji] = useState(false);
  const [commentVal, setCommentVal] = useState();
  const [dotClick, setDotClick] = useState(false);
  const [isEmojiObj, setIsEmojiObj] = useState({});
  const [isComObj, setIsComObj] = useState({});
  const [commentReply, setCommentReply] = useState();
  const [shareModal, setShareModal] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [sharePostItem, setSharePostItem] = useState();
  const [sharePostText, setSharePostText] = useState('');

  const [isWriteReply, setWriteReply] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isWriteComment, setWriteComment] = useState(false);
  const [fetchCommentList, setFetchCommentList] = useState();
  const [replyEmoji, setReplyEmoji] = useState({});

  const [isOpenImage, setIsOpenImage] = useState(false);
  const [userPhotosData, setUserPhotosData] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [userProfileFeeds, setUserProfileFeeds] = useState([]);
  const [allFirebaseUsers, setAllFirebaseUsers] = useState([]);

  const { getPostSpinner, getUserPost } = useSelector(
    (state) => state.postState
  );
  const { profileData } = useSelector((state) => state.memberProfileData);
  const { sharePostSpinner, removePostSpinner } = useSelector(
    (state) => state.activityFeedStatus
  );

  CustomUseEffect(async () => {
    const todoRef = firebase.database().ref("users");
    todoRef.on("value", async (snapshot) => {
      const response = snapshot.val();
      const result = [];
      for (let id in response) result.push({ id, ...response[id] });
      await setAllFirebaseUsers(result);
    });

  }, []);

  CustomUseEffect(async () => {
    await fetchFirebaseData();
  }, []);

  CustomUseEffect(async () => {
    const model = {
      postType: postType,
      page: 1,
    };
    await dispatch(fetchAllUserPost(model)).then(async (data) => {
      await setUserProfileFeeds(data?.data);
    });
  }, [profileData]);


  //console.log(currentUserInfo,"currentUserInfo");
  const fetchFirebaseData = () => {
    const todoRef = firebase.database().ref('post');
    todoRef.on('value', (snapshot) => {
      const response = snapshot.val();
      const result = [];
      for (let id in response) {
        result.push({ id, ...response[id] });
      }
      setFetchCommentList(result);
    });
  };

  const handleLike = async (item) => {
    const model = {
      like_or_unlike: item.like_flag ? 0 : 1,
      post_id: item.activity_action_id,
      user_id: profileData?.data?.user_data?.user_id,
    };
    if (item.like_flag) {
      item.like_count = --item.like_count;
      item.like_flag = 0;
    } else {
      item.like_count = ++item.like_count;
      item.like_flag = 1;
    }
    await dispatch(likePost(model));
  };

  const onEmojiClick = async (event, emojiObject) => {
    await setChosenEmoji(emojiObject);
    await setCommentVal(emojiObject.emoji);
    // await setIsEmoji(false);
  };

  const handleChange = (event) => {
    setCommentVal(event.target.value);
  };

  const sharePost = async (item) => {
    const model = {
      action_type: 'post',
      privacy: privacy,
      shared_post_id: item.activity_action_id,
      body: sharePostText?.trim()?.length > 0 ? sharePostText: "",
    };
    await dispatch(sharedPost(model)).then(async (res) => {
      if (res && res.success === 200) {
        firebase
          .database()
          .ref('post')
          .child(res && res.share_post_id)
          .set({
            likeCount: 0,
            commentCount: 0,
            postId: res && res.share_post_id,
          });
        setShareModal(false);
      }
    });
    const model1 = {
      postType: postType,
      page: page,
    };
    await dispatch(fetchAllUserPost(model1));
    setShareModal(false);
  };

  const submitCommentPost = async (event, item) => {
    event.preventDefault();
    const model = {
      post_id: item.activity_action_id,
      comment: commentVal,
      user_id: profileData?.data?.user_data?.user_id,
    };
    await dispatch(postComment(model)).then(async (res) => {
      if (res && res.success === 200) {
        setCommentVal('');
        setWriteComment(false);
        firebase
          .database()
          .ref('post')
          .child(item.activity_action_id)
          .child('comment')
          .child(res && res.data.core_comment_id)
          .set({
            body: res && res.data.body,
            core_comment_id: res && res.data.core_comment_id,
            created_at: res && res.data.created_at,
            poster: res && res.data.poster,
          });
        await fetchFirebaseData();
      }
    });
  };

  const submitCommentReply = async (event, item) => {
    event.preventDefault();
    const payload = {
      reply: commentReply,
      comment_id: replytId,
      post_id: item.activity_action_id,
      user_id: profileData?.data?.user_data?.user_id,
    };
    await dispatch(replyComment(payload)).then(async (res) => {
      setCommentReply('');
      if (res && res.success === 200) {
        firebase
          .database()
          .ref('post')
          .child(item.activity_action_id)
          .child('comment')
          .child(replytId)
          .child('ReplyDetails')
          .child(res && res.data.core_comment_id)
          .set({
            body: res && res.data.body,
            core_comment_id: res && res.data.core_comment_id,
            created_at: res && res.data.created_at,
            poster: res && res.data.poster,
          });
        await fetchFirebaseData();
      }
    });
  };

  const getCommenterProfilePhoto = (posterId) => {
    let user = allFirebaseUsers.filter(function (e) {
      return e.id == posterId;
    });
    if (Object.keys(user).length > 0) {
      return user[0].url;
    }
    else {
      return userProfile;
    }

  }

  const removePost = async (postId) => {
    const model = {
      post_id: postId,
    };
    await dispatch(deletePost(model)).then(async (res) => {
      if (res && res.success === 200) {
        const allFeeds = userProfileFeeds;
        const arrayData = [...allFeeds?.data];
        let finalArray = arrayData.filter(function (post) {
          return post.activity_action_id !== postId
        })
        allFeeds['data'] = finalArray;
        await setUserProfileFeeds(allFeeds);
        setDeletePopup(false);
      }
    });
    setDeletePopup(false);
  };

  const handleEditPost = async (post_id) => {
    await dispatch(getEditUserPostDetails(post_id));
    await setIsEditModal(true);
  };

  const handleClick = async (newValue) => {
    const model = {
      postType: postType,
      page: 1,
    };
    await dispatch(fetchAllUserPost(model)).then(async (data) => {
      await setUserProfileFeeds(data?.data);
    });
    setDotClick(newValue);
    setIsEditModal(newValue);
  };

  const handleEmoji = (id) => {
    setIsEmojiObj((isEmoji) => ({
      ...isEmoji,
      [id]: !isEmoji[id],
    }));
  };
  const handleCommentBox = (id) => {
    setIsComObj((isComObj) => ({
      ...isComObj,
      [id]: !isComObj[id],
    }));
  };

  const handleReplyEmoji = (id) => {
    setReplyEmoji((replyEmoji) => ({
      ...replyEmoji,
      [id]: !replyEmoji[id],
    }));
  };

  // photos gallery
  const handlePhotos = async (data, url) => {
    let userPhotos = [];
    await data?.attachments?.map(async (item) => {
      await userPhotos.push({
        url: `${item?.attachment_link?.base_url}${item && item?.attachment_link?.attachment_url
          }`,
        type: 'photo',
        altTag: 'image',
      });
    });
    userPhotos?.map(async (item, index) => {
      //console.log(item?.url, url);
      if (item?.url === url) {
        await setImageIndex(index);
      }
    });

    await setUserPhotosData(userPhotos);
    await setIsOpenImage(true);
  };

  // Check post content has URL
  const Checkurl = (text) => {
    var url1 = /(^|&lt;|\s)(www\..+?\..+?)(\s|&gt;|$)/g,
      url2 = /(^|&lt;|\s)(((https?|ftp):\/\/|mailto:).+?)(\s|&gt;|$)/g;

    var html = text;
    if (html) {
      html = html
        .replace(url1, '$1<a target="_blank"  href="http://$2">$2</a>$3')
        .replace(url2, '$1<a target="_blank"  href="$2">$2</a>$5')
        .replace(/(\w+@\w+.\w+)+/g, "<a href='mailto:$1'>$1</a>");
    }
    return Parse(html?.replace(/\n/g, "<br />"));
  };

  const loadMore = async () => {
    let nextPage = page + 1;
    if (
      nextPage > userProfileFeeds?.current_page &&
      nextPage <= userProfileFeeds?.last_page
    ) {
      setTimeout(async () => {
        const model1 = {
          postType: postType,
          page: nextPage,
        };
        await dispatch(
          fetchAllUserPosts(model1)
        ).then(async (response) => {
          //console.log(data,"dataaa");
          const data = response?.data;
          let concatArr = [...userProfileFeeds?.data, ...data?.data];
          data["data"] = await concatArr;
          await setUserProfileFeeds(data);
          await setPage(nextPage);
        });
      }, 1000);
    }
  };

  return (
    <Fragment>
      <AddPost isOpenModal={isEditModal} onHandleChange={handleClick} />
      {getPostSpinner ? (
        <FormSpinner />
      ) : (
        <div>
          {userProfileFeeds?.data?.length > 0 ? (
            <Fragment>
              <InfiniteScroll
                pageStart={0}
                threshold={1000}
                hasMore={true}
                useCapture={true}
                // useWindow={false}
                initialLoad={false}
                loadMore={loadMore}
                loader={page < userProfileFeeds?.last_page && <FormSpinner />}
              >
                {userProfileFeeds?.data?.map((item, index) => {
                  let userPost =
                    profileData?.data?.user_data?.user_id ===
                    item.subject_id?.user_id;
                  {
                    fetchCommentList?.map((comItem, comIdx) => {
                      const res = [];
                      if (comItem?.postId === item.activity_action_id) {
                        for (let id in comItem?.comment) {
                          res.push({ id, ...comItem.comment[id] });
                        }
                        return (item.comment = res);
                      }
                    });
                  }
                  {
                    /* if (!item.shared_post_id) { */
                  }
                  return (
                    <div className="card card--block" key={index}>
                      <div className="feed-listing">
                        <div className="feed-user-detail">
                          <div className="feed-user-info">
                            <div className="user__pic">
                              {item.subject_id && item.subject_id.avatar_id ? (
                                <img
                                  src={`${item.subject_id?.avatar_id?.base_url}${item.subject_id?.avatar_id?.attachment_medium_url}`}
                                />
                              ) : (
                                <img src={userProfile} />
                              )}
                            </div>
                            <div className="user__detail-data">
                              <span className="user__name">
                                <Link to="#">
                                  {item?.subject_id?.company_name ||
                                    item?.subject_id?.restaurant_name ||
                                    (item?.subject_id?.first_name !== null &&
                                      item?.subject_id?.last_name !== null &&
                                      `${item?.subject_id?.first_name} ${item?.subject_id?.last_name}`)}
                                </Link>
                              </span>
                              <div className="flex">
                                <MemberType item={item.subject_id} />
                                {item?.subject_id?.role_id === 10 ? (
                                  ""
                                ) : (
                                  <span className="text-blue">
                                    {" "}
                                    ,{" "}
                                    {!item.follower_count
                                      ? 0
                                      : item.follower_count}{" "}
                                    {lang?.Followers}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div
                              className="about_profile"
                              onClick={() => {
                                setDotClick(!dotClick);
                                setpostId(item.activity_action_id);
                              }}
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>

                          {dotClick && postId === item.activity_action_id && (
                            <div className="postView">
                              {userPost ? (
                                <ul>
                                  <li>
                                    <Link
                                      onClick={() => {
                                        handleEditPost(item.activity_action_id);
                                      }}
                                    >
                                      {" "}
                                      <span>
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                        ></i>{" "}
                                      </span>{" "}
                                      {lang?.Edit} {lang?.only_Post}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      onClick={() => {
                                        setDeletePopup(true);
                                        setpostId(item.activity_action_id);
                                        setDotClick(false);
                                      }}
                                    >
                                      <span>
                                        {" "}
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>{" "}
                                      </span>{" "}
                                      {lang?.only_delete} {lang?.only_Post}
                                    </Link>
                                  </li>
                                  {/* <li>
                                    <Link
                                      onClick={() => {
                                        setShareModal(!shareModal);
                                        setSharePostItem(item);
                                        setprivacy(item.privacy)
                                        setDotClick(false);
                                      }} >
                                      <span> <i className="fa fa-share" aria-hidden="true"></i> </span> {lang?.only_share} {lang?.only_Post}
                                    </Link>
                                  </li> */}
                                </ul>
                              ) : (
                                <ul>
                                  <li>
                                    <Link>
                                      <span>
                                        {" "}
                                        <i
                                          className="fa fa-flag"
                                          aria-hidden="true"
                                        ></i>{" "}
                                      </span>{" "}
                                      {lang?.Report} {lang?.only_Post}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      onClick={() => {
                                        setShareModal(!shareModal);
                                        setSharePostItem(item);
                                        setprivacy(item.privacy);
                                        setDotClick(false);
                                      }}
                                    >
                                      <span>
                                        <i
                                          className="fa fa-share"
                                          aria-hidden="true"
                                        ></i>
                                      </span>{" "}
                                      {lang?.only_share} {lang?.only_Post}
                                    </Link>
                                  </li>
                                </ul>
                              )}
                            </div>
                          )}
                          <div className="user__feed user__feed-text">
                            <p>
                              {item?.body && Checkurl(item.body)}
                            </p>

                            {item?.attachments?.length > 0 &&
                              item?.shared_post_id === 0 && (
                                <Fragment>
                                  {item?.attachments?.length === 1 ? (
                                    <div className="slider__img post--img singlePost">
                                      {item?.attachments?.map((el, index) => {
                                        return (
                                          <div
                                            className="slider__item"
                                            key={index}
                                          >
                                            <div key={index}>
                                              <img
                                                className="feed_img"
                                                src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                onClick={() =>
                                                  handlePhotos(
                                                    item,
                                                    `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <div className="slider__img post--img ">
                                      {item?.attachments
                                        ?.slice(0, 4)
                                        ?.map((el, index) => {
                                          return (
                                            <div
                                              className="slider__item"
                                              key={index}
                                            >
                                              <div key={index}>
                                                <img
                                                  className="feed_img"
                                                  src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                  onClick={() =>
                                                    handlePhotos(
                                                      item,
                                                      `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                    )
                                                  }
                                                />
                                                {item?.attachments?.length >
                                                  4 && (
                                                    <div className="show_all_img">
                                                      <div
                                                        className="show_all_icon"
                                                        onClick={() =>
                                                          handlePhotos(
                                                            item,
                                                            `${el?.attachment_link?.base_url}${item?.attachments[5]?.attachment_link?.attachment_url}`
                                                          )
                                                        }
                                                      >
                                                        {" "}
                                                        <i
                                                          className="fa fa-plus"
                                                          aria-hidden="true"
                                                        ></i>
                                                        <span className="img_count_no">
                                                          {item?.attachments
                                                            ?.length - 4}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  )}
                                </Fragment>
                              )}
                            {/* shared post block*/}
                            {item?.shared_post && (
                              <Fragment>
                                {/* inner form start */}
                                <div className="card card--block modal_box inner">
                                  <div className="feed-user-info home_page mb-3">
                                    <div
                                      className="user__pic mr-3"
                                      onClick={() =>
                                        history.push(
                                          `/user-profile/${item?.shared_post?.subject_id?.user_id}`
                                        )
                                      }
                                    >
                                      {item?.shared_post?.subject_id
                                        ?.avatar_id ? (
                                        <img
                                          src={`${item?.shared_post?.subject_id?.avatar_id?.base_url}${item?.shared_post?.subject_id?.avatar_id?.attachment_medium_url}`}
                                        />
                                      ) : (
                                        <img src={userProfile} />
                                      )}
                                    </div>
                                    <div className="user__detail-data">
                                      <span
                                        className="user__name"
                                        onClick={() =>
                                          history.push(
                                            `/user-profile/${item.shared_post?.subject_id?.user_id}`
                                          )
                                        }
                                      >
                                        <strong>
                                          {(item?.shared_post?.subject_id
                                            ?.first_name !== null &&
                                            item?.shared_post?.subject_id
                                              ?.last_name !== null &&
                                            `${item?.shared_post?.subject_id?.first_name}  ${item?.shared_post?.subject_id?.last_name}`) ||
                                            item?.shared_post?.subject_id
                                              ?.company_name ||
                                            item?.shared_post?.subject_id
                                              ?.restaurant_name}
                                        </strong>
                                        <div className="flex">
                                          <MemberType
                                            item={item?.shared_post?.subject_id}
                                          />
                                          {item?.shared_post?.subject_id
                                            ?.role_id === 10 ? (
                                            ""
                                          ) : (
                                            <span className="text-blue">
                                              {" "}
                                              ,{" "}
                                              {
                                                item?.shared_post
                                                  ?.follower_count
                                              }{" "}
                                              {lang?.Followers}
                                            </span>
                                          )}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                  <p className="mb-3">
                                    {item?.shared_post?.body && Checkurl(item?.shared_post?.body)}
                                  </p>
                                  <div className="post_img">
                                    {item?.shared_post?.attachments?.length >
                                      0 && (
                                        <Fragment>
                                          {item?.shared_post?.attachments
                                            .length === 1 ? (
                                            <div className="slider__img post--img singlePost">
                                              {item?.shared_post?.attachments?.map(
                                                (el, index) => {
                                                  return (
                                                    <div
                                                      className="slider__item"
                                                      key={index}
                                                    >
                                                      <div key={index}>
                                                        <img
                                                          className="feed_img"
                                                          src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                          onClick={() =>
                                                            handlePhotos(
                                                              item?.shared_post,
                                                              `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          ) : (
                                            <div className="slider__img post--img ">
                                              {item?.shared_post?.attachments
                                                ?.slice(0, 4)
                                                ?.map((el, index) => {
                                                  return (
                                                    <div
                                                      className="slider__item"
                                                      key={index}
                                                    >
                                                      <div key={index}>
                                                        <img
                                                          className="feed_img"
                                                          src={`${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`}
                                                          onClick={() =>
                                                            handlePhotos(
                                                              item?.shared_post,
                                                              `${el?.attachment_link?.base_url}${el?.attachment_link?.attachment_url}`
                                                            )
                                                          }
                                                        />
                                                        {item?.shared_post
                                                          ?.attachments.length >
                                                          4 && (
                                                            <div className="show_all_img">
                                                              <div
                                                                className="show_all_icon"
                                                                onClick={() =>
                                                                  handlePhotos(
                                                                    item?.shared_post,
                                                                    `${el?.attachment_link?.base_url}${item?.shared_post?.attachments[4].attachment_link?.attachment_url}`
                                                                  )
                                                                }
                                                              >
                                                                <i
                                                                  className="fa fa-plus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                                <span className="img_count_no">
                                                                  {item?.shared_post
                                                                    ?.attachments
                                                                    .length - 4}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          )}
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          )}
                                        </Fragment>
                                      )}
                                  </div>
                                  <div className="just__Now mt-3">
                                    {" "}
                                    <p>
                                      {" "}
                                      {item.shared_post &&
                                        item.shared_post.posted_at}
                                    </p>
                                  </div>
                                </div>
                                {/* inner form end */}
                              </Fragment>
                            )}
                            {item?.shared_post_deleted && (
                              <div className="deleted-post">
                                <h4 className="font-16 font-bold text-black">
                                  {lang?.deletedSharePostHeading}
                                </h4>
                                <p className="font-semi-bold">
                                  {lang?.deletedSharePostMessage}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="user__comment-feed">
                          <div className="cmnt-tab">
                            {/* <div className="cmnt-tab-left">
                        <p>Just Now</p>
                      </div> */}
                            <div className="cmnt-tab-right flex justify-between items-center">
                              <span className="time_posted">
                                {item && item.posted_at}
                              </span>
                              <ul className="flex items-center">
                                <li>
                                  <Link
                                    to="#"
                                    // className={item.like_flag ? "like_fav" : ""}
                                    onClick={() => handleLike(item)}
                                  >
                                    {item.like_flag ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="18"
                                        viewBox="0 0 20 17.648"
                                      >
                                        <path
                                          id="like_icon_active"
                                          d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                          transform="translate(-5 -9)"
                                          fill="#ff0046"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 20.653 18.099"
                                      >
                                        <path
                                          id="icons8_heart"
                                          d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                          transform="translate(-2 -7)"
                                        />
                                      </svg>
                                    )}

                                    <span className="noCount">
                                      {item.like_count}
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      handleCommentBox(item.activity_action_id)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 20.653 18.857"
                                    >
                                      <path
                                        id="icons8_comments"
                                        d="M4.245,4A2.254,2.254,0,0,0,2,6.245V17.02a2.254,2.254,0,0,0,2.245,2.245H6.083A3.476,3.476,0,0,1,6,20.724a2.31,2.31,0,0,1-1.08,1.3.449.449,0,0,0,.224.828,5.571,5.571,0,0,0,5.191-3.592H20.408a2.254,2.254,0,0,0,2.245-2.245V6.245A2.254,2.254,0,0,0,20.408,4Zm0,.9H20.408a1.347,1.347,0,0,1,1.347,1.347V17.02a1.347,1.347,0,0,1-1.347,1.347H10.082a.449.449,0,0,0-.421.295A4.638,4.638,0,0,1,6.42,21.8a3.144,3.144,0,0,0,.435-.8,4.613,4.613,0,0,0,.07-2.273.45.45,0,0,0-.435-.365H4.245A1.347,1.347,0,0,1,2.9,17.02V6.245A1.347,1.347,0,0,1,4.245,4.9Z"
                                        transform="translate(-2 -4)"
                                      />
                                    </svg>
                                    <span className="noCount">
                                      {item.comment_count}
                                    </span>
                                  </Link>
                                </li>
                                {!userPost && (
                                  <li>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        setShareModal(!shareModal);
                                        setSharePostItem(item);
                                        setprivacy(item.privacy);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 18.857 15.283"
                                      >
                                        <path
                                          id="icons8_forward_arrow"
                                          d="M15.168,11a.448.448,0,0,0-.393.449v3.171c-5.169.158-7.955,2.894-9.33,5.612A13.958,13.958,0,0,0,4,25.756v.056a.449.449,0,1,0,.9.028v-.028a5.221,5.221,0,0,1,1.389-2.848c1.263-1.419,3.727-2.85,8.489-2.946v3.1a.449.449,0,0,0,.73.351l7.184-5.837a.45.45,0,0,0,0-.7L15.5,11.095A.45.45,0,0,0,15.168,11Zm.505,1.389,6.019,4.9-6.019,4.9V19.527a.45.45,0,0,0-.449-.449c-5.277,0-8.122,1.622-9.611,3.3-.056.063-.088.119-.14.182a12.281,12.281,0,0,1,.772-1.936c1.31-2.589,3.811-5.135,8.98-5.135a.45.45,0,0,0,.449-.449Z"
                                          transform="translate(-4 -10.993)"
                                        />
                                      </svg>
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            </div>

                            {isComObj[item.activity_action_id] && (
                              <div className="user__comment-feed--list">
                                <div className="feed-cmnt-form add_cmnt">
                                  <div className="user_pic">
                                    {profileData?.data?.user_data ? (
                                      <img
                                        src={`${profileData?.data?.user_data?.avatar_id?.base_url}${profileData?.data?.user_data?.avatar_id?.attachment_medium_url}`}
                                      />
                                    ) : (
                                      <img src={userProfile} />
                                    )}
                                  </div>
                                  <form
                                    className="cmnt-form replay_cmnt flex justify-between"
                                    onSubmit={(event) =>
                                      submitCommentPost(event, item)
                                    }
                                  >
                                    <input
                                      className="cmnt-write"
                                      placeholder="Write a comment..."
                                      defaultValue={""}
                                      value={commentVal}
                                      onChange={(event) => {
                                        handleChange(event);
                                        setWriteComment(true);
                                      }}
                                    />
                                    <div className="post-icons-option">
                                      <Link
                                        to="#"
                                        // onClick={() => setIsEmoji(!isEmoji)}
                                        onClick={() => {
                                          handleEmoji(item?.activity_action_id);
                                        }}
                                      >
                                        <i className="far fa-smile" />
                                      </Link>
                                    </div>
                                  </form>
                                </div>
                                <div>
                                  {isEmojiObj[item.activity_action_id] && (
                                    <Picker onEmojiClick={onEmojiClick} />
                                  )}
                                </div>

                                {item?.comment?.map((comm, idx) => {
                                  return (
                                    <Fragment key={idx}>
                                      <div className="feed-cmnt-form add_cmnt show_cmnt mt-3 mb-4">
                                        <div className="user_pic">
                                          {comm?.poster?.avatar_id ? (
                                            <img
                                              src={comm.poster.avatar_id?.attachment_url}
                                              alt={comm?.poster?.user_id}
                                            />
                                          ) : (
                                            <img src={userProfile} />
                                          )}
                                        </div>
                                        <div className="cmnt-box">
                                          <p>{comm?.body}</p>
                                        </div>
                                      </div>
                                      <div className="cmnt_btn flex items-center">
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            handleCommentBox(
                                              comm?.core_comment_id
                                            );
                                          }}
                                        >
                                          {lang?.Reply}
                                        </Link>
                                        {/* <Link
                                        to="#"
                                      >
                                        View all
                                      </Link> */}
                                      </div>
                                      {comm?.ReplyDetails &&
                                        Object.values(comm.ReplyDetails).map(
                                          (commReply, replyIdx) => {
                                            return (
                                              <div
                                                className="inner__cmnt"
                                                key={replyIdx}
                                              >
                                                <div className="feed-cmnt-form add_cmnt show_cmnt mt-3 mb-4">
                                                  <div className="user_pic">
                                                    {commReply?.poster
                                                      ?.avatar_id ? (
                                                      <img
                                                        src={commReply.poster.avatar_id?.attachment_url}
                                                        alt={commReply?.poster?.user_id}
                                                      />
                                                    ) : (
                                                      <img src={userProfile} />
                                                    )}
                                                  </div>
                                                  <div className="cmnt-box">
                                                    <p>{commReply?.body}</p>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}

                                      {isComObj[comm.core_comment_id] && (
                                        <div className="inner__cmnt">
                                          <div className="feed-cmnt-form add_cmnt">
                                            <div className="user_pic">
                                              {profileData?.data?.user_data ? (
                                                <img
                                                  src={`${profileData?.data?.user_data?.avatar_id?.base_url}${profileData?.data?.user_data?.avatar_id?.attachment_medium_url}`}
                                                />
                                              ) : (
                                                <img src={userProfile} />
                                              )}
                                            </div>

                                            <form
                                              className="cmnt-form replay_cmnt flex justify-between"
                                              onSubmit={(event) =>
                                                submitCommentReply(event, item)
                                              }
                                            >
                                              <input
                                                className="cmnt-write"
                                                placeholder="Write a reply..."
                                                defaultValue={""}
                                                value={commentReply}
                                                onChange={(event) => {
                                                  setCommentReply(
                                                    event.target.value
                                                  );
                                                }}
                                              />
                                              <div className="post-icons-option">
                                                <Link
                                                  to="#"
                                                  onClick={() =>
                                                    handleReplyEmoji(
                                                      comm?.core_comment_id
                                                    )
                                                  }
                                                >
                                                  <i className="far fa-smile" />
                                                </Link>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      )}

                                      <div>
                                        {replyEmoji[comm.core_comment_id] && (
                                          <Picker onEmojiClick={onEmojiClick} />
                                        )}
                                      </div>
                                    </Fragment>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                  {
                    /* } */
                  }
                })}
              </InfiniteScroll>
            </Fragment>
          ) : (
            <DataNotFound
              post={{
                post: `${lang?.when_you_post_see_here}`,
                title: `${lang?.only_Post}`,
              }}
            />
          )}
        </div>
      )}

      {/* Share Post PopUp */}
      {shareModal && (
        <div>
          <Modal
            className="post_cmnt"
            isOpen={shareModal}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title">
                {" "}
                {lang?.only_share} {lang?.only_Post}
              </h4>
              <button
                className="close_btn--modal"
                onClick={() => setShareModal(!shareModal)}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box">
              <div className="feed-user-info home_page mb-3">
                <div className="user__pic mr-3">
                  <img
                    src={
                      profileData &&
                        profileData.data &&
                        profileData.data.user_data.avatar_id !== null
                        ? `${profileData.data.user_data.avatar_id.base_url}${profileData.data.user_data.avatar_id.attachment_medium_url}`
                        : `${userProfile}`
                    }
                    alt="Profile Image"
                  />
                </div>

                <div className="user__detail-data">
                  <span className="user__name">
                    <strong>
                      {(profileData.data.user_data.first_name !== null &&
                        profileData.data.user_data.last_name !== null &&
                        `${profileData.data.user_data.first_name} ${profileData.data.user_data.last_name}`) ||
                        profileData.data.user_data.company_name ||
                        profileData.data.user_data.restaurant_name ||
                        profileData.data.user_data.username}{" "}
                    </strong>
                  </span>
                  <span>
                    {/* Public  */}
                    <div className="form__item postDrop">
                      <select
                        value={privacy}
                        onChange={(event) => setprivacy(event.target.value)}
                      >
                        <option value="Public">{lang?.Public}</option>
                        <option value="Only Me">{lang?.only_me}</option>
                        <option value="Followers">{lang?.Followers}</option>
                        <option value="Connections">
                          {lang?.my_connections}
                        </option>
                      </select>
                    </div>
                  </span>
                </div>
              </div>
              <form className="feed-post-form share_post">
                <textarea
                  className="write_post-area"
                  placeholder={lang?.Enter_your_text}
                  rows="2"
                  onChange={(event) => setSharePostText(event.target.value)}
                ></textarea>
              </form>
              {/* <div>{isEmoji && <Picker onEmojiClick={onEmojiClick} skinTone={SKIN_TONE_MEDIUM_DARK}/>} </div> */}
              {/* inner form start */}
              <div className="card card--block modal_box inner">
                <div className="feed-user-info home_page mb-3">
                  <div className="user__pic mr-3">
                    {sharePostItem?.subject_id?.avatar_id ? (
                      <img
                        src={`${sharePostItem?.subject_id?.avatar_id?.base_url}${sharePostItem?.subject_id?.avatar_id?.attachment_url}`}
                      />
                    ) : (
                      <img src={userProfile} />
                    )}
                  </div>
                  <div className="user__detail-data">
                    <span className="user__name">
                      <strong>
                        {sharePostItem?.subject_id?.company_name ||
                          sharePostItem?.subject_id?.restaurant_name}{" "}
                      </strong>
                    </span>
                  </div>
                </div>
                <p className="mb-3">{sharePostItem?.body}</p>
                <div className="post_img">
                  {sharePostItem?.attachments?.length > 0 && (
                    <Carousel cols={1}>
                      {sharePostItem?.attachments?.map((item, index) => {
                        return (
                          <Carousel.Item>
                            <div key={index}>
                              <img
                                className="feed_img"
                                src={`${item.attachment_link?.base_url}${item.attachment_link?.attachment_url}`}
                              />
                            </div>
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  )}
                  {sharePostItem?.shared_post?.attachments?.length > 0 && (
                    <Carousel cols={1}>
                      {sharePostItem?.shared_post?.attachments?.map(
                        (item, index) => {
                          return (
                            <Carousel.Item>
                              <div key={index}>
                                <img
                                  src={`${item.attachment_link?.base_url}${item.attachment_link?.attachment_url}`}
                                />
                              </div>
                            </Carousel.Item>
                          );
                        }
                      )}
                    </Carousel>
                  )}
                </div>
                <div className="just__Now mt-3">
                  <p>{sharePostItem?.posted_at}</p>
                </div>
              </div>
              {/* inner form end */}
              <div className="btn_blue_full_w">
                <button
                  type="button"
                  className="btn"
                  onClick={() => sharePost(sharePostItem)}
                >
                  {sharePostSpinner ? <FormSpinner /> : `${lang?.only_share}`}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}

      {/* Delete Popup */}
      {deletePopup && (
        <div>
          <Modal
            isOpen={deletePopup}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                padding: "0",
                border: "0",
                borderRadius: "8px",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center popupheaderBg">
              <h4 className="page__title">{lang?.Delete}</h4>
              <button
                className="close_btn--modal"
                onClick={() => setDeletePopup(!deletePopup)}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box">
              <p className="warning">{lang?.sure_for_delete}</p>
              <div className="right__btn text-right mt-4">
                <Link
                  className="btn btn1"
                  to="#"
                  onClick={() => setDeletePopup(false)}
                >
                  {lang?.Cancel}
                </Link>
                <Link
                  className="btn btn1 btn2 ml-3"
                  to="#"
                  onClick={() => removePost(postId)}
                >
                  {removePostSpinner ? <FormSpinner /> : `${lang?.ok}`}{" "}
                </Link>
              </div>
            </div>
          </Modal>
        </div>
      )}

      {/* user photos view */}
      {isOpenImage && (
        <div className="image--light--pop">
          <ReactImageVideoLightbox
            data={userPhotosData}
            startIndex={imageIndex}
            showResourceCount={true}
            onCloseCallback={() => {
              setIsOpenImage(false);
              setImageIndex(0);
            }}
          // onNavigationCallback={(currentIndex) =>
          //     console.log(`Current index: ${currentIndex}`)
          // }
          />
        </div>
      )}
    </Fragment>
  );
}
