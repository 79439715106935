import React, { useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import Modal from "react-modal";
import FormSpinner from "../../Spinners/FormSpinner";
import {
  getBankDetailsData,
  paymentSetting,
  producerStripeConnect,
  getStripeConnectStatus,
  producerStripeDisconnect,
} from "../../../routes/marketplace/modules/marketplace";
import REACT_APP_API from "../../../config/environment";
const formField = { account_holder_name: "", bank_name: '', paypal_id: "", account_number: '', swift_code: '', country: '', city: '', default_payment: "paypal" }
export default function ProducerPaymentSettingView({ lang, isStripeConnected }) {
  const history = useHistory();
  const [formData, setFormData] = useState(formField);
  const [formErrors, setformErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [connectedStatus, setConnectedStatus] = useState(false);
  const [disconnectOpenModal, setDisconnectOpenModal] = useState(false);
  const [paymentMode, setpaymentMode] = useState("Paypal");
  const [whyStripe, setWhyStripe] = useState(false);
   const urlParams = new URLSearchParams(history?.location?.search);
  const stripe_id = urlParams.get("code");
  //Get bank details
  useEffect(()=>{
    const freeCall = async () => {
      getBankDetails();
      await stripeConnectedStatus();
    }
    freeCall()
  }, []);

  useEffect(()=>{
    const freeCall = async () => {
      if (stripe_id) {
        const data = {
          stripe_id: stripe_id,
        };
        const response = await producerStripeConnect(data);
        if (response?.success === 200) {
          await stripeConnectedStatus();
           history.push("/marketplace/dashboard");
        }
      }
    }
    freeCall()
  }, [stripe_id]);

  const stripeConnectedStatus = async () => {
    const response = await getStripeConnectStatus();
    if (response?.success === 200) {
      await setConnectedStatus(response?.connected);
    }
  };

  const disconnectFromStripe = async () => {
    await setLoader(true);
    const response = await producerStripeDisconnect();
    if (response?.success === 200) {
      await stripeConnectedStatus();
      await setDisconnectOpenModal(false);
      await setLoader(false);
    }
  };
  const getBankDetails = async () => {
    setLoader(true);
    const response = await getBankDetailsData();
    if (response?.success === 200) {
      let newData =
        response?.payment &&
        Object.keys(formField)?.map((key) =>
          setFormData((prevData) => ({
            ...prevData,
            id: response?.payment["id"],
            [key]: response?.payment[key],
          }))
        );
      setLoader(false);
    } else {
      console.log(response?.message);
      setLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setformErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setformErrors({});
    setLoading(true);
    if (formValidation(formData)) {
      formData["payment_option"] =
        paymentMode === "Paypal" ? "paypal" : paymentMode;
      const response = await paymentSetting(formData);
      if (response?.success === 200) {
        setformErrors((prev) => ({ ...prev, ["success"]: response?.message }));
        setLoading(false);
      } else {
        setLoading(false);
        setformErrors((prev) => ({ ...prev, ["errors"]: response?.message }));
      }
    } else {
      setLoading(false);
    }

    setTimeout(() => {
      setformErrors({});
    }, 2000);
  };

  const formValidation = (data) => {
    if (paymentMode === "Paypal" && formData?.paypal_id?.trim()?.length > 0)
      return true;
    let formValid = true;
    for (let key in data) {
      if (data[key]?.toString()?.trim()?.length === 0 && key !== "paypal_id") {
        formValid = false;
        setformErrors((prev) => ({ ...prev, [key]: lang?.Option_require }));
      }
    }
    return formValid;
  };

  const openStripeUrl = () => {
    localStorage.setItem("payTab", "2");
  };
  return (
    <>
      {formErrors?.success && (
        <p className="text-center pb-4" style={{ color: "green" }}>
          {formErrors?.success}.
        </p>
      )}
      {formErrors?.errors && (
        <p className="text-center pb-4" style={{ color: "red" }}>
          {formErrors?.errors}.
        </p>
      )}
      <div className="paymentForm">
        {/* <div className='paymentOptionSection'> */}
        {/* <h4 className='fs-16 font-bold'>{lang?.Mode_of_Payment}</h4> */}
        <h4 className="fs-16 font-bold mb-2">Stripe Connect</h4>
        <p>
          {lang?.stripeMsg}
        </p>
        <div className="btnBlock mt-4 flexBox gap-2 items-center">
          {connectedStatus ? (
            <button
              className="btn btn-blue stripe"
              onClick={() => setDisconnectOpenModal(true)}
            >
              {/* Disconnect your Stripe account */}
              {lang?.disconnectWithStripe}
            </button>
          ) : (
            <a
              onClick={openStripeUrl}
              href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${REACT_APP_API.ENV.CLIENT_ID}&scope=read_write&`}
            >
              <button className="btn btn-blue stripe">
                {lang?.connectWithStripe}
                {/* Connect with stripe */}
              </button>
            </a>
          )}
          <div>
            <a
              href="https://stripe.com/in/connect"
              target="_blank"
              className="text--black cursor-pointer"
            >
              {/* What is Stripe Connect? */}
              {lang?.whatsStripeConnect}

            </a>
          </div>
        </div>
        {/* <div className='customRadio'>
                        <input type="radio" id="radio1" name="paymentMode" checked={paymentMode === "Paypal"} value="Paypal" onChange={(e) => setpaymentMode(e.target.value)} />
                        <label className='fs-16 font-bold' htmlFor='radio1'>
                            <span>{lang?.Paypal}</span>
                        </label>
                    </div>
                    <div className='customRadio'>
                        <input type="radio" id="radio2" name="paymentMode" value="bank" checked={paymentMode === "bank"} onChange={(e) => { setpaymentMode(e.target.value) }} />
                        <label className='fs-16 font-bold' htmlFor='radio2'>{lang?.Bank_wire_transfer}</label>
                    </div>
                    <div className='hub--checkbox--area'>
                        {paymentMode === "bank" ? <input type='checkbox' className='form-check-input' value={"bank"} checked={"bank" === formData?.default_payment} id="check1" name="default_payment" onChange={(e) => setFormData({ ...formData, ["default_payment"]: "bank" })} />
                            : <input type='checkbox' className='form-check-input' value={"paypal"} checked={'paypal' === formData?.default_payment} id="check1" name="default_payment" onChange={(e) => setFormData({ ...formData, ["default_payment"]: "paypal" })} />}
                        <label className='fs-16 font-bold form-check-label' htmlFor='check1'>{lang?.Set_payment_option}</label>
                    </div> */}
        {/* </div> */}
        {/* {paymentMode === "bank" && <form onSubmit={handleSubmit}>
                    <div className='checkboxData'>
                        <div className="SavebtnBox">
                            <div className='product-title'>
                                {!loader && <div className='inner-btn-box' onClick={handleSubmit}>
                                    <a className="btn btn-blue  btnPrimary paymentBtn" >

                                        {loading ? `${lang?.Save}...` : <> <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
                                            <path d="M1.66667 0.5C0.754788 0.5 0 1.25479 0 2.16667V13.8333C0 14.7452 0.754788 15.5 1.66667 15.5H13.3333C14.2452 15.5 15 14.7452 15 13.8333V3.83333L11.6667 0.5H1.66667ZM1.66667 2.16667H10.9766L13.3333 4.52344V13.8333H1.66667V2.16667ZM3.33333 3.83333V6.33333H10V3.83333H3.33333ZM7.08333 8C6.5308 8 6.00089 8.21949 5.61019 8.61019C5.21949 9.00089 5 9.5308 5 10.0833C5 10.6359 5.21949 11.1658 5.61019 11.5565C6.00089 11.9472 6.5308 12.1667 7.08333 12.1667C7.63587 12.1667 8.16577 11.9472 8.55647 11.5565C8.94717 11.1658 9.16667 10.6359 9.16667 10.0833C9.16667 9.5308 8.94717 9.00089 8.55647 8.61019C8.16577 8.21949 7.63587 8 7.08333 8Z" fill="white" />
                                        </svg>
                                            {formData?.id ? lang?.Save : lang?.only_Submit} </>}

                                    </a>
                                    {' '}
                                </div>}
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="formLeft">

                                <div className="form__item">
                                    <label>{lang?.Account_number}</label>
                                    <input type="text" name="account_number" value={formData.account_number} onChange={handleChange} />
                                    {formErrors?.account_number && <p>{formErrors?.account_number}</p>}
                                </div>

                                <div className="form__item">
                                    <label>{lang?.Swift_Code}</label>
                                    <input type="text" name="swift_code" value={formData.swift_code} onChange={handleChange} />
                                    {formErrors?.swift_code && <p>{formErrors?.swift_code}</p>}
                                </div>

                                <div className="form__item">
                                    <label>
                                        {lang?.Country}
                                    </label>
                                    <input type='text' name='country' value={formData?.country} onChange={handleChange} />
                                    {formErrors?.country && <p>{formErrors?.country}</p>}
                                </div>
                            </div>

                            <div className='formLeft'>
                                <div className="form__item">
                                    <label>Bank Name</label>
                                    <input type="text" name="bank_name" value={formData.bank_name} onChange={handleChange} />
                                    {formErrors?.bank_name && <p>{formErrors?.bank_name}</p>}
                                </div>

                                <div className="form__item">
                                    <label>{lang?.Account_Holder_Name}</label>
                                    <input type="text" name="account_holder_name" value={formData.account_holder_name} onChange={handleChange} />
                                    {formErrors?.account_holder_name && <p>{formErrors?.account_holder_name}</p>}
                                </div>
                                <div className="form__item">
                                    <label>
                                        {lang?.City}
                                    </label>
                                    <input type='text' name='city' value={formData?.city} onChange={handleChange} />
                                    {formErrors?.city && <p>{formErrors?.city}</p>}
                                </div>

                            </div>
                        </div>
                    </div>

                </form>}
                {paymentMode === "Paypal" &&
                    <div className='checkboxData'>
                        <div className="SavebtnBox">
                            <div className='product-title'>
                              {!loader &&  <div className='inner-btn-box' onClick={(e) => formData?.paypal_id?.trim()?.length > 0 && handleSubmit(e)}>
                                    <a className="btn btn-blue  btnPrimary paymentBtn" >

                                        {loading ? `${lang?.Saving}...` : <> <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
                                            <path d="M1.66667 0.5C0.754788 0.5 0 1.25479 0 2.16667V13.8333C0 14.7452 0.754788 15.5 1.66667 15.5H13.3333C14.2452 15.5 15 14.7452 15 13.8333V3.83333L11.6667 0.5H1.66667ZM1.66667 2.16667H10.9766L13.3333 4.52344V13.8333H1.66667V2.16667ZM3.33333 3.83333V6.33333H10V3.83333H3.33333ZM7.08333 8C6.5308 8 6.00089 8.21949 5.61019 8.61019C5.21949 9.00089 5 9.5308 5 10.0833C5 10.6359 5.21949 11.1658 5.61019 11.5565C6.00089 11.9472 6.5308 12.1667 7.08333 12.1667C7.63587 12.1667 8.16577 11.9472 8.55647 11.5565C8.94717 11.1658 9.16667 10.6359 9.16667 10.0833C9.16667 9.5308 8.94717 9.00089 8.55647 8.61019C8.16577 8.21949 7.63587 8 7.08333 8Z" fill="white" />
                                        </svg>
                                            {formData?.id ? lang?.Save : lang?.Submit} </>}

                                    </a>
                                    {' '}
                                </div>}
                            </div>
                        </div>
                        <div className='w-50'>
                            <div className="form__item">
                                <label>{lang?.Paypal_id}</label>
                                <input type="text" name="paypal_id" value={formData?.paypal_id} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                } */}
      </div>

      {/* Why Connect Stripe */}
      {whyStripe && (
        <Modal
          isOpen={whyStripe}
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            },
            content: {
              position: "absolute",
              top: "0",
              left: "0%",
              right: "0%",
              bottom: "200px",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              padding: "0",
              border: "0",
              borderRadius: "8px",
              // outline: 'none',
            },
          }}
        >
          <div className="popup_header flex justify-between items-center popupheaderBg">
            <h4 className="page__title">What is stripe connect?</h4>
            <button
              className="close_btn--modal"
              onClick={() => setWhyStripe(!whyStripe)}
            >
              Close
            </button>
          </div>
          <div className="card card--block modal_box">
            <p className="">
              Stripe Connect is the fastest and easiest way to integrate
              payments into your platform or marketplace. Our APIs enable you to
              build and scale end-to-end payments experiences, from instant
              onboarding to global payouts, and create new revenue streams – all
              while Stripe handles payments KYC.
            </p>
          </div>
        </Modal>
      )}

      {disconnectOpenModal && (
        <Modal
          isOpen={disconnectOpenModal}
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            },
            content: {
              position: "absolute",
              top: "0",
              left: "0%",
              right: "0%",
              bottom: "200px",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              padding: "0",
              border: "0",
              borderRadius: "8px",
              // outline: 'none',
            },
          }}
        >
          <div className="popup_header flex justify-between items-center popupheaderBg">
            <h4 className="page__title">Disconnect Stripe</h4>
            <button
              className="close_btn--modal"
              onClick={() => setDisconnectOpenModal(!disconnectOpenModal)}
            >
              Close
            </button>
          </div>
          <div className="card card--block modal_box">
            <p className="warning">
              Are you sure you want to remove your Stripe Account?
            </p>
            <div className="right__btn text-right mt-4">
              {loader ? (
                <FormSpinner />
              ) : (
                <>
                  <Link
                    className="btn btn1"
                    to="#"
                    onClick={() => setDisconnectOpenModal(!disconnectOpenModal)}
                  >
                    Cancel
                  </Link>
                  <Link
                    className="btn btn1 btn2 ml-3"
                    to="#"
                    onClick={() => disconnectFromStripe()}
                  >
                    Disconnect
                  </Link>
                </>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

