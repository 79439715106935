import React from 'react';
import Slider from 'react-slick';
// Importing all brand logo images
import logo1 from '../../../assets/landing-page/brand-logo/222181.png';
import logo2 from '../../../assets/landing-page/brand-logo/406673.png';
import logo3 from '../../../assets/landing-page/brand-logo/492351.png';
import logo4 from '../../../assets/landing-page/brand-logo/599921.jpg';
import logo5 from '../../../assets/landing-page/brand-logo/695524.png';
import logo6 from '../../../assets/landing-page/brand-logo/968140.png';
import logo7 from '../../../assets/landing-page/brand-logo/1089230.png';
import logo8 from '../../../assets/landing-page/brand-logo/1265983.png';
import logo9 from '../../../assets/landing-page/brand-logo/1362003.png';
import logo10 from '../../../assets/landing-page/brand-logo/1847727.png';
import logo11 from '../../../assets/landing-page/brand-logo/2067483.png';
import logo12 from '../../../assets/landing-page/brand-logo/2103522.png';
import logo13 from '../../../assets/landing-page/brand-logo/2225223.png';
import logo14 from '../../../assets/landing-page/brand-logo/2354117.png';
import logo15 from '../../../assets/landing-page/brand-logo/2450674.png';
import logo16 from '../../../assets/landing-page/brand-logo/2482910.png';
import logo17 from '../../../assets/landing-page/brand-logo/2588563.png';
import logo18 from '../../../assets/landing-page/brand-logo/2592193.png';
import logo19 from '../../../assets/landing-page/brand-logo/2884570.png';
import logo20 from '../../../assets/landing-page/brand-logo/3034080.png';
import logo21 from '../../../assets/landing-page/brand-logo/3207754.png';
import logo22 from '../../../assets/landing-page/brand-logo/3233987.png';
import logo23 from '../../../assets/landing-page/brand-logo/3600942.png';
import logo24 from '../../../assets/landing-page/brand-logo/3612406.png';
import logo25 from '../../../assets/landing-page/brand-logo/3640469.png';
import logo26 from '../../../assets/landing-page/brand-logo/4075291.png';
import logo27 from '../../../assets/landing-page/brand-logo/4093937.png';
import logo28 from '../../../assets/landing-page/brand-logo/4196114.png';
import logo29 from '../../../assets/landing-page/brand-logo/4215493.png';
import logo30 from '../../../assets/landing-page/brand-logo/4247488.png';
import logo31 from '../../../assets/landing-page/brand-logo/4588423.png';
import logo32 from '../../../assets/landing-page/brand-logo/5028615.png';
import logo33 from '../../../assets/landing-page/brand-logo/5330149.png';
import logo34 from '../../../assets/landing-page/brand-logo/5790218.png';
import logo35 from '../../../assets/landing-page/brand-logo/5934209.png';
import logo36 from '../../../assets/landing-page/brand-logo/5977195.png';
import logo37 from '../../../assets/landing-page/brand-logo/6126739.png';
import logo38 from '../../../assets/landing-page/brand-logo/6287738.png';
import logo39 from '../../../assets/landing-page/brand-logo/6314847.png';
import logo40 from '../../../assets/landing-page/brand-logo/6574781.png';
import logo41 from '../../../assets/landing-page/brand-logo/6724585.png';
import logo42 from '../../../assets/landing-page/brand-logo/6805886.png';
import logo43 from '../../../assets/landing-page/brand-logo/6989508.png';
import logo44 from '../../../assets/landing-page/brand-logo/7362993.png';
import logo45 from '../../../assets/landing-page/brand-logo/7813904.png';
import logo46 from '../../../assets/landing-page/brand-logo/8137740.png';
import logo47 from '../../../assets/landing-page/brand-logo/8632969.png';
import logo48 from '../../../assets/landing-page/brand-logo/8654361.jpg';
import logo49 from '../../../assets/landing-page/brand-logo/8741525.png';
import logo50 from '../../../assets/landing-page/brand-logo/9001287.png';
import logo51 from '../../../assets/landing-page/brand-logo/9037340.png';
import logo52 from '../../../assets/landing-page/brand-logo/9117948.png';
import logo53 from '../../../assets/landing-page/brand-logo/9160506.png';
import logo54 from '../../../assets/landing-page/brand-logo/9330791.png';
import logo55 from '../../../assets/landing-page/brand-logo/9445802.png';
import logo56 from '../../../assets/landing-page/brand-logo/9451099.png';
import logo57 from '../../../assets/landing-page/brand-logo/9552332.png';
import AnimateFromBottom from './AnimateFromBottom';

const Brands = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 15000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Array of imported brand logo images
  const logos = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logo12,
    logo13,
    logo14,
    logo15,
    logo16,
    logo17,
    logo18,
    logo19,
    logo20,
    logo21,
    logo22,
    logo23,
    logo24,
    logo25,
    logo26,
    logo27,
    logo28,
    logo29,
    logo30,
    logo31,
    logo32,
    logo33,
    logo34,
    logo35,
    logo36,
    logo37,
    logo38,
    logo39,
    logo40,
    logo41,
    logo42,
    logo43,
    logo44,
    logo45,
    logo46,
    logo47,
    logo48,
    logo49,
    logo50,
    logo51,
    logo52,
    logo53,
    logo54,
    logo55,
    logo56,
    logo57,
  ];

  return (
    <div className="md:px-10 px-4 md:py-5 py-5">
      <AnimateFromBottom delay={0.2}>
        {' '}
        <p className="md:py-5 py-2 text-2xl font-semibold text-center">
          Trusted by
        </p>
      </AnimateFromBottom>
      <AnimateFromBottom delay={0.2}>
        <div className="slider-container overflow-hidden">
          <Slider {...settings}>
            {logos.map((logo, index) => (
              <div key={index}>
                <img
                  src={logo}
                  className="w-48"
                  alt={`Brand logo ${index + 1}`}
                />
              </div>
            ))}
          </Slider>
        </div>
      </AnimateFromBottom>
    </div>
  );
};

export default Brands;
