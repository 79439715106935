import React, { useState } from 'react';
import signUpIcon from '../../../assets/landing-page/signup-icon.svg';
import productIcon from '../../../assets/landing-page/product-icon.svg';
import listSearchIcon from '../../../assets/landing-page/list-search-icon.svg';
import mailShareIcon from '../../../assets/landing-page/mail-share-icon.svg';
import buyIcon from '../../../assets/landing-page/buy-icon.svg';
import messageUserIcon from '../../../assets/landing-page/message-user-icon.svg';
import buyerMockup from '../../../assets/landing-page/buyer-mockup.png';
import AnimateFromBottom from './AnimateFromBottom';
import language from "../../../helpers/language";

const Buyers = () => {
  const [openSection, setOpenSection] = useState("signUp");
  const lang = language.getLang();

  const handleToggle = (section) => {
    setOpenSection(prevSection => (prevSection === section ? null : section));
  };

  return (
    <div className='flex md:flex-row flex-col-reverse items-center'>
      
      <div className='md:w-6/12 md:py-8 py-5 pr-4 md:pr-0'>
        <AnimateFromBottom delay={0.2}>
          <img src={buyerMockup} className='market-place-img mr-auto' alt="Buyer Mockup" />
        </AnimateFromBottom>
      </div>
      
      <div className='md:w-6/12 md:px-14 px-4 md:py-8 py-5'>
        <AnimateFromBottom delay={0.2}>
          <p className='text-lg text-royal pb-2 uppercase font-medium'>{lang.Buyers_title}</p>
        </AnimateFromBottom>
        <AnimateFromBottom delay={0.3}> 
          <p className='font-bold text-2xl pb-4'>{lang.Buyers_subtitle}</p>
        </AnimateFromBottom>
        <AnimateFromBottom delay={0.4}>
          <p className='text-dim font-medium text-sm pb-3'>
            {lang.Buyers_description}
          </p>
        </AnimateFromBottom>
        
        {/* Accordion sections */}
        {[
          { id: 'signUp', icon: signUpIcon },
          { id: 'createStore', icon: productIcon },
          { id: 'productList', icon: listSearchIcon },
          { id: 'enquiry', icon: mailShareIcon },
          { id: 'sell', icon: buyIcon },
          { id: 'support', icon: messageUserIcon },
        ].map(({ id, icon }) => (
          <AnimateFromBottom delay={0.5} key={id}> 
            <div className='bg-dim px-3 py-2 mt-3 cursor-pointer' onClick={() => handleToggle(id)}>
              <div className='flex justify-between'>
                <div className='flex gap-2 items-center'>
                  <img src={icon} className='w-6' alt={`${lang.Buyers_sections[id].title} Icon`} />
                  <p className='font-bold text-base'>{lang.Buyers_sections[id].title}</p>
                </div>
                <span>
                  <i className={`${openSection === id ? "arrowUp" : "arrowDown"} fa fa-arrow-right`}></i>
                </span>
              </div>
              {openSection === id && (
                <p className='text-dim mt-3 font-medium'>{lang.Buyers_sections[id].description}</p>
              )}
            </div>
          </AnimateFromBottom>
        ))}
      
      </div>
    </div>
  );
};

export default Buyers;
