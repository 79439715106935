
import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, } from 'react-router';
import '../../../assets/marketplacecss/sendinquiry-style.css'
import language from '../../../helpers/language';
import { getMarketplaceProductDetails, saveProductInquiry } from '../modules/marketplace';
import { Link } from "react-router-dom";
import constant from '../../../helpers/constant';
import CustomUseEffect from '../../../components/constants';


export default function SendInquiryForm() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [inQuiryMessage, sendInQuiryMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false)
  const [lang] = useState(language.getLang());
  CustomUseEffect(async () => {
    if (id && id != 'mEnquiry') {
      await dispatch(getMarketplaceProductDetails(id));
    }
    window.scrollTo(0, 0);
  }, [id]);

  const {
    marketplaceProduct, cartProducts,
    marketPlaceSpinner
  } = useSelector((state) => state.marketPlaceReducerData);

  const { profileData: { data } } = useSelector((state) => state.memberProfileData);

  const submitInquiryMessage = async () => {
    window.scrollTo(0, 0);
    let user = data && data.user_data && data.user_data;
    const user_name = data && data.user_data.company_name || data && user.restaurant_name;

    let productId = returnProductData?.length > 0 && returnProductData[0].marketplace_product_id
    let productQuantity = returnProductData?.length > 0 && returnProductData[0]?.count
    const inQuiryData = {
      product_id: productId,
      product_ids: productId,
      product_quantities: productQuantity,
      name: user_name,
      email: data && data.contact_tab.email,
      phone: data && data.contact_tab.phone,
      message: inQuiryMessage,
    }

    if (returnProductData.length > 1) {
      const product_ids = returnProductData?.length > 0 && returnProductData?.map((product, index) => {
        return index == returnProductData?.length - 1 ? product?.marketplace_product_id : product?.marketplace_product_id + ',';
      }).join(' ');
      const product_quantities = returnProductData?.length > 0 && returnProductData?.map((product, index) => {
        return index == returnProductData?.length - 1 ? product?.count : product?.count + ',';
      }).join(' ');
      inQuiryData['product_ids'] = product_ids;
      inQuiryData['product_quantities'] = product_quantities;
    }

    if (inQuiryMessage === '') {
      setMessageError(lang?.enterMessageField)
    } else {

      const response = await dispatch(saveProductInquiry(inQuiryData));
      if (response.success === 200) {
        setIsOpenSuccessModal(true)
        // setSuccessMessage(response.message);
      } else {
        setSuccessMessage(response.errors.exception);
        setIsSuccess(true);
      }
    }
    setTimeout(() => {
      setMessageError('');
      setSuccessMessage('');
      setIsSuccess(false);
    }, 3000);
  }

  const returnProductData = useMemo(() => {
    if (id && id != 'mEnquiry') {
      // return [marketplaceProduct]
      return cartProducts;
    }
    return cartProducts;

  }, [id, marketplaceProduct, cartProducts])

  const onCancel = () => {
    setIsOpenSuccessModal(false)

    history.goBack()
  }

  return (
    <React.Fragment>


      {/*  @@START back button block */}
      <div className='discover_page mb-4'>
        <div className='page_top_form'>
          <div className="step">
            <div className="cancel">
              <Link to="/networking" onClick={() => history.goBack()}>
                <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;
                {lang?.Go_back}
              </Link>
            </div>
            <div className="right__align_btn btn-white">
            </div>
          </div>
        </div>
      </div>

      {/*  @@END back button block */}

      <div className="marketplace add-product sendEnqury bg-gray">
        {/* <div className="flex justify-between items-center">
        <a href="javascript:void(0)" className="back__btn" onClick={() => history.goBack()}>
          <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;
          {lang?.Go_back}
        </a>
      </div> */}
        {/* Banner Section */}
        <div className="main__content">
          <div className="section__wrapper">
            <div className="enquiry-popup-bg">
              <div className="popup-header flex justify-between items-center">
                <h4 className="product-title black mb--0 mt--0">{lang?.Send_Inquiry}</h4>
                {/* <a href="javascript:void(0)"><i className="fa fa-times" aria-hidden="true" /></a> */}
              </div>
              <div className="reset text-center ">
                <h5 className="heading--w text-Red" style={{ color: isSuccess ? 'red' : 'green' }}>{successMessage}</h5>
              </div>
              {/* @@ product list */}
              {(returnProductData?.length > 0) && returnProductData?.map((marketplaceProductItem) => {
                return <div className="flex justify-between items-center">
                  <p className="font-20 mt--2 mb--2">{marketplaceProductItem.title}</p>
                  {(data?.user_data?.role_id === constant.rolesId.importer || data?.user_data?.role_id === constant.rolesId.distributer || data?.user_data?.role_id === constant.rolesId['importer&distributer']) &&
                    marketplaceProductItem?.product_price > 0 && <h4 className="product-title black mt--2 mb--2">${marketplaceProductItem.product_price}</h4>}
                </div>
              })}
              {/* <div className="flex justify-between items-center">
                <p className="font-20 mt--2 mb--2">{marketplaceProduct.title}</p>
                {(data?.user_data?.role_id === constant.rolesId.importer || data?.user_data?.role_id === constant.rolesId.distributer || data?.user_data?.role_id === constant.rolesId['importer&distributer']) &&
                  marketplaceProduct?.product_price > 0 && <h4 className="product-title black mt--2 mb--2">${marketplaceProduct.product_price}</h4> }
                
              </div> */}
              <hr className="mt-0 mb--2" />
              <p className="mt-5 mb-5">
                {lang?.Fast_response}
              </p>
              {/* @@ Inquiry Form */}
              <div className="mrkt-place form">
                <div className="card-form">
                  <form className="configure-store-form">
                    <div className="flex flex-wrap main-row">
                      <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                        <div className="w-100">
                          <label>{lang?.Full_name} *</label>
                          <div className="form__item">
                            <input type="text" placeholder="Importer Name" id="store-name" defaultValue={data && data.user_data && data.user_data.company_name || data && data.user_data && data.user_data.restaurant_name} readOnly />
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                        <div className="w-100">
                          <label>{lang?.Email} *</label>
                          <div className="form__item">
                            <input type="text" placeholder="Importer Name" id="store-name" defaultValue={data && data.contact_tab && data.contact_tab.email} readOnly />
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                        <div className="w-100">
                          <label>{lang?.Phone_number} *</label>
                          <div className="form__item">
                            <input type="text" placeholder={`${lang?.Phone_number}`} id="store-name" defaultValue={data && data.contact_tab && `${data.contact_tab.country_code} ${data.contact_tab.phone}`} readOnly />
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding" />
                      <div className="w-full col-padding">
                        <div className="reset text-center ">
                          <h5 className="heading--w text-Red" style={{ color: 'red' }}>{messageError}</h5>
                        </div>

                        <div className="w-100">
                          <label>{lang?.Message} *</label>
                          <div className="form__item">
                            <textarea type="text" placeholder="Hi is this product available?" defaultValue={""} onChange={(event) => sendInQuiryMessage(event.target.value)} />
                          </div>
                        </div>
                      </div>
                      <div className="w-full col-padding">
                        <div className="w-100">
                          <div className="inner-btn-box right-btn mb-5">
                            <button type="button" className="btn btn-blue" onClick={() => submitInquiryMessage()}>{lang?.Send_Inquiry}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* @@START Confirmation Success modal */}

      {(isOpenSuccessModal) ?
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
  <div className="relative p-4 w-full max-w-md bg-white rounded-lg shadow-lg">
    {/* <button
      type="button"
      className="absolute top-3 right-3 text-blue-800 hover:bg-blue-200 hover:text-blue-900 rounded-lg text-sm w-8 h-8 flex items-center justify-center"
      onClick={onCancel}
    >
      <svg className="w-3 h-3 text-blue-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6" />
      </svg>
    </button> */}

    <div className="p-4 text-center">
      <svg xmlns="http://www.w3.org/2000/svg" className="w-14 shrink-0 fill-blue-800 inline" viewBox="0 0 512 512">
        <path d="M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z" />
        <path d="M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z" />
      </svg>

      <h3 className="mb-5 text-lg font-normal text-black">{lang?.sendQuiry_success?.slice(0,27)} <br />{lang?.sendQuiry_success?.slice(27)} </h3>

      <div className="flex justify-center space-x-3">
        <button
          onClick={onCancel}
          className="text-white bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5"
        >
          {'Close'}
        </button>
      </div>
    </div>
  </div>
</div>

        : null}

      {/* @@END Confirmation Success modal */}


    </React.Fragment>
  );
}
