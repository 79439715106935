import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AnimateFromBottom from '../../LandingPage/component/AnimateFromBottom';
import language from "../../../helpers/language";

const NewsLetter = () => {
  const [lang] = useState(language.getLang());

  return (
    <div className='md:px-40 px-4 md:py-10 py-5'>
      <AnimateFromBottom delay={0.4}>
        <div className="newsletter-container rounded-3xl flex justify-center flex-col md:py-16 py-8 md:px-14 px-6 gap-5 items-center">
          <div className='text-center'>
            <p className='font-bold text-3xl mb-3 text-white'>{lang.Join_Alysei}</p>
            <p className='font-normal text-sm text-white'>
              {lang.Sign_Up_Description}
            </p>
          </div>
          <Link to="/signup" className='bg-white text-royal py-2 px-12 rounded-md font-semibold'>{lang.Join_Now}</Link>
        </div>
      </AnimateFromBottom>
    </div>
  );
}

export default NewsLetter;