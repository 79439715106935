import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomUseEffect from "../constants";

export function Textarea({ type, label, placeholder, id, name, itemkey, onChange, value, }) {
  const [inputValue, setInputValue] = useState("");

  CustomUseEffect(async () => { await setInputValue(value) }, []);

  const handlechange = (e) => {
    setInputValue(e.target.value);
    onChange(e);
  };
  return (
    <Fragment>
      <div className="form__item relative" key={itemkey}>
        <label htmlFor={id}>{label}</label>
          <textarea 
            name={name}
            defaultValue={inputValue}
            placeholder={placeholder}
            id={id}
            onChange={(e) => handlechange(e)}
            rows={4}
            cols={40}
           />
      </div>
      <hr />
    </Fragment>
  );
}

export default Textarea;
