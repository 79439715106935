import React, { useState } from 'react';
import language from '../../../helpers/language';
import { Link } from 'react-router-dom';
import logoBlue from '../../../assets/landing-page/logo-blue.svg';
import REACT_APP_API from '../../../config/environment';
import { useHistory } from 'react-router-dom';
const Unsubscribe = () => {
  const [lang] = useState(language.getLang());
  const history = useHistory();
  const id = getQueryVariable('id');

  if (id) {
    unsubscribe();
  } else {
    history.push('/home');
  }

  async function unsubscribe(variable) {
    const action = 'unsubscribe';
    const response = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/unsubscribe`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, action }),
      }
    );
  }

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }

  return (
    <div className="unsubscribe-wrapper md:pt-40 pt-32 md:px-72 px-4 flex justify-center items-center">
      <div className="shadow-md rounded-md p-4 flex flex-col justify-center items-center gap-6 ">
        <img src={logoBlue} alt="Logo" className="w-32" />
        <div>
          <h2 className="text-xl font-bold ">{lang.unsubscribe_We_sorry}</h2>
          <h2 className="text-xl font-bold ">{lang.unsubscribe}</h2>
              <h2 className='text-xl font-bold '>{lang.unsubscribe_more}</h2>
        </div>

        <Link to="/" className="btn-royal py-2 px-3 font-semibold inline">
          Home
        </Link>
      </div>
    </div>
  );
};

export default Unsubscribe;
