import React, { useState } from 'react'
import officeLocations from "../../../assets/landing-page/about/office-locations.png"
import AnimateFromBottom from '../../LandingPage/component/AnimateFromBottom'
import language from "../../../helpers/language";
const OurPresence = () => {
  const [lang] = useState(language.getLang());
  return (
    <div className='md:px-14 px-4 md:py-8 py-5'>
      <AnimateFromBottom delay={0.2}>
      <p className='text-lg text-royal uppercase font-medium'>
      {lang.Our_Presence}
            </p>
        </AnimateFromBottom>
      <AnimateFromBottom delay={0.3}>
      <p className='font-bold text-2xl mb-4'>{lang.Alysei_Around_The_World}</p>
        </AnimateFromBottom>
          
          
      <div className='md:mx-20'>
      <AnimateFromBottom delay={0.4}>
          <img src={officeLocations} />
          </AnimateFromBottom>
          </div>
    
</div>
  )
}

export default OurPresence