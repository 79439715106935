import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import MembershipBar from "../../../components/membership/membershipBar";

import FormSpinner from "../../../components/Spinners/FormSpinner";
import auth from "../../../helpers/auth";
import constant from "../../../helpers/constant";
import language from "../../../helpers/language";
import { setProfileTabIndex } from "../../profile/modules/profile";
import { fetchMemberProgress } from "../modules/membership";
import CustomUseEffect from "../../../components/constants";



export default function Membership() {
  const dispatch = useDispatch();
  const [lang] = useState(language.getLang());
  const [userType, setuserType] = useState()
  const [isProfileStatus, setIsProfileStatus] = useState(0);
  const [profileScore, setProfileScore] = useState(0);


  const { getMemberProgressData, memberProgressSpinner } = useSelector((state) => state.memberProgressState);

  CustomUseEffect(async () => {
    await dispatch(fetchMemberProgress());
    await dispatch(setProfileTabIndex(0));
  }, []);

  CustomUseEffect(async () => {
    const userType = await auth.getUserType();
    await setuserType(JSON.parse(userType));
    const alyseiProfileStatus = localStorage.getItem('alysei_review');
    const score = localStorage.getItem('profile_percentage');
    await setIsProfileStatus(alyseiProfileStatus);
    await setProfileScore(score);
    
  }, [auth])

  return (
    <Fragment>
      {parseInt(userType?.role_id) === parseInt(constant.rolesId.voyagers) ?
        <Redirect to='/profile' /> :
        <div className="memberShip">
          <div className="flex flex-wrap">
            {/* Left Block */}
            <div className="md:w-4/6 sm:w-full sm:pb-0 md:pb-2 sm:pb-0">
              <div className="colLeftBlock">
                <div className="memberBlock">
                  <h2 className="font-32">{lang?.Alysei_Membership_Progress}</h2>
                  <p className="lead text-muted">
                    {lang?.Alysei_Membership_is_important_to_guarantee}
                  </p>
                  <div className="appNotiFication">
                    <h2>{lang?.Latest_Notifications}</h2>
                    <div className="alert alert-primary">
                      {isProfileStatus === '0' ? lang?.Your_membership_progress_is_pending_review : profileScore === '100' ? `${lang?.Profile_100_percent}${userType?.name} ${lang?.Certified_Alysei}` : lang?.Alysei_Certified}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Right Block */}
            <div className="md:w-2/6 sm:w-full sm:pb-0 md:pb-2">
              <div className="colRightBlock">
                <MembershipBar />
              </div>
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
}
