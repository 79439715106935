import React, { Fragment, useState, useEffect, useRef } from "react";

import Modal from "react-modal";
import PhoneInput from "react-phone-number-input";
import { useSelector, useDispatch } from "react-redux";
import auth from "../../../helpers/auth";
import Map from "../../../components/Map/Map_google";
import FormError from "../../../components/Errors/FormError";
import ReactFlagsSelect from "react-flags-select";
import {
    addFeatureProductList,
    setFieldValues,
    setFormErrors,
    updateContactDetail,
    memberProfileData,
    profileProgress,
    profileFormField
} from "../../profile/modules/profile";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import constant from "../../../helpers/constant"
import language from "../../../helpers/language";
import { default as country } from '../../profile/countryWithCode.json'
import CustomUseEffect from "../../../components/constants";

export const EditContact = (props) => {
    const contactMyRef = useRef(null);
    const [lang] = useState(language.getLang());
    const [value, setValue] = useState("+1");
    const [latitude, setLatitude] = useState(41.878);
    const [longitude, setLongitude] = useState(-87.63);
    const [contactModel, setcontactModel] = useState(true);
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [countryCode, setCountryCode] = useState("US");
    const [address, setAddress] = useState();
    const [addressLine1, setAddressLine1] = useState('');
    const [website, setWebsite] = useState('');
    const [fb_link, setFb_link] = useState('');
    const [inst_link, setInst_link] = useState('');
    const [contactTabModel, setcontactTabModel] = useState(false);
    const [contactResult, setContactResult] = useState(1);
    const [userType, setuserType] = useState();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState("");

    const {
        profileData: { data },
        formValue,
        errMessage,
        featureProduct,
        updateContactSpinner,
    } = useSelector((state) => state.memberProfileData);

    
    //console.log(data,"datadata");
    // const scrollToBottom = () => {
    //     contactMyRef.current?.scrollIntoView({ behavior: "smooth" });
    // };
    // useEffect(scrollToBottom, [contactResult]);
    
    const getCountryCode = (code) => country.data?.filter((item) => item?.isoCode == code);
    
    CustomUseEffect(async () => {
         
        const userType = await auth.getUserType();
        await setuserType(JSON.parse(userType));
        if (data?.contact_tab) {
            const { contact_tab, user_data } = data;
            // console.log(typeof contact_tab?.country_code, 'contact_tab..');
            if (contact_tab?.country_iso) {
                //let newCode = getCountryCode(parseInt(contact_tab?.country_code));
                await setCountryCode(contact_tab?.country_iso);
            } else if (contact_tab?.role_id === constant.rolesId.producer && !contact_tab?.country_code) {
                await setCountryCode("IT");
                await setValue("+39");
            }
            // (contact_tab?.role_id === constant.rolesId.producer) && await setCountryCode("IT");
            await setEmail(contact_tab?.email);
            await setPhone(contact_tab?.phone);
            contact_tab?.country_code && await setValue(`+${contact_tab?.country_code}`)
            await setAddress(contact_tab?.address);
            await setAddressLine1(contact_tab?.address1 !== null && contact_tab?.address1 != ''  ? (contact_tab?.address1) : "");
            await setWebsite(contact_tab?.website);
            const faceBookLink = contact_tab?.fb_link !== "null" ? contact_tab?.fb_link : ""
            await setFb_link(faceBookLink);
            const instagram = contact_tab?.inst_link !== 'null' ? contact_tab?.inst_link : "";
            await setInst_link(instagram);
            await setLatitude(user_data?.lattitude !== null ? parseFloat(user_data?.lattitude) : latitude);
            await setLongitude(user_data?.longitude !== null ? parseFloat(user_data?.longitude) : longitude);
        }
    }, [data]);


    const scrollToRef = (ref) => {
        contactMyRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleChange = async (e) => {
        const { name, value } = e.target
        if (e.target.name === "phone") {
            const regEx = /^[0-9\b]+$/;
            if (e.target.value === "" || regEx.test(e.target.value)) {
                setPhone(e.target.value);
            } else {
                setPhone(phone);
            }
        } else if (e.target.name == 'addressLine1'){
            setAddressLine1(e.target.value);
        }else {
            setPhone(phone);
        }
        formValue[e.target.id] = e.target.value;
        await dispatch(setFieldValues(formValue));
        if (e.target.id === 1) {
            featureProduct[e.target.id] = e.target.files[0];
            await dispatch(addFeatureProductList(featureProduct));
        } else {
            featureProduct[e.target.id] = e.target.value;
            await dispatch(addFeatureProductList(featureProduct));
        }
    };

    const handleBlur = async (e) => {
        const { name, value } = e.target
        if (name == 'addressLine1'){
            setAddressLine1(value);
        }
    };
    const testIfValidURL= (str) => {
        const pattern = new RegExp('^https?:\\/\\/' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      
        return !!pattern.test(str);
      }

    const updateContact = async () => {
        const web = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

        const facebookRegex = /^(https?:\/\/)?((w{3}\.)?)facebook.com\/(#!\/)?[a-z0-9_]/i;
        //console.log(data?.contact_tab?.role_id,"role_id");
        if(data?.contact_tab?.role_id !=10){
            if (phone === "" || phone == null) {
                dispatch(setFormErrors(lang?.enterPhoneNo, "phone"));
                scrollToRef();
                // } else if (phone?.length !== 8 || phone?.length !== 9 || phone?.length !== 10) {
            } else if (phone?.length < 8 || phone?.length > 11) {
                dispatch(setFormErrors(lang?.numericalDigit, "phone"));
                scrollToRef();
            } else if (!address) {
                dispatch(setFormErrors(lang?.enterAddress, "address"));
            } 
            // else if (userType.role_id !== constant.rolesId.voyagers && (website == "" || website == null) && (fb_link == "" || fb_link == null)) {
            //     dispatch(setFormErrors(lang?.enterWebsiteLink, "website"));
            // }
            //  else if (userType.role_id !== constant.rolesId.voyagers && web.test(website) === false && testIfValidURL(website) === false) {
            //     dispatch(setFormErrors(lang?.enterWebsiteLink, "website"));
            // }
            // else if(fb_link !='' && fb_link !=null && facebookRegex.test(fb_link) === false){
            //     //dispatch(setFormErrors("Please enter valid facebook link", "fb_link"));
            // } 
            else {
                updateProfile();
            }
        }
        else{
            updateProfile();
        }
        setTimeout(() => {
            dispatch(setFormErrors({ msg: "", field: "" }));
        }, 3000);
    };

    const updateProfile = async()=>{
        const model = {
            email: data && data.contact_tab && data.contact_tab.email,
            country_code: parseInt(value),
            country_iso : countryCode,
            phone: phone,
            address: address,
            address1 : addressLine1 === 'undefined' ? '' : addressLine1,
            website: website,
            fb_link: fb_link,
            inst_link : inst_link,
            longitude,
            lattitude: latitude
        };
        await dispatch(updateContactDetail(model)).then(async (res) => {
            if (res && res.success === 200) {
                setcontactModel(false);
                await dispatch(memberProfileData());
                await dispatch(profileProgress());
                await dispatch(profileFormField());
            } else {
                dispatch(setFormErrors(res?.errors, "website"));
                // await setContactResult(res?.errors)
                // setcontactModel(false);
                // await dispatch(profileFormField(token));
                // await dispatch(memberProfileData(token));
                // await dispatch(profileProgress(token));
            }
        });
    }

    const testWebsiteURLBlur = (event) => {
        var tarea_regex = /^(http|https)/;
        tarea_regex.test(String(event.target.value).toLowerCase()) ? setWebsite(event.target.value) : event.target.value !='' ? setWebsite(`https://${event.target.value}`) : setWebsite(event.target.value)
    }

    const testFBURLBlur = (event) => {
        var tarea_regex = /^(http|https)/;
        tarea_regex.test(String(event.target.value).toLowerCase()) ? setFb_link(event.target.value) : event.target.value !='' ? setFb_link(`https://${event.target.value}`) : setFb_link(event.target.value)
    }

    const testInstURLBlur = (event) => {
        var tarea_regex = /^(http|https)/;
        tarea_regex.test(String(event.target.value).toLowerCase()) ? setInst_link(event.target.value) : event.target.value !='' ? setInst_link(`https://${event.target.value}`) : setInst_link(event.target.value)
    }
    const handleWebsiteAndFacebookChange = async (event) => {
        let name = event.target.name;
        if (name === "website") {
            setWebsite(event.target.value);
            
        } if (name === "fb_link") {
            // (data && data.contact_tab && data.contact_tab.fb_link !== null && fb_link.length === 0) || (data && data.contact_tab && data.contact_tab.fb_link === null && (fb_link && fb_link.length === null || fb_link && fb_link.length === 1)) ? setFb_link(`www.facebook.com/${event.target.value}`) : setFb_link(event.target.value);
            setFb_link(event.target.value)
        }
        if (name === "inst_link") {
            setInst_link(event.target.value);
            
        }
    }

    const inputSelectCountry = async(code) => {
        await setCountryCode(code);
        let dial_Code = await getCountryCode(code);
        await setValue(dial_Code[0]?.dialCode);
        console.log(dial_Code[0]?.dialCode,"dialCode");
    }

    return (
        <Fragment>
            {/* Tab Content left Section */}
            <Modal
                ariaHideApp={false}
                isOpen={contactModel}
                style={{
                    overlay: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.75)",
                    },
                    content: {
                        position: "absolute",
                        top: "140px",
                        left: "30%",
                        right: "30%",
                        bottom: "200px",
                        border: "1px solid #ccc",
                        background: "#fff",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "4px",
                        // outline: 'none',
                        padding: "20px",
                    },
                }}
            >
                <button className="close_btn--modal" onClick={() => setcontactModel(!contactModel)} > {lang?.close} </button>
                <div
                    className={contactTabModel ? "contact-edit__form profile__option--selected" : "contact-edit__form "}>
                    <form ref={contactMyRef}>
                        <div className="form__item">
                            <label htmlFor="email">{lang?.Email}</label>
                            <input
                                type="email"
                                placeholder={`${lang?.Enter_email}`}
                                id="email"
                                name="email"
                                disabled
                                defaultValue={email}
                            />
                        </div>
                        <div className="form__item phoneCountryCode">
                            <label htmlFor="phone">{lang?.Phone_number}</label>
                            <div className="selectedCountry">
                                <div className="flexBox countryFlagLeft">
                            <div className="flagCountry">
                            <ReactFlagsSelect
                                className="flagDropDown"
                                selected={countryCode}
                                onSelect={(code) => inputSelectCountry(code)}
                            />
                            </div>
                            <div className="countryCode">
                                <input type="text" value={value} className="form-control"/>
                            </div>
                            </div>
                            <div className="phoneNo">
                                <input className="form-control"
                                type="text"
                                placeholder={`${lang?.NoPlace_holder}`}
                                id="phone"
                                name="phone"
                                maxLength={11}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(e) => { handleChange(e) }}
                                defaultValue={phone}
                            />
                            </div>
                            </div>

                        </div>
                        {/* <div className="form__item phoneCountryCode">
                            <label htmlFor="phone">{lang?.Phone_number}</label>
                            <PhoneInput  
                            international defaultCountry={countryCode?.toString()} onChange={(e) => setValue(e)} defaultValue={value}/>
                            <input className="phone_number"
                                type="text"
                                placeholder={`${lang?.NoPlace_holder}`}
                                id="phone"
                                name="phone"
                                maxLength={11}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(e) => { handleChange(e) }}
                                onSelect={(e) => { handleChange(e) }}
                                defaultValue={phone}
                            />
                        </div> */}
                        {errMessage.field == "phone" ? (
                            <div>
                                <FormError msg={errMessage.msg} />
                            </div>
                        ) : null}
                        <div className="form__item">
                            <div className="form__item mt-2 mb-2" style={{ paddingBottom: 40 }} >
                                <Map
                                    google={props.google}
                                    center={{ lat: latitude, lng: longitude, address1: addressLine1}}
                                    height="300px"
                                    zoom={15}
                                    onChange={(address, latValue, lngValue) => {
                                        formValue["address"] = address;
                                        setAddress(address);
                                        setLatitude(latValue);
                                        setLongitude(lngValue);
                                    }}
                                    name="address"
                                    value={data.contact_tab?.address}
                                />
                            </div>
                        </div>
                        <div className="form__item">
                            {errMessage.field === "address" ? (
                                <div><FormError msg={errMessage.msg} /></div>
                            ) : null}
                        </div>
                        <div className="form__item">
                            <label htmlFor="add">{lang?.HouseAndFlatNo}</label>
                            <input
                                type="text"
                                placeholder={`${lang?.HouseAndFlatNo}`}
                                id="addressLine1"
                                name="addressLine1"
                                onChange={(e) => {
                                    handleChange(e);
                                    setAddressLine1(e.target.value);
                                }}
                                onBlur={(e) => handleBlur(e)}
                                defaultValue={addressLine1}
                            />
                        </div>

                        {errMessage.field === "addressLine1" ? (
                            <div ><FormError msg={errMessage.msg} /></div>
                        ) : null}

                        <div className="form__item">
                            <label htmlFor="web">{lang?.Website}</label>
                            <input
                                type="text"
                                placeholder={`${lang?.Website}`}
                                id="website"
                                name="website"
                                value={website === 'undefined' ? '' : website}
                                onBlur={(e) => {
                                    testWebsiteURLBlur(e);
                                }}
                                onChange={(e) => {handleWebsiteAndFacebookChange(e)}}
                            // onClick={() => { website === null && setWebsite('https://'); }}
                            />
                        </div>

                        {errMessage.field == "website" ? (
                            <div><FormError msg={errMessage.msg} /></div>
                        ) : null}
                        {/* <h4 style={{color:"red"}}>{contactResult}</h4> */}
                        <div className="form__item">
                            <label htmlFor="fb">{lang?.Facebook}</label>
                            <input
                                type="text"
                                placeholder={`${lang?.Facebook}`}
                                id="fb_link"
                                name="fb_link"
                                value={fb_link}
                                onBlur={(e) => {
                                    testFBURLBlur(e);
                                }}
                                onChange={(e) => {
                                    handleWebsiteAndFacebookChange(e);
                                }}
                            // onClick={() => {fb_link === null&& setFb_link('https://facebook.com/'); }}
                            />
                        </div>
                        {errMessage.field == "fb_link" ? (
                            <div>
                                <FormError msg={errMessage.msg} />
                            </div>
                        ) : null}

                        <div className="form__item">
                            <label htmlFor="fb">Instagram</label>
                            <input
                                type="text"
                                placeholder="Instagram"
                                id="inst_link"
                                name="inst_link"
                                value={inst_link}
                                onBlur={(e) => {
                                    testInstURLBlur(e);
                                }}
                                onChange={(e) => {
                                    handleWebsiteAndFacebookChange(e);
                                }}
                            />
                        </div>
                        {errMessage.field == "inst_link" ? (
                            <div>
                                <FormError msg={errMessage.msg} />
                            </div>
                        ) : null}
                        <div className="form__action form__item">
                            <button type="button" onClick={() => updateContact()}>
                                {updateContactSpinner ? <FormSpinner /> : `${lang?.Update}`}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </Fragment>
    );
};
