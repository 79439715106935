import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getAddressList, createOrderIntent, createOrder } from '../modules/marketplace';
import BillingForm from '../../../components/Checkbox/MarketPlaceCheckOutForm';
import REACT_APP_API from '../../../config/environment'
import { getProductDetailsOnType, getSingleProductTax, getTotalPriceOfAllProduct } from '../modules/marketplaceHelper';
import { newAddressFields } from '../../../components/Dashboard/AddressFormFields/AddressData';
import StripePayment from '../../../components/Stripe/StripePayment';
import { getRegion } from '../../register/modules/register';
import language from '../../../helpers/language';

export default function MarketPlaceCheckout() {
    const stripePromise = loadStripe(REACT_APP_API.ENV.REACT_APP_STRIPE_KEY, { stripeAccount: localStorage.getItem('stripe_id') });
    const dispatch = useDispatch();
    const myRef = useRef(null);
    const [errors, setErrors] = useState({});
    const [lang] = useState(language.getLang());
    const [responseErrors, setResponseErrors] = useState({ message: '', type: '' });
    const [loading, setLoading] = useState(false);
    const [selectAddress, setSelectAddress] = useState(false);
    const [selectShippingAddress, setSelectShippingAddress] = useState(false);
    const [formFields, setFormFields] = useState(newAddressFields);
    let { cartProducts } = useSelector((state) => state.marketPlaceReducerData);
    const [selectAddressId, setSelectAddressId] = useState({ billing_address_id: '', shipping_address_id: '' });
    const [formType, setFormType] = useState('');
    const [formTypeShipping, setFormTypeShipping] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    const [shippingPriceInfo, setShippingPriceInfo] = useState('')
    const [billingAddressList, setBillingAddressList] = useState([]);
    const [shippingAddressList, setShippingAddressList] = useState([]);
    const [reRender, setRerender] = useState(false);
    const [isSameAsShipping, setIsSameAsShipping] = useState(false);
    const [billingFormData, setBillingFormData] = useState({});
    const [shippingFormData, setShippingFormData] = useState({});
    const [clientSecret, setClientSecret] = useState('');
    const [clientIntentId, setClientIntentId] = useState('');
    const [stripeId, setStripeId] = useState('');
    let { regions } = useSelector((state) => state.register);

    useEffect(() => {
        regions?.length === 0 && getCountryRegion(233);
    }, []);

    useEffect(() => {
        const stripe_id = localStorage.getItem('stripe_id') ? localStorage.getItem('stripe_id') : '';
        setStripeId(stripe_id);
    }, [stripeId])

    useEffect(() => {
        cartProducts && setTotalAmount(getTotalPriceOfAllProduct(cartProducts, false) + cartProducts[0]?.shipping_price);
    }, [cartProducts]);

    useEffect(() => {
        getAllAdrress();
    }, [reRender]);

    useEffect(() => {
        if (isSameAsShipping) {
            setFormFields(formFields?.map((item) => ({ ...item, value: billingFormData[item?.name] })))
        } else {
            setFormFields(newAddressFields);
        }
    }, [isSameAsShipping]);

    useEffect(() => {
        totalAmount && stripeId && makePaymentIntent();
    }, [totalAmount, stripeId]);

    const makePaymentIntent = async () => {
        let tAmount = returnTotalPrice;//totalAmount
        const response = totalAmount > 0 && await createOrderIntent(tAmount, stripeId);
        if (response?.success) {
            setClientSecret(response?.secret_key?.client_secret)
            setClientIntentId(response?.secret_key?.id)
        }
    }

    const getCountryRegion = async (id) => {
        await dispatch(getRegion(id));
    }


    // stripe view points
    const appearance = {
        // If you are planning to extensively customize rules, use the "none"
        // theme. This theme provides a minimal number of rules by default to avoid
        theme: 'stripe',

        rules: {
            '.Tab': {
                border: '1px solid #E0E6EB',
                boxShadow:
                    '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
            },

            '.Tab:hover': {
                color: 'var(--colorText)',
            },

            '.Tab--selected': {
                borderColor: '#E0E6EB',
                boxShadow:
                    '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
            },

            '.Input--invalid': {
                boxShadow:
                    '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
            },

            // See all supported class names and selector syntax below
        },
    };

    // Get Billing and Delivery address
    const getAllAdrress = async () => {
        const response = await getAddressList();
        if (response?.success) {
            setBillingAddressList(response.billing_address ?? []);
            setShippingAddressList(response.shipping_address ?? []);
            const shippingId = response.shipping_address?.find((item) => item?.checked);

        }
    }

    const hadleSubmitData = () => {
        setRerender(!reRender);
    }

    //order create
    const createProductOrder = async () => {
        setLoading(true);
        setResponseErrors({ message: '', type: "" });
        const { billing_address_id, shipping_address_id } = selectAddressId;
        if ((billing_address_id !== '' && (shipping_address_id !== '' || isSameAsShipping)) || ((billing_address_id !== '' || billingFormData?.first_name?.trim()?.length > 0) && (shipping_address_id !== '' || shippingFormData?.first_name?.trim()?.length > 0 || isSameAsShipping))) {
            const getValueOnType = getProductDetailsOnType(cartProducts?.filter((item) => item?.available_status !== 2));
            // const total_amount = totalAmount;
            const total_amount = returnTotalPrice;
            let shippingPrice=shippingPriceInfo?.shipping_price > 0 ? Number(shippingPriceInfo?.shipping_price) : 0;
            const total_tax = getTotalPriceOfAllProduct(cartProducts, true) ?? 0;
            const net_total = total_amount - total_tax - shippingPrice;
            let paymentData = {
                store_id: cartProducts && cartProducts[0]?.marketplace_store_id,
                product_id: getValueOnType("marketplace_product_id") ?? [],
                product_quantity: getValueOnType("count") ?? [],
                product_price: getValueOnType("product_price") ?? [],
                offer_map_id: cartProducts[0]?.map_offer_id,
                // shipping_total: cartProducts[0]?.shipping_price,
                shipping_total: shippingPriceInfo?.shipping_price > 0 ? Number(shippingPriceInfo?.shipping_price) : 0,
                billing_address: billingFormData,
                shipping_address: shippingFormData,
                same_billing_address: isSameAsShipping,
                billing_address_id,
                shipping_address_id,
                total_amount,
                total_tax,
                net_total,
                returning: 0,
                returning_customer: 0,
                intent_id: clientIntentId
            }
            const response = await createOrder(paymentData);
            if (response.success === 200) {
                return { orderStatus: true, orderId: response?.order_id };
            } else {
                setLoading(false);
                setResponseErrors({ message: response?.message ?? lang?.Order_errors, type: 'common' });
                scrollToRef(myRef);
                return { orderStatus: false, orderId: null };
            }
        } else {
            setLoading(false);
            setResponseErrors((billing_address_id !== '' || billingFormData?.first_name?.trim()?.length > 0) ? { message: lang?.Shipping_address_warning, type: "shipping" } : { message: lang?.Billing_address_warning, type: "billing" });
            scrollToRef(myRef);
            return { orderStatus: false, orderId: null };
        }
    }

    const handleModal = (type) => {
        setFormType(type);
    }

    const handleOpenForm = (formType) => {
        if (formType === 'billing') {
            setSelectAddress(false);
            setFormType('');
        } else {
            setSelectShippingAddress(false);
            setFormTypeShipping('');
            setShippingPriceInfo('')
        }


    }

    // @@ create prodect json for shippng price api
    const jsonForShippingPrice = () => {
        return cartProducts?.map((i) => {
            return { 'product_id': i.marketplace_product_id, "quantity": i?.count }
        })
    }

    //  @@ return total price with shipping price
    const returnTotalPrice = useMemo(() => {
        let price = totalAmount;
        if (shippingPriceInfo && shippingPriceInfo?.shipping_price) {
            price = totalAmount + Number(shippingPriceInfo?.shipping_price)
        }
        return price;
    }, [totalAmount, shippingPriceInfo])


    // @@ same as address calculate shipping price
    const handleSameAsAddress = (e) => {
        let isCheked = e.target.checked;
        console.log('billing_address_id', selectAddressId?.billing_address_id, billingAddressList);
        if (isCheked == true) {
            // handleSameAsAddress
            let addressId = selectAddressId?.billing_address_id;
            console.log('addressIdaddressId',addressId)
            if (addressId) {
                handleShippingAddressCalPrice(selectAddressId?.billing_address_id, 'billing')
            } else {
                let state = billingFormData?.state;
                if (state) {
                    handleShippingAddressCalPrice(state, 'state')
                }
            }
        } else {
            setShippingPriceInfo('');
        }
    }
    // @@ handle selected address shippig price calculation
    const handleShippingAddressCalPrice = async (id, type) => {

        let isShipping=localStorage.getItem('is_sh_pr');
        if(isShipping != true && isShipping !='true' ){
            setShippingPriceInfo('');
            return;
        }
        console.log('shippingAddressList', shippingAddressList, id);
        let addressList = type == 'billing' ? billingAddressList : shippingAddressList;
        let state;
        if (type != 'state') {
            let findAddress = addressList?.find((item) => item?.id == id);
            console.log('findAddressfindAddress', findAddress)
            state = findAddress?.state
        } else {
            state = id;
        }
        if (state && cartProducts.length > 0) {
            let productJson = jsonForShippingPrice();
            console.log('productJson', productJson, state);
            let jsonObj = {
                'products': productJson,
                'state_name': state
            }
            const token = localStorage.getItem("ACCESS_TOKEN");
            let api_url = `${REACT_APP_API.ENV.REACT_APP_API}/shipping/charges`

            const response = await fetch(api_url, {
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}`, },
                body: JSON.stringify(jsonObj),
            }
            );

            if (response.ok) {
                const result = await response.json();
                console.log('resultresult', result, response);
                let shippingPrice = result?.shipping_price;
                setShippingPriceInfo(result);
                // setShippingPrice(Number(shippingPrice))
            } else {
                setShippingPriceInfo('');
                // setShippingPrice(0)
            }
        }
    }

    const handleShippingPrice = (state, type) => {
        if (type === "billing" && isSameAsShipping == true) {
            handleShippingAddressCalPrice(state, 'state')
        } else if (type != 'billing') {
            handleShippingAddressCalPrice(state, 'state')
        }
    }

    const scrollToRef = (ref) => {
        window.scroll(0, ref.current && ref.current.offsetTop - 200);
    };

    return (
        <>
            <div className='check_Flex'>
                <div className='checkout'>
                    <div className='order-address order__Checkout'>
                        {/*Billing address section */}

                        <div className='order-Detail mt-4'>
                            {responseErrors?.type === "billing" && <h4 className="textRed text-center pb-4" ref={myRef}>{responseErrors?.message}</h4>}
                            <h1 className='relative'>
                                1. {lang?.Add_billing_details}*
                                <span className='aadWarning' title={lang?.Add_reflected_in_the_bill}>
                                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                </span>
                                {/* <ReactTooltip /> */}
                            </h1>
                            <br />
                            <div className='addressDrop'>
                                {billingAddressList?.length > 0 && <>
                                    <div className='addressItem'>
                                        <select name='billing_address_id' value={selectAddressId?.billing_address_id} onChange={(e) => { (isSameAsShipping && handleShippingAddressCalPrice(e?.target.value, 'billing')); setSelectAddressId({ ...selectAddressId, [e.target.name]: e.target.value ? parseInt(e.target.value) : "" }); setSelectAddress(false); setResponseErrors({ message: '', type: "" }); }}>
                                            <option value="">{lang?.Select_an_address}</option>
                                            {billingAddressList?.map(({ first_name, last_name, street_address, street_address_2, zipcode, country, city, id, state, email }, i) => {
                                                return (
                                                    <optgroup key={id} label={`${first_name} ${last_name}`} >
                                                        <option value={id}>
                                                            {street_address} {street_address_2} {city} {state} {country} {zipcode}
                                                        </option>
                                                    </optgroup>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </>}
                                {!selectAddress && <p className='add_billing text-blue' onClick={() => { handleModal("billing"); setSelectAddress(!selectAddress); setSelectAddressId({ ...selectAddressId, ["billing_address_id"]: '' }); }}>+  {lang?.Add_billing_details}</p>}
                            </div>
                            <br />
                            {(selectAddress && formType === "billing") && <BillingForm handleShippingPrice={(state) => handleShippingPrice(state, 'billing')} lang={lang} newAddressFields={newAddressFields} formData={billingFormData} setFormData={setBillingFormData} formType={formType} setErrors={setErrors} errors={errors} loading={loading} setLaoding={setLoading} hadleSubmitData={hadleSubmitData} handleCancel={handleOpenForm} submitBtn={false} />}
                        </div>

                        {/*Shipping address section */}

                        <div className='order-Detail'>

                            {responseErrors?.type === "shipping" && <h4 className="textRed text-center pb-4" ref={myRef}>{responseErrors?.message}</h4>}
                            {(selectAddressId?.billing_address_id !== '' || billingFormData?.first_name?.trim()?.length > 0) && <div className='hub--checkbox--area'>
                                <input type='checkbox' className='form-check-input' id="check1" name="select_item" onChange={(e) => {
                                    setIsSameAsShipping(e.target.checked);
                                    setSelectAddressId({ ...selectAddressId, ["shipping_address_id"]: '' });
                                    setResponseErrors({ message: '', type: "" });
                                    localStorage.setItem("isSameAddress", e.target.checked);
                                    handleSameAsAddress(e)
                                }} />
                                <label className='fs-16 font-bold form-check-label' htmlFor='check1'>{lang?.Set_shipping_details}</label>
                            </div>}
                            <br />
                            {!isSameAsShipping && <>
                                <h1 className='relative'>2. {lang?.Add_shipping_details}*
                                    <span className='aadWarning' title="The address where you want the product to be delivered">
                                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                    </span>
                                </h1>
                                <br />
                                <div className='addressDrop'>
                                    {shippingAddressList?.length > 0 && <div className='addressItem '>
                                        <select name='shipping_address_id' value={selectAddressId?.shipping_address_id} onChange={(e) => { handleShippingAddressCalPrice(e?.target.value, 'shipping'); setSelectAddressId({ ...selectAddressId, [e.target.name]: e.target.value ? parseInt(e.target.value) : "" }); setSelectShippingAddress(false); setResponseErrors({ message: '', type: "" }); }}>
                                            <option value="">{lang?.Select_an_address}</option>
                                            {shippingAddressList?.map(({ first_name, last_name, street_address, street_address_2, zipcode, country, city, id, state, email }, i) => {
                                                return (
                                                    <optgroup key={id} label={`${first_name} ${last_name}`} >
                                                        <option value={id}>
                                                            {street_address} {street_address_2} {city} {state} {country} {zipcode}
                                                        </option>
                                                    </optgroup>
                                                )
                                            })}
                                        </select>
                                    </div>}

                                    {!selectShippingAddress && <p className='add_billing text-blue' onClick={() => { setShippingPriceInfo(''); setFormTypeShipping("shipping"); setSelectShippingAddress(!selectShippingAddress); setSelectAddressId({ ...selectAddressId, ["shipping_address_id"]: '' }); }}>+  {lang?.New_address_added}</p>}
                                </div>
                                {(selectShippingAddress && formTypeShipping === "shipping") && <BillingForm handleShippingPrice={(state) => handleShippingPrice(state, 'shipping')} lang={lang} newAddressFields={formFields}
                                    formData={shippingFormData} setFormData={setShippingFormData} isSameAsShipping={isSameAsShipping}
                                    setErrors={setErrors} errors={errors} loading={loading} setLaoding={setLoading}
                                    formType={formTypeShipping} hadleSubmitData={hadleSubmitData} handleCancel={handleOpenForm} submitBtn={false} />}
                            </>}

                        </div>

                        {/*payment section */}
                        <div className='order-Detail'>
                            {responseErrors?.type === "common" && <h4 className="textRed text-center pb-4" ref={myRef}>{responseErrors?.message}</h4>}
                            <h1>3.  {lang?.Select_Payment_Method}</h1>
                            <br />
                            {clientSecret && stripePromise && (<Elements stripe={stripePromise} options={{ clientSecret, appearance }}
                            >
                                <div>
                                    <br />
                                    <StripePayment lang={lang} scrollToRef={scrollToRef} forwardedRef={myRef} setResponseErrors={setResponseErrors} responseErrors={responseErrors} createProductOrder={createProductOrder} loader={(loading)} setLoading={setLoading} clientSecret={clientSecret}
                                        totalAmount={returnTotalPrice} />
                                </div>
                            </Elements>)}
                        </div>
                    </div>
                </div>

                {/*cart product view section */}
                <div className='checkout_left rightBlockheight'>
                    <div className='order_summm'>
                        <div className='scrollBlock'>
                            {cartProducts?.filter((product) => product?.available_status !== 2)?.map(({ marketplace_product_id, title, slug, product_gallery, avg_rating, count, product_price, get_product_tax }, i) => {
                                let prodImg = product_gallery && product_gallery[0];
                                let shippingP = (shippingPriceInfo && shippingPriceInfo?.charge?.length > 0) ? shippingPriceInfo?.charge?.find((i) => marketplace_product_id == i?.product_id)?.price : 0
                                let shippingPrice = (shippingP) ? shippingP : 0
                                console.log('shippingPriceshippingPrice', shippingPrice, marketplace_product_id)
                                return (
                                    <div key={marketplace_product_id}>
                                        <div >
                                            <div className='checkDetails'>
                                                <Link to={`/marketplace/product/${slug}`} >  <img src={`${prodImg?.base_url}${prodImg?.attachment_medium_url}`} alt='product_img' /></Link>
                                                <h4 className='font-18 black mt-0'>{title}</h4>
                                            </div>
                                        </div>
                                        <div className='summ_Details'>
                                            <p className='order_head'>{lang?.Order_summary}</p>
                                            <div className='sum_Details'>
                                                <table>
                                                    <thead></thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className='ProductTitleName'>{title}<span className='crossIcon'> x </span>{count}</td>
                                                            <td className='text-right'>${product_price}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{lang?.Tax}</td>
                                                            <td className='text-right'>${getSingleProductTax(product_price, count, get_product_tax, true)}</td>
                                                        </tr>
                                                        {(shippingPrice > 0) &&
                                                            <tr>
                                                                <td>{lang?.Shipping_charge}</td>
                                                                <td className='text-right'>${shippingPrice}</td>
                                                            </tr>}
                                                        <tr>
                                                            <td>{lang?.Total}</td>
                                                            <td className='text-right'>${Number(shippingPrice) + getSingleProductTax(product_price, count, get_product_tax, false)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {/* <div>
                                                    <p className='ProductTitleName'>{title}<span className='crossIcon'> x </span>{count}</p>
                                                    <p>{lang?.Tax}</p>
                                                    <p>{lang?.Total}</p>
                                                    <br></br>
                                                </div>
                                                <div>
                                                    <p>${product_price}</p>
                                                    <p>${getSingleProductTax(product_price, count, get_product_tax, true)}</p>
                                                    <p>${getSingleProductTax(product_price, count, get_product_tax, false)}</p>
                                                    <br></br>
                                                </div> */}

                                            </div>
                                        </div>
                                        {i + 1 < cartProducts?.length && <hr />}
                                    </div>
                                )
                            })}
                        </div>
                        <div className='order_total fixedBottom'>
                            {/* {cartProducts[0]?.shipping_price > 0 &&
                                <h4 className='flexBox justify-between mb-2'>{lang?.Shipping_charge} : <span>${cartProducts[0]?.shipping_price}</span></h4>
                                
                                } */}

                            {shippingPriceInfo?.shipping_price > 0 &&
                                <h4 className='flexBox justify-between mb-2'>Total {lang?.Shipping_charge} <span>${shippingPriceInfo?.shipping_price}</span></h4>
                            }

                            <h4 className='flexBox justify-between'>{lang?.Order_total} <span>${cartProducts && returnTotalPrice}</span></h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

