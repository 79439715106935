import React from 'react'
import { Helmet } from 'react-helmet'
const Voyagers = () => {
    return (
        <Helmet>
            <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />

            {/* <!-- Primary Meta Tags --> */}
            <title>Best Italian Cuisine & Culinary Adventures USA | Alysei</title>
            <meta name="description" content="Join a vibrant community dedicated to Italian gastronomy with Alysei. Explore authentic recipes, cooking tips, food photography, and stay updated on Italian food festivals and trends for an unforgettable culinary journey." />
            <meta name="keywords" content="Italian Culinary Voyagers, Italian Gastronomy Exploration USA, Italian Cuisine Enthusiasts Platform, Italian Gastronomic Experiences, Italian Food Tourism USA, Culinary Travelers Italy, Italian Food and Beverage Voyages, Italian Gastronomy Community USA" />
            <link rel="canonical" href="https://alysei.com/services/voyagers" />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Best Italian Cuisine & Culinary Adventures USA | Alysei" />
            <meta property="og:description" content="Join a vibrant community dedicated to Italian gastronomy with Alysei. Explore authentic recipes, cooking tips, food photography, and stay updated on Italian food festivals and trends for an unforgettable culinary journey." />
            <meta property="og:url" content="https://alysei.com/services/voyagers" />
            <meta property="og:site_name" content="Alysei" />
            <meta property="og:image" content="https://alysei.com/dist/images/voyager-contentimg.png" />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary" />
            <meta property="twitter:url" content="https://alysei.com/services/voyagers" />
            <meta property="twitter:title" content="Best Italian Cuisine & Culinary Adventures USA | Alysei" />
            <meta property="twitter:description" content="Join a vibrant community dedicated to Italian gastronomy with Alysei. Explore authentic recipes, cooking tips, food photography, and stay updated on Italian food festivals and trends for an unforgettable culinary journey." />
            <meta property="twitter:image" content="https://alysei.com/dist/images/voyager-contentimg.png" />

            <script type="application/ld+json">
                {`{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Best Italian Cuisine & Culinary Adventures USA | Alysei",
  "url": "https://alysei.com/services/voyagers",
  "description": "Join a vibrant community dedicated to Italian gastronomy with Alysei. Explore authentic recipes, cooking tips, food photography, and stay updated on Italian food festivals and trends for an unforgettable culinary journey.",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://alysei.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Voyagers",
        "item": "https://alysei.com/services/voyagers"
      }
    ]
  },
  "about": {
    "@type": "Service",
    "name": "Voyagers Services",
    "serviceType": "Personalized Travel and Adventure Solutions",
    "provider": {
      "@type": "Organization",
      "name": "Alysei",
      "url": "https://alysei.com"
    },
    "areaServed": {
      "@type": "Country",
      "name": "US"
    },
    "audience": {
      "@type": "Audience",
      "audienceType": "Travel Enthusiasts, Adventurers, Individuals, Businesses"
    }
  },
  "publisher": {
    "@type": "Organization",
    "name": "Alysei",
    "url": "https://alysei.com",
    "logo": "https://alysei.com/dist/images/logo.svg",
    "sameAs": [
      "https://www.facebook.com/AlyseiB2B",
      "https://www.instagram.com/alyseiplatform/",
      "https://www.linkedin.com/company/alysei",
      "https://www.youtube.com/@alysei/",
      "https://apps.apple.com/in/app/alysei/id1634783265",
      "https://play.google.com/store/apps/details?id=com.alysei"
    ]
  }
}`}
            </script>

        </Helmet>
    )
}
export default Voyagers