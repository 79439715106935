import React, { useEffect, useRef, useState } from 'react';
import banner from '../../../assets/landing-page/Banner-img.png';
import BannerVdo from '../../../assets/landing-page/banner-video.mp4';
import language from "../../../helpers/language";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

const Banner = () => {
  const [lang] = useState(language.getLang());
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [shouldUseImage, setShouldUseImage] = useState(false);
  const videoParentRef = useRef(null);

  useEffect(() => {
    // Set up Safari-specific handling
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0];
      if (player) {
        player.controls = false;
        player.playsInline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // Ensures muted is set for Safari
        player.autoplay = true;

        // Attempt to autoplay, fallback to image if it fails
        setTimeout(() => {
          const promise = player.play();
          if (promise && promise.then) {
            promise
              .then(() => setIsVideoReady(true))
              .catch(() => {
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          } else {
            setIsVideoReady(true);
          }
        }, 0);
      }
    } else {
      // Handle autoplay for non-Safari browsers
      const video = videoParentRef.current?.children[0];
      const handleCanPlay = () => {
        setIsVideoReady(true);
        video.play().catch(error => {
          console.error("Autoplay prevented:", error);
        });
      };
      video?.addEventListener("canplay", handleCanPlay);
      return () => video?.removeEventListener("canplay", handleCanPlay);
    }
  }, []);

  return (
    <div className="landing-banner relative h-screen w-full">
      {/* Show the banner image if video is not ready or using fallback */}
      {(shouldUseImage || !isVideoReady) && (
        <img
          src={banner}
          alt="Banner"
          className="absolute object-cover h-full w-full"
        />
      )}

      {/* Video element injected with dangerouslySetInnerHTML for Safari */}
      <div ref={videoParentRef} dangerouslySetInnerHTML={{
        __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            preload="metadata"
            class="absolute object-cover h-full w-full ${isVideoReady && !shouldUseImage ? "visible" : "hidden"}"
            title="A video showcasing Alysei's platform"
          >
            <source src="${BannerVdo}" type="video/mp4" />
            Your browser does not support the video tag. Please consider upgrading your browser.
          </video>
        `
      }} />

      <div className="position absolute w-full z-10">
        <div className="text-white md:mx-64 mx-8 md:mt-48 mt-40 mb-10 text-center">
          <p className="text-xl md:mb-3 mb-5 font-semibold">{lang?.Welcome}</p>
          <h1 className="md:text-6xl text-4xl font-extrabold md:mb-3 mb-5 leading-heading">{lang?.A_Bright_future}</h1>
          <p className="font-normal md:text-sm text-base mb-8">{lang?.Alysei_is_the_premier_B2B}</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
