import React, { useEffect, useState,useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import MultiSelecter from "../../../components/MutiSelect/MultiSelect";
import { Multiselect } from 'multiselect-react-dropdown';
import constant from "../../../helpers/constant"
import language from "../../../helpers/language";
import { getRegion } from "../../register/modules/register"

import { fetchTravelAgencies, fetchSpeciality, fetchAllHubs } from "../modules/B2B";
import CustomUseEffect from "../../../components/constants";

export default function TravelAgenciesBar(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [hubsId, setHubsId] = useState("");
    const [roleSearchType, setroleSearchType] = useState(2);
    const [lang] = useState(language.getLang());
    const [region, setRegion] = useState("");
    const [country, setCountry] = useState("");
    const labelRef = useRef(null);
    const [speciality, setSpeciality] = useState("");
    const { regions } = useSelector((state) => state.register);
    const { getAllHubs, getStatesData, getAllSpeciality, } = useSelector((state) => state.b2bState);

    CustomUseEffect(async () => {
        // await dispatch(fetchAllHubs());
        await dispatch(fetchSpeciality(14)); 
        document.getElementById('travel_hubs_input').placeholder = lang?.select;
        document.getElementById('travel_speciality_input').placeholder = lang?.select;
        document.getElementById('travel_ragions_input').placeholder = lang?.select;
    }, []);

    CustomUseEffect(async() => {
        await setHubsId(props?.hubsId != undefined ? props?.hubsId : '');
    },[])

    const getTravelAgenciesSearch = async () => {
        const model = {
            searchType: roleSearchType,
            roleId: constant.rolesId.travelAgencies,
            hubsId: hubsId,
            speciality: speciality,
            country: country,
            region: region,
            page: 1,
        };
        props.getStatesOfSearchBar(model);
        await dispatch(fetchTravelAgencies(model));
        //console.log(model,"modelmodel");
    };

    const handTravelAgenciesSearch = async (event) => {
        event.preventDefault();
        await getTravelAgenciesSearch();
    };

    const handleMultiSelect = async (id, selectedList) => {
        const arr = [];
        await selectedList && selectedList.map((item) => arr.push(item.id));
        setHubsId(arr)
    };

    const handleMultiSelectSpeciality = async (id, selectedList) => {
        const arr = [];
        await selectedList && selectedList.map((item) => arr.push(item.user_field_option_id));
        setSpeciality(arr)
    };

    const handleMultiSelectTitleState = async (id, selectedList) => {
        const arr = [];
        await selectedList && selectedList.map((item) => arr.push(item.id));
        setRegion(arr)
    };
    const handleCountry = async (event) => {
        await setCountry(event[0].value)
        await dispatch(getRegion(event[0].value));
    }

    return (
        <div className="left__section-block sm:mb-2 md:mb-2">
            <div className="card card--block ">
                <div className="hubsTitle">
                    <h4 className="font-18">{lang?.Travel_AgenciesB2B} </h4>
                </div>
                <div className="card-form p-3">
                    <form onSubmit={(event) => handTravelAgenciesSearch(event)}>
                    {props.hubShow && 
                        <div className="form__item">
                            <MultiSelecter
                                displayValue={"title"}
                                placeholder={lang?.select}
                                Options={getAllHubs}
                                label={"Hubs"}
                                id="travel_hubs"
                                required={"no"}
                                fireData={(id, selectedList) => handleMultiSelect(id, selectedList)}
                            />
                        </div>
                    }
                        <div className="form__item">
                            <MultiSelecter
                                displayValue={"option"}
                                Options={getAllSpeciality && getAllSpeciality.options}
                                label={lang?.Speciality}
                                placeholder={lang?.select}
                                id="travel_speciality"
                                required={"no"}
                                fireData={(id, selectedList) => handleMultiSelectSpeciality(id, selectedList)}
                            />
                        </div>
                        <div className="form__item">
                            <label htmlFor="comp">{lang?.Country}</label>
                            {/* <select
                                id="hor"
                                onChange={(event) => handleCountry(event)} Title
                            >
                                <option value="-1">{lang?.Select}</option>
                                <option value={233}>{lang?.United_State}</option>
                                <option value={107}>{lang?.Italy}</option>
                            </select> */}
                            <Multiselect
                                options={[{ key: lang?.United_State, value:'233'},{ key: lang?.Italy, value:'107'}]}
                                displayValue="key"
                                placeholder={lang?.select}
                                onSelect={(event) => handleCountry(event)}
                                id={'fda__certificate'}
                                singleSelect
                                ref={labelRef}
                            />
                        </div>
                        <div className="form__item">
                            <MultiSelecter
                                displayValue={"name"}
                                placeholder={lang?.select}
                                Options={regions}
                                label={lang?.SelectState}
                                id="travel_ragions"
                                required={"no"}
                                fireData={(id, selectedList) => handleMultiSelectTitleState(id, selectedList)}
                            />
                        </div>

                        <div className="fomr-actions">
                            <button className="btn btnBgPrimary" type="submit">
                                {lang?.Search}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
