import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Contact from "./Contact";
import Benefits from "./Benefits";
import language from "../../../helpers/language";
// Importing images
import logo from '../../../assets/landing-page/logo.svg';
import logoBlue from '../../../assets/landing-page/logo-blue.svg';
import LanguageConverter from "./LanguageConverter";
import auth from "../../../helpers/auth";
import SeoBookDemo from "../../../components/Seo-helmet/SeoBookDemo";

const Header = () => {

  const token = auth.getAccessToken();
  const [lang] = useState(language.getLang());
  const location = useLocation();
  const currentPath = location.pathname;

  const [isScrolled, setIsScrolled] = useState(false);
  const [contact, setContact] = useState(false);
  const [benefits, setBenefits] = useState(false);
  const [sideBar, setSidebar] = useState(false);
  const returnLoginAccountRoute = useMemo(() => {
    return {
      route: token ? '/home' : '/login',
      heading: token ? 'My Account' : lang?.Login
    }
  }, [token])

  const resetAll = () => {
    setContact(false);
    setBenefits(false);
    setSidebar(false);
  };

  const toggleContact = () => {
    if (contact) {
      setContact(false);
    } else {
      resetAll();
      setContact(true);
    }
  };

  const toggleBenefits = () => {
    setBenefits(prev => !prev); // Toggle benefits without resetting the sidebar
  };

  const toggleSidebar = () => {
    if (sideBar) {
      setSidebar(false);
    } else {
      resetAll();
      setSidebar(true);
    }
  };

  const handleScroll = useCallback(() => setIsScrolled(window.scrollY > 50), []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const isNotHome = currentPath !== "/";
  const showBlueLogo = isScrolled || sideBar || contact || benefits || isNotHome;

  // Function to check if in mobile view
  const isMobileView = window.innerWidth < 768; // You can adjust the breakpoint as needed

  return (
    <>
    {/* @@ seo for demo btn */}
    <SeoBookDemo />
      <header className={`navBar ${isNotHome ? "innerPage" : "home"} ${showBlueLogo ? "scrolled" : "top"}`}>
        <div className="flex md:gap-12 items-center">
          <Link to="/" className="logo md:w-32 w-20" onClick={resetAll}>
            <img src={showBlueLogo ? logoBlue : logo} alt="Logo" />
          </Link>
          <div className="hidden md:block">
            <LanguageConverter />
          </div>
        </div>
        <ul className={`flex gap-4 font-medium navBar-list ${sideBar ? "active" : ""}`}>
          <div className="md:hidden block">
            <LanguageConverter />
          </div>
          <li>
            <Link to="/" className={currentPath === "/" ? "text-green-accent" : ""} onClick={resetAll}>Home</Link>
          </li>
          {/* <li className="cursor-pointer " onClick={toggleBenefits}>
            Benefits <i className="fa fa-chevron-down text-sm"></i>
            { benefits && <Benefits resetAll={resetAll} />} 
          </li> */}
          <li>
            <Link to="/about" className={currentPath === "/about" ? "text-green-accent" : ""} onClick={resetAll}>{lang.About_us}</Link>
          </li>
          <li>
            <a href="https://blog.alysei.com" onClick={resetAll}>
              Blogs
            </a>
          </li>
          <li> <Link to="/contact" className={currentPath === "/contact" ? "text-green-accent" : ""} >{lang.Contact}</Link></li>
          <Link to={returnLoginAccountRoute?.route} className="btn-royal text-center md:hidden block" onClick={resetAll}>{returnLoginAccountRoute?.heading}</Link>
          <Link to="/signup" className="border-2 border-royal text-royal text-center rounded-md px-3 py-2 md:hidden block" onClick={resetAll}>Join Alysei</Link>
        </ul>
        <div className="flex gap-2 items-center font-medium navBtn-group">
          {/* <Link to="/signIn" className="hidden md:block" onClick={resetAll}>{lang?.Login}</Link> */}
          <Link to={returnLoginAccountRoute?.route} className="hidden md:block" onClick={resetAll}>{returnLoginAccountRoute?.heading}</Link>
          <Link to="/signup" className="border-2 border-white rounded-md px-3 py-2 hidden md:block" onClick={resetAll}>Join Alysei</Link>
          {/* <a href="https://calendly.com/alysei2024" className="btn-green-accent" onClick={resetAll}>
            <span>Book a Demo</span>
          </a> */}
          <a 
            href="https://calendly.com/alysei2024" 
            className="btn-green-accent" 
            onClick={resetAll}
            aria-label="Book a personalized demo of our product"  // Accessible description for screen readers
            title="Click to schedule a personalized product demo" // Tooltip text for more context
          >
            <span>Book a Demo</span>
          </a>
          <button onClick={toggleSidebar} aria-label="Toggle sidebar" className="bg-gray-200 px-3 py-2 rounded-md cursor-pointer text-black md:hidden block">
            <i className={`fa ${sideBar ? "fa-times" : "fa-bars"}`}></i>
          </button>
        </div>
      </header>
      {contact && <Contact />}
    </>
  );
};

export default Header;
