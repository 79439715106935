import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { renderRoutes } from "react-router-config";
import { useHistory, useLocation, useParams } from "react-router-dom";
import auth from "../helpers/auth";

export const Root = ({ children, route }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const token = auth.getAccessToken();
    if (!token) {
      // history.push('/register')
      //history.push("/services");
    }
    if ((pathname?.includes('signIn') || pathname?.includes('signin') || pathname?.includes('login') || pathname?.includes('Login') ) && token) {
      history.push(`/`)
    }
    if (pathname?.includes("public") && token) {
      history.push(`/home/post/activity/${pathname.split("/")[5]}`);
    } else if (pathname?.includes("public") && !token) {
      localStorage.setItem(
        "post-url",
        `/home/post/activity/${pathname.split("/")[5]}`
      );
    }
  }, [pathname]);

  return (
    <Fragment>
      {children}
      {renderRoutes(route.routes)}
    </Fragment>
  );
};

Root.propTypes = {
  children: PropTypes.element,
  route: PropTypes.object.isRequired,
};

export default Root;
