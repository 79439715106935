import React from 'react'
import { Helmet } from 'react-helmet'
const SeoSignup = () => {
  return (
   <Helmet>
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />

{/* <!-- Primary Meta Tags --> */}
<title>Sign Up for Alysei - Join the Best Italian Food & Beverage Platform USA</title>
<meta name="description" content="Sign up for Alysei today and join the leading platform connecting Italian food and beverage enthusiasts, businesses, and professionals in the USA. Start your journey now!" />
<meta name="keywords" content="Alysei Sign Up, Register on Alysei, Create Alysei Account" />
<link rel="canonical" href="https://alysei.com/signup" />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:title" content="Sign Up for Alysei - Join the Best Italian Food & Beverage Platform USA" />
<meta property="og:description" content="Sign up for Alysei today and join the leading platform connecting Italian food and beverage enthusiasts, businesses, and professionals in the USA. Start your journey now!" />
<meta property="og:url" content="https://alysei.com/signup" />
<meta property="og:site_name" content="Alysei" />
<meta property="og:image" content="https://alysei.com/dist/images/login-to-alysei.png" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary" />
<meta property="twitter:url" content="https://alysei.com/signup" />
<meta property="twitter:title" content="Sign Up for Alysei - Join the Best Italian Food & Beverage Platform USA" />
<meta property="twitter:description" content="Sign up for Alysei today and join the leading platform connecting Italian food and beverage enthusiasts, businesses, and professionals in the USA. Start your journey now!" />
<meta property="twitter:image" content="https://alysei.com/dist/images/login-to-alysei.png" />

<script type="application/ld+json">
{`{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Sign Up for Alysei - Join the Best Italian Food & Beverage Platform USA",
  "url": "https://alysei.com/signup",
  "description": "Sign up for Alysei today and join the leading platform connecting Italian food and beverage enthusiasts, businesses, and professionals in the USA. Start your journey now!",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://alysei.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Sign Up",
        "item": "https://alysei.com/signup"
      }
    ]
  },
  "publisher": {
    "@type": "Organization",
    "name": "Alysei",
    "url": "https://alysei.com",
    "logo": "https://alysei.com/dist/images/logo.svg",
    "sameAs": [
      "https://www.facebook.com/AlyseiB2B",
      "https://www.instagram.com/alyseiplatform/",
      "https://www.linkedin.com/company/alysei",
      "https://www.youtube.com/@alysei/",
      "https://apps.apple.com/in/app/alysei/id1634783265",
      "https://play.google.com/store/apps/details?id=com.alysei"
    ]
  }
}`}
</script>

   </Helmet>
  )
}

export default SeoSignup