import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import MultiSelecter from "../../../components/MutiSelect/MultiSelect";
import { Multiselect } from 'multiselect-react-dropdown';

import constant from "../../../helpers/constant"
import {
    fetchVoiceOfExport,
    fetchExpertise,
    fetchTitle,
    fetchAllHubs,
} from "../modules/B2B";
import { getRegion } from "../../register/modules/register";
import language from "../../../helpers/language";
import CustomUseEffect from "../../../components/constants";

export default function VoiceExpertSearchBar(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [hubsId, setHubsId] = useState([]);
    const [roleSearchType, setroleSearchType] = useState(2);
    const labelRef = useRef(null);
    const [title, setTitle] = useState([]);
    const [region, setRegion] = useState([]);
    const [country, setCountry] = useState("");
    const [expertise, setExpertise] = useState([]);
    const [lang] = useState(language.getLang());

    const { regions } = useSelector((state) => state.register);
    const { getAllHubs, getAllTitle, getAllCountry, getStatesData, getAllExpertise } = useSelector((state) => state.b2bState);

    CustomUseEffect(async () => {
        // await dispatch(fetchAllHubs());
        await dispatch(fetchExpertise(11));
        await dispatch(fetchTitle(12)); 
        document.getElementById('voiceExpert_hubs_input').placeholder = lang?.select;
        document.getElementById('voiceExpert_expertise_input').placeholder = lang?.select;
        document.getElementById('voiceExpert_title_input').placeholder = lang?.select;
        document.getElementById('voiceExpert_region_input').placeholder = lang?.select;
    }, []);

    CustomUseEffect(async() => {
        await setHubsId(props?.hubsId != undefined ? props?.hubsId : '');
    },[])

    const getVoiceOfExpertSearch = async () => {
        const model = {
            searchType: roleSearchType,
            roleId: constant.rolesId.voiceOfExpert,
            hubsId: hubsId,
            expertise: expertise,
            title: title,
            country: country,
            region: region,
            page: 1
        };
        props.getStatesOfSearchBar(model);
        await dispatch(fetchVoiceOfExport(model));
        //console.log(model,"modelmodel");
    };

    const handVoiceOfExpertSearch = async (event) => {
        event.preventDefault();
        await getVoiceOfExpertSearch();
    };

    const handleMultiSelect = async (id, selectedList) => {
        const arr = [];
        await selectedList && selectedList.map((item) => arr.push(item.id));
        setHubsId(arr)
    };

    const handleMultiSelectExpertise = async (id, selectedList) => {
        const arr = [];
        await selectedList && selectedList.map((item) => arr.push(item.user_field_option_id));
        setExpertise(arr)
    };

    const handleMultiSelectTitle = async (id, selectedList) => {
        const arr = [];
        await selectedList && selectedList.map((item) => arr.push(item.user_field_option_id));
        setTitle(arr)
    };

    const handleMultiSelectTitleState = async (id, selectedList) => {
        const arr = [];
        await selectedList && selectedList.map((item) => arr.push(item.id));
        setRegion(arr)
    };

    const handleCountry = async (event) => {
        await setCountry(event[0].value)
        await dispatch(getRegion(event[0].value));
    }
    return (
        <div className="left__section-block sm:mb-2 md:mb-2">
            <div className="card card--block ">
                <div className="hubsTitle">
                    <h4 className="font-18">{lang?.B2b_Voice_Of_Experts} </h4>
                </div>
                <div className="card-form p-3">
                    <form onSubmit={(event) => handVoiceOfExpertSearch(event)}>
                    {props.hubShow && 
                        <div className="form__item">
                            <MultiSelecter
                                displayValue={"title"}
                                placeholder={lang?.select}
                                Options={getAllHubs}
                                label={"Hubs"}
                                id="voiceExpert_hubs"
                                required={"no"}
                                fireData={(id, selectedList) => handleMultiSelect(id, selectedList)}
                            />
                        </div>
                    }
                        <div className="form__item">
                            <MultiSelecter
                                displayValue={"option"}
                                placeholder={lang?.select}
                                Options={getAllExpertise?.options}
                                label={lang?.Expertise}
                                id="voiceExpert_expertise"
                                required={"no"}
                                fireData={(id, selectedList) => handleMultiSelectExpertise(id, selectedList)}
                            />
                        </div>
                        <div className="form__item">
                            <MultiSelecter
                                displayValue={"option"}
                                placeholder={lang?.select}
                                Options={getAllTitle?.options}
                                label={lang?.Title}
                                id="voiceExpert_title"
                                required={"no"}
                                fireData={(id, selectedList) => handleMultiSelectTitle(id, selectedList)}
                            />
                        </div>

                        <div className="form__item">
                            <label htmlFor="comp">{lang?.Country}</label>
                            {/* <select
                                id="hor"
                                onChange={(event) => handleCountry(event)} Title
                            >
                                <option value="-1">{lang?.Select}</option>
                                <option value={233}>{lang?.United_State}</option>
                                <option value={107}>{lang?.Italy}</option>
                            </select> */}
                            <Multiselect
                                options={[{ key: lang?.United_State, value:'233'},{ key: lang?.Italy, value:'107'}]}
                                displayValue="key"
                                placeholder={lang?.select}
                                onSelect={(event) => handleCountry(event)}
                                id={'fda__certificate'}
                                singleSelect
                                ref={labelRef}
                            />
                        </div>
                        <div className="form__item">
                            <MultiSelecter
                                displayValue={"name"}
                                Options={regions}
                                label={lang?.SelectState}
                                id="voiceExpert_region"
                                required={"no"}
                                fireData={(id, selectedList) => handleMultiSelectTitleState(id, selectedList)}
                            />
                        </div>

                        <div className="fomr-actions">
                            <button className="btn btnBgPrimary" type="submit">
                                {lang?.Search}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
