import React, { Fragment } from 'react'
import REACT_APP_API from "../../../config/environment"
import '../../../assets/recipe/style.css'
import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import FormError from '../../../components/Errors/FormError'
import Modal from "react-modal";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import auth from '../../../helpers/auth'
import language from '../../../helpers/language'
import { searchRecipeTool } from "../modules/RecipeModule";
import {
  submitRecipeData, submitRecipePostData, recipeUpdateData, tabIndexFun
} from "../modules/RecipeModule";
import CustomUseEffect from '../../../components/constants'

export default function AddUtencil() {
  const [lang] = useState(language.getLang());
  const history = useHistory();
  const dispatch = useDispatch()
  const myRef = useRef(null);
  const [recipeTools, setRecipeTools] = useState([])
  const [toolsLoader, setToolsLoader] = useState(false);
  const [selectedToolLists, setSelectedToolLists] = useState([]);
  const [isCheckedData, setIsCheckedData] = useState('');
  const [toolErrorStatus, setToolErrorStatus] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isSearchStatus, setIsSearchStatus] = useState(false);
  const [searchToolstData, setSearchToolData] = useState([]);
  const [toolNotFoundMessage, setToolNotFoundMessage] = useState();
  const [isSaveDraft, setIsSaveDraft] = useState(false);
  const [explorePercentageSpinner, setExplorePercentageSpinner] = useState(false);
  const [isRecipeSteps, setIsRecipeSteps] = useState([]);
  const [recipeinformation, setRecipeinformation] = useState('');
  const [selectedIngredientList, setSelectedIngredientList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorStatus, setErrorStatus] = useState(false);

  CustomUseEffect(async () => {
    document.body.classList.add("hidePageScroll");
  }, []);

  const moveToIngredient = (e) => {
    let access = document.getElementById(e.target.value);
    access.scrollIntoView({ behavior: 'smooth' });
  }
  
  CustomUseEffect(async() => {
    if(localStorage.getItem('savedTools') != null){
      await setSelectedToolLists(JSON.parse(localStorage.getItem('savedTools')));
    }
    if(localStorage.getItem('savedIngredients') != null){
      await setSelectedIngredientList(JSON.parse(localStorage.getItem('savedIngredients')));
    }
    if(localStorage.getItem('recipe_step') != null){
      const addedSteps = JSON.parse(localStorage.getItem('recipe_step'));
      await setIsRecipeSteps(addedSteps);
    }
    if(localStorage.getItem('generalInformation') != null){
      await setRecipeinformation(JSON.parse(localStorage.getItem('generalInformation')));
    }
  },[])

  CustomUseEffect(async () => {
    let token = await auth.getAccessToken();
    await setToolsLoader(true);
    const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/recipe/tools`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      },
    });
    let response = await result.json();
    if (response.success === 200) {
      //console.log(response,"response");
      setRecipeTools(response?.data)
      await setToolsLoader(false);
    }
    else{
      await setToolsLoader(false);
      await setToolNotFoundMessage(response.errors.exception);
    }
  }, [])

  // OnClick checkbox 
  const handleclick = async (e, res) => {
    if(e.target.checked){
      const obj = {
        tool_id: res?.recipe_tool_id,
        title: res?.title,
        src: res?.image_id.attachment_url,
        base_url: res?.image_id.base_url,
      }
      await setSelectedToolLists((old) => [...old, obj]);
    }
    else{
      const idex = selectedToolLists.findIndex(
        (obj) => obj.tool_id == res.recipe_tool_id
      );

      if(idex > -1){
        selectedToolLists.splice(idex, 1);
        await setSelectedToolLists([...selectedToolLists]);
      }
    }
  }

  // Remove Selected Tool
  const removeSelectedTool = async(tool, index) => {
      selectedToolLists.splice(index, 1);
      await setSelectedToolLists([...selectedToolLists]);
      document.getElementById(tool.title).checked = false;
  }

  // Save tool and move to next step
  const handleNextProcess = async() => {
    if(selectedToolLists.length > 0){
    localStorage.setItem('savedTools', JSON.stringify(selectedToolLists));
    history.push('/recipes/recipe-steps')
    }
    else{
      await setToolErrorStatus(true);
      setTimeout(() => {
        setToolErrorStatus(false);
      }, 2000);
    }
  }

  // Goback Step
  const goToBackPage = async () => {
       history.push("/recipes/add-ingredients");
  }

  const searchTool = async(e) => {
    await setSearchKeyword(e.target.value)
    await setToolsLoader(true);
    if(e.target.value !=''){
      dispatch(searchRecipeTool(e.target.value)).then(async (res) => {
        if (res.success === 200) {
          await setIsSearchStatus(true)
          await setSearchToolData(res.data)
          await setToolsLoader(false);

        }
        else{
          await setToolsLoader(false);
          await setToolNotFoundMessage(res.errors.exception);

        }
        
      })
    }
    else{
      await setIsSearchStatus(false)
      await setToolsLoader(false);
    }

  }

   // Save recipe as a draft
   const saveInDraft = async() => {
    await setExplorePercentageSpinner(true)
    const postdata = {
      params: {
        name: recipeinformation?.name,
        meal_id: recipeinformation?.meal_id,
        course_id: recipeinformation?.course_id,
        hours: recipeinformation?.hours || 0,
        minutes: recipeinformation?.minutes || 0,
        serving: recipeinformation?.serving,
        cousin_id: recipeinformation?.cousin_id,
        region_id: recipeinformation?.region_id,
        diet_id:recipeinformation?.diet_id,
        intolerance_id: recipeinformation?.intolerance_id,
        cooking_skill_id: recipeinformation?.cooking_skill_id,
        image_id: recipeinformation?.recipeImage,
        saved_ingredients: selectedIngredientList,
        saved_tools: selectedToolLists,
        recipe_steps: isRecipeSteps,
        status: 0,
      },
    };
    if (localStorage.getItem('recipe_id') != null) {
      if(recipeinformation?.recipeImage === undefined){
         delete postdata?.params?.image_id;
      }
      postdata.params["recipeId"] = localStorage.getItem('recipe_id')
      await recipeUpdateData(postdata).then(async (response) => {
        if (response && response.success === 200) {
          await dispatch(tabIndexFun(2))
          await localStorage.removeItem("recipe_id")
          await localStorage.removeItem("savedIngredients")
          await localStorage.removeItem("savedTools")
          await localStorage.removeItem("recipe_step")
          await localStorage.removeItem("generalInformation")
          history.push('/recipes')
        } else if (response && response.success === 409) {
          await setErrorMessage(response.errors.exception)
          await setErrorStatus(true);

        }
      })
    } else if (recipeinformation != '') {
      await submitRecipeData(postdata).then(async (response) => {
        if (response.success === 200) {
          dispatch(submitRecipePostData(postdata))
          await dispatch(tabIndexFun(2))
          await localStorage.removeItem("savedIngredients")
          await localStorage.removeItem("savedTools")
          await localStorage.removeItem("recipe_step")
          await localStorage.removeItem("generalInformation")
          history.push('/recipes')
        } else if (response.success === 409) {
          await setErrorMessage(response.errors.exception)
          await setErrorStatus(true);
        }
      });

    }
  }
  //console.log(selectedToolLists,"selectedToolLists");
  return (
    <div className="recipe__page">
    <div className="step">
      <div className="cancel">
        <Link to="#" onClick={() => { setIsSaveDraft(!isSaveDraft)}}><span><i className="fa fa-arrow-left" aria-hidden="true"></i></span>{lang?.Create_Recipe}</Link>
      </div>
      <div className="right__align_btn btn-white">
        <button  onClick={() => { goToBackPage() }} className="btn ">{lang?.Back}</button>
        <button onClick={() => { handleNextProcess(); document.body.classList.remove("hidePageScroll"); }} className="btn activeBtn">{lang?.Next}</button>
      </div>
    </div>
    <div className="top__div">
      <div className="innerPageTitle">
        <h4 className="semi__bold__title">
          <span className="circle">3</span>
          {lang?.Add_Utencils_Tools_used_in_recipe}
        </h4>
      </div>
    </div>
    {errorStatus ?  <div> <FormError msg={errorMessage} /></div> : ''}
    <div className="">
      <div className="ingredient__main">
        <div className="ingredient__width lg:order-first xl:order-first md:order-last sm:order-last">
          <div className="ingredients__search">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="search-box">
                <div className="top-search">
                  <span className="search_icon">
                    <i className="fas fa-search" aria-hidden="true" />
                  </span>
                  <input type="text" placeholder={lang?.Search_Tools} value={searchKeyword} onChange={(e) => searchTool(e)}/>
                </div>
                <div className="form__item">
                  <div className="mySelect mt-2">
                    <select id="pl" onChange={(e) => {moveToIngredient(e)}}>
                      {
                        recipeTools && recipeTools.length > 0 && recipeTools.map((list, i) => {
                            return (
                              <Fragment key={i}> <option value={list.recipe_tool_id} >{list.title}</option></Fragment>
                            )
                          }) 
                      }

                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="create__recipe__list">
            { 
              toolsLoader ? <FormSpinner/> : 
              isSearchStatus ? 
              <ul className="recipe__innerdiv">
                {
                  searchToolstData && searchToolstData?.length > 0 && searchToolstData.map((val, index) => {
                    const isSelected = selectedToolLists.some((selected)=> selected.tool_id == val.recipe_tool_id)
                    return (
                      <Fragment>
                        <li className="recipe__detail" key={index}>
                            { isSelected ? 
                              <input className="form-check-input" type="checkbox" id={val.title}  checked={isSelected} onClick={(e) => { handleclick(e, val) }}/>
                              :
                              <input className="form-check-input" type="checkbox" id={val.title}  onClick={(e) => { handleclick(e, val) }}/>
                            }
                            <label htmlFor={val.title}>
                            <img src={`${val.image_id.base_url}${val.image_id.attachment_url}`} />
                            <div className="check_mark" />
                            <h4 className="ingredient_name">{val.title}</h4>
                          </label>
                        </li>
                      </Fragment>
                    )
                  })
                }
              </ul>
              :
              recipeTools && recipeTools?.length > 0 && recipeTools.map((item) => {
                    return (
                      <Fragment>
                         <h1 className='font-18 black' id={item.recipe_tool_id}>{item.title}</h1>
                        <ul className="recipe__innerdiv">
                          {
                            item && item.tools.map((val, index) => {
                              const isSelected = selectedToolLists.some((selected)=> selected.tool_id == val.recipe_tool_id)
                              return (
                                <Fragment>
                                  <li className="recipe__detail" key={index}>
                                      { isSelected ? 
                                        <input className="form-check-input" type="checkbox" id={val.title}  checked={isSelected} onClick={(e) => { handleclick(e, val) }}/>
                                        :
                                        <input className="form-check-input" type="checkbox" id={val.title}  onClick={(e) => { handleclick(e, val) }}/>
                                      }
                                      <label htmlFor={val.title}>
                                      <img src={`${val.image_id.base_url}${val.image_id.attachment_url}`} />
                                      <div className="check_mark" />
                                      <h4 className="ingredient_name">{val.title}</h4>
                                    </label>
                                  </li>
                                </Fragment>
                              )
                            })
                          }
                        </ul>
                      </Fragment>
                    )

              })
            }
            {/* <p>{toolNotFoundMessage}</p>  */}
          </div>
        </div>
        <div className="ingredients-list lg:order-last xl:order-last md:order-first sm:order-first ">
          <h4 className="semi__bold__title add-recipe pb-4">{lang?.Ingredients_List}</h4>
          <hr className="mt-0" />
          <div className="ingredient-detail justify-center"> {selectedToolLists && selectedToolLists.length <= 0 && <span>{lang?.There_are_no_tools}</span>} </div>          
          {toolErrorStatus ? (
                <div>
                  <FormError msg={lang?.Please_select_atleast_one_tool} />
                </div>
              ) : ''}
          <div className='listMaxHeight'>
            {selectedToolLists && selectedToolLists.length > 0 &&
              selectedToolLists.map((list, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="ingredient-detail">
                        <div className="ingredient-left">
                          <div className="icon-img"><img src={`${list.base_url}${list.src}`} /></div>
                          <span>{list.title}</span>
                        </div>
                        <div className="ingredient-right">
                          <span className="close">
                            <i className="fa fa-times-circle" aria-hidden="true" onClick={() => removeSelectedTool(list, index)}></i>
                          </span>
                        </div>
                      </div>
                    </Fragment>
                  )
                })
              }
          </div>
        </div>
      </div>
    </div>
    {isSaveDraft && (
        <div>
          <Modal
            isOpen={true}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title">{lang?.Save_in_draft}</h4>
              <button
                className="close_btn--modal"
                onClick={() => setIsSaveDraft(!isSaveDraft)}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box">
              <p>{lang?.are_you_sure}</p>
              <div className="right__btn text-right">
                <Link
                  className="btn btn1"
                  to="/recipes"
                  onClick={() => setIsSaveDraft(false)}
                >
                  {lang?.Cancel}
                </Link>
                <Link
                  className="btn btn2 ml-3"
                  to="#"
                  onClick={() => saveInDraft()}
                >
                  {explorePercentageSpinner ? <FormSpinner /> : `${lang?.Save}`}

                </Link>
              </div>
            </div>
            <div></div>
          </Modal>
        </div>
      )}
  </div>
  );
}