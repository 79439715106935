import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReactStars from "react-rating-stars-component";
import REACT_APP_API from "../../../config/environment"
import language from '../../../helpers/language';
import { tabIndexFun } from '../modules/RecipeModule'

export default function Trending(props) {
    const [lang] = useState(language.getLang());
    const { favouriteRecipeData } = useSelector((state) => state.recipedata)
    const dispatch = useDispatch()
    // CustomUseEffect(async () => {
    //     await dispatch(tabIndexFun(1))
    // }, [])

    return (
        <div className="recipe__section">
            <div className="section_heading"> <h2 className="section_title">{lang?.MyFavouriteRecipe}</h2> </div>
            {/* <div className="viewAll"><Link to="#"><h6>{lang?.View_all}</h6></Link> </div> */}
            <div className="trending_List">
                <ul>
                    {favouriteRecipeData && favouriteRecipeData.length > 0 && favouriteRecipeData.map((item) => {
                        return (
                            <li>
                                <Link to={`${"/recipes/recipe-view"}/${item?.recipe_id}`}>
                                    <div className="trending_Img">
                                        <img src={`${item?.image?.base_url}${item?.image?.attachment_url}`} />
                                        <div className="recipe_rating">
                                            <p> <ReactStars count={5} value={item.avg_rating} size={24} edit={false} color2={'#ffd700'} /></p>
                                            <p> <span className="heart_Icon"><i className="fa fa-heart" aria-hidden="true"></i> </span> <span className="likes">{item?.total_likes} {lang?.Likes}</span> </p>
                                        </div>
                                    </div>
                                    <div className="recipe_Info">
                                        <p className="mb-3"> {item?.username}</p>
                                        <p><span><i className="fa fa-clock-o" aria-hidden="true"></i></span>{`${item?.hours}${lang?.Hr} ${item?.minutes}${lang?.Mins}`} </p>
                                        <p><span><i className="fa fa-user" aria-hidden="true"></i></span>{item?.serving}</p>
                                        <p><span><i className="fa fa-cutlery" aria-hidden="true"></i></span>{item?.meal?.name}</p>
                                    </div>
                                </Link>
                            </li>
                        )
                    })
                    }
                </ul>
            </div>
        </div>
    )
}

