import React, { useCallback, useMemo } from "react";
import "../../../assets/marketplacecss/mrktplace-style.css";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import Cropper from "react-cropper";
import Modal from "react-modal";
import CurrencyInput from "react-currency-input-field";
import FormError from "../../../components/Errors/FormError";
import constant from "../../../helpers/constant";
import auth from "../../../helpers/auth";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import ReactTooltip from "react-tooltip";
import { setFormErrors } from "../../register/modules/register";
import REACT_APP_API from '../../../config/environment';

import {
  fetchStoreData,
  fetchProductCategories,
  fetchBrandLabels,
  getMarketplaceProductDetails,
  updateProductData,
  removeMarketplaceStoreProductImage,
  setMarketplaceProduct,
  getMyAllTaxesClassesList,
  getMarketplaceMyProducts,
  fetchHomeScreenData,
  setMarketplaceShipping
} from "../modules/marketplace";
import language from "../../../helpers/language";
import MyEditor from "../../../components/TextEditor/MyEditor";
import DashboardSideBar from "./DashboardSideBar";
import Carousel from "react-grid-carousel";
import CustomUseEffect from "../../../components/constants";
import { shippingNewObj, destinationArr } from "./AddProduct";

export default function EditProduct() {
  const [activeTab, setActiveTab] = useState("products");
  let { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const myRef = useRef(null);
  const [showShipping, setShowShipping] = useState(false);

  const handleShowShipping = (event) => {
    handleIsUpdate();
    setShowShipping(event.target.checked);
  };

  const [destinations, setDestinations] = useState("");

  const [marketPlaceProductId, setMarketPlaceProductId] = useState();
  const [lang] = useState(language.getLang());
  const [isSuccess, setIsSuccess] = useState(false);
  const [galleryImageObject, setGalleryImageObject] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [addProductDataArr, setAddProductDataArr] = useState({});
  const [isSpinner, setIsSpinner] = useState(false);
  const [isflag, setIsflag] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [OrderQuantityUnit, setOrderQuantityUnit] = useState("No. of pieces");
  const [allTaxClasses, setAllTaxClasses] = useState([]);
  const [isCropped, setIsCropped] = useState(false);
  const [targetImageId, setTargetImageId] = useState("");
  const [openModal, setOpenModal] = useState("");
  const [disableIds, setDisableIds] = useState({});

  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [cropData, setCropData] = useState("");
  const cropper = React.createRef(null);
  const [productCat, setProductCat] = useState([]);
  const [brandLabel, setBrandLabel] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState([]);
  const [selectedBrandLabel, setSelectedBrandLabel] = useState([]);
  const [productPrice, setProductPrice] = useState("0.00");
  const [rrPrice, setRRPrice] = useState("0.00");
  const [ranges, setRanges] = useState([]);
  const [apiStateData, setApiStateData] = useState([]);
  const [stateSelections, setStateSelections] = useState([]);
  const [zones, setZones] = useState([]);
  const [zoneSelections, setZoneSelections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState("");

  const [shippingInfo, setShippingInfo] = useState([]);

  console.log(marketPlaceProductId, "marketPlaceProductId");


  const [formEntries, setFormEntries] = useState([
    {
      destination_id: "",
      quantity_id: "",
      price: "",
      zones: [],
      states: [],
    },
  ]);

  //  @@ save shipping details api
  const handleSubmitButton = async (id) => {

    return new Promise(async (resolve, reject) => {
      try {
        const token = localStorage.getItem("ACCESS_TOKEN");
        let jsonShiping = {
          product_id: id,
          shipping: (shippingInfo?.length > 0 && showShipping == true) ? shippingInfo : []

        }

        let api_url = `${REACT_APP_API.ENV.REACT_APP_API}/shipping/save`
        const response = await fetch(api_url,
          {
            method: "POST",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}`, },
            body: JSON.stringify(jsonShiping),
          }
        );
        const result = await response.json();
        // console.log(result, "api modified data response");
        // console.log(formEntries, "checking form entries format");

        if (response.ok) {
          const responseData = await response.json();
          // console.log("Data sent successfully:", responseData);
          alert(`${lang?.shipping_name} data saved successfully!`);
          resolve('res')
        } else {
          const errorData = await response.json();
          // console.error("Error saving data:", errorData);
          alert("Failed to save shipping data!");
          resolve('err')
        }
      } catch (error) {
        // console.error("Error during API request:", error);
        resolve('error')
      } finally {
        resolve('finally')
        setLoading(false);
      }
    })
  };


  CustomUseEffect(async () => {
    const user = await auth.getUserType();
    JSON.parse(user).role_id !== constant.rolesId.producer &&
      history.push("/marketplace/error");
    await dispatch(getMarketplaceProductDetails(id));
    await dispatch(fetchStoreData());
    await dispatch(fetchProductCategories());
    await dispatch(fetchBrandLabels());
  }, [id]);

  let {
    marketplaceProduct,
    marketPlaceStore,
    productCategies,
    brandLabels,
    marketPlaceDashboardSpinner,
    errMessage,
    marketplaceShipping
  } = useSelector((state) => state.marketPlaceReducerData);

  //console.log(marketplaceProduct, "marketplace");

  useEffect(() => {
    // marketplaceShipping  set auto save shipping details
    if (marketplaceShipping) {
      let shippingSavedData = marketplaceShipping;
      let shippingData = []
      // console.log("Editmarketplace", marketplaceShipping, JSON.stringify(marketplaceShipping))

      for (let item in shippingSavedData) {
        shippingData.push(shippingSavedData[item])
      }
      // console.log('shippingData',)

      if (shippingData.length > 0) {

        setShippingInfo(shippingData);
        setShowShipping(true);
      } else {
        setShippingInfo([shippingNewObj()])
        setShowShipping(false);
      }
      // console.log('shippingDatashippingData', shippingData)
    }

  }, [marketplaceShipping])


  // Get my product classes
  CustomUseEffect(async () => {
    const response = await getMyAllTaxesClassesList();
    setAllTaxClasses(response.data ?? []);
  }, []);

  CustomUseEffect(async () => {
    if (productCategies && productCategies.length > 0) {
      const prodCatArray = [];
      productCategies.map((cat, index) => {
        const array = {
          key: cat.name,
          value: cat.marketplace_product_category_id,
        };

        if (
          cat.marketplace_product_category_id ===
          parseInt(marketplaceProduct.product_category_id)
        ) {
          setSelectedProductCategory([array]);
        }
        prodCatArray.push(array);
      });
      await setProductCat(prodCatArray);
    }

    await setProductPrice(marketplaceProduct?.product_price);
    await setRRPrice(marketplaceProduct?.rrp_price ?? 0);
  }, [productCategies, marketplaceProduct]);

  CustomUseEffect(async () => {
    if (brandLabels && brandLabels.length > 0) {
      const brandLabelArray = [];
      brandLabels.map((brand, index) => {
        const array = {
          key: brand.name,
          value: brand.marketplace_brand_label_id,
        };
        if (
          brand.marketplace_brand_label_id ===
          parseInt(marketplaceProduct.brand_label_id)
        ) {
          setSelectedBrandLabel([array]);
        }
        brandLabelArray.push(array);
      });
      await setBrandLabel(brandLabelArray);
    }
  }, [brandLabels, marketplaceProduct]);

  CustomUseEffect(async () => {
    const response = await getMyAllTaxesClassesList();
    setAllTaxClasses(response.data ?? []);
  }, []);

  CustomUseEffect(async () => {
    if (productCategies && productCategies.length > 0) {
      const prodCatArray = [];
      productCategies.map((cat, index) => {
        const array = {
          key: cat.name,
          value: cat.marketplace_product_category_id,
        };

        if (
          cat.marketplace_product_category_id ===
          parseInt(marketplaceProduct.product_category_id)
        ) {
          setSelectedProductCategory([array]);
        }
        prodCatArray.push(array);
      });
      await setProductCat(prodCatArray);
    }

    await setProductPrice(marketplaceProduct?.product_price);
    await setRRPrice(marketplaceProduct?.rrp_price ?? 0);
  }, [productCategies, marketplaceProduct]);

  CustomUseEffect(async () => {
    if (brandLabels && brandLabels.length > 0) {
      const brandLabelArray = [];
      brandLabels.map((brand, index) => {
        const array = {
          key: brand.name,
          value: brand.marketplace_brand_label_id,
        };
        if (
          brand.marketplace_brand_label_id ===
          parseInt(marketplaceProduct.brand_label_id)
        ) {
          setSelectedBrandLabel([array]);
        }
        brandLabelArray.push(array);
      });
      await setBrandLabel(brandLabelArray);
    }
  }, [brandLabels, marketplaceProduct]);

  useEffect(() => {
    setAddProductDataArr({
      ...marketplaceProduct,
    });
  }, [marketplaceProduct]);

  CustomUseEffect(async () => {
    await setOrderQuantityUnit(
      marketplaceProduct?.unit !== null
        ? marketplaceProduct?.unit
        : OrderQuantityUnit
    );
  }, [marketplaceProduct]);

  const scrollToRef = (myref) => {
    window.scrollTo(0, myref.current && myref.current.offsetTop - 200);
  };

  const handleGalleryImages = async (event) => {
    Object.keys(event.target.files).map((item, i) => {
      if (
        !event.target.files[item].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)
      ) {
        dispatch(setFormErrors(lang?.Imagetype, event.target.id));
        scrollToRef(myRef);
      } else if (event.target.files[item].size > 2000000) {
        dispatch(setFormErrors(lang?.ImageSize, event.target.id));
        scrollToRef(myRef);
      } else {
        setOpenModal(true);

        setFileName(event.target.files[0].name);
        setTargetImageId(event.target.id);
        setImage(URL.createObjectURL(event.target.files[0]));
      }
    });

    setTimeout(() => {
      dispatch(setFormErrors({ msg: "", field: "" }));
    }, 3000);
    return (event.target.value = null);
  };

  const removeGallery = async (index) => {
    if (index > -1) {
      galleryImages.splice(index, 1);
      galleryImageObject.splice(index, 1);
      await setGalleryImages(galleryImages);
      await setGalleryImageObject(galleryImageObject);
      setAddProductDataArr({
        ...addProductDataArr,
        gallery_images: galleryImageObject,
      });
    }
  };

  const handleChange = async (event) => {
    setIsUpdate(true);
    let key = event.target.name;
    let value = event.target.value;
    await setAddProductDataArr({ ...addProductDataArr, [key]: value });
  };

  const handleChangePrice = async (value, name) => {
    name === "rrp_price" ? setRRPrice(value) : await setProductPrice(value);
    await setAddProductDataArr({ ...addProductDataArr, [name]: value ?? 0 });
    setIsUpdate(true);
  };



  const updateProduct = async () => {
    await setIsSpinner(true);
    const {
      title,
      description,
      product_category_id,
      handling_instruction,
      min_order_quantity,
      dispatch_instruction,
      available_for_sample,
      quantity_available,
      product_price,
      keywords,
      brand_label_id,
      gallery_images,
      class_tax_id,
    } = addProductDataArr;
    const obj = {
      title: title || title === "" ? title : marketplaceProduct.title,
      description:
        description || description === "" ? description : marketplaceProduct.description,
      product_category_id:
        product_category_id || product_category_id >= 0
          ? product_category_id.toString()
          : marketplaceProduct.product_category_id.toString(),
      handling_instruction:
        handling_instruction || handling_instruction === ""
          ? handling_instruction
          : marketplaceProduct.handling_instruction,
      min_order_quantity:
        min_order_quantity ?? marketplaceProduct?.min_order_quantity,
      dispatch_instruction:
        dispatch_instruction || dispatch_instruction === ""
          ? dispatch_instruction
          : marketplaceProduct.dispatch_instruction,
      available_for_sample:
        available_for_sample || available_for_sample === ""
          ? available_for_sample
          : marketplaceProduct.available_for_sample,
      quantity_available:
        quantity_available ?? marketplaceProduct?.quantity_available,
      product_price:
        product_price || product_price === ""
          ? product_price
          : marketplaceProduct.product_price,
      keywords:
        keywords || keywords === "" ? keywords : marketplaceProduct?.keywords,
      brand_label_id: brand_label_id
        ? brand_label_id
        : marketplaceProduct.brand_label_id,
      gallery_images: gallery_images,
      class_tax_id: class_tax_id ?? marketplaceProduct?.class_tax_id,
      rrp_price: rrPrice,
    };
    if (showShipping) {
      const { isError, newArray } = isShipingValidate();
      // console.log('newArray', newArray, isError);
      if (isError) {
        setShippingInfo(newArray);
        await setIsSpinner(false);
        return;
      }
    }

    var data = validateData(obj);

    var data = validateData(addProductDataArr);

    if (data.length > 0) {
      await setIsSpinner(false);
      dispatch(setFormErrors(data[0][1], data[0][0]));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if ((gallery_images === undefined || gallery_images.length <= 0) && marketplaceProduct.product_gallery.length <= 0) {
      await setIsSpinner(false);
      dispatch(setFormErrors(lang?.enterImage, "gallery_images"));
      scrollToRef(myRef);
    } else {
      obj.unit = await OrderQuantityUnit;
      await dispatch(updateProductData(obj, marketplaceProduct.marketplace_product_id)
      ).then(async (res) => {
        if (res?.success === 200) {
          // console.log(marketplaceProduct.marketplace_product_id, "marketplaceProduct.marketplace_product_id");
          setMarketPlaceProductId(marketplaceProduct.marketplace_product_id);
          // console.log(marketplaceProduct.marketplace_product_id, "marketplaceProduct.marketplace_product_id");

          setIsSpinner(false);
          setIsSuccess(true);
          setResponseMsg("Successfully updated.");
          await handleSubmitButton(marketplaceProduct.marketplace_product_id);

          await dispatch(getMarketplaceMyProducts(1, {}));
          await dispatch(fetchHomeScreenData());
          setGalleryImages([]);
          history.push("/marketplace/dashboard");
          localStorage.setItem("tab", activeTab);
        } else {
          setIsSpinner(false);
          console.log(res?.errors?.exception);
        }
      });
    }

    setTimeout(() => {
      setIsSuccess(false);
      setResponseMsg("");
      dispatch(setFormErrors({ msg: "", field: "" }));
    }, 5000);
  };

  const validateData = (data) => {
    let keyValidate = [
      // {
      //   name: 'title',
      //   min_char: 3,
      //   max_char: 60,
      //   display_name: `${lang?.only_title}`,
      // },

      {
        name: "product_category_id",
        min_number: 1,
        display_name: `${lang?.Product_category}`,
      },

      // {
      //   name: 'handling_instruction',
      //   min_char: 10,
      //   max_char: 250,
      //   display_name: `${lang?.Handling_Instructions}`,
      // },
      // {
      //   name: 'dispatch_instruction',
      //   min_char: 10,
      //   max_char: 250,
      //   display_name: `${lang?.Dispatch_Instruction}`,
      // },
      {
        name: "available_for_sample",
        min_value: 0,
        display_name: `${lang?.Available_For_Sample}`,
      },
    ];
    let errors = [];
    for (let key = 0; key < keyValidate.length; key++) {
      let keyName = keyValidate[key].name;
      if (!data.hasOwnProperty(keyValidate[key].name) || !data[keyName]) {
        errors.push([
          keyValidate[key].name,
          keyValidate[key].display_name + ` ${lang?.is_required}`,
        ]);
      }

      if (keyValidate[key].hasOwnProperty("min_char")) {
        let keyName = keyValidate[key].name;
        if (data[keyName].length < keyValidate[key].min_char) {
          errors.push([
            keyValidate[key].name,
            keyValidate[key].display_name +
            ` ${lang?.Min_Char} ` +
            keyValidate[key].min_char,
          ]);
        }
      }

      if (keyValidate[key].hasOwnProperty("max_char")) {
        let keyName = keyValidate[key].name;
        if (data[keyName].length > keyValidate[key].max_char) {
          errors.push([
            keyValidate[key].name,
            keyValidate[key].display_name +
            ` ${lang?.LessThan} ` +
            keyValidate[key].max_char +
            " characters",
          ]);
        }
      }

      if (keyValidate[key].hasOwnProperty("min_value")) {
        let keyName = keyValidate[key].name;
        if (
          data[keyName] &&
          data[keyName].length < keyValidate[key].min_value
        ) {
          errors.push([
            keyValidate[key].name,
            keyValidate[key].display_name +
            ` ${lang?.MinValue} ` +
            keyValidate[key].min_value,
          ]);
        }
      }
      if (keyValidate[key].hasOwnProperty("max_value")) {
        let keyName = keyValidate[key].name;
        if (data[keyName] && data[keyName] > keyValidate[key].max_value) {
          errors.push([
            keyValidate[key].name,
            keyValidate[key].display_name +
            ` ${lang?.LessThanOrEq} ` +
            keyValidate[key].max_value,
          ]);
        }
      }
    }
    return errors;
  };

  const deleteProductGalleryImage = async (marketplace_product_gallery_id) => {
    setIsUpdate(true);
    let type = 2;
    const item =
      marketplaceProduct &&
      marketplaceProduct.product_gallery.filter(
        (item) =>
          item.marketplace_product_gallery_id !== marketplace_product_gallery_id
      );
    const delete_product_image = {
      ...marketplaceProduct,
      product_gallery: (marketplaceProduct["product_gallery"] = item),
    };
    const response = await dispatch(
      removeMarketplaceStoreProductImage(marketplace_product_gallery_id, type)
    );
    if (response.success === 200) {
      await dispatch(setMarketplaceProduct(delete_product_image));
      // alert('product remove successfully.');
    }
  };

  // Start Cropping your Image
  const getCropData = async () => {
    setIsUpdate(true);
    setIsCropped(true);
    if (typeof cropper !== "undefined") {
      setCropData(cropper.current.cropper.getCroppedCanvas().toDataURL());
      targetImageId === "gallery_images" &&
        (await galleryImages.push(
          cropper.current.cropper.getCroppedCanvas().toDataURL()
        ));
      await fetch(cropper.current.cropper.getCroppedCanvas().toDataURL()).then(
        (res) =>
          res.blob().then(async (blob) => {
            const file = await new File([blob], `${fileName}`, {
              type: "image/png",
            });
            targetImageId === "gallery_images" &&
              (await galleryImageObject.push(file));
          })
      );
      await setIsCropped(false);
      await setOpenModal(false);
      await setGalleryImages(galleryImages);
      await setAddProductDataArr({
        ...addProductDataArr,
        gallery_images: galleryImageObject,
      });
    }
  };

  const closeModal = async () => {
    await setCropData("");
    await setImage("");
    await setTargetImageId("");
    await setCropData("");
    await setFileName("");
    await setOpenModal(false);
  };
  // End of Cropping

  const selectTab = async (tab) => {
    setActiveTab(tab);
  };

  const getApidata = async () => {
      let api_url = `${REACT_APP_API.ENV.REACT_APP_API}/shipping/form`
         
    const apiData = await fetch(api_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await apiData.json();
    console.log(data, "api data");
    setRanges(data?.ranges);
    setZones(data?.zones);
    setDestinations(data?.destinations);
    setStates(data?.states);
  };
  useEffect(() => {
    getApidata();
  }, []);


  //  @@@ valildate shipping details 
  function isShipingValidate() {

    let isError = false;
    let newArray = [...shippingInfo];

    newArray.forEach((item) => {
      const isQuantityInvalid = item.quantity_id == '';
      const isPriceInvalid = item?.price == '' || item?.price == 0 || item?.price == undefined  || item?.price == null  || item?.price == '$' ;
      let isDestinationInvalid = item.destination_id == 1 || item.destination_id == '';
      let isDestination2or3 = item.destination_id == 2 || item.destination_id == 3



      if (isDestination2or3) {
        let subArray = item?.destination_id == 2 ? item.zones : item.states;
        if (subArray.length > 0) {

          let isEvery = subArray.every((item) => item?.price != undefined && item.price != '' && item?.price != null);
          if (isEvery == false) {
            item['pZsError'] = true;
            isError = true;
          } else {
            item['pZsError'] = false;
            isError = false;
          }
        } else {
          item['pZsError'] = false;
          isError = false;
        }

      }


      if (item.destination_id == '') {
        item['destError'] = true;
        isError = true;
      }

      if (isDestinationInvalid && isPriceInvalid) {
        item['pZsError'] = true;
        isError = true;
      }

      if (isQuantityInvalid) {
        item['quantityError'] = true;
        isError = true;
      }


    });

    return { isError, newArray }
  }

  // @@ add new shipping  
  const handleAddNewShipping = () => {
    // console.log('shshshshsh', shippingInfo);

    const { isError, newArray } = isShipingValidate();
    // console.log('newArraynewArray', newArray, isError);

    if (isError) {
      setShippingInfo(newArray);
      return;
    }
    let newAddArr = [...newArray, shippingNewObj()]
    // console.log('newAddArrnewAddArr', newAddArr)

    setShippingInfo(newAddArr);

  };

  // @@ delete shipping details
  const handleDeleteShiping = (index) => {
    if (shippingInfo.length == 1) return;
    handleIsUpdate()
    setShippingInfo((prev) => {
      const newArray = [...prev];
      newArray.splice(index, 1);
      return newArray;
    });
  }

  // @@ disable range of shipping details
  const isRangeDisabled = (id) => {
    const newArray = [...shippingInfo]
    const isDisabled2 = newArray.some((i) => i?.destination_id == 2 && i.quantity_id == id)
    const isDisabled3 = newArray.some((i) => i?.destination_id == 3 && i.quantity_id == id)
    if (isDisabled3 && isDisabled2) {
      return true;
    }

    const findRangData = shippingInfo?.find((i) => i.quantity_id == id && i?.destination_id == 1);
    console.log('findRangData', findRangData);

    return findRangData ? true : false
  }

  // @@ disable distance of shipping
  const isDistanceDisabled = useCallback((id, index) => {
    const newArray = [...shippingInfo]
    const quantityId = newArray[index].quantity_id;


    if (id == 1 || id == 2 || id == 3) {
      const arrFilter = newArray?.filter((i) => ((i.quantity_id == quantityId && i.quantity_id != '') && (id.destination_id != '' && id.destination_id != id)));
      console.log('arrFilter', arrFilter)
      if (arrFilter.length == 0 || arrFilter.length == 1) {
        return false;
      }
    }


    const isDisabled2 = newArray.some((i) => (i.destination_id == 2) && i.quantity_id == quantityId)
    const isDisabled3 = newArray.some((i) => (i.destination_id == 3) && i.quantity_id == quantityId)

    if (isDisabled2 && (id == 1 || id == 2)) {
      return true
    }

    if (isDisabled3 && (id == 1 || id == 3)) {
      return true
    }

    return false;
  }, [shippingInfo])

  // @@ sleect and input price select
  const handleSelectChange = (e, key, index, errType) => {
    let value = e.target.value;
    let id = e.target.id
    // console.log('handleSelectChange',e,key,value,id,index);
    let keyTwo;
    if (key == 'destination_id') {
      keyTwo = value;
    } else {
      keyTwo = ''
    }

    handleStateUpdate(key, value, index, errType, keyTwo);
  }

  // @@ handle zones checkbox
  const handleZoneCheckbox = (e, index, zItem) => {
    let isChecked = e.target.checked;
    console.log('isChecked', isChecked, index, zItem)
    let newZone = { 'zone_id': zItem?.id, 'price': '' }
    hadleZoneInnerSetStateUpdate(isChecked ? 'ADD' : 'DELETE', index, newZone);

  }
  // @@ update zone under shipping
  const hadleZoneInnerSetStateUpdate = (action, index, newZone, value) => {
    handleIsUpdate();
    setShippingInfo(prev => {
      const newArray = [...prev];
      if (action == 'ADD') {
        let zones = newArray[index].zones || [];
        zones.push(newZone);
        newArray[index].zones = zones;
      } else if (action == 'DELETE') {
        let zoneData = newArray[index].zones;
        let findZIndex = zoneData?.findIndex((i) => i.zone_id == newZone.zone_id);
        console.log('findIndex', newZone, findZIndex, zoneData, newArray)
        newArray[index].zones.splice(findZIndex, 1);
      } else if (action == 'UPDATE') {
        let zoneData = newArray[index].zones;
        newArray[index]['pZsError'] = false;
        let findZIndex = zoneData?.findIndex((i) => i.zone_id == newZone.zone_id);
        newArray[index].zones[findZIndex]['price'] = value
      }
      return newArray;
    });

  }
  // @@ states checkbox
  const handleStatesCheckbox = (e, index, sItem) => {
    let isChecked = e.target.checked;
    console.log('isChecked', isChecked, index)
    let newStates = { 'state_id': sItem?.id, 'price': '' }
    hadleStatesInnerSetStateUpdate(isChecked ? 'ADD' : 'DELETE', index, newStates);

  }

  //  update states under shipping
  const hadleStatesInnerSetStateUpdate = (action, index, newStates, value) => {
    handleIsUpdate();
    setShippingInfo(prev => {
      const newArray = [...prev];
      if (action == 'ADD') {
        const states = newArray[index].states || [];
        states.push(newStates)
        newArray[index].states = states;
      } else if (action == 'DELETE') {
        let statesData = newArray[index].states;
        let findZIndex = statesData?.findIndex((i) => i.state_id == newStates.state_id);
        console.log('findIndex', statesData, findZIndex, newStates, newArray)
        newArray[index].states.splice(findZIndex, 1);
      } else if (action == 'UPDATE') {
        newArray[index]['pZsError'] = false;
        let statesData = newArray[index].states;
        let findZIndex = statesData?.findIndex((i) => i.state_id == newStates.state_id);
        newArray[index].states[findZIndex]['price'] = value > 0 ? value : ''
      }
      return newArray;
    });

  }

  // @@ update shipping state
  const handleStateUpdate = (key, value, index, errType, keyTwo) => {
    handleIsUpdate();
    setShippingInfo((prev) => {
      const newArray = [...prev];
      newArray[index][key] = value;
      if (errType) {
        newArray[index][errType] = false;
      }
      if (key == 'quantity_id') {
        newArray[index]['destination_id'] = '';
      }
      if (keyTwo) {
        if (keyTwo == 1) {
          newArray[index]['zones'] = [];
          newArray[index]['states'] = [];
        } else if (keyTwo == 2) {
          newArray[index]['states'] = [];
          newArray[index]['price'] = '';
        } else if (keyTwo == 3) {
          newArray[index]['price'] = '';
          newArray[index]['zones'] = [];
        }
      }
      return newArray;
    });
  };

  const handleIsUpdate = () => {
    setIsUpdate(true)
  }

  // @@ return updated quantity available value
  const quantityAvailabe = useMemo(() => {
    if (addProductDataArr.hasOwnProperty('quantity_available')) {
      return addProductDataArr?.quantity_available
    }
    let value = (marketplaceProduct?.quantity_available) ? marketplaceProduct?.quantity_available : 0;
    return value;

  }, [marketplaceProduct, addProductDataArr]);


  return (
    <>
      <div className="marketplace dashboard header">
        <div className="flex justify-between items-center">
          <h6 className="back-btn-small">
            <Link to="/marketplace/dashboard?tab=products">
              <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;{" "}
              {lang?.Go_back_Dashboard}
            </Link>
          </h6>
        </div>
      </div>
      <div className="servics_blocks dashboardView">
        <div className="site-align top-align">
          <div className="left__section-block sm:mb-2 md:mb-2">
            <div className="tabLeftBlock">
              <DashboardSideBar
                lang={lang}
                selectTab={selectTab}
                activeTab={activeTab}
                id={id}
              />
            </div>
          </div>
          <div className="servics_blocks middle-long__section-block middleBlockBg">
            <div className="home-section no-padding sm:mt-2">
              <div className="product-title">
                <div className="flex flex-wrap justify-between items-center">
                  <h4 className="mb-0">{lang?.EditProduct}</h4>
                  <div className="inner-btn-box">
                    <Link
                      to="#"
                      className="btn btn-blue SaveBtn"
                      onClick={() => {
                        isUpdate && updateProduct();
                      }}
                    >
                      {isSpinner ? (
                        "Saving..."
                      ) : (
                        <>
                          {" "}
                          <svg
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                          >
                            <path
                              d="M1.66667 0.5C0.754788 0.5 0 1.25479 0 2.16667V13.8333C0 14.7452 0.754788 15.5 1.66667 15.5H13.3333C14.2452 15.5 15 14.7452 15 13.8333V3.83333L11.6667 0.5H1.66667ZM1.66667 2.16667H10.9766L13.3333 4.52344V13.8333H1.66667V2.16667ZM3.33333 3.83333V6.33333H10V3.83333H3.33333ZM7.08333 8C6.5308 8 6.00089 8.21949 5.61019 8.61019C5.21949 9.00089 5 9.5308 5 10.0833C5 10.6359 5.21949 11.1658 5.61019 11.5565C6.00089 11.9472 6.5308 12.1667 7.08333 12.1667C7.63587 12.1667 8.16577 11.9472 8.55647 11.5565C8.94717 11.1658 9.16667 10.6359 9.16667 10.0833C9.16667 9.5308 8.94717 9.00089 8.55647 8.61019C8.16577 8.21949 7.63587 8 7.08333 8Z"
                              fill="white"
                            />
                          </svg>
                          Save
                        </>
                      )}
                    </Link>
                    <Link to="/marketplace/dashboard" className="btn btn--gray">
                      <svg width="18" height="9" viewBox="0 0 18 9" fill="none">
                        <path
                          d="M0 0.833252V8.33325H7.5L4.48242 5.31567C5.63777 4.34289 7.12075 3.74992 8.75 3.74992C11.695 3.74992 14.1875 5.66213 15.07 8.31047L17.0426 7.65291C15.8851 4.17708 12.615 1.66659 8.75 1.66659C6.54497 1.66659 4.53823 2.48852 3.00293 3.83618L0 0.833252Z"
                          fill="black"
                        />
                      </svg>
                      Back
                    </Link>
                  </div>
                </div>
              </div>
              <div className="product-list">
                <div className="recentOrder secBlockGap">
                  <h4 className="secBlockTitle"></h4>
                  <form className="configure-store-form add-product-page">
                    <div className="marketplace add-product">
                      {marketPlaceDashboardSpinner && <FormSpinner />}
                      <div className="mt-0">
                        {responseMsg && (
                          <h4
                            className="font-20 text-center w-full"
                            style={{
                              color: isSuccess ? "green" : "red",
                              textAlign: "center",
                            }}
                          >
                            {responseMsg}.
                          </h4>
                        )}
                        <div className="flex items-center store-profile pb-3">
                          <div className="user__pic">
                            <img
                              src={`${marketPlaceStore.logo_base_url}${marketPlaceStore.logo_id}`}
                              alt={marketPlaceStore.name}
                              title={marketPlaceStore.name}
                            />
                          </div>
                          <h4 className="font-16 ml-5">
                            {marketPlaceStore.name}
                          </h4>
                        </div>
                        <div className="flex flex-wrap w-full">

                          <div className="w-full">
                            <ul className="gallery__images flex">
                              <div className="xl:w-1/4 lg:w-1/4 md:w-full sm:w-full col-padding add--cover--photo mt-0">
                                <div className>
                                  <label>
                                    {isflag ? (
                                      <FormSpinner />
                                    ) : (
                                      <span className>
                                        <i
                                          className="fa fa-picture-o"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    )}
                                    <input
                                      type="file"
                                      id="gallery_images"
                                      name="gallery_images[]"
                                      style={{}}
                                      accept="image/png,image/jpeg"
                                      onChange={(e) => handleGalleryImages(e)}
                                      multiple
                                      value={null}
                                    />{" "}
                                    {lang?.Add_photos}
                                  </label>
                                </div>
                              </div>
                              {galleryImages?.length > 0 &&
                                galleryImages?.map((src, i) => {
                                  return (
                                    <li key={i} className="uploadedImg">
                                      <img src={src} key={i} />
                                      <div className="editDeleteBtn flex justify-end items-center p-2">
                                        <p>
                                          <Link
                                            to="#"
                                            className="remove-image"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => removeGallery(i)}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              aria-hidden="true"
                                            ></i>
                                          </Link>
                                        </p>
                                      </div>
                                    </li>
                                  );
                                })}
                              <div
                                className="carousel-container"
                                style={{
                                  width: "420px",
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Carousel
                                  cols={2}
                                  gap={10}
                                  loop
                                  hideArrow={
                                    marketplaceProduct?.product_gallery
                                      ?.length <= 3
                                      ? true
                                      : false
                                  }
                                >
                                  {marketplaceProduct?.product_gallery?.length >
                                    0 &&
                                    marketplaceProduct?.product_gallery.map(
                                      (item, i) => {
                                        return (
                                          <Carousel.Item>
                                            <li
                                              className="uploadedImg formLoadImg editImage"
                                              key={
                                                item.marketplace_product_gallery_id
                                              }
                                            >
                                              <img
                                                src={`${item.base_url}${item.attachment_medium_url}`}
                                              />
                                              <div className="editDeleteBtn flex justify-end items-center p-2">
                                                <p>
                                                  <Link
                                                    to="#"
                                                    className="remove-image"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      deleteProductGalleryImage(
                                                        item.marketplace_product_gallery_id
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-trash"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </Link>
                                                </p>
                                              </div>
                                            </li>
                                          </Carousel.Item>
                                        );
                                      }
                                    )}
                                </Carousel>
                              </div>
                            </ul>
                          </div>

                          <div className="w-full col-padding imgBottomLine">
                            <p className="mt-3">
                              {lang?.Photos}{" "}
                              {galleryImages.length +
                                (marketplaceProduct &&
                                  marketplaceProduct.product_gallery &&
                                  marketplaceProduct.product_gallery.length)}
                              /8 {lang?.ImageMaxLen}.
                            </p>
                            {errMessage.field == "gallery_images" ? (
                              <FormError msg={errMessage.msg} />
                            ) : null}
                          </div>
                        </div>
                        <div className="mrkt-place form">
                          <div className="card-form">
                            <div className="flex flex-wrap main-row">
                              <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                                <div className="w-100">
                                  <div className="form__item">
                                    <label>{lang?.only_title}*</label>
                                    <input
                                      type="text"
                                      name="title"
                                      onChange={(e) => handleChange(e)}
                                      placeholder={lang?.Product_Title}
                                      id="product-title"
                                      defaultValue={marketplaceProduct?.title}
                                      maxLength={100}
                                    />
                                    {errMessage.field == "title" ? (
                                      <div ref={myRef}>
                                        <FormError msg={errMessage.msg} />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="w-100">
                                  <div className="form__item">
                                    <label>{lang?.Keywords}</label>
                                    <div className="withinfoIcon relative">
                                      <input
                                        type="text"
                                        name="keywords"
                                        onChange={(e) => handleChange(e)}
                                        placeholder={lang?.Keywords}
                                        id="product-title"
                                        defaultValue={
                                          marketplaceProduct.keywords
                                        }
                                      />
                                      <p
                                        className="aadWarning pr-4"
                                        data-tip={
                                          lang?.helps_user_to_find_product
                                        }
                                      >
                                        <i
                                          className="fa fa-exclamation-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </p>
                                      <ReactTooltip />
                                    </div>
                                  </div>
                                </div>
                                <div className="w-100">
                                  <div className="form__item">
                                    <label>{lang?.Product_category}*</label>
                                    <select
                                      name={"product_category_id"}
                                      value={
                                        addProductDataArr?.product_category_id ??
                                        ""
                                      }
                                      onChange={(e) => handleChange(e)}
                                    >
                                      <option value={""}>{lang?.select}</option>
                                      {productCat?.map(({ key, value }) => {
                                        return (
                                          <option key={key} value={value}>
                                            {key}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    {errMessage.field ==
                                      "product_category_id" ? (
                                      <div ref={myRef}>
                                        <FormError msg={errMessage.msg} />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="w-100">
                                  {/* <div className="form__item">
                                <label>{lang?.Quantity_Available}*</label>
                                <input type="text" name="quantity_available" onChange={(e) => handleChange(e)} placeholder={`${lang?.Quantity_Available}`} id="Quantity-Available" defaultValue={marketplaceProduct.quantity_available} />
                                {errMessage.field == "quantity_available" ? (
                                  <div ref={myRef}>
                                    <FormError msg={errMessage.msg} />
                                  </div>
                                ) : null}
                              </div> */}
                                </div>
                                <div className="w-100">
                                  {/* <div className="form__item noBoder minQty"> */}
                                </div>
                              </div>
                              <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                                <div className="col-100">
                                  <div className="form__item">
                                    <label>{lang?.Description}*</label>
                                    <textarea
                                      className="cmnt-write"
                                      id="description"
                                      onChange={(e) => handleChange(e)}
                                      placeholder={`${lang?.Edit_prod_desc}`}
                                      name="description"
                                      defaultValue={
                                        marketplaceProduct.description
                                      }
                                      maxLength="250"
                                    />
                                    {/* {addProductDataArr?.description?.length > 0 && `${addProductDataArr?.description?.length}/250 Characters`} */}
                                    {errMessage.field == "description" ? (
                                      <div ref={myRef}>
                                        <FormError msg={errMessage.msg} />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="w-100">
                                  <div className="form__item">
                                    <label>{lang?.Brand_Label}</label>
                                    <select
                                      name={"brand_label_id"}
                                      value={
                                        addProductDataArr?.brand_label_id ?? ""
                                      }
                                      onChange={(e) => (e) => handleChange(e)}
                                    >
                                      <option value={""}>{lang?.select}</option>
                                      {brandLabel?.map(({ key, value }) => {
                                        return (
                                          <option key={key} value={value}>
                                            {key}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Stock data  */}
                    <h4 className="secBlockTitle">Stock</h4>
                    <div className="marketplace add-product">
                      <div className="mrkt-place form">
                        <div className="card-form">
                          <div className="flex flex-wrap main-row">
                            <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                              <div className="w-100">
                                <div className="form__item noBoder">
                                  <div className="pdctQty">
                                    <div className="">
                                      <label>{lang?.Quantity_Available}</label>
                                      <div className="withinfoIcon relative">
                                        <input
                                          type="text"
                                          name="quantity_available"
                                          onChange={(e) => handleChange(e)}
                                          placeholder={`${lang?.Quantity_Available}*`}
                                          id="Quantity-Available"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          value={quantityAvailabe}

                                        // defaultValue={(marketplaceProduct?.quantity_available) ? marketplaceProduct?.quantity_available : 0}
                                        />
                                        <p
                                          className="aadWarning p-4"
                                          data-tip={lang?.quanity_available}
                                        >
                                          <i
                                            className="fa fa-exclamation-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </p>
                                        <ReactTooltip />
                                      </div>
                                    </div>
                                  </div>
                                  {errMessage.field == "quantity_available" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="w-100">
                                <div className="form__item noBoder">
                                  <label>{lang?.Minimum_Order_Quantity}</label>
                                  <div className="withinfoIcon relative flex items-center">
                                    <input
                                      type="text"
                                      name="min_order_quantity"
                                      onChange={(e) => handleChange(e)}
                                      placeholder={`${lang?.Minimum_Order_Quantity}`}
                                      id="Minimum-Order"
                                      defaultValue={
                                        marketplaceProduct.min_order_quantity !==
                                          "null"
                                          ? marketplaceProduct.min_order_quantity
                                          : 0
                                      }
                                    />
                                  </div>
                                  {errMessage.field == "min_order_quantity" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                              <div className="w-100">
                                <div className="form__item noBoder">
                                  <div className="pieceNo">
                                    <label>{lang?.pieces}</label>
                                    <select
                                      onChange={(e) => {
                                        setOrderQuantityUnit(e.target.value);
                                        setIsUpdate(true);
                                      }}
                                      value={OrderQuantityUnit}
                                    >
                                      <option value={"No. of pieces"}>
                                        {lang?.pieces}
                                      </option>
                                      <option value={"No. of bottles"}>
                                        {lang?.bottles}
                                      </option>
                                      <option value={"liters"}>
                                        {lang?.liters}
                                      </option>
                                      <option value={"kilograms"}>
                                        {lang?.kilograms}
                                      </option>
                                      <option value={"grams"}>
                                        {lang?.grams}
                                      </option>
                                      <option value={"milligrams"}>
                                        {lang?.milligrams}
                                      </option>
                                      <option value={"pallet"}>
                                        {lang?.pallet}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="w-100">
                                <div className="form__item">
                                  <label>{lang?.Sample_Available}*</label>
                                  <select
                                    id="available_for_sample"
                                    name="available_for_sample"
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <option value="-1">
                                      {lang?.Sample_Available}
                                    </option>
                                    <option
                                      value="Yes"
                                      selected={
                                        marketplaceProduct.available_for_sample ===
                                          "Yes"
                                          ? true
                                          : false
                                      }
                                    >
                                      {lang?.yes}
                                    </option>
                                    <option
                                      value="No"
                                      selected={
                                        marketplaceProduct.available_for_sample ===
                                          "No"
                                          ? true
                                          : false
                                      }
                                    >
                                      {lang?.no}
                                    </option>
                                  </select>
                                  {errMessage.field ==
                                    "available_for_sample" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Price data  */}
                    <h4 className="secBlockTitle">{lang?.price}</h4>
                    <div className="marketplace add-product">
                      <div className="mrkt-place form">
                        <div className="card-form">
                          <div className="flex flex-wrap main-row">
                            <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                              <div className="w-100">
                                <div className="form__item">
                                  <label>{lang?.Product_Price}*</label>
                                  <div className="withinfoIcon relative">
                                    <CurrencyInput
                                      prefix="$"
                                      id="product_price"
                                      name="product_price"
                                      placeholder={`${lang?.product_price}*`}
                                      allowDecimals={true}
                                      value={productPrice}
                                      decimalsLimit={2}
                                      onValueChange={(value, name) =>
                                        handleChangePrice(value, name)
                                      }
                                    />
                                    <span className="usd">
                                      <i
                                        className="fa fa-usd"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <p
                                      className="aadWarning pr-4"
                                      data-tip={lang?.charge_10_percentage}
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </p>
                                    <ReactTooltip />
                                  </div>
                                  {productPrice > 0 && (
                                    <p className="textGreen">
                                      You will get $
                                      {productPrice - (productPrice * 10) / 100}{" "}
                                      (Click info icon for more information)
                                    </p>
                                  )}
                                  {errMessage.field == "product_price" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="w-100">
                                <div className="form__item">
                                  <label>{lang?.Recommended_retail_price}</label>
                                  <div className="withinfoIcon relative">
                                    <CurrencyInput
                                      id="rrp_price"
                                      name="rrp_price"
                                      placeholder={`${lang?.product_price}`}
                                      allowDecimals={true}
                                      value={rrPrice}
                                      decimalsLimit={2}
                                      onValueChange={(value, name) =>
                                        handleChangePrice(value, name)
                                      }
                                    />
                                    <span className="usd">
                                      <i
                                        className="fa fa-usd"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <p
                                      className="aadWarning pr-4"
                                      data-tip={lang?.RRP_info}
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </p>
                                    <ReactTooltip />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                              <div className="w-100">
                                <div className="form__item">
                                  <label>{lang?.Tax_class}</label>
                                  <div className="withinfoIcon relative selectOption">
                                    <select
                                      className=""
                                      id="class_tax_id"
                                      name="class_tax_id"
                                      onChange={(e) => {
                                        setIsUpdate(true);
                                        setAddProductDataArr({
                                          ...addProductDataArr,
                                          ["class_tax_id"]: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="-1">
                                        {lang?.Select_tax_Class}
                                      </option>
                                      {allTaxClasses?.length > 0 ? (
                                        allTaxClasses.map(
                                          ({ tax_class_id, name }) => {
                                            return (
                                              <option
                                                value={tax_class_id}
                                                selected={
                                                  marketplaceProduct.class_tax_id ===
                                                    tax_class_id
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {name}
                                              </option>
                                            );
                                          }
                                        )
                                      ) : (
                                        <option value="">
                                          Not create yet!
                                        </option>
                                      )}
                                    </select>
                                    <p
                                      className="aadWarning pr-6"
                                      data-tip={lang?.Tax_class_data_tip}
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                        // title="Select the tax class to apply. For more info go to the Tax Management section on your dashboard."
                                      ></i>
                                    </p>
                                  </div>
                                  {errMessage.field == "class_tax_id" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* shipping Data */}
                    <h4 className="secBlockTitle flex gap-2">
                      <input type="checkbox" checked={showShipping} onChange={handleShowShipping} />
                      {lang?.shipping_name}
                    </h4>
                    {showShipping && (
                      <>
                        {shippingInfo.length > 0 && shippingInfo.map((entry, index) => (
                          <div
                            className="marketplace add-product"
                            key={entry.id}
                          >
                            <div className="mrkt-place form">
                              <div className="card-form">
                                <div className="flex flex-wrap main-row gap-2">

                                  {/* @@START select Range */}
                                  <div className="xl:w-3/12 lg:w-3/12 md:w-full sm:w-full col-padding">
                                    <div className="w-100">
                                      <div className="form__item">
                                        <label >{lang?.Quantity_Range}</label>
                                        <select
                                          name={"quantity_id"}
                                          value={entry?.quantity_id}
                                          required
                                          onChange={(e) => handleSelectChange(e, 'quantity_id', index, 'quantityError')}
                                        >
                                          <option value="">Select Range</option>

                                          {ranges?.length > 0 && ranges.map((iRange, rIndex) => {
                                            console.log('iRangeiRange', iRange);

                                            return (
                                              <option disabled={isRangeDisabled(iRange?.id)} key={iRange.id} value={iRange.id}>
                                                {iRange.range}
                                              </option>
                                            )
                                          })}
                                        </select>
                                        {entry?.quantityError &&
                                          <div >
                                            <FormError msg={"Range is Required"} />
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  {/* @@END select Range */}


                                  {/* @@START select Desitnation */}
                                  <div className="xl:w-3/12 lg:w-3/12 md:w-full sm:w-full col-padding">
                                    <div className="w-100">
                                      <div className="form__item">
                                        <label>{lang?.Destination_name}</label>
                                        <select name="destination_id" onChange={(e) => handleSelectChange(e, 'destination_id', index, 'destError')}
                                          value={entry.destination_id}
                                          required>
                                          <option value=""> Select Destination</option>
                                          {destinationArr?.map((option) => {
                                            return (
                                              <option disabled={isDistanceDisabled(option.value, index)} key={option.value} value={option.value} >
                                                {option.label}
                                              </option>
                                            )
                                          })}
                                        </select>
                                        {entry?.destError &&
                                          <div >
                                            <FormError msg={"Destination is Required"} />
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  {/* @@End select Desitnation */}

                                  {/* if destination not selected */}
                                  <div className="xl:w-4/12 lg:w-4/12 md:w-full sm:w-full col-padding">
                                    {(entry.destination_id == "" || entry.destination_id == 1) && (
                                      <div className="w-100">
                                        <div className="form__item">
                                          <label>{lang?.price}</label>
                                          {/* <input
                                            type="number"
                                            placeholder="$10.00"
                                            value={entry?.price}
                                            onChange={(e) => handleSelectChange(e, 'price', index, 'pZsError')}

                                          /> */}

                                          <CurrencyInput
                                            prefix="$"
                                            id="price"
                                            name="price"
                                            placeholder={`$10.00`}
                                            allowDecimals={true}
                                            decimalsLimit={2}
                                            defaultValue={entry?.price}
                                            onValueChange={(value, name) =>
                                              handleSelectChange({ target: { value: value } }, 'price', index, 'pZsError')
                                            }
                                          />
                                          {entry?.pZsError &&
                                            <div >
                                              <FormError msg={"Price is Required"} />
                                            </div>
                                          }
                                        </div>
                                      </div>
                                    )}

                                    {/*  if destination is us zones */}
                                    {entry?.destination_id == 2 && <>
                                      <div className="w-100">
                                        <div className="form__item">
                                          <label>US Region</label>
                                          {entry?.pZsError &&
                                            <div >
                                              <FormError msg={"Price is Required"} />
                                            </div>
                                          }
                                          {zones.length > 0 ? (
                                            <div className="h-28 overflow-y-auto border p-2">

                                              {zones.length > 0 && <>
                                                {zones.map((zItem, zIndex) => {
                                                  const allState = zItem?.states?.length > 0 && zItem?.states?.map((state, index) => {
                                                    return index === zItem?.states?.length - 1 ? state?.name : state?.name + ',';
                                                  }).join(' ');

                                                  const isZoneExist = entry?.zones?.find((i) => i.zone_id == zItem.id);
                                                  console.log('isZoneChecked', entry, zones, isZoneExist)
                                                  return <>
                                                    <div key={zItem.id} className="zone-option flex justify-between items-center mb-1">
                                                      <div className="withinfoIcon relative flexBox labelInfo">
                                                        <input
                                                          type="checkbox"
                                                          id={zItem.id}
                                                          checked={isZoneExist ? true : false}
                                                          onChange={(e) => handleZoneCheckbox(e, index, zItem)}
                                                        />
                                                        <label htmlFor={zItem.id}>
                                                          {zItem.name}
                                                        </label>
                                                        {(allState) && <span className=" ml-2">
                                                          <span className="" data-tip={allState} currentitem="false"  >
                                                            <i className="fa fa-exclamation-circle cursor-info" aria-hidden="true"></i>
                                                          </span>
                                                          <ReactTooltip />
                                                        </span>}
                                                      </div>
                                                      {isZoneExist &&
                                                        <div className="w-20">
                                                          {/* <input
                                                            type="number"
                                                            placeholder={`$10.00`}
                                                            className="zone-input"
                                                            value={(isZoneExist?.price && isZoneExist?.price > 0) ? isZoneExist?.price : ''}
                                                            onChange={(e) => hadleZoneInnerSetStateUpdate('UPDATE', index, isZoneExist, Number(e.target.value))}
                                                          />  */}
                                                          <CurrencyInput
                                                            prefix="$"
                                                            id="price"
                                                            name="price"
                                                            placeholder={`$10.00`}
                                                            allowDecimals={true}
                                                            decimalsLimit={2}
                                                            
                                                            value={(isZoneExist?.price && isZoneExist?.price > 0) ? isZoneExist?.price : ''}
                                                            onValueChange={(value, name) =>
                                                              hadleZoneInnerSetStateUpdate('UPDATE', index, isZoneExist, value)
                                                            }
                                                          />

                                                        </div>}

                                                    </div>
                                                  </>
                                                })}

                                              </>}
                                            </div>
                                          ) : (
                                            <p>Loading zones...</p>
                                          )}
                                        </div>
                                      </div>
                                    </>}

                                    {/*  if destination is States */}
                                    {entry?.destination_id == 3 && <>
                                      <div className="w-100">
                                        <div className="form__item">
                                          <label>States</label>
                                          {entry?.pZsError &&
                                            <div >
                                              <FormError msg={"Price is Required"} />
                                            </div>
                                          }
                                          {states.length > 0 ? <div className="h-28 overflow-y-auto border p-2">

                                            {states.map((sItem, sIndex) => {
                                              const isStatesExist = entry?.states?.find((i) => i.state_id == sItem.id);

                                              return (
                                                <div
                                                  key={sItem.id}
                                                  className="state-option flex justify-between items-center mb-1"
                                                >
                                                  <div className="flex items-center">
                                                    <input
                                                      type="checkbox"
                                                      id={sItem.id}
                                                      checked={isStatesExist ? true : false}
                                                      onChange={(e) => handleStatesCheckbox(e, index, sItem)}

                                                    />
                                                    <label htmlFor={sItem.id}>
                                                      {sItem.name}
                                                    </label>
                                                  </div>
                                                  {isStatesExist &&
                                                    <div className="w-20">
                                                      {/* <input
                                                        type="number"
                                                        placeholder={`$10.00`}
                                                        className="state-input"
                                                        onChange={(e) => hadleStatesInnerSetStateUpdate('UPDATE', index, isStatesExist, Number(e.target.value))}
                                                        value={(isStatesExist?.price && isStatesExist?.price > 0) ? isStatesExist?.price : ''}
                                                      />  */}
                                                      <CurrencyInput
                                                        prefix="$"
                                                        id="price"
                                                        name="price"
                                                        placeholder={`$10.00`}
                                                        allowDecimals={true}
                                                        decimalsLimit={2}
                                                        defaultValue={(isStatesExist?.price && isStatesExist?.price > 0) ? isStatesExist?.price : ''}
                                                        onValueChange={(value, name) =>
                                                          hadleStatesInnerSetStateUpdate('UPDATE', index, isStatesExist, Number(value))
                                                        }
                                                      />

                                                    </div>
                                                  }
                                                </div>
                                              )
                                            })}
                                          </div> : <>
                                            <p>Loading states...</p>
                                          </>}
                                        </div>
                                      </div>
                                    </>}

                                  </div>
                                  <div className="xl:w-1/12 lg:w-2/12 md:w-full sm:w-full col-padding">
                                    {console.log('indexindex', index, shippingInfo.length)}
                                    {/* @@ Add and remove new shipping */}
                                    <div className="flex gap-2 mt-6">
                                      {(index == shippingInfo.length - 1) && <>
                                        <span>
                                          <i onClick={() => handleAddNewShipping()} className="fa fa-plus-circle text-green-400 text-xl cursor-pointer"></i>
                                        </span>
                                      </>}
                                      {shippingInfo.length > 1 &&
                                        <span>
                                          <i onClick={() => handleDeleteShiping(index)} className="fa fa-minus-circle text-red-400 text-xl cursor-pointer"></i>

                                        </span>
                                      }</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                    {/* Other data  */}
                    <h4 className="secBlockTitle">{lang?.Other_name}</h4>
                    <div className="marketplace add-product">
                      <div className="mrkt-place form">
                        <div className="card-form">
                          <div className="flex flex-wrap main-row">
                            <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                              <div className="w-100">
                                <div
                                  className="form__item"
                                  onClick={() => setIsUpdate(true)}
                                >
                                  <div className="withinfoIcon relative flexBox labelInfo">
                                    <label>
                                      {lang?.Handling_Instructions}*
                                    </label>
                                    <p
                                      className=""
                                      data-tip={
                                        lang?.Provide_details_about_your_safe_handling_instructions
                                      }
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </p>
                                    <ReactTooltip />
                                  </div>
                                  <div className="withinfoIcon relative textArea">
                                    <MyEditor
                                      setAddProductDataArr={
                                        setAddProductDataArr
                                      }
                                      keys="handling_instruction"
                                      data={
                                        marketplaceProduct.handling_instruction
                                      }
                                    />
                                  </div>
                                  {addProductDataArr?.handling_instruction
                                    ?.length > 0 &&
                                    `${addProductDataArr?.handling_instruction?.replace(
                                      /<[^>]+>/g,
                                      ""
                                    )?.length
                                    }/250 Characters`}
                                  {errMessage.field ==
                                    "handling_instruction" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                              <div className="col-100">
                                <div
                                  className="form__item"
                                  onClick={() => setIsUpdate(true)}
                                >
                                  <div className="withinfoIcon relative flexBox labelInfo">
                                    <label>{lang?.Dispatch_Instruction}*</label>
                                    <p
                                      className=""
                                      data-tip={
                                        lang?.detailAboutSpecificDispatch
                                      }
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </p>
                                    <ReactTooltip />
                                  </div>
                                  <div className="withinfoIcon relative textArea">
                                    <MyEditor
                                      setAddProductDataArr={
                                        setAddProductDataArr
                                      }
                                      keys="dispatch_instruction"
                                      data={
                                        marketplaceProduct.dispatch_instruction
                                      }
                                    />
                                  </div>
                                  {addProductDataArr?.dispatch_instruction
                                    ?.length > 0 &&
                                    `${addProductDataArr?.dispatch_instruction?.replace(
                                      /<[^>]+>/g,
                                      ""
                                    )?.length
                                    }/250 Characters`}
                                  {errMessage.field ==
                                    "dispatch_instruction" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* <div className="w-full col-padding mt-3">
                    <p className="">
                      Photos {galleryImages.length}/5 Choose your stores main photo first. Add more photos of your store max 5 allowed.
                    </p>
                    {errMessage.field == "gallery_images" ? (
                            <FormError msg={errMessage.msg} />
                          ) : null}
                    
                    </div> */}
                <div className="mrkt-place form editProduct">
                  <div className="card-form">
                    <form className="configure-store-form add-product-page"></form>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* </div>
          </div>
        </div> */}
      {/* crop image nodal */}
      <div>
        {openModal && (
          <Modal
            className=""
            ariaHideApp={false}
            isOpen={openModal}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0px",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title">{lang?.Crop_image}</h4>
              <button className="close_btn--modal" onClick={() => closeModal()}>
                {" "}
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box">
              <div className="crop_img_block">
                <Cropper
                  zoomTo={0}
                  style={{ height: 400, width: "100%" }}
                  preview=".img-preview"
                  src={image}
                  viewMode={1}
                  dragMode={"move"}
                  cropBoxMovable={false}
                  responsive={true}
                  cropBoxResizable={false}
                  ref={cropper}
                  initialAspectRatio={2 / 2}
                  // ready={() => {
                  //   cropper.current.cropper.setCropBoxData({
                  //     left: 70,
                  //     top: 70,
                  //     width: 200,
                  //     height: 200,
                  //   });
                  // }}
                  center={true}
                  guides={false}
                />
              </div>
              <div className="form__action form__item mt-4">
                <button onClick={() => getCropData()}>
                  {" "}
                  {isCropped ? <FormSpinner /> : `${lang?.Crop_image}`}{" "}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
