import React from 'react'
import user_img from '../../../assets/images/user_profile-img.png'
import user from '../../../assets/images/user_profile-img.png'
import { useState, useEffect, } from 'react';
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import moment from 'moment';
import ReactImageVideoLightbox from 'react-image-video-lightbox'
import ReactStars from "react-rating-stars-component";
import Map from "../../../components/Map/storeMap"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import {
  fetchStoreReview,
  fetchStoreDataById,
  fetchFavouriteStoreProduct,
  setUnFavouriteStoreProduct,
  getProductStoreReview,
  saveReviewRating,
  updateMarketplaceReview,
  setMarketPlaceStoreDataById,
  fetchAllStoreProductTypeAndData,
  addProdctToCart
} from "../modules/marketplace";
import FormSpinner from '../../../components/Spinners/FormSpinner';
import Carousel from 'react-grid-carousel';
import MemberType from '../../../components/MemeberType';
import language from '../../../helpers/language';
import constant from '../../../helpers/constant';
import CustomUseEffect from '../../../components/constants';


export default function

  NewlyAddStore(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [reviewModal, setReviewModal] = useState(false);
  const [addReviewModal, setAddReviewModal] = useState(false);
  const [editReviewModal, setEditReviewModal] = useState(false);
  const [lang] = useState(language.getLang());
  const [rating, setRating] = useState(0);
  const [marketplaceReviewRatingId, setMarketplaceReviewRatingId] = useState(0);
  const [review, setReview] = useState("");
  const [reviewStatus, setReviewStatus] = useState("");
  const [reviewSuccessMsg, setReviewSuccessMsg] = useState("");
  const [isRatings, setIsRatings] = useState(false);
  const [productItemCart, setProductItemInCart] = useState([]);

  const [isOpenImage, setIsOpenImage] = useState(false);
  const [userPhotosData, setUserPhotosData] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [addUpdateReview, setAddUpdateReview] = useState(false);
  // const [userData,setUserData]=useState()

  CustomUseEffect(async () => {
    window.scrollTo(0, 0);
    setIsRatings(false);
    await dispatch(fetchStoreDataById(id));
    await dispatch(fetchStoreReview(id));
    await dispatch(fetchAllStoreProductTypeAndData(id));
    setIsRatings(true);
    window.scrollTo(0, 0);
  }, [id]);

  // useEffect(()=>{
  //   let role=JSON.parse(localStorage.getItem('USER_TYPE'))
  //   setUserData(role)
  // },[])

  useEffect(() => {
    productItemCart?.length > 0 && localStorage.setItem('totalProductInCart', JSON.stringify(productItemCart));
  }, [productItemCart]);

  useEffect(() => {
    const totalProduct = localStorage.getItem('totalProductInCart') ? JSON.parse(localStorage.getItem('totalProductInCart')) : [];
    totalProduct?.length > 0 && setProductItemInCart(totalProduct);
  }, []);

  let {
    storeReview,
    marketPlaceSpinner,
    marketplacestoreDataById,
    isStoreReviewed,
    allStoreProductTypeAndData,
  } = useSelector((state) => state.marketPlaceReducerData);

  const { profileData: { data } } = useSelector((state) => state.memberProfileData);

  const ratingChanged = (newRating) => {
    setRating(newRating);
  }

  const reviewChanged = (value) => {
    setReview(value);
  }

  const getAllReviews = async (id) => {
    await dispatch(getProductStoreReview(id, 1));
    setReviewModal(!reviewModal);
  }

  const calculateRatingPercentage = (total_one_start, total_reviews) => {
    if (total_one_start == 0) {
      return 0;
    }
    return parseInt((total_one_start / total_reviews) * 100).toFixed();
  }

  const handleFavouritesItems = async (storeProduct_id, number) => {
    const fav_unfav = number === 1 ? 0 : 1;
    let favourite_type = 1;
    let favorite = 2;
    let unFavorite = 1;
    let favoriteData = {
      ...marketplacestoreDataById,
      is_favourite: marketplacestoreDataById["is_favourite"] = fav_unfav
    }
    await dispatch(setMarketPlaceStoreDataById(favoriteData));
    if (number === favorite) {
      const response = await dispatch(fetchFavouriteStoreProduct(favourite_type, storeProduct_id));
      (response.success === 200) && await dispatch(setMarketPlaceStoreDataById(favoriteData));
    } if (number === unFavorite) {
      const response = await dispatch(setUnFavouriteStoreProduct(favourite_type, storeProduct_id));
      (response.success === 200) && await dispatch(setMarketPlaceStoreDataById(favoriteData));
    }

  }

  const addReviewModalFn = async () => {
    setReviewModal(!reviewModal);
    setAddReviewModal(!addReviewModal);
  }

  const editReviewModalFn = async () => {
    setReviewModal(!reviewModal);
    setEditReviewModal(!editReviewModal);
    const user_id = data && data.user_data.user_id;
    const storeReviews = storeReview && storeReview.filter((item) => item.user_id == user_id);
    storeReviews && storeReviews.map((item) => {
      setRating(item.rating);
      setReview(item.review);
      setMarketplaceReviewRatingId(item.marketplace_review_rating_id);
    });
  }

  const submitUpdateReview = async () => {
    const userReview = review?.trim();
    if (userReview === "" || userReview?.length < 3) {
      setReviewStatus("Review is required");
    } else {
      setIsRatings(false);
      setAddUpdateReview(true);
      let type = 1;
      const data = {
        type,
        rating,
        review: review.trim(),
        marketplace_review_rating_id: marketplaceReviewRatingId,
      }
      let response = data && await dispatch(updateMarketplaceReview(data, '', id));
      if (response.success === 200) {
        setIsRatings(true);
        setAddUpdateReview(false);
        setReviewSuccessMsg(response.message);
        setReviewModal(false);
        setEditReviewModal(!editReviewModal);
      }
    }
    setTimeout(() => {
      setReviewStatus('');
      setAddUpdateReview(false);
      setReviewSuccessMsg('');
    }, 3000);

  }

  const submitReview = async () => {
    setIsRatings(false);
    if (rating <= 0) {
      setReviewStatus("Rating is required");
    } else if (review.trim() == "") {
      setReviewStatus("Review is required");
    } else if (review.trim()?.length < 3) {
      setReviewStatus("Review must be greater than 3 char");
    } else {
      let response = await dispatch(saveReviewRating(review, rating, 1, id));
      if (response.success === 200) {
        setIsRatings(true);
        setAddReviewModal(!addReviewModal);
        setReviewSuccessMsg("Thank you for your review");
        await dispatch(getProductStoreReview(id, 1));
        setAddReviewModal(!addReviewModal);
      }
      if (response.success === 409) {
        setIsRatings(true);
        setReviewStatus(response.errors.exception);
      }
    }
    setTimeout(() => {
      setReviewStatus('');
      setReviewSuccessMsg('');
    }, 3000);
  }

  // photos gallery
  const handlePhotos = async (data, url) => {
    let userPhotos = []
    await marketplacestoreDataById?.store_gallery?.map(async (item) => {
      await userPhotos.push({ url: `${item.base_url}${item.attachment_url}`, type: "photo", altTag: "image", })
    })
    userPhotos?.map(async (item, index) => {
      console.log(item?.url, url);
      if (item?.url === url) {
        await setImageIndex(index)
      }
    })

    await setUserPhotosData(userPhotos)
    await setIsOpenImage(true)
  }

  // Add product into cart
  const addToCart = (item) => {
    //distructuring taxes from item 
    console.log(item, 'item...')
    const taxes = item?.get_product_tax?.get_tax_classes?.map((item) => ({ ...item?.get_tax_detail }));
    // checking product is from same store or not
    if (productItemCart?.length > 0 && findProductAlreadyAdded(item.marketplace_store_id) && productItemCart?.[0]?.map_offer_id?.length === 0) {
      // filter same product from productItemCart
      const filterWithIdData = productItemCart.filter((prod) => prod.marketplace_product_id === item.marketplace_product_id);
      let data = [];
      if (filterWithIdData?.length > 0) {
        data = filterWithIdData?.map((product) => (product.marketplace_product_id === item.marketplace_product_id)
          ? { ...product, count: product.count + Number(product?.min_order_quantity), available_status: 0, tax: taxes, product_price: item.product_price ? item.product_price : 0, map_offer_id: [] } : product);
      } else {
        data = [{ ...item, count: Number(item?.min_order_quantity), available_status: 0, tax: taxes, map_offer_id: [] }]
      }
      const newfilterData = productItemCart?.filter((prod) => prod.marketplace_product_id !== item.marketplace_product_id)
      setProductItemInCart([...newfilterData, ...data]);
      dispatch(addProdctToCart([...newfilterData, ...data]))
    } else {
      // alert when adding other store product in cart
      if (productItemCart?.length > 0) {
        showAlert([{ count: Number(item?.min_order_quantity), ...item, available_status: 0, tax: taxes, map_offer_id: [] }])
      } else {
        setProductItemInCart([{ count: Number(item?.min_order_quantity), ...item, available_status: 0, tax: taxes, map_offer_id: [], }]);
        dispatch(addProdctToCart([{ count: Number(item?.min_order_quantity), ...item, available_status: 0, tax: taxes, map_offer_id: [], }]));
      }
    }
  }

  // find all Product are same store
  const findProductAlreadyAdded = (id) => {
    return productItemCart?.length > 0 ? productItemCart?.some((product) => product.marketplace_store_id === id) : true;
  }

  // click on cancel button
  const handleAlertCancel = () => {
    // Function to be called when Cancel is clicked
    console.log('Cancel button clicked!');
    // Call any other desired function or perform actions here
  };

  // if user add product from other store
  const showAlert = (data) => {
    if (window.confirm(productItemCart?.[0]?.map_offer_id?.length > 0 ? `At a time you can add either a product including offer or a product without offer.
    Do you want to add this product. Are you sure?` : `Currently you have items in your cart from another store.Would you like to clear the cart and add this product. Are you sure?`)) {
      setProductItemInCart(data);
      dispatch(addProdctToCart(data));
    } else {
      handleAlertCancel();
    }
  };

  // check product is already in cart
  const checkProductAlreadyInCart = (id) => {
    if (!productItemCart) return false;
    return productItemCart.some((item) => item.marketplace_product_id === id)
  }


  return (
    <div className="marketplace mrkthome add-product bg-gray newGridView listPage">
      {reviewModal && (
        <div>
          <Modal
            isOpen={reviewModal}
            ariaHideApp={false}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title">
                {/* {lang?.Reviews} */}
                Rating and Review
              </h4>
              {!isStoreReviewed && <Link className="btn BgTeal" onClick={() => editReviewModalFn()}>{lang?.Edit_review}</Link>}
              <button
                className="close_btn--modal"
                onClick={() => setReviewModal(!reviewModal)}
              >
                {lang?.close}
              </button>
            </div>

            <div className="card card--block modal_box text-center">
              {isStoreReviewed && <div className='text-right'><Link to="#" className='btn BgTeal text-center' onClick={() => addReviewModalFn()}>+Add {lang?.review}</Link></div>}
              <div className="feed-user-info home_page block edit mt-3 mb-3 addReview">
                {storeReview && storeReview.length > 0 && storeReview.map((item, i) => {
                  return (
                    <div id={`review` + i} className='w-full reviewRepeat'>
                      <div className='flex'>
                        <div className='blockLeft'>
                          <div className="user__pic mr-3">
                            <span className='profile-img'>
                              <img
                                src={`${item && item.user && item.user.avatar_id && item.user.avatar_id.base_url}${item && item.user && item.user.avatar_id && item.user.avatar_id.attachment_url}`}
                              />
                            </span>
                          </div>
                          <div>
                            <span className='profile-name font-bold'>
                              {item && item.user && item.user.review_name}
                            </span>
                            <div className='description mt-0'>
                              <p>{item && item.review}</p>
                            </div>
                          </div>
                        </div>
                        <div className='blockRight'>
                          <div className='review justify-end'>
                            <ReactStars
                              edit={false}
                              isHalf={true}
                              value={item.rating}
                              activeColor="#eb8a2f"
                              color="rgb(74 72 72)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
                }

              </div>
            </div>
          </Modal>
        </div>
      )}
      {addReviewModal && (
        <div>
          <Modal
            isOpen={addReviewModal}
            ariaHideApp={false}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              {/* <h4 className="page__title">{lang?.Reviews}</h4> */}
              <h4 className='font-16'>{lang?.AddReviewAndRatings}</h4>
              <button
                className="close_btn--modal"
                onClick={() => { setAddReviewModal(!addReviewModal); setReview(''); setRating(0) }}
              >
                {lang?.close}
              </button>
            </div>

            <div className="card card--block modal_box addReview">
              <div className="feed-user-info home_page items-center mb-3">
                <div className='blockLeft'>
                  <div className="user__pic mr-3">
                    {data && data.user_data && data.user_data.avatar_id ? <img src={`${data.user_data.avatar_id.base_url}${data.user_data.avatar_id.attachment_url}`} height="100" width="100" /> : <img src={user} alt="default-image" />}
                  </div>
                  <span className="user__name">
                    <div className="profile-name font-bold">
                      {data && data.user_data && data.user_data.company_name}
                    </div>
                  </span>
                </div>
                <div className='blockRight'>
                  <div className="rating text-right">
                    <ReactStars
                      // edit={false}
                      isHalf={false}
                      activeColor="#eb8a2f"
                      color="rgb(74 72 72)"
                      edit={true}
                      onChange={ratingChanged}
                    />
                  </div>
                </div>
              </div>
              <p style={{ color: "red" }}>{reviewStatus}</p>
              <div className='feed-user-info home_page'>
                <div className="comment-box w-full">
                  {/* <h4 className='font-16'>{lang?.AddReviewAndRatings}</h4> */}
                  <div className="comment-area">
                    <textarea className="form-control"
                      placeholder="what is your view?"
                      rows="4"
                      onChange={(e) => reviewChanged(e.target.value)}
                    >
                    </textarea>
                  </div>
                  <div className="text-right mt-4">
                    <Link to="#" className="btn BgTeal" onClick={() => submitReview()}>{lang?.Add_review}</Link>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
      {editReviewModal && (
        <div>
          <Modal
            isOpen={editReviewModal}
            ariaHideApp={false}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title">{lang?.review}</h4>
              <button
                className="close_btn--modal"
                onClick={() => setEditReviewModal(!editReviewModal)}
              >
                {lang?.close}
              </button>
            </div>

            <div className="card card--block modal_box">
              <div className="feed-user-info home_page block edit mb-3">
                <div className='flex'>
                  <div className="user__pic mr-3">
                    <span className="profile-img">
                      {data && data.user_data.avatar_id ? <img src={`${data.user_data.avatar_id.base_url}${data.user_data.avatar_id.attachment_url}`} height="100" width="100" /> : <img src={user_img} alt="default-image" />}
                    </span>
                  </div>
                  <div>
                    <span className="profile-name font-bold">{data && data.user_data && data.user_data.company_name}</span>
                  </div>
                </div>
              </div>

              <div className='feed-user-info home_page'>
                <div className="comment-box w-full">
                  <h4 className='font-16'>{lang?.EditReviewAndRatings}</h4>
                  <div className="rating text-right">
                    <ReactStars
                      // edit={false}
                      isHalf={false}
                      activeColor="#eb8a2f"
                      value={rating}
                      color="rgb(74 72 72)"
                      edit={true}
                      onChange={ratingChanged}
                    />
                  </div>
                  <div className="comment-area">
                    <textarea className="form-control"
                      placeholder="what is your view?"
                      rows="4"
                      onChange={(e) => reviewChanged(e.target.value)}
                      defaultValue={review}
                    >
                    </textarea>
                  </div>
                  <div className="text-right mt-4">
                    {addUpdateReview ? <div className="btn BgTeals"><FormSpinner /></div> : <Link to="#" className="btn BgTeal" onClick={() => submitUpdateReview()}>{lang?.Add_review}</Link>}
                  </div>

                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
      <div className="">
        <div className="section__wrapper">
          <div className="page-main">
            <div className="">
              <div className="flex justify-between items-center">
                <Link to="#" className="back__btn" onClick={() => history.goBack()}>
                  <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;
                  {lang?.Go_back}
                </Link>
              </div>
              <div className="right-banner mt-3 bg-gray-200 bg--banner--img" style={{ background: `url(${marketplacestoreDataById.banner_base_url}${marketplacestoreDataById.banner_id}) no-repeat`, backgroudSize: '100% auto', backgroundPosition: "center" }}>
                <div className="profile-upload-circle">
                  {marketPlaceSpinner ? <FormSpinner /> : <div className="bg--img-center">
                    <img src={`${marketplacestoreDataById.logo_base_url}${marketplacestoreDataById.logo_id}`} alt={marketplacestoreDataById.name} title={marketplacestoreDataById.name} />
                  </div>}
                  <label className="hide-input">
                    {/*<span className>
                      <i className="fa fa-camera" aria-hidden="true" />
                    </span>*/}
                    <input type="file" style={{}} />
                  </label>
                </div>
              </div>
              <div className="our__gallery mt--5">
                <h2 style={{ color: "green" }} className="text-center mb-4 font-20">{reviewSuccessMsg}</h2>
                <div className="flex wrapOnMob">
                  <div className="page_w_left">
                    {/* store gellary */}
                    <div className="product-type pt-4">
                      <h4 className="product-title">{lang?.Our_gallery}</h4>
                      <Carousel cols={3} rows={1} gap={10} loop
                        hideArrow={marketplacestoreDataById?.store_gallery?.length <= 3 ? true : false}>
                        {marketplacestoreDataById?.store_gallery?.length > 0 && marketplacestoreDataById?.store_gallery?.map((items) => {
                          return (
                            <Carousel.Item>
                              <div className='productImgSlider'>
                                <Link to='#'>
                                  <img src={`${items.base_url}${items.attachment_url}`} alt="Image"
                                    onClick={() => handlePhotos(marketplacestoreDataById, `${items.base_url}${items.attachment_url}`)}
                                  />
                                </Link>
                              </div>
                            </Carousel.Item>
                          )
                        })
                        }
                      </Carousel>

                    </div>
                    <div className="gallery_data mt-4">
                      <h4 className="font-18 black">{lang?.About_store}</h4>
                      <p>
                        {marketplacestoreDataById.description}
                      </p>
                      <hr />
                      <h4 className="font-18 black">{lang?.Store_products}</h4>
                      <div className='product-type tabView'>
                        <Tabs>
                          <TabList>
                            <Tab>{lang?.All}</Tab>
                            {allStoreProductTypeAndData?.length > 0 && allStoreProductTypeAndData?.map(({ name, category_id }) => {
                              return (
                                <Tab key={category_id}>{name}</Tab>
                              )
                            })}
                          </TabList>
                          <TabPanel>
                            <div className="card-block-wrapper grid3">
                              {
                                marketplacestoreDataById && marketplacestoreDataById.store_products && marketplacestoreDataById.store_products.map((item) => {
                                  return (
                                    <>
                                      <div className="card-block-item mt-2 mb-2">
                                        <div className='card-item-wrapper'>
                                          <div className='card-item-img'>
                                            <div className="item-img">
                                              <Link to={`/marketplace/product/${item.slug}`}>
                                                <img
                                                  src={`${item.product_gallery[0].base_url}${item.product_gallery[0].attachment_url}`}
                                                />
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='card-item-content'>
                                          <div className='card-content-wrapper'>
                                            <div className='flex flex-wrap justify-between mt-3'>
                                              <h4 className="font-18 black mt-0">{item.title?.length > 10 ? `${item.title?.slice(0, 10)}...` : item.title}</h4>
                                              <h4 className="font-18 black mt-0">{item?.product_price > 0 && `$ ${item?.product_price}`}</h4>
                                            </div>
                                            <p className='mt-2 mb-2 paraparahideMob'>{marketplacestoreDataById.name}</p>
                                            {/* <div className="flexBox items-center justify-between">
                                              <h4 className="font-16">Calabria</h4>
                                              <h4 className="count_no font-16 text-teal">Bakery &amp; Snacks</h4>
                                            </div> */}
                                            <div className="flex flex-wrap justify-between items-center mt-2">
                                              <p className="rating flex items-center">
                                                <span className='flex items-center gap-1'>0.0 <i className="fa fa-star" aria-hidden="true"></i></span>&nbsp;0 Rating
                                              </p>
                                              {(item?.product_price > 0 && (data?.user_data?.role_id === constant.rolesId.importer || data?.user_data?.role_id === constant.rolesId['importer&distributer'] || data?.user_data?.role_id === constant.rolesId.distributer)) &&
                                              <> 
                                                {checkProductAlreadyInCart(item?.marketplace_product_id) ? <button type='button' className='btn btnTeal addtocart font-bold' onClick={() => history.push('/marketplace/my-cart')}>Go To Cart</button> : <button type='submit' className='btn btnTeal addtocart font-bold' onClick={() => addToCart(item)}>Add To Cart </button>}
                                              </>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                })
                              }
                            </div>
                          </TabPanel>
                          {allStoreProductTypeAndData?.length > 0 && allStoreProductTypeAndData?.map(({ products, category_id }) => {
                            return (
                              <TabPanel key={category_id}>
                                <div className="card-block-wrapper grid3">
                                  {products?.map((item) => {
                                    return (
                                      <div className="card-block-item mt-2 mb-2">
                                        <div className='card-item-wrapper'>
                                          <div className="card-item-img">
                                            <div className="item-img">
                                              <Link to={`/marketplace/product/${item?.slug}`}>
                                                <img
                                                  src={`${item?.product_gallery[0]?.base_url}${item.product_gallery[0]?.attachment_url}`}
                                                />
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-item-content">
                                          <div className='card-content-wrapper'>
                                            <div className='flex flex-wrap justify-between mt-3'>
                                              <h4 className="font-18 black mt-0">{item.title?.length > 10 ? `${item.title?.slice(0, 10)}...` : item.title}</h4>
                                              <h4 className="font-18 black mt-0">${item?.product_price}</h4>
                                            </div>
                                            <div className='mt-2 mb-2 paraparahideMob'>
                                              <p>{marketplacestoreDataById?.name}</p>
                                            </div>
                                            <div className="flex flex-wrap justify-between items-center mt-2">
                                              <p className="rating flex items-center">
                                                <span className='flex items-center gap-1'>0.0 <i className="fa fa-star" aria-hidden="true"></i></span>&nbsp;0 Rating
                                              </p>
                                              {(item?.product_price > 0 && (user.role_id === constant.rolesId.importer || user.role_id === constant.rolesId['importer&distributer'] || user.role_id === constant.rolesId.distributer)) && <> {checkProductAlreadyInCart(item?.marketplace_product_id) ? <button type='button' className='btn btnTeal addtocart font-bold' onClick={() => history.push('/marketplace/my-cart')}>Go To Cart</button> : <button type='submit' className='btn btnTeal addtocart font-bold' onClick={() => addToCart(item)}>Add To Cart 1</button>}</>}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </TabPanel>
                            )
                          })}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                  <div className="page_w_right">
                    <div className="store__detail xl:pt-4 lg:pt-4 md:pt-4 sm:pt-4">
                      <h4 className="font-18 black m-0">{marketplacestoreDataById.name}</h4>
                      <p>{marketplacestoreDataById.location}</p>
                      <div className="flex reviewMob">
                        <div className="reviw-border">
                          <div className="review mt-2">
                            <h4 className="text-left prdcr-store-title">{marketplacestoreDataById.avg_rating}</h4>
                            {isRatings && <p className="mt-2">
                              <ReactStars
                                edit={false}
                                isHalf={true}
                                value={marketplacestoreDataById && marketplacestoreDataById.avg_rating}
                                activeColor="#eb8a2f"
                                color="rgb(74 72 72)"
                              />
                            </p>}
                          </div>
                          <div className='text-center'>{marketplacestoreDataById.total_reviews} {(marketplacestoreDataById.total_reviews <= 1) ? lang?.review : lang?.reviews}</div>
                        </div>
                        <div className="reviw-border">
                          <div className="review mt-2">
                            <h4 className="text-left prdcr-store-title">{marketplacestoreDataById?.store_products?.length}</h4>
                          </div>
                          <div>{lang?.Products}</div>
                        </div>
                        <div className="reviw-border">
                          <div className="review mt-2">
                            <h4 className="text-left prdcr-store-title">{marketplacestoreDataById.total_category}</h4>
                          </div>
                          <div>{lang?.Categories}</div>
                        </div>
                      </div>
                      <hr />
                      <div className="producer-name mt-2 mb-2">
                        <div className="feed-user-info mb-5 noCursor">
                          <div className="user__pic mr-3">
                            {marketplacestoreDataById && marketplacestoreDataById.prefilled ? <img
                              src={`${marketplacestoreDataById?.prefilled?.avatar_id?.base_url}${marketplacestoreDataById?.prefilled?.avatar_id?.attachment_medium_url}`}
                              alt={marketplacestoreDataById}
                            /> :
                              <img src={user} alt="default-image" />}
                          </div>
                          <div className="user__detail-data">
                            <span className="user__name"><a href="javascript:void(0)">{marketplacestoreDataById.name}</a></span>
                            <MemberType item={marketplacestoreDataById} />
                          </div>
                        </div>
                        <div className="blue-btn-full w-full">
                          {data && data.user_data.user_id !== marketplacestoreDataById && marketplacestoreDataById.user_id ? <Link to={`/user-profile/${marketplacestoreDataById && marketplacestoreDataById.user_id}`} className="btn btnPrimary">View Profile</Link> : <Link to="/profile" className="btn btnPrimary">View Profile</Link>}
                        </div>
                      </div>
                      <hr />
                      <div className="location-map">
                        <h4 className="font-20 m-0">{lang?.Location}</h4>
                        <div className="map mt-4">
                          <Map
                            google={props.google}
                            center={{ lat: parseFloat(marketplacestoreDataById.lattitude), lng: parseFloat(marketplacestoreDataById.longitude), address: marketplacestoreDataById.location }}
                            height="300px"
                            zoom={15}
                            name="address"
                          />
                        </div>
                        <div className="contact-icon">
                          <ul>
                            <li>
                              <div className="icon__circle phone">
                                <a href={`tel:${marketplacestoreDataById.phone}`}>
                                  <i className="fa fa-phone" aria-hidden="true" />
                                </a>
                              </div>
                              <h6>{lang?.call}</h6>
                            </li>
                            <li>
                              <div className="icon__circle">
                                {marketplacestoreDataById && marketplacestoreDataById.website && marketplacestoreDataById.website.includes("https://") ? <a href={`${marketplacestoreDataById.website}`} target="_blank"> <i className="fa fa-globe" aria-hidden="true" /> </a> : <a href={`https://${marketplacestoreDataById.website}`} target="_blank"> <i className="fa fa-globe" aria-hidden="true" /> </a>}
                              </div>
                              <h6>{lang?.Website}</h6>
                            </li>
                            <li>
                              <div className="icon__circle">
                                <a href={`https://maps.google.com/?q=${marketplacestoreDataById.location}`} target="_blank">
                                  <i className="fa fa-map-marker" aria-hidden="true" />
                                </a>
                              </div>
                              <h6>{lang?.Direction}</h6>
                            </li>
                            <li>
                              <div className="icon__circle">
                                {(marketplacestoreDataById && marketplacestoreDataById.is_favourite === 1) ? <span onClick={() => handleFavouritesItems(marketplacestoreDataById.marketplace_store_id, 1)} style={{ cursor: 'pointer' }}>
                                  <i className="fa fa-heart"
                                    style={{ color: '#f00' }} aria-hidden="true" />
                                </span> :
                                  <span onClick={() => handleFavouritesItems(marketplacestoreDataById.marketplace_store_id, 2)} style={{ cursor: 'pointer' }}>
                                    <i className="fa fa-heart-o" aria-hidden="true" />
                                  </span>}
                              </div>
                              <h6>{lang?.Favourite}</h6>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr />
                      <div className="rating__review">
                        <div className="flex justify-between">
                          <h4 className="font-20 mt--0">{lang?.Ratings_and_reviews}</h4>
                          <Link to="#" className="font-20" onClick={() => getAllReviews(marketplacestoreDataById.marketplace_store_id)}>See All</Link>
                        </div>
                        <div className="review mt-2 items-center">
                          <div className='ratingBlock'>
                            <span className="font-20">{marketplacestoreDataById && marketplacestoreDataById.avg_rating}</span>
                            {isRatings &&
                              <ReactStars
                                edit={false}
                                isHalf={true}
                                value={marketplacestoreDataById && parseFloat(marketplacestoreDataById.avg_rating)}
                                activeColor="#eb8a2f"
                                color="rgb(74 72 72)"
                                count="5"
                              />
                            }
                          </div>
                          <p className='text-right'>{marketplacestoreDataById.total_reviews} {(marketplacestoreDataById.total_reviews <= 1) ? lang?.review : lang?.reviews}</p>
                        </div>
                        <div className="review rightArea mt-2 items-center">
                          <p className="flex items-center mt-2">
                            <span className='rateCount'>
                              5 &nbsp;<i className="fa fa-star" aria-hidden="true" /> &nbsp;
                            </span>
                            <span className='progressBar'>
                              <div className="prgressBarBg" style={{ width: calculateRatingPercentage(marketplacestoreDataById.total_five_star, marketplacestoreDataById.total_reviews) + "%" }}></div>
                            </span>
                            <span className='progressCount'>{calculateRatingPercentage(marketplacestoreDataById.total_five_star, marketplacestoreDataById.total_reviews) + "%"}</span>
                          </p>
                          <p className="flex items-center mt-2">
                            <span className='rateCount'>
                              4 &nbsp;<i className="fa fa-star" aria-hidden="true" />
                            </span>
                            <span className='progressBar'>
                              <div className="prgressBarBg" style={{ width: calculateRatingPercentage(marketplacestoreDataById.total_four_star, marketplacestoreDataById.total_reviews) + "%" }}></div>
                            </span>
                            <span className='progressCount'>{calculateRatingPercentage(marketplacestoreDataById.total_four_star, marketplacestoreDataById.total_reviews) + "%"}</span>
                          </p>
                          <p className="flex items-center mt-2">
                            <span className='rateCount'>
                              3 &nbsp;<i className="fa fa-star" aria-hidden="true" />
                            </span>
                            <span className='progressBar'>
                              <div className="prgressBarBg" style={{ width: calculateRatingPercentage(marketplacestoreDataById.total_three_star, marketplacestoreDataById.total_reviews) + "%" }}></div>
                            </span>
                            <span className='progressCount'>{calculateRatingPercentage(marketplacestoreDataById.total_three_star, marketplacestoreDataById.total_reviews) + "%"}</span>
                          </p>
                          <p className="flex items-center mt-2">
                            <span className='rateCount'>
                              2 &nbsp;<i className="fa fa-star" aria-hidden="true" />
                            </span>
                            <span className='progressBar'>
                              <div className="prgressBarBg" style={{ width: calculateRatingPercentage(marketplacestoreDataById.total_two_star, marketplacestoreDataById.total_reviews) + "%" }}></div>
                            </span>
                            <span className='progressCount'>{calculateRatingPercentage(marketplacestoreDataById.total_two_star, marketplacestoreDataById.total_reviews) + "%"}</span>
                          </p>
                          <p className="flex items-center mt-2">
                            <span className='rateCount'>
                              1 &nbsp;<i className="fa fa-star" aria-hidden="true" />
                            </span>
                            <span className='progressBar'>
                              <div className="prgressBarBg" style={{ width: calculateRatingPercentage(marketplacestoreDataById.total_one_star, marketplacestoreDataById.total_reviews) + "%" }}></div>
                            </span>
                            <span className='progressCount'>{calculateRatingPercentage(marketplacestoreDataById.total_one_star, marketplacestoreDataById.total_reviews) + "%"}</span>
                          </p>
                        </div>
                        <div className="review mt-2 items-center">
                          <p>
                            <span className="flex font-20 justify-start">{marketplacestoreDataById?.latest_review?.user.company_name ?? marketplacestoreDataById?.latest_review?.user?.name}</span>
                            {(isRatings && marketplacestoreDataById?.latest_review) &&
                              <ReactStars
                                edit={false}
                                isHalf={true}
                                value={marketplacestoreDataById && marketplacestoreDataById.latest_review && parseFloat(marketplacestoreDataById.latest_review.rating)}
                                activeColor="#eb8a2f"
                                color="rgb(74 72 72)"
                                count="5"
                              />
                            }
                          </p>
                          <p>{marketplacestoreDataById && marketplacestoreDataById.latest_review && moment(marketplacestoreDataById.latest_review.created_at).format("MMM D, YYYY")}</p>
                        </div>
                        <p>
                          {marketplacestoreDataById?.latest_review?.review}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* user photos view */}
      {
        isOpenImage &&
        <div className='image--light--pop'>
          <ReactImageVideoLightbox
            data={userPhotosData}
            // data={[{ url: "https://alysei.s3.us-west-1.amazonaws.com/uploads/2022/06/11108081655154850.jpeg", type: "photo", altTag: "image", }]}
            startIndex={imageIndex}
            showResourceCount={true}
            onCloseCallback={() => {
              setIsOpenImage(false);
              setUserPhotosData([]);
              setImageIndex(0);
            }}
          // onNavigationCallback={(currentIndex) =>
          //     console.log(`Current index: ${currentIndex}`)
          // }
          />
        </div>
      }
    </div >
  );
}
