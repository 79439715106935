import React from 'react'
import { Helmet } from 'react-helmet'
const SeoAbout = () => {
  return (
   <Helmet>
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />

{/* <!-- Primary Meta Tags --> */}
<title>About Alysei - Italian Food & Beverage Solutions</title>
<meta name="description" content="Alysei, the premier platform connecting Italian food and beverage brands with global markets. Explore our services and expertise in supporting B2B & B2C businesses in the USA and beyond." />
<meta name="keywords" content="Alysei About, Alysei About Us, Alysei" />
<link rel="canonical" href="https://alysei.com/about" />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:title" content="About Alysei - Italian Food & Beverage Solutions" />
<meta property="og:description" content="Alysei, the premier platform connecting Italian food and beverage brands with global markets. Explore our services and expertise in supporting B2B & B2C businesses in the USA and beyond." />
<meta property="og:url" content="https://alysei.com/about" />
<meta property="og:site_name" content="Alysei" />
<meta property="og:image" content="https://alysei.com/dist/images/office-locations.png" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary" />
<meta property="twitter:url" content="https://alysei.com/about" />
<meta property="twitter:title" content="About Alysei - Italian Food & Beverage Solutions" />
<meta property="twitter:description" content="Alysei, the premier platform connecting Italian food and beverage brands with global markets. Explore our services and expertise in supporting B2B & B2C businesses in the USA and beyond." />
<meta property="twitter:image" content="https://alysei.com/dist/images/office-locations.png" />
<script type="application/ld+json">
 {`{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "About Alysei | Italian Food & Beverage Solutions",
  "url": "https://alysei.com/about",
  "description": "Alysei, the premier platform connecting Italian food and beverage brands with global markets. Explore our services and expertise in supporting B2B & B2C businesses in the USA and beyond.",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://alysei.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "About Us",
        "item": "https://alysei.com/about"
      }
    ]
  },
  "about": {
    "@type": "Organization",
    "name": "Alysei",
    "url": "https://alysei.com",
    "logo": "https://alysei.com/dist/images/logo.svg",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1 (312) 286-4961",
      "contactType": "Customer Service",
      "areaServed": "US",
      "availableLanguage": "English"
    },
    "sameAs": [
      "https://www.facebook.com/AlyseiB2B",
      "https://www.instagram.com/alyseiplatform/",
      "https://www.linkedin.com/company/alysei",
      "https://www.youtube.com/@alysei/",
      "https://apps.apple.com/in/app/alysei/id1634783265",
      "https://play.google.com/store/apps/details?id=com.alysei"
    ],
    "description": "Alysei provides specialized solutions for businesses in multiple industries, helping them grow, expand, and connect with the right clients and markets."
  },
  "publisher": {
    "@type": "Organization",
    "name": "Alysei",
    "url": "https://alysei.com",
    "logo": "https://alysei.com/dist/images/logo.svg",
    "sameAs": [
      "https://www.facebook.com/AlyseiB2B",
      "https://www.instagram.com/alyseiplatform/",
      "https://www.linkedin.com/company/alysei",
      "https://www.youtube.com/@alysei/",
      "https://apps.apple.com/in/app/alysei/id1634783265",
      "https://play.google.com/store/apps/details?id=com.alysei"
    ]
  }
}`}
</script>

   </Helmet>
  )
}

export default SeoAbout