import React from 'react'
import Banner from './component/Banner'
import FloatingBtns from '../LandingPage/component/FloatingBtns'
import Team from './component/Team'
import OurPresence from './component/OurPresence'
import NewsLetter from './component/NewsLetter'
import Testimonial from '../LandingPage/component/Testimonial'
import SeoAbout from '../../components/Seo-helmet/SeoAbout'
const About = () => {
  return (
    <>
      {/* @@ SEO */}
      <SeoAbout />

      <Banner />
      <Team />
      <OurPresence />
      <Testimonial />
      <NewsLetter />
      <FloatingBtns />
    </>

  )
}

export default About