import React, { useState, useEffect } from 'react'
import BuyerHeader from '../DashboardView/BuyerHeader'
import SideBar from '../SideBar'
import { Link, useParams } from "react-router-dom";
import { getSingleRequest } from '../../modules/Buyermodule';
import { uploadBuyerChatImageUpdatedLastActivity } from "../../../marketplace/modules/marketplace";
import { useSelector, useDispatch } from 'react-redux';
import FormSpinner from '../../../../components/Spinners/FormSpinner';
import moment from "moment";
import firebase from "../../../../config//firebase";
import language from '../../../../helpers/language';
import nodataimage from '../../../../assets/images/no-data.svg'
import uploadimg from '../../../../assets/images/img-1.png'
import ReactImageVideoLightbox from "react-image-video-lightbox";
import CustomUseEffect from '../../../../components/constants';
export default function RecentRequestView() {
  const dispatch = useDispatch();
  const params = useParams();
  const [lang] = useState(language.getLang());
  const [requestId, setRequestId] = useState(null);
  const [loadCurrentChatData, setLoadCurrentChatData] = useState(null);
  const [fetchChatList, setFetchChatList] = useState([]);
  const [message, setMessage] = useState("");
  const [image, setImage] = useState([]);
  const [imageError, setImageError] = useState("");
  const [disabledTrue, setDisabledTrue] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [chatMembers, setChatMembers] = useState([]);
  const [recentChatMessages, setRecentChatMessages] = useState([]);
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [userPhotosData, setUserPhotosData] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  let uuid = params.uuid;
  let { singleRequestData, chtUserProfiles, singleDataLoader } = useSelector((state) => state.buyerModuleFilterData);

  // Get Single Request Api
  CustomUseEffect(async () => {
    singleRequestData && await dispatch(getSingleRequest(uuid));
  }, [uuid]);

  CustomUseEffect(async () => {
    // singleDataLoader === false && await setLoadCurrentChatData(singleRequestData?.chat_members[0]?.user); await setRequestId(singleRequestData?.id);
    singleDataLoader === false && await setRequestId(singleRequestData?.id);
  }, [singleRequestData, singleDataLoader]);

  CustomUseEffect(async () => {
    await setChatMembers(chtUserProfiles);
  }, [chtUserProfiles])

  CustomUseEffect(async () => {
    const fetchUserNode = firebase
      .database()
      .ref("request")
      .child(requestId)
    fetchUserNode.on("value", async (snapshot) => {
      const response = snapshot.val();
      //console.log(response,"lastdata");
      if (response) {
        const result = [];
        for (let id in response) {
          const lastMessageData = {
            lastmessage: response[id]["lastmessage"],
            lasttimestamp: response[id]["lasttimestamp"],
            senderId: response[id]['senderId'],
            totalmessage: response[id]['totalmessage'],
          };
          //console.log(response[id]["lastmessage"], "chat node");
          result.push({ id, ...lastMessageData });
        }
        await setRecentChatMessages(result);
        //console.log(result, "resultmessage");
      }
      //return response;
    });
  }, [requestId])

  CustomUseEffect(async () => {
    if (loadCurrentChatData) {
      const fetchUserNode = firebase.database().ref("request").child(requestId).child(loadCurrentChatData?.user_id).child('message');
      fetchUserNode.on("value", async (snapshot) => {
        const response = snapshot.val();
        const result = [];
        for (let id in response) {
          result.push({ id, ...response[id] });
        }

        await setFetchChatList(result);
      });
      await unreadChatCount();
    }
  }, [loadCurrentChatData, requestId]);

  //message save in state
  const handleChange = async (e) => {
    const { value } = e.target;
    await setMessage(value);
    await unreadChatCount();
  }

  const handleFile = async (event) => {
    let imageArr = [];
    //await unreadChatCount();
    if (event.target.files.length > 5) {
      await setImageError("Images should be less than 6");
      document.getElementById("media").value = "";
    } else {
      Object.keys(event.target.files).map(async (item, i) => {
        if (event.target.files[item].size > 1000000) {
          await setImageError(lang?.disputeImageSize);
          document.getElementById("media").value = "";
        } else {
          let reader = new FileReader();
          reader.readAsDataURL(event.target.files[item]);
          reader.onload = (e) => {
            imageArr.push(event.target.files[item]);
          };
          await setImageCount(i + 1);
        }
      });
      await setImage(imageArr);

    }
    setTimeout(() => {
      setImageError("");
    }, 3000);
  };

  // unreadcount Chat Node
  const unreadChatCount = () => {
    const fetchUserNode = firebase.database().ref("request").child(requestId).child(loadCurrentChatData?.user_id);
    fetchUserNode.on("value", async (snapshot) => {
      const response = snapshot.val();
      if (response?.senderId === singleRequestData?.buyer_id) {
        await setUnreadMessageCount(response?.totalmessage);
      }
      else {
        await setUnreadMessageCount(0);
      }
      //return response;
    });
  };

  // Save Chat into the firebase
  const saveChatFirebase = async () => {
    if (image != "" || message != "" || message != null) {
      await unreadChatCount();
      setDisabledTrue(true);
      setIsLoader(true);
      const sendData = {
        request_id: requestId,
        chat_receiver_id: loadCurrentChatData?.user_id,
        image: image,
      };
      const updateChat = await uploadBuyerChatImageUpdatedLastActivity(sendData);
      if (updateChat?.success) {
        var currentTimeStamp = Date.now();
        firebase
          .database()
          .ref("request")
          .child(requestId)
          .child(loadCurrentChatData?.user_id)
          .update({
            lastmessage: message,
            lasttimestamp: currentTimeStamp,
            read_status: false,
            senderId: singleRequestData?.buyer_id,
            messageType: 0,
            totalmessage: parseInt(unreadMessageCount) + 1,
          });
        firebase.database().ref("request").child(requestId).child(loadCurrentChatData?.user_id).child('message').child(currentTimeStamp).set({
          id: loadCurrentChatData?.user_id,
          timestamp: currentTimeStamp,
          message: message,
          senderId: singleRequestData?.buyer_id,
          attachments: updateChat?.attachments,
          messageType: 0,
        });

        await setMessage("");
        await setImage("");
        await setImageCount(0);
        document.getElementById("media").value = "";

        setTimeout(() => {
          setDisabledTrue(false);
          setIsLoader(false);
        }, 1000);
      }
    }
  };

  const updateReadMessage = async () => {
    if (loadCurrentChatData) {
      const fetchNode = firebase
        .database()
        .ref("request")
        .child(requestId)
        .child(loadCurrentChatData?.user_id);
      fetchNode.once("value", (snapshot) => {
        const response = snapshot.val();
        if (response?.senderId == loadCurrentChatData?.user_id) {
          firebase
            .database()
            .ref("request")
            .child(requestId)
            .child(loadCurrentChatData?.user_id)
            .update({
              totalmessage: 0,
            });
        }
      });
    }
  }
  const isImgUrl = (url) => {
    return /\.(jpg|jpeg|png)$/.test(url);
  }

  const imageViewerHandler = (imgData) => {
    const transformedData = imgData
      .filter(url => {
        const extension = url.split('.').pop().toLowerCase();
        return extension !== 'pdf';
      })
      .map((url) => {
        const extension = url.split('.').pop().toLowerCase(); // Extract file extension
        const type = 'photo'; // Since PDFs are filtered out, all remaining files are considered photos
        const altTag = 'image'; // Set alt tag for photos

        return {
          url: url,
          type: type,
          altTag: altTag
        };
      });
    setUserPhotosData(transformedData);
    setIsOpenImage(true);
  };

  return (
    <>
      <section className="buyerDashboard">
        <div className="dashboardLayout">
          <div className="headerBlock">
            <BuyerHeader pageTitle="Recent Requests" />
          </div>
          <div className="sideBarBlock">
            <SideBar />
          </div>
          <div className="centerContent">
            {singleDataLoader ? (
              <FormSpinner />
            ) : (
              <div className="pageContent">
                {/* <div className='newDashboard bgWhite'> */}
                <div className="card card--block card-gap">
                  <div className="flexBox justify-between">
                    <h4 className="fs-18 font-bold">
                      {singleRequestData?.title}
                    </h4>
                    <Link to="/recent-request" className="btn btn--gray">
                      <svg
                        width="18"
                        height="9"
                        viewBox="0 0 18 9"
                        fill="none"
                      >
                        <path
                          d="M0 0.833252V8.33325H7.5L4.48242 5.31567C5.63777 4.34289 7.12075 3.74992 8.75 3.74992C11.695 3.74992 14.1875 5.66213 15.07 8.31047L17.0426 7.65291C15.8851 4.17708 12.615 1.66659 8.75 1.66659C6.54497 1.66659 4.53823 2.48852 3.00293 3.83618L0 0.833252Z"
                          fill="black"
                        ></path>
                      </svg>{" "}
                      Back
                    </Link>
                  </div>
                  <div className="showData">
                    <div className="grid grid-cols-4 mt-0 mb-3 ">
                      <div className="dataTitle">
                        <strong>Requests ID</strong>
                        <span>{singleRequestData?.id}</span>
                      </div>
                      <div className="dataTitle">
                        <strong>Created</strong>
                        <span>
                          {/* {moment(
                              new Date(
                                singleRequestData?.created_at
                              ).toLocaleString()
                            ).format("DD MMMM YYYY, hh:mm A")} */}

                          {moment((singleRequestData?.created_at)).format("DD MMM YYYY hh:mm A")}

                        </span>
                      </div>
                      {/* <div className="dataTitle">
                          <strong>Last Activity</strong>
                          <span>
                            {moment(
                              new Date(
                                singleRequestData?.last_activity
                              ).toLocaleString()
                            ).format("DD MMMM YYYY, hh:mm A")}
                          </span>
                        </div> */}
                    </div>
                    <p>{singleRequestData?.description}</p>
                  </div>
                </div>
                {/* </div> */}
                {/* Chat Box  */}
                <div className="card card--block card-gap dashboardView dashboardInquiry">
                  {/* show this when no data found */}
                  {singleRequestData?.chat_response ? (
                    <div className="sendInquiry servics_blocks middle-long__section-block middleBlockBg">
                      <div className="product-title mt-0">
                        <h4 className="text-blue">In Conversation</h4>
                      </div>
                      <div className="mrktInquiry gap-0">
                        <div className="inquiryLeftBlock">
                          <div className="inquiryUsersList">
                            <ul className="chatListHeader">
                              {singleRequestData?.chat_members.length > 0 &&
                                singleRequestData?.chat_members.map(
                                  ({ user_id, user }) => {
                                    const getLastMessage =
                                      recentChatMessages.find(
                                        ({ id }) => id == user_id
                                      );
                                    // console.log(
                                    //   getLastMessage,
                                    //   "getLastMessage"
                                    // );
                                    return (
                                      <li
                                        onClick={() =>
                                          setLoadCurrentChatData(user)
                                        }
                                      >
                                        <div className="feed-user-info">
                                          <div className="user__pic">
                                            <img
                                              src={`${user?.profile_img?.base_url}${user?.profile_img?.attachment_medium_url}`}
                                              alt="Profile"
                                            />
                                          </div>
                                          <div className="user__detail-data">
                                            <p className="user__name font-18 text-black">
                                              <Link to="#">
                                                {user?.company_name}
                                              </Link>
                                              <span className="font-15 text-gray">
                                                {getLastMessage?.lastmessage
                                                  ? getLastMessage?.lastmessage.slice(
                                                    0,
                                                    22
                                                  ) +
                                                  (getLastMessage
                                                    ?.lastmessage.length >
                                                    22
                                                    ? "..."
                                                    : "")
                                                  : ""}
                                              </span>
                                            </p>
                                          </div>
                                          <div className="about_profile text-teal ml-4">
                                            <h4
                                              className={
                                                getLastMessage?.id ==
                                                  getLastMessage?.senderId &&
                                                  parseInt(
                                                    getLastMessage?.totalmessage
                                                  ) > 0
                                                  ? "font-14 text-teal font-bold"
                                                  : "font-14 text-black"
                                              }
                                            >
                                              {getLastMessage?.lasttimestamp
                                                ? moment(
                                                  parseInt(
                                                    getLastMessage?.lasttimestamp
                                                  )
                                                ).format("hh:mm A")
                                                : ""}
                                              <span
                                                className={
                                                  getLastMessage?.id ==
                                                    getLastMessage?.senderId &&
                                                    parseInt(
                                                      getLastMessage?.totalmessage
                                                    ) > 0
                                                    ? "noCount Teal ml-auto mt-1"
                                                    : "Teal ml-auto mt-1"
                                                }
                                              >
                                                {getLastMessage?.id ==
                                                  getLastMessage?.senderId
                                                  ? parseInt(
                                                    getLastMessage?.totalmessage
                                                  ) > 0
                                                    ? getLastMessage?.totalmessage
                                                    : ""
                                                  : ""}
                                              </span>
                                            </h4>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  }
                                )}

                            </ul>
                          </div>
                        </div>
                        <div className="inquiryRightBlock border-left-0">
                          {loadCurrentChatData != null && (
                            loadCurrentChatData?.block === false ?
                              <div className="inquiryBody">
                                <div className="inquiryBodyHeader">
                                  <div className="prdctHead">
                                    <div className="prdtImg">
                                      <img
                                        src={`${loadCurrentChatData?.profile_img?.base_url}${loadCurrentChatData?.profile_img?.attachment_medium_url}`}
                                        alt="Profile Image"
                                      />
                                    </div>
                                    <div className="prdtDes">
                                      <p>{loadCurrentChatData?.company_name}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="inquiryChatBody">
                                  <div className="showData">
                                    <div className="flex flex-wrap mb-2">
                                      <div className="dataTitle">
                                        <strong>Company Name</strong>
                                        <span>
                                          {loadCurrentChatData?.company_name}
                                        </span>
                                      </div>
                                      <div className="dataTitle">
                                        <strong>Location</strong>
                                        <span>
                                          {loadCurrentChatData?.country_id}
                                        </span>
                                      </div>
                                      {/* <div className="dataTitle">
                                            <strong>Contact</strong>
                                            {loadCurrentChatData?.phone !=null && loadCurrentChatData?.phone!='' ? 
                                              <span>
                                                {`+${loadCurrentChatData?.country_code} ${loadCurrentChatData?.phone}`}
                                              </span>
                                            :
                                            '--'
                                            }
                                          </div> */}
                                      <div className="dataTitle">
                                        <strong>Address</strong>
                                        <span>
                                          {loadCurrentChatData?.address}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {fetchChatList &&
                                    fetchChatList.length > 0 &&
                                    fetchChatList.map(
                                      ({
                                        senderId,
                                        timestamp,
                                        message,
                                        attachments,
                                        messageType,
                                      }) => {
                                        const senderData = chatMembers.find(
                                          ({ user_id }) => user_id === senderId
                                        );
                                        updateReadMessage();
                                        //console.log(senderData,"senderData");
                                        return (
                                          <div
                                            className={
                                              singleRequestData?.buyer_id ===
                                                senderId
                                                ? "msgReciver right_message "
                                                : "msgReciver left_message"
                                            }
                                          >
                                            {/* <div class="prdctHead">
                                                  <div class="prdtImg">
                                                    <img
                                                      src={`${senderData?.attachment?.base_url}${senderData?.attachment?.attachment_medium_url}`}
                                                      alt="Profile"
                                                    />
                                                  </div>
                                                  <div class="prdtDes">
                                                    <p>{senderData?.name}</p>
                                                  </div>
                                                  <div className="chatTime">
                                                    <p className="chatDate">
                                                      {moment(
                                                        parseInt(timestamp)
                                                      ).format("DD MMM YYYY hh:mm A")}
                                                    </p>
                                                  </div>
                                                </div> */}
                                            {message.length > 0 ? <p className="importertitle">
                                              {message}
                                            </p> : <p >
                                              {message}
                                            </p>}


                                            <div className="attachedFile">
                                              <div className="attachedFileGroupflex">
                                                {attachments &&
                                                  attachments.length > 0 &&
                                                  attachments.map(
                                                    (attachment, index) => {
                                                      const img =
                                                        isImgUrl(
                                                          attachment
                                                        );
                                                      return (
                                                        <div className="uploadImg">
                                                          {
                                                            img ? (
                                                              <>
                                                                {
                                                                  index == 0 &&
                                                                  <div style={{ cursor: "pointer" }} onClick={() => imageViewerHandler(attachments)} className="uploadImg">
                                                                    <img src={attachment} alt='uploaded-img' />
                                                                  </div>
                                                                }

                                                                {
                                                                  index == 1 && attachments.length == 2 &&
                                                                  <div style={{ cursor: "pointer" }} onClick={() => imageViewerHandler(attachments)} className="uploadImg">
                                                                    <img src={attachment} alt='uploaded-img' />
                                                                  </div>

                                                                }

                                                                {index == 2 && attachments.length > 2 &&
                                                                  <div onClick={() => imageViewerHandler(attachments)} className="uploadImg extra">
                                                                    <img src={attachments[1]} alt='uploaded-img' />
                                                                    <div class="show_all_icon"><i class="fa fa-plus" aria-hidden="true"></i><span class="img_count_no">{attachments.length - 2}</span></div>
                                                                  </div>
                                                                }

                                                              </>
                                                            ) : (
                                                              <>
                                                                <a
                                                                  href={attachment}
                                                                  alt="PDF"
                                                                  target="_blank"
                                                                >
                                                                  <svg width="155" height="131" viewBox="0 0 155 131" fill="none">
                                                                    <path d="M0 0H111L133.5 25.5L155 50V131H0V0Z" fill="#EF4036" />
                                                                    <path d="M54.5985 75V56.6078H61.8548C63.2498 56.6078 64.4382 56.8742 65.4201 57.4071C66.402 57.9339 67.1504 58.6674 67.6653 59.6073C68.1861 60.5413 68.4466 61.619 68.4466 62.8403C68.4466 64.0617 68.1831 65.1393 67.6563 66.0733C67.1294 67.0073 66.3661 67.7347 65.3662 68.2556C64.3724 68.7765 63.169 69.0369 61.756 69.0369H57.1311V65.9207H61.1274C61.8758 65.9207 62.4925 65.7919 62.9774 65.5345C63.4683 65.2711 63.8335 64.9088 64.073 64.4478C64.3185 63.9809 64.4412 63.445 64.4412 62.8403C64.4412 62.2296 64.3185 61.6968 64.073 61.2418C63.8335 60.7808 63.4683 60.4246 62.9774 60.1731C62.4865 59.9157 61.8638 59.7869 61.1094 59.7869H58.4871V75H54.5985ZM77.4922 75H70.9723V56.6078H77.5461C79.3961 56.6078 80.9887 56.976 82.3238 57.7124C83.6589 58.4428 84.6857 59.4936 85.4041 60.8646C86.1285 62.2356 86.4908 63.8761 86.4908 65.7859C86.4908 67.7018 86.1285 69.3482 85.4041 70.7253C84.6857 72.1023 83.6529 73.159 82.3058 73.8954C80.9647 74.6318 79.3602 75 77.4922 75ZM74.8609 71.6682H77.3306C78.4801 71.6682 79.447 71.4647 80.2313 71.0575C81.0216 70.6444 81.6143 70.0068 82.0095 69.1447C82.4106 68.2766 82.6111 67.157 82.6111 65.7859C82.6111 64.4269 82.4106 63.3163 82.0095 62.4542C81.6143 61.592 81.0246 60.9574 80.2403 60.5503C79.456 60.1432 78.4891 59.9396 77.3396 59.9396H74.8609V71.6682ZM89.3713 75V56.6078H101.549V59.8139H93.2598V64.1964H100.741V67.4024H93.2598V75H89.3713Z" fill="white" />
                                                                    <path d="M111 45V0.000198364L133 25L155 50H116C113.239 50 111 47.7614 111 45Z" fill="#D6D6D7" />
                                                                  </svg>

                                                                </a>

                                                              </>
                                                            )
                                                          }

                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                {/* <div className="uploadImg">
                                                          <img src={uploadimg} alt='uploaded-img'/>
                                                      </div>
                                                      <div className="uploadImg extra">
                                                          <img src={uploadimg} alt='uploaded-img'/>
                                                          <div class="show_all_icon"><i class="fa fa-plus" aria-hidden="true"></i><span class="img_count_no">2</span></div>
                                                      </div> */}
                                              </div>
                                            </div>
                                            <div className="chatTime">
                                              <p className="chatDate">
                                                {moment(
                                                  parseInt(timestamp)
                                                ).format(
                                                  "DD MMM YYYY hh:mm A"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                                <div className="chatFooter">
                                  <div className="cmnt-form replay_cmnt replayBlock flex justify-between items-center">
                                    <input
                                      type="text"
                                      className="cmnt-write relative"
                                      placeholder="Enter Message Here..."
                                      value={message}
                                      onChange={(event) => handleChange(event)}
                                    ></input>
                                    <div className="post-icons-option">
                                      <div className="fileAttach">
                                        <label className="hide-input">
                                          <span className="cursor-pointer">
                                            <i
                                              className="fa fa-paperclip"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <input
                                            type="file"
                                            name="file"
                                            id="media"
                                            accept="image/*, application/pdf"
                                            onChange={(event) =>
                                              handleFile(event)
                                            }
                                            multiple
                                          />
                                        </label>
                                        <span className="slectedFiles">
                                          {imageCount > 0
                                            ? imageCount == 1
                                              ? ` ${imageCount} File Selected`
                                              : ` ${imageCount} Files Selected`
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="rightButtons">
                                      <button
                                        type="button"
                                        className="btn btn-blue ml-4"
                                        disabled={disabledTrue}
                                        onClick={() => {
                                          saveChatFirebase();
                                        }}
                                      >
                                        {isLoader ? <FormSpinner /> : "Send"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              <div className='noChatData'>
                                <div className='noDataImage'>
                                  <img src={nodataimage} alt="Dummy Image" />
                                </div>
                                <h2 className='fs-20 font-bold'>You are blocked by producer</h2>
                              </div>
                          )}
                          {fetchChatList &&
                            fetchChatList.length == 0 && (
                              <>
                                <div className='noChatData'>
                                  <div className='noDataImage'>
                                    <img src={nodataimage} alt="Dummy Image" />
                                  </div>
                                  <h2 className='fs-20 font-bold'>There are no chat started yet</h2>
                                  <p>Click on anyone of the producer and start your chat.</p>
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    singleRequestData?.for_admin ? (
                      <div className="dummyData">
                        <div className="width--submitation">
                          {/* <img src={nodataimage} alt="Dummy Image" /> */}
                          <div class="icon"><i class="fa fa-smile-o" aria-hidden="true"></i></div>
                          <h4 className="fs-20 font-bold">
                            Thank you for sending request to Alysei Team.
                          </h4>
                          <p>
                            We will get back to you soon.
                          </p>
                        </div>
                      </div>
                    )
                      :
                      (
                        <div className='noChatData'>
                          <div className='noDataImage'>
                            <img src={nodataimage} alt="Dummy Image" />
                          </div>
                          <h2 className='fs-20 font-bold'>There are no response yet</h2>
                          <p>When there is any response in request it will appear here</p>
                        </div>
                      )


                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {isOpenImage && (
          <div className="image--light--pop"  >
            <ReactImageVideoLightbox
              data={userPhotosData}
              startIndex={imageIndex}
              showResourceCount={true}
              onCloseCallback={() => {
                setIsOpenImage(false);
                setUserPhotosData([]);
                setImageIndex(0);
              }}
            // onNavigationCallback={(currentIndex) =>
            //     console.log(`Current index: ${currentIndex}`)
            // }
            />
          </div>
        )}
      </section>
    </>
  );
}


