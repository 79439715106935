import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Importing images
import googlePlay from '../../../assets/landing-page/googleplay.png';
import appStore from '../../../assets/landing-page/appstore.png';
import appMockup from '../../../assets/landing-page/app-mockup.png';
import AnimateFromBottom from './AnimateFromBottom';
import language from "../../../helpers/language";
const Download = () => {
  const [lang] = useState(language.getLang());
  return (
    <div className='md:px-20 px-4 md:py-10 py-5'>
    <AnimateFromBottom delay={0.2}>
      <div className="bg-green-accent rounded-3xl flex md:flex-row flex-col">
        <div className='md:w-6/12 md:p-10 p-5'>
          <p className='font-bold text-2xl mb-4 text-white'>{lang.Download_Title}</p>
          <p className='font-normal text-sm mb-4 text-white'>
            {lang.Download_Description}
          </p>
          <div className='flex gap-3'>
            <a href="https://play.google.com/store/apps/details?id=com.alysei">
              <img src={googlePlay} alt='Download on Google Play' className='h-10' />
            </a>
            <a href="https://apps.apple.com/in/app/alysei/id1634783265">
              <img src={appStore} alt='Download on the App Store' className='h-10' />
            </a>
          </div>
        </div>
        <div className='relative md:pt-6 pt-4 md:px-16 px-4'>
          <img src={appMockup} alt='App mockup' className='app-mockup-img' />
        </div>
      </div>
    </AnimateFromBottom>
  </div>
  );
};

export default Download;
