import React, { useState } from 'react';
import signUpIcon from '../../../assets/landing-page/signup-icon.svg';
import storeIcon from '../../../assets/landing-page/store-icon.svg';
import listIcon from '../../../assets/landing-page/list-icon.svg';
import enquiryIcon from '../../../assets/landing-page/mail-reply-icon.svg';
import boxIcon from '../../../assets/landing-page/box-icon.svg';
import supportIcon from '../../../assets/landing-page/message-user-icon.svg';
import marketPlaceImg from '../../../assets/landing-page/market-place.png';
import AnimateFromBottom from './AnimateFromBottom';
import language from "../../../helpers/language";

// Section data with icons only, text comes from language.js
const sectionsData = [
  { id: 'signUp', icon: signUpIcon },
  { id: 'createStore', icon: storeIcon },
  { id: 'productList', icon: listIcon },
  { id: 'enquiry', icon: enquiryIcon },
  { id: 'sell', icon: boxIcon },
  { id: 'support', icon: supportIcon },
];

// ToggleSection component
const ToggleSection = ({ icon, title, content, isOpen, onToggle }) => (
  <div className='bg-dim px-3 py-2 mt-3 cursor-pointer'>
    <div className='flex justify-between' onClick={onToggle}>
      <div className='flex gap-2 items-center'>
        <img src={icon} className='w-6' alt={`${title} Icon`} />
        <p className='font-bold text-base'>{title}</p>
      </div>
      <i className={`${isOpen ? "arrowUp" : "arrowDown"} fa fa-arrow-right`}></i>
    </div>
    {isOpen && <p className='text-dim mt-3 font-medium'>{content}</p>}
  </div>
);

const MarketPlace = () => {
  const lang = language.getLang();
  const [openSection, setOpenSection] = useState("signUp");

  const handleToggle = (section) => {
    setOpenSection((prevSection) => (prevSection === section ? null : section));
  };

  return (
    <div className='grid md:grid-cols-2 items-center'>
      <div className='md:px-14 px-4 md:py-8 py-5'>
        <AnimateFromBottom delay={0.2}>
          <p className='text-lg text-royal pb-2 uppercase font-medium'>{lang.MarketPlace_title}</p>
        </AnimateFromBottom>
        <AnimateFromBottom delay={0.3}> 
          <p className='font-bold text-2xl pb-4'>{lang.MarketPlace_subtitle}</p>
        </AnimateFromBottom>
        <AnimateFromBottom delay={0.4}>
          <p className='text-dim font-medium text-sm pb-3'>
            {lang.MarketPlace_description}
          </p>
        </AnimateFromBottom>

        {sectionsData.map(({ id, icon }, idx) => (
          <AnimateFromBottom delay={0.5} key={id}>
            <ToggleSection
              icon={icon}
              title={lang.MarketPlace_sections[id].title}
              content={lang.MarketPlace_sections[id].content}
              isOpen={openSection === id}
              onToggle={() => handleToggle(id)}
            />
          </AnimateFromBottom>
        ))}
      </div>

      <AnimateFromBottom delay={0.2}>
        <div className='md:py-8 py-5 pl-4 md:pl-0'>
          <img src={marketPlaceImg} className='market-place-img ml-auto' alt='Market Place' />
        </div>
      </AnimateFromBottom>
    </div>
  );
};

export default MarketPlace;
