import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import auth from '../../../helpers/auth';
import language from '../../../helpers/language';
import { fetchFAQ } from '../module/UserSetting';
import SettingLeftNavigation from './SettingLeftNavigation';
import CustomUseEffect from '../../../components/constants';

export default function FAQ() {
    const [userType, setuserType] = useState();
    const [lang] = useState(language.getLang());
    const [isAnswerShown, setIsAnswerShown] = useState({});
    const dispatch = useDispatch();
    const { userFAQData, blockUserSpinner, } = useSelector((state) => state.userSettingState);
    CustomUseEffect(async () => {
        //window.fcWidget.show() 
        const userType = await auth.getUserType();
        const userRoleId = JSON.parse(userType);
        await setuserType(JSON.parse(userType));
        userFAQData?.length === 0 && await dispatch(fetchFAQ({ userRoleId: userRoleId.role_id, pageNo: 1 }));
    }, []);

    const handlePageClick = async (e) => {
        let pageNo = e.selected + 1;
        await dispatch(fetchFAQ({ userRoleId: userType?.role_id, pageNo: pageNo }));
    };

    const toggleFAQ = id => {
        setIsAnswerShown(isAnswerShown => ({
            ...isAnswerShown,
            [id]: !isAnswerShown[id]
        }));
    };

    return (
        <div>
            <div className="home__page b2b settings">
                <div className="servics_blocks">
                    <div className="site-align top-align">
                        <SettingLeftNavigation />
                        <div className="servics_blocks middle-long__section-block middleBlockBg">
                            <div className="medium_title"><h1 className="font-18 text-black">{lang?.FAQ}</h1></div>
                            <div className='mt-6'>
                                {blockUserSpinner ? <FormSpinner /> :
                                    userFAQData?.data?.data?.map((item, index) => {
                                        return (
                                            <div key={index} className={isAnswerShown[item.faq_id] ? "faq active" : "faq"} >
                                                <h5 className='title font-18' onClick={() => toggleFAQ(item.faq_id)}>{item.question}</h5>
                                                {isAnswerShown[item.faq_id] && <p>{item.answer}</p>}
                                            </div>
                                        )
                                    })}
                            </div>
                            {userFAQData?.data?.total > 10 &&
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={Math.ceil((userFAQData?.data?.total) / (userFAQData?.data?.per_page))}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"} />}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
