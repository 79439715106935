import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Marquee from "react-fast-marquee";
// Import images
import logo1 from '../../../assets/landing-page/testimonial/logo1.jpg';
import image1 from '../../../assets/landing-page/testimonial/image1.jpg';
import logo2 from '../../../assets/landing-page/testimonial/logo2.png';
import image2 from '../../../assets/landing-page/testimonial/image2.jpg';
import logo3 from '../../../assets/landing-page/testimonial/logo3.png';
import image3 from '../../../assets/landing-page/testimonial/image3.jpg';
import logo4 from '../../../assets/landing-page/testimonial/logo4.png';
import image4 from '../../../assets/landing-page/testimonial/image4.jpg';
import logo5 from '../../../assets/landing-page/testimonial/logo5.jpg';
import image5 from '../../../assets/landing-page/testimonial/image5.jpg';
import logo6 from '../../../assets/landing-page/testimonial/logo6.png';
import image6 from '../../../assets/landing-page/testimonial/image6.jpg';
import logo7 from '../../../assets/landing-page/testimonial/logo7.jpg';
import image7 from '../../../assets/landing-page/testimonial/image7.png';
import logo8 from '../../../assets/landing-page/testimonial/logo8.png';
import image8 from '../../../assets/landing-page/testimonial/image8.png';
import logo9 from '../../../assets/landing-page/testimonial/logo9.png';
import image9 from '../../../assets/landing-page/testimonial/image9.png';
import logo10 from '../../../assets/landing-page/testimonial/logo10.jpg';
import image10 from '../../../assets/landing-page/testimonial/image10.jpg';
import logo11 from '../../../assets/landing-page/testimonial/logo11.jpg';
import image11 from '../../../assets/landing-page/testimonial/image11.jpg';
import logo12 from '../../../assets/landing-page/testimonial/logo12.jpg';
import image12 from '../../../assets/landing-page/testimonial/image12.jpg';
import AnimateFromBottom from './AnimateFromBottom';
import language from '../../../helpers/language';
import { SampleNextArrow, SamplePrevArrow } from './Videos';

// @@ return array in chunks
const chunkArray = (array, size) => {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
    array.slice(index * size, index * size + size)
  );
};
const Testimonial = () => {
  const [lang] = useState(language.getLang());
  const [seeMoreState, setSeeMoreState] = useState({});
  const [isMobileView, setIsMobileView] = useState(false);
  const handleSeeMore = (id) => {
    setSeeMoreState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  useEffect(() => {
    const updateView = () => setIsMobileView(window.innerWidth <= 768);
    window.addEventListener('resize', updateView);
    updateView(); 
    return () => window.removeEventListener('resize', updateView);
  }, []);
  const settings = {
    arrow: true,
    dots:false,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 3,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const settings = {
  //   dots: false,
  //   slidesToShow: 3,
  //   autoplay: true,
  //   arrows: false,
  //   slidesToScroll: 3,
  //   easing: 'linear',
  //   focusOnSelect: true,
  //   speed: 1000,
  //   autoplaySpeed: 5000,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         pauseOnHover: true,
  //         // dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 1,
  //         pauseOnHover: true,
  //         infinite: true,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         pauseOnHover: true,
  //       },
  //     },
  //   ],
  // };


  const testimonials = [
    {
      id: 1,
      logo: logo1,
      text: lang.testimonials[0].text,
      image: image1,
      name: lang.testimonials[0].name,
      position: lang.testimonials[0].position,
    },
    {
      id: 2,
      logo: logo2,
      text: lang.testimonials[1].text,
      image: image2,
      name: lang.testimonials[1].name,
      position: lang.testimonials[1].position,
    },
    {
      id: 3,
      logo: logo3,
      text: lang.testimonials[2].text,
      image: image3,
      name: lang.testimonials[2].name,
      position: lang.testimonials[2].position,
    },
    {
      id: 4,
      logo: logo4,
      text: lang.testimonials[3].text,
      image: image4,
      name: lang.testimonials[3].name,
      position: lang.testimonials[3].position,
    },
    {
      id: 5,
      logo: logo5,
      text: lang.testimonials[4].text,
      image: image5,
      name: lang.testimonials[4].name,
      position: lang.testimonials[4].position,
    },
    {
      id: 6,
      logo: logo6,
      text: lang.testimonials[5].text,
      image: image6,
      name: lang.testimonials[5].name,
      position: lang.testimonials[5].position,
    },
    {
      id: 7,
      logo: logo7,
      text: lang.testimonials[6].text,
      image: image7,
      name: lang.testimonials[6].name,
      position: lang.testimonials[6].position,
    },
    {
      id: 8,
      logo: logo8,
      text: lang.testimonials[7].text,
      image: image8,
      name: lang.testimonials[7].name,
      position: lang.testimonials[7].position,
    },
    {
      id: 9,
      logo: logo9,
      text: lang.testimonials[8].text,
      image: image9,
      name: lang.testimonials[8].name,
      position: lang.testimonials[8].position,
    },
    {
      id: 10,
      logo: logo10,
      text: lang.testimonials[9].text,
      image: image10,
      name: lang.testimonials[9].name,
      position: lang.testimonials[9].position,
    },
    {
      id: 11,
      logo: logo11,
      text: lang.testimonials[10].text,
      image: image11,
      name: lang.testimonials[10].name,
      position: lang.testimonials[10].position,
    },
    {
      id: 12,
      logo: logo12,
      text: lang.testimonials[11].text,
      image: image12,
      name: lang.testimonials[11].name,
      position: lang.testimonials[11].position,
    },
  ];
  // @@ devide in chunks
  const chunkedItemsForMaping = chunkArray(testimonials,isMobileView ? 1: 3); // Split items into chunks of 3 for rows


// @@ style for testimonial
const stylesForMarquee = {
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: isMobileView ? 'wrap' : 'nowrap', // Wrap on mobile view
    gap: isMobileView ? '10px' : '0',
  },
  row: {
    display: 'flex',
    flexWrap: isMobileView ? 'wrap' : 'nowrap', // Adjust for mobile
    justifyContent: isMobileView ? 'center' : 'flex-start',
    gap: isMobileView ? '10px' : '0',
  },
  column: {
    flex: isMobileView ? '1 0 90%' : '1 0 30%', // Full width on mobile
    padding: isMobileView ? '10px' : '15px',
    margin: isMobileView ? '0 auto' : '5px', // Center align on mobile
    maxWidth: isMobileView ? '500px' : '500px',
    minWidth: isMobileView ? '450px' : '450px',
  },
};


  return (
    <>
      <div className="md:px-14 px-4 md:pb-8 md:pt-14 pb- pt-12">
        <AnimateFromBottom delay={0.2}>
          <p className="text-2xl mb-3 font-semibold">
            {lang?.Testimonials_title}
          </p>
        </AnimateFromBottom>
        <AnimateFromBottom delay={0.3}>
          <p className="text-sm mb-2 font-normal">
            {lang?.Testimonials_subtitle}
          </p>
        </AnimateFromBottom>
      </div>
      <AnimateFromBottom delay={0.2}>
        {console.log('settings', settings)}
        {/* <div className="md:ml-14 overflow-hidden md:mb-8">
         */}
        {/* Old Test monial */}
        <div className="md:ml-14 mb-10 overflow-hidden video-slider">
        
          <Slider {...settings}>
            {testimonials.map((testimonial) => (
              <div key={testimonial.id}>
                <div className="testimonial-card ">
            
                  <img
                    src={testimonial.logo}
                    className="h-14 mb-3"
                    alt="logo"
                  />
                  
                  <p
                    className={`${seeMoreState[testimonial.id] ? '' : 'elipsis-4'
                      } "text-dim font-medium text-sm mb-1" `}
                  >
                    {testimonial.text}
                  </p>
                
                 
                  <p
                    className="text-royal flex mb-2 cursor-pointer"
                    onClick={() => handleSeeMore(testimonial.id)}
                  >
                    {seeMoreState[testimonial.id]
                      ? lang.see_less
                      : lang.see_more}
                  </p>
                  <div className="flex gap-2 items-center">
                    <img
                      src={testimonial.image}
                      className="w-10 h-10 rounded-full"
                      alt={testimonial.name}
                    />
                    <div>
                      <p className="font-medium text-sm">{testimonial.name}</p>
                      <p className="text-dim font-medium text-sm">
                        {testimonial.position}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* Old Test monial */}


        {/* Testmonial with Marquee */}

        {/* <Marquee speed={20} pauseOnHover={true} pauseOnClick={true} gradient={false}>
          <div style={stylesForMarquee.container}>
            {chunkedItemsForMaping.map((chunk, index) => (
              <div key={index} style={stylesForMarquee.row}>
                {chunk.map((testimonial) => (
                  <div key={testimonial.id} style={stylesForMarquee.column}>
                    <div className="testimonial-card">
                      <img src={testimonial.logo} className="h-14 mb-3" alt="logo" />
                  <p className={`${seeMoreState[testimonial.id] ? '' : 'elipsis-4'} text-dim font-medium text-sm mb-1`}> {testimonial.text}</p>
                      <p className="text-royal flex mb-2 cursor-pointer" onClick={() => handleSeeMore(testimonial.id)}>
                        {seeMoreState[testimonial.id] ? lang.see_less : lang.see_more}
                      </p>
                      <div className="flex gap-2 items-center">
                        <img src={testimonial.image} className="w-10 h-10 rounded-full" alt={testimonial.name} />
                        <div>
                          <p className="font-medium text-sm">{testimonial.name}</p>
                          <p className="text-dim font-medium text-sm">
                            {testimonial.position}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Marquee> */}
      </AnimateFromBottom>
    </>
  );
};

export default Testimonial;
