import React, {useState, useEffect} from 'react'
import { getMyRecentRequest, getDashboardRolesLogo } from '../../modules/Buyermodule';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import FormSpinner from '../../../../components/Spinners/FormSpinner';
import NoData from '../CreateRequestView/NoRequestFound'
import moment from 'moment';
import CustomUseEffect from '../../../../components/constants';

export default function RecentRequest() {
    const dispatch = useDispatch();
    const [isShow, setIsShow] = useState(false);
    function handleElipsis(){
        isShow ? setIsShow(false) : setIsShow(true);
    }
    CustomUseEffect(async() => {
        await (dispatch(getDashboardRolesLogo()));
        await (dispatch(getMyRecentRequest(1)));
    },[]);

    let { myRecentRequests, createRequestSpinner } = useSelector((state) => state.buyerModuleFilterData);
    return (
        <>
            <div className='card card--block card-gap'>
                <h4 className='fs-18 boxTitle'>Recent Requests</h4>
                <div className='reqBlock mt-4'>
                {createRequestSpinner ? <FormSpinner/> : 
                myRecentRequests?.recent_request?.length > 0 && myRecentRequests?.recent_request.map(({id, uuid, title,created_at}) => {
                    return (<><div className='grid grid-cols-2 mb-4 gap-2'>
                        <div className=''>
                            <p  className={isShow ? 'fs-14 textBlue cursor-pointer' : 'fs-14 textBlue elipsis cursor-pointer'} onClick={handleElipsis}>{title}</p>
                            <div className='text-xs'>{moment(created_at).format("DD MMM YYYY")}</div>
                        </div>
                        
                        <div className=''>
                        <Link to={`/view-recent-request/${uuid}`}><button className='btn btnBlue'>View Request</button></Link>
                        </div>
                    </div> </>)
                })
                }
                {myRecentRequests?.recent_request?.length == 0 && (
                <div className='dummyDataBlock'>
                    <h4 className='font-20 text-black text-center'>No request created yet</h4>
                    <p className='text-center'>You will see your recent requests that you will create.</p>
                </div>
                )}
                </div>
            </div>
        </>
    )
}
