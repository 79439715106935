import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import language from '../../../helpers/language';
import AnimateFromBottom from './AnimateFromBottom';
import Modal from 'react-modal';
import errorIcon from '../../../assets/landing-page/error-icon.svg';
import checkIcon from '../../../assets/landing-page/check-icon.svg';
import { Oval } from 'react-loader-spinner';
import ButtonRounded from './buttonRounded';
import REACT_APP_API from '../../../config/environment';

const NewsLetter = () => {
  const [lang] = useState(language.getLang());
  const emailRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleCloseSuccessModal = () => {
    setSuccess(false);
    setError(null);
  };

  const handleCloseErrorModal = () => {
    setError(null);
  };

  const locale = localStorage.getItem('lang');
  const handleSubmit = async (event) => {
    event.preventDefault();

    const email = emailRef.current.value;
    if (!email || !email.match(/\S+@\S+\.\S+/)) {
      setError('Please enter a valid email address.');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/newsletter`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            locale: locale,
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();
      console.log(data, 'data');

      if (response.ok) {
        setSuccess(true);
        emailRef.current.value = '';
      } else {
        console.log(data, 'data');

        setError(data.message || 'An error occurred while subscribing.');
      }
    } catch (error) {
      console.log(error, 'error');
      setError('Failed to subscribe. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="md:px-40 px-4 md:py-10 py-5">
      <AnimateFromBottom delay={0.2}>
        <div className="newsletter-container rounded-3xl grid md:grid-cols-2 md:py-16 py-8 md:px-14 px-6 gap-5 items-center">
          <div>
            <p className="font-bold text-3xl mb-3 text-white">
              {lang.Newsletter_Title}
            </p>
            <p className="font-normal text-sm text-white">
              {lang.Newsletter_Description}
            </p>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="newsletter-input">
              <input
                type="email"
                name="email"
                ref={emailRef}
                placeholder={lang.Newsletter_Placeholder}
                className={error ? 'error-input' : ''}
                required
              />
              {loading ? (
                <button
                  type="submit"
                  className=" flex justify-center cursor-not-allowed"
                >
                  <Oval
                    visible={true}
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="tail-spin-loading"
                    radius="4"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </button>
              ) : (
                <button type="submit" disabled={loading}>
                  {lang.Newsletter_Button}
                </button>
              )}
            </div>

            {error && <p className="error-message text-red-500">{error}</p>}
          </form>
        </div>
      </AnimateFromBottom>

      {/* Success Modal */}
      {success && (
        <Modal ariaHideApp={false} isOpen={success}>
          <div className="flex justify-center items-center flex-col gap-3">
          <i className='fa fa-check-circle text-royal text-6xl'></i>
            <p className="font-bold text-xl text-center">
              {lang.thakyou_subscribe}
            </p>
            <button
              className="border-btn-royal py-2 px-3 font-semibold inline"
              onClick={handleCloseSuccessModal}
            >
              {lang.close}
            </button>
          </div>
        </Modal>
      )}

      {/* Error Modal */}
      {error && (
        <Modal ariaHideApp={false} isOpen={!!error}>
          <div className="flex justify-center items-center flex-col gap-3">
            <img src={errorIcon} className="w-20" />
            <p className="font-bold text-xl text-center">{error}</p>
            <button
              className="rounded-md bg-red-500 text-white py-2 px-3 font-semibold inline"
              onClick={handleCloseErrorModal}
            >
              {lang.close}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default NewsLetter;
