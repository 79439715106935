import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import AnimateFromBottom from '../../LandingPage/component/AnimateFromBottom';

const Video = ({ videoUrl, thumbnail, videoTitle, videoDesc, videoSub }) => {
  const [playingVideo, setPlayingVideo] = useState(false);

  const handlePlayVideo = () => {
      setPlayingVideo(true);
  };
  return (
    <div className='md:px-14 px-4 md:py-8 py-5'>
      <div className='flex md:flex-row flex-col gap-10'>
        <div className='w-55'> 
          <AnimateFromBottom delay={0.2}>
          <p className='text-lg text-royal pb-2 uppercase font-medium'>
            {videoTitle}
          </p>
        </AnimateFromBottom>
          <AnimateFromBottom delay={0.3}>
            <p className='font-bold text-2xl pb-4'>{videoDesc}</p></AnimateFromBottom>
          <AnimateFromBottom delay={0.4}> 
          <p className='text-dim font-medium text-sm'>
            {videoSub}
          </p>
        </AnimateFromBottom>
        </div>
        <div className='w-45 w-full relative h-60 md:h-80'>
          <AnimateFromBottom delay={0.4}>
          {playingVideo ? (
                            <iframe
                                className='w-full h-60'
                                src={videoUrl}
                                title="Investment Video"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <>
                                <img src={thumbnail} className='w-full h-auto' alt="Investment" />
                                <button
                                    className='bg-black absolute translate-middle top-2/4 left-1/2 rounded-full flex w-10 h-10 justify-center items-center cursor-pointer'
                                    onClick={handlePlayVideo}
                                >
                                    <i className="fas fa-play text-xl text-white p-2"></i>
                                </button>
                            </>
                        )}</AnimateFromBottom>
                       
                    </div>
      </div>
    </div>
  );
}

export default Video;
