import React, { useState } from 'react'
import aboutImg1 from '../../../assets/landing-page/about-img1.png'
import aboutImg2 from '../../../assets/landing-page/about-img2.png'
import AnimateFromBottom from './AnimateFromBottom'
import language from "../../../helpers/language";
const About = () => {
    const [lang] = useState(language.getLang());
  return (
      <div className='md:px-14 px-4 md:py-8 py-5'>
          <div className='flex md:gap-10 gap-5 md:flex-row flex-col items-center mb-5 md:mb-0'>
              <div className='md:w-7/12'>
                  <AnimateFromBottom delay={0.2}>
                  <p className='text-lg text-royal mb-2 uppercase font-medium'>
                      {lang?.About_Alysei}
                  </p></AnimateFromBottom>    
                  <AnimateFromBottom delay={0.3}>
                  <p className='font-bold text-2xl mb-4'>{lang?.Set_your_course}</p>
                  <p className='text-sm text-royal mb-4 font-semibold'>{lang?.Alysei_was_conceived_L}</p>
                  <p className='text-dim font-medium text-sm'>{lang?.Alysei_is_the_first_B2B}

</p>
                  </AnimateFromBottom>    
             

               
              </div>
              <div className='md:w-5/12'>
                  <AnimateFromBottom delay={0.4}>
                  <img src={aboutImg1} className='w-full h-auto'/>
                  </AnimateFromBottom>   
                
              </div>
          </div>
          <div className='flex md:gap-10 gap-5 items-center  md:flex-row flex-col-reverse md:pt-10 pt-5'>
              <div className='md:w-5/12'>
                  <AnimateFromBottom delay={0.4}>
                  <img src={aboutImg2} className='w-full h-auto'/>
              </AnimateFromBottom>
                 
              </div>
              <div className='md:w-6/12'> 
           
                  <AnimateFromBottom delay={0.4}>
                  <p className='text-dim font-medium text-sm mb-4'>{lang?.Our_platform_aims}
</p>
                      <p className='text-dim font-medium text-sm mb-4'>{ lang?.Qualified_and_Certified} 

</p>
                  <p className='text-dim font-medium text-sm'>{lang?.In_addition_to_Italian_Producers}


</p>
              </AnimateFromBottom>
                 
                  
              </div>
              
          </div>
    </div>
  )
}

export default About