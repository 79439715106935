import React from 'react'
import { Helmet } from 'react-helmet'
const SeoContact = () => {
  return (
  <Helmet>
    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />

{/* <!-- Primary Meta Tags --> */}
<title>Contact Alysei | Connect with Us for Italian F&B Solutions</title>
<meta name="description" content="Reach out to Alysei for all your Italian food and beverage needs. Contact us to explore our B2B & B2C services, and learn how we can help expand your brand and business in the USA." />
<meta name="keywords" content="Alysei Contact Support, Alysei Customer Suppot, Alysei Contact No" />
<link rel="canonical" href="https://alysei.com/contact" />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:title" content="Contact Alysei | Connect with Us for Italian F&B Solutions" />
<meta property="og:description" content="Reach out to Alysei for all your Italian food and beverage needs. Contact us to explore our B2B & B2C services, and learn how we can help expand your brand and business in the USA." />
<meta property="og:url" content="https://alysei.com/contact" />
<meta property="og:site_name" content="Alysei" />
<meta property="og:image" content="https://alysei.com/dist/images/logo-blue.svg" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary" />
<meta property="twitter:url" content="https://alysei.com/contact" />
<meta property="twitter:title" content="Contact Alysei | Connect with Us for Italian F&B Solutions" />
<meta property="twitter:description" content="Reach out to Alysei for all your Italian food and beverage needs. Contact us to explore our B2B & B2C services, and learn how we can help expand your brand and business in the USA." />
<meta property="twitter:image" content="https://alysei.com/dist/images/logo-blue.svg" />

<script type="application/ld+json">
{`{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Contact Alysei | Connect with Us for Italian F&B Solutions",
  "url": "https://alysei.com/contact",
  "description": "Reach out to Alysei for all your Italian food and beverage needs. Contact us to explore our B2B & B2C services, and learn how we can help expand your brand and business in the USA.",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://alysei.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Contact",
        "item": "https://alysei.com/contact"
      }
    ]
  },
  "publisher": {
    "@type": "Organization",
    "name": "Alysei",
    "url": "https://alysei.com",
    "logo": "https://alysei.com/dist/images/logo.svg",
    "sameAs": [
      "https://www.facebook.com/AlyseiB2B",
      "https://www.instagram.com/alyseiplatform/",
      "https://www.linkedin.com/company/alysei",
      "https://www.youtube.com/@alysei/",
      "https://apps.apple.com/in/app/alysei/id1634783265",
      "https://play.google.com/store/apps/details?id=com.alysei"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1 (312) 286-4961",
      "contactType": "Customer Service",
      "areaServed": "US",
      "availableLanguage": "English"
    }
  }
}`}
</script>

  </Helmet>
  )
}

export default SeoContact;