import auth from '../../../helpers/auth';
import REACT_APP_API from '../../../config/environment';
import headerType from '../../../helpers/headerType';
export const RECENTLY_ADDED_PRODUCT = 'RECENTLY_ADDED_PRODUCT';
export const RECENTLY_ADDED_STORE = 'RECENTLY_ADDED_STORE';
export const TOP_BANNERS = 'TOP_BANNERS';
export const BOTTOM_BANNERS = 'BOTTOM_BANNERS';
export const TOP_FAVOURITE__PRODUCTS = 'TOP_FAVOURITE__PRODUCTS';
export const TOP_RATED__PRODUCTS = 'TOP_RATED__PRODUCTS';
export const PACKAGE = 'PACKAGE';
export const STORE_DATA = 'STORE_DATA';
export const SAVE_MARKETPLACE_STORE_BY_ID = 'SAVE_MARKETPLACE_STORE_BY_ID';
export const SET_MARKETPLACE_PRODUCT_BY_STORE_ID =
  'SET_MARKETPLACE_PRODUCT_BY_STORE_ID ';
export const POST_STORE_DATA = 'POST_STORE_DATA';
export const SAVE_MARKETPLACE_STORE = 'SAVE_MARKETPLACE_STORE';
export const STORE_PRODUCT_CATEGORIES = 'STORE_PRODUCT_CATEGORIES';
export const STORE_BRAND_LABELS = 'STORE_BRAND_LABELS';
export const CHECK_STORE_CREATED = 'CHECK_STORE_CREATED';
export const SAVE_STORE_REVIEW = 'SAVE_STORE_REVIEW';
export const SAVE_PRODUCT_CATEGORIES = 'SAVE_PRODUCT_CATEGORIES';
export const SAVE_PRODUCT_PROPERTIES = 'SAVE_PRODUCT_PROPERTIES';
export const SAVE_CONSERVATION_METHOD = 'SAVE_CONSERVATION_METHOD';
export const SAVE_ITALIAN_REGION = 'SAVE_ITALIAN_REGION';
export const SAVE_SEARCH_PRODUCT = 'SAVE_SEARCH_PRODUCT';
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const SET_MY_PRODUCT_LIST = 'SET_MY_PRODUCT_LIST';
export const SET_MARKETPLACE_PRODUCT = 'SET_MARKETPLACE_PRODUCT';
export const SET_MARKETPLACE_SHIPPING = 'SET_MARKETPLACE_SHIPPING';
export const SET_MARKETPLACE_RELATED_PRODUCT =
  'SET_MARKETPLACE_RELATED_PRODUCT';
export const SET_REVIEWS = 'SET_REVIEWS';
export const SET_PRODUCT_REVIEWED = 'SET_PRODUCT_REVIEWED';
export const SET_STORE_PRODUCT_REVIEWED = 'SET_STORE_PRODUCT_REVIEWED';
export const GET_MARKET_PLACE_SPINNER_STATUS =
  'GET_MARKET_PLACE_SPINNER_STATUS';
export const GET_MARKET_PLACE_PRODUCT_SPINNER_STATUS =
  'GET_MARKET_PLACE_PRODUCT_SPINNER_STATUS';
export const GET_MARKET_PLACE_SPINNER_DASHBOARD =
  'GET_MARKET_PLACE_SPINNER_DASHBOARD';
export const SET_REGISTER_ERR_MESSAGE = 'SET_REGISTER_ERR_MESSAGE';
export const SET_REGISTER_RESPONSE_ERR_MESSAGE =
  'SET_REGISTER_RESPONSE_ERR_MESSAGE';
export const SET_NEW_INQUIRY = 'SET_NEW_INQUIRY';
export const GET_ALL_STORE_PRODUCT = 'GET_ALL_STORE_PRODUCT';
export const SET_INQUIRY_MESSAGES = 'SET_INQUIRY_MESSAGES';
export const SET_CURRENT_USER_ID = 'SET_CURRENT_USER_ID';
export const SET_CONSERVATION_METHOD_LABEL = 'SET_CONSERVATION_METHOD_LABEL';
export const SET_PRODUCT_PROPERTIES_LABEL = 'SET_PRODUCT_PROPERTIES_LABEL';
export const ADD_PRODUCTS_TO_CART = 'ADD_PRODUCTS_TO_CART';
export const MY_DASHBOARD_STATUS = 'MY_DASHBOARD_STATUS';

export function setRecentlyAddedProduct(data) {
  return {
    type: RECENTLY_ADDED_PRODUCT,
    payload: data,
  };
}

export function getAllStoreProduct(data) {
  return {
    type: GET_ALL_STORE_PRODUCT,
    payload: data,
  };
}
export function getMarketPlaceSpinner(flag) {
  return {
    type: GET_MARKET_PLACE_SPINNER_STATUS,
    payload: flag,
  };
}
export function getMarketPlaceProductSpinner(flag) {
  return {
    type: GET_MARKET_PLACE_PRODUCT_SPINNER_STATUS,
    payload: flag,
  };
}
export function getMarketPlaceDashboarSpinner(flag) {
  return {
    type: GET_MARKET_PLACE_SPINNER_DASHBOARD,
    payload: flag,
  };
}
export function setFormErrors(message, fld) {
  return {
    type: SET_REGISTER_ERR_MESSAGE,
    payload: { msg: message, field: fld },
  };
}
export function setResponseErrors(error) {
  return {
    type: SET_REGISTER_RESPONSE_ERR_MESSAGE,
    payload: error,
  };
}
export function setRecentlyAddedStore(data) {
  return {
    type: RECENTLY_ADDED_STORE,
    payload: data,
  };
}
export function setMarketplaceProductByStoreId(data) {
  return {
    type: SET_MARKETPLACE_PRODUCT_BY_STORE_ID,
    payload: data,
  };
}
export function getTopFavouriteProduct(data) {
  return {
    type: TOP_FAVOURITE__PRODUCTS,
    payload: data,
  };
}
export function getTopRatedProduct(data) {
  return {
    type: TOP_RATED__PRODUCTS,
    payload: data,
  };
}

export function setTopBanners(data) {
  return {
    type: TOP_BANNERS,
    payload: data,
  };
}
export function setBottomBanners(data) {
  return {
    type: BOTTOM_BANNERS,
    payload: data,
  };
}
export function setPackagedata(data) {
  return {
    type: PACKAGE,
    payload: data,
  };
}

export function setStoreData(data) {
  return {
    type: STORE_DATA,
    payload: data,
  };
}

export function setPostStoreData(data) {
  return {
    type: POST_STORE_DATA,
    payload: data,
  };
}

export function setMarketPlaceStoreData(data) {
  return {
    type: SAVE_MARKETPLACE_STORE,
    payload: data,
  };
}
export function setMarketPlaceStoreDataById(data) {
  return {
    type: SAVE_MARKETPLACE_STORE_BY_ID,
    payload: data,
  };
}
export function setProductCategories(data) {
  return {
    type: STORE_PRODUCT_CATEGORIES,
    payload: data,
  };
}

export function setBrandLabels(data) {
  return {
    type: STORE_BRAND_LABELS,
    payload: data,
  };
}

export function setStoreCreatedFlag(data) {
  return {
    type: CHECK_STORE_CREATED,
    payload: data,
  };
}

export function setStoreReview(data) {
  return {
    type: SAVE_STORE_REVIEW,
    payload: data,
  };
}

export function setProductCategoriesAll(data) {
  return {
    type: SAVE_PRODUCT_CATEGORIES,
    payload: data,
  };
}

export function setProductProperties(data) {
  return {
    type: SAVE_PRODUCT_PROPERTIES,
    payload: data,
  };
}

export function setProductPropertiesLabel(data) {
  return {
    type: SET_PRODUCT_PROPERTIES_LABEL,
    payload: data,
  };
}

export function setConservationMethodLabel(data) {
  return {
    type: SET_CONSERVATION_METHOD_LABEL,
    payload: data,
  };
}

export function setConservationMethod(data) {
  return {
    type: SAVE_CONSERVATION_METHOD,
    payload: data,
  };
}

export function setItalianRegion(data) {
  return {
    type: SAVE_ITALIAN_REGION,
    payload: data,
  };
}

export function setSearchProductData(data) {
  return {
    type: SAVE_SEARCH_PRODUCT,
    payload: data,
  };
}

export function setDashboardData(data) {
  return {
    type: SET_DASHBOARD_DATA,
    payload: data,
  };
}

export function setMyProductsList(data) {
  return {
    type: SET_MY_PRODUCT_LIST,
    payload: data,
  };
}

export function setMarketplaceProduct(data) {
  return {
    type: SET_MARKETPLACE_PRODUCT,
    payload: data,
  };
}

export function setMarketplaceShipping(data) {
  return {
    type: SET_MARKETPLACE_SHIPPING,
    payload: data,
  };
}

export function setMarketplaceRelatedProduct(data) {
  return {
    type: SET_MARKETPLACE_RELATED_PRODUCT,
    payload: data,
  };
}

export function setReviews(data) {
  return {
    type: SET_REVIEWS,
    payload: data,
  };
}

export function setProductReviewed(data) {
  return {
    type: SET_PRODUCT_REVIEWED,
    payload: data,
  };
}

export function setMyDashboardStatus(data) {
  return {
    type: MY_DASHBOARD_STATUS,
    payload: data,
  };
}

export function setStoreProductReviewed(data) {
  return {
    type: SET_STORE_PRODUCT_REVIEWED,
    payload: data,
  };
}

export function setNewInquiry(data) {
  return {
    type: SET_NEW_INQUIRY,
    payload: data,
  };
}

export function setInquiryMessages(data) {
  return {
    type: SET_INQUIRY_MESSAGES,
    payload: data,
  };
}

export function setCurrentUserId(userId) {
  return {
    type: SET_CURRENT_USER_ID,
    payload: userId,
  };
}

export function addProdctToCart(data) {
  return {
    type: ADD_PRODUCTS_TO_CART,
    payload: data,
  };
}

// All count of product in cart.
export function getTotalProductInCart(productItemCart) {
  return productItemCart?.reduce((currentVal, accum) => currentVal + parseInt(accum.count), 0)
}

export function fetchHomeScreenData() {
  return async (dispatch, getState) => {
    await dispatch(getMarketPlaceSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/homescreen`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data && response.data.recently_added_product) {
          await dispatch(
            setRecentlyAddedProduct(response.data.recently_added_product)
          );
          await dispatch(getMarketPlaceSpinner(false));
        }

        if (response.data && response.data.newly_added_store) {
          await dispatch(
            setRecentlyAddedStore(response.data.newly_added_store)
          );
          await dispatch(getMarketPlaceSpinner(false));
        }

        if (response.data && response.data.top_banners) {
          await dispatch(setTopBanners(response.data.top_banners));
          await dispatch(getMarketPlaceSpinner(false));
        }
        if (response.data && response.data.bottom_banners) {
          await dispatch(setBottomBanners(response.data.bottom_banners));
          await dispatch(getMarketPlaceSpinner(false));
        }
        if (response.data && response.data.top_favourite_products) {
          await dispatch(
            getTopFavouriteProduct(response.data.top_favourite_products)
          );
          await dispatch(getMarketPlaceSpinner(false));
        }
        if (response.data && response.data.top_rated_products) {
          await dispatch(getTopRatedProduct(response.data.top_rated_products));
          await dispatch(getMarketPlaceSpinner(false));
        }
      } else {
        await dispatch(getMarketPlaceSpinner(false));
        console.log(response.errors);
      }
    } catch (e) {
      await dispatch(getMarketPlaceSpinner(false));
      console.log(e);
    }
  };
}

export function fetchPackageData() {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/marketplace/packages`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          await dispatch(setPackagedata(response.data));
        }
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function fetchStorePrefilledData() {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/store/prefilled/values`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          let formData = {};
          formData['name'] = response.data.company_name;
          formData['description'] = response.data.about;
          formData['website'] = response.data.website;
          formData['store_region'] = response.data.state.id;
          formData['location'] = response.data.address;
          formData['email'] = response.data.email;
          await dispatch(setPostStoreData(formData));
          await dispatch(setStoreData(response.data));
        }
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function fetchStoreData() {
  return async (dispatch, getState) => {
    // await dispatch(getMarketPlaceSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/store/details`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          let data = [];
          data = response.data;
          data['store_products'] = response?.store_products;
          data['incoterms'] = response?.incoterms;
          await dispatch(setMarketPlaceStoreData(data));
          // await dispatch(getMarketPlaceSpinner(false));
        }
      } else {
        // await dispatch(getMarketPlaceSpinner(false));
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      await dispatch(getMarketPlaceSpinner(false));
      console.log(e);
    }
  };
}

export function fetchAllStoreProductTypeAndData(id) {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/store/category/${id}`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          let data = [];
          data = response.data;
          data['store_products'] = response.store_products;
          await dispatch(getAllStoreProduct(data));
        }
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function fetchStoreDataById(id) {
  return async (dispatch, getState) => {
    await dispatch(getMarketPlaceSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/seller/profile/${id}`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          let data = [];
          data = response.data;
          data['store_products'] = response.store_products;
          await dispatch(setMarketPlaceStoreDataById(data));
          await dispatch(getMarketPlaceSpinner(false));
        }
      } else {
        await dispatch(getMarketPlaceSpinner(false));
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      await dispatch(getMarketPlaceSpinner(false));
      console.log(e);
    }
  };
}

export function fetchProductCategories() {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/marketplace/product/categories`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          await dispatch(setProductCategories(response.data));
        }
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}
export function fetchBrandLabels() {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/marketplace/brand/label`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          await dispatch(setBrandLabels(response.data));
        }
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function saveStoreData(data) {
  let form_data = new FormData();
  for (let key in data) {
    if (key === 'gallery_images') {
      for (let gallerykey in data[key]) {
        form_data.append('gallery_images[]', data[key][gallerykey]);
      }
    } else {
      form_data.append(key, data[key]);
    }
  }

  return async (dispatch, getState) => {
    await dispatch(getMarketPlaceSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/save/store`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getMarketPlaceSpinner(false));
        return response;
      } else {
        await dispatch(getMarketPlaceSpinner(false));
        await dispatch(setResponseErrors(response.errors));
        console.log(response.errors);
        return response;
      }
    } catch (e) {
      await dispatch(getMarketPlaceSpinner(false));
      await dispatch(setResponseErrors(e.toString()));
      console.log(e);
    }
  };
}

export function updateStoreData(data) {
  let form_data = new FormData();
  for (let key in data) {
    if (key === 'gallery_images') {
      for (let gallerykey in data[key]) {
        form_data.append('gallery_images[]', data[key][gallerykey]);
      }
    } else {
      form_data.append(key, data[key]);
    }
  }

  return async (dispatch, getState) => {
    await dispatch(getMarketPlaceSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/update/store/details`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(fetchStoreData());
        await dispatch(getMarketPlaceSpinner(false));
        return response;
      } else {
        await dispatch(getMarketPlaceSpinner(false));
        console.log(response.errors);
      }
    } catch (e) {
      await dispatch(getMarketPlaceSpinner(false));
      console.log(e);
    }
  };
}

export function checkStoreCreated() {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/checkif/store/created`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setStoreCreatedFlag(response.is_store_created));
      } else {
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function checkStoreStatus() {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/check/store/status`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        localStorage.setItem('storeStatus', response.data);
        await dispatch(setConservationMethodLabel(''));
        await dispatch(setProductPropertiesLabel(''));
      } else {
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function saveProductData(data, marketplace_store_id) {
  
  let form_data = new FormData();
  for (let key in data) {
    if (key === 'gallery_images') {
      for (let gallerykey in data[key]) {
        form_data.append('gallery_images[]', data[key][gallerykey]);
      }
    } else {
      form_data.append(key, data[key]);
    }
  }

  form_data.append('marketplace_store_id', marketplace_store_id);
  return async (dispatch, getState) => {
    await dispatch(getMarketPlaceSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/save/product`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(fetchHomeScreenData());
        dispatch(getMarketplaceMyProducts());
        await dispatch(getMarketPlaceSpinner(false));
        console.log(response,'save api response data');
        const { marketplace_product_id } = response.data;
        return {
          success: 200,
          marketplace_product_id: marketplace_product_id,
          data: response.data
        };
      } else {
        await dispatch(getMarketPlaceSpinner(false));
        await dispatch(setResponseErrors(response.errors));
        return response.errors
      }
    } catch (e) {
      await dispatch(getMarketPlaceSpinner(false));
      await dispatch(setResponseErrors(e.toString()));
      console.log(e);
    }
  };
}

export function fetchStoreReview(id) {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/all/reviews?id=${id}&type=1`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setStoreReview(response.data));
        if (response.is_rated === 1) {
          await dispatch(setStoreProductReviewed(false));
        }
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function fetchFavouriteStoreProduct(favourite_type, store_id) {
  let form_data = new FormData();
  form_data.append('favourite_type', favourite_type);
  form_data.append('id', store_id);
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/make/favourite/store/product`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        // await dispatch(fetchStoreDataById(store_id));
        return response;
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function setUnFavouriteStoreProduct(favourite_type, store_id) {
  console.log(favourite_type, store_id);
  let form_data = new FormData();
  form_data.append('favourite_type', favourite_type);
  form_data.append('id', store_id);
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/make/unfavourite/store/product`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        // await dispatch(fetchStoreDataById(store_id));
        return response;
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function getProductCategories() {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/marketplace/product/categories/all`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          await dispatch(setProductCategoriesAll(response.data));
        }
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function getConservationMethod(categoryId = null) {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/conservation/methods?category_id=` +
        categoryId,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          await dispatch(setConservationMethod(response.data));
        }
      } else {
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function getItalianRegion() {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/states?country_id=107`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          await dispatch(
            setItalianRegion(response.data.sort(dynamicSort('name')))
          );
        }
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

const dynamicSort = (property) => {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export function getProductProperties(categoryId = null) {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/product/properties?category_id=` +
        categoryId,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          await dispatch(setProductProperties(response.data));
        }
      } else {
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function getProductPropertiesAndConservation($productTypeId) {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/conservation/properties/${$productTypeId}`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          await dispatch(setConservationMethodLabel(response.data[0].option));
          await dispatch(setProductPropertiesLabel(response.data[1].option));
          await dispatch(setProductProperties(response.data[1].options));
          await dispatch(setConservationMethod(response.data[0].options));
        }
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function searchProducts(data) {
  return async (dispatch, getState) => {
    await dispatch(getMarketPlaceSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/filter?property=${data.property ?? ''
        }&category=${data.category ?? ''}&method=${data.method ?? ''
        }&fda_certified=${data.fda_certified ?? ''}&region=${data.region ?? ''
        }&sort_by_producer=${data.sort_by_producer ?? ''}&sort_by_product=${data.sort_by_product ?? ''
        }&type=${data.type ?? ''}&keyword=${data.keyword ?? ''}&box_id=${data.box_id
        }&page=${data.pageNo ?? ''}`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          await dispatch(getMarketPlaceSpinner(false));
          await dispatch(setSearchProductData(response.data));
          await dispatch(setMarketplaceProductByStoreId(response.data));
          return response.data;
        }
      } else {
        await dispatch(getMarketPlaceSpinner(false));
        console.log(response.errors);
      }
    } catch (e) {
      await dispatch(getMarketPlaceSpinner(false));
      console.log(e);
    }
  };
}

export function viewAllProductAndStoreData(id, pageNo) {
  return async (dispatch, getState) => {
    await dispatch(getMarketPlaceSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API
        }/get/all/entities/for/homescreen/${id}?&page=${pageNo ?? ''}`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        if (response.data) {
          await dispatch(getMarketPlaceSpinner(false));
          return response.data;
        }
      } else {
        await dispatch(getMarketPlaceSpinner(false));
        console.log(response.errors);
      }
    } catch (e) {
      await dispatch(getMarketPlaceSpinner(false));
      console.log(e);
    }
  };
}

export function getMarketplaceDashboard(year = 1) {
  return async (dispatch, getState) => {
    await dispatch(getMarketPlaceDashboarSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/dashboard/screen/${year}`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setDashboardData(response));
        await dispatch(getMarketPlaceDashboarSpinner(false));
      } else {
        await dispatch(getMarketPlaceDashboarSpinner(false));
        console.log(response.errors);
      }
    } catch (e) {
      await dispatch(getMarketPlaceDashboarSpinner(false));
      console.log(e);
    }
  };
}

export function analystMarketPlace() {
  return async (dispatch, getState) => {
    // await dispatch(getMarketPlaceDashboarSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        // `${REACT_APP_API.ENV.REACT_APP_API}/download/marketplace/analyst/1`,
        `${REACT_APP_API.ENV.REACT_APP}/download/marketplace/analyst/1`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      console.log(response, 'response...');
      if (response.success === 200) {
        await dispatch(setDashboardData(response));
        // await dispatch(getMarketPlaceDashboarSpinner(false));
      } else {
        // await dispatch(getMarketPlaceDashboarSpinner(false));
        console.log(response.errors);
      }
    } catch (e) {
      // await dispatch(getMarketPlaceDashboarSpinner(false));
      console.log(e);
    }
  };
}

export function getMarketplaceProductByStoreId(id, pageNo) {
  return async (dispatch, getState) => {
    await dispatch(getMarketPlaceSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API
        }/get/products/by/region?region_id=${id}&page=${pageNo ?? 0}`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setMarketplaceProductByStoreId(response.data));
        await dispatch(getMarketPlaceSpinner(false));
        return response.data;
      } else {
        await dispatch(getMarketPlaceSpinner(false));
        console.log(response.errors);
      }
    } catch (e) {
      await dispatch(getMarketPlaceSpinner(false));
      console.log(e);
    }
  };
}

export function getMarketplaceMyProducts(pageNo = 1, data) {
  const { search_product, category_id, stock } = data ?? {};
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/myproduct/list?page=${pageNo}&search_product=${search_product ?? ""}&category_id=${category_id ?? ""}&stock=${stock ?? ""}`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
     
      if (response.success === 200) {
        console.log(response,'response product product')
        // let filterData =response.data.data.filter((item)=>{
        //   return item.status==0
        //  })
        //  response.data["data"]=filterData
         // console.log(Math.ceil(filterData.length / 10),"Math.ceil(filterData.length / 10)")
         // response.data["last_page"]=Math.ceil(filterData.length / response.data.per_page)
         
        await dispatch(setMyProductsList(response.data));
      } else {
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function getMarketplaceProductDetails(
  marketplaceProductId,
  loader = true
) {
  return async (dispatch, getState) => {
    if (loader) {
      await dispatch(getMarketPlaceDashboarSpinner(true));
    }
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/product/detail?marketplace_product_id=${marketplaceProductId}`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setMarketplaceProduct(response.data.product_detail));
        await dispatch(setMarketplaceShipping(response.data.shipping_price));   
        await dispatch(getMarketPlaceDashboarSpinner(false));
      } else {
        await dispatch(getMarketPlaceDashboarSpinner(false));
        console.log(response.errors);
      }
    } catch (e) {
      await dispatch(getMarketPlaceDashboarSpinner(false));
      console.log(e);
    }
  };
}

export function getMarketplaceProductDetailsBySlug(slug) {
  return async (dispatch, getState) => {
    await dispatch(getMarketPlaceProductSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/product/detail/by/slug?slug=${slug}`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setMarketplaceProduct(response.data.product_detail));
        await dispatch(setMarketplaceShipping(response.data.shipping_price));
        await dispatch(getMarketPlaceProductSpinner(false));
        await dispatch(
          setMarketplaceRelatedProduct(response.data.related_products)
        );
      } else {
        await dispatch(getMarketPlaceProductSpinner(false));
        console.log(response.errors);
      }
    } catch (e) {
      await dispatch(getMarketPlaceProductSpinner(false));
      console.log(e);
    }
  };
}

export function updateMarketplaceReview(data, slug, id) {
  console.log(data, slug, id);
  let form_data = new FormData();
  for (let key in data) {
    form_data.append(key, data[key]);
  }

  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/update/marketplace/rating`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        data.type === 2 &&
          (await dispatch(getMarketplaceProductDetailsBySlug(slug)));
        data.type === 1 && (await dispatch(fetchStoreReview(id)));
        data.type === 1 && (await dispatch(fetchStoreDataById(id)));
        return response;
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function updateProductData(data, marketplace_product_id) {
  let form_data = new FormData();
  for (let key in data) {
    if (key === 'gallery_images') {
      for (let gallerykey in data[key]) {
        form_data.append('gallery_images[]', data[key][gallerykey]);
      }
    } else {
      form_data.append(key, data[key]);
    }
  }
  form_data.append('marketplace_product_id', marketplace_product_id);
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/update/product/details`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();
      console.log(response.data.marketplace_product_id,'checking marketplace product id');
      
      if (response.success === 200) {
        await dispatch(getMarketplaceProductDetails(marketplace_product_id));
        return response;
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function saveProductInquiry(data) {
  let form_data = new FormData();
  for (let key in data) {
    form_data.append(key, data[key]);
  }
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/save/product/enquery`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        return response;
      }
      if (response.success === 409) {
        console.log(response.errors);
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function deleteProductData(marketplace_product_id) {
  let form_data = new FormData();
  form_data.append('marketplace_product_id', marketplace_product_id);
  return async (dispatch) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/delete/product`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(fetchHomeScreenData());
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function removeMarketplaceStoreGalleryImage(
  marketplace_gallery_id,
  type
) {
  let form_data = new FormData();
  form_data.append('marketplace_store_gallery_id', marketplace_gallery_id);
  form_data.append('gallery_type', type);
  return async (dispatch) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/delete/gallery/image`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        // await dispatch(fetchStoreData());
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function removeMarketplaceStoreProductImage(
  marketplace__product_gallery_id,
  type
) {
  let form_data = new FormData();
  form_data.append(
    'marketplace_product_gallery_id',
    marketplace__product_gallery_id
  );
  form_data.append('gallery_type', type);

  return async (dispatch) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/delete/gallery/image`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function getProductStoreReview(id, type) {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/all/reviews?id=${id}&type=${type} `,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setReviews(response.data));
        if (response.is_rated === 1) {
          await dispatch(setProductReviewed(false));
        }
      } else {
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function saveReviewRating(review, rating, type = 1, id, slug) {
  let form_data = new FormData();
  form_data.append('review', review);
  form_data.append('rating', rating);
  form_data.append('type', type);
  form_data.append('id', id);

  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/do/review/store/product`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        type === 2 &&
          (await dispatch(getMarketplaceProductDetailsBySlug(slug)));
        type === 1 && (await dispatch(fetchStoreDataById(id)));
        type === 1 && (await dispatch(fetchStoreReview(id)));
        return response;
      } else {
        // alert(response.errors)
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function fetchNewInquiry(status = 'open') {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/user/enquiries/` + status,
        {
          method: 'GET',
          headers: headerType.HeadersWithToken(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setNewInquiry(response));
        await dispatch(setCurrentUserId(response.current_user_id));
        await dispatch(setInquiryMessages([]));
        await dispatch(
          fetchInquiryMessages(
            response?.data.data[0]?.receiver?.user_id ||
            response?.data.data[0]?.sender?.user_id,
            response?.data.data[0]?.product_id
          )
        );
        return response;
      } else {
        // alert(response.errors)
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function fetchInquiryMessages(senderId, productId) {
  let form_data = new FormData();
  form_data.append('product_id', productId);
  form_data.append('sender_id', senderId);

  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/enquiry/messages`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();

      if (response.success === 200) {
        await dispatch(setCurrentUserId(response.current_user_id));
        await dispatch(setInquiryMessages(response.data));
        return response;
      } else {
        // alert(response.errors)
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function sendNewMessage(data) {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/send/enquiry/message`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: data,
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setInquiryMessages(response.data));
        return response;
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function closeEnquiryApi(data) {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/update/enquiry/status`,
        {
          method: 'POST',
          headers: headerType.HeadersWithToken(token),
          body: data,
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        return response;
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

// Marketplace e-commerce start

export async function createTaxClass(data) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/add/tax/class`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify(data),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }

}

export async function uploadChatImageUpdatedLastActivity(model) {
  var formData = new FormData();
  for (var key in model) {
    if (key === "image") {
      for (let attachmentKey in model[key]) {
        attachmentKey !== "length" &&
          formData.append("image[]", model[key][attachmentKey]);
      }
    } else {
      formData.append(key, model[key]);
    }
  }
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/dispute/upload/images`,
      {
        method: "POST",
        headers: headerType.HeadersWithToken(token),
        body: formData,
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function changeDisputeStatus(data) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/dispute/update-status`,
      {
        method: "POST",
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify(data),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      //console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}



export async function createOrderDispute(data) {
   var formData = new FormData();
   for (var key in data) {
     if (key === "image") {
       for (let attachmentKey in data[key]) {
         attachmentKey !== "length" &&
           formData.append("image[]", data[key][attachmentKey]);
       }
     } else {
       formData.append(key, data[key]);
     }
   }
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/dispute/store`,
      {
        method: "POST",
        headers: headerType.HeadersWithToken(token),
        body: formData,
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function producerStripeConnect(data) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/connect/account`,
      {
        method: "POST",
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify(data),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      //console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function producerStripeDisconnect() {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/remove/connected/account`,
      {
        method: "DELETE",
        headers: headerType.HeadersWithTokenAndJson(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      //console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getStripeConnectStatus() {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/stripe/connected/status`,
      {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      //console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function createProductOffer(data) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/offer/create`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify(data),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }

}

export async function changeOrderProductStatus(id, status) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/change/order/status/${id}`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify({ status }),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }

}

export async function blockBuyerUser(block_user_id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/block/buyer`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify({ block_user_id }),
      }
    );
    let response = await result.json();
      return response;
    
  } catch (e) {
    console.log(e);
  }

}

// Delete my request
export async function deleteProducerRequest(uuid) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/delete/producer/request/${uuid}`,
      {
        method: 'DELETE',
        headers: headerType.HeadersWithTokenAndJson(token),
      }
    );
    let response = await result.json();
    return response;
  } catch (e) {
    console.log(e);
  }

}

export async function unBlockBuyerUser(block_user_id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/unblock/user`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify({ block_user_id }),
      }
    );
    let response = await result.json();
      return response;
    
  } catch (e) {
    console.log(e);
  }

}

export async function getProducerDisputeUnreadCount() {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/dispute/producer/unread-counts`,
      {
        method: "GET",
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}


export async function getProducerUnreadRequestCount() {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/producer/request-count`,
      {
        method: "GET",
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getMyDisputes(page, requestId, status) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/disputes?page=${page}&request_id=${requestId}&status=${status}`,
      {
        method: "GET",
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getMyLeads(page, buyer_name, product_type) {
  
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/producer/requests?page=${page}&buyer_name=${buyer_name}&product_type=${product_type}`,
      {
        method: "GET",
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getSingleLead(uuid) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/producer/request/${uuid}`,
      {
        method: "GET",
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function uploadBuyerChatImageUpdatedLastActivity(model) {
  var formData = new FormData();
  for (var key in model) {
    if (key === "image") {
      for (let attachmentKey in model[key]) {
        attachmentKey !== "length" &&
          formData.append("image[]", model[key][attachmentKey]);
      }
    } else {
      formData.append(key, model[key]);
    }
  }
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/upload/chat/attachment`,
      {
        method: "POST",
        headers: headerType.HeadersWithToken(token),
        body: formData,
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getSingleDispute(id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/dispute/${id}`,
      {
        method: "GET",
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function changeProductStatus(id, status) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/change/product/status/${id}`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify({ status }),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }

}

export async function createTax(data) {
  try {
    const token = auth.getAccessToken();

    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/add/my-tax`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify(data),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }

}

export async function changeOfferStatusImp(data) {
  try {
    const token = auth.getAccessToken();

    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/change/offer/status`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify(data),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }

}

export async function paymentSetting(data) {
  try {
    const token = auth.getAccessToken();

    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/payment/setting`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify(data),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }

}

// get bank details list
export async function getBankDetailsData() {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/payment/setting`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getGeoLocation() {
  try {
    const result = await fetch(
      `https://geolocation-db.com/json/`,
      {
        method: 'GET',
      }
    );
    let response = await result.json();
    if (response) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function updateTax(data, id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/update/my-tax/${id}`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify(data),
      }
    );
    let response = await result.json();
    if (response?.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }

}

export async function updateTaxClass(data, id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/update/tax-class/${id}`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify(data),
      }
    );
    let response = await result.json();
    if (response?.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }

}

export async function deleteTaxClasses(id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/delete/tax-class/${id}`,
      {
        method: 'DELETE',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function deleteTax(id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/delete/my-tax/${id}`,
      {
        method: 'DELETE',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getMyAllTaxesClassesList(page = 1) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/tax/classes?page=${page}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response.data;
    } else {
      console.log(response.errors);
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getMyAllTaxesList(page = 1) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/mytax?page=${page}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response.data;
    } else {
      console.log(response.errors);
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getSingeTaxDetails(id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/edit/my-tax/${id}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getSingleOfferImp(id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/offer/view/${id}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response
    }
  } catch (e) {
    console.log(e);
  }
}

export async function deleteOffer( id) {
  try {
    const token = auth.getAccessToken();
    const response = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/delete/offer/${id}`, {
      method: 'DELETE',
      headers: headerType.HeadersWithToken(token),
    });
    let data = response.json();
    if (data.success === 200) {
      return data
    } else {
      console.log(data)
      return data;
    }
  } catch (errors) {
    console.log(errors)
  }
}

export async function getSingeTaxClassDetails(id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/edit/tax-class/${id}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getMyAllOrderList(data, page = 1,filter) {
  const { order_id, customer, status, date,filterstatus} = data ?? {};
  console.log(filterstatus,order_id,"filllll")
  try {
    const token = auth.getAccessToken();
 
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/my-orders?page=${page}&order_id=${order_id ?? ""}&customer=${customer ?? ""}&status=${status??filterstatus??filter?? ""}&date=${date ?? ""}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();

    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getMyAllOrderTrasactionList(data, page = 1) {
  const { start_date, end_date, } = data ?? {};
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/my-transactions?page=${page}&start_date=${start_date ?? ""}&end_date=${end_date ?? ""}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function requestForTransactionPayment(order_id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/producer/payment/request/${order_id}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithTokenAndJson(token),
      },
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getCustomerList(data, page = 1) {
  const { customer, sort_by, } = data ?? {};
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/my-customers?page=${page}&customer=${customer ?? ""}&sort_by=${sort_by ?? ""}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getProductLists() {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/my-product/list`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getAddressList() {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/my-address`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getImportersList() {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/importer/lists`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getMyOffers(page = 1) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/my-offers?page=${page}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getSingleMyOffers(id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/order/${id}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      console.log(response.errors);
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function updateOffer(id, updatedData) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/update/my-offer/${id}`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify(updatedData)
      },
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      return response;
     
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getSingleTransactionDetails(id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/transaction/${id}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getCustomerDetailsById(id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/my-customer/${id}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getSingleOfferDetails(id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/edit/my-offer/${id}`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}
export async function getAllHubsListing() {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/get/my-country/hubs`,
      {
        method: 'GET',
        headers: headerType.HeadersWithToken(token),
      }
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function createOrderIntent(amount, stripe_id) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/payment/intent`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify({ amount, stripe_id })
      },
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function paymentStatus(data) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/order/payment/status`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify(data)
      },
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function verifyCartProducts(data) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/verify/checkout/order`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify(data)
      },
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function createOrder(orderData) {
  try {
    const token = auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/product/order`,
      {
        method: 'POST',
        headers: headerType.HeadersWithTokenAndJson(token),
        body: JSON.stringify(orderData)
      },
    );
    let response = await result.json();
    if (response.success === 200) {
      return response;
    } else {
      return response;
     
    }
  } catch (e) {
    console.log(e);
  }
}

export async function saveBillingAndDeliveryAddress(addressData) {
  try {
    const token = auth.getAccessToken();
    const response = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/add/address`, {
      method: "POST",
      headers: headerType.HeadersWithTokenAndJson(token),
      body: JSON.stringify(addressData)
    });
    let data = response.json();
    if (data.success === 200) {
      return data
    } else {
      console.log(data)
      return data;
    }
  } catch (errors) {
    console.log(errors)
  }
}

export async function uploadOrderInvoice(id, file) {
  try {
    const form_data = new FormData();
    form_data.append("invoice", file);
    const token = auth.getAccessToken();
    const response = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/upload/order-invoice/${id}`, {
      method: "POST",
      headers: headerType.HeadersWithToken(token),
      body: form_data
    });
    let data = response.json();
    if (data.success === 200) {
      return data
    } else {
      console.log(data)
      return data;
    }
  } catch (errors) {
    console.log(errors)
  }
}

export async function updateBillingAndDeliveryAddress(addressData, type, id) {
  try {
    const token = auth.getAccessToken();
    const response = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/update/my-address/${type}/${id}`, {
      method: "PUT",
      headers: headerType.HeadersWithTokenAndJson(token),
      body: JSON.stringify(addressData)
    });
    let data = response.json();
    if (data.success === 200) {
      return data
    } else {
      console.log(data)
      return data;
    }
  } catch (errors) {
    console.log(errors)
  }
}

export async function deleteBillingAndDeliveryAddress(type, id) {
  try {
    const token = auth.getAccessToken();
    const response = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/delete/${type}/${id}`, {
      method: 'DELETE',
      headers: headerType.HeadersWithToken(token),
    });
    let data = response.json();
    if (data.success === 200) {
      return data
    } else {
      console.log(data)
      return data;
    }
  } catch (errors) {
    console.log(errors)
  }
}


export const initialState = {
  recentlyAddedProduct: [],
  recentlyAddedStore: [],
  topBanners: [],
  bottomBanners: [],
  topFavourite: [],
  topRatedProduct: [],
  packageData: [],
  storeData: [],
  ProductByItalianRegionsId: [],
  postStoreData: [],
  marketPlaceStore: [],
  productCategies: [],
  brandLabels: [],
  isStoreCreated: '',
  marketplacestoreDataById: [],
  storeReview: [],
  productCategories: [],
  productProperties: [],
  conservationMethods: [],
  conservationMethodLabel: [],
  productPropertiesLabel: [],
  italianRegions: [],
  searchProductData: [],
  dashboardData: [],
  myProductsList: [],
  marketplaceProduct: [],
  marketplaceShipping: [],
  marketplaceRelatedProduct: [],
  productReviewed: true,
  isStoreReviewed: true,
  reviews: [],
  marketPlaceSpinner: false,
  marketPlaceProductSpinner: false,
  marketPlaceDashboardSpinner: false,
  errMessage: {
    msg: '',
    field: '',
  },
  resError: '',
  newInquiries: [],
  inquiryMessages: [],
  currentUserId: '',
  allStoreProductTypeAndData: [],
  cartProducts: [],
  myDashBoardStatus: ""
};

const ACTION_HANDLERS = {
  [RECENTLY_ADDED_PRODUCT]: (state, action) => {
    return {
      ...state,
      recentlyAddedProduct: action.payload,
    };
  },
  [RECENTLY_ADDED_STORE]: (state, action) => {
    return {
      ...state,
      recentlyAddedStore: action.payload,
    };
  },
  [TOP_BANNERS]: (state, action) => {
    return {
      ...state,
      topBanners: action.payload,
    };
  },
  [BOTTOM_BANNERS]: (state, action) => {
    return {
      ...state,
      bottomBanners: action.payload,
    };
  },
  [TOP_FAVOURITE__PRODUCTS]: (state, action) => {
    return {
      ...state,
      topFavourite: action.payload,
    };
  },
  [TOP_RATED__PRODUCTS]: (state, action) => {
    return {
      ...state,
      topRatedProduct: action.payload,
    };
  },
  [PACKAGE]: (state, action) => {
    return {
      ...state,
      packageData: action.payload,
    };
  },
  [STORE_DATA]: (state, action) => {
    return {
      ...state,
      storeData: action.payload,
    };
  },
  [SAVE_MARKETPLACE_STORE_BY_ID]: (state, action) => {
    return {
      ...state,
      marketplacestoreDataById: action.payload,
    };
  },
  [POST_STORE_DATA]: (state, action) => {
    return {
      ...state,
      postStoreData: action.payload,
    };
  },
  [SAVE_MARKETPLACE_STORE]: (state, action) => {
    return {
      ...state,
      marketPlaceStore: action.payload,
    };
  },
  [STORE_PRODUCT_CATEGORIES]: (state, action) => {
    return {
      ...state,
      productCategies: action.payload,
    };
  },
  [STORE_BRAND_LABELS]: (state, action) => {
    return {
      ...state,
      brandLabels: action.payload,
    };
  },
  [CHECK_STORE_CREATED]: (state, action) => {
    return {
      ...state,
      isStoreCreated: action.payload,
    };
  },
  [SAVE_STORE_REVIEW]: (state, action) => {
    return {
      ...state,
      storeReview: action.payload,
    };
  },
  [SAVE_PRODUCT_CATEGORIES]: (state, action) => {
    return {
      ...state,
      productCategories: action.payload,
    };
  },
  [SAVE_PRODUCT_PROPERTIES]: (state, action) => {
    return {
      ...state,
      productProperties: action.payload,
    };
  },
  [SET_CONSERVATION_METHOD_LABEL]: (state, action) => {
    return {
      ...state,
      conservationMethodLabel: action.payload,
    };
  },
  [SET_PRODUCT_PROPERTIES_LABEL]: (state, action) => {
    return {
      ...state,
      productPropertiesLabel: action.payload,
    };
  },
  [SAVE_CONSERVATION_METHOD]: (state, action) => {
    return {
      ...state,
      conservationMethods: action.payload,
    };
  },
  [SAVE_ITALIAN_REGION]: (state, action) => {
    return {
      ...state,
      italianRegions: action.payload,
    };
  },
  [SET_MARKETPLACE_PRODUCT_BY_STORE_ID]: (state, action) => {
    return {
      ...state,
      ProductByItalianRegionsId: action.payload,
    };
  },
  [SAVE_SEARCH_PRODUCT]: (state, action) => {
    return {
      ...state,
      searchProductData: action.payload,
    };
  },
  [SET_DASHBOARD_DATA]: (state, action) => {
    return {
      ...state,
      dashboardData: action.payload,
    };
  },
  [SET_MY_PRODUCT_LIST]: (state, action) => {
    return {
      ...state,
      myProductsList: action.payload,
    };
  },
  [SET_MARKETPLACE_PRODUCT]: (state, action) => {
    return {
      ...state,
      marketplaceProduct: action.payload,
    };
  },
  [SET_MARKETPLACE_SHIPPING]: (state, action) => {
    return {
      ...state,
      marketplaceShipping: action.payload,
    };
  },
  [SET_MARKETPLACE_RELATED_PRODUCT]: (state, action) => {
    return {
      ...state,
      marketplaceRelatedProduct: action.payload,
    };
  },
  [SET_REVIEWS]: (state, action) => {
    return {
      ...state,
      reviews: action.payload,
    };
  },
  [SET_PRODUCT_REVIEWED]: (state, action) => {
    return {
      ...state,
      productReviewed: action.payload,
    };
  },
  [SET_STORE_PRODUCT_REVIEWED]: (state, action) => {
    return {
      ...state,
      isStoreReviewed: action.payload,
    };
  },
  [GET_MARKET_PLACE_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      marketPlaceSpinner: action.payload,
    };
  },
  [GET_MARKET_PLACE_PRODUCT_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      marketPlaceProductSpinner: action.payload,
    };
  },
  [GET_MARKET_PLACE_SPINNER_DASHBOARD]: (state, action) => {
    return {
      ...state,
      marketPlaceDashboardSpinner: action.payload,
    };
  },
  [SET_REGISTER_ERR_MESSAGE]: (state, action) => {
    return {
      ...state,
      errMessage: action.payload,
    };
  },
  [SET_REGISTER_RESPONSE_ERR_MESSAGE]: (state, action) => {
    return {
      ...state,
      resError: action.payload,
    };
  },
  [SET_NEW_INQUIRY]: (state, action) => {
    return {
      ...state,
      newInquiries: action.payload,
    };
  },
  [SET_INQUIRY_MESSAGES]: (state, action) => {
    return {
      ...state,
      inquiryMessages: action.payload,
    };
  },
  [SET_CURRENT_USER_ID]: (state, action) => {
    return {
      ...state,
      currentUserId: action.payload,
    };
  },
  [GET_ALL_STORE_PRODUCT]: (state, action) => {
    return {
      ...state,
      allStoreProductTypeAndData: action.payload,
    };
  },
  [ADD_PRODUCTS_TO_CART]: (state, action) => {
    return {
      ...state,
      cartProducts: action.payload,
    };
  },
  [MY_DASHBOARD_STATUS]: (state, action) => {
    return {
      ...state,
      myDashBoardStatus: action.payload,
    };
  },
};

export default function marketPlaceReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
