import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import constant from "../../../helpers/constant";
import NetworkLeftNavigation from "./NetworkLeftNavigation";
import { blockRequest, cencelConnectionReq, fetchConnectionTabs, acceptRejectRequest, getConnectionTabsData } from "../modules/networkConnection";
import NotFound from "../../../components/NetworkConnection/NotFound";
import FormSpinner from '../../../components/Spinners/FormSpinner';
import ReactPaginate from 'react-paginate';
import ConnectionModal from '../../../components/ConnectionModals/ConnectionModal';
import auth from '../../../helpers/auth';
import { fetchDataConncetionSuggetion, getConnectionData } from '../../B2B/modules/B2B';
import language from '../../../helpers/language';
import { unblockUserRequest } from '../../setting/module/UserSetting';
import ContentLoader from 'react-content-loader'
import CustomUseEffect from '../../../components/constants';

export default function Pending() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [isTrue, setIsTrue] = useState(false);
    const [openConnectionModel, setOpenConnectionModel] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [lang] = useState(language.getLang());
    const [typeOfUser, setTypeOfUser] = useState("");

    CustomUseEffect(async () => {
        await dispatch(fetchConnectionTabs(constant.tabs.pending, page));
        const userType = await auth.getUserType()
        await setTypeOfUser(JSON.parse(userType))
        await setIsTrue(true);
    }, [])

    const { connectionSpinner, connectionTabs, blockUserSpinner, cancelConnectionSpinner, acceptRejectSpinner } = useSelector((state) => state.networkConnectionState);

    const handleCancelReq = async (items) => {
        const response = await dispatch(acceptRejectRequest({ connection_id: parseInt(items?.connection_id), accept_or_reject: 2 }));
        if (response.success === 200) {
            const newdata = connectionTabs.data?.data?.filter(item => item.connection_id !== parseInt(items?.connection_id));
            const finalValue = {
                ...connectionTabs,
                data: { ...connectionTabs.data, data: newdata }
            }
            await dispatch(getConnectionTabsData(finalValue));
            await dispatch(fetchDataConncetionSuggetion({ searchType: 2, roleId: typeOfUser?.role_id }));
        } else {
            setErrorText(response?.errors?.exception)
        }
    }
    const handleBlockReq = async (userId) => {
        const response = await dispatch(blockRequest({ block_user_id: userId }));
        if (response.success === 200) {
            const newdata = connectionTabs?.data?.data?.map((item) => item.user_id === userId ? { ...item, block_flag: 1 } : item);
            const finalValue = {
                ...connectionTabs,
                data: { ...connectionTabs.data, data: newdata }
            }
            await dispatch(getConnectionTabsData(finalValue));
        } else {
            setErrorText(response?.errors?.exception)
        }
    }

    const handleUnBlockReq = async (userId) => {
        const response = await dispatch(unblockUserRequest({ block_user_id: userId }));
        if (response.success === 200) {
            const newdata = connectionTabs?.data?.data?.map((item) => item.user_id === userId ? { ...item, block_flag: 0 } : item);
            const finalValue = {
                ...connectionTabs,
                data: { ...connectionTabs.data, data: newdata }
            }
            await dispatch(getConnectionTabsData(finalValue));
        } else {
            setErrorText(response?.errors?.exception)
        }
    }

    const handlePageClick = async (e) => {
        let pageNo = e.selected + 1
        await setPage(page);
        await dispatch(fetchConnectionTabs(constant.tabs.followers, pageNo));
    };
    const handleModal = (newValue) => {
        setOpenConnectionModel(newValue);
    }
    return (
        <div className="home__page b2b hubMember pendingPage">
            {/* {openConnectionModel && <ConnectionModal openModelData={{ openModel: openConnectionModel, user_id: userId, name: userName, handleOpenModel: handleModal, network: true, from: "Cancel request", userData: connectionTabs }} />} */}
            <div className="servics_blocks">
                <div className="site-align top-align">
                    {/* Left Block */}
                    <NetworkLeftNavigation />
                    {/* Middle Block */}
                    <div className="servics_blocks middle-long__section-block middleBlockBg">
                        <div className="">
                            {connectionTabs?.errors || (connectionTabs?.data?.data?.length === 0) ?
                                <Fragment><div className="medium_title"><h1 className="font-18 text-black">{lang?.Pending}</h1></div><NotFound errors={connectionTabs?.data?.data?.length === 0 ? `${lang?.No_pending_request}` : connectionTabs?.errors?.exception} /></Fragment>
                                : <div className="medium_title"><h1 className="font-18 text-black">{lang?.Pending}</h1></div>
                            }
                            <p style={{ color: "red" }}>{errorText}</p>
                            {connectionSpinner ? <FormSpinner /> :
                                <div className="card--section site-align top-align flex-wrap">
                                    {connectionTabs?.data?.data.length > 0 && connectionTabs?.data?.data?.map((item, index) => {
                                        return (
                                            <div className="card card--block" key={index} >
                                                <div className="hubUser" onClick={() => history.push(`${"/visitor-profile/"}${item.user_id}`)}>
                                                    <div className="feed-user-info mb-2">
                                                        <div className="user__pic">
                                                            {item?.user?.avatar_id ?
                                                                <img src={`${item.user.avatar_id.base_url}${item.user.avatar_id.attachment_medium_url}`} alt="User Image" /> :
                                                                ''
                                                                
                                                            }
                                                        </div>
                                                        <div className="user__detail-data">
                                                            <span className="user__name">
                                                                {item?.user ? 
                                                                <Link to="#">{(item?.user?.company_name) ?? (item?.user?.restaurant_name) ?? (`${item?.user?.first_name} ${item?.user?.last_name}`)}</Link>
                                                                :
                                                                ''
                                                                }
                                                            </span>
                                                            <div className="flex">
                                                                {item?.user?.role_id === 3 && <span>{lang?.Italian_F_and_B_producers}</span>}
                                                                {item?.user?.role_id === 4 && <span>{lang?.only_Importer}</span>}
                                                                {item?.user?.role_id === 5 && <span>{lang?.only_Distributor}</span>}
                                                                {item?.user?.role_id === 6 && <span>{lang?.Importer_And_Distributor}</span>}
                                                                {item?.user?.role_id === 7 && <span>{lang?.Voice_Of_Experts}</span>}
                                                                {item?.user?.role_id === 8 && <span>{lang?.Travel_Agencies}</span>}
                                                                {item?.user?.role_id === 9 && <span>{lang?.Italian_Restaurants_in_US}</span>}
                                                                {item?.user?.role_id === 10 && <span>{lang?.Voyagers}</span>}

                                                            </div>
                                                            {/*<span className="text-blue">
                                                            {item&& item.user.followers_count} {" "}Followers
                                                                </span>*/}
                                                        </div>
                                                    </div>
                                                    <p>{item.reason_to_connect?.length > 80 ? item.reason_to_connect?.slice(0, 80) + "..." : item.reason_to_connect} </p>
                                                </div>
                                                <div className="viewReq mt-4"><Link to="#" className="btn bg-Primary w-full" onClick={() => handleCancelReq(item)}>{lang?.Cancel}</Link></div>
                                                {item.block_flag === 0 ? <div className="view__hub__btn"><Link to="#" onClick={() => handleBlockReq(item?.user?.user_id)}>{lang?.Block}</Link></div>
                                                    : <div className="view__hub__btn"><Link to="#" onClick={() => handleUnBlockReq(item?.user?.user_id)}>{lang?.UnBlock}</Link></div>}
                                            </div>
                                        )
                                    })
                                    }

                                </div>}
                        </div>
                        {isTrue && connectionTabs?.data?.total > 10 &&
                            <ReactPaginate
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil((connectionTabs?.data?.total) / (connectionTabs?.data?.per_page))}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"} />}
                    </div>
                    {/* Right Block */}
                </div>
            </div>
        </div>
    )
}
