import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Carousel from 'react-grid-carousel'
import ReactStars from "react-rating-stars-component";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import language from '../../../helpers/language';

import { tabIndexFun } from '../modules/RecipeModule'

export default function ExploreTab(props) {

    const [lang] = useState(language.getLang());
    const dispatch = useDispatch()
    // CustomUseEffect(async () => { await dispatch(tabIndexFun(0)) }, [])
    useEffect(() => {
        localStorage.removeItem('recipe_id');
      },[])
    const { recipeExplore, explorePercentageSpinner } = useSelector((state) => state.recipedata)
    return (
        <Fragment>
            {explorePercentageSpinner ? <FormSpinner /> :
                <div className="section__wrapper">
                    <div className="">
                        {/*---------------- Quick search by Ingredients------------------------- */}
                        <div className="recipe__section">
                            <div className="section_heading"> <h2 className="section_title">{lang?.Quick_search_by} {lang?.Ingredients}</h2> </div>
                            {/* <div className="viewAll"> <Link to="#"><h6>View All</h6></Link></div> */}
                            <div className="ingredients__List">
                                <ul>
                                    {recipeExplore?.ingredients?.length > 0 && recipeExplore?.ingredients?.map((item, index) => {
                                        return (
                                            <li className="recipe__detail" key={index}>
                                                <Link to={`${"/recipe/search"}/${item?.title}/${item?.recipe_ingredient_id}`}  >
                                                    <div className="ingredient_img"><img src={`${item?.image_id?.base_url}${item?.image_id?.attachment_url}`} alt='img' /></div>
                                                </Link>
                                                <h6 className="ingredient_name">{item.title}</h6>
                                            </li>
                                        )
                                    })
                                    }
                                </ul>
                            </div>

                        </div>

                        {/*---------------- Quick search by Meals------------------------- */}
                        <div className="recipe__section">
                            <div className="section_heading"> <h2 className="section_title">{lang?.Quick_search_by} {lang?.Meals}</h2></div>
                            {/* <div className="viewAll"><Link to="#"><h6>View All</h6></Link></div> */}
                            {explorePercentageSpinner ? <FormSpinner /> :
                                <div className="ingredients__List">
                                    <ul>
                                        {recipeExplore?.meals?.length > 0 && recipeExplore?.meals?.map((item, index) => {
                                            return (
                                                <li className="recipe__detail" key={index}>
                                                    <Link to={`${"/recipe/search"}/${item?.name}/${item?.recipe_meal_id}`} >
                                                        <div className="ingredient_img"> <img src={`${item?.image_id?.base_url}${item?.image_id?.attachment_url}`} alt='img' /></div>
                                                    </Link>
                                                    <h6 className="ingredient_name">{item?.name}</h6>
                                                </li>
                                            )
                                        })
                                        }
                                    </ul>
                                </div>
                            }
                        </div>

                        {/*---------------- Quick search by Italian Regions------------------------- */}
                        <div className="recipe__section">
                            <div className="section_heading"> <h2 className="section_title">{lang?.Quick_search_by_Italian_Regions}</h2></div>
                            {/* <div className="viewAll"><Link to="#"><h6>View All</h6></Link> </div> */}
                            {explorePercentageSpinner ? <FormSpinner /> :
                                <div className="ingredients__List byRegion">
                                    <ul>
                                        {recipeExplore?.regions?.length > 0 &&
                                            <Carousel cols={6} rows={1} gap={1} 
                                            hideArrow={recipeExplore?.regions?.length <= 6 ? true : false}
                                            loop
                                            responsiveLayout={[
                                            {
                                                breakpoint: 991,
                                                cols: 3,
                                            },
                                            {
                                                breakpoint: 767,
                                                cols: 2,
                                            },
                                            {
                                                breakpoint: 500,
                                                cols: 2,
                                            },
                                            ]}
                                            mobileBreakpoint={670}
                                            >
                                                {recipeExplore?.regions?.length > 0 && recipeExplore?.regions?.map((item, index) => {
                                                    return (
                                                        <Carousel.Item key={index}>
                                                            <li className="recipe__detail">
                                                                <Link to={`${"/recipe/search"}/${item?.name?.replace(/\s+/g, '-')}/${item?.recipe_region_id}`} >
                                                                    <div className="ingredient_img"><img src={`${item?.image_id?.base_url}${item?.image_id?.attachment_url}`} alt='img' /></div>
                                                                    <h6 className="ingredient_name">{item?.name}</h6>
                                                                </Link>
                                                            </li>
                                                        </Carousel.Item>
                                                    )
                                                })
                                                }
                                            </Carousel>
                                        }
                                    </ul>
                                </div>
                            }
                        </div>

                        {/*---------------- Trending Now------------------------- */}
                        { recipeExplore?.trending_recipes?.length > 0 ?
                        <div className="recipe__section">
                            <div className="section_heading"> <h2 className="section_title">{lang?.Trending_Now}</h2></div>
                            {/* <div className="viewAll"> <Link to="#"><h6>View All</h6></Link></div> */}
                            {explorePercentageSpinner ? <FormSpinner /> :
                                <div className="trending_List">
                                    <ul>
                                        {recipeExplore?.trending_recipes?.length > 0 && recipeExplore?.trending_recipes?.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link to={`${"/recipes/recipe-view"}/${item.recipe_id}`} >
                                                        <div className="trending_Img">
                                                            <img src={`${item.image.base_url}${item.image.attachment_url}`} alt='' />
                                                            <div className="recipe_rating">
                                                                <p><span className="recipe_Name justify-start">{item.name}</span>
                                                                    <span className="star_rating"><ReactStars count={5} value={parseFloat(item.avg_rating)} size={24} edit={false} color2={'#ffd700'} /></span>
                                                                </p>
                                                                <p>
                                                                    <span className="heart_Icon">
                                                                        {item?.is_favourite !== 0 ? (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 20 17.648" >
                                                                                <path id="like_icon_active"
                                                                                    d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                                                                    transform="translate(-5 -9)" fill="#ff0046" />
                                                                            </svg>
                                                                        ) : (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20.653 18.099" >
                                                                                <path d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                                                                    id="icons8_heart" transform="translate(-2 -7)" />
                                                                            </svg>
                                                                        )}
                                                                    </span> <span className="likes"> {item.favourite_count} Likes </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="recipe_Info">
                                                            <p className="mb-3">{item.username}</p>
                                                            <p><span><i className="fa fa-clock-o" aria-hidden="true"></i></span>{item && item.hours === 0 ? '' : `${item && item.hours} Hr`} {item && item.minutes === 0 ? '' : `${item.minutes} Mins`}  </p>
                                                            <p><span><i className="fa fa-user" aria-hidden="true"></i></span>{item.serving}</p>
                                                            <p><span><i className="fa fa-cutlery" aria-hidden="true"></i></span>{item.meal.name}</p>
                                                        </div>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                         : ''
                        }
                        {/*---------------- Quick Easy------------------------- */}
                        {recipeExplore?.quick_easy?.length > 0 ? 
                        <div className="recipe__section">
                            <div className="section_heading"> <h2 className="section_title">{lang?.Quick_Easy}</h2></div>
                            {/* <div className="viewAll"> <Link to="#"><h6>View All</h6></Link></div> */}
                            {explorePercentageSpinner ? <FormSpinner /> :
                                <div className="trending_List">
                                    <ul>
                                        {recipeExplore?.quick_easy?.length > 0 && recipeExplore?.quick_easy?.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link to={`${"/recipes/recipe-view"}/${item?.recipe_id}`}>
                                                        <div className="trending_Img">
                                                            <img src={`${item?.image?.base_url}${item?.image?.attachment_url}`} alt='' />
                                                            <div className="recipe_rating">
                                                                <p><span className="recipe_Name">{item?.name}</span>
                                                                    <span className="star_rating"> <ReactStars count={5} value={parseFloat(item?.avg_rating)} size={24} edit={false} color2={'#ffd700'} /> </span>
                                                                </p>
                                                                <p>
                                                                    <span className="heart_Icon">
                                                                        {item.favourite_count > 0 ? (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 20 17.648" >
                                                                                <path id="like_icon_active"
                                                                                    d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                                                                    transform="translate(-5 -9)" fill="#ff0046" />
                                                                            </svg>
                                                                        ) : (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20.653 18.099" >
                                                                                <path id="icons8_heart"
                                                                                    d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                                                                    transform="translate(-2 -7)"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                    </span> <span className="likes"> {item?.favourite_count} Likes </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="recipe_Info">
                                                            <p className="mb-3">{item.username}</p>
                                                            <p><span><i className="fa fa-clock-o" aria-hidden="true"></i></span>{item && item.hours === 0 ? '' : `${item && item.hours} Hr`} {item && item.minutes === 0 ? '' : `${item.minutes} Mins`}  </p>
                                                            <p><span><i className="fa fa-user" aria-hidden="true"></i></span>{item?.serving}</p>
                                                            <p><span><i className="fa fa-cutlery" aria-hidden="true"></i></span>{item?.meal?.name}</p>
                                                        </div>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                        : ''
                        }
                    </div>
                </div>
            }
        </Fragment>
    );
}

