import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import auth from "../../../helpers/auth";
import constant from "../../../helpers/constant"
import REACT_APP_API from "../../../config/environment"
import settinginactive from '../../../assets/images/setting-inactive.svg'
import settingactive from '../../../assets/images/setting-active.svg'
import privacyinactive from '../../../assets/images/privacy-inactive.svg'
import privacyactive from '../../../assets/images/privacy-active.svg'
import companyinactive from '../../../assets/images/company-inactive.svg'
import companyactive from '../../../assets/images/company-active.svg'
import passwordinactive from '../../../assets/images/password-inactive.svg'
import passwordactive from '../../../assets/images/password-active.svg'
import blockinginactive from '../../../assets/images/blocking-inactive.svg'
import blockingactive from '../../../assets/images/blocking-active.svg'
import membershipinactive from '../../../assets/images/membership-inactive.svg'
import membershipactive from '../../../assets/images/membership-active.svg'
import subscriptioninactive from '../../../assets/images/subscription-inactive.svg'
import subscriptionactive from '../../../assets/images/subscription-active.svg'
import billinginactive from '../../../assets/images/billings-inactive.svg'
import billingactive from '../../../assets/images/billing-active.svg'
import yourdatainactive from '../../../assets/images/yourdata-inactive.svg'
import yourdataactive from '../../../assets/images/yourdata-active.svg'
import faqinactive from '../../../assets/images/faq-inactive.svg'
import faqactive from '../../../assets/images/faq-active.svg'
import privacypolicyinactive from '../../../assets/images/privacypolicy-inactive.svg'
import privacypolicyactive from '../../../assets/images/privacypolicy-active.svg'
import termconditioninactive from '../../../assets/images/termcondition-inactive.svg'
import copylink from '../../../assets/images/copy-link.svg'
import termconditionactive from '../../../assets/images/termcondition-active.svg'
import language from "../../../helpers/language";
import CustomUseEffect from "../../../components/constants";

export default function SettingLeftNavigation() {
    const location = useLocation();
    const [lang] = useState(language.getLang());
    const [userType, setuserType] = useState();
    const [toggle, setToggle] = useState(false);
    const [copyLink, setCopyLink] = useState(false);
    const [copied, setCopied] = useState(false);
    const [nonSubscriptionPage, setNonsubscription] = useState()

    CustomUseEffect(async () => {
        const userType = await auth.getUserType()
        await setuserType(JSON.parse(userType));
    }, []);

    useEffect(() => {
        let role = JSON.parse(localStorage.getItem('USER_TYPE'));
        if (role.role_id == 3) {
            setNonsubscription(role)
        }
    }, [])

    const hubSelection = async () => {
        if (constant?.rolesId?.voyagers != userType?.role_id) {
            await setToggle(true);
        }
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(window.location.origin)
            .then(() => {
                setCopied(true);
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <div className="left__section-block sm:mb-2 md:mb-2">
            <div className="card card--block">
                <div className="hubsTitle">
                    <h4 className="font-18">{lang?.Settings}</h4>
                </div>
                <div className="card-form p-3">
                    <div className="leftNavigation">
                        <ul>
                            <li className={location.pathname === "/setting" ? "activeNav" : ""}>
                                <Link to="/setting" onClick={() => hubSelection()}>
                                    <span className="navIcon disable_icon">
                                        <img src={settinginactive} alt="Setting Inactive" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={settingactive} alt="Setting Active" />
                                    </span>
                                    <span className="w-full navText text-left pl-4">
                                        {lang?.Settings}
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                                {toggle && <ul className="py-1 setting-dropdown forDesktop" aria-labelledby="dropdownButton">
                                    <li>
                                        <Link to="/hubs" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Hub Settings</Link>
                                    </li>
                                </ul>}
                            </li>
                            {/* <div id="dropdown" className="z-10 w-44 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
                                    <ul className="py-1" aria-labelledby="dropdownButton">
                                        <li>
                                            <Link to="/setting" className="pl-4 pr-4">User Settings</Link>
                                        </li>
                                        <li>
                                            <Link to="/hubselect" className="pl-4 pr-4">Hub Select</Link>
                                        </li>
                                    </ul>
                                </div>

                            </li> */}
                            <li className={location.pathname === "/privacy" ? "activeNav" : ""}>
                                <Link to="/privacy">
                                    <span className="navIcon disable_icon">
                                        <img src={privacyinactive} alt="Privacy Inactive" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={privacyactive} alt="Privacy Active" />
                                    </span>
                                    <span className="navText">{lang?.Privacy}
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                            {userType && userType.role_id === constant.rolesId.producer &&
                                <li className={location.pathname === "/company" ? "activeNav" : ""}>
                                    <Link to="/company">
                                        <span className="navIcon disable_icon">
                                            <img src={companyinactive} alt="Company Inactive" />
                                        </span>
                                        <span className="navIcon enable_icon">
                                            <img src={companyactive} alt="Company Active" />
                                        </span>
                                        <span className="navText">{lang?.Company}
                                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                        </span>
                                    </Link>
                                </li>}

                            <li className={location.pathname === "/password" ? "activeNav" : ""}>
                                <Link to="/password">
                                    <span className="navIcon disable_icon">
                                        <img src={passwordinactive} alt="Password Inactive" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={passwordactive} alt="Password Active" />
                                    </span>
                                    <span className="navText">{lang?.only_password}
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                            <li className={location.pathname === "/blocking" ? "activeNav" : ""}>
                                <Link to="/blocking">
                                    <span className="navIcon disable_icon">
                                        <img src={blockinginactive} alt="Blocking Inactive" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={blockingactive} alt="Blocking Active" />
                                    </span>
                                    <span className="navText">{lang?.Blocking}
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                            {parseInt(userType?.role_id) !== parseInt(constant?.rolesId?.voyagers) &&
                                <li className={location.pathname === "/setting-membership" ? "activeNav" : ""}>
                                    <Link to="/setting-membership">
                                        <span className="navIcon disable_icon">
                                            <img src={membershipinactive} alt="Membership Inactive" />
                                        </span>
                                        <span className="navIcon enable_icon">
                                            <img src={membershipactive} alt="Membership Active" />
                                        </span>
                                        <span className="navText">{lang?.Membership}
                                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                        </span>
                                    </Link>
                                </li>
                            }
                            {
                                nonSubscriptionPage?.role_id != 3 ? (<></>) : (
                                    <li className={location.pathname === "/subscription" ? "activeNav" : ""}>
                                        <Link to="/subscription">
                                            <span className="navIcon disable_icon">
                                                <img src={subscriptioninactive} alt="Membership Inactive" />
                                            </span>
                                            <span className="navIcon enable_icon">
                                                <img src={subscriptionactive} alt="Membership Active" />
                                            </span>
                                            <span className="navText">{lang?.Subscription}
                                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                            </span>
                                        </Link>
                                    </li>

                                )

                            }

                            <li className={location.pathname === "/settingbilling" ? "activeNav" : ""}>
                                <Link to="/settingbilling">
                                    <span className="navIcon disable_icon">
                                        <img src={billinginactive} alt="Billing Inactive" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={billingactive} alt="Billing Active" />
                                    </span>
                                    <span className="navText">{lang?.Billing}
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                            <li className={location.pathname === "/your-data" ? "activeNav" : ""}>
                                <Link to="/your-data">
                                    <span className="navIcon disable_icon">
                                        <img src={yourdatainactive} alt="Your Data Inactive" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={yourdataactive} alt="Your Data Active" />
                                    </span>
                                    <span className="navText">{lang?.Your_Data}
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/FAQ">
                                    <span className="navIcon disable_icon">
                                        <img src={faqinactive} alt="FAQ Inactive" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={faqactive} alt="FAQ Active" />
                                    </span>
                                    <span className="navText">{lang?.FAQ}
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <a href={`${REACT_APP_API.ENV.REACT_APP}/privacy-policy`} target="_blank">
                                    <span className="navIcon disable_icon">
                                        <img src={privacypolicyinactive} alt="Privacy Policy Inactive" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={privacypolicyactive} alt="Privacy Policy Active" />
                                    </span>
                                    <span className="navText">{lang?.Privacy_Policy}
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href={`${REACT_APP_API.ENV.REACT_APP}/terms`} target="_blank">
                                    <span className="navIcon disable_icon">
                                        <img src={termconditioninactive} alt="Terms and Condition Inactive" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={termconditionactive} alt="Terms and Condition Active" />
                                    </span>
                                    <span className="navText">{lang?.Terms_and_Condition}
                                    </span>
                                </a>
                            </li>
                            {/* <li className="navBtn">
                                <button type="button" onClick={() =>{ setCopyLink(!copyLink); setCopied(false);}}>
                                    <span className="navIcon disable_icon">
                                        <img src={copylink} alt="Copy Link" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={copylink} alt="Copy Link" />
                                    </span>
                                    <span className="navText">Invite others to join Alysei
                                    </span>
                                </button>
                                {copyLink && <ul className="py-1 setting-dropdown copyText" aria-labelledby="dropdownButton" >
                                    <li className={`text-center ${copied ? "textGreen" : ""}`} onClick={copyToClipboard}>
                                        {copied ? 'Copied!' : 'Copy URL to Clipboard'}
                                    </li>
                                </ul>}
                            </li> */}
                        </ul>
                    </div>
                    {toggle && <ul className="py-1 setting-dropdown forMobile" aria-labelledby="dropdownButton">
                        <li>
                            <Link to="/hubs" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Hub Settings</Link>
                        </li>
                    </ul>}
                </div>
            </div>
        </div>
    )
}
