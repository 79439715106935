import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const AnimateFromBottom = ({ children, delay = 0 }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
  });

  const [isAnimating, setIsAnimating] = useState(false);
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Check if scrolling down
      if (currentScrollY > lastScrollY.current && inView) {
        setIsAnimating(true); // Trigger animation
      } else if (currentScrollY < lastScrollY.current) {
        setIsAnimating(false); // Stop animation when scrolling up
      }

      lastScrollY.current = currentScrollY; // Update last scroll position
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`animate-from-bottom ${isAnimating ? 'animate' : ''}`}
      style={{ animationDelay: `${delay}s`, opacity: 1 }} // Always visible
    >
      {children}
    </div>
  );
};

export default AnimateFromBottom;
