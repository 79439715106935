import React from 'react';
import { Link } from "react-router-dom";

// Importing images
import whatsappIcon from '../../../assets/landing-page/whatsapp-icon.png';
// import phoneIcon from '../../../assets/landing-page/phone-icon.svg'; // Uncomment if needed

const FloatingBtns = () => {
  return (
    <div className="inline-flex justify-between md:px-12 px-4 items-center fixed md:bottom-24 bottom-5 right-5 md:right-0 z-10">
      <a href="https://api.whatsapp.com/send/?phone=%2B13122864961&text&type=phone_number&app_absent=0">
        <img src={whatsappIcon} alt='WhatsApp' className="w-16" />
      </a>
      {/* 
      <Link to="#">
        <div className="relative">
          <div className="rounded-full flex justify-center items-center bg-royal p-2 absolute -top-px">
            <img src={phoneIcon} alt='Phone' className="w-8" />
          </div>
          <div className="rounded-3xl border border-royal py-1 pl-12 pr-3 bg-white text-royal">
            <p className="text-xs font-semibold">Book a free</p>
            <p className="text-sm font-bold">Consultation</p>
          </div>
        </div>
      </Link>
      */}
    </div>
  );
}

export default FloatingBtns;
