import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PaginatedComponentView from '../../../components/PaginatedComponent/PaginatedComponentView'
import DeleteConfirmation from '../../../components/DeleteConfirmation';
import action_img from '../../../assets/images/action.svg'
import { deleteOffer } from '../modules/marketplace';

export default function OfferManagement({ offer, handleOfferPageClick, myOffersListing,lang }) {

    const [loader, setLoader] = useState(false);
    const [offerModal, setOffer] = useState({ id: '', isDelete: false });
    const [offerMsg, setOfferMsg] = useState({ message: '', isSuccess: false });

    const handleDelete = (id) => {
        id && setOffer({ id, isDelete: true });
    }

    const handleCancelDelete = () => {
        setOffer({ id: '', isDelete: false });
    }

    const handleDetleteOffer = async () => {
        setLoader(true);
        const response = await deleteOffer(offerModal?.id);
        if (response?.success === 200) {
            myOffersListing(offer?.current_page)
            setLoader(false);
            setOfferMsg({ message: response?.message, isSuccess: true });
            handleCancelDelete();
        } else {
            setOfferMsg({ message: response?.errors?.exception ?? lang?.SomeThingsWrong, isSuccess: false })
            setLoader(false);
            handleCancelDelete();
        }
        setTimeout(() => {
            setLoader(false);
            setOfferMsg({ message: '', isSuccess: false });
        }, 3000)
    }


    return (
        <>
            <div className="product-title">
                <div className="flex flex-wrap justify-between items-center">
                    <h4 className="mb-0">
                        {lang?.Offer_management}
                    </h4>
                    {offerMsg?.message && <h4 className={`${offerMsg?.isSuccess ? 'textGreen' : "textRed"}`}>{offerMsg?.message}</h4>}
                    <div className="inner-btn-box">
                        <Link
                            to="/marketplace/create-offer"
                            className="btn btn-blue"

                        >
                            <i className="fa fa-plus-circle" aria-hidden="true" ></i>
                            {lang?.Send_offer}
                        </Link>
                        {/* <Link to="#" className="btn btn-red">
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                            Delete
                        </Link> */}
                    </div>

                </div>
            </div>

            <div className="product-list transactionMgmt">
                <div className='recentOrder secBlockGap'>
                    <div className='itemTable itemTableScroll customer__table'>
                        <table>
                            <thead>
                                <tr>
                                    <th>{lang?.Offfer_id}</th>
                                    <th>{lang?.Offer_title}</th>
                                    <th class="customer__name">{lang?.Importer_name}</th>
                                    <th>{lang?.Starting_date}</th>
                                    <th>{lang?.Ending_date}</th>
                                    <th>{lang?.Action}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {offer?.data?.length > 0 ? offer?.data?.map(({ offer_id, offer_title, end_date, status, created_at, get_buyer_info }, i) => {
                                    return (
                                        <tr key={offer_id}>
                                            <td>{offer_id}</td>
                                            <td class="customer__name">{offer_title ?? 'N/A'}</td>
                                            <td class="customer__name">{get_buyer_info?.map(({ company_name }) => company_name)}</td>
                                            <td>{moment(created_at).format("DD/MM/YYYY hh:mm A")}</td>
                                            <td>{moment(end_date).format("DD/MM/YYYY hh:mm A")}</td>
                                            <td className='actionBg'>
                                                <div className='flexBox'>
                                                    <Link to={`/marketplace/offer/${offer_id}`} title="Edit">
                                                        <span className='' title="View">
                                                            <img src={action_img} alt="Action" />
                                                        </span>
                                                    </Link>
                                                    <span className='deleteBlock' title="Delete" onClick={() => !loader && handleDelete(offer_id)}>
                                                        <Link to="#">
                                                            <i className="fa fa-trash-o" aria-hidden="true"></i>

                                                        </Link>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                }) : 
                                <>
                                    <tr>
                                        <td colSpan="6" className='text-center'>
                                            {lang?.No_offer}
                                        </td>
                                    </tr>
                                </>
                                }

                            </tbody>
                        </table>
                    </div>
                    <PaginatedComponentView lastPage={offer?.last_page} handlePageClick={handleOfferPageClick} />
                </div>
            </div>

            {(offerModal?.isDelete && offerModal?.id) && <DeleteConfirmation message={''} loader={loader} onDelete={handleDetleteOffer} btnText={lang?.only_delete} heading={lang?.Confirm_Delete} subHeading={lang?.Confirm_msg} notes={''} onCancel={handleCancelDelete} title={lang?.Offer} />}
        </>

    )
}
