import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Modal from "react-modal";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import userProfile from "../../../assets/images/dummy-profile-pic.jpg";
import { setUploadImageErrors } from "../../profile/modules/profile";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import FormError from "../../../components/Errors/FormError";
import firebase from "../../../config//firebase";
import Cropper from "react-cropper";
import { addPostModel, getUserEditPostData, saveEditPostUserDetails, getUserPostData, deletePostAttachment } from "../modules/post";
import { fetchAllActivityFeed, getActivityPost } from "../../home/modules/HomeModule";
import language from "../../../helpers/language";
import insertTextAtCursor from 'insert-text-at-cursor';
import CustomUseEffect from "../../../components/constants";

export function AddPost(props) {
  const history = useHistory()
  const dispatch = useDispatch();
  const [postText, setPostText] = useState('');
  const [postErrorText, setPostErrorText] = useState('');
  const [privacy, setprivacy] = useState("public");
  const [cmntModal, setCmntModal] = useState(false);
  const [isWritePost, setWritePost] = useState(false);
  const [isEmoji, setIsEmoji] = useState(false);
  const [postImageArr, setPostImageArr] = useState([]);
  const [selectedPostImageData, setSelectedPostImageData] = useState([]);
  const [post_id, setpost_id] = useState('');
  const [fileName, setFileName] = useState('');
  const [targetImageId, setTargetImageId] = useState('');
  const [openModal, setOpenModal] = useState('');
  const [image, setImage] = useState('');
  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState();
  const [lang] = useState(language.getLang());
  const [feedActivityArr, setFeedActivityArr] = useState([]);
  const [discardModal, setDiscardModal] = useState(false);
  const [changeInBody, setChangeInBody] = useState('');
  const [changeInPrivacy, setChangeInPrivacy] = useState("");
  const [changeInAttachment, setChangeInAttachment] = useState("");
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const [postAttachments, setPostAttachments] = useState([]);
  const cursorPosition = 0;

  const { profileData, errImageMessage } = useSelector((state) => state.memberProfileData);
  let { postSpinner, userEditPostDetail, getUserPost } = useSelector((state) => state.postState);
  const { activityPost } = useSelector((state) => state.activityFeedStatus);

  CustomUseEffect(async () => {
    await setpost_id(userEditPostDetail?.activity_action_id)
    await setPostText(userEditPostDetail?.body?.length > 0 ? userEditPostDetail?.body : '');
    await setprivacy(userEditPostDetail?.privacy?.length > 0 ? userEditPostDetail?.privacy : 'public');
    await setCmntModal(props?.isOpenModal ? props.isOpenModal : false);
    await setPostAttachments(
      userEditPostDetail?.attachments?.length > 0
        ? userEditPostDetail?.attachments
        : []
    );
  }, [props.isOpenModal]);

  const onChange = (e) => {
    const value = e.target.value;
    setPostText(value);
    setChangeInBody(value);
  };

  // Start Cropping your Image
  const getCropData = async () => {
    if (typeof cropper !== "undefined") {
      await setCropData(cropper.getCroppedCanvas().toDataURL());
      await postImageArr.push(cropper.getCroppedCanvas().toDataURL());
      await fetch(cropper.getCroppedCanvas().toDataURL())
        .then(res => res.blob()
          .then(async (blob) => {
            const file = await new File([blob], `${fileName}`, { type: "image/png" });
            await selectedPostImageData.push(file);
          })
        );
      await setOpenModal(false);
    }
  };
  const closeModal = async () => {
    await setCropData('');
    await setImage('');
    await setTargetImageId('');
    await setCropData('');
    await setFileName('');
    await setPostImageArr([]);
    await setSelectedPostImageData([]);
    setIsEmoji(false);
    await setOpenModal(false);
  }
  // End of Cropping
  const handleFile = async (event) => {
    // if (event.target.files.length + postImageArr.length > 5) {
    //   dispatch(setUploadImageErrors("Images should be less than 5", event.target.id));
    // } else {
    Object.keys(event.target.files).map((item, i) => {
      if (!event.target.files[item].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
        dispatch(setUploadImageErrors(lang?.imageType, event.target.id));
      } else if (event.target.files[item].size > 2000000) {
        dispatch(setUploadImageErrors(lang?.ImageSize, event.target.id));
      } else {
        setFileName(event.target.files[item].name);
        // setOpenModal(true);
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[item]);
        reader.onload = (e) => {
          postImageArr.push(e.target.result);
          setImage(e.target.result)
        };
        selectedPostImageData.push(event.target.files[item]);
        setChangeInAttachment(event.target.files[item]);
      }
    });
    // }
    setTimeout(() => {
      dispatch(setUploadImageErrors({ msg: "", field: "" }));
    }, 3000);
  };

  const removePostImage = async (index) => {
    if (index > -1) {
      postImageArr.splice(index, 1);
      await setPostImageArr(postImageArr);
      let newFileList = Array.from(selectedPostImageData);
      newFileList.splice(index, 1);
      await setSelectedPostImageData(newFileList);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const model = {
      body: postText?.trim(),
      privacy: privacy,
      action_type: "post",
      attachments: selectedPostImageData,
    };
    if (postText?.trim().length === 0 && selectedPostImageData?.length === 0) {
      setPostErrorText(lang?.enterTextBeforePost)
      // console.log("Please Add Some Text Before Post")
    } else {
      await dispatch(addPostModel(model)).then(async (res) => {
        if (res && res.success === 200) {
          // await dispatch(fetchAllActivityFeed({ postType: 0, page: 1 }));
          firebase.database().ref("post").child(res && res.post_id).set({ likeCount: 0, commentCount: 0, postId: res && res.post_id });
          setCmntModal(false);
          setWritePost(false);
          setPostText("");
          setprivacy("public");
          setFileName('');
          setPostImageArr([]);
          setSelectedPostImageData([]);
          setIsEmoji(false);
          props?.onHandleChange(true);
          props?.onHandleChange(false);
        } else {
          console.log(res?.errors)
          console.log(res, 'res')
        }
      });
    }
    setTimeout(() => {
      setPostErrorText('');
    }, 3000);

  };

  const submitEditUserPost = async (e) => {
    console.log(selectedPostImageData, postAttachments, 'clicked me.')
    e.preventDefault();
    const newUpdatePropsData = await props?.dataUpdate;
    const model = {
      post_id,
      body: postText?.trim(),
      privacy: privacy,
      attachments: selectedPostImageData,
      deleteAttachments: deletedAttachments,
      action_type: "post",
    };
    if (postText?.trim()?.length !== 0 || selectedPostImageData?.length !== 0 || postAttachments?.length !== 0) {
      await dispatch(saveEditPostUserDetails(model)).then(async (res) => {
        console.log(res, "res");
        if (res && res.success === 200) {
          //window.location.reload();
          const update_post = getUserPost?.data?.map((item) =>
            item.activity_action_id == post_id
              ? {
                ...item,
                body: postText,
                privacy: privacy,
                attachment_count: res.post.attachment_count,
                attachments: res.post.attachments,
              }
              : item
          );
          if (update_post?.length > 0) {
            const updateUserPost = {
              ...getUserPost,
              data: getUserPost["data"] = update_post
            }
            await dispatch(getUserPostData(updateUserPost));
          }
          await dispatch(getUserEditPostData([]));
          const newUpdateData = newUpdatePropsData?.data.map((item) =>
            item.activity_action_id == post_id
              ? {
                ...item,
                body: postText,
                privacy: privacy,
                attachment_count: res.post.attachment_count,
                attachments: res.post.attachments,
              }
              : item
          );
          if (newUpdateData?.length > 0) {
            const updateActivePost = {
              ...newUpdatePropsData,
              data: newUpdatePropsData["data"] = newUpdateData
            }
            props?.onHandleChange(false, updateActivePost);
          }
          props?.onHandleChange(false);
          setCmntModal(false);
          setWritePost(false);
          setIsEmoji(false);
          setPostText("");
          setprivacy("public");
          setPostImageArr([]);
          setSelectedPostImageData([]);
          setChangeInBody('');
          setChangeInPrivacy('');
          setChangeInAttachment('');
          setDeletedAttachments([]);
        }
      });
    }

  };

  const handleModal = async () => {
    if (
      changeInBody != "" ||
      changeInPrivacy != "" ||
      changeInAttachment != "" ||
      deletedAttachments.length > 0
    ) {
      setDiscardModal(true);
    }
    else {
      await setCmntModal(false);
      await setPostText("");
      await setprivacy("");
      await setFileName("");
      await dispatch(getUserEditPostData([]));
      await setPostImageArr([]);
      await setSelectedPostImageData([]);
      setIsEmoji(false);
      props && props.onHandleChange && props.onHandleChange(false);
    }
  }

  const onEmojiClick = async (event, emojiObject) => {
    var ctl = document.getElementById('write_post');
    insertTextAtCursor(ctl, emojiObject?.emoji);
    var values = document.getElementById('write_post').value;
    //console.log(values,"msgmsg");
    await setPostText(values);
  };

  function setCaretPosition(ctrl, pos) {
    // Modern browsers
    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(pos, pos);

      // IE8 and below
    } else if (ctrl.createTextRange) {
      var range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  }

  const insert = (str, index, value) => {
    return str.substr(0, index) + value + str.substr(index);
  }

  const removePostAttachment = async (id, index) => {
    if (index > -1) {
      let newAttachmentLists = Array.from(postAttachments);
      newAttachmentLists.splice(index, 1);
      await setPostAttachments(newAttachmentLists);
      await deletedAttachments.push(id);
    }
  }

  // change Privacy
  const changePrivacy = async (value) => {
    setprivacy(value);
    setChangeInPrivacy(value);
  }

  // Discard Change made
  const discardPopupModal = async () => {
    setDiscardModal(false);
    setCmntModal(false);
    setWritePost(false);
    setIsEmoji(false);
    setPostText("");
    setprivacy("public");
    setPostImageArr([]);
    setSelectedPostImageData([]);
    setChangeInBody("");
    setChangeInPrivacy("");
    setChangeInAttachment("");
    setDeletedAttachments([]);
    setIsEmoji(false);
    props && props.onHandleChange && props.onHandleChange(false);
  }
  
  return (
    <Fragment>
      {/* image cropper */}
      {openModal && (
        <Modal
          className=""
          ariaHideApp={false}
          isOpen={openModal}
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            },
            content: {
              position: "absolute",
              top: "0",
              left: "0%",
              right: "0%",
              bottom: "200px",
              border: "1px solid #ccc",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              borderRadius: "4px",
              padding: "0px",
              // outline: 'none',
            },
          }}
        >
          <div className="popup_header flex justify-between items-center">
            <h4 className="page__title">{lang?.Crop_image}</h4>
            <button className="close_btn--modal" onClick={() => closeModal()}>
              {" "}
              {lang?.close}
            </button>
          </div>
          <div className="card card--block modal_box " >
            <div className="crop_img_block">
              <Cropper
                style={{ height: 400, width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
            <div className="form__action form__item mt-4">
              <button onClick={() => getCropData()}>
                {" "}
                {lang?.Crop_image}{" "}
              </button>
            </div>
          </div>
        </Modal>
      )}
      {/* image cropper end */}

      {/* Middle Block */}
      <div className="card card--block lg:mb-2 md:mb-2 sm:mb-2" style={{ background: "#000 !important" }}>
        <div className="post--form">
          <div className="feed-user-info home_page">
            <div className="user__pic">
              <img
                src={
                  profileData &&
                    profileData.data &&
                    profileData.data.user_data.avatar_id !== null
                    ? `${profileData &&
                    profileData.data &&
                    profileData.data.user_data.avatar_id.base_url
                    }${profileData &&
                    profileData.data.user_data.avatar_id.attachment_medium_url
                    }`
                    : userProfile
                }
                alt="Profile Image"
              />
            </div>
            <form
              className="feed-post-form share_post"
            // onSubmit={(e) => submitHandler(e)}
            >
              <div onClick={() => setCmntModal(!cmntModal)}>
                <textarea
                  className="write_post-area"
                  placeholder="What's new?"
                  readonly=""
                />
              </div>
              <div className="upload_img inner__file__upload">
                <i className="fas fa-image pr-2" />

                <label
                  htmlFor="img_upload"
                  onClick={() => setCmntModal(!cmntModal)}
                >
                  {lang?.Add_photos}
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Add Post Popup */}
      {cmntModal && (
        <div>
          <Modal
            className="post_cmnt"
            ariaHideApp={false}
            isOpen={cmntModal}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title">{lang?.Whats_new}</h4>
              <button
                className="close_btn--modal"
                onClick={() => handleModal()}
              >
                {lang?.close}
              </button>
            </div>
            <p className="text-center text-red">{postErrorText}</p>
            <div className="card card--block modal_box">
              <div className="feed-user-info home_page mb-3">
                <div className="user__pic">
                  <img
                    src={
                      profileData?.data?.user_data?.avatar_id !== null
                        ? `${profileData?.data?.user_data?.avatar_id?.base_url}${profileData?.data?.user_data?.avatar_id?.attachment_medium_url}`
                        : `${userProfile}`
                    }
                    alt="Profile Image"
                  />
                </div>

                <div className="user__detail-data pl-2">
                  <span className="user__name">
                    <strong>
                      {profileData?.data?.user_data?.company_name ??
                        profileData?.data?.user_data?.restaurant_name}
                    </strong>
                  </span>
                  <span>
                    <div className="form__item postDrop">
                      <select
                        value={privacy}
                        onChange={(event) => changePrivacy(event.target.value)}
                      >
                        <option value="Public">{lang?.Public}</option>
                        <option value="Only Me">{lang?.only_me}</option>
                        <option value="Followers">{lang?.Followers}</option>
                        <option value="Connections">{lang?.Connections}</option>
                      </select>
                    </div>
                  </span>
                </div>
              </div>
              <form className="feed-post-form share_post">
                <textarea
                  className="write_post-area"
                  placeholder={lang?.Enter_your_text}
                  rows="2"
                  id="write_post"
                  value={postText}
                  onChange={(e) => {
                    onChange(e);
                  }}
                ></textarea>
                <div className="post-icons-option">
                  {" "}
                  <Link to="#" onClick={() => setIsEmoji(!isEmoji)}>
                    <i className="far fa-smile" />{" "}
                  </Link>
                </div>
                {/* <div>{isEmoji && <Picker onEmojiClick={onEmojiClick} skinTone={SKIN_TONE_MEDIUM_DARK}/>} </div> */}
                <div className="w-full">
                  <ul className="gallery__images flex">
                    {postAttachments &&
                      postAttachments.length > 0 && postAttachments?.map((item, index) => {
                        return (
                          <li key={index}>
                            <img
                              src={`${item?.attachment_link?.base_url}${item?.attachment_link?.attachment_url}`}
                            />
                            <div className="remove-image">
                              <Link
                                to="#"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  removePostAttachment(
                                    item.activity_attachment_id, index
                                  )
                                }
                              >
                                {" "}
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                                { }
                              </Link>
                            </div>
                          </li>
                        );
                      })}
                    {postImageArr &&
                      postImageArr.length > 0 &&
                      postImageArr.map((src, i) => {
                        if (i <= 3) {
                          return (
                            <li key={i}>
                              <div className="share_post_img">
                                <div className="remove-image">
                                  <Link
                                    to="#"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => removePostImage(i)}
                                  >
                                    {" "}
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </div>
                                <img src={src} key={i} />
                                {postImageArr.length > 4 && (
                                  <div className="show_all_img">
                                    <div className="show_all_icon">
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                      ></i>
                                      <span className="img_count_no">
                                        {postImageArr.length - 4}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </li>
                          );
                        }
                      })}
                  </ul>
                </div>
                <div className="upload_img">
                  <label htmlFor="img_upload">
                    <i className="fas fa-image pr-2" /> {lang?.Add_photos}
                    <input
                      type="file"
                      id="img-upload"
                      accept="image/*"
                      multiple
                      value={""}
                      onChange={(event) => handleFile(event)}
                    />{" "}
                  </label>
                </div>
              </form>
              <div>
                {isEmoji && (
                  <Picker
                    onEmojiClick={onEmojiClick}
                    skinTone={SKIN_TONE_MEDIUM_DARK}
                  />
                )}{" "}
              </div>
              {errImageMessage.field === "img-upload" ? (
                <FormError msg={errImageMessage.msg} />
              ) : null}
              {/* inner form end */}
              <div className="btn_blue_full_w">
                {/* {(postText.length > 0) &&  */}
                <button
                  type="button"
                  className="btn"
                  onClick={(e) =>
                    props?.isOpenModal
                      ? submitEditUserPost(e)
                      : submitHandler(e)
                  }
                  disabled={postSpinner}
                >
                  {postSpinner ? (
                    <FormSpinner />
                  ) : props?.isOpenModal ? (
                    `${lang?.Update} ${lang?.only_Post}`
                  ) : (
                    `${lang?.only_Post}`
                  )}
                </button>
                {/* } */}
              </div>
            </div>
          </Modal>
        </div>
      )}

      {/* Discard Modal If any change in post and close the modal without update then it will appeared */}
      {discardModal && (
        <Modal
          isOpen={discardModal}
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            },
            content: {
              position: "absolute",
              top: "0",
              left: "0%",
              right: "0%",
              bottom: "200px",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              padding: "0",
              border: "0",
              borderRadius: "8px",
              // outline: 'none',
            },
          }}
        >
          <div className="popup_header flex justify-between items-center popupheaderBg">
            <h4 className="page__title">{lang?.discard}</h4>
            <button
              className="close_btn--modal"
              onClick={() => setDiscardModal(!discardModal)}
            >
              {lang?.close}
            </button>
          </div>
          <div className="card card--block modal_box">
            <p className="warning">{lang?.discard_post}</p>
            <div className="right__btn text-right mt-4">
              <Link
                className="btn btn1"
                to="#"
                onClick={() => discardPopupModal()}
              >
                {lang?.discard}
              </Link>
              <Link
                className="btn btn1 btn2 ml-3"
                to="#"
                onClick={() => setDiscardModal(!discardModal)}
              >
                {lang?.continue_editing}
              </Link>
            </div>
          </div>
        </Modal>
      )}
    </Fragment>
  );
}
