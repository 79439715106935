import React, { Fragment, useMemo } from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import auth from "../../../helpers/auth";
import Carousel from "react-grid-carousel";
import {
  fetchCuisine,
  fetchFoodIntrolerence,
  fetchDiet,
  fetchCookingSkill,
  fetchIngredients,
  tabIndexFun,
  fetchPreferences,
  submitSelectPreferencesData,
} from "../modules/RecipeModule";
import { useDispatch, useSelector } from "react-redux";
import imgs2 from "../../../assets/images/recipe-banner-img.jpg";
import img1 from "../../../assets/images/recipe-img1.png"
import { Link } from "react-router-dom";
import language from "../../../helpers/language";
import CustomUseEffect from "../../../components/constants";


export default function RecipeSlider(props) {
  const [lang] = useState(language.getLang());
  const [searchRecipe, setSearchRecipe] = useState()
  const [isNextBtn, setNextBtn] = useState(false);
  const [isBackBtn, setBackBtn] = useState(false);
  const [cooksId, setCooksId] = useState([])
  const [isSaveBtn, setSaveBtn] = useState(false);
  const [dietCount, setDietCount] = useState(0);
  const [foodCount, setFoodCount] = useState(0);
  const [ingredentCount, setIngredientCount] = useState(0);
  const [tempId, setTempId] = useState(0);
  const [ischecked, setChecked] = useState(false);
  const [isCuisine, setCuisine] = useState(true);
  const [isFood, setFood] = useState(false);
  const [isDiet, setDiet] = useState(false);
  const [isCookSkill, setCookskill] = useState(false);
  const [isIngredients, setIngredients] = useState(false);
  const [title, setTitle] = useState(lang?.what_is_your_favorite_cuisines);
  const [cuisineId, setCuisineId] = useState([]);
  const [foodId, setFoodId] = useState([]);
  const [dietsId, setDietsId] = useState([]);
  const [ingredientsId, setIngredientsId] = useState([]);
  const [cuisineData, setCuisineData] = useState([]);
  const [foodData, setFoodData] = useState([]);
  const [selectAllData, setSelectAlleData] = useState([]);
  const [selectDietsData, setSelectDietsData] = useState([]);
  const [selectInredientsData, setSelectInredientsData] = useState([]);
  const [selectCookSkill, setSelectCookSkill] = useState([]);
  const [searchIngredient, setSearchIngredient] = useState()
  const [searchPreferencesData, setSearchPreferencesData] = useState([])
  const [name, setName] = useState('')
  const [havingPreferences, setHavingPreferences] = useState(0)
  const { activityPost } = useSelector((state) => state.activityFeedStatus);
  const history = useHistory()
  const {
    cuisine,
    foodintrolerance,
    recipeDiet,
    cookingskill,
    recipeIngredientData,
    recipePreferenceIngredient,
  } = useSelector((state) => state.recipedata);
  const dispatch = useDispatch();

  CustomUseEffect(async () => {
    let token = auth.getAccessToken();
    await dispatch(fetchFoodIntrolerence(token));
    await dispatch(fetchCuisine(token));
    await dispatch(fetchDiet(token));
    await dispatch(fetchCookingSkill(token));
    await dispatch(fetchIngredients(token));
    await isPreference();
  }, [tempId]);

  useEffect(() => { history.push('/recipes') }, [tempId])
  useEffect(() => { setHavingPreferences(parseInt(localStorage.getItem("having_preferences"))) }, [localStorage.getItem("having_preferences")])

  const isPreference = async () => {
    if (havingPreferences === 1) {
      setIngredients(false);
      setCuisine(false);
      setFood(false);
      setDiet(false);
      setCookskill(false);
      setNextBtn(false);
      setNextBtn(false);
      setSaveBtn(false);
      setBackBtn(false);
      setTitle("");
    }

  }
  const nextSteps = async () => {
    if (isCuisine === true) {
      setBackBtn(true);
      setCuisine(false);
      setFood(true);
      setDiet(false);
      setIngredients(false);
      setNextBtn(true);
      await setSelectAlleData((old) => [...old, cuisineData]);
      setTitle(lang?.Do_you_have_any_food_allergies);
    } else if (isFood === true) {
      // setCuisine(false);
      setFood(false);
      setDiet(true);
      setIngredients(false);
      setTitle(lang?.Do_you_follow_these_diets);
      await setSelectAlleData((old) => [...old, foodData]);
    } else if (isDiet === true) {
      // setCuisine(false);
      setFood(false);
      setDiet(false);
      setIngredients(true);
      setTitle(lang?.Do_not_want_to_see_ingredients);
      await setSelectAlleData((old) => [...old, selectDietsData]);
    } else if (isIngredients === true) {
      setIngredients(false);
      // setCuisine(false);
      setFood(false);
      setDiet(false);
      setCookskill(true);
      setNextBtn(false);
      setSaveBtn(true);
      await setSelectAlleData((old) => [...old, selectInredientsData]);
    } else if (isCookSkill === true) {
      setIngredients(false);
      // setCuisine(false);
      setFood(false);
      setDiet(false);
      setCookskill(false);
      await setSelectAlleData((old) => [...old, selectInredientsData]);
    }
  };
  const backStep = () => {
    if (isFood === true) {
      setBackBtn(false);
      setIngredients(false);
      setCuisine(true);
      setFood(false);
      setDiet(false);
      setCookskill(false);
      setSaveBtn(false);
      setTitle(lang?.what_is_your_favorite_cuisines);
      // setNextBtn(true);
    } else if (isDiet === true) {
      setIngredients(false);
      setCuisine(false);
      setFood(true);
      setDiet(false);
      setCookskill(false);
      setNextBtn(true);
      setSaveBtn(false);
      setTitle(lang?.Do_you_have_any_food_allergies);
    } else if (isIngredients === true) {
      setIngredients(false);
      setCuisine(false);
      setFood(false);
      setDiet(true);
      setCookskill(false);
      setNextBtn(true);
      setSaveBtn(false);
      setTitle(lang?.Do_you_follow_these_diets);
    } else if (isCookSkill === true) {
      setIngredients(true);
      setCuisine(false);
      setFood(false);
      setDiet(false);
      setCookskill(false);
      setNextBtn(false);
      setNextBtn(true);
      setSaveBtn(false);
    }
  };

  const checkedRecipe = async (e) => {
    setChecked(!ischecked);
    let name = e.target.name;
    // setSearchPreferencesData()
    if (e.target.checked) {
      if (e.target.name === "cuisine") {
        cuisineId.push(parseInt(e.target.id));
        const cousineObj = {
          preference: 1,
          id: cuisineId,
        };
        await setCuisineData(cousineObj);
        await setCuisineId(cuisineId);
      } else if (e.target.name === "food_allergie") {
        foodId.push(parseInt(e.target.id));
        const foodObj = {
          preference: 2,
          id: foodId,
        };
        await setFoodData(foodObj);
        await setFoodId(foodId);
      } else if (e.target.name === "diets") {
        dietsId.push(parseInt(e.target.id));
        const dietsObj = {
          preference: 3,
          id: dietsId,
        };
        await setSelectDietsData(dietsObj);
      } else if (e.target.name === "ingredients") {
        ingredientsId.push(parseInt(e.target.id));
        const ingredietsObj = {
          preference: 4,
          id: ingredientsId,
        };
        await setSelectInredientsData(ingredietsObj);
        await setIngredientsId(ingredientsId);
      } else if (e.target.name === "cooking_skill") {
        // await setName(e.target.value)
        await cooksId.splice(0, 1, parseInt(e.target.id))
        let arr = [];
        arr = parseInt(e.target.id)
        const cookSkillsObj = {
          preference: 5,
          id: cooksId
        };
        await setSelectCookSkill(cookSkillsObj);
      }
    } else {
      if (e.target.name === "cuisine") {
        const index = cuisineId.indexOf(parseInt(e.target.id));
        if (index > -1) {
          cuisineId.splice(index, 1);
          setCuisineId(cuisineId);
        }
      } else if (e.target.name === "food_allergie") {
        const index = foodId.indexOf(parseInt(e.target.id));
        if (index > -1) {
          foodId.splice(index, 1);
        }
        setFoodId(foodId);
      } else if (e.target.name === "diets") {
        const index = dietsId.indexOf(parseInt(e.target.id));
        if (index > -1) {
          dietsId.splice(index, 1);
        }
        setDietsId(dietsId);
      } else if (e.target.name === "ingredients") {
        const index = ingredientsId.indexOf(parseInt(e.target.id));
        if (index > -1) {
          ingredientsId.splice(index, 1);
        }
        setIngredientsId(ingredientsId);
      } else if (e.target.name === "cooking_skill") {
        await setName('')
        const index = cooksId.indexOf(parseInt(e.target.id));
        if (index > -1) {
          cooksId.splice(index, 1);
        }
        setCooksId(cooksId);
      }
    }
    if (e.target.checked && isCuisine === true) {
      setNextBtn(true);
    } else {
      (e.target.name === "cuisine") && setNextBtn(false);
    }
  };

  const saveRecipeData = async () => {
    await localStorage.setItem("having_preferences", 1)
    await selectAllData.push(selectCookSkill);
    setIngredients(false);
    setCuisine(false);
    setFood(false);
    setDiet(false);
    setCookskill(false);
    setNextBtn(false);
    setSaveBtn(false);
    setBackBtn(false);
    setTitle("");
    const data = { params: selectAllData };
    await submitSelectPreferencesData({ params: selectAllData }).then(async (res) => {
      if (res && res.success === 200) {
        setTempId(tempId + 1)
        await props.preferencesFun()
        await dispatch(fetchPreferences()).then(async (res) => {
          if (res && res.success === 200) {
            await dispatch(tabIndexFun(3))
          }
        })
      }
    })
  };
  const filterRecipeFun = (e) => {
    e.preventDefault();
    if (searchRecipe != undefined) {
      history.push({ pathname: `/recipe/search/${searchRecipe}/universaltitle` })
    }

  }

  const preferencesIngredientSearch = async (e) => {
    const findIng = e.target.value;
    if (findIng !== '' || findIng !== undefined) {
      const results = recipePreferenceIngredient.maps.filter(item => item.title?.toLowerCase()?.includes(findIng.trim()?.toLowerCase()));
      setSearchPreferencesData(results);
    } else {
      setSearchPreferencesData();
    }
  }


  return (
    <Fragment>
      <div className="site__banner recipeSlider">
        <div className={havingPreferences === 1 ? "banner__image withoutSlider recipeSlider" : "banner__image recipeSlider"}>
          <img src={imgs2} alt="Banner Image" />
        </div>
        <div className="banner__content">
          <div className="site__width text-center">
            <div className="banner__search-form">
              <h4>{lang?.What_are_you_craving}</h4>
              <form onSubmit={(e) => { filterRecipeFun(e) }}>
                <div className="top-search">
                  <span className="search_icon">
                    <i className="fas fa-search" />
                  </span>
                  <input type="text" placeholder={lang?.Search_for_recipes} value={searchRecipe} onChange={(e) => setSearchRecipe(e.target.value)} />
                  {/* <span className="filter_icon">
                    <Link to='#' onClick={(e) => { filterRecipeFun(e) }}>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 0C9.70703 0 8.60547 0.84375 8.1875 2H0V4H8.1875C8.60547 5.15625 9.70703 6 11 6C12.293 6 13.3945 5.15625 13.8125 4H24V2H13.8125C13.3945 0.84375 12.293 0 11 0ZM11 2C11.5625 2 12 2.4375 12 3C12 3.5625 11.5625 4 11 4C10.4375 4 10 3.5625 10 3C10 2.4375 10.4375 2 11 2ZM18 8C16.707 8 15.6055 8.84375 15.1875 10H0V12H15.1875C15.6055 13.1562 16.707 14 18 14C19.293 14 20.3945 13.1562 20.8125 12H24V10H20.8125C20.3945 8.84375 19.293 8 18 8ZM18 10C18.5625 10 19 10.4375 19 11C19 11.5625 18.5625 12 18 12C17.4375 12 17 11.5625 17 11C17 10.4375 17.4375 10 18 10ZM7 16C5.70703 16 4.60547 16.8438 4.1875 18H0V20H4.1875C4.60547 21.1562 5.70703 22 7 22C8.29297 22 9.39453 21.1562 9.8125 20H24V18H9.8125C9.39453 16.8438 8.29297 16 7 16ZM7 18C7.5625 18 8 18.4375 8 19C8 19.5625 7.5625 20 7 20C6.4375 20 6 19.5625 6 19C6 18.4375 6.4375 18 7 18Z"
                          fill="#5B5B5B"
                        />
                      </svg>
                    </Link>
                  </span> */}
                </div>
              </form>
              {/* <div className="create_recipe-btn">
                        <a href="javascript:script(0)"><img src={hat} />Create Recipe</a>
                      </div> */}
            </div>
            <div className="banner_text ">
              <div className="banner_flex">
                <div className="banner_text_wrapper">
                  {
                    havingPreferences === 1 ? '' : <Fragment>
                      <div className="banner_small-title">
                        <p className="text-white">{lang?.Personalize_your_experience}</p>
                      </div>
                      <div className="banner-title">
                        <p className="text-white lg:text-white xl:text-white md:text-white sm:text-white">{title}</p>
                      </div>
                    </Fragment>
                  }

                </div>
                {/* <div className="create_recipe-btn">  { havingPreferences === 1 && <Link to="/recipes/create-recipe"> <img src={hat} /> Create Recipe </Link> } </div> */}
              </div>
            </div>
            {
              havingPreferences === 1 ? '' :
                <Fragment>
                  <div className="banner_slider page__banner slider responsive">
                    <ul>
                      {isCuisine && cuisine &&
                        cuisine.length > 0 &&
                        cuisine.map((item) => {
                          return (
                            <li>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="cuisine"
                                defaultChecked={parseInt(cuisineData && cuisineData.id && cuisineData.id[0]) === 1 ? true : false}
                                value={item.name}
                                onChange={(e) => {
                                  checkedRecipe(e);
                                }}
                                id={item.cousin_id}
                              />

                              <label
                                className="form-check-label"
                                htmlFor={item.cousin_id}
                              >
                                <div className="slider_img">
                                  {/* <img src={`${item && item.image_id && item.image_id.base_url}${item.image_id.attachment_url}`}
                                  /> */}
                                  <img src={img1} alt="Cusion Image" />
                                </div>
                                <div className="checked_bg">
                                  {" "}
                                </div>
                                <div className='banner__title'>
                                  <span>{item.name}</span>
                                </div>
                              </label>
                            </li>
                          );
                        })
                      }
                      {isFood && foodintrolerance?.data?.length > 0 &&
                        <Carousel cols={5} rows={1} gap={1} loop
                        responsiveLayout={[
                          {
                            breakpoint: 991,
                            cols: 3,
                          },
                          {
                            breakpoint: 767,
                            cols: 2,
                          },
                          {
                            breakpoint: 500,
                            cols: 3,
                          },
                        ]}
                        mobileBreakpoint={670}
                          // hideArrow={foodintrolerance?.data?.length <= 5 ? true : false}
                        >
                          {foodintrolerance &&
                            foodintrolerance.data.length > 0 &&
                            foodintrolerance.data.map((item) => {
                              const result = foodId && foodId.filter((res) => parseInt(res) === item.recipe_food_intolerance_id)
                              return (
                                <Carousel.Item>
                                  <li>
                                    {
                                      parseInt(result && result[0]) === item.recipe_food_intolerance_id ?
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="food_allergie"
                                          value={item.name}
                                          defaultChecked
                                          id={item.recipe_food_intolerance_id}
                                          onChange={(e) => {
                                            checkedRecipe(e);
                                            setFoodCount(foodCount - 1);
                                          }}
                                        /> : <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="food_allergie"
                                          value={item.name}
                                          id={item.recipe_food_intolerance_id}
                                          onChange={(e) => {
                                            checkedRecipe(e);
                                            setFoodCount(foodCount + 1);
                                          }}
                                        />
                                    }

                                    <label
                                      className="form-check-label"
                                      htmlFor={item.recipe_food_intolerance_id}
                                    >
                                      <div className="slider_img">
                                        <img
                                          src={`${item.image_id.base_url}${item.image_id.attachment_url}`}
                                        />
                                      </div>
                                      <div className="checked_bg"> </div>
                                      <div className="banner__title">
                                        <span>{item.name}</span>
                                      </div>
                                    </label>
                                  </li>
                                </Carousel.Item>
                              );
                            })}
                        </Carousel>}


                      {
                        isDiet && recipeDiet && recipeDiet.data?.length > 0 && <Carousel cols={5} rows={1} gap={1} loop
                          hideArrow={recipeDiet.data?.length <= 5 ? true : false}
                        >
                          {recipeDiet &&
                            recipeDiet.data.length > 0 &&
                            recipeDiet.data.map((item) => {
                              const result = dietsId && dietsId.filter((res) => parseInt(res) === item.recipe_diet_id)
                              return (
                                <Carousel.Item>
                                  <li>
                                    {
                                      parseInt(result && result[0]) === item.recipe_diet_id ?
                                        <input
                                          className="form-check-input"
                                          name="diets"
                                          type="checkbox"
                                          value={item.name}
                                          defaultChecked
                                          id={item.recipe_diet_id}
                                          onChange={(e) => {
                                            checkedRecipe(e);
                                            setDietCount(dietCount - 1)
                                          }}
                                        /> : <input
                                          className="form-check-input"
                                          name="diets"
                                          type="checkbox"
                                          value={item.name}
                                          id={item.recipe_diet_id}
                                          onChange={(e) => {
                                            checkedRecipe(e);
                                            setDietCount(dietCount + 1)
                                          }}
                                        />
                                    }

                                    <label
                                      className="form-check-label"
                                      htmlFor={item.recipe_diet_id}
                                    >
                                      <div className="slider_img">
                                        <img
                                          src={`${item.image_id.base_url}${item.image_id.attachment_url}`}
                                        />
                                      </div>
                                      <div className="checked_bg"></div>
                                      <div className="banner__title">
                                        <span>{item.name}</span>
                                      </div>
                                    </label>
                                  </li>
                                </Carousel.Item>
                              );
                            })}
                        </Carousel>}
                      {/* {console.log(recipeIngredientData,'recipePreferenceIngredient')} */}
                      {isIngredients &&
                        <Carousel cols={5} rows={1} gap={1} loop hideArrow={recipePreferenceIngredient?.maps?.length <= 5 ? true : false} >
                          {recipePreferenceIngredient?.maps.length > 0 &&
                            recipePreferenceIngredient?.maps?.map((item, index) => {
                              console.log("first clicked me....");
                              const result = ingredientsId?.filter((res) => parseInt(res) === item.recipe_ingredient_id)
                              return (
                                <Carousel.Item>
                                  {
                                    <li>
                                      {parseInt(result && result[0]) === item.recipe_ingredient_id ?
                                        <input
                                          className="form-check-input"
                                          name="ingredients"
                                          value={item.title}
                                          type="checkbox"
                                          defaultChecked
                                          id={item.recipe_ingredient_id}
                                          onChange={(e) => {
                                            checkedRecipe(e);
                                            setIngredientCount(ingredentCount - 1)
                                          }}
                                        /> :
                                        <input
                                          className="form-check-input"
                                          name="ingredients"
                                          value={item.title}
                                          type="checkbox"
                                          id={item.recipe_ingredient_id}
                                          onChange={(e) => {
                                            checkedRecipe(e);
                                            setIngredientCount(ingredentCount + 1)
                                          }}
                                        />
                                      }
                                      <label
                                        className="form-check-label"
                                        htmlFor={item.recipe_ingredient_id}
                                      >
                                        <div className="slider_img">
                                          <img
                                            src={`${item.image_id.base_url}${item.image_id.attachment_url}`}
                                          />
                                        </div>
                                        <div className="checked_bg"> </div>
                                        <div className="banner__title">
                                          <span>{item.title} </span>
                                        </div>
                                      </label>
                                    </li>
                                  }

                                </Carousel.Item>
                              );
                            })}
                        </Carousel>
                      }
                      {/* {isIngredients &&
                        <Carousel cols={5} rows={1} gap={1} loop>
                          {recipeIngredientData &&
                            recipeIngredientData.data.length > 0 &&
                            recipeIngredientData.data.map((item, index) => {
                              const result = ingredientsId && ingredientsId.filter((res) => parseInt(res) === item.recipe_ingredient_id)
                              return (
                                <Carousel.Item>
                                  {
                                    <li>
                                      {parseInt(result && result[0]) === item.recipe_ingredient_id ?
                                        <input
                                          className="form-check-input"
                                          name="ingredients"
                                          value={item.title}
                                          type="checkbox"
                                          defaultChecked
                                          id={item.recipe_ingredient_id}
                                          onChange={(e) => {
                                            checkedRecipe(e);
                                            setIngredientCount(ingredentCount - 1)
                                          }}
                                        /> :
                                        <input
                                          className="form-check-input"
                                          name="ingredients"
                                          value={item.title}
                                          type="checkbox"
                                          id={item.recipe_ingredient_id}
                                          onChange={(e) => {
                                            checkedRecipe(e);
                                            setIngredientCount(ingredentCount + 1)
                                          }}
                                        />
                                      }
                                      <label
                                        className="form-check-label"
                                        htmlFor={item.recipe_ingredient_id}
                                      >
                                        <div className="slider_img">
                                          <img
                                            src={`${item.image_id.base_url}${item.image_id.attachment_url}`}
                                          />
                                        </div>
                                        <div className="checked_bg"> </div>
                                        <div className="banner__title">
                                          <span>{item.title} </span>
                                        </div>
                                      </label>
                                    </li>
                                  }

                                </Carousel.Item>
                              );
                            })}
                        </Carousel>
                      } */}
                      {isCookSkill && cookingskill?.data?.length > 0 &&
                        cookingskill.data.map((item) => {
                          const result = cooksId && cooksId.filter((res) => parseInt(res) === item.recipe_cooking_skill_id)
                          return (
                            <li>
                              {
                                <input
                                  className="form-check-input"
                                  name="cooking_skill"
                                  value={item.name}
                                  type="checkbox"
                                  checked={item.name === name ? 'true' : ''}
                                  id={item.recipe_cooking_skill_id}
                                  onChange={(e) => {
                                    checkedRecipe(e); setName(e.target.value);
                                  }}
                                />
                              }
                              <label
                                className="form-check-label"
                                htmlFor={item.recipe_cooking_skill_id}
                              >
                                <div className="slider_img">
                                  <img
                                    src={`${item.image_id.base_url}${item.image_id.attachment_url}`}
                                  />
                                </div>
                                <div className="checked_bg"> </div>
                                <div className="banner__title">
                                  <span>{item.name}</span>
                                </div>
                              </label>
                            </li>
                          );
                        })
                      }
                    </ul>
                    <div className="navigation_btns">
                      <span className="nav_btn prev_btn" />
                      <span className="nav_btn next_btn" />
                    </div>
                  </div>
                </Fragment>
            }
          </div>
        </div>
      </div>
      <div className={havingPreferences === 0 ? "CenterBtn" : ""}>
        {isBackBtn && <Link to='#' className="btn back" onClick={() => backStep()}> {lang?.Back} </Link>}
        {isNextBtn && isCuisine && <Link to="#" className={isNextBtn ? "btn next" : ""} onClick={() => { nextSteps(); }} >{lang?.Next}</Link>}
        {isNextBtn && isFood && <Link to="#" className={isNextBtn ? "btn next" : ""} onClick={() => { nextSteps(); }} >{foodCount !== 0 ? lang?.Next : lang?.Skip}</Link>}
        {isNextBtn && isDiet && <Link to="#" className={isNextBtn ? "btn next" : ""} onClick={() => { nextSteps(); }} >{dietCount !== 0 ? lang?.Next : lang?.Skip}</Link>}
        {isNextBtn && isIngredients && <Link to="#" className={isNextBtn ? "btn next" : ""} onClick={() => { nextSteps(); }} >{ingredentCount !== 0 ? lang?.Next : lang?.Skip}</Link>}
        {isSaveBtn ? (<Link to='#' className="btn next" onClick={() => { saveRecipeData(); }} > {lang?.Save} </Link>) : ("")}
      </div>
      <div >
        {isIngredients &&
          <div className="banner_slider page__banner slider searchIngredientData">
            <div className="form__item mb-0">
              <div className="mySelect">
                <div className="site__width text-center">
                  <div className="banner__search-form">
                    <input type="search" placeholder="Search Ingredients.." value={searchIngredient} onChange={(e) => { preferencesIngredientSearch(e); }} />
                  </div>
                </div>
                <div className="site__width">
                  <ul className="searchPreferences">
                    {
                      searchPreferencesData?.length > 0 && searchPreferencesData?.map((item) => {
                        const result = ingredientsId?.filter((res) => parseInt(res) === item.recipe_ingredient_id);
                        return (
                          <Fragment>
                            <li key={item.recipe_ingredient_id}>
                              {
                                parseInt(result && result[0]) === item.recipe_ingredient_id ?
                                  <input
                                    className="form-check-input"
                                    name="ingredients"
                                    value={item.title}
                                    type="checkbox"
                                    defaultChecked
                                    id={item.recipe_ingredient_id}
                                    onChange={(e) => {
                                      checkedRecipe(e);
                                    }}
                                  /> :
                                  <input
                                    className="form-check-input"
                                    name="ingredients"
                                    value={item.title}
                                    type="checkbox"
                                    id={item.recipe_ingredient_id}
                                    onChange={(e) => {
                                      checkedRecipe(e);
                                    }}
                                  />
                              }
                              <label
                                className="form-check-label"
                                htmlFor={item.recipe_ingredient_id}
                              >
                                <div className="slider_img">
                                  <img
                                    src={`${item.image_id.base_url}${item.image_id.attachment_url}`}
                                  />
                                </div>
                                <div className="checked_bg"> </div>
                                <div className="banner__title">
                                  <span>{item.title}</span>
                                </div>
                              </label>
                            </li>
                          </Fragment>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </Fragment>
  );
}
