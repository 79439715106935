import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import constant from '../../../helpers/constant';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import {
  getUserPrivacy,
  updateEmailPreferences,
  updateUserPrivacy,
} from '../module/UserSetting';
import SettingLeftNavigation from './SettingLeftNavigation';
import language from '../../../helpers/language';
import { getAllHubsListing } from '../../marketplace/modules/marketplace';
import { debounce } from 'lodash';
import CustomUseEffect from '../../../components/constants';

export default function Privacy() {
  const dispatch = useDispatch();
  const [whoCanSendtMsg, setWhoCanSendtMsg] = useState();
  const [whoCanViewProfile, setWhoCanViewProfile] = useState();
  const [whoCanConnect, setWhoCanConnect] = useState([]);
  const [cannectWithRoles, setCannectWithRoles] = useState([]);
  const [hubLists, setHubsList] = useState([]);
  const [selectedHubs, setSelectedHubs] = useState([]);
  const [privateMessages, setPrivateMessages] = useState(0);
  const [WeeklyUpdates, setWeeklyUpdates] = useState(0);
  const [whenSomeoneRequestToFollow, setWhenSomeoneRequestToFollow] = useState(0);
  const [emailVisibilty, setEmailVisibilty] = useState();
  const [phoneVisibility, setPhoneVisibility] = useState();
  const [addressVisibility, setAddressVisibility] = useState();
  const [lang] = useState(language.getLang());
  const [roleId, setRoleId] = useState();

  CustomUseEffect(async () => {
    await dispatch(getUserPrivacy());
  }, []);

  const {
    userPrivacySpinner,
    updateUserPrivacySpinner,
    userPrivacyData: { privacy_data },
    userPrivacyData: { email_preference },
    userPrivacyData: { roles, hubs, setHubconnectionShow },
  } = useSelector((state) => state.userSettingState);

  CustomUseEffect(async () => {
    //window.fcWidget.show()
    await setCannectWithRoles(roles);
  }, [roles]);

  useEffect(() => {
    setHubsList(hubs?.slice()?.sort((a, b) => (a.checked === b.checked ? 0 : a.checked ? -1 : 1)));
    hubs?.length > 0 && setSelectedHubs(hubs?.filter((item) => item.checked)?.map((item) => item?.id))
  }, [hubs]);

  const getAllHubsLis = async (isReset) => {
    const response = await getAllHubsListing();
    console.log(response, 'response...')
    if (response?.success === 200) {
      setHubsList(response?.data);
      (isReset && response?.data) && setSelectedHubs(response?.data?.filter((item) => item.checked)?.map((item) => item?.id))

    } else {
      setHubsList([])
    }
  }

  CustomUseEffect(async () => {
    await setWhoCanSendtMsg(privacy_data && privacy_data.allow_message_from);
    await setWhoCanViewProfile(
      privacy_data && privacy_data.who_can_view_profile
    );
    await setEmailVisibilty(privacy_data && privacy_data.email_visibility);
    await setPhoneVisibility(privacy_data && privacy_data.phone_visibility);
    await setAddressVisibility(privacy_data && privacy_data.address_visibility);
    await setRoleId(privacy_data && privacy_data.role_id);
    await setCannectWithRoles(roles);
    const arr = privacy_data && privacy_data.who_can_connect.split(',');
    let newArray = [];
    arr?.map((el) => {
      newArray.push(parseInt(el));
    });
    await setWhoCanConnect(newArray);
  }, [privacy_data]);

  CustomUseEffect(async () => {
    await setWeeklyUpdates(email_preference && email_preference.weekly_updates);
    await setPrivateMessages(
      email_preference && email_preference.private_messages
    );
    await setWhenSomeoneRequestToFollow(
      email_preference && email_preference.when_someone_request_to_follow
    );
  }, [email_preference]);

  const handleCheck = async (e, item) => {
    if (e.target.checked) {
      await setWhoCanConnect((prev) => [...prev, item]);
    } else {
      const index = whoCanConnect.indexOf(item);
      if (index > -1) whoCanConnect.splice(index, 1);
      await setWhoCanConnect(whoCanConnect);
    }
  };

  const handleHubCheckBox = async (e, item) => {
    if (e.target.checked) {
      await setSelectedHubs((prev) => [...prev, item]);
    } else {
      const index = selectedHubs.indexOf(item);
      if (index > -1) selectedHubs.splice(index, 1);
      await setSelectedHubs(selectedHubs);
    }
  };

  const submitPrivacy = async () => {
    const privacyData = {
      allow_message_from: whoCanSendtMsg,
      who_can_view_profile: whoCanViewProfile,
      who_can_connect: whoCanConnect,
      weekly_updates: WeeklyUpdates,
      private_messages: privateMessages,
      when_someone_request_to_follow: whenSomeoneRequestToFollow,
      email_visibility: emailVisibilty,
      phone_visibility: phoneVisibility,
      address_visibility: addressVisibility,
      hubs: selectedHubs
    };
    await dispatch(updateUserPrivacy(privacyData));
    // const emailPreference = {
    //   weekly_updates: WeeklyUpdates,
    //   private_messages: privateMessages,
    //   when_someone_request_to_follow: whenSomeoneRequestToFollow
    // }
    // dispatch(updateEmailPreferences(emailPreference))
  };

  const handleKeywords = debounce((keywords) => {
    if (keywords?.trim()?.length > 0) {
      const filterValue = hubLists?.filter((item) => item.title?.toLowerCase()?.includes(keywords?.toLowerCase()));
      filterValue?.length ? setHubsList(filterValue) : setHubsList([]);
    } else {
      setHubsList(hubs?.slice()?.sort((a, b) => (a.checked === b.checked ? 0 : a.checked ? -1 : 1)));
    }
  }, 500);


  return (
    <div>
      {/* Layout Content */}
      <div className="home__page b2b hubMember settings">
        <div className="servics_blocks">
          <div className="site-align top-align">
            <SettingLeftNavigation />
            <div className="servics_blocks middle-long__section-block middleBlockBg">
              <div className="medium_title">
                <h1 className="font-18 text-black">{lang?.Privacy}</h1>
              </div>
              {
                userPrivacySpinner ? (
                  <FormSpinner />
                ) : (
                  <div className="md:w-3/4 mt-6">
                    <form>
                      <div className="form__item mb-4">
                        <label className="font-16 text-normal">
                          {lang?.WHO_CAN_SEND_YOU_A_PRIVATE_MESSAGE}
                        </label>
                        <select
                          className="form-select"
                          onChange={(e) => setWhoCanSendtMsg(e.target.value)}
                          value={whoCanSendtMsg}
                        >
                          <option value={'anyone'}>{lang?.Anyone}</option>
                          <option value={'followers'}>{lang?.Followers}</option>
                          <option value={'connections'}>
                            {lang?.Connections}
                          </option>
                          <option value={'just me'}>{lang?.Just_me}</option>
                        </select>
                      </div>
                      <div className="form__item mb-4">
                        <label className="font-16">
                          {lang?.WHO_CAN_SEND_YOUR_PROFILE}
                        </label>
                        <select
                          className="form-select"
                          onChange={(e) => setWhoCanViewProfile(e.target.value)}
                          value={whoCanViewProfile}
                        >
                          <option value={'anyone'}>{lang?.Anyone}</option>
                          <option value={'followers'}>{lang?.Followers}</option>
                          <option value={'connections'}>
                            {lang?.Connections}
                          </option>
                          <option value={'just me'}>{lang?.Just_me}</option>
                        </select>
                      </div>
                      {constant.rolesId.voyagers == roleId && (
                        <>
                          <div className="form__item mb-4">
                            <label className="font-16">
                              {lang?.EMAIL_VISIBILITY}
                            </label>
                            <select
                              className="form-select"
                              onChange={(e) =>
                                setEmailVisibilty(e.target.value)
                              }
                              value={emailVisibilty}
                            >
                              <option value={'1'}>{lang?.yes}</option>
                              <option value={'0'}>{lang?.no}</option>
                            </select>
                          </div>

                          <div className="form__item mb-4">
                            <label className="font-16">
                              {lang?.PHONE_NUMBER_VISIBILITY}
                            </label>
                            <select
                              className="form-select"
                              onChange={(e) =>
                                setPhoneVisibility(e.target.value)
                              }
                              value={phoneVisibility}
                            >
                              <option value={'1'}>{lang?.yes}</option>
                              <option value={'0'}>{lang?.no}</option>
                            </select>
                          </div>
                          <div className="form__item mb-4">
                            <label className="font-16">
                              {lang?.ADDRESS_VISIBILITY}
                            </label>
                            <select
                              className="form-select"
                              onChange={(e) =>
                                setAddressVisibility(e.target.value)
                              }
                              value={addressVisibility}
                            >
                              <option value={'1'}>{lang?.yes}</option>
                              <option value={'0'}>{lang?.no}</option>
                            </select>
                          </div>
                        </>
                      )}
                      <div className="form__item mb-4">
                        <label className="font-16 text-normal">
                          {lang?.WHO_CAN_CONNECT_WITH_YOU}
                        </label>
                        <div className="form-checkbox-container">
                          <div className="form-checkbox-container-items">
                            {cannectWithRoles?.length > 0 &&
                              cannectWithRoles?.map((item, index) => {
                                return (
                                  <div className="form-check" key={index}>
                                    {whoCanConnect?.includes(item?.role_id) ? (
                                      <input
                                        className="form-check-input"
                                        defaultChecked
                                        value={item}
                                        type="checkbox"
                                        id={index}
                                        onChange={(e) =>
                                          handleCheck(e, item?.role_id)
                                        }
                                      />
                                    ) : (
                                      <input
                                        className="form-check-input"
                                        value={item}
                                        type="checkbox"
                                        id={index}
                                        onChange={(e) =>
                                          handleCheck(e, item?.role_id)
                                        }
                                      />
                                    )}
                                    <label>{item?.name} </label>
                                    {/* {parseInt(item) === constant.rolesId.producer && <label>{lang?.Italian_F_and_B_producers} </label>}
                                {parseInt(item) === constant.rolesId.importer && <label>{lang?.only_Importer} </label>}
                                {parseInt(item) === constant.rolesId.distributer && <label>{lang?.only_Distributor} </label>}
                                {parseInt(item) == constant.rolesId["importer&distributer"] && <label>{lang?.Importer_And_Distributor} </label>}
                                {parseInt(item) === constant.rolesId.voiceOfExpert && <label>{lang?.Voice_Of_Experts} </label>}
                                {parseInt(item) === constant.rolesId.travelAgencies && <label>{lang?.Travel_Agencies} </label>}
                                {parseInt(item) === constant.rolesId.restaurant && <label>{lang?.Italian_Restaurants_in_US} </label>}
                                {parseInt(item) === constant.rolesId.voyagers && <label>{lang?.Voyagers} </label>} */}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      {setHubconnectionShow && <div className="form__item mb-4">
                        <label className="font-16 text-normal">
                          {lang?.Select_hubs_for_restrict_connection}
                        </label>
                        <div className="form-checkbox-container">
                          <div className="form-checkbox-container-items">
                            <div className="colRightBlock rest topSearchbox">
                              <div className="banner__search-form__center justify-center">
                                <div className="top-search">
                                  <span className="search_icon">
                                    <i className="fas fa-search" aria-hidden="true"></i>
                                  </span>
                                  <input type="text" placeholder={lang?.Enter_your_text} onChange={(e) => handleKeywords(e.target.value)} />
                                </div>
                              </div>
                            </div>
                            {hubLists?.length > 0 ?
                              hubLists?.map(({ id, title }) => {
                                return (
                                  <div className="form-check" key={id}>
                                    <input
                                      className="form-check-input"
                                      value={id}
                                      type="checkbox"
                                      defaultChecked={selectedHubs?.includes(id)}
                                      id={id}
                                      onChange={(e) =>
                                        handleHubCheckBox(e, id)
                                      }
                                    />

                                    <label htmlFor={id}>{title} </label>
                                  </div>
                                );
                              }) : <label>No result found.</label>}
                          </div>
                        </div>
                      </div>}
                      <div className="form__item mb-4">
                        <label className="font-16 text-caps">
                          {lang?.Email_Preferences}
                        </label>
                        <div className="form-checkbox-container">
                          <div className="form-checkbox-container-items">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="2"
                                defaultChecked={
                                  privateMessages == 1 ? true : false
                                }
                                value={privateMessages}
                                onChange={(e) =>
                                  e.target.checked
                                    ? setPrivateMessages(1)
                                    : setPrivateMessages(0)
                                }
                              />
                              <label className="form-check-label" for="2">
                                {lang?.Private_messages}
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="3"
                                defaultChecked={
                                  whenSomeoneRequestToFollow == 1 ? true : false
                                }
                                value={whenSomeoneRequestToFollow}
                                onChange={(e) =>
                                  e.target.checked
                                    ? setWhenSomeoneRequestToFollow(1)
                                    : setWhenSomeoneRequestToFollow(0)
                                }
                              />
                              <label className="form-check-label" for="3">
                                {lang?.When_someone_request_to_follow}
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="4"
                                defaultChecked={
                                  WeeklyUpdates == 1 ? true : false
                                }
                                value={WeeklyUpdates}
                                onChange={(e) =>
                                  e.target.checked
                                    ? setWeeklyUpdates(1)
                                    : setWeeklyUpdates(0)
                                }
                              />
                              <label className="form-check-label" for="4">
                                {lang?.Weekly_updates}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="btnBox">
                      <Link
                        to="#"
                        className="btn btnPrimary"
                        onClick={() => submitPrivacy()}
                      >
                        {updateUserPrivacySpinner ? (
                          <FormSpinner />
                        ) : (
                          `${lang?.Save_Changes}`
                        )}
                      </Link>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
