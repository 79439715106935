import React from 'react'
import { Helmet } from 'react-helmet';

const TravelAgencies = () => {
    return (
        <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>Register as a Travel Agency USA | Alysei</title>
            <meta name="description" content="Sign up as a travel agency on Alysei to offer exclusive Italian culinary tours and food experiences to clients, connecting with the best food and beverage suppliers." />
            <meta name="keywords" content="register as travel agency USA, Italian culinary tours USA, food experiences travel agency USA, connect with food suppliers USA, exclusive Italian food tours, Alysei travel agency network, offer Italian food tours, best Italian food suppliers, travel agency food experiences USA" />
            <link rel="canonical" href="https://alysei.com/register/travel-agencies" />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Register as a Travel Agency USA | Alysei" />
            <meta property="og:description" content="Sign up as a travel agency on Alysei to offer exclusive Italian culinary tours and food experiences to clients, connecting with the best food and beverage suppliers." />
            <meta property="og:url" content="https://alysei.com/register/travel-agencies" />
            <meta property="og:site_name" content="Alysei" />
            <meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary" />
            <meta property="twitter:url" content="https://alysei.com/register/travel-agencies" />
            <meta property="twitter:title" content="Register as a Travel Agency USA | Alysei" />
            <meta property="twitter:description" content="Sign up as a travel agency on Alysei to offer exclusive Italian culinary tours and food experiences to clients, connecting with the best food and beverage suppliers." />
            <meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />

        </Helmet>
    )
}

export default TravelAgencies;