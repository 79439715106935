import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { fetchBlogListingData, deleteEvent, deleteBlog, setCreateEvent, editEventDataById, setCreateBlog } from '../../discover/modules/DiscoverModule';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import moment from 'moment';
import Modal from "react-modal";
import MemberType from '../../../components/MemeberType';
import language from '../../../helpers/language';
import CustomUseEffect from '../../../components/constants';


export default function BlogView({ isUniversalSearchTrue, visitorId, pageNo }) {
    const params = useParams()
    const [awardId, setAwardId] = useState('');
    const [deleteModel, setDeleteModel] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isBlog, setIsBlog] = useState(true);
    const dispatch = useDispatch();
    const [lang] = useState(language.getLang());

    CustomUseEffect(async () => {
        await setIsBlog(isUniversalSearchTrue ? isUniversalSearchTrue?.hide : isBlog);
        if (window.location.pathname === "/profile" || window.location.pathname === `${"/visitor-profile/"}${params?.id}`) {
            if (visitorId) {
                await dispatch(fetchBlogListingData(visitorId))
            } else {
                await dispatch(fetchBlogListingData())
            }
        }
        return () => { isBlog = false };
    }, [])


    const { createdBlogtData, awardSpinner } = useSelector((state) => state.discoverReducerState);

    const { profileData: { data } } = useSelector((state) => state.memberProfileData);
    const userId = data && data.user_data && data.user_data.user_id;

    const deleteBlogtitem = async (id) => {
        await setIsDelete(true);
        const response = await dispatch(deleteBlog(id));
        if (response) {
            await setIsDelete(false);
            let blogData = createdBlogtData?.filter((item) => item.blog_id !== id);
            await setDeleteModel(false);
            await dispatch(setCreateBlog(blogData));
        }

    }
    return (
        <div>
            <div className="discover_page">
                <div className={isBlog ? "blogView gridColOne" : "blogView"}>
                    {awardSpinner ? <FormSpinner /> : createdBlogtData?.length > 0 ? createdBlogtData.map((item) => {
                        console.log(item, 'item...');
                        return (
                            <div className='imgBlog'>
                                <div className='awardColn'>
                                    <div className='awardImg'>
                                        <img src={`${item?.attachment?.base_url}${item?.attachment?.attachment_url}`} alt="Blog Image" />
                                    </div>
                                    {visitorId !== undefined || isBlog &&
                                        (item.user_id === userId) && <div className="flex justify-between items-center editOption mt-2">
                                            <Link to={`/edit-blog/${item.blog_id}`}><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                                            <Link to="#" ><div onClick={() => { setDeleteModel(true); setAwardId(item.blog_id); }}><i className="fa fa-trash" aria-hidden="true"></i></div></Link>
                                        </div>
                                    }

                                    <div className='blogProfile'>
                                        <div className="feed-user-info">
                                            <div className="user__pic">
                                                <img src={`${item?.user?.avatar_id?.base_url}${item?.user?.avatar_id?.attachment_url}`} alt='Blog Profile' />
                                            </div>
                                            <div className="user__detail-data">
                                                {/* <MemberType item={item?.user} /> */}
                                                <span className="user__name">
                                                    <a>{item?.title}</a>
                                                </span>
                                                <span className="user__name">
                                                    {`${item?.user?.first_name} ${item?.user?.last_name}` ?? item?.user?.name}
                                                </span>
                                            </div>
                                        </div>
                                        <p className='mt-4'>
                                            {`${item?.description?.slice(0, 100)}`}
                                        </p>
                                        <p className='readMore flex justify-between items-center mt-4'>
                                            <span className='text-black'>{moment(item.created_at).format("MMM D, YYYY")}</span>
                                            <Link to={{ pathname: `/view-blogs/${item.blog_id}/${item.user_id}`, state: { isState: (isBlog ? 1 : 3), pageNo } }}>{lang?.Read_More}</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        )
                    }) :
                        <div className="card card--block">
                            <div className="post--form emptyData">
                                <div className="empty_notification">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="61.989" height="67.363" viewBox="0 0 61.989 67.363">
                                        <path id="icons8_edit_file" d="M4,0V64.665H26.9a1.347,1.347,0,1,0,0-2.694H6.694V2.694H30.944V21.555H49.8V36.374a1.347,1.347,0,1,0,2.694,0V19.661l-.379-.421L33.259.379,32.838,0ZM33.638,4.631l14.23,14.23H33.638ZM60.455,33.553a5.505,5.505,0,0,0-3.915,1.6l-.926.968.042.042-20.8,20.8a1.372,1.372,0,0,0-.379.589l-2.273,8.083a1.345,1.345,0,0,0,1.642,1.684l8.083-2.147a1.375,1.375,0,0,0,.589-.379l21.3-21.134a1.369,1.369,0,0,0,.379-.547l.168-.126a5.515,5.515,0,0,0-3.915-9.43Zm-2.9,4.5L61.592,42.1,40.921,62.644l-3.957-3.831.042-.168Z" transform="translate(-4)" />
                                    </svg>
                                    <h3 className="font-20 font-bold mt-5 mb-3">{lang?.Blogs}</h3>
                                    <h4 className="font-18 text-normal text-black">{lang?.There_is_no_blogs_at_this_moment}</h4>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {deleteModel && (
                <div>
                    <Modal
                        ariaHideApp={false}
                        isOpen={deleteModel}
                        style={{
                            overlay: {
                                position: "fixed",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: "rgba(255, 255, 255, 0.75)",
                            },
                            content: {
                                position: "absolute", top: "0", left: "0%", right: "0%", bottom: "200px", border: "1px solid #ccc", background: "#fff", overflow: "auto", WebkitOverflowScrolling: "touch", borderRadius: "10px", padding: "0",
                                // outline: 'none',
                            },
                        }}
                    >
                        <div className="popup_header flex justify-between items-center popupheaderBg">
                            <h4 className="page__title">{lang?.Delete}</h4>
                            <button
                                className="close_btn--modal"
                                onClick={() => setDeleteModel(!deleteModel)}
                            >
                                {lang?.close}
                            </button>
                        </div>
                        <div className="card card--block modal_box">
                            <p className="warning">{lang?.are_you_sure}</p>
                            <div className="right__btn text-right">
                                <Link
                                    className="btn btn1"
                                    to="#"
                                    onClick={() => setDeleteModel(false)}
                                >
                                    {lang?.Cancel}
                                </Link>
                                <Link className="btn btn2 ml-3" to="#" onClick={() => deleteBlogtitem(awardId)}> {isDelete ? <FormSpinner /> : `${lang?.ok}`} </Link>
                            </div>
                        </div>
                        <div></div>
                    </Modal>
                </div>
            )}
        </div>
    );
}
