import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Cropper from "react-cropper";
import Modal from "react-modal";
import CurrencyInput from "react-currency-input-field";
import FormError from "../../../components/Errors/FormError";
import constant from "../../../helpers/constant";
import auth from "../../../helpers/auth";
import MyEditor from "../../../components/TextEditor/MyEditor";

import FormSpinner from "../../../components/Spinners/FormSpinner";
import "../../../assets/marketplacecss/mrktplace-style.css";
import { Multiselect } from "multiselect-react-dropdown";
import REACT_APP_API from '../../../config/environment';

import {
  fetchStoreData,
  fetchProductCategories,
  fetchBrandLabels,
  saveProductData,
  setFormErrors,
  setResponseErrors,
  getMyAllTaxesClassesList,
  getMarketplaceDashboard,
} from "../modules/marketplace";
import language from "../../../helpers/language";
import DashboardSideBar from "./DashboardSideBar";
import { checkSubscription } from "../../home/modules/HomeModule";
import CustomUseEffect from "../../../components/constants";

export const shippingNewObj = () => {
  return {
    destination_id: "",
    quantity_id: "",
    price: "",
    zones: [],
    states: [],
    destError: false,
    quantityError: false,
    pZsError: false
  }
}

// state_id
export const destinationArr = [
  {
    value: 1,
    label: "Anywhere in USA",
  },
  {
    value: 2,
    label: "US Region",
  },
  {
    value: 3,
    label: "States",
  },

];

export default function AddProduct(props) {
  const dispatch = useDispatch();
  let location = useLocation();
  const history = useHistory();
  const myRef = useRef(null);
  const [lang] = useState(language.getLang());
  const inputElement = useRef(null);
  const [galleryImageObject, setGalleryImageObject] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [addProductDataArr, setAddProductDataArr] = useState({});
  const [OrderQuantityUnit, setOrderQuantityUnit] = useState(lang?.pieces);
  const [isCropped, setIsCropped] = useState(false);
  const [targetImageId, setTargetImageId] = useState("");
  const [openModal, setOpenModal] = useState("");
  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [cropData, setCropData] = useState("");
  const [cropButtonDisabled, setCropButtonDisabled] = useState(false);
  const [destinations, setDestinations] = useState("");
  const [states, setStates] = useState("");
  const [disableIds, setDisableIds] = useState({});
  const [availableQuantityText, setAvailableQuantityText] = useState(
    lang?.pieces
  );
  const [errors, setErrors] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [selectedRanges, setSelectedRanges] = useState([]);
  const [formEntries, setFormEntries] = useState([
    {
      destination_id: "",
      quantity_id: "",
      price: "",
      zones: [],
      states: [],
    },
  ]);


  const [shippingInfo, setShippingInfo] = useState([shippingNewObj()]);

  const [addStateDataArray, setAddStateDataArray] = useState({});
  const [apiStateData, setApiStateData] = useState([]);
  const [zones, setZones] = useState([]);
  const [zoneSelections, setZoneSelections] = useState([]);
  const [productCat, setProductCat] = useState([]);
  const [brandLabel, setBrandLabel] = useState([]);
  const [allTaxClasses, setAllTaxClasses] = useState([]);
  const [productPrice, setProductPrice] = useState("0.00");
  const [rrPrice, setRRPrice] = useState("0.00");
  const [activeTab, setActiveTab] = useState("products");
  const [laoding, setLoading] = useState();
  const cropper = React.createRef(null);
  const [responseMsg, setResponseMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const { get_Subscription_Status_Data } = useSelector(
    (state) => state.activityFeedStatus
  );
  const [entry, setEntry] = useState({ quantity_id: '', destination_id: '' })
  const [entries, setEntries] = useState([0]);
  const [stateSelections, setStateSelections] = useState([]);
  const [destination, setDestination] = useState("");
  const [showPriceInputBox, setShowPriceInputBox] = useState(false);
  const [showShipping, setShowShipping] = useState(false);
  const [error, setError] = useState('');


  const destinationOptions = [
    {
      value: "anywhere_in_usa",
      label: "Anywhere in USA",
    },
    {
      value: "states",
      label: "States",
    },
    {
      value: "us_zones",
      label: "US Zones",
    },
  ];
  const handleShowShipping = () => {
    !showShipping ? setShowShipping(true) : setShowShipping(false);
  };
  const handlePriceShowShipping = () => {
    !showPriceInputBox
      ? setShowPriceInputBox(true)
      : setShowPriceInputBox(false);
  };

  useEffect(() => {
    dispatch(checkSubscription());
  }, []);

  const shipingDetailHandler = (
    index,
    data,
    stateOrZone,
    stateOrZoneIndex,
    deleteField,
    // e
  ) => {
    console.log(data.quantity_id, "shipinghandlervalue");
    //   let key = e?.target?.name; 
    // let value = e?.target?.value; 

    // setAddProductDataArr({ ...addProductDataArr, [key]: value });

    // // If quantity_id is missing or invalid
    // if (data?.quantity_id && data?.quantity_id.length > 0) {
    //   setErrMsg('Select Range is Required');
    //   return;
    // }

    const updatedData = [...formEntries];
    console.log(updatedData, "updatedFormEntries");

    if (stateOrZone) {
      const updatedStateOrZone = [...updatedData[index][stateOrZone]];

      if (stateOrZoneIndex !== null) {
        updatedStateOrZone[stateOrZoneIndex] = {
          ...updatedStateOrZone[stateOrZoneIndex],
          ...data,
        };
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [stateOrZone]: updatedStateOrZone,
        };
      }
    } else {
      updatedData[index] = {
        ...updatedData[index],
        ...data,
      };
    }

    if (deleteField) delete updatedData[index][deleteField];
    const newDetail = [...updatedData];
    setFormEntries(newDetail);
  };

  // Function to handle quantity_id selection and validation
  // const handleSelectChange = (e, index) => {
  //   const selectedValue = e.target.value;

  //   setEntry((prev) => ({
  //     ...prev,
  //     quantity_id: selectedValue,
  //   }));

  //   // Set error if no selection is made
  //   if (!selectedValue) {
  //     setError('Range is required');
  //   } else {
  //     setError('');
  //   }

  //   shipingDetailHandler(index, { quantity_id: selectedValue });

  //   const ids = disableIds?.[entry?.destination_id] || [];
  //   ids[index] = selectedValue;

  //   if (entry?.destination_id) {
  //     setDisableIds({
  //       ...disableIds,
  //       [entry?.destination_id]: ids,
  //     });
  //   }
  // };

  const handleRangeSelection = (index, selectedRangeId) => {
    if (!selectedRanges.includes(selectedRangeId)) {
      setSelectedRanges([...selectedRanges, selectedRangeId]);
    }

    shipingDetailHandler(index, { quantity_id: selectedRangeId });
  };

  const transformedEntries = (formEntries, id) => {
    let transformedData = {
      product_id: id,
      shipping: [],
    };

    formEntries.forEach((entry) => {
      console.log(entry);

      let destinationId;

      if (entry.destination_id == "anywhere_in_usa") {
        destinationId = 1;
      } else if (entry.destination_id == "us_zones") {
        destinationId = 2;
      } else if (entry.destination_id == "states") {
        destinationId = 3;
      }

      if (destinationId === 1) {
        transformedData.shipping.push({
          destination_id: destinationId,
          quantity_id: entry.quantity_id,
          price: Number(entry.price),
        });
      } else if (destinationId === 2) {
        if (entry.zones && entry.zones.length > 0) {
        }
        transformedData.shipping.push({
          destination_id: destinationId,
          quantity_id: entry.quantity_id,
          zones: entry.zones
            .filter((zone) => zone && zone.zone_id)
            .map((zone) => ({
              zone_id: zone.zone_id,
              price: Number(zone.price) || 0,
            })),
        });
      } else if (destinationId === 3) {
        if (entry.states && entry.states.length > 0) {
          transformedData.shipping.push({
            destination_id: destinationId,
            quantity_id: Number(entry.quantity_id),
            states: entry.states
              .filter((state) => state && state.state_id)
              .map((state) => ({
                state_id: state.state_id,
                price: Number(state.price) || 0,
              })),
          });
        }
      }
    });
    console.log(transformedData, "actual data");

    return transformedData;
  };

  CustomUseEffect(async () => {
    const user = await auth.getUserType();

    if (location?.state) {
      history.push("/marketplace/add-product");
    } else {
      (await parseInt(localStorage.getItem("storeStatus"))) === 0 &&
        marketPlaceStore?.store_products?.length > 0 &&
        history.push("/marketplace/thankyou");
    }

    (await (JSON.parse(user).role_id !== constant.rolesId.producer)) &&
      history.push("/marketplace/error");
    await dispatch(fetchStoreData());
    await dispatch(fetchProductCategories());
    await dispatch(fetchBrandLabels());
  }, []);

  let { marketPlaceStore, productCategies, brandLabels, errMessage, resError } =
    useSelector((state) => state.marketPlaceReducerData);

  const scrollToRef = (ref) => {
    window.scroll(0, ref.current && ref.current.offsetTop - 200);
  };

  CustomUseEffect(async () => {
    const response = await getMyAllTaxesClassesList();
    setAllTaxClasses(response.data ?? []);
  }, []);
  console.log(allTaxClasses, "checking data in all tax classes");
  console.log(marketPlaceStore, "checking market place store data");

  CustomUseEffect(async () => {
    if (productCategies && productCategies.length > 0) {
      const prodCatArray = [];
      productCategies.map((cat, index) => {
        const array = {
          key: cat.name,
          value: cat.marketplace_product_category_id,
        };

        prodCatArray.push(array);
      });
      await setProductCat(prodCatArray);
    }
  }, [productCategies]);

  CustomUseEffect(async () => {
    if (brandLabels && brandLabels.length > 0) {
      const brandLabelArray = [];
      brandLabels.map((brand, index) => {
        const array = {
          key: brand.name,
          value: brand.marketplace_brand_label_id,
        };

        brandLabelArray.push(array);
      });
      await setBrandLabel(brandLabelArray);
    }
  }, [brandLabels]);

  const handleGalleryImages = async (event) => {
    Object.keys(event.target.files).map((item, i) => {
      if (
        !event.target.files[item].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)
      ) {
        dispatch(setFormErrors(lang?.Imagetype, event.target.id));
        scrollToRef(myRef);
        // 6200000 file size
      } else if (event.target.files[item].size > 2000000) {
        dispatch(setFormErrors(lang?.ImageSize, event.target.id));
        scrollToRef(myRef);
      } else {
        setOpenModal(true);

        setFileName(event.target.files[0].name);
        setTargetImageId(event.target.id);
        setImage(URL.createObjectURL(event.target.files[0]));
      }
    });

    setTimeout(() => {
      dispatch(setFormErrors({ msg: "", field: "" }));
    }, 3000);
    return (event.target.value = null);
  };

  const getCropData = async () => {
    setIsCropped(true);
    if (typeof cropper !== "undefined") {
      if (targetImageId === "gallery_images") {
        await setCropButtonDisabled(true);
      }
      setCropData(cropper.current.cropper.getCroppedCanvas().toDataURL());
      targetImageId === "gallery_images" &&
        (await galleryImages.push(
          cropper.current.cropper.getCroppedCanvas().toDataURL()
        ));
      await fetch(cropper.current.cropper.getCroppedCanvas().toDataURL()).then(
        (res) =>
          res.blob().then(async (blob) => {
            const file = await new File([blob], `${fileName}`, {
              type: "image/jpeg",
            });
            targetImageId === "gallery_images" &&
              (await galleryImageObject.push(file));
          })
      );
      await setIsCropped(false);
      await setOpenModal(false);
      await setGalleryImages(galleryImages);
      await setAddProductDataArr({
        ...addProductDataArr,
        gallery_images: galleryImageObject,
      });
      await setCropButtonDisabled(false);
    }
  };

  const closeModal = async () => {
    await setCropData("");
    await setImage("");
    await setTargetImageId("");
    await setCropData("");
    await setFileName("");
    await setOpenModal(false);
  };

  // End of Cropping
  const removeGallery = async (index) => {
    if (index > -1) {
      galleryImages.splice(index, 1);
      galleryImageObject.splice(index, 1);
      await setGalleryImages(galleryImages);
      await setGalleryImageObject(galleryImageObject);
      setAddProductDataArr({
        ...addProductDataArr,
        gallery_images: galleryImageObject,
      });
    }
  };

  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setAddProductDataArr({ ...addProductDataArr, [key]: value });
  };
  console.log(states, "checked state values");

  const handleChangePrice = async (value, name) => {
    name === "product_price" ? await setProductPrice(value) : setRRPrice(value);
    await setAddProductDataArr({ ...addProductDataArr, [name]: value });
  };

  const selectProductType = (e) => {
    let key = "product_category_id";
    let value = e[0].value;
    setAddProductDataArr({ ...addProductDataArr, [key]: value });
  };

  const selectBrandLabel = (e) => {
    let key = "brand_label_id";
    let value = e[0].value;
    setAddProductDataArr({ ...addProductDataArr, [key]: value });
  };
  console.log(errMessage, 'checking the setError msg');




  const addProduct = async () => {

    // validate shipping 
    if (showShipping) {
      const { isError, newArray } = isShipingValidate();
      console.log('newArray', newArray, isError);
      if (isError) {
        setShippingInfo(newArray);
        return;
      }
    }
    var data = validateData(addProductDataArr);
    console.log(data, "checking add product data array");

    if (data.length > 0) {
      dispatch(setFormErrors(data[0][1], data[0][0]));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if (
      addProductDataArr.brand_label_id === undefined ||
      addProductDataArr.brand_label_id === ""
    ) {
      dispatch(setFormErrors(lang?.Please_select_brand_label, "brand_labels"));
      scrollToRef(myRef);
    } else {
      addProductDataArr.unit = await OrderQuantityUnit;
      // if (showShipping === true) {
      //   if (
      //     formEntries &&
      //     formEntries[0].destination_id > 0 &&
      //     formEntries[0].quantity_id > 0
      //   ) {
      // setLoading(true);

      //     await dispatch(
      //       saveProductData(
      //         addProductDataArr,
      //         marketPlaceStore.marketplace_store_id
      //       )
      //     ).then(async (res) => {
      //       if (res && res.success === 200) {
      //         setLoading(false);
      //         setResponseMsg("Product successfully added.");

      //         const marketplaceProductId = res.data.marketplace_product_id;
      //         await handleSubmitButton(marketplaceProductId);

      //         await dispatch(fetchStoreData());
      //         await dispatch(getMarketplaceDashboard());
      //         history.push("/marketplace/dashboard");
      //         localStorage.setItem("tab", activeTab);
      //       } else {
      //         setLoading(false);
      //         setErrMsg(
      //           `You can upload upto ${get_Subscription_Status_Data?.product_count}`
      //         );
      //       }
      //     });
      //   }
      // } else {
      setLoading(true)
      await dispatch(saveProductData(addProductDataArr, marketPlaceStore.marketplace_store_id)
      ).then(async (res) => {
        if (res && res.success === 200) {
          setLoading(false);
          setResponseMsg("Product successfully added.");

          const marketplaceProductId = res.data.marketplace_product_id;
          await handleSubmitButton(marketplaceProductId);

          await dispatch(fetchStoreData());
          await dispatch(getMarketplaceDashboard());
          history.push("/marketplace/dashboard");
          localStorage.setItem("tab", activeTab);
        } else {
          setLoading(false);
          setErrMsg(
            `You can upload upto ${get_Subscription_Status_Data?.product_count}`
          );
        }
      });
      // }

      // }
    }
    setTimeout(() => {
      setErrMsg("");
      dispatch(setResponseErrors(""));
      dispatch(setFormErrors({ msg: "", field: "" }));
    }, 5000);
  };

  const validateData = (data) => {
    console.log(data, 'validate data check');

    let quantity_available = parseInt(data.quantity_available);
    let keyValidate = [
      {
        name: "gallery_images",
        display_name: `${lang?.gallery_images} `,
      },
      {
        name: "title",
        min_char: 3,
        max_char: 100,
        display_name: `${lang?.Title}`,
      },
      {
        name: "description",
        min_char: 10,
        max_char: 500,
        display_name: `${lang?.Edit_prod_desc}`,
      },
      {
        name: "brand_label_id",
        min_number: 1,
        display_name: `${lang?.Select_brandLebel}`,
      },
      {
        name: "product_category_id",
        min_number: 1,
        display_name: `${lang?.Product_category} `,
      },

      {
        name: "available_for_sample",
        min_value: 0,
        is_required: true,
        display_name: `${lang?.Available_For_Sample} `,
      },
      {
        name: "handling_instruction",
        min_char: 10,
        max_char: 250,
        display_name: `${lang?.Handling_Instructions} `,
      },
      {
        name: "dispatch_instruction",
        min_char: 10,
        max_char: 250,
        display_name: `${lang?.Dispatch_Instruction} `,
      },


    ];
    let errors = [];

    for (let key = 0; key < keyValidate.length; key++) {
      if (!data.hasOwnProperty(keyValidate[key].name)) {
        if (keyValidate[key].name === "gallery_images") {
          errors.push([
            keyValidate[key].name,
            `${lang?.add_a}` + keyValidate[key].display_name,
          ]);
        } else {
          errors.push([
            keyValidate[key].name,
            keyValidate[key].display_name + `${lang?.is_required}`,
          ]);
        }
      }

      if (keyValidate[key].hasOwnProperty("min_char")) {
        let keyName = keyValidate[key].name;
        if (data[keyName] && data[keyName].length < keyValidate[key].min_char) {
          errors.push([
            keyValidate[key].name,
            keyValidate[key].display_name +
            `${lang?.Min_Char}` +
            keyValidate[key].min_char,
          ]);
        }
      }

      if (keyValidate[key].hasOwnProperty("max_char")) {
        let keyName = keyValidate[key].name;
        if (data[keyName] && data[keyName].length > keyValidate[key].max_char) {
          errors.push([
            keyValidate[key].name,
            keyValidate[key].display_name +
            `${lang?.LessThan}` +
            keyValidate[key].max_char +
            " characters",
          ]);
        }
      }

      if (keyValidate[key].hasOwnProperty("min_value")) {
        let keyName = keyValidate[key].name;
        if (data[keyName] && data[keyName].length < keyValidate[key].min_value) {
          errors.push([
            keyValidate[key].name,
            keyValidate[key].display_name +
            `${lang?.MinValue}` +
            keyValidate[key].min_value,
          ]);
        }
      }

      if (keyValidate[key].hasOwnProperty("max_value")) {
        let keyName = keyValidate[key].name;
        if (data[keyName] && data[keyName] > keyValidate[key].max_value) {
          errors.push([
            keyValidate[key].name,
            keyValidate[key].display_name +
            `${lang?.LessThanOrEq}` +
            keyValidate[key].max_value,
          ]);
        }
      }



      // Validation for brand_label_id
      if (keyValidate[key].name === "brand_label_id" && keyValidate[key].is_required) {
        let keyName = keyValidate[key].name;
        if (!data[keyName] || data[keyName].trim() === "") {
          errors.push([
            keyValidate[key].name,
            `${lang?.Brand} ${lang?.is_required}`,
          ]);
        }
      }
      if (keyValidate[key].name === "available_for_sample") {
        let keyName = keyValidate[key].name;
        if (data[keyName] !== undefined) {
          const value = parseInt(data[keyName], 10);
          if (value < keyValidate[key].min_value) {
            errors.push([
              keyValidate[key].name,
              `${keyValidate[key].display_name} ${lang?.MinValue} ${keyValidate[key].min_value}`,
            ]);
          }
        }
      }

    }


    return errors;
  };

  const getOrderQuantityUnit = async (event) => {
    await setOrderQuantityUnit(event.target.value);
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index];
    var optionText = optionElement.getAttribute("data_tag");
    await setAvailableQuantityText(optionText);
    console.log(index, optionElement, optionText, "chcking 3");
  };
  console.log(OrderQuantityUnit, "check quantity");

  const selectTab = async (tab) => {
    setActiveTab(tab);
  };

  const getApidata = async () => {
    let api_url = `${REACT_APP_API.ENV.REACT_APP_API}/shipping/form`
             
    const apiData = await fetch(api_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await apiData.json();
    console.log(data, "api data");
    setRanges(data?.ranges);
    setZones(data?.zones);
    setDestinations(data?.destinations);
    setStates(data?.states);
  };
  useEffect(() => {
    getApidata();
  }, []);
  console.log(states, "stateValues");
  console.log(formEntries, "form valuess");

  // @@ save shipping details api
  const handleSubmitButton = async (id) => {

    return new Promise(async (resolve, reject) => {
      try {
        const token = localStorage.getItem("ACCESS_TOKEN");
        let jsonShiping = {
          product_id: id,
          shipping: shippingInfo
        }
        let api_url = `${REACT_APP_API.ENV.REACT_APP_API}/shipping/save`
        const response = await fetch(api_url,
          {
            method: "POST",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}`, },
            body: JSON.stringify(jsonShiping),
          }
        );
        const result = await response.json();
        console.log(result, "api modified data response");
        console.log(formEntries, "checking form entries format");

        if (response.ok) {
          const responseData = await response.json();
          console.log("Data sent successfully:", responseData);
          alert(`${lang?.shipping_name} data saved successfully!`);
          resolve('res')
        } else {
          const errorData = await response.json();
          console.error("Error saving data:", errorData);
          alert("Failed to save shipping data!");
          resolve('err')
        }
      } catch (error) {
        console.error("Error during API request:", error);
        resolve('error')
      } finally {
        resolve('finally')
        setLoading(false);
      }
    })

    console.log('shippingapical')
    setLoading(true);

  };

  console.log(disableIds, "checking ranges state data");

  const onHandleDestinationChange = (index, value) => {
    const updatedFormEntries = [...formEntries];
    updatedFormEntries[index].destination = value;
    setFormEntries(updatedFormEntries);
  };
  const handleQuantityChange = (index, value) => {
    const updatedFormEntries = [...formEntries];
    updatedFormEntries[index].quantityRange = value;
    setFormEntries(updatedFormEntries);
  };
  const handlePriceChange = (index, value) => {
    const updatedFormEntries = [...formEntries];
    updatedFormEntries[index].price = value;
    setFormEntries(updatedFormEntries);
  };

  const handleZoneChange = (zoneId) => {
    setZoneSelections((prevSelections) => {
      if (prevSelections.includes(zoneId)) {
        return prevSelections.filter((id) => id !== zoneId);
      } else {
        return [...prevSelections, zoneId];
      }
    });
  };
  const handleStateChange = (stateId) => {
    setStateSelections((prevSelections) => {
      if (prevSelections.includes(stateId)) {
        return prevSelections.filter((id) => id !== stateId);
      } else {
        return [...prevSelections, stateId];
      }
    });
  };

  console.log(formEntries, "check form", shippingInfo);


  const deleteEntry = (index) => {
    const updatedEntries = [...formEntries];
    if (updatedEntries.length === 1) {
      setShowShipping(true);
      return;
    }
    updatedEntries.splice(index, 1);
    setFormEntries(updatedEntries);
  };

  const handleAddEntry = () => {
    setFormEntries([
      ...formEntries,
      {
        destination_id: "",
        quantity_id: "",
        price: "",
        zones: [],
        states: [],
      },
    ]);
  };
  console.log(addProductDataArr, "checking add prod array");
  console.log(formEntries, "checking form entryies values");

  const handleCheckboxChange = (index, i, state_id) => {
    console.log(formEntries, "formm");

    const updatedFormEntries = [...formEntries];

    if (!updatedFormEntries[index].states[i]) {
      updatedFormEntries[index].states[i] = {};
    }

    if (updatedFormEntries[index].states[i].state_id === state_id) {
      updatedFormEntries[index].states[i].state_id = null;
    } else {
      updatedFormEntries[index].states[i].state_id = state_id;
    }

    setFormEntries(updatedFormEntries);
  };

  const handleCheckboxChangeZone = (index, i, zone_id) => {
    console.log(formEntries, "formm");
    const updatedFormEntries = [...formEntries];

    if (!updatedFormEntries[index].zones[i]) {
      updatedFormEntries[index].zones[i] = {};
    }

    if (updatedFormEntries[index].zones[i].zone_id === zone_id) {
      updatedFormEntries[index].zones[i].zone_id = null;
    } else {
      updatedFormEntries[index].zones[i].zone_id = zone_id;
    }

    setFormEntries(updatedFormEntries);
  };

  //  @@@ valildate shipping details 
  function isShipingValidate() {

    let isError = false;
    let newArray = [...shippingInfo];

    newArray.forEach((item) => {
      const isQuantityInvalid = item.quantity_id == '';
      const isPriceInvalid = item?.price == '' ||  item?.price == null ||  item?.price == undefined ||  item?.price == '$';
      let isDestinationInvalid = item.destination_id == 1 || item.destination_id == '';
      let isDestination2or3 = item.destination_id == 2 || item.destination_id == 3



      if (isDestination2or3) {
        let subArray = item?.destination_id == 2 ? item.zones : item.states;
        if (subArray.length > 0) {

          let isEvery = subArray.every((item) => item?.price != undefined && item.price != '' && item?.price != null && item?.price !='$');
          if (isEvery == false) {
            item['pZsError'] = true;
            isError = true;
          } else {
            item['pZsError'] = false;
            isError = false;
          }
        } else {
          item['pZsError'] = false;
          isError = false;
        }

      }


      if (item.destination_id == '') {
        item['destError'] = true;
        isError = true;
      }

      if (isDestinationInvalid && isPriceInvalid) {
        item['pZsError'] = true;
        isError = true;
      }

      if (isQuantityInvalid) {
        item['quantityError'] = true;
        isError = true;
      }


    });

    return { isError, newArray }
  }


  // @@ add new shipping  
  const handleAddNewShipping = () => {
    console.log('shshshshsh', shippingInfo);

    const { isError, newArray } = isShipingValidate();
    console.log('newArraynewArray', newArray, isError);

    if (isError) {
      setShippingInfo(newArray);
      return;
    }
    let newAddArr = [...newArray, shippingNewObj()]
    console.log('newAddArrnewAddArr', newAddArr)

    setShippingInfo(newAddArr);

  };

  const handleDeleteShiping = (index) => {
    if (shippingInfo.length == 1) return;
    setShippingInfo((prev) => {
      const newArray = [...prev];
      newArray.splice(index, 1);
      return newArray;

    });
  }

  // @@ disable range of shipping details
  const isRangeDisabled = (id) => {
    const newArray = [...shippingInfo]
    const isDisabled2 = newArray.some((i) => i.destination_id == 2 && i.quantity_id == id)
    const isDisabled3 = newArray.some((i) => i.destination_id == 3 && i.quantity_id == id)
    if (isDisabled3 && isDisabled2) {
      return true;
    }

    const findRangData = shippingInfo.find((i) => i.quantity_id == id && i?.destination_id == 1);
    console.log('findRangData', findRangData);

    return findRangData ? true : false
  }

  // @@ disable distance of shipping
  const isDistanceDisabled = (id, index) => {
    const newArray = [...shippingInfo]
    const quantityId = newArray[index].quantity_id;

    if (id == 1 || id == 2 || id == 3) {
      const arrFilter = newArray?.filter((i) => ((i.quantity_id == quantityId && i.quantity_id != '') && (id.destination_id != '' && id.destination_id != id)));
      console.log('arrFilter', arrFilter)
      if (arrFilter.length == 0 || arrFilter?.length == 1) {
        return false;
      }
    }

    const isDisabled2 = newArray.some((i) => (i.destination_id == 2) && i.quantity_id == quantityId)
    const isDisabled3 = newArray.some((i) => (i.destination_id == 3) && i.quantity_id == quantityId)

    if (isDisabled2 && (id == 1 || id == 2)) {
      return true
    }

    if (isDisabled3 && (id == 1 || id == 3)) {
      return true
    }

    return false;
  }

  // @@ sleect and input price select
  const handleSelectChange = (e, key, index, errType) => {
    let value = e.target.value;
    let id = e.target.id
    // console.log('handleSelectChange', e, key, value, id, index);
    let keyTwo;
    if (key == 'destination_id') {
      keyTwo = value;
    } else {
      keyTwo = ''
    }

    handleStateUpdate(key, value, index, errType, keyTwo);
  }

  // @@ handle zones checkbox
  const handleZoneCheckbox = (e, index, zItem) => {
    let isChecked = e.target.checked;
    console.log('isChecked', isChecked, index, zItem)
    let newZone = { 'zone_id': zItem?.id, 'price': '' }
    hadleZoneInnerSetStateUpdate(isChecked ? 'ADD' : 'DELETE', index, newZone);

  }
  // @@ update zone under shipping
  const hadleZoneInnerSetStateUpdate = (action, index, newZone, value) => {
    setShippingInfo(prev => {
      const newArray = [...prev];
      if (action == 'ADD') {
        newArray[index].zones.push(newZone);
      } else if (action == 'DELETE') {
        let zoneData = newArray[index].zones;
        let findZIndex = zoneData?.findIndex((i) => i.zone_id == newZone.zone_id);
        console.log('findIndex', newZone, findZIndex, zoneData, newArray)
        newArray[index].zones.splice(findZIndex, 1);
      } else if (action == 'UPDATE') {
        let zoneData = newArray[index].zones;
        newArray[index]['pZsError'] = false;
        let findZIndex = zoneData?.findIndex((i) => i.zone_id == newZone.zone_id);
        newArray[index].zones[findZIndex]['price'] = value
      }
      return newArray;
    });

  }
  // @@ states checkbox
  const handleStatesCheckbox = (e, index, sItem) => {
    let isChecked = e.target.checked;
    console.log('isChecked', isChecked, index)
    let newStates = { 'state_id': sItem?.id, 'price': '' }
    hadleStatesInnerSetStateUpdate(isChecked ? 'ADD' : 'DELETE', index, newStates);

  }

  //  update states under shipping
  const hadleStatesInnerSetStateUpdate = (action, index, newStates, value) => {
    setShippingInfo(prev => {
      const newArray = [...prev];
      if (action == 'ADD') {
        newArray[index].states.push(newStates);
      } else if (action == 'DELETE') {
        let statesData = newArray[index].states;
        let findZIndex = statesData?.findIndex((i) => i.state_id == newStates.state_id);
        console.log('findIndex', statesData, findZIndex, newStates, newArray)
        newArray[index].states.splice(findZIndex, 1);
      } else if (action == 'UPDATE') {
        newArray[index]['pZsError'] = false;
        let statesData = newArray[index].states;
        let findZIndex = statesData?.findIndex((i) => i.state_id == newStates.state_id);
        newArray[index].states[findZIndex]['price'] = value > 0 ? value : ''
      }
      return newArray;
    });

  }

  // @@ update shipping state
  const handleStateUpdate = (key, value, index, errType, keyTwo) => {
    setShippingInfo((prev) => {
      const newArray = [...prev];
      newArray[index][key] = value;
      if (errType) {
        newArray[index][errType] = false;
      }
      if (key == 'quantity_id') {
        newArray[index]['destination_id'] = '';
      }
      if (keyTwo) {
        if (keyTwo == 1) {
          newArray[index]['zones'] = [];
          newArray[index]['states'] = [];
        } else if (keyTwo == 2) {
          newArray[index]['states'] = [];
          newArray[index]['price'] = '';
        } else if (keyTwo == 3) {
          newArray[index]['price'] = '';
          newArray[index]['zones'] = [];
        }
      }

      return newArray;
    });
  };

  console.log('shishishi', shippingInfo)

  console.log('shippingInfo', shippingInfo);
  return (
    <>
      <div className="marketplace dashboard header">
        {/* Banner Section */}
        <div>
          <div className="section__wrapper">
            <div className="flex justify-between items-center">
              <h6 className="back-btn-small">
                <Link to="/marketplace">
                  <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;{" "}
                  {lang?.Go_back}
                </Link>
              </h6>
            </div>
          </div>
        </div>
      </div>
      {/* New Dashboard Start */}
      <div className="servics_blocks dashboardView">
        <div className="site-align top-align">
          <div className="left__section-block sm:mb-2 md:mb-2" disabled={true}>
            <div className="tabLeftBlock">
              <DashboardSideBar
                lang={lang}
                selectTab={selectTab}
                activeTab={activeTab}
              />
            </div>
          </div>
          <div className="servics_blocks middle-long__section-block middleBlockBg">
            <div className="home-section no-padding sm:mt-2">
              <div className="product-title">
                <div className="flex flex-wrap justify-between items-center">
                  <h4 className="mb-0">Add Product</h4>
                  {marketPlaceStore?.store_products?.length == 0 && (
                    <h4 className="mb-0 textRed">
                      *Please add a product You cannot access store, before
                      adding any product.
                    </h4>
                  )}
                  <div className="inner-btn-box">
                    <Link
                      to="#"
                      className="btn btn-blue SaveBtn"
                      onClick={() => addProduct()}
                    >
                      {laoding ? (
                        `${lang?.Saving}...`
                      ) : (
                        <>
                          <svg
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                          >
                            <path
                              d="M1.66667 0.5C0.754788 0.5 0 1.25479 0 2.16667V13.8333C0 14.7452 0.754788 15.5 1.66667 15.5H13.3333C14.2452 15.5 15 14.7452 15 13.8333V3.83333L11.6667 0.5H1.66667ZM1.66667 2.16667H10.9766L13.3333 4.52344V13.8333H1.66667V2.16667ZM3.33333 3.83333V6.33333H10V3.83333H3.33333ZM7.08333 8C6.5308 8 6.00089 8.21949 5.61019 8.61019C5.21949 9.00089 5 9.5308 5 10.0833C5 10.6359 5.21949 11.1658 5.61019 11.5565C6.00089 11.9472 6.5308 12.1667 7.08333 12.1667C7.63587 12.1667 8.16577 11.9472 8.55647 11.5565C8.94717 11.1658 9.16667 10.6359 9.16667 10.0833C9.16667 9.5308 8.94717 9.00089 8.55647 8.61019C8.16577 8.21949 7.63587 8 7.08333 8Z"
                              fill="white"
                            />
                          </svg>
                          Save
                        </>
                      )}
                    </Link>
                    <Link to="/marketplace/dashboard" className="btn btn--gray">
                      <svg width="18" height="9" viewBox="0 0 18 9" fill="none">
                        <path
                          d="M0 0.833252V8.33325H7.5L4.48242 5.31567C5.63777 4.34289 7.12075 3.74992 8.75 3.74992C11.695 3.74992 14.1875 5.66213 15.07 8.31047L17.0426 7.65291C15.8851 4.17708 12.615 1.66659 8.75 1.66659C6.54497 1.66659 4.53823 2.48852 3.00293 3.83618L0 0.833252Z"
                          fill="black"
                        />
                      </svg>
                      Back
                    </Link>
                  </div>
                </div>
              </div>
              <div className="product-list">
                <div className="recentOrder secBlockGap">
                  <h4 className="secBlockTitle">General</h4>
                  {responseMsg && (
                    <h4
                      className="font-20 text-center w-full"
                      style={{
                        color: "green",
                        textAlign: "center",
                      }}
                    >
                      {responseMsg}
                    </h4>
                  )}
                  {errMsg && (
                    <h4
                      className="font-20 text-center w-full"
                      style={{
                        color: "red",
                        textAlign: "center",
                      }}
                    >
                      {errMsg}
                    </h4>
                  )}
                  <form className="configure-store-form add-product-page">
                    <div className="marketplace add-product">
                      {/* Banner Section */}
                      <div className="">
                        <div className="section__wrapper">
                          <div className="page-main">
                            <div className="mt-3">
                              <div className="flex items-center store-profile mb-3">
                                <div className="user__pic">
                                  <img
                                    src={`${marketPlaceStore &&
                                      marketPlaceStore.logo_base_url
                                      }${marketPlaceStore &&
                                      marketPlaceStore.logo_id
                                      }`}
                                    alt={marketPlaceStore.name}
                                    title={marketPlaceStore.name}
                                  />
                                </div>
                                <h4 className="font-16 ml-5">
                                  {marketPlaceStore.name}
                                </h4>
                              </div>
                              <div className="flex flex-wrap w-full">
                                <div className="w-full">
                                  <ul className="gallery__images flex">
                                    {galleryImages.length > 0 &&
                                      galleryImages.map((src, i) => {
                                        return (
                                          <li key={i} className="uploadedImg">
                                            <img src={src} key={i} />
                                            <div className="editDeleteBtn flex justify-end items-center p-2">
                                              <p>
                                                <Link
                                                  to="#"
                                                  className="remove-image"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    removeGallery(i)
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-trash"
                                                    aria-hidden="true"
                                                  ></i>
                                                </Link>
                                              </p>
                                            </div>
                                          </li>
                                        );
                                      })}
                                    <div className="xl:w-1/4 lg:w-1/4 md:w-full sm:w-full col-padding add--cover--photo">
                                      <div className>
                                        <label>
                                          <span className>
                                            <i
                                              className="fa fa-picture-o"
                                              aria-hidden="true"
                                            />
                                          </span>
                                          <input
                                            type="file"
                                            id="gallery_images"
                                            name="gallery_images[]"
                                            style={{}}
                                            accept="image/png,image/jpeg"
                                            onChange={(e) =>
                                              handleGalleryImages(e)
                                            }
                                            multiple
                                            value={null}
                                          />{" "}
                                          {lang?.Add_photos}
                                        </label>
                                      </div>
                                    </div>
                                  </ul>
                                </div>
                                <div className="w-full">
                                  {errMessage.field == "gallery_images" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />{" "}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mrkt-place form">
                                <div className="card-form">
                                  <form className="configure-store-form add-product-page">
                                    <div className="flex flex-wrap main-row">
                                      <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                                        <div className="w-100">
                                          <div className="form__item">
                                            <label>{lang?.Product_Title}</label>
                                            <input
                                              type="text"
                                              name="title"
                                              onChange={(e) => handleChange(e)}
                                              placeholder={lang?.product_title}
                                              id="product-title"
                                              maxLength={100}
                                            />
                                            {errMessage.field == "title" ? (
                                              <div ref={myRef}>
                                                <FormError
                                                  msg={errMessage.msg}
                                                />
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="w-100">
                                          <div className="form__item">
                                            <label>{lang?.Keywords}</label>
                                            <div className="withinfoIcon relative">
                                              <input
                                                type="text"
                                                name="keywords"
                                                onChange={(e) =>
                                                  handleChange(e)
                                                }
                                                placeholder={lang?.Keywords}
                                                id="product-title"
                                              />
                                              <p
                                                className="aadWarning pr-4"
                                                data-tip={
                                                  lang?.helps_user_to_find_product
                                                }
                                              >
                                                <i
                                                  className="fa fa-exclamation-circle"
                                                  aria-hidden="true"
                                                ></i>
                                              </p>
                                              <ReactTooltip />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-100">
                                          <div className="form__item">
                                            <label>
                                              {lang?.Product_category}*
                                            </label>
                                            <select
                                              name={"product_category_id"}
                                              value={
                                                addProductDataArr?.product_category_id ??
                                                ""
                                              }
                                              onChange={(e) => handleChange(e)}
                                            >
                                              <option value={""}>
                                                {lang?.select}
                                              </option>
                                              {productCat?.map(
                                                ({ key, value }) => {
                                                  return (
                                                    <option
                                                      key={key}
                                                      value={value}
                                                    >
                                                      {key}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>

                                            {errMessage.field ==
                                              "product_category_id" ? (
                                              <div ref={myRef}>
                                                <FormError
                                                  msg={errMessage.msg}
                                                />
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                                        <div className="col-100">
                                          <div className="form__item">
                                            <label>
                                              {lang?.Edit_prod_desc}*
                                            </label>
                                            <textarea
                                              className="cmnt-write"
                                              id="description"
                                              maxLength="250"
                                              onChange={(e) => handleChange(e)}
                                              placeholder={lang?.Edit_prod_desc}
                                              name="description"
                                              defaultValue={""}
                                            />
                                            {errMessage.field ==
                                              "description" ? (
                                              <div ref={myRef}>
                                                <FormError
                                                  msg={errMessage.msg}
                                                />
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="w-100">
                                          <div className="form__item">
                                            <label>
                                              {lang?.Select_brandLebel}*
                                            </label>
                                            <div className="withinfoIcon relative selectOption">
                                              <select
                                                name={"brand_label_id"}
                                                value={
                                                  addProductDataArr?.brand_label_id ??
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  handleChange(e)
                                                }
                                              >
                                                <option value={""}>
                                                  {lang?.select}
                                                </option>
                                                {brandLabel?.map(
                                                  ({ key, value }) => {
                                                    return (
                                                      <option
                                                        key={key}
                                                        value={value}
                                                      >
                                                        {key}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>

                                              <p
                                                className="aadWarning pr-6"
                                                data-tip={lang?.how_choose_plan}
                                                currentitem="false"
                                              >
                                                <i
                                                  className="fa fa-exclamation-circle"
                                                  aria-hidden="true"
                                                ></i>
                                              </p>
                                              <ReactTooltip />
                                            </div>

                                            {errMessage.field ==
                                              "brand_label_id" ? (
                                              <div ref={myRef}>
                                                <FormError
                                                  msg={errMessage.msg}
                                                />
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Stock Data */}

                    <h4 className="secBlockTitle">Stock</h4>
                    <div className="marketplace add-product">
                      <div className="mrkt-place form">
                        <div className="card-form">
                          <div className="flex flex-wrap main-row">
                            <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                              <div className="w-100">
                                <div className="form__item noBoder">
                                  <div className="pdctQty">
                                    <div className="">
                                      <label>{lang?.Quantity_Available}</label>
                                      <div className="withinfoIcon relative">
                                        <input
                                          type="text"
                                          name="quantity_available"
                                          onChange={(e) => handleChange(e)}
                                          placeholder={lang?.Quantity_Available}
                                          id="Quantity-Available"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                        />
                                        <p
                                          className="aadWarning p-4"
                                          data-tip={lang?.quanity_available}
                                        >
                                          <i
                                            className="fa fa-exclamation-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </p>
                                        <ReactTooltip />
                                      </div>
                                    </div>
                                  </div>
                                  {errMessage.field == "quantity_available" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="w-100">
                                <div className="form__item noBoder">
                                  <label>{lang?.Minimum_Order_Quantity}</label>
                                  <div className="withinfoIcon relative flex items-center">
                                    <input
                                      type="text"
                                      name="min_order_quantity"
                                      onChange={(e) => handleChange(e)}
                                      placeholder={lang?.Minimum_Order_Quantity}
                                      id="Minimum-Order"
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                    {/* <p
                                      className="aadWarning p-4"
                                      data-tip={lang?.quanity_available}
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </p>
                                    <ReactTooltip /> */}
                                    {/* <div className="quantityRow">
                                      <span>{availableQuantityText}</span>
                                      <span
                                        className="aadWarning p-4"
                                        data-tip={
                                          lang?.Minimum_Order_Quantity_tooltip
                                        }
                                      >
                                        <i
                                          className="fa fa-exclamation-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <ReactTooltip />
                                    </div> */}
                                  </div>
                                </div>
                                {errMessage.field == "min_order_quantity" ? (
                                  <div ref={myRef}>
                                    <FormError msg={errMessage.msg} />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                              <div className="w-100">
                                <div className="form__item">
                                  <div className="gidColRight pieceNo">
                                    <label>{lang?.pieces}</label>
                                    <select
                                      onChange={getOrderQuantityUnit}
                                      value={OrderQuantityUnit}
                                      ref={inputElement}
                                      className={"gidColRightpieceNo"}
                                    >
                                      <option value="" selected hidden>
                                        {lang?.pieces}
                                      </option>
                                      <option
                                        data_tag={lang?.pieces}
                                        value={"No. of pieces"}
                                      >
                                        {lang?.pieces}
                                      </option>
                                      <option
                                        data_tag={lang?.bottles}
                                        value={"No. of bottles"}
                                      >
                                        {lang?.bottles}
                                      </option>
                                      <option
                                        data_tag={lang?.liters}
                                        value={"liters"}
                                      >
                                        {lang?.liters}
                                      </option>
                                      <option
                                        data_tag={lang?.kilograms}
                                        value={"kilograms"}
                                      >
                                        {lang?.kilograms}
                                      </option>
                                      <option
                                        data_tag={lang?.grams}
                                        value={"grams"}
                                      >
                                        {lang?.grams}
                                      </option>
                                      <option
                                        data_tag={lang?.milligrams}
                                        value={"milligrams"}
                                      >
                                        {lang?.milligrams}
                                      </option>
                                      <option
                                        data_tag={lang?.pallet}
                                        value={"pallet"}
                                      >
                                        {lang?.pallet}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="w-100">
                                <div className="form__item">
                                  <label>{lang?.Available_For_Sample}</label>
                                  <div className="withinfoIcon relative selectOption">
                                    <select
                                      id="available_for_sample"
                                      name="available_for_sample"
                                      onChange={(e) => handleChange(e)}
                                    >
                                      <option value="-1">
                                        {lang?.Available_For_Sample}*
                                      </option>
                                      <option value="Yes">{lang?.yes}</option>
                                      <option value="No">{lang?.no}</option>
                                    </select>
                                    <p
                                      className="aadWarning pr-6"
                                      data-tip={lang?.provideSampleOfProduct}
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </p>
                                  </div>
                                  {errMessage.field ==
                                    "available_for_sample" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Price Data */}
                    <h4 className="secBlockTitle">{lang?.price}</h4>
                    <div className="marketplace add-product">
                      <div className="mrkt-place form">
                        <div className="card-form">
                          <div className="flex flex-wrap main-row">
                            <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                              <div className="w-100">
                                <div className="form__item">
                                  <label>{lang?.product_price}</label>
                                  <div className="withinfoIcon relative">
                                    <CurrencyInput
                                      id="product_price"
                                      name="product_price"
                                      placeholder={`${lang?.product_price}`}
                                      allowDecimals={true}
                                      defaultValue={productPrice}
                                      decimalsLimit={2}
                                      onValueChange={(value, name) =>
                                        handleChangePrice(value, name)
                                      }
                                    />
                                    <span className="usd">
                                      <i
                                        className="fa fa-usd"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <p
                                      className="aadWarning pr-4"
                                      data-tip={lang?.charge_10_percentage}
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </p>
                                    <ReactTooltip />
                                  </div>
                                  {productPrice > 0 && (
                                    <p className="textGreen">
                                      You will get $
                                      {productPrice - (productPrice * 10) / 100}{" "}
                                      (Click info icon for more information)
                                    </p>
                                  )}
                                </div>

                                {errMessage.field == "product_price" ? (
                                  <div ref={myRef}>
                                    <FormError msg={errMessage.msg} />
                                  </div>
                                ) : null}
                              </div>
                              <div className="w-100">
                                <div className="form__item">
                                  <label>{lang?.Recommended_retail_price}</label>
                                  <div className="withinfoIcon relative">
                                    <CurrencyInput
                                      id="rrp_price"
                                      name="rrp_price"
                                      placeholder={`${lang?.product_price}`}
                                      allowDecimals={true}
                                      defaultValue={rrPrice}
                                      decimalsLimit={2}
                                      onValueChange={(value, name) =>
                                        handleChangePrice(value, name)
                                      }
                                    />
                                    <span className="usd">
                                      <i
                                        className="fa fa-usd"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <p
                                      className="aadWarning pr-4"
                                      data-tip={lang?.RRP_info}
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </p>
                                    <ReactTooltip />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                              <div className="w-100">
                                <div className="form__item">
                                  <label>{lang?.Tax_class}</label>
                                  <div className="withinfoIcon relative selectOption">
                                    <select
                                      id="class_tax_id"
                                      name="class_tax_id"
                                      onChange={(e) =>
                                        setAddProductDataArr({
                                          ...addProductDataArr,
                                          ["class_tax_id"]: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="">{lang?.Select_tax_Class}</option>
                                      {allTaxClasses?.length > 0 ? (
                                        allTaxClasses.map(
                                          ({ tax_class_id, name }) => {
                                            return (
                                              <option
                                                key={tax_class_id}
                                                value={tax_class_id}
                                              >
                                                {name}
                                              </option>
                                            );
                                          }
                                        )
                                      ) : (
                                        <option value="">
                                          Not create yet!
                                        </option>
                                      )}
                                    </select>
                                    <p
                                      className="aadWarning pr-6"
                                      data-tip={lang?.Tax_class_data_tip}
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      // title={lang?.Tax_Class_Product}
                                      ></i>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Price Data */}

                    {console.log('aashippingInfo', formEntries, showShipping, shippingInfo)}
                    {/* shipping Data */}
                    <h4 className="secBlockTitle flex gap-2">
                      <input type="checkbox" onChange={handleShowShipping} />
                      {lang?.shipping_name}
                    </h4>
                    {/* new  */}
                    {showShipping && (
                      <>
                        {shippingInfo.length > 0 && shippingInfo.map((entry, index) => (
                          <div
                            className="marketplace add-product"
                            key={entry.id}
                          >
                            <div className="mrkt-place form">
                              <div className="card-form">
                                <div className="flex flex-wrap main-row gap-2">

                                  {/* @@START select Range */}
                                  <div className="xl:w-3/12 lg:w-3/12 md:w-full sm:w-full col-padding">
                                    <div className="w-100">
                                      <div className="form__item">
                                        <label >{lang?.Quantity_Range}</label>
                                        <select
                                          name={"quantity_id"}
                                          value={entry?.quantity_id}
                                          required
                                          onChange={(e) => handleSelectChange(e, 'quantity_id', index, 'quantityError')}
                                        >
                                          <option value="">Select Range</option>

                                          {ranges?.length > 0 && ranges.map((iRange, rIndex) => {
                                            console.log('iRangeiRange', iRange);

                                            return (
                                              <option disabled={isRangeDisabled(iRange?.id)} key={iRange.id} value={iRange.id}>
                                                {iRange.range}
                                              </option>
                                            )
                                          })}
                                        </select>
                                        {entry?.quantityError &&
                                          <div >
                                            <FormError msg={"Range is Required"} />
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  {/* @@END select Range */}


                                  {/* @@START select Desitnation */}
                                  <div className="xl:w-3/12 lg:w-3/12 md:w-full sm:w-full col-padding">
                                    <div className="w-100">
                                      <div className="form__item">
                                        <label>{lang?.Destination_name}</label>
                                        <select name="destination_id" onChange={(e) => handleSelectChange(e, 'destination_id', index, 'destError')}
                                          value={entry.destination_id}
                                          required>
                                          <option value=""> Select Destination</option>
                                          {destinationArr?.map((option) => {
                                            return (
                                              <option disabled={isDistanceDisabled(option.value, index)} key={option.value} value={option.value} >
                                                {option.label}
                                              </option>
                                            )
                                          })}
                                        </select>
                                        {entry?.destError &&
                                          <div >
                                            <FormError msg={"Destination is Required"} />
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  {/* @@End select Desitnation */}

                                  {/* if destination not selected */}
                                  <div className="xl:w-4/12 lg:w-4/12 md:w-full sm:w-full col-padding">
                                    {(entry.destination_id == "" || entry.destination_id == 1) && (
                                      <div className="w-100">
                                        <div className="form__item">
                                          <label>{lang?.price}</label>
                                          {/* <input
                                            type="number"
                                            placeholder="$10.00"
                                            onChange={(e) => handleSelectChange(e, 'price', index, 'pZsError')}
                                          
                                          /> */}
                                          <CurrencyInput
                                            prefix="$"
                                            id="price"
                                            name="price"
                                            placeholder={`$10.00`}
                                            allowDecimals={true}
                                            decimalsLimit={2}
                                            onValueChange={(value, name) =>
                                              handleSelectChange({ target: { value: value } }, 'price', index, 'pZsError')
                                            }
                                          />
                                          {entry?.pZsError &&
                                            <div >
                                              <FormError msg={"Price is Required"} />
                                            </div>
                                          }
                                        </div>
                                      </div>
                                    )}

                                    {/*  if destination is us zones */}
                                    {entry?.destination_id == 2 && <>
                                      <div className="w-100">
                                        <div className="form__item">
                                          <label>US Region</label>
                                          {entry?.pZsError &&
                                            <div >
                                              <FormError msg={"Price is Required"} />
                                            </div>
                                          }
                                          {zones.length > 0 ? (
                                            <div className="h-28 overflow-y-auto border p-2">

                                              {zones.length > 0 && <>
                                                {zones.map((zItem, zIndex) => {

                                                  const allState = zItem?.states?.length > 0 && zItem?.states?.map((state, index) => {
                                                    return index === zItem?.states?.length - 1 ? state?.name : state?.name + ',';
                                                  }).join(' ');
                                                  console.log('allState', allState);
                                                  const isZoneExist = entry?.zones?.find((i) => i.zone_id == zItem.id);
                                                  console.log('isZoneChecked', zIndex, zones, isZoneExist)
                                                  return <>
                                                    <div key={zItem.id} className="zone-option flex justify-between items-center mb-1">
                                                      {/* <div className="flex items-center"> */}
                                                      <div className="withinfoIcon relative flexBox labelInfo">
                                                        <input
                                                          type="checkbox"
                                                          id={zItem.id}
                                                          checked={isZoneExist ? true : false}
                                                          onChange={(e) => handleZoneCheckbox(e, index, zItem)}
                                                        />
                                                        <label htmlFor={zItem.id}>
                                                          {zItem?.name}
                                                        </label>
                                                        {(allState) && <span className=" ml-1">
                                                          <span className="p-0" data-tip={allState} currentitem="false"  >
                                                            <i className="fa fa-exclamation-circle cursor-info" aria-hidden="true"></i>
                                                          </span>
                                                          <ReactTooltip />
                                                        </span>}
                                                      </div>

                                                      {isZoneExist &&
                                                        <div className="w-20">
                                                          {/* <input
                                                            type="number"
                                                            placeholder={`$10.00`}
                                                            className="zone-input"
                                                            value={(isZoneExist?.price && isZoneExist?.price > 0) ? isZoneExist?.price : ''}
                                                            onChange={(e) => 
                                                              hadleZoneInnerSetStateUpdate('UPDATE', index, isZoneExist, Number(e.target.value))}
                                                          /> */}
                                                          <CurrencyInput
                                                            prefix="$"
                                                            id="price"
                                                            name="price"
                                                            placeholder={`$10.00`}
                                                            allowDecimals={true}
                                                            decimalsLimit={2}
                                                            value={(isZoneExist?.price && isZoneExist?.price > 0) ? isZoneExist?.price : ''}
                                                            onValueChange={(value, name) =>
                                                              hadleZoneInnerSetStateUpdate('UPDATE', index, isZoneExist, value)
                                                            }
                                                          />

                                                        </div>}

                                                    </div>
                                                  </>
                                                })}

                                              </>}
                                            </div>
                                          ) : (
                                            <p>Loading zones...</p>
                                          )}
                                        </div>
                                      </div>
                                    </>}

                                    {/*  if destination is States */}
                                    {entry?.destination_id == 3 && <>
                                      <div className="w-100">
                                        <div className="form__item">
                                          <label>States</label>
                                          {entry?.pZsError &&
                                            <div >
                                              <FormError msg={"Price is Required"} />
                                            </div>
                                          }
                                          {states.length > 0 ? <div className="h-28 overflow-y-auto border p-2">

                                            {states.map((sItem, sIndex) => {
                                              const isStatesExist = entry?.states?.find((i) => i.state_id == sItem.id);

                                              return (
                                                <div
                                                  key={sItem.id}
                                                  className="state-option flex justify-between items-center mb-1"
                                                >
                                                  <div className="flex items-center">
                                                    <input
                                                      type="checkbox"
                                                      id={sItem.id}
                                                      checked={isStatesExist ? true : false}
                                                      onChange={(e) => handleStatesCheckbox(e, index, sItem)}

                                                    />
                                                    <label htmlFor={sItem.id}>
                                                      {sItem.name}
                                                    </label>
                                                  </div>
                                                  {isStatesExist &&
                                                    <div className="w-20">
                                                      {/* <input
                                                        type="number"
                                                        placeholder={`$10.00`}
                                                        className="state-input"
                                                        onChange={(e) => hadleStatesInnerSetStateUpdate('UPDATE', index, isStatesExist, Number(e.target.value))}
                                                        value={(isStatesExist?.price && isStatesExist?.price > 0) ? isStatesExist?.price : ''}

                                                      /> */}
                                                      <CurrencyInput
                                                        id="price"
                                                        name="price"
                                                        prefix="$"
                                                        placeholder={`$10.00`}
                                                        allowDecimals={true}
                                                        decimalsLimit={2}
                                                        defaultValue={(isStatesExist?.price && isStatesExist?.price > 0) ? isStatesExist?.price : ''}
                                                        onValueChange={(value, name) =>
                                                          hadleStatesInnerSetStateUpdate('UPDATE', index, isStatesExist, Number(value))
                                                        }
                                                      />

                                                    </div>
                                                  }
                                                </div>
                                              )
                                            })}
                                          </div> : <>
                                            <p>Loading states...</p>
                                          </>}
                                        </div>
                                      </div>
                                    </>}


                                  </div>
                                  <div className="xl:w-1/12 lg:w-2/12 md:w-full sm:w-full col-padding">
                                    {console.log('indexindex', index, shippingInfo.length)}
                                    {/* @@ Add and remove new shipping */}
                                    <div className="flex gap-2 mt-6">
                                      {(index == shippingInfo.length - 1) &&
                                        <span>
                                          <i onClick={() => handleAddNewShipping()} className="fa fa-plus-circle text-green-400 text-xl cursor-pointer"></i>
                                        </span>
                                      }
                                      {shippingInfo.length > 1 &&
                                        <span>
                                          <i onClick={() => handleDeleteShiping(index)} className="fa fa-minus-circle text-red-400 text-xl cursor-pointer"></i>

                                        </span>
                                      }
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                    <h4 className="secBlockTitle">{lang?.Other_name}</h4>
                    <div className="marketplace add-product">
                      <div className="mrkt-place form">
                        <div className="card-form">
                          <div className="flex flex-wrap main-row">
                            <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                              <div className="col-100">
                                <div className="form__item">
                                  <div className="withinfoIcon relative flexBox labelInfo">
                                    <label>{lang?.Handling_Instructions}</label>
                                    <p
                                      className=""
                                      data-tip={
                                        lang?.Provide_details_about_your_safe_handling_instructions
                                      }
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </p>
                                    <ReactTooltip />
                                  </div>
                                  <div className="withinfoIcon relative textArea">
                                    <MyEditor
                                      setAddProductDataArr={
                                        setAddProductDataArr
                                      }
                                      keys={"handling_instruction"}
                                      data={
                                        addProductDataArr?.handling_instruction
                                      }
                                    />
                                    {/* <p
                                      className="aadWarning pr-4"
                                      data-tip={
                                        lang?.Provide_details_about_your_safe_handling_instructions
                                      }
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </p>
                                    <ReactTooltip /> */}
                                  </div>
                                  {addProductDataArr?.handling_instruction
                                    ?.length > 0 &&
                                    `${addProductDataArr?.handling_instruction?.replace(
                                      /<[^>]+>/g,
                                      ""
                                    )?.length
                                    }/250 Characters`}
                                  {errMessage.field ==
                                    "handling_instruction" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding">
                              <div className="col-100">
                                <div className="form__item">
                                  <div className="withinfoIcon relative flexBox labelInfo">
                                    <label>{lang?.Dispatch_Instructions}</label>
                                    <p
                                      data-tip={
                                        lang?.detailAboutSpecificDispatch
                                      }
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </p>
                                  </div>
                                  <div className="withinfoIcon relative textArea">
                                    {/*<textarea
                                      className="cmnt-write"
                                      name="dispatch_instruction"
                                      id="dispatch_instruction"
                                      maxLength="250"
                                      placeholder={lang?.Dispatch_Instructions}
                                      defaultValue={""}
                                      onChange={(e) => handleChange(e)}
                                    />*/}
                                    <MyEditor
                                      setAddProductDataArr={
                                        setAddProductDataArr
                                      }
                                      keys={"dispatch_instruction"}
                                      data={
                                        addProductDataArr?.dispatch_instruction
                                      }
                                    />
                                  </div>
                                  {addProductDataArr?.dispatch_instruction
                                    ?.length > 0 &&
                                    `${addProductDataArr?.dispatch_instruction?.replace(
                                      /<[^>]+>/g,
                                      ""
                                    )?.length
                                    }/250 Characters`}
                                  {errMessage.field ==
                                    "dispatch_instruction" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* crop image nodal */}
      <div>
        {openModal && (
          <Modal
            className=""
            ariaHideApp={false}
            isOpen={openModal}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0px",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title">{lang?.Crop_image}</h4>
              <button className="close_btn--modal" onClick={() => closeModal()}>
                {" "}
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box">
              <div className="crop_img_block">
                <Cropper
                  zoomTo={0}
                  style={{ width: "100%" }}
                  preview=".img-preview"
                  src={image}
                  viewMode={1}
                  dragMode={"move"}
                  cropBoxMovable={false}
                  responsive={true}
                  cropBoxResizable={false}
                  ref={cropper}
                  //initialAspectRatio={2/2}
                  ready={() => {
                    cropper.current.cropper.setCropBoxData({
                      left: 70,
                      top: 70,
                      width: 200,
                      height: 200,
                    });
                  }}
                  center={true}
                  guides={false}
                />
              </div>
              <div className="form__action form__item mt-4">
                <button
                  onClick={() => getCropData()}
                  disabled={cropButtonDisabled}
                >
                  {" "}
                  {isCropped ? <FormSpinner /> : `${lang?.Crop_image}`}{" "}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
