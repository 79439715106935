import Carousel from 'react-grid-carousel';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import { Cropper } from 'react-cropper';
import language from '../../../helpers/language';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import ProducerPaymentSettingView from '../../../components/Dashboard/ProducerPaymentSetting/ProducerPaymentSettingView';
import {
  producerStripeConnect,
} from "../modules/marketplace";
import IncoTermModal from './IncoTermModal';

export default function MyStore({ storeBannerImage, activeTab, updateStore, lang, updateStatus, marketPlaceStore
    , cancelCropData, getCropData, handleLogoOrBannerImage, isflag, handleGalleryImages, galleryImages, bannerCropButtonName, cropper,
    coverImageWidth, bannerCroped, croppedBannerImage, divHeightWidth, coverImageHeight, deleteStoreGalleryImage, removeGallery, isSuccess, errorMsg, selectedLogoImageUrl, setIncoterms, incoterm, incotermText, setIncotermText
}) {
  const history = useHistory();
  const [incoTerm, setIncoTerm] = useState(false);
  const urlParams = new URLSearchParams(history?.location?.search);
  const [defaultTab, DefaultTab] = useState(0);
  // const stripe_id = urlParams.get("code");
  // CustomUseEffect(async () => {
  //   if (stripe_id) {
  //     const data = {
  //       stripe_id: stripe_id,
  //     };
  //     const response = await producerStripeConnect(data);
  //     if (response?.success === 200) {
  //       localStorage.setItem("tab", 'store');
  //       localStorage.setItem("payTab", '2');
  //       history.push("/marketplace/dashboard");
  //     }
      
  //     //console.log(response,"connected");
  //   }
  // }, [stripe_id]);

  //console.log({bannerCroped,storeBannerImage},"bannerCroped");
    return (
      <div
        className={`tab home-section no-padding mt-6  ${
          activeTab === "store" ? "active-tab" : ""
        }`}
      >
            <div className="tab-content-left-section">
              <div className="inner-btn-box ml-auto justify-end btnWidth">
                <Link
                  to="#"
                  onClick={() => updateStore()}
                  className="btn btn-blue storeBtn"
                >
                  <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
                    <path
                      d="M1.66667 0.5C0.754788 0.5 0 1.25479 0 2.16667V13.8333C0 14.7452 0.754788 15.5 1.66667 15.5H13.3333C14.2452 15.5 15 14.7452 15 13.8333V3.83333L11.6667 0.5H1.66667ZM1.66667 2.16667H10.9766L13.3333 4.52344V13.8333H1.66667V2.16667ZM3.33333 3.83333V6.33333H10V3.83333H3.33333ZM7.08333 8C6.5308 8 6.0009 8.21949 5.61019 8.61019C5.21949 9.00089 5 9.5308 5 10.0833C5 10.6359 5.21949 11.1658 5.61019 11.5565C6.0009 11.9472 6.5308 12.1667 7.08333 12.1667C7.63587 12.1667 8.16577 11.9472 8.55647 11.5565C8.94717 11.1658 9.16667 10.6359 9.16667 10.0833C9.16667 9.5308 8.94717 9.00089 8.55647 8.61019C8.16577 8.21949 7.63587 8 7.08333 8Z"
                      fill="white"
                    />
                  </svg>
                  {updateStatus ? <FormSpinner /> : lang?.Update}
                </Link>
              </div>
              <h3
                className="font-18"
                style={{
                  color: isSuccess ? "green" : "red",
                  textAlign: "center",
                }}
              >
                {errorMsg}
              </h3> 
              <div
                className="right-banner bannerImg mt-3 bg-gray-200 bg--banner--img" ref={divHeightWidth}
                style={{
                  backgroundImage: croppedBannerImage ? `url("${croppedBannerImage}")` : `url("${marketPlaceStore.banner_base_url}${marketPlaceStore.banner_id}")`,
                  backgroudSize: "100% auto",
                }}
              >
                {storeBannerImage && bannerCroped == false && (
                  <div
                    className="storeBannerCropper"
                    style={{ height: coverImageHeight }}
                  >
                    <Cropper
                      zoomTo={0}
                      style={{ top: 0, left: 0 }}
                      preview=".img-preview"
                      src={storeBannerImage}
                      viewMode={1}
                      dragMode={"move"}
                      cropBoxMovable={false}
                      responsive={true}
                      cropBoxResizable={false}
                      ref={cropper}
                      ready={() => {
                        cropper.current.cropper.setCropBoxData({
                          left: 0,
                          top: 0,
                          width: coverImageWidth,
                          height: coverImageHeight,
                        });
                      }}
                      center={true}
                      guides={false}
                    />
                  </div>
                )}
                {storeBannerImage && bannerCroped == false ? (
                  <div className="image__cropped">
                    <button
                      type="button"
                      className="cancel"
                      onClick={() => cancelCropData()}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="cropped"
                      onClick={() => getCropData()}
                    >
                      {bannerCropButtonName}
                    </button>
                  </div>
                ) : (
                  <div className="right-camera-icon rightCameraImg">
                    <label className="hide-input">
                      <span className>
                        <i className="fa fa-camera" aria-hidden="true" />
                        <input
                          type="file"
                          id="banner_id"
                          name="banner_id"
                          accept="image/png,image/jpeg"
                          onChange={(e) => handleLogoOrBannerImage(e)}
                        />
                      </span>
                    </label>
                  </div>
                )}
                <div className="profile-upload-circle profileCameraIcon">
                  <div className="bg--img-center">
                    <img
                      src={`${marketPlaceStore.logo_base_url}${marketPlaceStore.logo_id}`}
                      alt={marketPlaceStore.name}
                      title={marketPlaceStore.name}
                    />
                    {selectedLogoImageUrl && (
                      <img
                        src={selectedLogoImageUrl}
                        className="store-logo-image"
                      />
                    )}
                  </div>
                  <label className="hide-input">
                    <span className>
                      {/* <i className="fa fa-camera" aria-hidden="true" /> */}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M8.53023 2.08301C7.86423 2.08301 7.24615 2.43798 6.91077 3.01318L5.99524 4.58301H4.37496C2.88665 4.58301 1.66663 5.80303 1.66663 7.29134V15.208C1.66663 16.6963 2.88665 17.9163 4.37496 17.9163H15.625C17.1133 17.9163 18.3333 16.6963 18.3333 15.208V7.29134C18.3333 5.80303 17.1133 4.58301 15.625 4.58301H14.0047L13.0892 3.01318C12.7538 2.43798 12.1357 2.08301 11.4697 2.08301H8.53023ZM8.53023 3.33301H11.4697C11.6929 3.33301 11.8971 3.45077 12.0092 3.64307L13.1062 5.52295C13.1613 5.61725 13.24 5.6955 13.3347 5.7499C13.4294 5.80429 13.5366 5.83295 13.6458 5.83301H15.625C16.4375 5.83301 17.0833 6.47882 17.0833 7.29134V15.208C17.0833 16.0205 16.4375 16.6663 15.625 16.6663H4.37496C3.56243 16.6663 2.91663 16.0205 2.91663 15.208V7.29134C2.91663 6.47882 3.56243 5.83301 4.37496 5.83301H6.35413C6.46331 5.83295 6.57057 5.80429 6.66523 5.7499C6.75989 5.6955 6.83865 5.61725 6.89368 5.52295L7.99068 3.64307C8.1028 3.45077 8.30706 3.33301 8.53023 3.33301ZM9.99996 7.08301C7.93629 7.08301 6.24996 8.76934 6.24996 10.833C6.24996 12.8967 7.93629 14.583 9.99996 14.583C12.0636 14.583 13.75 12.8967 13.75 10.833C13.75 8.76934 12.0636 7.08301 9.99996 7.08301ZM9.99996 8.33301C11.3881 8.33301 12.5 9.44489 12.5 10.833C12.5 12.2211 11.3881 13.333 9.99996 13.333C8.61184 13.333 7.49996 12.2211 7.49996 10.833C7.49996 9.44489 8.61184 8.33301 9.99996 8.33301Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <input
                      type="file"
                      id="logo_id"
                      name="logo_id"
                      accept="image/png,image/jpeg"
                      onChange={(e) => handleLogoOrBannerImage(e)}
                    />
                  </label>
                </div>
              </div>

              <div className="formData">
                <div className="gallery_data">
                  <h4 className="product-title black">{lang?.name}</h4>
                  <p>{marketPlaceStore.name}</p>
                </div>

                <div className="gallery_data">
                  <h4 className="product-title black">{lang?.About_store}</h4>
                  <p>{marketPlaceStore.description}</p>
                </div>

                <div className="gallery_data">
                  <h4 className="product-title black">{lang?.Website}</h4>
                  <p>{marketPlaceStore.website}</p>
                </div>

                <div className="gallery_data">
                  <h4 className="product-title black">{lang?.PhoneNumber}</h4>
                  <p>
                    +{marketPlaceStore?.prefilled?.country_code}{" "}
                    {marketPlaceStore.phone}
                  </p>
                </div>
                <div className="gallery_data">
                  <h4 className="product-title black">{lang?.Location}</h4>
                  <p>
                    {marketPlaceStore?.location
                      ?.toString()
                      ?.replace("null", "")}
                  </p>
                </div>
                <div className="gallery_data">
                  <h4 className="product-title black">{lang?.Store_region}</h4>
                  <p>{marketPlaceStore?.prefilled?.state.name}</p>
                </div>
                <div className="gallery_data">
                  <h4 className="product-title black">
                    {lang?.Incoterms}{" "}
                    <span className="">
                      <i
                        className="fa fa-exclamation-circle infoIcon"
                        aria-hidden="true"
                        onClick={() => setIncoTerm(!incoTerm)}
                      ></i>
                    </span>
                  </h4>
                  <div className="form__item relative">
                    <select
                      value={incoterm}
                      onChange={(e) => {
                        setIncoterms(e.target.value);
                        setIncotermText("");
                      }}
                    >
                      <option value={""}>{lang?.Select_incoterms}</option>
                      {marketPlaceStore?.incoterms?.length > 0 &&
                        marketPlaceStore?.incoterms?.map(
                          ({ id, incoterms }) => {
                            return (
                              <option key={id} value={id}>
                                {incoterms}
                              </option>
                            );
                          }
                        )}
                    </select>
                  </div>
                  <div className="form__item relative textCaps">
                    <div className="important-notes">
                      <label>{lang?.Incoterm_text}</label>
                    </div>
                    <input
                      type="text"
                      name="incoterm_text"
                      placeHolder={lang?.Incoterm_text}
                      value={incotermText}
                      onChange={(e) => setIncotermText(e.target.value)}
                    />
                    <p className="pl--0">
                      Sei a conoscenza di tutti i vincoli contrattuali collegati
                      a questo termine di consegna? Per saperne di più: 
                      <a href="mailto:business&development@ormesani.it">
                        business&development@ormesani.it
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <h4 className="product-title black font-20 mt--0 mb-2">
                {lang?.Our_gallery}
              </h4>
              <div className="slider__gallery startFirst flex-wrap mt-4">
                <div className="fileUpload noBg mb-4">
                  <label className="hide-input">
                    {isflag ? (
                      <FormSpinner />
                    ) : (
                      <span className="flexBox">
                        <i className="fa fa-picture-o" aria-hidden="true" />
                        {lang?.Add_gallery}
                      </span>
                    )}
                    <input
                      type="file"
                      id="gallery_images"
                      name="gallery_images[]"
                      style={{}}
                      accept="image/png,image/jpeg"
                      onChange={(e) => handleGalleryImages(e)}
                      multiple
                    />
                  </label>
                </div>
                <ul className="gallery__images mrktDashboard w-full flex mb-4 flex-wrap">
                  {galleryImages.length > 0 &&
                    galleryImages.map((src, i) => {
                      return (
                        <li key={i} className="uploadedImg gallery">
                          <img src={src} key={i} />
                          <div className="editDeleteBtn flex justify-end items-center p-2">
                            <p>
                              <Link
                                to="#"
                                className="remove-image"
                                style={{ cursor: "pointer" }}
                                onClick={() => removeGallery(i)}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </p>
                          </div>
                        </li>
                      );
                    })}
                  {marketPlaceStore?.store_gallery?.length > 0 && (
                    <div
                      className="carousel-container"
                      style={{
                        width: "420px",
                        overflowX: "auto",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Carousel
                        gap={10}
                        cols={2}
                        loop
                        hideArrow={marketPlaceStore?.store_gallery?.length <= 2} // Show at least 2 items without scrolling
                      >
                        {marketPlaceStore?.store_gallery?.length > 0 &&
                          marketPlaceStore.store_gallery?.map((item, i) => {
                            return (
                              <Carousel.Item
                                key={item.marketplace_store_gallery_id}
                              >
                                <li
                                  className="uploadedImg"
                                  key={item.marketplace_store_gallery_id}
                                >
                                  <img
                                    src={`${item.base_url}${item.attachment_url}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <div className="editDeleteBtn flex justify-end items-center p-2">
                                    <p>
                                      <Link
                                        to="#"
                                        className="remove-image"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          deleteStoreGalleryImage(
                                            item.marketplace_store_gallery_id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </Link>
                                    </p>
                                  </div>
                                </li>
                              </Carousel.Item>
                            );
                          })}
                      </Carousel>
                    </div>
                  )}
                </ul>
              </div>
              {/* Incoterm Poppup */}
              {incoTerm && (
                <IncoTermModal
                  lang={lang}
                  incoTerm={incoTerm}
                  setIncoTerm={setIncoTerm}
                />
              )}
            </div>
      </div>
    );
}
