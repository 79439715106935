import React from 'react'
import { Helmet } from 'react-helmet';
const SeoLanding = () => {
  return (
   
<Helmet>
<meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
{/* <!-- Primary Meta Tags --> */}
<title>Alysei - Best Platform for Authentic Italian Food & Beverage Solutions in the USA</title>
<meta name="description" content="Explore Alysei, the leading B2B and B2C platform for authentic Italian food and beverage products. Connect with producers, importers, and enthusiasts to promote and enjoy Made in Italy excellence in the USA." />
<meta name="keywords" content="Italian Food and Beverage Platform USA, B2B Italian Food Solutions, B2C Italian Products Marketplace, Italian Food Distributors USA, Italian Culinary Products USA, Italian Gastronomy Network, Italian Food Importers and Distributors, Italian Food and Beverage Marketplace" />
<link rel="canonical" href="https://alysei.com" />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:title" content="Alysei - Best Platform for Authentic Italian Food & Beverage Solutions in the USA" />
<meta property="og:description" content="Explore Alysei, the leading B2B and B2C platform for authentic Italian food and beverage products. Connect with producers, importers, and enthusiasts to promote and enjoy Made in Italy excellence in the USA." />
<meta property="og:url" content="https://alysei.com" />
<meta property="og:site_name" content="Alysei" />
<meta property="og:image" content="https://alysei.com/dist/images/investment-img.png" />


{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary" />
<meta property="twitter:url" content="https://alysei.com" />
<meta property="twitter:title" content="Alysei - Best Platform for Authentic Italian Food & Beverage Solutions in the USA" />
<meta property="twitter:description" content="Explore Alysei, the leading B2B and B2C platform for authentic Italian food and beverage products. Connect with producers, importers, and enthusiasts to promote and enjoy Made in Italy excellence in the USA." />
<meta property="twitter:image" content="https://alysei.com/dist/images/investment-img.png" />

<script type="application/ld+json">
{`{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Alysei - Best Platform for Authentic Italian Food & Beverage Solutions in the USA",
  "url": "https://alysei.com/",
  "description": "Explore Alysei, the leading B2B and B2C platform for authentic Italian food and beverage products. Connect with producers, importers, and enthusiasts to promote and enjoy Made in Italy excellence in the USA.",
  "publisher": {
    "@type": "Organization",
    "name": "Alysei",
    "url": "https://alysei.com",
    "logo": "https://alysei.com/dist/images/logo.svg",
    "sameAs": [
      "https://www.facebook.com/AlyseiB2B",
      "https://www.instagram.com/alyseiplatform/",
      "https://www.linkedin.com/company/alysei",
      "https://www.youtube.com/@alysei/",
      "https://apps.apple.com/in/app/alysei/id1634783265",
      "https://play.google.com/store/apps/details?id=com.alysei"
    ]
  }
}
`}
</script>
</Helmet> 
  )
}

export default SeoLanding;