import moment from 'moment'
import React, { useState } from 'react'
import {useHistory } from 'react-router-dom'
import PaginatedComponentView from '../../../components/PaginatedComponent/PaginatedComponentView';
import ResetButtons from '../../../components/ResetButton/ResetButtons';

export default function CustomerManagement({lang, action_img, customerData, filterCustomerList, handleCustomerPageClick }) {
    const [filterform, setFilterForm] = useState({ customer: "", sort_by: "" });
    const history = useHistory();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilterForm({ ...filterform, [name]: value });
    }

    console.log(customerData,"customerData");
    return (
        <>
            <div className='servics_blocks dashboardView customer_mgmt'>
                <div className='site-align top-align'>
                    <div className='servics_blocks middle-long__section-block middleBlockBg'>
                        <div className="product-title">
                            <div className="flex flex-wrap justify-between items-center">
                                <h4 className="mb-0">
                                    {lang?.Customer_management}
                                </h4>
                                <div className="inner-btn-box">
                                    {/* <Link to="#" className="btn btn-red">
                                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                                        Delete
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className='productFilter mb--30'>
                            <div className='searchBar'>
                                <input type='text' className='search form--group' name='customer' value={filterform.customer} onChange={handleChange} placeholder={lang?.Customer_Name} />
                                <span className='searchIcon'>
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </span>
                            </div>
                            <div className='leftFilter'>
                                <div className='categoryList'>
                                    <select name='sort_by' className='form--group' value={filterform.sort_by} onChange={handleChange} >
                                        <option value={''}>{lang?.Select} </option>
                                        <option value={'desc'}>{lang?.Sort_by_A} </option>
                                        <option value={"asc"}>{lang?.Sort_By_Z_A} </option>
                                    </select>
                                </div>
                                <div className='btn btn--gray'>
                                    <button type='button' className='btn filterBtn' onClick={() => filterCustomerList(filterform)}>
                                        {/* <i className="fa fa-filter" aria-hidden="true"></i> */}
                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
                                            <path d="M5.35132 2L8.35132 5L11.3513 2H5.35132ZM15.3513 2L12.3513 5H18.3513L15.3513 2ZM3.35132 7V9H4.35132V9.0332L9.35132 16.0332V22H15.3513V16.0332L20.3513 9.0332V9H21.3513V7H3.35132ZM6.78491 9H17.9177L13.3513 15.3926V20H11.3513V15.3926L6.78491 9Z" fill="black" />
                                        </svg>
                                        {lang?.Filter}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {(filterform?.customer || filterform?.sort_by) && <ResetButtons text={lang?.Reset_filter} onclick={() => { filterCustomerList(); setFilterForm({ customer: "", sort_by: "" }) }} />}
                        <div className="product-list">
                            <div className='recentOrder secBlockGap'>
                                <div className='itemTable itemTableScroll customer__table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                {/* <th>
                                                    <div className="form-checkbox-contents">
                                                        <div className="hub--checkbox--area">
                                                            <input className='form-check-input' type="checkbox" id="check_box1" name="select_item" />
                                                            <label className="form-check-label" htmlFor="check_box1"></label>
                                                        </div>
                                                    </div>
                                                </th> */}
                                                <th><div className='lastOrder'>{lang?.Sr_no}</div></th>
                                                <th><div className='CustomName'>{lang?.Customer_Name}</div></th>
                                                <th>{lang?.Email_id}</th>
                                                <th>{lang?.Contact_No}</th>
                                                <th><div className='orderComplete'>{lang?.Order_completed}</div></th>
                                                <th><div className='country'>{lang?.Country}</div></th>
                                                <th><div className='lastOrder'>{lang?.last_order_date}</div></th>
                                                <th className='ActionHead' >{lang?.Action}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {customerData?.data?.length > 0 ? customerData?.data?.map(({ buyer_info, completed_order, created_at,buyer_id,last_order_date }, i) => {
                                                return (
                                                    <tr>
                                                        {/* <td>
                                                            <div className="form-checkbox-contents">
                                                                <div className="hub--checkbox--area">
                                                                    <input className='form-check-input' type="checkbox" id="check_box2" name="select_item" />
                                                                    <label className="form-check-label" htmlFor="check_box2"></label>
                                                                </div>
                                                            </div>
                                                        </td> */}
                                                        <td>{i + 1}</td>
                                                        <td class="customer__name">{buyer_info?.company_name}</td>
                                                        <td>{buyer_info?.email}</td>
                                                        <td>{buyer_info?.phone}</td>
                                                        <td>{completed_order}</td>
                                                        <td>{buyer_info?.country?.name}</td>
                                                        <td>{last_order_date!==null?moment(last_order_date).format("DD/MM/YYYY hh:mm A"):""}</td>
                                                        <td className='actionBg'>
                                                            <div className='iconGroup'>
                                                                <span onClick={() => history.push(`/marketplace/customer-management-view/${buyer_id}`)}>
                                                                    <img src={action_img} alt="Action Icon" title="View" />
                                                                </span>
                                                                {/* <span className='blockList' title="Inactive">
                                                                    <i className="fa fa-ban" aria-hidden="true"></i>
                                                                    
                                                                </span>   
                                                                <span className='deleteBlock' title="Delete">
                                                                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                                                                    
                                                                </span>  */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }):<p>{lang?.No_data_found}</p>}
                                        </tbody>
                                    </table>
                                </div>
                                <PaginatedComponentView lastPage={customerData?.last_page} handlePageClick={handleCustomerPageClick} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
