import React from "react";
function Accordian({ faq,
  answer,
  index,
  isActive,
  onClickHandler}
) {
  return (
    <div
      onClick={() => onClickHandler(index)}
      className={`${isActive ? "opened" : ""} accordion mb-3`}
    >
      <div className="rounded-lg accordion-title mb-2">
        <div className="flex justify-between items-center">
          <p className="font-medium text-base">{faq}</p>
          <span className="cursor-pointer  flex justify-center items-center w-5 h-5 p-1 rounded-full">
            <i
              className={`${
                isActive ? "fa fa-chevron-up" : "fa fa-chevron-down"
              } text-dim text-sm`}
            ></i>
          </span>
        </div>
        {isActive && (
          <div  className="accordion-body mt-3">
            {answer}
          </div>
        )}
      </div>
    
    </div>
  );
}

export default Accordian;
