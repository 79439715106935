import React from 'react'
import BlueBadge from './BlueBadge'
import AnimateFromBottom from '../../LandingPage/component/AnimateFromBottom'

const ContentImg = ({heading, subheading, desc, badgeInfo, img, direction}) => {
  return (
    <div className='md:px-14 px-4 md:py-8 py-5'>
    <div className={`${direction} flex md:flex-row gap-8`}>
        <div className='w-55'> 
          <AnimateFromBottom delay={0.2}>
          <p className='text-lg text-royal pb-1 uppercase font-medium'>
              {heading}
            </p>
        </AnimateFromBottom>
          <AnimateFromBottom delay={0.3}>
          <p className='font-bold text-2xl pb-4'>{subheading}</p>
        </AnimateFromBottom>
          <AnimateFromBottom delay={0.4}>
          <p className='text-dim font-medium text-sm pb-4'>{desc}
          </p>
        </AnimateFromBottom>
       
      
            <div className='flex gap-2 flex-wrap'>
            {badgeInfo.map((badge, index) => (
              <AnimateFromBottom delay={0.5} key={index}>
                <BlueBadge  badgeInfo={badge} />
               </AnimateFromBottom>
            ))}
               
            </div>
        </div>
        <div className='w-45'>
          <AnimateFromBottom delay={0.2}>
          <img src={img} className='w-full h-auto'/>
        </AnimateFromBottom>
           
        </div>
    </div>
    
</div>
  )
}

export default ContentImg