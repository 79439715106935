import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { postSlectedHubs, selectedHubsState } from "../modules/hubs";
import { Link } from "react-router-dom";
import language from "../../../helpers/language";
import CustomUseEffect from "../../../components/constants";

export function SelectedHubs(props) {
  const dispatch = useDispatch();

  const history = useHistory();
  const [selectedHubId, setSelectedHubId] = useState([]);
  const [selectedCity, setselectedCity] = useState([]);
  const [lang] = useState(language.getLang());
  const { selectedHubsStateData } = useSelector((state) => state.hubs_State);
  CustomUseEffect(async () => {
    document.body.classList.add(`hubs`);
    await getSelectedHUbs()
  }, [selectedHubsStateData]);


  const getSelectedHUbs = () => {
    selectedHubsStateData?.map(async (item) => {
      const selectedcityObj = {
        country_id: item.countryId,
        state_id: item.stateId,
        city_id: item.hubId,
      };
      return (
        setselectedCity((id) => [...id, selectedcityObj]),
        setSelectedHubId((id) => [...id, item.hubId])
      );
    });
  }

  const submitHubs = async () => {
    const params = {
      add_or_update: 1, // for edit 2, for add 1
      selectedhubs: [...new Set(selectedHubId)],
      selectedcity: '',
    };
    const response = await dispatch(postSlectedHubs(params));
    if (response.success === 200) {
      await dispatch(selectedHubsState([]));
      history.push("/home");
    } else {
      console.log(response);
    }
  };

  const removeHubs = async (index) => {
    if (index > -1) {
      selectedHubsStateData.splice(index, 1);
      selectedHubId.splice(index, 1);
      setSelectedHubId([...selectedHubId])
      await dispatch(selectedHubsState([...new Set(selectedHubsStateData)]));
    }
  };

  //console.log(postSlectedHubs,"postSlectedHubs");
  return (
    <Fragment>
      <div className="hub__selection--form bg-white">
        <form className="hub__form padding--5">
          <div className="form--inner--div">
            <div className="flex">
              <div className="w-full">
                <div className="coln-left">
                  <h4 className="title-blue-font-30">{lang?.selectedHubs}</h4>
                  <p className="title-para mb-3">
                    {/* {lang?.Below_are_the_selected_hubs} */}
                  </p>
                  <div className="hub__tab padding--5">
                    <div className="choose__hub">
                      {selectedHubsStateData?.length > 0 && selectedHubsStateData?.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            <div className="selected__data">
                              <p>
                                {item.title}
                                <span onClick={() => removeHubs(index)}><i className="fa fa-times" aria-hidden="true" ></i> </span>
                              </p>
                            </div>
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="closeAll text-right mt-4">
              <Link to="#" onClick={() => props.onEdit()}>
                <p className="pb-2">
                  {lang?.Edit}{" "}
                  <span>
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </span>
                </p>
              </Link>
              <Link to="#" onClick={() => props.removeAllHubs()}>
                <p>
                  {lang?.Remove_all}{" "}
                  <span >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </span>
                </p>
              </Link>
            </div>
            <div className="flex justify-between mt-4">
              {selectedHubsStateData?.length > 0 ?
                <div className="bg-btn-gray sky-blue"> <button type="button" className="btn" onClick={() => submitHubs()} > {lang?.Next} </button></div>
                : <div className="bg-btn-gray"> <button type="button" disabled className="btn" onClick={() => submitHubs()} > {lang?.Next} </button></div>
              }
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}
