import React from 'react'

const Banner = ({title, description}) => {
  return (
      <div className=' md:px-64 px-8  md:pt-40 pt-28 md:pb-24 pb-10 text-center service-banner' id="banner">
          <h2 className='md:text-5xl text-3xl font-bold mb-4'>{title}</h2>
      <p className='text-dim text-base font-medium'>{ description}</p>
    </div>
  )
}

export default Banner