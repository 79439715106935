import React, { useRef } from 'react';
import Carousel from 'react-grid-carousel';
import PartnerLogoView from './PartnerLogoView';
import { useSelector } from 'react-redux';
import FormSpinner from '../../../../components/Spinners/FormSpinner';

export default function PartnerView() {
  let { dashboardPartnerLogo, loadDashboardDataSpinner } = useSelector(
    (state) => state.buyerModuleFilterData
  );
  let sliderRef = useRef(null);

  return (
    <>
      <div className='partnerView card card--block'>
        <h5 className='fs-24 font-bold text-blue'> Alysei Producers</h5>
        <div className='mt-8'>
          <div className='slider-container'>
            {loadDashboardDataSpinner ? (
              <FormSpinner />
            ) : (
              <Carousel
                cols={5} // Display 5 logos on larger screens
                rows={1}
                gap={10}
                loop
                autoplay={3000}
                responsiveLayout={[
                  {
                    breakpoint: 1024, // For screens smaller than 1024px
                    cols: 3, // Display 3 logos
                  },
                  {
                    breakpoint: 768, // For screens smaller than 768px
                    cols: 2, // Display 2 logos
                  },
                  {
                    breakpoint: 480, // For screens smaller than 480px (mobile)
                    cols: 1, // Display 1 logo
                  },
                ]}
              >
                {dashboardPartnerLogo.length > 0 &&
                  dashboardPartnerLogo.map(({ attachment }, index) => (
                    <Carousel.Item key={index}>
                      <PartnerLogoView
                        Logo={`${attachment?.base_url}${attachment?.attachment_url}`}
                      />
                    </Carousel.Item>
                  ))}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
