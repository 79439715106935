import React, { useState, useEffect, Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import FormSpinner from "../../../components/Spinners/FormSpinner";
import { fetchDataConncetionSuggetion, fetchHubsMemberDataConncetion, fetchProducer, fetchUserByRole, getConnectionData, getHubMemberConnectionData, getUsersData } from "../modules/B2B";
import constant from "../../../helpers/constant"
import ProducerSearchBar from "./ProducerSearchBar";
import ImporterSearchBar from "./ImporterSearchBar";
import VoiceExpertSearchBar from "./VoiceExpertSearchBar";
import TravelAgenciesBar from "./TravelAgenciesBar";
import RestaurantSearchBar from "./RestaurantSearchBar";
import ReactPaginate from "react-paginate";
import { acceptRejectRequest, acceptRejectRequestWithVisitorId, followUnfollowUser } from "../../NetworkConnection/modules/networkConnection";
import ConnectionModal from "../../../components/ConnectionModals/ConnectionModal";
import ImporterModel from "../../../components/ConnectionModals/ImporterModal";
import auth from "../../../helpers/auth";
import language from "../../../helpers/language";
import CustomUseEffect from "../../../components/constants";

export default function HubMember(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [istrue, setIsTrue] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [openConnectionModel, setOpenConnectionModel] = useState(false);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [userTypeNo, setuserType] = useState();
  const [lang] = useState(language.getLang());
  const [hubShow, setHubShow] = useState(false);
  const [heroca, setHeroca] = useState("");
  const [hubsId, setHubsId] = useState("");
  const [productType, setProductType] = useState("");
  const [roleId, setRoleId] = useState([]);
  const [privateLabel, setPrivateLabel] = useState("");
  const [roleSearchType, setroleSearchType] = useState(2);
  const [alyseiBrandLabel, setAlyseiBrandLabel] = useState("");
  const [userPercenatge] = useState(parseInt(localStorage.getItem("profile_percentage")));
  let userType = params.roleId;
  let memberType = constant?.rolesId;

  CustomUseEffect(async () => {
    await setHubsId(parseInt(params.hubId));
    await setRoleId(parseInt(params.roleId));
  }, []);

  CustomUseEffect(async () => {
    const userType = await auth.getUserType()
    await setuserType(JSON.parse(userType))
    let type = 2;
    const model = (parseInt(params.roleId) === constant.rolesId.voyagers) ?
      { hubId: parseInt(params.hubId), roleId: parseInt(params.roleId), page: page }
      : { searchType: type, hubId: parseInt(params.hubId), roleId: parseInt(params.roleId), page: page }

    await dispatch(fetchHubsMemberDataConncetion(model));
    await setIsTrue(true)
  }, []);
  const { profileData: { data } } = useSelector((state) => state.memberProfileData);
  const { producerSpinner, hubMemberConnection } = useSelector((state) => state.b2bState);

  const handlePageClick = async (e) => {
    let type = 2;
    let pageNo = e.selected + 1
    await setPage(page);
    await setIsChange(true);
    const model = { searchType: type, hubId: parseInt(params.hubId), roleId: parseInt(params.roleId), page: pageNo };
    await dispatch(fetchHubsMemberDataConncetion(model));
  };
  const handleCancelReq = async (userId) => {
    const newdata = hubMemberConnection?.data?.data?.map((item) => item.user_id === userId ? { ...item, connection_flag: 0 } : item);
    const finalValue = {
      ...hubMemberConnection,
      data: { ...hubMemberConnection.data, data: newdata }
    }
    const response = await dispatch(acceptRejectRequestWithVisitorId({ connection_id: parseInt(userId), accept_or_reject: 3 }));
    if (response?.success === 200) {
      userTypeNo?.role_id === parseInt(userType) && await dispatch(getConnectionData(finalValue));
      await dispatch(getHubMemberConnectionData(finalValue));
    }
  }

  const handleModal = (newValue) => {
    setOpenConnectionModel(newValue);
  }

  const followUser = async (user) => {
    const respone = await dispatch(followUnfollowUser({ follow_user_id: user.user_id, follow_or_unfollow: user.follow_flag === 0 ? 1 : 0 }));
    if (respone?.success === 200) {
      const newdata = hubMemberConnection?.data?.data?.map((item) => item.user_id === user.user_id ? { ...item, follow_flag: user.follow_flag === 0 ? 1 : 0 } : item);
      const finalValue = {
        ...hubMemberConnection,
        data: { ...hubMemberConnection.data, data: newdata }
      }
      await dispatch(getHubMemberConnectionData(finalValue));

    } else {
      console.log(respone);
    }
  }

  const getStatesOfSearchBar = async () => {

  }

  return (
    <div className="home__page b2b hubMember">
      {!(parseInt(data?.user_data?.role_id) === memberType.producer && parseInt(params.roleId) === memberType["importer&distributer"]) && openConnectionModel && <ConnectionModal openModelData={{ openModel: openConnectionModel, user_id: userId, name: userName, handleOpenModel: handleModal, type: parseInt(userType), userData: hubMemberConnection, from: "hubsMember" }} />}
      {parseInt(data?.user_data?.role_id) === memberType.producer && parseInt(params.roleId) === memberType["importer&distributer"] && openConnectionModel && <ImporterModel data={{ from: "importer", openModel: openConnectionModel, name: userName, handleModel: handleModal, userId: userId }} />}

      <div className="card card--block servics_blocks">
        <div className="site-align top-align">
          {/* Left Block */}
          {parseInt(params.roleId) === memberType.producer && <ProducerSearchBar hubShow={hubShow} getStatesOfSearchBar={getStatesOfSearchBar} hubsId={parseInt(params.hubId)} pageNo={isChange ? page + 1 : 1} />}
          {parseInt(params.roleId) === memberType.importer && <ImporterSearchBar hubShow={hubShow} getStatesOfSearchBar={getStatesOfSearchBar} hubsId={parseInt(params.hubId)} pageNo={isChange ? page + 1 : 1} />}
          {parseInt(params.roleId) === memberType.distributer && <ImporterSearchBar hubShow={hubShow} getStatesOfSearchBar={getStatesOfSearchBar} hubsId={parseInt(params.hubId)} pageNo={isChange ? page + 1 : 1} />}
          {parseInt(params.roleId) === memberType["importer&distributer"] && <ImporterSearchBar hubShow={hubShow} getStatesOfSearchBar={getStatesOfSearchBar} hubsId={parseInt(params.hubId)} pageNo={isChange ? page + 1 : 1} />}
          {parseInt(params.roleId) === memberType.voiceOfExpert && <VoiceExpertSearchBar hubShow={hubShow} getStatesOfSearchBar={getStatesOfSearchBar} hubsId={parseInt(params.hubId)} pageNo={isChange ? page + 1 : 1} />}
          {parseInt(params.roleId) === memberType.travelAgencies && <TravelAgenciesBar hubShow={hubShow} getStatesOfSearchBar={getStatesOfSearchBar} hubsId={parseInt(params.hubId)} pageNo={isChange ? page + 1 : 1} />}
          {parseInt(params.roleId) === memberType.restaurant && <RestaurantSearchBar hubShow={hubShow} getStatesOfSearchBar={getStatesOfSearchBar} hubsId={parseInt(params.hubId)} pageNo={isChange ? page + 1 : 1} />}
          {/* <div className="left__section-block"></div> */}

          {/* Middle long Block */}
          {
            parseInt(params.roleId) === memberType.voyagers ?
              <div className="fullW">
                <div className="medium_title">
                  {parseInt(params.roleId) === memberType.voyagers && <h1 className="font-18 text-black">{lang?.Voyagers}</h1>}
                </div>
                {producerSpinner ? (<FormSpinner />) : (
                  <div className="blogView voyagersView">
                    {hubMemberConnection?.data?.data?.length <= 0 && <p>{lang?.No_data_found}.</p>}
                    {
                      hubMemberConnection?.data?.data?.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            <div className="card_inner_block">
                              <div className="hubUser">
                                <div className="feed-user-info">
                                  <div className="user__pic" onClick={() => history.push(`${"/visitor-profile/"}${item.user_id}`)}>
                                    <img src={item.avatar_id && `${item.avatar_id.base_url}${item.avatar_id.attachment_medium_url}`} alt="User Image" />
                                  </div>
                                  <div className="user__detail-data">
                                    <span className="user__name">
                                      <Link to={`${"/visitor-profile/"}${item.user_id}`} className="font-16 text-Blue">
                                        {item.company_name ?? item.restaurant_name ?? `${item.first_name} ${item.last_name}` ?? item.name}
                                      </Link>
                                      {/* <div className="flex"> */}
                                      {/* </div> */}
                                    </span>
                                  </div>
                                </div>
                                <>
                                  {(parseInt(userTypeNo?.role_id) !== memberType.voyagers) ?
                                    <div className="hub_btn">
                                      <div className="viewReq mt-4"><Link to={`${"/visitor-profile"}/${item.user_id}`} className="btn bg-Primary bg-transparent w-full">{lang?.View}</Link></div>
                                    </div> :
                                    <>
                                      {(data?.user_data?.user_id !== item.user_id) ? <div className="hub_btn flex items-center">
                                        <div className={(parseInt(userTypeNo?.role_id) !== memberType.voyagers) ? "md:w-full viewReq mt-4" : "md:w-1/2 viewReq mt-4"}><Link to={`${"/visitor-profile"}/${item.user_id}`} className="btn bg-Primary bg-transparent w-full">{lang?.View}</Link></div>
                                        {userPercenatge === 100 && ((item.available_to_connect === 1) && (item.connection_flag === 0)) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className="btn bg-Primary btn BgTeal w-full" onClick={() => { setUserName(item?.company_name ?? item.restaurant_name ?? `${item.first_name} ${item.last_name}` ?? item.name); setOpenConnectionModel(true); setUserId(item.user_id) }}>{lang?.Connect}</Link></div>}
                                        {userPercenatge === 100 && ((item.available_to_connect === 0) && (item?.available_to_follow === 1 && item?.follow_flag === 0)) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className="btn bg-Primary btn BgTeal w-full" onClick={() => followUser(item)}>{lang?.Follow}</Link></div>}
                                        {userPercenatge === 100 && ((item.available_to_connect === 0) && (item?.available_to_follow === 1 && item?.follow_flag === 1)) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className="btn bg-Primary btn BgTeal w-full" onClick={() => followUser(item)}>{lang?.Unfollow}</Link></div>}
                                        {userPercenatge !== 100 && (<div className="md:w-1/2 view__hub__btn"><Link to="/home" className='btn bg-Primary btn BgTeal w-full'>{lang?.Connect}</Link></div>)}
                                        {(item.available_to_connect === 1 && item.connection_flag === 1) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className='btn bg-Primary btn BgTeal w-full' >{lang?.Connected}</Link></div>}
                                        {((item.available_to_connect === 1) && (item.connection_flag === 2)) && <div className="md:w-1/2 view__hub__btn"><Link to="#" onClick={() => { handleCancelReq(item.user_id) }} className='btn bg-Primary btn BgTeal w-full' >{lang?.Cancel}</Link></div>}
                                      </div> : <div className="md:w-1/2 md:w-full viewReq mt-4"><Link to={`${"/profile"}`} className="btn bg-Primary bg-transparent w-full">{lang?.View}</Link></div>}
                                    </>}
                                </>
                              </div>
                            </div>
                          </Fragment>
                        );
                      })}
                  </div>
                )}
                {istrue && hubMemberConnection?.data?.total > 10 &&
                  <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil((hubMemberConnection?.data?.total) / (hubMemberConnection?.data?.per_page))}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />}
              </div>
              : <div className="servics_blocks middle-long__section-block middleBlockBg">
                <div className="">
                  <div className="medium_title">

                    {parseInt(params.roleId) === memberType.producer && <h1 className="font-18 text-black">{lang?.Italian_F_and_B_producersB2B}</h1>}
                    {parseInt(params.roleId) === memberType.importer && <h1 className="font-18 text-black">{lang?.Importer_And_DistributorB2B}</h1>}
                    {parseInt(params.roleId) === memberType.distributer && <h1 className="font-18 text-black">{lang?.Importer_And_DistributorB2B}</h1>}
                    {parseInt(params.roleId) === memberType["importer&distributer"] && <h1 className="font-18 text-black">{lang?.Importer_And_DistributorB2B}</h1>}
                    {parseInt(params.roleId) === memberType.voiceOfExpert && <h1 className="font-18 text-black">{lang?.B2b_Voice_Of_Experts}</h1>}
                    {parseInt(params.roleId) === memberType.travelAgencies && <h1 className="font-18 text-black"> {lang?.Travel_AgenciesB2B}</h1>}
                    {parseInt(params.roleId) === memberType.restaurant && <h1 className="font-18 text-black">{lang?.Italian_Restaurants_in_USB2B}</h1>}
                  </div>
                  {producerSpinner ? (
                    <FormSpinner />
                  ) : (
                    <div className="card--section site-align top-align flex-wrap">
                      {hubMemberConnection?.data?.data?.length <= 0 && <p>{lang?.No_data_found}.</p>}
                      {/* {console.log(hubMemberConnection?.data, 'hubMemeberConnection')} */}
                      {
                        hubMemberConnection?.data?.data?.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <div className="card card--block">
                                <div className="hubUser">
                                  <div className="feed-user-info">
                                    <div className="user__pic" onClick={() => history.push(`${"/visitor-profile/"}${item.user_id}`)}>
                                      <img src={item.avatar_id && `${item.avatar_id.base_url}${item.avatar_id.attachment_url}`} alt="User Image" />
                                    </div>
                                    <div className="user__detail-data">
                                      <span className="user__name">
                                        <Link to={data?.user_data?.user_id === item.user_id ? `${"/profile"}` : `${"/visitor-profile/"}${item.user_id}`} className="font-16 text-Blue">
                                          {item.company_name ?? item.restaurant_name ?? `${item.first_name} ${item.last_name}` ?? item.name}
                                        </Link>
                                        {/* <div className="flex"> */}
                                        {/* </div> */}
                                      </span>
                                    </div>
                                  </div>
                                  {data?.user_data?.user_id !== item.user_id ? <div className="hub_btn flex items-center">
                                    <div className={(parseInt(userTypeNo?.role_id) !== memberType.voyagers) ? "md:w-full viewReq mt-4" : "md:w-1/2 viewReq mt-4"}><Link to={`${"/visitor-profile"}/${item.user_id}`} className="btn bg-Primary bg-transparent w-full">{lang?.View}</Link></div>
                                    {userPercenatge === 100 && ((item.available_to_connect === 1) && (item.connection_flag === 0)) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className="btn bg-Primary btn BgTeal w-full" onClick={() => { setUserName(item?.company_name ?? item.restaurant_name ?? `${item.first_name} ${item.last_name}` ?? item.name); setOpenConnectionModel(true); setUserId(item.user_id) }}>{lang?.Connect}</Link></div>}
                                    {userPercenatge === 100 && ((item.available_to_connect === 0) && (item?.available_to_follow === 1 && item?.follow_flag === 0)) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className="btn bg-Primary btn BgTeal w-full" onClick={() => followUser(item)}>{lang?.Follow}</Link></div>}
                                    {userPercenatge === 100 && ((item.available_to_connect === 0) && (item?.available_to_follow === 1 && item?.follow_flag === 1)) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className="btn bg-Primary btn BgTeal w-full" onClick={() => followUser(item)}>{lang?.Unfollow}</Link></div>}
                                    {userPercenatge !== 100 && (<div className="md:w-1/2 view__hub__btn"><Link to="/home" className='btn bg-Primary btn BgTeal w-full'>{lang?.Connect}</Link></div>)}
                                    {(item.available_to_connect === 1 && item.connection_flag === 1) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className='btn bg-Primary btn BgTeal w-full' >{lang?.Connected}</Link></div>}
                                    {((item.available_to_connect === 1) && (item.connection_flag === 2)) && <div className="md:w-1/2 view__hub__btn"><Link to="#" onClick={() => { handleCancelReq(item.user_id) }} className='btn bg-Primary btn BgTeal w-full' >{lang?.Cancel}</Link></div>}
                                  </div> : <div className="md:w-1/2 md:w-full viewReq mt-4"><Link to={`${"/profile"}`} className="btn bg-Primary bg-transparent w-full">{lang?.View}</Link></div>}

                                </div>
                              </div>
                            </Fragment>
                          );
                        })}
                    </div>
                  )}
                  {istrue && hubMemberConnection?.data?.total > 10 &&
                    <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={Math.ceil((hubMemberConnection?.data?.total) / (hubMemberConnection?.data?.per_page))}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />}
                </div>

              </div>
          }
          {/* Right Block */}
        </div>
      </div>
    </div>
  );
}
