import React, { useEffect, useRef, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ResetButtons from '../../../components/ResetButton/ResetButtons'
import { uploadOrderInvoice } from '../modules/marketplace';
import ProductOrderTable from '../../../components/Dashboard/ProductOrderTable'
import language from '../../../helpers/language';


export default function OrderManagement({ data, handlePageClick, filterOrders, changeOrderStatus,setToast,toast,setFilterStatus,setFilter}) {
  const myRef = useRef(null);
  const [fileUpload, setFileUpload] = useState({ isOpen: false, id: '' });
  const [filterKeys, setFilterKeys] = useState({
    order_id: '',
    customer: '',
    status: '',
    date: '',
  });

  const [error, setError] = useState({ message: '', isSuccess: false });
  const [file, setFile] = useState(null);
  const [lang] = useState(language?.getLang());
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [status, setStatus] = useState(false);
  const [tabType] = useState({
    0: "",
    1: "pending",
    2: "ongoing",
    3: "completed",
  });
  const [hightlightMessage,setHighLightMessage]=useState()


  const handleChange = (e) => {
    e.preventDefault();
    setFilter(e.target.value)
    const { name, value } = e.target;
    setFilterKeys({ ...filterKeys, [name]: value })

  }

  const clearfilter = () => {
    setFilterKeys({
      order_id: '',
      customer: '',
      status: '',
      date: '',
    });
    filterOrders();
  }

  const hanldeOption = (id, value) => {
    changeOrderStatus(id, value, true);
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ['application/pdf'];
    setError({ message: '', isSuccess: false });
    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
    } else {
      setFile(null);
      setError({ message: 'Only PDF files are supported.', isSuccess: false });
    }
  };

  const uploadOrderInovice = async (id) => {
    setLoading(true);
    const response = await uploadOrderInvoice(id, file);
    setError({ message: '', isSuccess: false });
    if (response?.success === 200) {
      filterOrders('', data?.current_page);
      setFile(null);
      setLoading(false);
      setFileUpload({ isOpen: false, id: '' });
      setError({ message: response?.message, isSuccess: true });
    } else {
      setError({ message: response?.message, isSuccess: false });
      setLoading(false);
    }
    setTimeout(() => {
      setError({ message: '', isSuccess: false });
    }, 2500);
  }

  useEffect(()=>{
    if(toast==true){
    setHighLightMessage("Order Accepted")
    setTimeout(()=>{
      setHighLightMessage("")
      setToast(false)
    },4000)

  }
  },[toast])
 
  


  return (
    <>
      <div className='servics_blocks dashboardView order_mgmt'>
        <div className='site-align top-align'>
          <div className='servics_blocks middle-long__section-block middleBlockBg'>
            <div className="product-title">
              <div className="flex flex-wrap justify-between items-center">
                <h4 className="mb-0">
                  {lang?.Order_management}
                </h4>
                {error?.message && <h5 className={`${error?.isSuccess ? 'textGreen' : 'textRed'}`} ref={myRef}>{error?.message}</h5>}
                <div className="inner-btn-box">
                </div>
              </div>
            </div>
            <div className='productFilter mb--30'>
              <div className='searchBar'>
                <input type='text' className='search form--group' placeholder={lang?.Order_id} name="order_id" value={filterKeys?.order_id} onChange={(e) => handleChange(e)} />
              </div>
              <div className='searchBar'>
                <input type='text' className='search form--group' placeholder={lang?.Customer_Name} name="customer" value={filterKeys?.customer} onChange={(e) => handleChange(e)} />
              </div>
              <div className='categoryList'>
                <select name="status" className='form--group' value={filterKeys?.status} onChange={(e) => handleChange(e)}>
                  <option value={'on hold'}>{lang?.Hold_name}</option>
                  <option value={'in transit'}>{lang?.InTransit_name}</option>
                  <option value={'completed'}>{lang?.Completed_name}</option>
                  <option value={'processing'}>{lang?.Processing_name}</option>
                  <option value={'pending'}>{lang?.Pending_name} </option>
                  <option value={'cancelled'}>{lang?.Cancel_name} </option>
                </select>
              </div>
              <div className='categoryList' >
                <input type='date' name="date" className='form--group' value={filterKeys?.date} onChange={(e) => handleChange(e)} />
              </div>
              <div className='btn btn--gray'>
                <button type='button' className='btn filterBtn' onClick={() => {filterOrders(filterKeys,data?.current_page)}}>
                  {/* <i className="fa fa-filter" aria-hidden="true"></i> */}
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M5.35132 2L8.35132 5L11.3513 2H5.35132ZM15.3513 2L12.3513 5H18.3513L15.3513 2ZM3.35132 7V9H4.35132V9.0332L9.35132 16.0332V22H15.3513V16.0332L20.3513 9.0332V9H21.3513V7H3.35132ZM6.78491 9H17.9177L13.3513 15.3926V20H11.3513V15.3926L6.78491 9Z" fill="black" />
                  </svg>
                  {lang?.Filter}
                </button>
              </div>
            </div>
            {(filterKeys?.date || filterKeys?.order_id || filterKeys?.customer) && <ResetButtons text={"Reset Filter"} onclick={clearfilter} />}
            
            <div className="product-list">
              <div className='tax__mgmt'>
                <div className='TabFlex'>
                  <div className='TaxTabFlex'>
                    <div className='recentOrder secBlockGap'>
                      {/* table Data */}
                      <div className='custom__tab'>
                        <Tabs className="Tax_Tab" onSelect={(i) => { setIndex(i); i !== index && filterOrders({ status: tabType[i] }, data?.current_page);setFilterStatus(tabType[i]) }}>
                          <TabList>
                            <Tab>{lang?.All}</Tab>
                            <Tab>{lang?.New_orders}</Tab>
                            <Tab>{lang?.Ongoing}</Tab>
                            <Tab>{lang?.Past_orders}</Tab>
                          </TabList>
                          <h3 className="text-center text-green-600 font-bold mt-2 p-2">{hightlightMessage}</h3>
                          {Array.from({ length: 4 })?.map((_, i) => {
                            return (
                              <TabPanel className="custom__tab__data" key={i}>
                                <ProductOrderTable orderData={{ file, data, hanldeOption, handleFileChange, uploadOrderInovice, setFile, changeOrderStatus, handlePageClick, setFileUpload, fileUpload, loading, error }} />
                              </TabPanel>
                            )
                          })}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </>
  )
}
