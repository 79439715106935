import React from "react";
import { Helmet } from "react-helmet"
const Buyers=()=>{
    return (
<Helmet>
<meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />

{/* <!-- Primary Meta Tags --> */}
<title>Wholesale Italian Food & Beverages for Buyers | Alysei</title>
<meta name="description" content="Find the best deals on Italian food and beverages with Alysei. Connect with trusted suppliers and buy wholesale Italian products, including food, wine, and beverages for your business in the USA." />
<meta name="keywords" content="Italian food buyers USA, Wholesale Italian products buyers USA, Italian food and beverage buyers USA, Italian food sourcing for buyers USA, Bulk Italian food buyers USA, Italian product buyers USA, Authentic Italian product sourcing USA, Wholesale Italian food for buyers USA, Italian food distributors for buyers USA, Buy Italian food products online USA" />
<link rel="canonical" href="https://alysei.com/services/buyers" />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:title" content="Wholesale Italian Food & Beverages for Buyers | Alysei" />
<meta property="og:description" content="Find the best deals on Italian food and beverages with Alysei. Connect with trusted suppliers and buy wholesale Italian products, including food, wine, and beverages for your business in the USA." />
<meta property="og:url" content="https://alysei.com/services/buyers" />
<meta property="og:site_name" content="Alysei" />
<meta property="og:image" content="https://alysei.com/dist/images/buyer-contentimg.png" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary" />
<meta property="twitter:url" content="https://alysei.com/services/buyers" />
<meta property="twitter:title" content="Wholesale Italian Food & Beverages for Buyers | Alysei" />
<meta property="twitter:description" content="Find the best deals on Italian food and beverages with Alysei. Connect with trusted suppliers and buy wholesale Italian products, including food, wine, and beverages for your business in the USA." />
<meta property="twitter:image" content="https://alysei.com/dist/images/buyer-contentimg.png" />

<script type="application/ld+json">
{`{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Wholesale Italian Food & Beverages for Buyers USA | Alysei",
  "url": "https://alysei.com/services/buyers",
  "description": "Find the best deals on Italian food and beverages with Alysei. Connect with trusted suppliers and buy wholesale Italian products, including food, wine, and beverages for your business in the USA.",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://alysei.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Buyers",
        "item": "https://alysei.com/services/buyers"
      }
    ]
  },
  "about": {
    "@type": "Service",
    "name": "Buyers Services",
    "serviceType": "Buyer Assistance and Supplier Connections",
    "provider": {
      "@type": "Organization",
      "name": "Alysei",
      "url": "https://alysei.com"
    },
    "areaServed": {
      "@type": "Country",
      "name": "US"
    },
    "audience": {
      "@type": "Audience",
      "audienceType": "Buyers, Wholesalers, Retailers"
    }
  },
  "publisher": {
    "@type": "Organization",
    "name": "Alysei",
    "url": "https://alysei.com",
    "logo": "https://alysei.com/dist/images/logo.svg",
    "sameAs": [
      "https://www.facebook.com/AlyseiB2B",
      "https://www.instagram.com/alyseiplatform/",
      "https://www.linkedin.com/company/alysei",
      "https://www.youtube.com/@alysei/",
      "https://apps.apple.com/in/app/alysei/id1634783265",
      "https://play.google.com/store/apps/details?id=com.alysei"
    ]
  }
}`}
</script>

</Helmet>

    )
}

export default Buyers;