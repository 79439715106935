import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import REACT_APP_API from '../../../config/environment'

// Importing images
import instagramIcon from '../../../assets/landing-page/Instagram.svg';
import linkedinIcon from '../../../assets/landing-page/linkedin.svg';
import facebookIcon from '../../../assets/landing-page/facebook.svg';
import youtubeIcon from '../../../assets/landing-page/Youtube.svg';
import footerLogo from '../../../assets/landing-page/footer-logo.png';
import AnimateFromBottom from './AnimateFromBottom';
import language from "../../../helpers/language";
const Footer = () => {
  const [lang] = useState(language.getLang());
  return (
    <AnimateFromBottom delay={0.2}>
    <div className='md:px-14 px-4 md:py-8 py-5'>
      <div className='flex md:flex-row flex-col gap-4 justify-between'>
        <ul className='flex justify-center md:justify-start gap-4'>
          <li className='text-royal font-bold text-base'><Link to="/">Home</Link></li>
          <li className='text-royal font-bold text-base'><Link to="/faq">FAQ</Link></li>
          <li className='text-royal font-bold text-base'><Link to="/about">{lang.About_us}</Link></li>
          <li className='text-royal font-bold text-base'><a href="https://alyseiblog.ibyteworkshop.com/blogs/">Blogs</a></li>
        </ul>
        <div className='flex justify-center md:justify-end gap-3'>
          <a href="https://www.instagram.com/"><img src={instagramIcon} alt='Instagram' /></a>
          <a href='https://www.linkedin.com/company/alysei/'><img src={linkedinIcon} alt='LinkedIn' /></a>
          <a href='https://www.facebook.com/AlyseiB2B/'><img src={facebookIcon} alt='Facebook' /></a>
          <a href='https://www.youtube.com/channel/UCLS2XGoIFJcqhBCxm9K7OEg'><img src={youtubeIcon} alt='YouTube' /></a>
        </div>
      </div>
      <hr className='my-4' />
      <div className='flex md:flex-row flex-col gap-4 md:justify-between justify-center items-center relative'>
        <p>© Copyright 2024 Alysei Corp</p>
        <img src={footerLogo} alt='Alysei Logo' className='footer-logo' />
        <div className='flex gap-2'>
          <Link to="">All rights reserved</Link> |
          <a href={`${REACT_APP_API.ENV.REACT_APP}/privacy-policy`} target="_blank">{lang?.Privacy_Policy}</a> |
            <a href={`${REACT_APP_API.ENV.REACT_APP}/terms`} target="_blank">{lang?.Terms_of_Use}</a>
        
        </div>
      </div>
      </div>
      </AnimateFromBottom>
  );
}

export default Footer;
