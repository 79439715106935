import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import MultiSelecter from '../MutiSelect/MultiSelect';
import { Link } from 'react-router-dom';
import { getConnectionProductType } from '../../routes/NetworkConnection/modules/networkConnection';
import language from '../../helpers/language';
import { useHistory } from 'react-router-dom';

export default function ImporterModel({ data }) {
  const [lang] = useState(language.getLang());
  const [connectToImporter, setConnectToImporter] = useState(false);
  const [connectPopup, setConnectPopup] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(()=>{
    const freeCall = async () => {
      await dispatch(getConnectionProductType());
    }
    freeCall()
  }, []);

  useEffect(()=>{
    const freeCall = async () => {
      await setConnectToImporter(data?.openModel);
    }
    freeCall()
  }, [data.connectionType]);

  const { connectionProductTypeData } = useSelector(
    (state) => state.networkConnectionState
  );


  const handleMultiSelect = async (id, selectedList) => {
    const arr = [];
    (await selectedList) &&
      selectedList.map((item) => arr.push(item.user_field_option_id));
    await localStorage.setItem('productTypeArr', JSON.stringify(arr));
  };
  return (
    <div>
      <Modal
      className="overflow-scroll"
        isOpen={connectToImporter}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
          content: {
            position: 'absolute',
            top: '0',
            left: '0%',
            right: '0%',
            bottom: '200px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            padding: '0',
            // outline: 'none',
          },
        }}
      >
        <div className="popup_header flex justify-between items-center">
          <h4 className="page__title">{lang?.Connection_Request}</h4>
          <button
            className="close_btn--modal"
            onClick={() => {
              setConnectToImporter(!connectToImporter);
              data?.handleModel(false);
            }}
          >
            {lang?.close}
          </button>
        </div>
        <div className="card card--block modal_box modalLessGap">
        
          <form>
            <div className="form__item h-40 overflow-scroll overflow-x-hidden scrollbar-none ">
              <MultiSelecter
                displayValue={'option'}
                Options={connectionProductTypeData}
                label={lang?.Select_Product_Type}
                fireData={(id, selectedList) =>
                  handleMultiSelect(id, selectedList)
                }
                placeholder={lang?.select}
              />
            </div>
          </form>
          <div className="view__hub__btn">
            {data.from === 'importer' ? (
              localStorage.getItem('productTypeArr') &&
              localStorage.getItem('productTypeArr').length > 0 ? (
                <Link
                  to={'#'}
                  className="btn BgTeal"
                  onClick={() => {
                    history.push(
                      `${'/user-certification/'}${data?.name}/${data?.userId}`
                    );
                    setConnectPopup(!connectPopup);
                  }}
                >
                  {lang?.Continue}
                </Link>
              ) : (
                <Link to="#" className="btn BgTeal">
                  {lang?.Continue}
                </Link>
              )
            ) : localStorage.getItem('productTypeArr') &&
              localStorage.getItem('productTypeArr').length > 0 ? (
              <Link
                className="btn BgTeal"
                to={`${'/user-certification/'}${
                  data?.data?.user_data.company_name ??
                  data?.data.user_data.restaurant_name
                }/${parseInt(data.userId)}`}
                onClick={() => setConnectPopup(!connectPopup)}
              >
                {lang?.Continue}
              </Link>
            ) : (
              <Link to="#" className="btn BgTeal">
                {lang?.Continue}
              </Link>
            )}
          </div>
        </div>
        <div></div>
      </Modal>
    </div>
  );
}
