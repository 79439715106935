import React from 'react'
import { Helmet } from 'react-helmet'
const TravelAgencies = () => {
  return (
    <Helmet>
      <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />

      {/* <!-- Primary Meta Tags --> */}
      <title>Travel Agencies for Italian Food & Beverage Tourism USA | Alysei</title>
      <meta name="description" content="Connect with travel agencies specializing in Italian food and beverage tourism with Alysei. Explore unique culinary experiences, plan customized itineraries, and access B2B opportunities in the USA." />
      <meta name="keywords" content="Italian Travel Agencies USA, Italian Food Tourism Opportunities, Culinary Travel Itineraries Italy, Italian Gastronomy Travel USA, B2B Travel Solutions Italy, Italian Food & Beverage Tourism, Italian Travel and Culinary Experiences, Travel Agencies for Italian Cuisine Lovers, Italian Culinary Adventures USA, Italy Travel Agencies for Food Enthusiasts" />
      <link rel="canonical" href="https://alysei.com/services/travel-agencies" />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Travel Agencies for Italian Food & Beverage Tourism USA | Alysei" />
      <meta property="og:description" content="Connect with travel agencies specializing in Italian food and beverage tourism with Alysei. Explore unique culinary experiences, plan customized itineraries, and access B2B opportunities in the USA." />
      <meta property="og:url" content="https://alysei.com/services/travel-agencies" />
      <meta property="og:site_name" content="Alysei" />
      <meta property="og:image" content="https://alysei.com/dist/images/travel-contentimg.png" />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:url" content="https://alysei.com/services/travel-agencies" />
      <meta property="twitter:title" content="Travel Agencies for Italian Food & Beverage Tourism USA | Alysei" />
      <meta property="twitter:description" content="Connect with travel agencies specializing in Italian food and beverage tourism with Alysei. Explore unique culinary experiences, plan customized itineraries, and access B2B opportunities in the USA." />
      <meta property="twitter:image" content="https://alysei.com/dist/images/travel-contentimg.png" />

      <script type="application/ld+json">
        {`{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Travel Agencies for Italian Food & Beverage Tourism USA | Alysei",
  "url": "https://alysei.com/services/travel-agencies",
  "description": "Connect with travel agencies specializing in Italian food and beverage tourism with Alysei. Explore unique culinary experiences, plan customized itineraries, and access B2B opportunities in the USA.",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://alysei.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Travel Agencies",
        "item": "https://alysei.com/services/travel-agencies"
      }
    ]
  },
  "about": {
    "@type": "Service",
    "name": "Travel Agencies Services",
    "serviceType": "Travel Agency Solutions and Business Optimization",
    "provider": {
      "@type": "Organization",
      "name": "Alysei",
      "url": "https://alysei.com"
    },
    "areaServed": {
      "@type": "Country",
      "name": "US"
    },
    "audience": {
      "@type": "Audience",
      "audienceType": "Travel Agencies, Tour Operators"
    }
  },
  "publisher": {
    "@type": "Organization",
    "name": "Alysei",
    "url": "https://alysei.com",
    "logo": "https://alysei.com/dist/images/logo.svg",
    "sameAs": [
      "https://www.facebook.com/AlyseiB2B",
      "https://www.instagram.com/alyseiplatform/",
      "https://www.linkedin.com/company/alysei",
      "https://www.youtube.com/@alysei/",
      "https://apps.apple.com/in/app/alysei/id1634783265",
      "https://play.google.com/store/apps/details?id=com.alysei"
    ]
  }
}`}
      </script>

    </Helmet>
  )
}

export default TravelAgencies