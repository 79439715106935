import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { seletedCityNameArray, getStateOfCity, stateHubSelect, newHubStateSelected, reviewHubs } from "../modules/hubs";
import CityName from "./CityName";
import constant from "../../../helpers/constant";
import auth from "../../../helpers/auth";
import FormError from "../../../components/Errors/FormError";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import language from "../../../helpers/language";
import CustomUseEffect from "../../../components/constants";

export default function CitySelect() {
  const [city, setCity] = useState([]);
  const [selectcity, setselectCity] = useState([]);
  const [selectedCityArr, setselectedCityArr] = useState(false);
  const [findcity, setFindCity] = useState([]);
  const [search, setSearch] = useState();
  const [ActiveNo, setActiveNo] = useState(0);
  const [newUser, setUser] = useState([]);
  const dispatch = useDispatch();
  const [isMsg, setMsg] = useState(false);
  const [textMsg, setTextMsg] = useState('');
  const [lang] = useState(language.getLang());

  let {
    selectedCity,
    statehubselect,
    hubsSpinner,
    selectedCityName,
    reviewHubsData
  } = useSelector((state) => state.hubs_State);

 const history = useHistory();

  CustomUseEffect(async () => {
    document.body.classList.add(`hubs`);
    setSearch(selectedCity)
    await setFindCity(selectedCity)
    const user = await auth.getUserType();
    await setUser(JSON.parse(user))
    const id = await statehubselect && statehubselect[0]?.id;

    id && await dispatch(getStateOfCity(id)).then(async (res) => {
      if (res && res.success === 200) {
        await setSearch(res.data)
        // await setselectCity(res.data)
      }
    })
    await dispatch(reviewHubs());

  }, [])

  // for selected city in hubs...
  // CustomUseEffect(async () => {
  //   await search?.length > 0 && search?.map((res) => {
  //     reviewHubsData?.data[0]?.cities?.map((item) => {
  //       console.log(res?.city_id === item?.city_id,'ghfcghnh');
  //       // if (res?.city_id === item?.city_id) {
  //       //   console.log(res, 'res..athar');
  //       //   // handleChange({ target: { checked: true } }, res)
  //       // }
  //     })
  //   })

  // }, [search, reviewHubsData]);

  useEffect(() => {
    setselectCity(selectedCity);
  }, [selectedCity]);

  useEffect(() => {
    setCity(selectedCityName);
  }, [selectedCityName])

  const handlesearch = async (e) => {
    const findName = e.target.value;
    if (findName !== "") {
      const results = selectcity.filter((user) => {
        return user.name.toLowerCase().includes(findName.toLowerCase());
      });
      setSearch(results);
    } else {
      setSearch(selectcity);
    }
    // setName(findName);
  };


  const handleChange = async (e, items) => {
    const selectCity = {
      name: items.name,
      id: items.id,
      state_id: items.state_id,
      country_id: items.country_id,
      state: items.state,
    }

    await setMsg(false);
    await setTextMsg('');
    if (e.target.checked) {
      setCity((oldArray) => [...oldArray, selectCity]);
    } else {
      city && city.length > 0 ? city.map((item, index) => {
        if (item.id === items.id) {
          if (index > -1) {
            city.splice(index, 1)
            setCity(() => [...city])
          } return city;
        }
      }) :
        setCity((old) => [...old, selectCity])

    }
  };


  const getCityFun = async (id, index) => {
    setActiveNo(index)
    await dispatch(getStateOfCity(id)).then((res) => {
      if (res && res.success === 200) {
        setSearch([]);
        setSearch(res.data);
      }
    });
  };

  const handleErrorMsg = async () => {
    await city.length ? setMsg(true) : setMsg(false)
    newUser.role_id === constant.rolesId.restaurant && city.length && await setTextMsg(lang?.onlyOneCity)
    setTimeout(() => {
      setMsg(false)
      setTextMsg('');
    }, 3000);
  }

  const handleclick = async () => {
    if (city?.length > 0) {
      await dispatch(seletedCityNameArray([...new Set(city)]));
      setselectedCityArr(true)
    };
    // history.push("/cityname")
  };


  const editSelectedHubs = async () => {
    setselectedCityArr(false);
  };

  const removeAllHubs = async () => {
    await dispatch(seletedCityNameArray([]));
    await dispatch(newHubStateSelected([]));
    await dispatch(stateHubSelect([]))
    setCity([]);
    setselectedCityArr(false);
    history.push("/hubs");
  };

  return (
    <Fragment>
      {selectedCityArr && <CityName onEdit={() => editSelectedHubs()} removeAllHubs={() => removeAllHubs()} />}
      {!selectedCityArr && (
        <div className="hub__selection--form bg-white selectedState city">
          <form className="hub__form padding--5">
            <div className="form--inner--div">
              <div className="flex">
                <div className="w-full">
                  <div className="coln-left">
                    <h4 className="title-blue-font-30">{lang?.USA}</h4>
                    <p className="title-para">
                      {/* {lang?.Below_Selected_state_where_You_from_exported} */}
                      {/* {lang?.Below_Selected_state_where_You_from_exported} */}
                      Select Cities
                    </p>
                    {/* <div>
                    <input type="searchbox" name="" value={name} placeholder="search here ..." style={{ border: '2px', width: '700px', backgroundColor: 'gray', height: '40px' }} onChange={handlesearch} />
                     </div> */}

                    <div className="form__item searchIcon mt-3">
                      <span className="inputSearch">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </span>
                      <input
                        type="text"
                        className=""
                        placeholder="Search City"
                        onChange={handlesearch}
                      />
                    </div>

                    <div className="flex justify-end mt-4 mb-4">
                      <div className="bg-btn-gray sky-blue">
                        {(<button type="button" className="btn" onClick={() => handleclick()}>
                          {lang?.Next}
                        </button>)}
                      </div>
                    </div>
                    {/* Hub Selected List*/}
                    <div className="selectedHubList">
                      <ul className="flex flex-wrap pb-4 selected--city">
                        {statehubselect?.length > 0 && statehubselect?.map((item, index) => {
                          return (
                            <Fragment>
                              <div className="selected-data" key={index}>
                                <Link to='#'>
                                  <a onClick={() => getCityFun(item.id, index)}>
                                    <div className={ActiveNo === index ? "selected__data Active" : "selected__data "}>
                                      {item.name}
                                    </div> </a> </Link>
                              </div>
                            </Fragment>
                          );
                        })}
                      </ul>
                    </div>

                    {textMsg !== '' && isMsg && <FormError msg={textMsg} />}
                    {hubsSpinner ? <FormSpinner /> :
                      <div className="hub__tab padding--5">
                        <div className="choose__hub" onClick={() => handleErrorMsg()}>
                          {reviewHubsData?.data?.length === 0 && search?.length === 0 && <div className="notFound">{lang?.noHubsFound}</div>}
                          {/* cities from selected state */}
                          {search?.length > 0 && search?.map((item) => {
                            const result = city && city.length > 0 && city.filter((res) => res.id === item.id);
                            return (
                              <div className="form__item form__checkbox country-check">
                                {result && result.length > 0 && result[0].id === item.id ?
                                  <input
                                    defaultChecked
                                    type="checkbox"
                                    id={item.id}
                                    name={item.name}
                                    onChange={(event) => handleChange(event, item,)}
                                  /> : <input
                                    type="checkbox"
                                    id={item.id}
                                    name={item.name}
                                    onChange={(event) => handleChange(event, item,)}
                                    disabled={city.length > 0 && city[0].id !== item.id && newUser.role_id === constant.rolesId.restaurant ? true : false}
                                  />}
                                <label
                                  htmlFor={item.id}
                                  className="flex p-4 rounded-lg"
                                >
                                  <span>{item.name}</span>
                                </label>
                              </div>
                            );
                          })
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
}
