import React, { Fragment } from 'react'
import ReactStars from "react-rating-stars-component";
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormSpinner from "../../../components/Spinners/FormSpinner";
import FormError from '../../../components/Errors/FormError'
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import userProfile from "../../../assets/images/dummy-profile-pic.jpg";

import { postReview, setFormError, fetchRecipeReview, updateRecipeReview } from '../modules/RecipeModule'
import language from '../../../helpers/language';
import CustomUseEffect from '../../../components/constants';

export default function AddReview(props) {
    const [lang] = useState(language.getLang());
    const [comment, setComment] = useState('')
    const [recipeId, setRecipeId] = useState()
    const [rating, setRating] = useState('')
    const [isRecipeReview, setIsRecipeReview] = useState(false)
    const [getReview, setGetReview] = useState([])
    const [spinner, setSpinner] = useState(true)
    const [post, setPost] = useState()
    const [postCount, setPostCount] = useState(false)
    const [recipeReviewRatingId, setRecipeReviewRatingId] = useState()
    const [itemUserId, setItemUserId] = useState(null)
    const [usersDataId, setUserDataId] = useState()
    const [tempVal, setTempVal] = useState(0)
    const [submitField, setSubmitField] = useState(false)
    const [reviewByThisUser, setNoReviewByThisUser] = useState(true)
    const dispatch = useDispatch()

    const { profileData: { data } } = useSelector((state) => state.memberProfileData);
    const { errMessage } = useSelector((state) => state.recipedata);
    const checkUserId = data && data.user_data && data.user_data.user_id
    const { slug } = useParams()
    const history = useHistory()

    CustomUseEffect(async () => {
        setIsRecipeReview(false)
        await fetchRecipeReview(parseInt(slug)).then(async (res) => {
            if (res?.success === 200) {
                await setGetReview(res?.data)
                await res?.data?.filter((item) => {
                    if (item.user_id === data?.user_data?.user_id) {
                        setItemUserId(item.user_id);
                        setSubmitField(false);
                        setNoReviewByThisUser(false);
                    } else if (reviewByThisUser) {
                        setSubmitField(true);
                    }
                })
                setSpinner(false);
            }
            else if (res && res.success === 409) {
                setSpinner(false);
                await setSubmitField(true)
            }
        })
        setIsRecipeReview(true)
    }, [data, tempVal])

    const handleRating = (rate) => {
        setRating(rate)
    }
    const submitPost = async (e) => {
        e.preventDefault();
        const postObj = {
            recipe_id: parseInt(slug),
            rating: rating,
            review: comment
        }
        if (comment?.trim() === '' || comment?.trim()?.length === 0) {
            dispatch(setFormError(`${lang?.please_Write_Reviews}`, "review"))
        } else if (rating === '') {
            dispatch(setFormError(`${lang?.please_Write_Rating}`, "review"))
        } else {
            postReview(postObj).then(async (res) => {
                if (res && res.success == 200) {
                    setIsRecipeReview(true)
                    setPostCount(postCount + 1)
                    setTempVal(tempVal + 1)
                    await setPostCount(false)
                    setPost(false)
                    setComment('')
                    setRating('')
                    setRating(0)
                } else if (res?.success === 409) {
                    dispatch(setFormError(res?.errors?.exception, "Add_Review"));
                    setComment(' ')
                    setRating('')
                    setPost(false)
                }
            })
        }
        setTimeout(() => {
            dispatch(setFormError("", ""))
        }, 2000);
    }

    const updateReview = async () => {
        const userId = data?.user_data?.user_id
        const result = await getReview && getReview.filter((item) => item.user_id === userId)
        setSubmitField(false)
        result.map(async (item) => {
            await setRecipeReviewRatingId(item.recipe_review_rating_id)
            await setRating(item && item.rating)
            await setComment(item.review)
            await setPost(true)
            await setPostCount(true)
        })
    }

    const updateSubmitPost = async (e) => {
        e.preventDefault();
        setSubmitField(false)
        const obj = {
            recipe_review_rating_id: recipeReviewRatingId,
            rating: rating,
            review: comment
        }
        if (rating === '' || rating === 0) {
            dispatch(setFormError(`${lang?.please_Write_Rating}`, "review"))
        } else {
            await updateRecipeReview(obj).then(async (res) => {
                if (res && res.success === 200) {
                    setIsRecipeReview(true)
                    setPostCount(postCount + 1)
                    setTempVal(tempVal + 1)
                    await setPostCount(false)
                    setPost(false)
                    setComment('')
                    setRating('')
                    setRating(0)
                }
            })
        }
        setTimeout(() => {
            dispatch(setFormError("", ""))
        }, 2000);
    }

    return (
        <div>
            <div className='addReview'>
                <div className='recipe__page'>
                    <div className="step">
                        <div className="cancel">
                            <Link to='#' >
                                <span>
                                    <i className="fa fa-arrow-left pr-4" aria-hidden="true" onClick={() => { history.goBack() }}> &nbsp;{lang?.AddReviewAndRatings}</i>
                                </span>
                            </Link>
                        </div>
                    </div>
                    {errMessage.field === "Add_Review" ? (<div><FormError msg={errMessage.msg} /></div>) : null}
                    <div className='ingredientsTab viewRecipe mt-4'>
                        <h4 className='font-20'>{lang?.Reviews}</h4>
                        <div className='contentWrapper pt-2 pb-2'>
                            {spinner ? (<FormSpinner />) : (getReview?.length > 0 &&
                                getReview?.map((item) => {
                                    return (
                                        <Fragment>
                                            <div className='ingredientContent borderBottom pt-2 pb-2'>
                                                <div className='flex flex-wrap items-center'>
                                                    <div className='lg:w-3/5 xl:w-3/5 md:w-full sm:w-full'>
                                                        <div className='colLeftBlock flex items-center'>
                                                            <div className='ingredientImg '>
                                                                <img src={`${item && item.user && item.user.avatar_id && item.user.avatar_id.base_url}${item && item.user && item.user.avatar_id && item.user.avatar_id.attachment_url}`} alt='Profile' />
                                                            </div>
                                                            <h4 className='font-20'>
                                                                <span className='font-16'>{item && item.user && item.user.name}</span>
                                                                <span className='font-14 text-black flex justify-start text-left'>
                                                                    {item.review}
                                                                </span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className='xl:w-2/5 lg:w-2/5 md:w-full sm:w-full'>
                                                        <div className='colRightBlock'>
                                                            <div className='rating text-right'>
                                                                <span className='font-14 flex justify-end'>{moment(item.created_at).fromNow()}</span>
                                                                {isRecipeReview && (
                                                                    <ReactStars
                                                                    count={5}
                                                                    value={item.rating}
                                                                    size={24}
                                                                    edit={false}
                                                                    //onChange={handleRating}
                                                                    color2={'rgb(74 72 72)'} />
                                                                )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            )
                            }
                        </div>
                        <div className='flex justify-between items-center'>
                            <div className="right__align_btn btn-white mt-3">
                                
                            </div>
                            {post && <div className='mt-2'>
                                <div className='colRightBlock'>
                                    <div className='rating text-right'>
                                        <ReactStars
                                            count={5}
                                            value={rating}
                                            size={24}
                                            onChange={handleRating}
                                            edit={true}
                                            color2={'rgb(74 72 72)'} />
                                    </div>
                                </div>
                            </div>}
                            {errMessage.field === "review" ? (<div> <FormError msg={errMessage.msg} /></div>) : null}
                        </div>
                        <div className="user__comment-feed--list">
                            {/* add rating */}
                            {itemUserId != data?.user_data?.user_id && postCount != true && submitField &&
                                <div className="feed-cmnt-form add_cmnt">
                                    <div className="user_pic">
                                        {data?.user_data ? (<img src={`${data.user_data.avatar_id.base_url}${data.user_data.avatar_id.attachment_url}`} />) : (<img src={userProfile} />)}
                                    </div>
                                    <form className="cmnt-form replay_cmnt flex justify-between" onSubmit={(e) => submitPost(e)}>
                                        <input className="cmnt-write widthFull" value={comment} placeholder={lang?.Leave_a_comment} onChange={(e) => { setComment(e.target.value); setPost(true) }} />
                                    </form>
                                </div>
                            }
                            {/* update rating */}
                            {postCount &&
                                <div className="feed-cmnt-form add_cmnt">
                                    <div className="user_pic">
                                        {data?.user_data ? (<img src={`${data?.user_data.avatar_id?.base_url}${data.user_data.avatar_id.attachment_url}`} />) : (<img src={userProfile} />)}
                                    </div>
                                    <form className="cmnt-form replay_cmnt flex justify-between" onSubmit={(e) => updateSubmitPost(e)}>
                                        <input className="cmnt-write widthFull" value={comment} placeholder={lang?.Leave_a_comment} onChange={(e) => { setComment(e.target.value); setPost(true) }} />
                                    </form>
                                    
                                </div>
                            }
                            <div className="right__align_btn btn-white mt-2">
                                {itemUserId === data?.user_data?.user_id && postCount != true && <button className="btn activeBtn" onClick={() => updateReview()}>{lang?.Edit} {lang?.only_Rating}</button>}
                                {postCount && <div className='updateButton'><button className="btn activeBtn" onClick={(e) => updateSubmitPost(e)}>{lang?.Update} {lang?.only_Rating}</button></div>}
                                {itemUserId != data?.user_data?.user_id ?
                                    <button className="btn activeBtn" onClick={(e) => submitPost(e)}>{lang?.only_Submit} {lang?.only_Rating}</button> :
                                    getReview && getReview.length == 0 && <button className="btn activeBtn" onClick={(e) => submitPost(e)}>{lang?.only_Submit} {lang?.only_Rating}</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
