import React, { useState } from 'react';
import Slider from 'react-slick';

import AnimateFromBottom from './AnimateFromBottom';
import language from '../../../helpers/language';

export const SamplePrevArrow = ({ onClick }) => {
  return (
    <div className="slick-arrow slick-prev" onClick={onClick}>
      <i className="fa fa-arrow-left"></i>
    </div>
  );
};

export const SampleNextArrow = ({ onClick }) => {
  return (
    <div className="slick-arrow slick-next" onClick={onClick}>
      <i className="fa fa-arrow-right"></i>
    </div>
  );
};
const Videos = () => {
  const [playingVideo, setPlayingVideo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const lang = language.getLang(); // Retrieve current language

  const videoList = lang.videoList;

  const handlePlayVideo = (id) => {
    setPlayingVideo(id);
    setIsModalOpen(true); // Open modal on video play
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
    setPlayingVideo(null); // Reset video state when modal is closed
  };

  const settings = {
    arrow: true,
    dots:false,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 3,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="md:px-14 px-4 md:py-8 py-5">
        <AnimateFromBottom delay={0.2}>
          <p className="text-lg text-royal mb-2 uppercase font-medium">
            {lang.Videos_title}
          </p>
        </AnimateFromBottom>
        <AnimateFromBottom delay={0.3}>
          <p className="font-bold text-2xl mb-4">{lang.Videos_subtitle}</p>
        </AnimateFromBottom>
      </div>
      <AnimateFromBottom delay={0.4}>
        <div className="md:ml-14 overflow-hidden video-slider">
          <Slider {...settings}>
            {videoList.map((video) => (
              <div key={video.id}>
                <div className="rounded-2xl overflow-hidden mb-3 relative max-h-48">
                     <img
                        src={video.image}
                        className="w-full"
                        alt={video.title}
                      />
                      <button
                        className="bg-black opacity-50 absolute top-2/4 left-1/2 rounded-full flex w-10 h-10 justify-center items-center cursor-pointer translate-middle"
                        onClick={() => handlePlayVideo(video.id)}
                      >
                        <i
                          className="fas fa-play text-xl text-white p-2"
                          aria-hidden="true"
                        ></i>
                      </button>
                   
                </div>
                <div className="bg-skyblue text-royal inline-flex p-2 gap-2 items-center">
                  <i className="fa fa-check-circle"></i>
                  <p className="font-semibold">{video.title}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </AnimateFromBottom>

      {/* Video Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg w-11/12 md:w-1/2 relative">
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 text-white text-3xl"
              onClick={handleCloseModal}
              style={{
                zIndex: 1000, // Ensure the button is on top of other content
              }}
            >
              &times;
            </button>
            <iframe
              className="w-full h-96"
              src={videoList.find((video) => video.id === playingVideo)?.videoUrl}
              title={videoList.find((video) => video.id === playingVideo)?.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default Videos;
