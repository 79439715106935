import { useEffect, useState } from "react";
// REACT_APP_API
import REACT_APP_API from '../../../config/environment'
export default function ShippingModule(props) {
  const [showShipping, setShowShipping] = useState(false);
  const handleShowShipping = () => {
    !showShipping ? setShowShipping(true) : setShowShipping(false);
  };
  const [loading,setLoading] = useState(false)
  const [ranges, setRanges] = useState("");
  const [zones, setZones] = useState("");
  const [destinations, setDestinations] = useState("");
  const [states, setStates] = useState("");
  const [formEntries, setFormEntries] = useState([
    {
      destination_id: "",
      quantity_id: "",
      price: "",
      zones: [],
      states: [],
    },
  ]);

  const getApidata = async () => {
    let api_url = `${REACT_APP_API.ENV.REACT_APP_API}/shipping/form`
            
    const apiData = await fetch(api_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await apiData.json();
    console.log(data, "api data");
    setRanges(data?.ranges);
    setZones(data?.zones);
    setDestinations(data?.destinations);
    setStates(data?.states);
  };
  useEffect(() => {
    getApidata();
  }, []);

  const shipingDetailHandler = (
    index,
    data,
    stateOrZone,
    stateOrZoneIndex,
    deleteField
  ) => {
    const updatedData = [...formEntries];
    if (stateOrZone) {
      const updateStateOrZone = updatedData?.[index]?.[stateOrZone] || [];
      console.log(updatedData?.[stateOrZone]?.[stateOrZoneIndex]);
      updateStateOrZone[stateOrZoneIndex] = {
        ...updateStateOrZone?.[stateOrZoneIndex],
        ...data,
      };
      console.log(updateStateOrZone,'checking updated zone and state');
      updatedData[index] = {
        ...updatedData?.[index],
        [stateOrZone]: [...updateStateOrZone],
      };
    } else {
      updatedData[index] = { ...updatedData?.[index], ...data };
    }
    if (deleteField) delete updatedData[deleteField];
    setFormEntries(updatedData);
  };

  const handleAddEntry = () => {
    setFormEntries([
      ...formEntries,
      {
        destination_id: "",
        quantity_id: "",
        price: "",
        zones: [],
        states: [],
      },
    ]);
  };



  const transformedEntries = (formEntries) => {
    let transformedData = {
      product_id : 558,
      shipping : []
    }

    formEntries.forEach(entry =>{
      console.log(entry);
      
    let destinationId;


    if (entry.destination_id == 'anywhere_in_usa') {
      destinationId = 1;  
    } else if (entry.destination_id == 'us_zones') {
      destinationId = 2;  
    } else if (entry.destination_id == 'states') {
      destinationId = 3; 
    }

      if(destinationId ===1){
        transformedData.shipping.push({
          destination_id: destinationId,
      quantity_id: entry.quantity_id,
      price: entry.price
        })
      }else if(destinationId===2){
        if (entry.zones && entry.zones.length > 0) {}
        transformedData.shipping.push({
          destination_id: destinationId,
          quantity_id: entry.quantity_id,
          zones: entry.zones
            .filter(zone => zone && zone.zone_id)  
            .map(zone => ({
              zone_id: zone.zone_id,
              price: zone.price || '0'  
            }))
        });
      }else if(destinationId===3){
        if (entry.states && entry.states.length > 0) {
          transformedData.shipping.push({
            destination_id: destinationId,
            quantity_id: Number(entry.quantity_id),
            states: entry.states
              .filter(state => state && state.state_id)  
              .map(state => ({
                state_id: state.state_id,
                price: state.price || '0'  
              }))
          });
        }
  
      }
      
    })
    console.log(transformedData,'actual data');
    
    return transformedData
    
  }

  // transformedEntries(formEntries)

  const handleSubmitButton = async() =>{
    setLoading(true); 
    try {

      const token = localStorage.getItem("ACCESS_TOKEN")
      let api_url = `${REACT_APP_API.ENV.REACT_APP_API}/shipping/save`
            
      const response = await fetch(api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
           'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(transformedEntries(formEntries)),
        
      });
      const result = await response.json();
    console.log(result,'api modified data response');
        console.log(formEntries,'checking form entries format');
        
      if (response.ok) {
        const responseData = await response.json();
        console.log('Data sent successfully:', responseData);
        alert('Shipping data saved successfully!');
      } else {
        const errorData = await response.json();
        console.error('Error saving data:', errorData);
        alert('Failed to save shipping data!');
      }
    } catch (error) {
      console.error('Error during API request:', error);
    } finally {
      setLoading(false);
    }
  }

  


  return (
    <>
      <h4 className="secBlockTitle flex gap-2">
        <input type="checkbox" onChange={handleShowShipping} />
        Shipping
      </h4>
      {showShipping && (
        <>
          {formEntries.map((entry, index) => (
            <div className="marketplace add-product" key={entry.id}>
              <div className="mrkt-place form">
                <div className="card-form">
                  <div className="flex flex-wrap main-row gap-2">
                    <div className="xl:w-3/12 lg:w-3/12 md:w-full sm:w-full col-padding">
                      <div className="w-100">
                        <div className="form__item">
                          <label>Quantity Range</label>
                          <select
                            value={entry?.quantity_id}
                            onChange={(e) =>
                              shipingDetailHandler(index, {
                                quantity_id: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Range</option>
                            {ranges.map((range) => (
                              <option key={range.id} value={range.id}>
                                {range?.range}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="xl:w-3/12 lg:w-3/12 md:w-full sm:w-full col-padding">
                      <div className="w-100">
                        <div className="form__item">
                          <label>Destination</label>
                          <select
                            value={entry.destination_id}
                            onChange={(e) =>
                              shipingDetailHandler(index, {
                                destination_id: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Destination</option>
                            <option value="anywhere_in_usa">
                              Anywhere in USA
                            </option>
                            <option value="states">States</option>
                            <option value="us_zones">US Zones</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="xl:w-3/12 lg:w-3/12 md:w-full sm:w-full col-padding">
                      {entry.destination_id == "" && (
                        <div className="w-100">
                          <div className="form__item">
                            <label>Price</label>
                            <input
                              type="text"
                              //value = {entry.price}
                              onChange={(e) =>
                                shipingDetailHandler(index, {
                                  price: e.target.value,
                                })
                              }
                              placeholder="$10.00"
                              style={{
                                padding: "10px",
                                fontSize: "14px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                color: "#000",
                                opacity: "0.5",
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {entry.destination_id == "anywhere_in_usa" && (
                        <div className="w-100">
                          <div className="form__item">
                            <label>Price</label>
                            <input
                              type="text"
                              onChange={(e) =>
                                shipingDetailHandler(index, {
                                  price: e.target.value,
                                })
                              }
                              placeholder="$10.00"
                              style={{
                                padding: "10px",
                                fontSize: "14px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                color: "#000",
                                opacity: "0.5",
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {entry.destination_id == "states" && (
                        <div className="w-100">
                          <div className="form__item">
                            <label>States</label>
                            {states.length > 0 ? (
                              <div>
                                {states.map((state, i) => (
                                  <div key={state.id} className="state-option">
                                    <input
                                      type="checkbox"
                                      id={state.id}
                                      checked={
                                        formEntries?.[index]?.states?.[i]
                                          ?.state_id === state.id
                                      }
                                      onChange={() =>
                                        shipingDetailHandler(
                                          index,
                                          { state_id: state.id },
                                          "states",
                                          i,
                                          "zones"
                                        )
                                      }
                                    />
                                    <label htmlFor={`state-${state.id}`}>
                                      {state.name}
                                    </label>

                                    <input
                                      type="text"
                                      placeholder={`$10.00`}
                                      className="state-input"
                                      onChange={(e) =>
                                        shipingDetailHandler(
                                          index,
                                          {
                                            price: e.target.value,
                                          },
                                          "states",
                                          i,
                                          "zones"
                                        )
                                      }
                                      style={{
                                        padding: "10px",
                                        fontSize: "14px",
                                        border: "1px solid #ccc",
                                        borderRadius: "5px",
                                        color: "#000",
                                        opacity: "0.5",
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p>Loading states...</p>
                            )}
                          </div>
                        </div>
                      )}
                      {entry.destination_id == "us_zones" && (
                                      <div className="w-100">
                                        <div className="form__item">
                                          <label>US Zones</label>
                                          {zones.length > 0 ? (
                                            <div>
                                              {zones.map((zone, i) => (
                                                <div
                                                  key={zone.id}
                                                  className="zone-option"
                                                >
                                                  <input
                                                    type="checkbox"
                                                    id={`zone-${zone.id}`}
                                                    checked={
                                                      formEntries?.[index]?.zones?.[i]
                                                        ?.zone_id ===zone.id
                                                    }
                                                    onChange={() =>
                                                      shipingDetailHandler(
                                                        index,
                                                        { zone_id: zone.id },
                                                        "zones",
                                                        i,
"states"

                                                      )
                                                    }
                                                  />
                                                  <label
                                                    htmlFor={`zone-${zone.id}`}
                                                  >
                                                    {zone.name}
                                                  </label>

                                                  <input
                                                    type="text"
                                                    placeholder={`$10.00`}
                                                    className="zone-input"
                                                    onChange={(e) =>
                                                      shipingDetailHandler(
                                                        index,
                                                        {
                                                          price: e.target.value,
                                                        },
                                                        "zones",
                                                        i,
                                                        "states"
                                                      )
                                                    }
                                                    style={{
                                                      padding: "10px",
                                                      fontSize: "14px",
                                                      border: "1px solid #ccc",
                                                      borderRadius: "5px",
                                                      color: "#000",
                                                      opacity: "0.5",
                                                    }}
                                                  />
                                                </div>
                                              ))}
                                            </div>
                                          ) : (
                                            <p>Loading zones...</p>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    <span>
                          <button type="button" onClick={handleAddEntry}>
                            +
                          </button>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <button className="btn btn-success" onClick={handleSubmitButton}>Save</button>
        </>
      )}
    </>
  );
}
