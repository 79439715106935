import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Import images
import missionImg from '../../../assets/landing-page/mission-img.png';
import growthImg from '../../../assets/landing-page/growth-img.png';
import investmentImg from '../../../assets/landing-page/investment-img.png';
import letsStartImg from '../../../assets/landing-page/lets-start.png';
import AnimateFromBottom from './AnimateFromBottom';
import language from "../../../helpers/language";
const OurMission = () => {
    const [lang] = useState(language.getLang());
    const [playingVideo, setPlayingVideo] = useState(false);

    const handlePlayVideo = () => {
        setPlayingVideo(true);
    };

    return (
      <>
         <AnimateFromBottom>
            <div className='md:px-14 px-4 md:py-8 py-5'>
                <div className='flex md:gap-10 gap-5 md:flex-row flex-col items-center'>
                    <div className='w-55'>
                        <p className='text-lg text-royal mb-2 uppercase font-medium '>
                            {lang?.Our_Mission}
                        </p>
                        <p className='font-bold text-2xl mb-4'>“{lang?.You_can_never_cross}”</p>
                        <p className='text-dim font-medium text-sm mb-3'>
                            {lang?.Alysei_bridges_the_gap}
                        </p>
                        <p className='text-dim font-medium text-sm mb-3'> {lang?.While_embracing_growth}
                        </p>
                        <div className='flex gap-2 flex-wrap'>
                            <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
                                <i className='fa fa-check-circle'></i>
                                <p className='font-semibold'>{lang?.From_Farm}</p>
                            </div>
                            <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
                                <i className='fa fa-check-circle'></i>
                                <p className='font-semibold'>{lang?.The_Premier_B2B}</p>
                            </div>
                            <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
                                <i className='fa fa-check-circle'></i>
                                <p className='font-semibold'>{lang?.B2B_Connections}</p>
                            </div>
                            <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
                                <i className='fa fa-check-circle'></i>
                                <p className='font-semibold'>{lang?.Elevate_Your_Brand}</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-45'>
                        <img src={missionImg} className='w-full h-auto' alt="Our Mission" />
                    </div>
                </div>
            </div>
        </AnimateFromBottom>
        <AnimateFromBottom>
            <div className='md:px-14 px-4 md:py-8 py-5'>
                <div className='flex md:gap-10 gap-5 md:flex-row flex-col-reverse items-center'>
                    <div className='w-45'>
                        <img src={growthImg} className='w-full h-auto' alt="Growth" />
                    </div>
                    <div className='w-55'>
                        <p className='text-lg text-royal mb-2 uppercase font-medium'>
                        {lang?.Growth}
                        </p>
                        <p className='font-bold text-2xl mb-4'>{lang?.Market_Expansion_Blueprint}</p>
                        <p className='text-dim font-medium text-sm mb-3'>{lang?.Numerous_companies}
                        </p>
                        <p className='text-dim font-medium text-sm mb-3'>{lang?.A_recent_study}
                        </p>
                        <p className='text-dim font-medium text-sm mb-3'>{lang?.It_is_important}
                        </p>
                        <div className='flex gap-2 flex-wrap'>
                            <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
                                <i className='fa fa-check-circle'></i>
                                <p className='font-semibold'>{lang?.Origin_significantly}</p>
                            </div>
                            <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
                                <i className='fa fa-check-circle'></i>
                                <p className='font-semibold'>{lang?.Strategic_marketing}</p>
                            </div>
                            <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
                                <i className='fa fa-check-circle'></i>
                                <p className='font-semibold'>{lang?.Expand_international}</p>
                            </div>
                            <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
                                <i className='fa fa-check-circle'></i>
                                <p className='font-semibold'>{lang?.Consumers_are_willing}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AnimateFromBottom>
        <AnimateFromBottom>
            <div className='md:px-14 px-4 md:py-8 py-5'>
                <div className='flex md:gap-10 gap-5 md:flex-row flex-col items-center'>
                    <div className='w-55'>
                        <p className='text-lg text-royal mb-2 uppercase font-medium'>
                        {lang?.Investment} 
                        </p>
                        <p className='font-bold text-2xl mb-4'>{lang?.Major_Opportunities}
                        </p>
                        <p className='text-dim font-medium text-sm mb-3'>{lang?.With_over}
                        </p>
                        <p className='text-dim font-medium text-sm mb-3'>{lang?.Alysei_provides}
                        </p>

                        <div className='flex gap-2 flex-wrap'>
                            <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
                                <i className='fa fa-check-circle'></i>
                                <p className='font-semibold'>{lang?.Assess_US}</p>
                            </div>
                            <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
                                <i className='fa fa-check-circle'></i>
                                <p className='font-semibold'>{lang?.Identify_your_target}</p>
                            </div>
                            <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
                                <i className='fa fa-check-circle'></i>
                                <p className='font-semibold'>{lang?.Find_suitable}</p>
                            </div>
                            <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
                                <i className='fa fa-check-circle'></i>
                                <p className='font-semibold'>{lang?.Connect_directly}</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-45 w-full relative h-60 md:h-80'>
                        {playingVideo ? (
                            <iframe
                                className='w-full h-full'
                                src="https://www.youtube.com/embed/s8srZAmlIgE?autoplay=1"
                                title="Investment Video"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <>
                                <img src={investmentImg} className='w-full h-full' alt="Investment" />
                                <button
                                    className='bg-black absolute translate-middle top-2/4 left-1/2 rounded-full flex w-10 h-10 justify-center items-center cursor-pointer'
                                    onClick={handlePlayVideo}
                                >
                                    <i className="fas fa-play text-xl text-white p-2"></i>
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </AnimateFromBottom>
        <AnimateFromBottom>
        <div className='md:px-14 px-4 md:py-8 py-5'>
            <div className='flex md:gap-10 gap-5 md:flex-row flex-col-reverse items-center'>
<div className='w-45'>
    <img src={letsStartImg} className='w-full h-auto'/>
      </div>
      <div className='w-55'> 
    <p className='text-lg text-royal mb-2 uppercase font-medium'>
    {lang?.Lets_Start}
          </p>
          <p className='font-bold text-2xl mb-4'>{lang?.Where_to_Begin}</p>
    <p className='text-dim font-medium text-sm mb-2'>{lang?.The_first_step}
    </p>
    <p className='text-dim font-medium text-sm mb-2'>{lang?.Here_you_can}</p>
    <p className='text-dim font-medium text-sm mb-2'>{lang?.Alysei_is_a_platform_designed}</p>
    <div className='flex gap-2 flex-wrap'>
        <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
        <i className='fa fa-check-circle'></i>
            <p className='font-semibold'>{lang?.Complimentary_Registration}</p>
        </div>
        <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
            <i className='fa fa-check-circle'></i>
            <p className='font-semibold'>{lang?.Thorough_Verification}</p>
        </div>
        <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
            <i className='fa fa-check-circle'></i>
            <p className='font-semibold'>{lang?.Achieve_Alysei}</p>
        </div>
        <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
            <i className='fa fa-check-circle'></i>
            <p className='font-semibold'>{lang?.Showcase_Your_Brand}</p>
        </div>
    </div>
</div>
</div> 
            </div>
        </AnimateFromBottom>
           
        </>
    );
};

export default OurMission;





{/* <div className='flex md:gap-10 gap-5 md:flex-row flex-col-reverse items-center'>
<div className='w-45'>
    <img src="/landing-page/lets-start.png" className='w-full h-auto'/>
      </div>
      <div className='w-55'> 
    <p className='text-lg text-royal mb-2 uppercase font-medium'>
      Let's Start
          </p>
          <p className='font-bold text-2xl mb-4'>Where to Begin?</p>
    <p className='text-dim font-medium text-sm mb-2'>The first step is to log into the Alysei platform, where a thorough verification and approval process
ensures quality and safety.
    </p>
    <p className='text-dim font-medium text-sm mb-2'>As an Alysei Certified Member, you can complete your Company Profile, which will serve as your 
market showcase.
    </p>
    <p className='text-dim font-medium text-sm mb-2'>Here, you can share your story, highlight your flagship products, and create posts in a social 
environment that allows you to connect directly with customers.

    </p>
    <p className='text-dim font-medium text-sm mb-2'>You will also gain access to the Alysei B2B Marketplace, where you can build and promote your 
    online store, presenting your products to potential import channels, buyers, and beyond.</p>
    <p className='text-dim font-medium text-sm mb-2'>Alysei is a platform designed for those passionate about Italian food and wine. It serves as a 
business hub for fostering collaboration and growth, providing a window into a landscape rich 
with development opportunities.</p>
    <div className='flex gap-2 flex-wrap'>
        <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
        <i className='fa fa-check-circle'></i>
            <p className='font-semibold'>Complimentary Registration</p>
        </div>
        <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
            <i className='fa fa-check-circle'></i>
            <p className='font-semibold'>Thorough Verification and Approval Process</p>
        </div>
        <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
            <i className='fa fa-check-circle'></i>
            <p className='font-semibold'>Achieve Alysei Certified Member Status</p>
        </div>
        <div className='bg-skyblue text-royal flex p-2 gap-2 items-center'>
            <i className='fa fa-check-circle'></i>
            <p className='font-semibold'>Showcase Your Brand, Products, and Story</p>
        </div>
    </div>
</div>
</div> */}