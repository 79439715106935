import React, { useState, useEffect } from 'react';
import default_Image from '../../../assets/images/default_image.jpg';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import ReactPaginate from 'react-paginate';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import {
  viewAllProductAndStoreData,
  getItalianRegion,
} from '../modules/marketplace';
import auth from '../../../helpers/auth';
import constant from '../../../helpers/constant';
import language from '../../../helpers/language';
import CustomUseEffect from '../../../components/constants';

export default function ViewAllStoreAndProduct(props) {
  const [lang] = useState(language.getLang());
  const [viewAllData, setViewAllData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { type, id } = useParams();
  const [user, setUser] = useState([]);
  const CUTTING_EXPRESSION = /\s+[^\s]*$/;

  CustomUseEffect(async () => {
    window.scrollTo(0, 0);
    const pageNo = 1;
    const response = await dispatch(viewAllProductAndStoreData(id, pageNo));
    await setViewAllData(response);
    italianRegions &&
      italianRegions.length === 0 &&
      (await dispatch(getItalianRegion()));
    const user = await auth.getUserType();
    await setUser(JSON.parse(user));
  }, [id]);

  let { italianRegions, marketPlaceSpinner } = useSelector(
    (state) => state.marketPlaceReducerData
  );

  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    const response = await dispatch(
      viewAllProductAndStoreData(id, selectedPage)
    );
    await setViewAllData(response);
  };

  const textLimit = (text, limit) => {
    if (text?.length > limit) {
      const part = text?.slice(0, limit - 3);
      if (part.match(CUTTING_EXPRESSION)) {
        return part.replace(CUTTING_EXPRESSION, ' ...');
      }
      return part + '...';
    }
    return text;
  };

  console.log(viewAllData,"setviewAll")
  return (
    <div className="marketplace producerScore mrkt-place-three newGridView allProduct">
      {/* Banner Section */}
      <div className="">
        <div className="section__wrapper">
          <div className="">
            <div className="">
              <div
                className="flex justify-between items-center mb-3"
                onClick={() => history.goBack()}
              >
                <a href="javascript:void(0)" className="back__btn">
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                  &nbsp;
                  {lang?.Go_back}
                </a>
              </div>
              <h4 className="product-title black mt-0 fs-16">
                <span className="">
                  {lang?.Showing}{' '}
                  {viewAllData && viewAllData.total && viewAllData.total}{' '}
                  {lang?.results}
                </span>
              </h4>
              <div className="product-main">
                <div className="product-type">
                  {/*Put the scroll bar always on the bottom*/}
                  <div className='card-block-wrapper grid4' id="scrollableDiv">
                    {marketPlaceSpinner ? (
                      <FormSpinner />
                    ) :
                      viewAllData?.data?.length > 0 ? (
                        viewAllData?.data?.map((item) => {
                          let region_name = italianRegions
                            .filter((items) => items.id == item.store_region)
                            .map((item) => item.name);
                          return (
                            <>
                              {parseInt(id) === 2 ? (
                                <div className="card-block-item mt-2 mb-2">
                                  <div className="card-item-wrapper">
                                    <div className="card-item-img">
                                      <div className="item-img">
                                        <Link
                                          to={`/marketplace/added-store/${item.marketplace_store_id}`}
                                        >
                                          <img
                                            src={`${item.logo_base_url}${item.logo_id}`}
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-item-content">
                                    <div className="card-content-wrapper">
                                      <div className="flex flex-wrap justify-between mt-3">
                                        <h4 className="font-18 black mt-0">
                                        {/*textLimit(item.title,20)*/}
                                       {textLimit(item.name,20)}
                                        </h4>
                                      </div>
                                      <p className="mt-2 mb-2 paraparahideMob">
                                      {textLimit(item.description,60)}{' '}
                                      </p>
                                      <div className='flexBox items-center justify-between'>
                                        <h4 className="font-16">{item.region.name}</h4>
                                          
                                        <h4 className="count_no font-16 text-teal">
                                          {item.product_category_name}
                                        </h4>
                                      </div>
                                      <div className="flex flex-wrap justify-between items-center mt-2">
                                        <div className='flex flex-wrap items-center mt-2'>
                                          <div className='rating__bg flex items-center'>
                                            {/* <h4 className="font-12">
                                              {item.avg_rating}
                                            </h4>{' '}&nbsp; */}
                                            <span className='flex items-center gap-1'>
                                              {item.avg_rating}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          </div>
                                          <h4 className='font-12 pl-2'>
                                            &nbsp;{item.total_reviews}{' '}
                                            {item.total_reviews <= 1
                                              ? lang?.review
                                              : lang?.reviews}
                                            </h4>
                                        </div>
                                        <h4 className="count_no font-16 text-teal justify-between">
                                          {item?.count_category > 1 && (
                                            <span className="more_count">
                                              {' '}
                                              +{item?.count_category - 1} more
                                            </span>
                                          )}
                                      </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="card-block-item mt-2 mb-2">
                                  {' '}
                                  <div className="card-item-wrapper">
                                    <div className='card-item-img'>
                                      <div className='item-img'>
                                      <Link
                                          to={`/marketplace/product/${item.slug}`}
                                        >
                                          {item.product_gallery &&
                                            item.product_gallery.length > 0 ? (
                                            <img
                                              src={`${item.product_gallery &&
                                                item.product_gallery[0].base_url
                                                }${item.product_gallery &&
                                                item.product_gallery[0]
                                                  .attachment_url
                                                }`}
                                              alt="Image"
                                            />
                                          ) : (
                                            <img
                                              src={default_Image}
                                              alt="default-Image"
                                            />
                                          )}
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='card-item-content'>
                                    <div className='card-content-wrapper'>
                                    <div className="flex flex-wrap justify-between mt-3 items-center">
                                        <h4 className="font-18 black sm-w-full productName">
                                          {textLimit(item.title,20)}
                                          
                                        </h4>
                                      
                                        {(user &&
                                          user.role_id ==
                                          constant.rolesId[
                                          'importer&distributer'
                                          ]) ||
                                          user.role_id ==
                                          constant.rolesId.importer ||
                                          user.role_id ==
                                          constant.rolesId.distributer ? (
                                          <h4 className="font-18 black sm-w-full sm--mt-1">{item.product_price !== null ? `$${item.product_price}` : null}</h4>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                      <p className="mt-2 mb-2 parahideMob">
                                        {textLimit(item.description,60)}{' '}
                                      </p>
                                      <div className="flex flex-wrap justify-between items-center">
                                          <h4 className="font-16 storeName">{item.store_name}</h4>
                                          <h4 className="count_no font-16 text-teal">
                                            {item.product_category_name}
                                          </h4>
                                      </div>
                                      <div className="flex flex-wrap items-center mt-2">
                                        <div className='rating__bg flex items-center'>
                                            {/* <h4 className="font-12"> */}
                                            {/* </h4> */}
                                            <span className='flex items-center gap-1'>
                                              {item.avg_rating}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </div>
                                          <h4 className="font-12 pl-2">
                                            {item.total_reviews} &nbsp;
                                            {item.total_reviews.length <= 1
                                              ? lang?.review
                                              : lang?.reviews}
                                          </h4>
                                      </div>
                                      <div className="flex flex-wrap items-center mt-2">
                                      {user.role_id ==
                                          constant.rolesId.voyagers ||
                                          user.role_id ==
                                          constant.rolesId.travelAgencies ||
                                          user.role_id ==
                                          constant.rolesId.restaurant ||
                                          user.role_id == constant.rolesId.producer
                                          ? null
                                          : item.available_for_sample ==
                                          'Yes' && (
                                            <div>
                                              <span className="font-16 text-black">
                                                {lang?.Available_For_Sample}
                                              </span>
                                              <i
                                                className="fa fa-check-circle pl-4"
                                                aria-hidden="true"
                                              />
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <div className="notFound text-center mt-4 mb-4">
                          <h1>{lang?.No_data_found}</h1>
                        </div>
                      )}
                  </div>
                  {viewAllData?.total > 5 && (
                    <ReactPaginate
                      previousLabel={'prev'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={Math.ceil(
                        viewAllData.total / viewAllData.per_page
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
