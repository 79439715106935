import React, { useState } from 'react'
import language from "../../../helpers/language";
const Banner = () => {
  const [lang] = useState(language.getLang());
  return (
    <div className=' md:px-64 px-8  md:pt-40 pt-28 md:pb-24 pb-10 text-center service-banner'>
      {/* <p className='text-lg text-royal mb-3 font-medium'>FAQ</p> */}
          <h2 className='md:text-5xl text-3xl font-bold mb-4'>{lang.faq} </h2>
      {/* <p className='text-dim text-base font-medium'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pellentesque 
      neque eget diam posuere porta.</p> */}
    </div>
  )
}

export default Banner