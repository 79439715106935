import React from 'react'
import { Helmet } from 'react-helmet'
const ItalianRestaurant = () => {
  return (
   <Helmet>
    {/* <!-- Primary Meta Tags --> */}
<title>Register as Italian Restaurant USA | Alysei</title>
<meta name="description" content="Join Alysei as an Italian restaurant to connect with top Italian food suppliers, discover exclusive products, and enhance your menu with authentic ingredients." />
<meta name="keywords" content="register Italian restaurant USA, Italian food suppliers USA, authentic Italian ingredients USA, connect with Italian food suppliers USA, Alysei Italian restaurant network, exclusive Italian products USA, enhance restaurant menu USA, Italian restaurant suppliers USA, restaurant ingredients USA, authentic Italian menu USA" />
<link rel="canonical" href="https://alysei.com/register/italian-restaurants" />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:title" content="Register as Italian Restaurant USA | Alysei" />
<meta property="og:description" content="Join Alysei as an Italian restaurant to connect with top Italian food suppliers, discover exclusive products, and enhance your menu with authentic ingredients." />
<meta property="og:url" content="https://alysei.com/register/italian-restaurants" />
<meta property="og:site_name" content="Alysei" />
<meta property="og:image" content="https://alysei.com/dist/images/app-mockup.png" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary" />
<meta property="twitter:url" content="https://alysei.com/register/italian-restaurants" />
<meta property="twitter:title" content="Register as Italian Restaurant USA | Alysei" />
<meta property="twitter:description" content="Join Alysei as an Italian restaurant to connect with top Italian food suppliers, discover exclusive products, and enhance your menu with authentic ingredients." />
<meta property="twitter:image" content="https://alysei.com/dist/images/app-mockup.png" />


   </Helmet>
  )
}

export default ItalianRestaurant