import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import mailIcon from "../../../assets/landing-page/mail-icon.svg";
import waIcon from "../../../assets/landing-page/whatsapp-sm.svg";
import language from "../../../helpers/language";
import { toast } from "react-toastify";
import checkIcon from "../../../assets/landing-page/check-icon.svg";
import Modal from "react-modal";
import errorIcon from "../../../assets/landing-page/error-icon.svg";
import REACT_APP_API from "../../../config/environment";
import { Oval } from "react-loader-spinner";
import ButtonRounded from "./buttonRounded";
import Header from "./Header";
import SeoContact from "../../../components/Seo-helmet/SeoContact";
// import SeoLanding from "../../../components/Seo-helmet/Seo-Landing";
const Contact = () => {
  const [lang] = useState(language.getLang());
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const {
    profileData: { data },
    formValue,
  } = useSelector((state) => state.memberProfileData);

  console.log(formValue, "checking store form value");
  const locale = localStorage.getItem("lang");

  const handleCloseSuccessModal = () => {
    setSuccess(false);
    setError(null);
  };

  const handleCloseErrorModal = () => {
    document.getElementById("myForm").reset();
    setSuccess(!success);
    setError(null);
  };
  console.log(formRef, "checking form ref");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formValues = {
      first_name: formData.get("first_name"),
      last_name: formData.get("last_name"),
      email: formData.get("email"),
      company_name: formData.get("company_name"),
      message: formData.get("message"),
    };
    console.log(formValues, "lang");

    console.log(formValues.language, "checking form values");

    if (
      !formValues.first_name ||
      !formValues.last_name ||
      !formValues.email ||
      !formValues.message
    ) {
      toast.error("Please fill all required fields.");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/contact/us`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
            "Content-Type": "application/json",
            locale: locale,
          },
          body: JSON.stringify(formValues),
        }
      );

      const result = await response.json();
      console.log(result, "checking result values");

      if (result.success) {
        setSuccess(true);
      } else {
        toast.error("Something went wrong, please try again!");
      }
    } catch (error) {
      
      toast.error("Error sending the message. Please try again later.");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    {/* @@ SEO */}
    <SeoContact />
    
       <Header/>
      <div className="contact-bg flex md:flex-row flex-col md:gap-14 gap-5 justify-between md:px-14 px-4 md:py-8 py-5 fixed w-full ">
      <div className="md:w-3/12">
        <p className="text-lg text-royal mb-2 uppercase font-medium">
          {lang.Contact_Us}
        </p>
        <p className="font-bold text-4xl mb-4">{lang.Lets_Get_In_Touch}</p>
        <p className="text-dim font-medium text-sm mb-4">
          {lang.Complete_The_Form}
        </p>
        <div className="flex gap-2 items-center mb-3">
          <img src={mailIcon} alt="mail" className="w-7" />
          <p className="font-bold">info@alysei.com</p>
        </div>
        <div className="flex gap-2 items-center">
          <img src={waIcon} alt="call" className="w-7" />
          <p className="font-bold">+1 (312) 286-4961</p>
        </div>
      </div>

      <div className="md:w-8/12 input-container mb-10 md:mb-0">
        <form onSubmit={handleSubmit} id="myForm">
          <div className="grid md:grid-cols-2 gap-2 mb-3">
            <div className="input-group">
              <label>{lang.First_Name}*</label>
              <input
                type="text"
                placeholder={lang.First_Name}
                name="first_name"
                required
              />
            </div>
            <div className="input-group">
              <label>{lang.Last_Name}*</label>
              <input
                type="text"
                placeholder={lang.Last_Name}
                name="last_name"
                required
              />
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-2 mb-3">
            <div className="input-group">
              <label>{lang.Company_Name}</label>
              <input
                type="text"
                placeholder={lang.Company_Name}
                name="company_name"
              />
            </div>
            <div className="input-group">
              <label>{lang.Email}*</label>
              <input
                type="email"
                placeholder={lang.Email}
                name="email"
                required
              />
            </div>
          </div>

          <div className="input-group mb-5">
            <label>{lang.Message}*</label>
            <textarea
              placeholder={lang.Write_Here}
              rows={3}
              name="message"
              required
            ></textarea>
          </div>

          <div className="flex justify-between">
            {loading ? (
              <button
                type="submit"
                className="btn-royal w-36 flex justify-center cursor-not-allowed"
              >
                <Oval
                  visible={true}
                  height="20"
                  width="20"
                  color="#fff"
                  ariaLabel="tail-spin-loading"
                  radius="4"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </button>
            ) : (
              <button type="submit" className="btn-royal w-36 text-center">
                {lang.Send_Message}
              </button>
            )}
          </div>
        </form>
      </div>

      {success && (
        <Modal ariaHideApp={false} isOpen={success}>
          <div className="flex justify-center items-center flex-col gap-3">
          <i className='fa fa-check-circle text-royal text-6xl'></i>
            <p className="font-bold text-xl text-center">{lang.thank_you}</p>
            <button
              className="border-btn-royal py-2 px-3 font-semibold inline"
              onClick={handleCloseSuccessModal}
            >
              {lang.close}
            </button>
          </div>
        </Modal>
      )}

      {error && (
        <Modal ariaHideApp={false} isOpen={!!error}>
          <div className="flex justify-center items-center flex-col gap-3">
            <img src={errorIcon} className="w-20" />
            <p className="font-bold text-xl text-center">{error}</p>
            <button
              className="rounded-md bg-red-500 text-white py-2 px-3 font-semibold inline"
              onClick={handleCloseSuccessModal}
            >
              {lang.close}
            </button>
          </div>
        </Modal>
      )}
    </div>
    </>
    
  );
};

export default Contact;
