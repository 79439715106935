import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import fAndBProducerImg from '../../../assets/landing-page/f&b-producer.png';
import importersImg from '../../../assets/landing-page/importers.png';
import italianRestaurantImg from '../../../assets/landing-page/italian-restaurent.png';
import voiceOfExpertsImg from '../../../assets/landing-page/expert-voice.png';
import travelAgencyImg from '../../../assets/landing-page/travel-agency.png';
import voyagerImg from '../../../assets/landing-page/voyager.png';
import buyerImg from '../../../assets/landing-page/buyer.png';
import logoSmImg from '../../../assets/landing-page/logo-sm.png';
import howToHelpImg from '../../../assets/landing-page/how-to-help.png';
import AnimateFromBottom from './AnimateFromBottom';
import language from "../../../helpers/language";

const Members = () => {
    const history = useHistory();
    const lang = language.getLang();

   

    return (
      <div className='md:px-14 md:py-8 px-4 py-5'>
        <AnimateFromBottom>
          <p className='text-lg text-royal mb-2 uppercase font-medium'>{lang.Members_title}</p>
        </AnimateFromBottom>
        <AnimateFromBottom delay={0.2}>
          <p className='font-bold text-2xl mb-4'>{lang.Members_quote}</p>
        </AnimateFromBottom>
        <AnimateFromBottom delay={0.5}> 
          <div className='grid md:grid-cols-4 gap-4 mt-4'>
            {[
              {
                img: fAndBProducerImg,
                title: lang.Italian_FB_Producers,
                description: lang.Italian_FB_Producers_Description_m,
                slug: 'italian-food-beverage-producers',
              },
            
              {
                img: importersImg,
                title: lang.Importers_Distributors,
                description: lang.Importers_Distributors_Description_m,
                slug: 'importers-distributors',
              },
              {
                img: buyerImg,
                title: lang.Buyers,
                description: lang.Buyers_Description_m,
                slug: 'buyers',
              },
              {
                img: italianRestaurantImg,
                title: lang.Italian_Restaurants,
                description: lang.Italian_Restaurants_Description_m,
                slug: 'italian-restaurants',
              },
              {
                img: voiceOfExpertsImg,
                title: lang.Voice_of_Experts,
                description: lang.Voice_of_Experts_Description_m,
                slug: 'voice-of-experts',
              },
              {
                img: travelAgencyImg,
                title: lang.Travel_Agencies,
                description: lang.Travel_Agencies_Description_m,
                slug: 'travel-agencies',
              },
              {
                img: voyagerImg,
                title: lang.Voyagers,
                description: lang.Voyagers_Description_m,
                slug: 'voyagers',
              },
             
            ].map(({ img, title, description, slug  }, idx) => (
              <Link
              to={`/services/${slug}`}
                key={idx}
                className='rounded-md border border-gray-300 cursor-pointer'
              >
                <img src={img} alt={title} />
                <div className='p-3'>
                  <p className='font-bold text-royal text-base mb-1'>{title}</p>
                  <p className='text-dim font-medium text-sm'>{description}</p>
                </div>
              </Link>
            ))}

            <div className='rounded-md border border-gray-300 relative'>
              <div className='flex items-start pt-3 pl-3 gap-3 overflow-hidden'>
                <img src={logoSmImg} alt="Logo" />
                <img src={howToHelpImg} alt={lang.How_can_we_help} />
              </div>
              <div className='demo px-3 pb-3 pt-8'>
                <p className='font-bold text-white text-lg mb-1'>{lang.How_can_we_help}</p>
                <p className='text-white font-normal text-xs mb-2'>
                  {lang.Schedule_Demo_Description}
                </p>
                <a href="https://calendly.com/alysei2024" className='demo-btn'>{lang.Schedule_Demo}</a>
              </div>
            </div>
          </div>
        </AnimateFromBottom>
      </div>
    );
};

export default Members;
