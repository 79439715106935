
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import { fetchEditAwardData, } from '../modules/DiscoverModule';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import img1 from "../../../assets/images/img-1.png";
import language from '../../../helpers/language';
import CustomUseEffect from '../../../components/constants';

export default function ViewAward() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const [isOpenImage, setIsOpenImage] = useState(false);
    const [userPhotosData, setUserPhotosData] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [lang] = useState(language.getLang())

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    CustomUseEffect(async () => {
        await dispatch(fetchEditAwardData(id));
    }, [id]);

    const { editAwardData, awardSpinner } = useSelector((state) => state.discoverReducerState);

    // photos gallery
  const handlePhotos = async (awardImage) => {
    let userPhotos = [];
      await userPhotos.push({
        url: `${awardImage.attachment.base_url}${awardImage.attachment.attachment_url}`,
        type: 'photo',
        altTag: 'image',
      });

    await setUserPhotosData(userPhotos);
    await setIsOpenImage(true);
  };

    const checkUrlWithHttpsAndHttp = (string) => {
        var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (!regex.test(string)) {
            return false;
        } else {
            return true;
        }
    }
  
    return (
        <div>
            <div className="discover_page">
                <div className="page_top_form">
                    <div className="flex justify-between items-center">
                        <Link to="#" onClick={() => history.goBack()}><i className="fa fa-arrow-left pr-2" aria-hidden="true"></i> Go Back</Link>
                        <h3>{lang?.View} {lang?.Awards}</h3>
                    </div>
                </div>
                <div className="discover__events create">
                    {awardSpinner ? <FormSpinner /> : <form>
                        <div className='createForm mt-4'>
                            <div className='xl:w-2/5 lg:w-2/5 md:w-full sm:w-full'>
                                <div className='colLeftBlock'>
                                    <div className="fileUpload">
                                        <label className="hide-input" onClick={()=>handlePhotos(editAwardData)}>
                                            {editAwardData && editAwardData.attachment ? <img src={`${editAwardData && editAwardData.attachment && editAwardData.attachment.base_url}${editAwardData && editAwardData.attachment && editAwardData.attachment.attachment_medium_url}`} /> : <img src={img1} alt="Award" />}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='xl:w-3/5 lg:w-3/5 md:w-full sm:w-full'>
                                <div className='colRightBlock xl:pl-4 lg:pl-4 md:pl-0 sm:pl-0'>
                                    <div className="form__item">
                                    <label>{lang?.Name_of_Competition_Event}</label>
                                        <input type="text" maxLength={15} defaultValue={editAwardData && editAwardData.award_name} placeholder='' readOnly />
                                    </div>
                                    <div className="form__item">
                                    <label>{lang?.Name_of_winning_Product}</label>
                                        <input type="text" maxLength={15} defaultValue={editAwardData && editAwardData.winning_product} placeholder='' readOnly />
                                    </div>
                                    <div className="form__item">
                                    <label>{lang?.Name_of_Modal_place_recived}</label>
                                        <select defaultValue={editAwardData?.medal_id} disabled>
                                            <option value={1}>{lang?.Bronze}</option>
                                            <option value={2}>{lang?.Silver}</option>
                                            <option value={3}>{lang?.Gold}</option>
                                            <option value={4}>{lang?.Winner}</option>
                                        </select>
                                    </div>
                                    {editAwardData?.competition_url &&
                                        <div className="form__item">
                                             <label>{lang?.Website}</label>
                                            <a href={checkUrlWithHttpsAndHttp(editAwardData.competition_url) ? editAwardData.competition_url : `https://${(editAwardData.competition_url)}`} target="_blank" >{editAwardData.competition_url}</a>
                                            {/* <input type="text" maxLength={25} defaultValue={editAwardData && editAwardData.competition_url} placeholder='' readOnly /> */}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>}
                </div>
            </div>
            {isOpenImage && (
                <div className="image--light--pop">
                <ReactImageVideoLightbox
                    data={userPhotosData}
                    startIndex={imageIndex}
                    showResourceCount={true}
                    onCloseCallback={() => {
                    setIsOpenImage(false);
                    setUserPhotosData([]);
                    setImageIndex(0);
                    }}
                />
                </div>
            )}
        </div>
    )
}
