import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import language from '../../../helpers/language';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import { editTrips } from '../../home/modules/HomeModule';
import CustomUseEffect from '../../../components/constants';

export default function ViewTrip() {
    const dispatch = useDispatch()
    const { id } = useParams();
    const history = useHistory();
    const [isOpenImage, setIsOpenImage] = useState(false);
    const [userPhotosData, setUserPhotosData] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [lang] = useState(language.getLang());

    CustomUseEffect(async () => {
        window.scrollTo(0, 0)
        await dispatch(editTrips(id));
    }, []);

    // photos gallery
  const handlePhotos = async (data) => {
    let userPhotos = [];
      await userPhotos.push({
        url: `${data.attachment.base_url}${data.attachment.attachment_medium_url}`,
        type: 'photo',
        altTag: 'image',
      });

    await setUserPhotosData(userPhotos);
    await setIsOpenImage(true);
  };

    const { editTripData: { data }, editTripSpinner } = useSelector((state) => state.activityFeedStatus);
    return (
        <div>
            <div className="discover_page">
                <div className="page_top_form">
                    <div className="flex justify-between items-center">
                        <Link to="#" onClick={() => history.goBack()}><i className="fa fa-arrow-left pr-2" aria-hidden="true"></i> {lang?.Trip_View}</Link>
                        {/* <div className="editProfileBtn">
                            <Link to="#" className='btn btnPrimary'>Save</Link>
                        </div> */}
                    </div>
                </div>
                <div className="discover__events create mb-8">
                    {editTripSpinner ? <FormSpinner /> :
                        <form>
                            <div className='createForm mt-4'>
                                <div className='xl:w-2/5 lg:w-2/5 md:w-full sm:w-full'>
                                    <div className='colLeftBlock mb-4'>
                                        <div className="ViewImg" onClick={() =>handlePhotos(data)}>
                                            <img src={data && data.attachment ? `${data.attachment.base_url}${data.attachment.attachment_medium_url}` : 'https://alysei.s3.us-west-1.amazonaws.com/uploads/2022/02/844631643978317.jpeg'} alt='Blog Image' />
                                        </div>
                                    </div>
                                    {/* <div className='inline_label'>
                                        <div className="form__item"> <label>Trip Name / Package Name</label><p className='forINput preField w-full'>{data && data.trip_name}</p></div>
                                    </div> */}
                                    <div className="form__item">
                                        <p className='forINput w-full font-bold text-blue font-18'>{data && data.trip_name}</p>
                                    </div>
                                    <div className='inline_label'>
                                        <div className="form__item"> <label>{lang?.Travel_Agency}</label> <p className='forINput preField w-full'>{data && data.travel_agency}</p></div>
                                    </div>
                                    <div className='inline_label'>
                                        <div className="form__item"> <label>{lang?.only_country}</label> <p className='forINput preField w-full'>{data && data.country && data.country.name}</p></div>
                                    </div>
                                    <div className='inline_label'>
                                        <div className="form__item"> <label>{lang?.RegionTo}</label> <p className='forINput preField w-full'>{data?.region?.map(({name},i)=>data?.region?.length >i+1 ?`${name},  ` :name)}</p></div>
                                    </div>
                                    {data?.website !== null && <div className='inline_label'>
                                        <div className="form__item"> <label>{lang?.Website}</label><a href={data?.website} target="_blank" className='forINput preField w-full'>{data?.website}</a></div>
                                    </div>}
                                </div>
                                <div className='xl:w-3/5 lg:w-3/5 md:w-full sm:w-full'>
                                    <div className='colRightBlock xl:pl-4 lg:pl-4 md:pl-0 sm:pl-0'>
                                        <div className='inline_label'>
                                            <div className="form__item"> <label>{lang?.Adventure}</label><p className='forINput preField w-full'>{data && data.adventure && data.adventure.adventure_type}</p></div>
                                        </div>
                                        <div className='inline_label'>
                                            <div className="form__item"> <label>{lang?.Duration}</label><p className='forINput preField w-full'>{data && data.duration}</p></div>
                                        </div>
                                        <div className='inline_label'>
                                            <div className="form__item"> <label>{lang?.intensity}</label><p className='forINput preField w-full'>{data && data.intensity && data.intensity.intensity}</p></div>
                                        </div>
                                        <div className='inline_label'>
                                            <div className="form__item"> <label>{lang?.price}</label> <p className='forINput preField w-full'>${data && data.price}</p></div>
                                        </div>
                                        <div className='inline_label'>
                                            <div className="form__item"> <label>{lang?.Description}</label><p className='forINput preField w-full textArea h-12'>{data && data.description}</p></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
            {/* user photos view */}
       {isOpenImage && (
        <div className="image--light--pop">
          <ReactImageVideoLightbox
            data={userPhotosData}
            startIndex={imageIndex}
            showResourceCount={true}
            onCloseCallback={() => {
              setIsOpenImage(false);
              setUserPhotosData([]);
              setImageIndex(0);
            }}
          />
        </div>
      )}
        </div>
    )
}
