import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import FormSpinner from "../../../components/Spinners/FormSpinner";
import { fetchImpAndDistHubs, getConnectionData, getImpAndDistHubs } from "../modules/B2B";
import constant from "../../../helpers/constant";
import ImporterSearchBar from "./ImporterSearchBar";
import ReactPaginate from "react-paginate";
import { acceptRejectRequestWithVisitorId, followUnfollowUser, getConnectionProductType } from "../../NetworkConnection/modules/networkConnection";
import ImporterModel from "../../../components/ConnectionModals/ImporterModal";
import auth from "../../../helpers/auth";
import language from "../../../helpers/language";
import ConnectionModal from "../../../components/ConnectionModals/ConnectionModal";
import Modal from "react-modal";
import { checkSubscription } from "../../home/modules/HomeModule";
import CustomUseEffect from "../../../components/constants";

export default function ImporterSearch() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [heroca, setHeroca] = useState("");
  const [hubsId, setHubsId] = useState("");
  const [userType, setUserType] = useState("");
  const [typeOfUser, setTypeOfUser] = useState("");
  const [productType, setProductType] = useState("");
  const [importerRoleId, setImporterRoleId] = useState([]);
  const [privateLabel, setPrivateLabel] = useState("");
  const [roleSearchType, setroleSearchType] = useState(2);
  const [alyseiBrandLabel, setAlyseiBrandLabel] = useState("");
  const [page, setPage] = useState(1);
  const [isTrue, setIsTrue] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [openConnectionModel, setOpenConnectionModel] = useState(false);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [userPercenatge, setProfilePercentage] = useState('');
  const [lang] = useState(language.getLang());
  const [hubShow, setHubShow] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { get_Subscription_Status_Data } = useSelector((state) => state.activityFeedStatus);
  let subscriptionData=get_Subscription_Status_Data?.subscription?.subscription_plan

  let subscriptionDataModified=subscriptionData?.b2b_connection_importer==-1?10000000000:subscriptionData?.b2b_connection_importer


  const {
    getAllHubs,
    getAllRole,
    impAndDistHubsSpinner,
    getImpAndDistHubsData,
  } = useSelector((state) => state.b2bState);
  const { connectionProductTypeData } = useSelector((state) => state.networkConnectionState);
  const { profileData: { data } } = useSelector((state) => state.memberProfileData);
  CustomUseEffect(async () => {
    const userType = await auth.getUserType()
    await setTypeOfUser(JSON.parse(userType))
    await setProfilePercentage(parseInt(localStorage.getItem("profile_percentage")));
    await getImporterSearch(page);
    await setIsTrue(true);
    localStorage.removeItem("productTypeArr")
  }, []);

  useEffect(()=>{
    dispatch(checkSubscription())
  },[])

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenModal = (payment_url) => {
      setIsOpen(true);

    };

  const getImporterSearch = async (pageNo) => {

    const impAndDisHubs = {
      searchType: roleSearchType,
      roleId: constant.rolesId["importer&distributer"],
      hubsId: hubsId,
      heroca: heroca,
      userType: importerRoleId,
      productType: productType,
      privateLabel: privateLabel,
      alyseiBrandLabel: alyseiBrandLabel,
      page: !pageNo ? page : pageNo
    };
    await dispatch(fetchImpAndDistHubs(impAndDisHubs));
  };

  const handlePageClick = async (e) => {
    let pageNo = e.selected + 1
    await setPage(page);
    await setIsChange(true);
    await getImporterSearch(pageNo);
  };


  const handleCancelReq = async (userId, roleId) => {
    const newdata = getImpAndDistHubsData?.data?.data?.map((item) => item.user_id === userId ? { ...item, connection_flag: 0 } : item);
    const finalValue = {
      ...getImpAndDistHubsData,
      data: { ...getImpAndDistHubsData.data, data: newdata }
    }
    const response = await dispatch(acceptRejectRequestWithVisitorId({ connection_id: parseInt(userId), accept_or_reject: 3 }));
    if (response?.success === 200) {
      typeOfUser?.role_id === roleId && await dispatch(getConnectionData(finalValue));
      await dispatch(getImpAndDistHubs(finalValue));
    }
  }
  const handleConnection = async (item) => {
    await setUserName(item?.company_name ?? item.restaurant_name);
    await setUserId(item.user_id);
    await setOpenConnectionModel(true);

  }

  const handleModal = (newValue) => {
    setOpenConnectionModel(newValue);
  }
  const followUser = async (user) => {
    const respone = await dispatch(followUnfollowUser({ follow_user_id: user?.user_id, follow_or_unfollow: user.follow_flag === 0 ? 1 : 0 }));
    if (respone?.success === 200) {
      const newdata = getImpAndDistHubsData?.data?.data?.map((item) => item.user_id === user?.user_id ? { ...item, follow_flag: user.follow_flag === 0 ? 1 : 0 } : item);
      const finalValue = {
        ...getImpAndDistHubsData,
        data: { ...getImpAndDistHubsData.data, data: newdata }
      }
      await dispatch(getImpAndDistHubs(finalValue));

    } else {
      console.log(respone);
    }
  }

  const getStatesOfSearchBar = async (filters) => {
    //console.log(filters,"Imp filters");
    await setroleSearchType(filters.searchType);
    await setHubsId(filters.hubsId);
    await setHeroca(filters.heroca);
    await setImporterRoleId(filters.userType);
    await setProductType(filters.productType);
    await setPrivateLabel(filters.privateLabel);
    await setAlyseiBrandLabel(filters.alyseiBrandLabel);
  }

  // && item?.hubConnectionRequest
  return (
    <div className="servics_blocks">
      <div className="site-align top-align">
        {/* Left Block */}
        <ImporterSearchBar hubShow={hubShow} getStatesOfSearchBar={getStatesOfSearchBar} />

        {/* Middle long Block */}
        <div className="servics_blocks middle-long__section-block middleBlockBg">
          {openConnectionModel && parseInt(typeOfUser?.role_id) === constant.rolesId.producer && <ImporterModel data={{ from: "importer", openModel: openConnectionModel, name: userName, handleModel: handleModal, userId: userId }} />}
          {openConnectionModel && parseInt(typeOfUser?.role_id) !== constant.rolesId.producer && <ConnectionModal openModelData={{ openModel: openConnectionModel, user_id: userId, name: userName, handleOpenModel: handleModal, type: constant.rolesId["importer&distributer"], userData: getImpAndDistHubsData }} />}
          <div className="">
            <div className="medium_title">
              <h1 className="font-18 text-black">
                {lang?.Importer_And_DistributorB2B}
              </h1>
            </div>
            {impAndDistHubsSpinner ? (
              <FormSpinner />
            ) : (
              <div className="card--section site-align top-align flex-wrap">
                {getImpAndDistHubsData && getImpAndDistHubsData.errors && (
                  <p>{getImpAndDistHubsData.errors.exception}</p>
                )}
                {getImpAndDistHubsData?.data?.data?.map((item, index) => {
                  console.log(data,item,"item")
                  return (
                    <div className="card card--block" key={index}>
                      <div className="hubUser">
                        <div className="feed-user-info">
                          <div className="user__pic" onClick={() => history.push(data?.user_data?.user_id === item.user_id ? "/profile" : `${"/visitor-profile"}/${item.user_id}`)}>
                            <img src={item.avatar_id && `${item.avatar_id.base_url}${item.avatar_id.attachment_medium_url}`} alt="User Image" />
                          </div>
                          <div className="user__detail-data">
                            <span className="user__name">
                              <Link to={data?.user_data?.user_id === item.user_id ? "/profile" : `${"/visitor-profile"}/${item.user_id}`} className="font-16 text-Blue">
                                {(item && item.first_name !== null && item && item.last_name !== null && `${item && item.first_name}${item && item.last_name}`) ||
                                  (item && item.company_name) ||
                                  (item && item.restaurant_name)}
                              </Link>
                              {/* <div className="flex"> */}

                              {/* </div> */}
                            </span>
                          </div>
                        </div>
                        {parseInt(typeOfUser?.role_id) !== constant.rolesId.voyagers && <div className="hub_btn flex items-center">
                          <div className={(data?.user_data?.user_id !== item.user_id && item?.hubConnectionRequest) ? "md:w-1/2 viewReq mt-4" : "md:w-1/2 md:w-full viewReq mt-4"}><Link to={data?.user_data?.user_id === item.user_id ? "/profile" : `${"/visitor-profile"}/${item.user_id}`} className="btn bg-Primary bg-transparent w-full">{lang?.View}</Link></div>
                          {((userPercenatge === 100 && data?.user_data?.user_id !== item.user_id && item?.hubConnectionRequest) && (item.available_to_connect === 1 && item.connection_flag === 0)) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className="btn bg-Primary btn BgTeal w-full" onClick={() => {data?.user_data?.role_id==3?(get_Subscription_Status_Data?.connection_request<subscriptionDataModified?handleConnection(item):handleOpenModal()):(handleConnection(item))}}>{lang?.Connect}</Link></div>}
                          {userPercenatge !== 100 && (<div className="md:w-1/2 view__hub__btn"><Link to="/home" className='btn bg-Primary btn BgTeal w-full'>{lang?.Connect}</Link></div>)}
                          {(item.available_to_connect === 1 && item.connection_flag === 1) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className='btn bg-Primary btn BgTeal w-full' >{lang?.Connected}</Link></div>}
                          {/* {(item.available_to_connect === 1 && item.connection_flag === 2) && <div className="md:w-1/2 view__hub__btn"><Link to="#" onClick={() => { handleCancelReq(item.user_id, item.role_id) }} className='btn bg-Primary btn BgTeal w-full' >{lang?.Cancel}</Link></div>} */}
                          {(item.available_to_connect === 1 && item.connection_flag === 2) && <div className="md:w-1/2 view__hub__btn"><Link to="#"  className='btn bg-Primary btn BgTeal w-full' >{lang?.Requested}</Link></div>}
                        </div>}
                        {parseInt(typeOfUser?.role_id) === constant.rolesId.voyagers && <div className="hub_btn flex items-center">
                          <div className={data?.user_data?.user_id !== item.user_id ? "md:w-1/2 viewReq mt-4" : "md:w-1/2 md:w-full viewReq mt-4"}><Link to={data?.user_data?.user_id === item.user_id ? "/profile" : `${"/visitor-profile"}/${item.user_id}`} className="btn bg-Primary bg-transparent w-full">{lang?.View}</Link></div>
                          {(item?.available_to_follow === 1 && item?.follow_flag === 0) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className='btn bg-Primary btn BgTeal w-full' onClick={() => followUser(item)}>{lang?.Follow}</Link></div>}
                          {(item?.follow_flag === 1 && item?.available_to_follow === 1) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className='btn bg-Primary btn BgTeal w-full' onClick={() => followUser(item)}>{lang?.Unfollow}</Link></div>}
                        </div>}
                      </div>
                      {/* <div className="card-form">
                              <div className="view__hub__btn">
                                <Link to="#">Pending</Link>
                              </div>
                            </div> */}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {isTrue && getImpAndDistHubsData?.data?.total > 10 &&
            <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil((getImpAndDistHubsData?.data?.total) / (getImpAndDistHubsData?.data?.per_page))}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />}
        </div>
        {/* Right Block */}
      </div>
      <Modal
          isOpen={isOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Upgrade Modal"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg text-black text-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
          >
          <div className="w-16 m-auto mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 47.5 47.5" viewBox="0 0 47.5 47.5" id="diamond"><path fill="#bdddf4" d="M0 0h-6l-7-9h10l3 9z" transform="matrix(1.25 0 0 -1.25 17.5 5)"></path><path fill="#5dadec" d="m0 0-7 9h-6l3-9H0z" transform="matrix(1.25 0 0 -1.25 46.25 16.25)"></path><path fill="#4289c1" d="M0 0h10L-8-21 0 0Z" transform="matrix(1.25 0 0 -1.25 33.75 16.25)"></path><path fill="#8ccaf7" d="M0 0h-10L8-21 0 0Z" transform="matrix(1.25 0 0 -1.25 13.75 16.25)"></path><path fill="#8ccaf7" d="m0 0-3-9h16l-3 9H0Z" transform="matrix(1.25 0 0 -1.25 17.5 5)"></path><path fill="#5dadec" d="m0 0-8 21H8L0 0Z" transform="matrix(1.25 0 0 -1.25 23.75 42.5)"></path></svg></div>    
          <h2 className="text-2xl font-bold mb-4  ">Upgrade Your Plan</h2>
          
          {
             subscriptionData!==undefined?(
          
           <p>
           <span className="text-black">As per your current Plan, you can send up to {subscriptionData?.b2b_connection_importer==-1?"Unlimited":subscriptionData?.b2b_connection_importer} connection requests.
           To send more, please upgrade your Membership Plan</span>
       </p>
             ):(
              <p>
              <span className=" text-black">Sorry, You're unable to send any request to importer at the moment as you don't have an active subscription.</span>
               </p> 
             )
          }
          
          <Link to="/subscription">
          <button  className="btn btn-blue stripe text-white font-bold mt-6">Upgrade </button>
          </Link>
          
          </Modal>
    </div>
  );
}
