import React, { useState } from 'react';
import Andrea from "../../../assets/landing-page/about/Andrea.png";
import Simona from "../../../assets/landing-page/about/Simona.png";
import AnimateFromBottom from '../../LandingPage/component/AnimateFromBottom';
import language from "../../../helpers/language"; // Adjust the path as needed

const Team = () => {
  const [lang] = useState(language.getLang());

  return (
    <div className='md:px-14 px-4 md:py-8 py-5'>
      <AnimateFromBottom delay={0.2}>
        <p className='text-lg text-royal uppercase font-medium'>
          {lang.Team_Alysei}
        </p>
      </AnimateFromBottom>
      <AnimateFromBottom delay={0.3}>
        <p className='font-bold text-2xl mb-4'>{lang.Founders}</p>
      </AnimateFromBottom>

      <div className='flex md:flex-row flex-col md:gap-10 gap-5 md:mt-10 mt-5 md:mx-10'>
        <div className='md:w-4/12'>
 <AnimateFromBottom delay={0.2}>
            <img src={Andrea} className='w-full h-auto'/>
          </AnimateFromBottom>
        </div>
        <div className='md:w-8/12'> 
          <AnimateFromBottom delay={0.3}>
            <p className='text-lg text-royal mb-2 font-bold'>{lang.Andrea}</p>
          </AnimateFromBottom>
          <AnimateFromBottom delay={0.4}>
            <p className='font-medium text-lg mb-4 leading-8'>{lang.Andrea_Description}</p>
          </AnimateFromBottom>
        </div>
      </div>

      <div className='flex md:flex-row flex-col-reverse md:gap-10 gap-5 md:mt-10 mt-5 md:mx-10'>
        <div className='md:w-8/12 md:mt-6 mt-3'> 
          <AnimateFromBottom delay={0.3}>
            <p className='text-lg text-royal mb-2 font-bold'>{lang.Simona}</p>
          </AnimateFromBottom>
          <AnimateFromBottom delay={0.4}>
            <p className='font-medium text-lg mb-4 leading-8'>{lang.Simona_Description}</p>
          </AnimateFromBottom>
        </div>
        <div className='md:w-4/12'>
          <AnimateFromBottom delay={0.2}>
            <img src={Simona} className='w-full h-auto'/>
          </AnimateFromBottom>
        </div>
      </div>
    </div>
  );
};

export default Team;