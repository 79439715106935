import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactStars from 'react-rating-stars-component';
import { Link, useHistory } from 'react-router-dom';

import FormSpinner from '../../../components/Spinners/FormSpinner';
import {
  getRecipeDetail,
  getSingleRecipeDetail,
  tabIndexFun,
} from '../modules/RecipeModule';
import language from '../../../helpers/language';

export default function MyRecipeTab(props) {
  const [lang] = useState(language.getLang());
  const dispatch = useDispatch();
  const history = useHistory();
  const { myAllRecipeData, myRecipePercentageSpinner } = useSelector(
    (state) => state.recipedata
  );

  const updateRecipe = async (recipeId) => {
    //await dispatch(getRecipeDetail(recipeId));
    await dispatch(getRecipeDetail(recipeId)).then(async (res) => {
      if (res && res.success === 200) {
        localStorage.setItem('generalInformation', JSON.stringify(res.recipe));
        let ingredientArray = [];
        let toolsArray = [];
        let stepsArray = [];
        res?.used_ingredients?.length > 0 && res?.used_ingredients.map((list) => {
            const obj = {
              ingredient_id: list?.ingredient?.recipe_ingredient_id,
              src: list?.ingredient?.image_id?.attachment_url,
              title: list?.ingredient?.title,
              quantity: list?.quantity,
              unit: list?.unit,
              base_url: list?.ingredient?.image_id?.base_url,
            }

            ingredientArray.push(obj);
        })
        localStorage.setItem('savedIngredients', JSON.stringify(ingredientArray));
        res?.used_tools?.length > 0 && res?.used_tools.map((list) => {
          const obj = {
            tool_id: list?.tool?.recipe_tool_id,
            title: list?.tool?.title,
            src: list?.tool?.image_id.attachment_url,
            base_url: list?.tool?.image_id.base_url,
          }

          toolsArray.push(obj);
      })
      // Steps
      res?.steps?.length > 0 && res?.steps.map((list) => {
          let stepIngredient = [];
          let stepTool = [];
          list?.step_ingredients?.length > 0 && list?.step_ingredients.map((ingd) =>{
            if(ingd.is_selected){
            stepIngredient.push(ingd.ingredient_id);
            }
          })
          list?.step_tools?.length > 0 && list?.step_tools.map((t) =>{
            if(t.is_selected){
            stepTool.push(t.tool_id);
            }
          })
        const obj = {
          title: list?.title,
          description: list?.description,
          ingredients: stepIngredient,
          tools: stepTool
        }

        stepsArray.push(obj);
    })
      localStorage.setItem('savedTools', JSON.stringify(toolsArray));
      localStorage.setItem('recipe_step', JSON.stringify(stepsArray));
      }
    });
    localStorage.setItem('recipe_id', recipeId);
    history.push('/recipes/edit-recipe');
  };

  const singleRecipPage = async (recipeId) => {
    await dispatch(getRecipeDetail(recipeId));
    await dispatch(getSingleRecipeDetail(recipeId));
    history.push(`${'/recipes/recipe-view'}/${recipeId}`);
  };
  // CustomUseEffect(async () => {
  //     await dispatch(tabIndexFun(2))
  // }, [])
  return (
    <Fragment>
      <div className="section__wrapper">
        <div className="site__width">
          <div className="recipe__section">
            <div className="recipe__section">
              <div className="trending_List">
                {myRecipePercentageSpinner ? (
                  <FormSpinner />
                ) : (
                  <ul>
                    {myAllRecipeData?.length > 0 ? (
                      myAllRecipeData?.map((item, index) => {
                        return (
                          <li key={index}>
                            <div className="trending_Img">
                              <Link
                                to={`${'/recipes/recipe-view'}/${
                                  item.recipe_id
                                }`}
                                onClick={() => dispatch(tabIndexFun(2))}
                              >
                                {' '}
                                <img
                                  src={`${item.image.base_url}${item.image.attachment_url}`}
                                  alt="Trending_Image"
                                />{' '}
                              </Link>
                              <div className="edit_MyRecipe">
                                <button
                                  type="button"
                                  className="btn edit_RecipeBtn"
                                  onClick={() => updateRecipe(item.recipe_id)}
                                >
                                  {/* <span><i className="fa fa-pencil" aria-hidden="true"></i></span> */}
                                  {lang?.Edit} {lang?.Recipe}
                                </button>
                                <button
                                  type="button"
                                  disabled
                                  className={
                                    parseInt(item.status) === 1
                                      ? 'btn edit_RecipeBtn draftBtn'
                                      : 'btn edit_RecipeBtn publishedBtn'
                                  }
                                  onClick={() =>
                                    singleRecipPage(item.recipe_id)
                                  }
                                >
                                  {parseInt(item.status) === 1
                                    ? `${lang?.Published}`
                                    : `${lang?.Draft}`}
                                </button>
                              </div>
                              <Link
                                to={`${'/recipes/recipe-view'}/${
                                  item.recipe_id
                                }`}
                              >
                                <div className="recipe_rating">
                                  <p>
                                    {' '}
                                    <span className="recipe_Name">
                                      {item.name}{' '}
                                    </span>
                                    <span className="star_rating">
                                      {' '}
                                      <ReactStars
                                        count={5}
                                        value={item.avg_rating}
                                        size={24}
                                        edit={false}
                                        color2={'#ffd700'}
                                      />{' '}
                                    </span>
                                  </p>
                                  <p>
                                    {' '}
                                    <span className="heart_Icon">
                                      {item.favourite_count > 0 ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="18"
                                          viewBox="0 0 20 17.648"
                                        >
                                          <path
                                            id="like_icon_active"
                                            d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                            transform="translate(-5 -9)"
                                            fill="#ff0046"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 20.653 18.099"
                                        >
                                          <path
                                            id="icons8_heart"
                                            d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                            transform="translate(-2 -7)"
                                          />
                                        </svg>
                                      )}
                                    </span>{' '}
                                    <span className="likes">
                                      {item.favourite_count} {lang?.Likes}
                                    </span>
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <Link
                              to={`${'/recipes/recipe-view'}/${item.recipe_id}`}
                            >
                              <div className="recipe_Info">
                                <p className="mb-3">{item.username} </p>
                                <p>
                                  <span>
                                    <i
                                      className="fa fa-clock-o"
                                      aria-hidden="true"
                                    ></i>
                                  </span>{' '}
                                  {item && item.hours === 0
                                    ? ''
                                    : `${item && item.hours} ${lang?.Hr}`}{' '}
                                  {item && item.minutes === 0
                                    ? ''
                                    : `${item.minutes} ${lang?.Mins}`}{' '}
                                </p>
                                <p>
                                  <span>
                                    <i
                                      className="fa fa-user"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  {item.serving}
                                </p>
                                <p>
                                  <span>
                                    <i
                                      className="fa fa-cutlery"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  {item.meal.name}
                                </p>
                              </div>
                            </Link>
                          </li>
                        );
                      })
                    ) : (
                      <p className="pl-3">{lang?.No_Recipe_Found}</p>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
