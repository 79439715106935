import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import user_img from '../../../assets/images/user_profile-img.png'
import { Link, useHistory } from 'react-router-dom'
import FormSpinner from '../../../components/Spinners/FormSpinner';
import auth from '../../../helpers/auth';
import constant from "../../../helpers/constant";
import { fetchDataConncetionSuggetion, getConnectionData } from '../../B2B/modules/B2B';
import { acceptRejectRequestWithVisitorId } from '../../NetworkConnection/modules/networkConnection';
import { getVisitorProfile } from '../../profile/modules/profile';
import ConnectionModal from '../../../components/ConnectionModals/ConnectionModal';
import MemberType from '../../../components/MemeberType';
import language from '../../../helpers/language';
import copylink from '../../../assets/images/Invite.svg'

export default function SuggestedFriend() {
    const [user, setUser] = useState();
    const [openConnectionModel, setOpenConnectionModel] = useState(false);
    const [userName, setUserName] = useState("");
    const [userId, setUserId] = useState("");
    const [lang] = useState(language.getLang());
    const [profilePercentage, setProfilePercentage] = useState(0);
    const dispatch = useDispatch();
    const [copyLink, setCopyLink] = useState(false);
    const [copied, setCopied] = useState(false);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(window.location.origin)
            .then(() => {
                setCopied(true);
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const [userSuggestionList, setUserSuggestionList] = useState()

    const { profileData: { data } } = useSelector((state) => state.memberProfileData);
    const { producerSpinner, userSuggestion } = useSelector((state) => state.b2bState);

    useEffect(()=>{
        const firstCall = async () => {
            let type = 2;
            await setProfilePercentage(localStorage.getItem('profile_percentage'));
            const user = await auth.getUserType();
            const id = await JSON.parse(user);
            await setUser(id);
            await dispatch(getVisitorProfile([]));
            userSuggestion?.length <= 0 && await dispatch(fetchDataConncetionSuggetion({ searchType: type, roleId: id?.role_id }));
        }
        firstCall()
    }, []);

    useEffect(() => {
        let filterData = userSuggestion?.data?.data?.filter((item) => (data?.user_data?.user_id !== item.user_id && item?.connection_flag === 0))?.slice(0, 5)
        setUserSuggestionList(filterData)
    }, [userSuggestion])

    const handleCancelReq = async (userId) => {
        const newdata = userSuggestion?.data?.data?.map((item) => item.user_id === userId ? { ...item, connection_flag: 0 } : item);
        const finalValue = { ...userSuggestion, data: { ...userSuggestion.data, data: newdata } }
        const response = await dispatch(acceptRejectRequestWithVisitorId({ connection_id: parseInt(userId), accept_or_reject: 3 }));
        if (response.success === 200) {
            await dispatch(getConnectionData(finalValue));

        }
    }

    const bH = useRef(null);
    const blockHeight = bH.current?.clientHeight;
    useEffect(() => {
        if (blockHeight < 400) {
            document.body.classList.add("new__class")
        } else {
            document.body.classList.remove("new__class")
        }
    }, [blockHeight]);

    const handleModal = (newValue) => {
        setOpenConnectionModel(newValue);
    }
    return (
        <div className={userSuggestionList?.length < 2 ? 'suggested mt-2' : "suggested mt-2 connection_scroll"}>
            <button className="invitebtn" type="button" onClick={() => { setCopyLink(!copyLink); setCopied(false); }}>
                <span className="navIcon enable_icon">
                    <img src={copylink} alt="Copy Link" />
                </span>
                <span className="navText">Invite others to join Alysei
                </span>
            </button>
            {copyLink &&
                <>
                    <ul className="py-1 setting-dropdown copyText" aria-labelledby="dropdownButton" >
                        <li className={`text-center ${copied ? "textGreen" : ""}`} onClick={copyToClipboard}>
                            {copied ? 'Copied!' : 'Copy URL to Clipboard'}
                        </li>
                    </ul>
                </>
            }
            {/* {user?.role_id === constant.rolesId["importer&distributer"] && openConnectionModel && <ImporterModel data={{ from: "importer", openModel: openConnectionModel, name: userName, handleModel: handleModal, userId: userId }} />} */}
            {openConnectionModel && <ConnectionModal openModelData={{ openModel: openConnectionModel, user_id: userId, name: userName, handleOpenModel: handleModal, type: user?.role_id, userData: userSuggestion, from: "suggestion" }} />}
            <div className="card card--block mb-6" ref={bH}>
                <h4 className='font-16 text-black mb-3'>{lang?.Suggested_Connections}</h4>
                {producerSpinner ? <FormSpinner /> :
                    userSuggestionList?.length > 0 ? userSuggestionList?.map((item, index) => {
                        // if (data?.user_data?.user_id !== item.user_id)
                        return (
                            <div className="post--form" key={index}>
                                <div className="feed-user-detail">
                                    <div className="feed-user-info">

                                        <div className="user__pic" >
                                            <Link to={profilePercentage === '100' ? `${"/visitor-profile/"}${item.user_id}` : '/'}>
                                                {item?.avatar_id !== null ? <img src={`${item?.avatar_id?.base_url}${item?.avatar_id?.attachment_medium_url}`} alt='User Profile' /> : <img src={user_img} alt='default-Profile-image' />}
                                            </Link>
                                        </div>

                                        <div className="user__detail-data">
                                            <span className="user__name">
                                                <Link to={profilePercentage === '100' ? `${"/visitor-profile/"}${item.user_id}` : '/'}>{item.company_name ?? item.restaurant_name ?? `${item?.first_name} ${item?.last_name}`}</Link>
                                            </span>
                                            <MemberType item={item} />
                                            <div className={user?.role_id === constant?.rolesId?.voyagers ? 'btnBox text-right mt-1 voyager' : 'btnBox text-right mt-1'}>
                                                {parseInt(localStorage.getItem("profile_percentage")) !== 100 && (<Link to="/home" className='btn bgLight BgTeal'>{lang?.Connect}</Link>)}
                                                {parseInt(localStorage.getItem("profile_percentage")) === 100 && ((item.available_to_connect === 1 && item.connection_flag === 0 && item.connection_flag !== 2) &&
                                                    <Link to="#" className='btn bgLight BgTeal'
                                                        onClick={() => {
                                                            setUserName(item?.company_name ?? item.restaurant_name ?? item.name);
                                                            setOpenConnectionModel(true); setUserId(item.user_id)
                                                        }}>
                                                        {lang?.Connect}</Link>)}
                                                {(item.available_to_connect === 1 && item.connection_flag === 1) && <Link to="#" className='btn bgLight BgTeal' >{lang?.Connected}</Link>}
                                                {(item.available_to_connect === 1 && item.connection_flag === 2) && <Link to="#" onClick={() => { handleCancelReq(item.user_id) }} className='btn bgLight BgTeal' >{lang?.Cancel}</Link>}
                                                {(item.available_to_connect === 1 && item.connection_flag === 3) && <Link to={`${"/visitor-profile/"}${item.user_id}`} className='btn bgLight BgTeal' >{lang?.Pending}</Link>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    }) : "No Connection Found"}
            </div>
        </div >
    )
}
