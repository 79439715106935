import React from 'react'
import AnimateFromBottom from '../../LandingPage/component/AnimateFromBottom'

const OurServices = ({title, desc, cardContent}) => {
  return (
    <div className='md:px-14 px-4 md:py-8 py-5'>
      <AnimateFromBottom delay={0.2}>
      <p className='text-lg text-royal pb-2 uppercase font-medium text-center'>
        {title}
      </p>
       </AnimateFromBottom>
      <AnimateFromBottom delay={0.3}>
      <p className='font-bold text-2xl pb-6 text-center md:mx-52 mx-0'>
        "{desc}"
      </p>
       </AnimateFromBottom>
      
     
      <div className='grid md:grid-cols-3 gap-3'>
        {cardContent[0].content.map((text, index) => (
           <AnimateFromBottom delay={0.4}  key={index}>
          <div className='border h-full border-gray-300 rounded-lg p-3 flex flex-col items-center gap-2'>
            <img src={cardContent[0].icon[index]} className='w-16 h-16' alt={`icon-${index}`}/>
            <p className='text-lg font-bold text-center'>
              {text}
            </p>
            </div>
            </AnimateFromBottom>
        ))}
      </div>
    </div>
  )
}

export default OurServices
