import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import language from "../../../helpers/language";
import auth from '../../../helpers/auth'
import FormErrors from '../../../components/Errors/FormError'
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDiet,
  fetchCourse,
  fetchFoodIntrolerence,
  fetchCookingSkill,
  fetchRegion,
  fetchCuisine,
  selectmealfun
} from "../modules/RecipeModule";
import CustomUseEffect from "../../../components/constants";

export default function AddGeneralinformation() {
  const [lang] = useState(language.getLang());
  const dispatch = useDispatch();
  const history = useHistory()

  const { 
    recipeDiet,
    recipecourse,
    foodintrolerance,
    cookingskill,
    meal,
    region,
    cuisine
  } = useSelector((state) => state.recipedata);

  const intialValues = {
    name: "",
    meal_id: "",
    course_id: "",
    cooking_skill_id: "",
    cousin_id: "",
    diet_id : "",
    intolerance_id : "",
    region_id : "",
    hours : "",
    minutes : "",
    serving : "",
    recipeImage : "",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isImage, setIsImage] = useState("");
  const [loadPhoto, setLoadPhoto] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  CustomUseEffect(async() => {
    if(localStorage.getItem('generalInformation') != null){
      const basicInformation = JSON.parse(localStorage.getItem('generalInformation'));
      // if(basicInformation.recipeImage === undefined){
      //   basicInformation["recipeImage"] = `${basicInformation?.image?.base_url}${basicInformation?.image?.attachment_url}`;
      // }
      await setFormValues(basicInformation);
      
    }
  },[])

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      saveAndMoveNext();
    }
  }, [formErrors]);

  CustomUseEffect(async () => {
    let token = await auth.getAccessToken();
    await dispatch(selectmealfun(token));
    await dispatch(fetchCourse(token));
    await dispatch(fetchCookingSkill(token));
    await dispatch(fetchCuisine(token));
    await dispatch(fetchDiet(token));
    await dispatch(fetchFoodIntrolerence(token));
    await dispatch(fetchRegion(token));
  }, []);

  // Submit to next
  const processedToNext = async() => {
    setFormErrors(validate(formValues));
    setIsSubmitting(true);

  }

  const saveAndMoveNext = () => {
    localStorage.setItem('generalInformation', JSON.stringify(formValues));
    history.push('/recipes/add-ingredients')
  }

  // Form validation
  const validate = (values) => {
    let errors = {};
    if (!values.name) {
      errors.name = "Enter recipe name";
    }
    if (!values.meal_id) {
      errors.meal_id = "Select recipe meal";
    }
    if (!values.course_id) {
      errors.course_id = "Select recipe course";
    }
    if (!values.cooking_skill_id) {
      errors.cooking_skill_id = "Select recipe cooking skill";
    }
    if (!values.cousin_id) {
      errors.cousin_id = "Select recipe cuisine";
    }
    if (!values.serving) {
      errors.serving = "Please select recipe prepration serving";
    }
    if (!values.region_id) {
      errors.region_id = "Select recipe region";
    }
    if (localStorage.getItem('recipe_id') === null) {
      if (!values.recipeImage) {
        errors.recipeImage = "Choose recipe image";
      }
    }
    return errors;
  };

  // Options set in states
  const handleSelect = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  }

  const handleImgageChange = async (e) => {
    let type = e.target.files[0];
    if (!type.name.match(/\.(jpg|jpeg|png)$/)) {
      //  alert('The Image type must be JPEG, JPG, PNG')
      setFormErrors({ ...formErrors, ["recipeImage"]: lang?.imageType });
    } else if (e.target.files[0].size > 12400000) {
      setFormErrors({ ...formErrors, ["recipeImage"]: lang?.selectImageSizeLessThan10 });

    } else {
      let file = e.target.files[0];
      const base64 = await convertBase64(file)
      setFormValues({ ...formValues, ["recipeImage"]: base64 });
      setFormErrors({ ...formErrors, ['recipeImage']: "" });
    }

  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }


  // Sort Data
  const dynamicSort = (property) => {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  } 

  return (
    <div>
      <div className="section__wrapper">
        <div className="recipe__page create__recipe">
          <div className="step">
            <div className="cancel">
              <Link to="/recipes"><span><i className="fa fa-arrow-left pr-3" aria-hidden="true"></i></span> {lang?.Create_Recipe}</Link>
            </div>
            <div className="right__align_btn btn-white">
              <button className="btn activeBtn" onClick={processedToNext}>
                {lang?.Next}
              </button>
            </div>
          </div>
          {/* Block starts*/}
          <div className="top__div">
            <h4 className="semi__bold__title">
              <span className="circle">1</span>
              {lang?.General_Information}
            </h4>
        
          </div>
          {/* Block ends*/}
          {/* Form starts*/}
          <div className="site__width">
            <form className="receipe__form flex">
              <div className="form_info">
                <div className="form__item">
                  <label htmlFor="rec_name">{lang?.only_Name}*</label>
                  <input
                    type="text"
                    placeholder={`${lang?.Recipes} ${lang?.only_Name}`}
                    id="rec_name"
                    name="name"
                    autoComplete="off"
                    defaultValue={formValues.name}
                    onChange={(event) => handleSelect(event)}
                  />
                  {formErrors.name ? <FormErrors msg={formErrors.name} /> : ''}
                </div>
                
                <div className="form__grid">
                  <div className="form__item">
                    <label>{lang?.only_select} {lang?.meal}*</label>
                    <div className="">
                      <select
                        id="meal"
                        name="meal_id"
                        onChange={(event) => handleSelect(event)}
                      >
                        <option value=''>{lang?.only_select} {lang?.meal}</option>
                        {meal &&
                          meal.data &&
                          meal.data.sort(dynamicSort("name")).map((data, i) => {
                            return (
                              <option
                                key={i}
                                value={data.recipe_meal_id}
                                selected={parseInt(formValues.meal_id) === data.recipe_meal_id ? true : false}
                              >
                                {data.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {formErrors.meal_id ? <FormErrors msg={formErrors.meal_id} /> : ''}
                  </div>
                  <div className="form__item">
                    <label>{lang?.SelectCourse}*</label>
                    <div className="">
                      <select
                        id="select_course"
                        name="course_id"
                        onChange={(event) => handleSelect(event)}
                      >
                        <option value=''>{lang?.SelectCourse}</option>
                        {recipecourse &&
                          recipecourse.data &&
                          recipecourse.data.sort(dynamicSort("name")).map((item, i) => {
                            return (
                              <option
                                value={item.recipe_course_id}
                                selected={parseInt(formValues.course_id) === item.recipe_course_id ? true : false}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {formErrors.course_id ? <FormErrors msg={formErrors.course_id} /> : ''}
                  </div>
                </div>
                <div className="form__grid">
                  <div className="form__item">
                    <label>{lang?.only_select} {lang?.Cooking_Skills}*</label>
                    <div className="">
                      <select
                        id="Select_Cooking"
                        name="cooking_skill_id"
                        onChange={(event) => handleSelect(event)}
                      >
                        <option value=''>{lang?.only_select} {lang?.Cooking_Skills}</option>
                        {cookingskill &&
                          cookingskill.data &&
                          cookingskill.data.sort(dynamicSort("name")).map((item, i) => {
                            return (
                              <Fragment>
                                <option
                                  value={item.recipe_cooking_skill_id}
                                  selected={parseInt(formValues.cooking_skill_id) === item.recipe_cooking_skill_id ? true : false}
                                >
                                  {item.name}
                                </option>
                              </Fragment>
                            );
                          })}
                      </select>
                    </div>
                    {formErrors.cooking_skill_id ? <FormErrors msg={formErrors.cooking_skill_id} /> : ''}
                  </div>

                  <div className="form__item">
                    <label>{lang?.only_select} {lang?.Cuisine}*</label>
                    <div className="">
                      <select
                        id="Select_Cuisine"
                        name="cousin_id"
                        onChange={(event) => handleSelect(event)}
                      >
                        <option value=''>{lang?.only_select} {lang?.Cuisine}</option>
                        {cuisine &&
                          cuisine.length > 0 &&
                          cuisine.sort(dynamicSort("title")).map((data, i) => {
                            return (
                              <Fragment>

                                <option
                                  key={i}
                                  value={data.cousin_id}
                                  name={data.title}
                                  selected={parseInt(formValues.cousin_id) === data.cousin_id ? true : false}
                                >{data.name}</option>
                              </Fragment>
                            );
                          })}
                      </select>
                    </div>
                    {formErrors.cousin_id ? <FormErrors msg={formErrors.cousin_id} /> : ''}
                  </div>

                </div>
                <div className="form__grid">
                  <div className="form__item">
                    <label>{lang?.only_select} {lang?.Diets}</label>
                    <div className="">
                      <select
                        id="Select_Diets"
                        name="diet_id"
                        onChange={(event) => handleSelect(event)}
                      >
                        <option value=''>{lang?.only_select} {lang?.Diets}</option>
                        {recipeDiet &&
                          recipeDiet.data &&
                          recipeDiet.data.sort(dynamicSort("name")).map((item, i) => {
                            return (
                              <option
                                value={item.recipe_diet_id}
                                selected={parseInt(formValues.diet_id) === item.recipe_diet_id ? true : false}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    
                  </div>

                  <div className="form__item">
                    <label>{lang?.only_select} {lang?.Food_Introlerance}</label>
                    <div className="">
                      <select
                        id="Select_Food_introlerance"
                        name="intolerance_id"
                        onChange={(event) => handleSelect(event)}
                      >
                        <option value=''>{lang?.only_select} {lang?.Food_Introlerance}</option>
                        {foodintrolerance &&
                          foodintrolerance.data &&
                          foodintrolerance.data.sort(dynamicSort("name")).map((data) => {
                            return (
                              <Fragment>
                                <option
                                  value={data.recipe_food_intolerance_id}
                                  selected={parseInt(formValues.intolerance_id) === data.recipe_food_intolerance_id ? true : false}
                                >{data.name}</option>
                              </Fragment>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form__grid showNo">
                  <div className="form__grid pr-4 pt-8 relative col--25">
                    <span className="absolute top-0 left-0 font-bold">
                      {lang?.Prepration_Time}
                    </span>
                    <div className="form__item prepare">
                      <label htmlFor="pre_hrs">{lang?.Hours}</label>
                      <input type="number"
                        max="24"
                        name="hours"
                        value={formValues.hours}
                        placeholder={0}
                        min='0'
                        onChange={(event) => handleSelect(event)}
                        id="pre_hrs" />
                    </div>
                    <div className="form__item prepare">
                      <label htmlFor="pre_mint">{lang?.Minutes}</label>
                      <input type="number"
                        max="59"
                        min="0"
                        name="minutes"
                        value={formValues.minutes}
                        onChange={(event) => handleSelect(event)}
                        placeholder={0} id="pre_mint" />
                        
                    </div>
                  </div>

                  <div className="form__item w-full pt-8 relative Serving col--50 gapForAlign">
                    <span className="absolute top-0 font-bold">
                      {lang?.Serving}*
                    </span>
                    <label htmlFor="ser_ppl">
                      {lang?.For_how_much_people_you_are_cooking}
                    </label>
                    <input
                      min="0"
                      max="25"
                      name="serving"
                      value={formValues.serving}
                      onChange={(event) => handleSelect(event)}
                      type="number" placeholder={0} id="ser_ppl" />
                      {formErrors.serving ? <FormErrors msg={formErrors.serving} /> : ''}
                  </div>
                </div>


                <div className="form__grid">
                  <div className="form__item">
                    <label>{lang?.Select_Region}*</label>
                    <div className="">
                      <select id="Select_Region" name="region_id" onChange={(event) => handleSelect(event)}>
                        <option value=''>{lang?.Select_Region}</option>
                        {region &&
                          region.data &&
                          region.data.sort(dynamicSort("name")).map((item, i) => {
                            return (
                              <Fragment>

                                <option value={item.recipe_region_id}
                                  selected={parseInt(formValues.region_id) === item.recipe_region_id ? true : false}

                                >{item.name}</option>
                              </Fragment>
                            );
                          })}
                      </select>
                    </div>
                    {formErrors.region_id ? <FormErrors msg={formErrors.region_id} /> : ''}
                  </div>
                  <div className="form__item">
                    <label></label>
                    <div className="">
                    </div>
                  </div>
                </div>
              </div>
              <div className="form_upload">
                <div className="form-file-upload">
                  <div className="upload_content">
                    <input
                      className="rec_uploadimg"
                      type="file"
                      id="img_upload"
                      onChange={handleImgageChange}
                    />


                    <label htmlFor="img_upload" className="pt-3">
                      <i className="fas fa-cloud-upload-alt upl_rec" />
                      <p className="font-bold">{lang?.Upload_Recipe_Image}</p>
                      <p className="upload_subtxt">
                        {lang?.click_here_to_upload_recipe_image}
                      </p>
                      {
                        formValues.recipeImage || formValues?.image != undefined ? 
                          <p className="change_Photo">
                            <i className="fa fa-camera" aria-hidden="true"> &nbsp;{lang?.Change_Photo}</i>
                          </p>
                          : ''
                      }
                      {formValues.recipeImage === undefined ? <img src={`${formValues?.image?.base_url}${formValues?.image?.attachment_url}`} /> :
                      formValues.recipeImage != '' ? <img src={formValues.recipeImage} /> : ''
                      }
                    </label>
                  </div>
                </div>
                {formErrors.recipeImage ? <FormErrors msg={formErrors.recipeImage} /> : ''}
              </div>
            </form>
            {/* Form ends*/}
          </div>
        </div>
      </div>

      {/* Footer */}

    </div>
  )

}