import React from 'react'
import Banner from './component/Banner'
import Brands from '../LandingPage/component/Brands'
import OurServices from './component/OurServices'
import { serviceContent } from './utils'
import ContentImg from './component/ContentImg'
import Testimonial from '../LandingPage/component/Testimonial'
import NewsLetter from './component/NewsLetter'
import Video from './component/Video'
import { useParams } from 'react-router-dom';
import Header from "../LandingPage/component/Header";
import Footer from "../LandingPage/component/Footer";
import { Helmet } from 'react-helmet';

import ImporterDistributers from '../../components/Seo-helmet/service/Imorter-Distributers.js'
import ItalianFood from '../../components/Seo-helmet/service/Italian-Food.js'
import Buyers from '../../components/Seo-helmet/service/Buyers.js'
import ItalianRestaurant from '../../components/Seo-helmet/service/Italian-Restaurant.js'
import VoiceExperts from '../../components/Seo-helmet/service/Voice-Experts.js';
import TravelAgencies from '../../components/Seo-helmet/service/Travel-Agencies.js';
import Voyagers from '../../components/Seo-helmet/service/Voyagers.js'
const Services = () => {
  const { slug } = useParams();
  const service = serviceContent.find(s => s.slug === slug);

  if (!service) {
    return <p>No service found for this category!</p>;
  }


  const helmetObj = {
    'italian-food-beverage-producers': <ItalianFood />,
    'importers-distributors': <ImporterDistributers />,
    'buyers': <Buyers />,
    'italian-restaurants': <ItalianRestaurant />,
    'voice-of-experts': <VoiceExperts />,
    'travel-agencies': <TravelAgencies />,
    'voyagers': <Voyagers />
  }
  return (


    <>

      {/* @@ SEO HELMET */}
      {slug && helmetObj[slug]}

      {/* ---seo-helmet--- */}

      <Header />
      <Banner title={service.title} description={service.description} />
      <Brands />
      <OurServices title={service.ourServices.title} desc={service.ourServices.desc} cardContent={service.ourServices.cardContent} />
      {service.contentImg.map((content, idx) => (
        <ContentImg
          key={idx}
          heading={content.heading}
          subheading={content.subheading}
          desc={content.desc}
          badgeInfo={content.badgeInfo}
          img={content.img}
          direction={content.direction}
        />
      ))}
      <Testimonial />

      {service.videoUrl && <Video videoUrl={service.videoUrl} thumbnail={service.thumbnail} videoTitle={service.videoTitle} videoDesc={service.videoDesc} videoSub={service.videoSub} />}
      <NewsLetter joinLink={service.joinLink} />
      <Footer />
    </>
  )
}

export default Services
