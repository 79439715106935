import React, { useState } from 'react';
import usFlag from '../../../assets/landing-page/us-flag.svg';
import itFlag from '../../../assets/landing-page/italy-flag.svg'; 

const LanguageConverter = () => {
  const [selectLanguage, setSelectLanguage] = useState(localStorage.getItem('lang') || 'en');
  const [isOpen, setIsOpen] = useState(false);

  // Map language codes to their corresponding flag images and names
  const languages = [
    { code: 'en', name: 'English (US)', flag: usFlag },
    { code: 'it', name: 'Italian (IT)', flag: itFlag },
    // Add more languages and flags as needed
  ];

  const selectLang = (langCode) => {
    setSelectLanguage(langCode);
    localStorage.setItem("lang", langCode);
    setIsOpen(false);
    window.location.reload();
  };

  return (
    <div className="relative inline-block">
      {/* Selected Language Display */}
      <div
        className="flex items-center gap-1 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          src={languages.find(lang => lang.code === selectLanguage)?.flag}
          alt={`${selectLanguage} Flag`}
          className="h-5 w-5"
        />
        <span>{languages.find(lang => lang.code === selectLanguage)?.name}</span>
        <i className="fa fa-chevron-down"></i>
      </div>

      {/* Dropdown Options */}
      {isOpen && (
        <ul className="absolute bg-white border rounded shadow-md mt-2  z-50 w-36">
          {languages.map((lang) => (
            <li
              key={lang.code}
              className="flex items-center gap-2 px-3 py-2 cursor-pointer hover:bg-gray-100 "
              onClick={() => selectLang(lang.code)}
            >
              <img src={lang.flag} alt={`${lang.name} Flag`} className="h-5 w-5" />
              <span className='whitespace-nowrap text-black'>{lang.name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageConverter;
