import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Modal from "react-modal";
import auth from '../../../helpers/auth'
import FormError from '../../../components/Errors/FormError'
import FormSpinner from "../../../components/Spinners/FormSpinner";
import language from '../../../helpers/language';
import {
  fetchIngredients,
  getIngredientsSearch,
  submitRecipeData, submitRecipePostData, recipeUpdateData, tabIndexFun
} from "../modules/RecipeModule";
import CustomUseEffect from '../../../components/constants';

export default function AddIngredients() {
  const [lang] = useState(language.getLang());
  const myRef = useRef(null);
  const history = useHistory()
  const dispatch = useDispatch();
  const [ingredientLoad, setIngredientLoad] = useState(false);
  const [ingredientData, setIngredientData] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [recipeQuantity, setRecipeQuantity] = useState('')
  const [recipeUnit, setRecipeUnit] = useState('')
  const [isCheckedData, setIsCheckedData] = useState('');
  const [fromError, setFormError] = useState('');
  const [selectedIngredientList, setSelectedIngredientList] = useState([]);
  const [processedNextErrorStatus, setProcessedNextErrorStatus] = useState(false);
  const [editIngredientStatus, setEditIngredientStatus] = useState(false);
  const [searchIngredient, setSearchIngredient] = useState('');
  const [filterIngredientsData, setFilterIngredientsData] = useState([]);
  const [isSearchStatus, setIsSearchStatus] = useState(false);
  const [searchIngredientData, setSearchIngredientData] = useState([]);
  const [isSaveDraft, setIsSaveDraft] = useState(false);
  const [explorePercentageSpinner, setExplorePercentageSpinner] = useState(false);
  const [selectedTools, setSelectedTools] = useState([]);
  const [isRecipeSteps, setIsRecipeSteps] = useState([]);
  const [recipeinformation, setRecipeinformation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  CustomUseEffect(async() => {
    if(localStorage.getItem('savedIngredients') != null){
      await setSelectedIngredientList(JSON.parse(localStorage.getItem('savedIngredients')));
    }
    if(localStorage.getItem('savedTools') != null){
      await setSelectedTools(JSON.parse(localStorage.getItem('savedTools')));
    }
    if(localStorage.getItem('recipe_step') != null){
      const addedSteps = JSON.parse(localStorage.getItem('recipe_step'));
      await setIsRecipeSteps(addedSteps);
    }
    if(localStorage.getItem('generalInformation') != null){
      await setRecipeinformation(JSON.parse(localStorage.getItem('generalInformation')));
    }
  },[])

  CustomUseEffect(async () => {
    document.body.classList.add("hidePageScroll");
  }, []);

  const moveToIngredient = (e) => {
    let access = document.getElementById(e.target.value);
    access.scrollIntoView({ behavior: 'smooth' });
  }
  

  CustomUseEffect(async () => {
    let token = await auth.getAccessToken();
    await setIngredientLoad(true)
    await dispatch(fetchIngredients(token)).then(async (res) => {
      if (res && res.success === 200) {
        await setIngredientData(res);
        await setFilterIngredientsData(res);
       await setIngredientLoad(false)
      }
      else{
         await setIngredientLoad(false)
      }
    });
    
  }, []);

  // Search Ingredient
  const searchIngredients = async(e) => {
    setSearchIngredient(e.target.value)
    setIngredientLoad(true)
    if(e.target.value != ''){
      await dispatch((getIngredientsSearch(e.target.value))).then(async(res) => {
        if (res && res.success === 200) {
          await setSearchIngredientData(res);
          await setIsSearchStatus(true)
        }
        await setIngredientLoad(false)
      })
    }
    else{
      await setIsSearchStatus(false)
      await setIngredientLoad(false)
    }
  }

  //console.log(searchIngredientData,"searchIngredientData");
  const handleclick = async (e, res) => {
    if(e.target.checked){
    setOpenModel(true)
    setIsCheckedData(res);
    setRecipeQuantity('');
    setRecipeUnit('');
    setEditIngredientStatus(false);
    }
    else{
      const idex = selectedIngredientList.findIndex(
        (obj) => obj.ingredient_id == res.recipe_ingredient_id
      );

      if(idex > -1){
        selectedIngredientList.splice(idex, 1);
        await setSelectedIngredientList([...selectedIngredientList]);
      }
    }
  }
  const selectUnit = (e) => {
    setRecipeUnit(e.target.value)
  }
  const handleInput = (e) => {
    const regex = /^\d*[.]?\d*$/;
    if (regex.test(e.target.value)) {
      setRecipeQuantity(e.target.value)
    }
  }

  const goToBackPage = async () => {
    if (localStorage.getItem("recipe_id") != null) {
       history.push("/recipes/edit-recipe");
    } else {
       history.push("/recipes/create-recipe");
    }
  }

  const saveIngredientsList = async (e) => {
      if(recipeQuantity != '' && recipeUnit != ''){
        let obj = {
          ingredient_id: isCheckedData?.recipe_ingredient_id ,
          src: isCheckedData?.image_id?.attachment_url,
          title: isCheckedData?.title,
          quantity: recipeQuantity,
          unit: recipeUnit,
          base_url: isCheckedData?.image_id?.base_url,
        }
        await setSelectedIngredientList((old) => [...old, obj]);
        setOpenModel(false);
        setIsCheckedData('');
        setRecipeQuantity('');
        setRecipeUnit('');


      }
      else{
        await setFormError('Please select unit and quantity');
        setTimeout(() => {
          setFormError('');
        }, 2000);
      }
  }

  const handleNextProcess = async() => {
    if(selectedIngredientList.length > 0){
    localStorage.setItem('savedIngredients', JSON.stringify(selectedIngredientList));
    history.push('/recipes/add-untencil')
    }
    else{
      await setErrorMessage(lang?.Please_select_atleast_one_ingredients);
      await setProcessedNextErrorStatus(true);
      setTimeout(() => {
        setErrorMessage();
        setProcessedNextErrorStatus(false);
      }, 2000);
    }
  }

  // Edit Ingredient
  const editIngredientsList = async(ingredient) => {
    await setIsCheckedData(ingredient);
    await setRecipeUnit(ingredient?.unit);
    await setRecipeQuantity(ingredient?.quantity);
    await setOpenModel(true)
    await setEditIngredientStatus(true);
  }

  // Update Ingredient 
  const updateIngredientsList = async() => {
    if(recipeQuantity != '' && recipeUnit != ''){
      const objIndex = selectedIngredientList.findIndex(
        (obj) => obj.ingredient_id == isCheckedData.ingredient_id
      );

      if(objIndex > -1){
        //Update object's name property.
        selectedIngredientList[objIndex].quantity = recipeQuantity;
        selectedIngredientList[objIndex].unit = recipeUnit;
        await setSelectedIngredientList([...selectedIngredientList]);
        setOpenModel(false);
        setIsCheckedData('');
        setRecipeQuantity('');
        setRecipeUnit('');
        setEditIngredientStatus(false);
      }
      else{
        await setFormError(lang?.SomeThingsWrong);
        setTimeout(() => {
          setFormError(false);
        }, 2000);
      }
    }
    else{
      await setFormError(lang?.Please_select_quantity_or_unit);
      setTimeout(() => {
        setFormError(false);
      }, 2000);
    }
  }

  // Delete Selected Ingredient
  const deleteIngredientsList = async(index) => {
    selectedIngredientList.splice(index, 1);
    await setSelectedIngredientList([...selectedIngredientList]);
  }

  // Save recipe as a draft
  const saveInDraft = async() => {
    await setExplorePercentageSpinner(true)
    const postdata = {
      params: {
        name: recipeinformation?.name,
        meal_id: recipeinformation?.meal_id,
        course_id: recipeinformation?.course_id,
        hours: recipeinformation?.hours || 0,
        minutes: recipeinformation?.minutes || 0,
        serving: recipeinformation?.serving,
        cousin_id: recipeinformation?.cousin_id,
        region_id: recipeinformation?.region_id,
        diet_id:recipeinformation?.diet_id,
        intolerance_id: recipeinformation?.intolerance_id,
        cooking_skill_id: recipeinformation?.cooking_skill_id,
        image_id: recipeinformation?.recipeImage,
        saved_ingredients: selectedIngredientList,
        saved_tools: selectedTools,
        recipe_steps: isRecipeSteps,
        status: 0,
      },
    };
    if (localStorage.getItem('recipe_id') != null) {
      if(recipeinformation?.recipeImage === undefined){
         delete postdata?.params?.image_id;
      }
      postdata.params["recipeId"] = localStorage.getItem('recipe_id')
      await recipeUpdateData(postdata).then(async (response) => {
        if (response && response.success === 200) {
          await dispatch(tabIndexFun(2))
          await localStorage.removeItem("recipe_id")
          await localStorage.removeItem("savedIngredients")
          await localStorage.removeItem("savedTools")
          await localStorage.removeItem("recipe_step")
          await localStorage.removeItem("generalInformation")
          history.push('/recipes')
        } else if (response && response.success === 409) {
          await setErrorMessage(response.errors.exception)
          await setProcessedNextErrorStatus(true);

        }
      })
    } else if (recipeinformation != '') {
      await submitRecipeData(postdata).then(async (response) => {
        if (response.success === 200) {
          dispatch(submitRecipePostData(postdata))
          await dispatch(tabIndexFun(2))
          await localStorage.removeItem("savedIngredients")
          await localStorage.removeItem("savedTools")
          await localStorage.removeItem("recipe_step")
          await localStorage.removeItem("generalInformation")
          history.push('/recipes')
        } else if (response.success === 409) {
          await setErrorMessage(response.errors.exception)
          await setProcessedNextErrorStatus(true);
        }
      });

    }
  }
  
  return (
    <Fragment>
      <div className="add__list">
        {/* header */}
        {/* Layout Content */}
        <div className="recipe__page">
          <div className="step">
            <div className="cancel mb--0">
              <Link to="#" onClick={() => { setIsSaveDraft(!isSaveDraft)}}><span><i className="fa fa-arrow-left" aria-hidden="true"></i></span>{lang?.Create_Recipe}</Link>
            </div>
            <div className="right__align_btn btn-white">
              {/* <Link to='/information' className="btn">Back</Link> */}
              <button  onClick={() => { goToBackPage(); document.body.classList.remove("hidePageScroll"); }} className="btn">{lang?.Back}</button>
              <button  onClick={handleNextProcess} className="btn activeBtn">{lang?.Next}</button>
            </div>
          </div>
          <div className="top__div">
            <div className="innerPageTitle">
              <h4 className="semi__bold__title">
                <span className="circle">2</span>
                {lang?.Add_Ingredients_used_in_Recipe}
              </h4>
            </div>
            {processedNextErrorStatus ?  <div> <FormError msg={errorMessage} /></div> : ''}
          </div>
        <div className="site__width">
            <div className="ingredient__main">
              <div className="xl:w-75 lg:w-75 sm:w-full md:w-full lg:order-first xl:order-first md:order-last sm:order-last">
                <div className="ingredients__search">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="search-box">
                      <div className="top-search addList mobSearch">
                        <span className="search_icon"><i className="fas fa-search" aria-hidden="true" /></span>
                        <input type="text" placeholder={lang?.Search_Ingredients} value={searchIngredient} onChange={(e) => searchIngredients(e)}/>
                      </div>
                      <div className="form__item mb-0">
                        <div className="mySelect">
                          <select id="pl" onChange={(e) => {moveToIngredient(e)}}>
                            {
                              filterIngredientsData && filterIngredientsData?.data && filterIngredientsData?.data?.map((list, i) => {
                                  return (
                                    <Fragment key={i}><option value={list.recipe_ingredient_id}>{list.title}</option></Fragment>
                                  )
                                })
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
               
                <div className="innerScroll">
                      {ingredientLoad ? ( <FormSpinner/>  ) : (

                        <div className="create__recipe__list">
                        { 
                          isSearchStatus ? 
                          <ul className="recipe__innerdiv">
                            {
                          
                              searchIngredientData && searchIngredientData?.data?.map((res, indx) => {
                                
                                const isSelected = selectedIngredientList.some((item)=> item.ingredient_id == res.recipe_ingredient_id)
                                return (
                                  <Fragment key={indx}>
                                    <li className="recipe__detail" >
                                      {isSelected ? 
                                        <input className="form-check-input myIngredient"
                                          type="checkbox"
                                          id={res.title}
                                          checked={isSelected}
                                          onClick={(e) => { handleclick(e, res) }}
                                        />
                                        : 
                                        <input className="form-check-input myIngredient"
                                          type="checkbox"
                                          id={res.title}
                                          checked={isCheckedData?.recipe_ingredient_id === res.recipe_ingredient_id}
                                          onClick={(e) => { handleclick(e, res) }}
                                        />
                                      }
                                      <label htmlFor={res.title} > <img
                                        src={`${res && res.image_id && res.image_id.base_url}${res && res.image_id && res.image_id.attachment_url}`}
                                        alt="recipe"
                                        title="image"
                                        height="50"
                                        width="100"
                                        id={res.recipe_ingredient_id}
                                      />
                                        <h4 className="ingredient_name">{res.title}</h4>
                                        <div className="check_mark" />
                                      </label>
                                    </li>
                                  </Fragment>
                                )
                              })
                            }
                          </ul>
                          :
                          ingredientData && ingredientData?.data && ingredientData?.data?.map((data, i) => {
                              return (
                                <Fragment key={i}>
                                   <h1 className='font-18 black' id={data.recipe_ingredient_id}>{data.title}</h1>
                                  <ul className="recipe__innerdiv">
                                    {
                                    
                                      data && data?.ingredients?.map((res, indx) => {
                                       
                                        const isSelected = selectedIngredientList.some((item)=> item.ingredient_id == res.recipe_ingredient_id)
                                        return (
                                          <Fragment key={indx}>
                                            <li className="recipe__detail" >
                                              {isSelected ? 
                                                <input className="form-check-input myIngredient"
                                                  type="checkbox"
                                                  id={res.title}
                                                  checked={isSelected}
                                                  onClick={(e) => { handleclick(e, res) }}
                                                />
                                                : 
                                                <input className="form-check-input myIngredient"
                                                  type="checkbox"
                                                  id={res.title}
                                                  checked={isCheckedData?.recipe_ingredient_id === res.recipe_ingredient_id}
                                                  onClick={(e) => { handleclick(e, res) }}
                                                />
                                              }
                                              <label htmlFor={res.title} > <img
                                                src={`${res && res.image_id && res.image_id.base_url}${res && res.image_id && res.image_id.attachment_url}`}
                                                alt="recipe"
                                                title="image"
                                                height="50"
                                                width="100"
                                                id={res.recipe_ingredient_id}
                                              />
                                                <h4 className="ingredient_name">{res.title}</h4>
                                                <div className="check_mark" />
                                              </label>
                                            </li>
                                          </Fragment>
                                        )
                                      })
                                    }
                                  </ul>
                                </Fragment>
                              )
                            })
                        }
                        </div>
                      ) 
                      }
                </div>
              </div>
              <div className="ingredients-list lg:order-last xl:order-last md:order-first sm:order-first">
                <h4 className="semi__bold__title add-recipe">{lang?.Ingredients_List} </h4>
                <hr className="mb-0" />
                <div className="ingredient-detail justify-center"> {selectedIngredientList && selectedIngredientList.length <= 0 && <span>{lang?.There_are_no_ingredients}</span>} </div>
                {openModel && <Fragment>
                  <div className="quantity">
                    <div className="flex justify-between">
                      <div className="col-left-side">
                        <div className="form__item">
                          <h4 className="title mb-2">{lang?.Quantity}</h4>
                          <input type="text" maxLength={4} placeholder="Enter Quantity" value={recipeQuantity} onChange={(e) => { handleInput(e) }} />
                        </div>
                      </div>
                      <div className="col-left-side">
                        <div className="form__item">
                          <h4 className="title mb-2">{lang?.Unit}</h4>
                          <select defaultValue={recipeUnit === '' ? 'select units' : recipeUnit} onChange={(e) => { selectUnit(e) }}>
                            <option value='-1' >{lang?.only_select} {lang?.Unit}</option>
                            <option value='kg'> {lang?.kg}</option>
                            <option value='litre'>{lang?.litre}</option>
                            <option value='pieces'>{lang?.only_pieces}</option>
                            <option value='dozen'>{lang?.dozen}</option>
                            <option value='gm'>{lang?.gm}</option>
                            <option value='ml'>{lang?.ml}</option>
                            <option value='teaspoon'>{lang?.spoon}</option>
                            <option value='drops'>{lang?.drops}</option>
                            <option value='envelope'>{lang?.Envelope}</option>
                            <option value='clove'>{lang?.clove}</option>
                            <option value='tablespoon'>{lang?.tablespoon}</option>
                            <option value='pinch'>{lang?.pinch}</option>
                            <option value='shot'>{lang?.shot}</option>
                            <option value='as needed'>{lang?.asNeeded}</option>
                            <option value='glass'>{lang?.glass}</option>
                            <option value='cup'>{lang?.cup}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="full_w_btn">
                    { !editIngredientStatus ?  
                        <button type="button" className="btn bg__color__btn" onClick={(e) => saveIngredientsList(e)}>{lang?.Save}</button>
                        :
                        <button type="button" className="btn bg__color__btn" onClick={() => updateIngredientsList()}>{lang?.Edit}</button>
                    }
                    </div>
                    
                  </div>
                </Fragment>
                }
                <div className='listMaxHeight'>
                {selectedIngredientList && selectedIngredientList.length > 0 &&
                  selectedIngredientList.map((list, l) => {
                    return (
                      <Fragment>
                        <div className="ingredient-detail">
                          <div className="ingredient-left" key={l}>
                            <div className="icon-img">
                              <img src={`${list.base_url}${list.src}` || list.src} alt="Recipe" />
                            </div>
                            <div className='item--name'>
                              <span>{list.title}</span>
                            </div>
                            <div className='item--price'>
                              <span>{list.quantity}</span>
                              <span>{list.unit}</span>
                            </div>
                          </div>
                          <div className="ingredient-right">
                            <Link to="#" className="edit">
                              <i className="fa fa-pencil" aria-hidden="true" onClick={(e) => { editIngredientsList(list) }}></i>
                            </Link>
                            <Link to="#" className="close">
                              <i className="fa fa-times-circle" aria-hidden="true" onClick={() => deleteIngredientsList(l)}></i>
                            </Link>
                          </div>
                        </div>
                      </Fragment>)
                  })
                }
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
      {isSaveDraft && (
        <div>
          <Modal
            isOpen={true}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title">{lang?.Save_in_draft}</h4>
              <button
                className="close_btn--modal"
                onClick={() => setIsSaveDraft(!isSaveDraft)}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box">
              <p>{lang?.are_you_sure}</p>
              <div className="right__btn text-right">
                <Link
                  className="btn btn1"
                  to="/recipes"
                  onClick={() => setIsSaveDraft(false)}
                >
                  {lang?.Cancel}
                </Link>
                <Link
                  className="btn btn2 ml-3"
                  to="#"
                  onClick={() => saveInDraft()}
                >
                  {explorePercentageSpinner ? <FormSpinner /> : `${lang?.Save}`}

                </Link>
              </div>
            </div>
            <div></div>
          </Modal>
        </div>
      )}
    </Fragment>
  );

}