import React, { Fragment } from 'react'
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import Modal from "react-modal";
import moment from 'moment';
import Carousel from 'react-grid-carousel';
import parse from 'html-react-parser';
import ReactImageVideoLightbox from 'react-image-video-lightbox'

import user_img from '../../../assets/images/user_profile-img.png'
import FormSpinner from '../../../components/Spinners/FormSpinner';
import {
  getMarketplaceProductDetailsBySlug,
  getProductStoreReview,
  saveReviewRating,
  updateMarketplaceReview,
  setReviews,
  fetchHomeScreenData,
  addProdctToCart,
} from "../modules/marketplace";
import auth from '../../../helpers/auth';
import constant from '../../../helpers/constant';
import MemeberType from '../../../components/MemeberType';
import language from '../../../helpers/language';
import IncoTermModal from './IncoTermModal';
import CustomUseEffect from '../../../components/constants';


export default function ProductView() {
  let { slug } = useParams();
  const [lang] = useState(language.getLang());
  const dispatch = useDispatch();
  const history = useHistory();
  const [reviewModal, setReviewModal] = useState(false);
  const [addReviewModal, setAddReviewModal] = useState(false);
  const [editReviewModal, setEditReviewModal] = useState(false);
  const [isRatings, setIsRatings] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [reviewStatus, setReviewStatus] = useState("");
  const [reviewSuccessMsg, setReviewSuccessMsg] = useState("");
  const [marketplaceReviewRatingId, setMarketplaceReviewRatingId] = useState(0);
  const [isMoreTrue, setIsMoreTrue] = useState(true);
  const [isPrice, setIsPrice] = useState(false);
  const [user, setUser] = useState([]);
  const [isMoreTextTrue, setIsMoreTextTrue] = useState(true);
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [userPhotosData, setUserPhotosData] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [productItemCart, setProductItemInCart] = useState([]);
  const [incoTerm, setIncoTerm] = useState(false);
  // const [userData,setUserData]=useState()

  // useEffect(()=>{
  //   let role=JSON.parse(localStorage.getItem('USER_TYPE'))
  //   setUserData(role)
  // },[])

  CustomUseEffect(async () => {
    window.scrollTo(0, 0);
    setIsRatings(false);
    await dispatch(setReviews([]));
    await dispatch(getMarketplaceProductDetailsBySlug(slug));
    const user = await auth.getUserType();
    await setUser(JSON.parse(user));
    setIsRatings(true);
    setIsPrice(true)
  }, [slug]);

  useEffect(() => {
    const totalProduct = localStorage.getItem('totalProductInCart') ? JSON.parse(localStorage.getItem('totalProductInCart')) : [];
    totalProduct?.length > 0 && setProductItemInCart(totalProduct);
  }, []);

  useEffect(() => {
    productItemCart?.length > 0 && localStorage.setItem('totalProductInCart', JSON.stringify(productItemCart));
  }, [productItemCart]);

  const showMore = (number) => {
    number === 1 && setIsMoreTrue(!isMoreTrue);
    number === 2 && setIsMoreTextTrue(!isMoreTextTrue);
  }

  let {
    marketplaceProduct,
    marketplaceRelatedProduct,
    reviews,
    productReviewed,
    marketPlaceProductSpinner,
  } = useSelector((state) => state.marketPlaceReducerData);


  console.log(marketplaceProduct,"mareketpaklce ")

  const { profileData: { data } } = useSelector((state) => state.memberProfileData);

  const getAllReviews = async (id) => {
    await dispatch(getProductStoreReview(id, 2));
    setReviewModal(!reviewModal);
  }

  const addReviewModalFn = async () => {
    setReviewModal(!reviewModal)
    setAddReviewModal(!addReviewModal)
  }

  const editReviewModalFn = async () => {
    setReviewModal(!reviewModal);
    setEditReviewModal(!editReviewModal);
    const user_id = data && data.user_data.user_id;
    const userReview = reviews.filter((item) => parseInt(item.user_id) === parseInt(user_id));
    userReview && userReview.map((item) => {
      setRating(item.rating);
      setReview(item.review);
      setMarketplaceReviewRatingId(item.marketplace_review_rating_id);
    });
  }

  const submitUpdateReview = async () => {
    //console.log(review,"reviewreview");
    if (review === "") {
      setReviewStatus("Review is required");
    } else {
      setIsRatings(false);
      let type = 2;
      const data = {
        type,
        rating,
        review,
        marketplace_review_rating_id: marketplaceReviewRatingId,
      }
      const update_review = reviews.map(item =>
        (item.marketplace_review_rating_id === marketplaceReviewRatingId ? { ...item, review: review, rating } : item));
      const response = data && await dispatch(updateMarketplaceReview(data, slug));
      if (response.success === 200) {
        setIsRatings(true);
        await dispatch(setReviews(update_review));
        setEditReviewModal(!editReviewModal);
        setReviewModal(!reviewModal);
        setReviewModal(false);
        setReviewSuccessMsg(response.message);
        await dispatch(fetchHomeScreenData());
      }
    }

    setTimeout(() => {
      setReviewSuccessMsg('');
      setReviewStatus("");
    }, 3000);
  }

  const ratingChanged = (newRating) => {
    setRating(newRating);
  }

  const reviewChanged = (value) => {
    setReview(value);
  }

  const submitReview = async () => {
    setIsRatings(false);
    if (rating <= 0) {
      setReviewStatus(lang?.Rating_Is_Require);
    } else if (review.trim() === "") {
      setReviewStatus(lang?.Review_Is_Require);
    } else if (review.trim()?.length < 3) {
      setReviewStatus(lang?.Review_Must_Be_Greater_Than_Three_Character);
    } else {
      let response = await dispatch(saveReviewRating(review, rating, 2, marketplaceProduct.marketplace_product_id, slug));
      if (response.success === 200) {
        setIsRatings(true);
        setReviewSuccessMsg(lang?.Thank_You_For_Your_Review);
        setAddReviewModal(!addReviewModal)
        await dispatch(fetchHomeScreenData());
      }
      if (response.success === 409) {
        setIsRatings(true);
        setReviewStatus(response.errors.exception);
      }
    }
    setTimeout(() => {
      setReviewStatus('');
      setReviewSuccessMsg('');
    }, 3000);
  }

  const textLimit = (text, limit) => {
    if (text.length > limit) {
      const part = text.slice(0, limit);
      return part + '...';
    }
    return text;
  };

  const calculateRatingPercentage = (total_one_start, total_reviews) => {
    if (parseInt(total_one_start) === 0) {
      return 0;
    }
    return ((total_one_start / total_reviews) * 100).toFixed();
  }

  // photos gallery
  const handlePhotos = async (data, url) => {
    let userPhotos = []
    await marketplaceProduct?.product_gallery?.map(async (item) => {
      await userPhotos.push({ url: `${item.base_url}${item.attachment_url}`, type: "photo", altTag: "image", })
    })
    userPhotos?.map(async (item, index) => {
      console.log(item?.url, url);
      if (item?.url === url) {
        await setImageIndex(index)
      }
    })

    await setUserPhotosData(userPhotos)
    await setIsOpenImage(true)
  }

  // Add product into cart
  const addToCart = (item) => {
    localStorage.setItem('stripe_id', item?.stripeConnectedId);
    //distructuring taxes from item 
    const taxes = item?.get_product_tax?.get_tax_classes?.map((item) => ({ ...item?.get_tax_detail }));
    // checking product is from same store or not
    if (productItemCart?.length > 0 && findProductAlreadyAdded(item.marketplace_store_id) && productItemCart?.[0]?.map_offer_id?.length === 0) {
      // filter same product from productItemCart
      const filterWithIdData = productItemCart.filter((prod) => prod.marketplace_product_id === item.marketplace_product_id);
      let data = [];
      if (filterWithIdData?.length > 0) {
        data = filterWithIdData?.map((product) => (product.marketplace_product_id === item.marketplace_product_id)
          ? { ...product, count: product.count + Number(product?.min_order_quantity), available_status: 0, tax: taxes, product_price: item.product_price ? item.product_price : 0, map_offer_id: [] } : product);
      } else {
        data = [{ ...item, count: Number(item?.min_order_quantity), available_status: 0, tax: taxes, map_offer_id: [] }]
      }
      const newfilterData = productItemCart?.filter((prod) => prod.marketplace_product_id !== item.marketplace_product_id)
      setProductItemInCart([...newfilterData, ...data]);
      dispatch(addProdctToCart([...newfilterData, ...data]))
    } else {
      // alert when adding other store product in cart
      if (productItemCart?.length > 0) {
        showAlert([{ count: Number(item?.min_order_quantity), ...item, available_status: 0, tax: taxes, map_offer_id: [] }])
      } else {
        setProductItemInCart([{ count: Number(item?.min_order_quantity), ...item, available_status: 0, tax: taxes, map_offer_id: [], }]);
        dispatch(addProdctToCart([{ count: Number(item?.min_order_quantity), ...item, available_status: 0, tax: taxes, map_offer_id: [], }]));
      }
    }
  }

  // find all Product are same store
  const findProductAlreadyAdded = (id) => {
    return productItemCart?.length > 0 ? productItemCart?.some((product) => product.marketplace_store_id === id) : true;
  }


  // click on cancel button
  const handleAlertCancel = () => {
    // Function to be called when Cancel is clicked
    console.log('Cancel button clicked!');
    // Call any other desired function or perform actions here
  };

  // if user add product from other store
  const showAlert = (data) => {
    if (window.confirm(productItemCart?.[0]?.map_offer_id?.length > 0 ? `At a time you can add either a product including offer or a product without offer.
    Do you want to add this product. Are you sure?` : `Currently you have items in your cart from another store.Would you like to clear the cart and add this product . Are you sure?`)) {
      setProductItemInCart(data);
      dispatch(addProdctToCart(data));
    } else {
      handleAlertCancel();
    }
  };

  // check product is already in cart
  const checkProductAlreadyInCart = (id) => {
    if (!productItemCart) return false;
    return productItemCart.some((item) => item.marketplace_product_id === id)
  }

  return (
    <Fragment>
      {reviewModal && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={reviewModal}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title">{lang?.Reviews}</h4>
              <button
                className="close_btn--modal"
                onClick={() => setReviewModal(!reviewModal)}
              >
                {lang?.close}
              </button>
            </div>

            <div className="card card--block modal_box">
              {productReviewed && <div className='text-right'>
                <Link to='#' className='btn BgTeal' onClick={() => addReviewModalFn()}>+{lang?.Add_review}</Link>
              </div>}
              <div className="feed-user-info home_page block edit mt-3 mb-3 addReview">
                {reviews && reviews.map((item, i) => {
                  return (
                    <div id={`review` + i} className='w-full reviewRepeat' key={i}>
                      <div className='flex'>
                        <div className='blockLeft'>
                          <div className="user__pic mr-3">
                            <span className='profile-img'>
                              <img
                                src={`${item && item.user && item.user.avatar_id && item.user.avatar_id.base_url}${item && item.user && item.user.avatar_id && item.user.avatar_id.attachment_url}`}
                              />
                            </span>
                          </div>
                          <div>
                            <span className='profile-name font-bold'>
                              {item && item.user && item.user.review_name}
                            </span>
                            <div className='description mt-0'>
                              <p>{item && item.review}</p>
                            </div>
                          </div>
                        </div>
                        <div className='blockRight'>
                          <div className='review justify-end'>
                            <ReactStars
                              edit={false}
                              isHalf={true}
                              value={parseFloat(item.rating)}
                              activeColor="#eb8a2f"
                              color="rgb(74 72 72)"
                            />
                          </div>

                        </div>
                      </div>
                    </div>
                  )
                })
                }

              </div>
              <div className='text-right'>{!productReviewed &&
                <Link to='#' className="btn BgTeal" onClick={() => editReviewModalFn()}>{lang?.Edit_review}</Link>
              }</div>

              {/* <div className="user__detail-data">
                <span className="user__name">

                </span>
              </div> */}

            </div>
          </Modal>
        </div>
      )}

      {addReviewModal && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={addReviewModal}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              {/* <h4 className="page__title">{lang?.Reviews}</h4> */}
              <h4 className='font-16'>{lang?.AddReviewAndRatings}</h4>
              <button className="close_btn--modal" onClick={() => { setAddReviewModal(!addReviewModal); setReview(''); setRating(0) }} > {lang?.close}</button>
            </div>
            <div className="card card--block modal_box addReview">
              <div className="feed-user-info home_page items-center mb-3 addReview">
                <div className='blockLeft'>
                  <div className="user__pic mr-3">
                    {data.user_data && data.user_data.avatar_id ? <img src={`${data.user_data.avatar_id.base_url}${data.user_data.avatar_id.attachment_url}`} height="100" width="100" /> : <img src={user} alt="default-image" />}
                  </div>
                  <div className="">
                    <span className="user__name">
                      <div className="profile-name font-bold">
                        {data && data.user_data && data.user_data.company_name}
                      </div>
                    </span>
                  </div>
                </div>
                <div className='blockRight'>
                  <div className="rating text-right">
                    <ReactStars
                      // edit={false}
                      isHalf={false}
                      activeColor="#eb8a2f"
                      color="rgb(74 72 72)"
                      edit={true}
                      onChange={ratingChanged}
                    />
                  </div>
                </div>
              </div>
              <p style={{ color: "red" }}>{reviewStatus}</p>
              <div className='feed-user-info home_page'>
                <div className="comment-box w-full">
                  <div className="comment-area">
                    <textarea className="form-control"
                      placeholder="what is your view?"
                      rows="4"
                      onChange={(e) => reviewChanged(e.target.value)}
                    >
                    </textarea>
                  </div>
                  <div className="text-right mt-4">
                    <Link to="#"><a className="btn BgTeal" onClick={() => submitReview()}>{lang?.Add_review} </a></Link>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
      {editReviewModal && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={editReviewModal}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              {/* <h4 className="page__title">{lang?.Reviews}</h4> */}
              <h4 className='font-16'>{lang?.EditReviewAndRatings}</h4>
              <button
                className="close_btn--modal"
                onClick={() => setEditReviewModal(!editReviewModal)}
              >
                {lang?.close}
              </button>
            </div>

            <div className="card card--block modal_box">
              <div className="feed-user-info home_page block edit mb-3 addReview">
                <div className='flex justify-between'>
                  <div className='blockLeft'>
                    <div className="user__pic mr-3">
                      <span className="profile-img">
                        {data && data.user_data.avatar_id ? <img src={`${data.user_data.avatar_id.base_url}${data.user_data.avatar_id.attachment_url}`} height="100" width="100" /> : <img src={user_img} alt="default-image" />}
                      </span>
                    </div>
                    <div>
                      <span className="profile-name font-bold">{data && data.user_data && data.user_data.company_name}</span>
                    </div>
                  </div>
                  <div className='blockRight'>
                    <div className="rating text-right">
                      <ReactStars
                        // edit={false}
                        isHalf={false}
                        activeColor="#eb8a2f"
                        value={parseFloat(rating)}
                        color="rgb(74 72 72)"
                        edit={true}
                        onChange={ratingChanged}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p style={{ color: "red" }}>{reviewStatus}</p>
              <div className='feed-user-info home_page'>
                <div className="comment-box w-full">
                  <div className="comment-area">
                    <textarea className="form-control"
                      placeholder={lang?.What_Is_Your_View}
                      rows="4"
                      onChange={(e) => reviewChanged(e.target.value)}
                      defaultValue={review}
                    >
                    </textarea>
                  </div>
                  <div className="text-right mt-4">
                    <Link to="#" className="btn BgTeal" onClick={() => submitUpdateReview()}>{lang?.Update_Review} </Link>
                  </div>

                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
      <div className="marketplace mrkthome add-product bg-gray newGridView listPage">
        {/* Banner Section */}
        <div className="">
          <div className="section__wrapper">
            <div className="page-main">
              <div className="">
                <div className="flex justify-between items-center">
                  <Link to="#" className="back__btn" onClick={() => history.goBack()}>
                    <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;
                    {lang?.Go_back}
                  </Link>
                  <div className='flexBox items-center gap-4'>
                    {(marketplaceProduct?.quantity_available > 0 && (marketplaceProduct?.product_price !== null && marketplaceProduct?.product_price > 0) && (user.role_id === constant.rolesId.importer || user.role_id === constant.rolesId['importer&distributer'] || user.role_id === constant.rolesId.distributer)
                    ) && <div className='flexBox items-center gap-6'> {
                      (checkProductAlreadyInCart(marketplaceProduct?.marketplace_product_id)) ?
                        <button className="btn btnTeal addtocart withIcon" onClick={() => history.push("/marketplace/my-cart")}>
                          {lang?.go_to_cart}
                        </button> :
                        marketplaceProduct?.stripeConnected && (
                        <button className="btn btnTeal addtocart withIcon" onClick={() => addToCart(marketplaceProduct)}>
                          <div className="pretext">
                            <svg width="25" height="25" viewBox="0 0 30 30" fill="none">
                              <path d="M6.76514 2.49805L2.50244 2.51758L2.51465 5.01758L5.10254 5.00537L6.14258 7.50049H6.25H7.79297V7.50293H14.0479L11.6382 11.3262L10.1123 13.7505H10.1147L7.90039 17.2832C7.40039 18.0832 7.37453 19.0937 7.83203 19.9175C8.28953 20.7412 9.1576 21.2529 10.1001 21.2529H24.9976V18.7529H10.1001L10.0195 18.6064L11.4917 16.2529H20.6494C21.5582 16.2529 22.3945 15.7588 22.8345 14.9663L27.3389 6.8584C27.5551 6.4709 27.5492 6.00066 27.3242 5.61816C27.0992 5.23691 26.6876 5.00293 26.2451 5.00293H7.81006L6.76514 2.49805ZM0 10.0005V12.5005H7.94922L8.00049 12.4248L9.52393 10.0005H0ZM0 15.0005V17.5005H4.7998L6.38672 15.0005H0ZM9.99756 22.5029C9.33452 22.5029 8.69863 22.7663 8.22979 23.2352C7.76095 23.704 7.49756 24.3399 7.49756 25.0029C7.49756 25.666 7.76095 26.3019 8.22979 26.7707C8.69863 27.2395 9.33452 27.5029 9.99756 27.5029C10.6606 27.5029 11.2965 27.2395 11.7653 26.7707C12.2342 26.3019 12.4976 25.666 12.4976 25.0029C12.4976 24.3399 12.2342 23.704 11.7653 23.2352C11.2965 22.7663 10.6606 22.5029 9.99756 22.5029ZM22.4976 22.5029C21.8345 22.5029 21.1986 22.7663 20.7298 23.2352C20.261 23.704 19.9976 24.3399 19.9976 25.0029C19.9976 25.666 20.261 26.3019 20.7298 26.7707C21.1986 27.2395 21.8345 27.5029 22.4976 27.5029C23.1606 27.5029 23.7965 27.2395 24.2653 26.7707C24.7342 26.3019 24.9976 25.666 24.9976 25.0029C24.9976 24.3399 24.7342 23.704 24.2653 23.2352C23.7965 22.7663 23.1606 22.5029 22.4976 22.5029Z" fill="white" />
                            </svg>
                            {lang?.add_to_cart}
                          </div>
                        </button>
                        )
                    }
                      </div>}
                    {(user.role_id === constant.rolesId['importer&distributer'] || user.role_id === constant.rolesId.importer || user.role_id === constant.rolesId.distributer || user.role_id === constant.rolesId.voiceOfExpert || user.role_id === constant.rolesId.restaurant)
                      && isPrice &&
                      <div className="inner-btn-box border" >
                        <Link to={`/marketplace/send-inquiry/${(marketplaceProduct.marketplace_product_id)}`} className="btn btn-blue justify-center">
                          <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <path d="M15 2.5C8.09625 2.5 2.5 8.09625 2.5 15C2.5 21.9037 8.09625 27.5 15 27.5C21.9037 27.5 27.5 21.9037 27.5 15C27.5 8.09625 21.9037 2.5 15 2.5ZM16.25 21.25H13.75V13.75H16.25V21.25ZM16.25 11.25H13.75V8.75H16.25V11.25Z" fill="#4BB3FD" />
                          </svg>
                          {lang?.Send_Inquiry}
                        </Link>
                      </div>}
                  </div>
                </div>
                <div className="our__gallery mt--5 sm:mt--2">
                  <h2 style={{ color: "green" }} className="text-center mb-4 font-20">{reviewSuccessMsg}</h2>
                  <div className="flex reviewMob">
                    {/* left block */}
                    <div className="page_w_left">

                      {/* Product info */}
                      <Fragment>
                        <div className="flex justify-between">
                          <h4 className="font-20 mt--0">{marketplaceProduct.title}</h4>
                          {/* {(user.role_id === constant.rolesId.importer || user.role_id === constant.rolesId.distributer || user.role_id === constant.rolesId['importer&distributer'])
                            && isPrice && (marketplaceProduct.product_price !== null && marketplaceProduct.product_price > 0) ?
                            <>
                              <div className='flexBox items-center gap-4'>
                                <h4 className="font-20 mt--0">${marketplaceProduct.product_price}</h4>
                              </div>
                            </> : marketplaceProduct?.marketplace_product_id && <h5 className="textBlue">Send inquiry for abfd price*</h5>} */}
                        </div>
                        <div className="flex items-center mt-4">
                          <div className='send__Enquiry'>
                            <div className='flex flex-wrap items-center'>
                              <p className="rating__bg flex items-center">
                                <span className="flex items-center gap-1">{marketplaceProduct.avg_rating}
                                  <i className="fa fa-star" aria-hidden="true" />
                                </span>
                              </p>&nbsp;
                              {marketplaceProduct.total_reviews} {(marketplaceProduct.total_reviews <= 1) ? lang?.review : lang?.reviews}

                            </div>
                            <p className="food-product ml-5">
                              {marketplaceProduct.product_category_name}
                            </p>
                          </div>

                          {(user.role_id === constant.rolesId.importer || user.role_id === constant.rolesId.distributer || user.role_id === constant.rolesId['importer&distributer'])
                            && isPrice && (marketplaceProduct.product_price !== null && marketplaceProduct.product_price > 0) ?
                            <>
                              <div className='flexBox items-center gap-4 ml-auto'>
                                <h4 className="font-20 mt--0">${marketplaceProduct.product_price}</h4>
                              </div>
                            </> : marketplaceProduct?.marketplace_product_id && <div className='blue-btn-full sendenquiry__btn'><a className="textBlue btn btnPrimary noCursor">Send inquiry for price</a></div>}






                        </div>
                        <div className="mt-4 mb-4">
                          {marketPlaceProductSpinner ? <FormSpinner /> : marketplaceProduct?.product_gallery?.length > 0 ?
                            <Carousel cols={3} rows={1} gap={10} loop
                              hideArrow={marketplaceProduct?.product_gallery?.length <= 3 ? true : false}
                            >
                              {marketplaceProduct?.product_gallery.map((item, index) => {
                                return (
                                  <Carousel.Item>
                                    <div className='productImgSlider' key={index}>
                                      <Link to='#'>
                                        <img
                                          src={`${item.base_url}${item.attachment_medium_url}`}
                                          alt="Image"
                                          onClick={() => handlePhotos(marketplaceProduct, `${item.base_url}${item.attachment_url}`)}
                                        />
                                      </Link>
                                    </div>
                                  </Carousel.Item>
                                )
                              })
                              }
                            </Carousel>
                            :
                            <img src={user_img} />
                          }
                        </div>
                        <div className="gallery_data textCaps">
                          {marketplaceProduct?.incoterm && <p className="textGreen itemTotal text-left relative incoTerms">
                            <span style={{ color: "#000" }}>*{lang?.Incoterms} : </span> {marketplaceProduct?.incoterm} <span className=''><i className="fa fa-exclamation-circle infoIcon" aria-hidden="true" onClick={() => setIncoTerm(!incoTerm)}></i></span></p>}
                          <h4 className="font-20">{lang?.About_Product}</h4>
                          <p>
                            {marketplaceProduct.description}
                          </p>&nbsp;
                          <hr />
                        </div>
                      </Fragment>

                      <div className="gallery_data">
                        {/* quantity available */}
                        {(user.role_id === constant.rolesId.importer || user.role_id === constant.rolesId.distributer
                          || user.role_id === constant.rolesId['importer&distributer']
                        ) &&
                          <Fragment>
                            <div className="about__product">
                              <div className="flex justify-between items-center mt-1 mb-1">
                                <h4 className="font-20">{lang?.Quantity_Available}:</h4>
                                <h4 className="font-20">{(marketplaceProduct.quantity_available && marketplaceProduct.quantity_available !='null') ? marketplaceProduct.quantity_available :'--'}</h4>
                              </div>
                            </div>
                            <hr />
                          </Fragment>
                        }

                        {/* {(marketplaceProduct.brand_label_id !== null) &&
                          <><div className="about__product">
                            <div className="flex justify-between items-center mt-1 mb-1">
                              <h4 className="font-20"> {lang?.Brand_Label}</h4>
                              {getBrandLevel(marketplaceProduct?.brand_label_id)}
                              <h4 className="font-20">{marketplaceProduct.brand_label_id}</h4>
                            </div>
                          </div>
                            <hr /></>}

                        {/* min order quantity */}
                        {(user.role_id === constant.rolesId.importer || user.role_id === constant.rolesId.distributer
                          || user.role_id === constant.rolesId['importer&distributer']
                        ) &&
                          <Fragment>
                            <div className="about__product">
                              <div className="flex justify-between items-center mt-1 mb-1">
                                <h4 className="font-20">{lang?.Minimum_Order_Quantity}</h4>
                                <h4 className="font-20" >{(marketplaceProduct.min_order_quantity && marketplaceProduct.min_order_quantity !=null && marketplaceProduct.min_order_quantity !='null') ? marketplaceProduct.min_order_quantity :'--'}</h4>
                              </div>
                            </div>
                            <hr />
                          </Fragment>
                        }
                        {/* recommended retails price */}
                        {(marketplaceProduct.product_price > 0) && (user.role_id === constant.rolesId.importer || user.role_id === constant.rolesId.distributer
                          || user.role_id === constant.rolesId['importer&distributer'] || user.role_id === constant.rolesId.producer
                        ) &&
                          <Fragment>
                            <div className="about__product">
                              <div className="flex justify-between items-center mt-1 mb-1">
                                <h4 className="font-20">{lang?.Recommended_retail_price} </h4>
                                <h4 className="font-20">{marketplaceProduct?.rrp_price  !== null  && marketplaceProduct?.rrp_price > 0 ? `$${marketplaceProduct?.rrp_price}`  : "--"}</h4>
                              </div>
                            </div>
                            <hr />
                          </Fragment>
                        }

                        {/* handling instructions */}
                        {(user.role_id === constant.rolesId.importer || user.role_id === constant.rolesId.distributer
                          || user.role_id === constant.rolesId['importer&distributer']
                        ) &&
                          <Fragment>
                            <div className="about__product">
                              <div className="flex justify-between items-center mt-1 mb-1">
                                <h4 className="font-20">{lang?.Handling_Instructions}</h4>
                                <h4 className="font-20">
                                  <a><i className={isMoreTrue ? "fa fa-angle-down" : "fa fa-angle-up"} aria-hidden="true" onClick={() => showMore(1)} /></a>
                                </h4>
                              </div>
                              {isMoreTrue && marketplaceProduct.handling_instruction && marketplaceProduct.handling_instruction.length > 100 ? parse(`${marketplaceProduct.handling_instruction.slice(0, 100)}...`) : parse(marketplaceProduct.handling_instruction)}
                            </div>
                            <hr />
                          </Fragment>
                        }
                        {/* dispatch instructions */}
                        {(user.role_id === constant.rolesId.importer || user.role_id === constant.rolesId.distributer
                          || user.role_id === constant.rolesId['importer&distributer']
                        ) &&
                          <Fragment>
                            <div className="about__product">
                              <div className="flex justify-between items-center mt-1 mb-1">
                                <h4 className="font-20">{lang?.Dispatch_Instructions}</h4>
                                <h4 className="font-20">
                                  <a><i className={isMoreTextTrue ? "fa fa-angle-down" : "fa fa-angle-up"} aria-hidden="true" onClick={() => showMore(2)} /></a>
                                </h4>
                              </div>
                              {isMoreTextTrue && marketplaceProduct?.dispatch_instruction?.length > 100 ? parse(`${marketplaceProduct.dispatch_instruction.slice(0, 100)}...`) : parse(`${marketplaceProduct.dispatch_instruction}`)}
                            </div>
                            <hr />
                          </Fragment>
                        }

                        {/* sample available */}
                        {(user.role_id === constant.rolesId.importer || user.role_id === constant.rolesId.distributer
                          || user.role_id === constant.rolesId['importer&distributer'] || user.role_id === constant.rolesId.voiceOfExpert) &&
                          <Fragment>
                            <div className="about__product">
                              <div className="flex justify-between items-center mt-1 mb-1">
                                <h4 className="font-20">{lang?.Sample_Available}</h4>
                                <h4 className="font-20">{marketplaceProduct.available_for_sample}</h4>
                              </div>
                            </div>
                            <hr />
                          </Fragment>}

                        {/* Related Products */}
                        {marketplaceRelatedProduct.length > 0 && (
                          <>
                            <h4 className="font-20">{lang?.Related_Products}</h4>
                            <div className="product-type mt-4">
                              <div className="card-block-wrapper grid3">
                                {
                                  marketplaceRelatedProduct && marketplaceRelatedProduct.map((item) => {
                                    console.log( item?.stripeConnected,"hh")
                                    if (item.user_id !== marketplaceProduct.user_id);
                                    return (
                                      <div className="card-block-item mt-2 mb-2" >
                                        <div className="card-item-wrapper" key={item.marketplace_product_id}>
                                          <div className="card-item-img">
                                            <div className='item-img'>
                                              <Link to={`/marketplace/product/${item.slug}`}>
                                                {item.product_gallery.length > 0 ? <img
                                                  src={`${item.product_gallery[0].base_url}${item.product_gallery[0].attachment_medium_url}`} alt={item.name}
                                                /> : <img src={user_img} alt="default-image" />}
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-item-content">
                                          <div className='card-content-wrapper'>
                                            <div className='flex flex-wrap justify-between mt-3'>
                                              <h4 className="font-18 black mt-0">{textLimit(item.title, 12)}</h4>
                                              {/* <h4 className='font-18 black mt-0'>$1.00</h4> */}
                                            </div>
                                            <div className='flex flex-wrap justify-between items-center mt-2'>
                                              <p className='rating flex items-center'>
                                                <span className='flex items-center gap-1'>{item.avg_rating}
                                                  <i className="fa fa-star" aria-hidden="true"></i>
                                                </span> &nbsp;
                                                {item.total_reviews} {(item.total_reviews <= 1) ? lang?.review : lang?.reviews}
                                              </p>
                                              {((item?.product_price !== null && item?.product_price > 0 && item?.quantity_available > 0) && (user.role_id === constant.rolesId["importer&distributer"] || user.role_id === constant.rolesId?.importer || user.role_id === constant.rolesId.distributer)) &&
                                                <>
                                                  {(checkProductAlreadyInCart(item?.marketplace_product_id)) ? <button className="btn btnTeal addtocart font-bold" onClick={() => history.push("/marketplace/my-cart")}>
                                                    Go To Cart
                                                  </button> :
                                                  item?.stripeConnected  &&<button className="btn btnTeal addtocart font-bold" onClick={() => addToCart(item)}>
                                                      <div className="pretext">
                                                      {/* disabled={item?.stripeConnected} */}
                                                        {/* <i className="fas fa-cart-plus"></i> */}
                                                        Add To Cart
                                                      </div>
                                                    </button>} </>}
                                            </div>
                                            <div className='flex flex-wrap justify-between items-center mt-2'>

                                              {/* <div className="review">
                                                      <h4 className="text-left prdcr-store-title flex">{item.avg_rating}
                                                        <span className='ml-2'>
                                                          <ReactStars
                                                            edit={false}
                                                            isHalf={true}
                                                            value={parseFloat(item.avg_rating)}
                                                            activeColor="#eb8a2f"
                                                            color="rgb(74 72 72)"
                                                          />
                                                        </span>
                                                      </h4>
                                                    </div>
                                                    <p>{item.total_reviews} {(item.total_reviews <= 1) ? lang?.review : lang?.reviews}</p> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })

                                }
                              </div>
                            </div>
                          </>
                        )
                        }
                      </div>
                    </div>

                    {/* right block  start */}
                    <div className="page_w_right xl:mt-0 lg:mt-0 md:mt-4 sm:mt-4">

                      <div className="store__detail xl:mt-8 lg:mt-8 md:mt-4 sm:mt-4">
                        {/* <h4 className="font-20 m-0">{marketplaceProduct && marketplaceProduct.store_detail && marketplaceProduct.store_detail.name}</h4>
                          <p>{marketplaceProduct && marketplaceProduct.store_detail && marketplaceProduct.store_detail.location}</p>
                          <div className="flex">
                            <div className="reviw-border">
                              <div className="review mt-2">
                                <h4 className="text-left prdcr-store-title">{marketplaceProduct && marketplaceProduct.store_detail && marketplaceProduct.store_detail.avg_rating}</h4>
                                <p>
                                <ReactStars
                                    edit={false}
                                    isHalf={true}
                                    value={marketplaceProduct && marketplaceProduct.store_detail && marketplaceProduct.store_detail.avg_rating}
                                    activeColor="#eb8a2f"
                                    color="#fff"
                                  />
                                </p>
                              </div>
                              <div>{marketplaceProduct && marketplaceProduct.store_detail && marketplaceProduct.store_detail.total_reviews} {(marketplaceProduct.store_detail.total_reviews <= 1) ? "Review" : "Reviews"}</div>
                            </div>
                            <div className="reviw-border">
                              <div className="review mt-2">
                                <h4 className="text-left prdcr-store-title">1000+</h4>
                              </div>
                              <div>Products</div>
                            </div>
                            <div className="reviw-border">
                              <div className="review mt-2">
                                <h4 className="text-left prdcr-store-title">20+</h4>
                              </div>
                              <div>Categories</div>
                            </div>
                          </div>
                          <hr /> */}
                        <div className="producer-name pt-4">
                          <div className="feed-user-info mb-5">
                            <div className="user__pic mr-3">
                              {marketplaceProduct?.user?.avatar_id ? <img
                                src={`${marketplaceProduct.user?.avatar_id?.base_url}${marketplaceProduct.user.avatar_id?.attachment_url}`}
                                alt={marketplaceProduct.user?.company_name}
                                title={marketplaceProduct.user?.company_name}
                              /> : <img src={user_img} />}
                            </div>
                            <div className="user__detail-data">
                              <span className="user__name">
                                {/* <a href="#">{marketplaceProduct?.user?.company_name}</a> */}
                                {(data?.user_data?.user_id !== marketplaceProduct?.user_id) ?
                                  <Link to={`/user-profile/${marketplaceProduct && marketplaceProduct.user_id}`} className="">{marketplaceProduct?.user?.company_name}</Link> :
                                  <Link to="/profile" className="btn btnPrimary p-0">{marketplaceProduct?.user?.company_name}</Link>}
                              </span>
                              <MemeberType item={marketplaceProduct.user} />
                              {/* <span className="time_posted">Italian F&amp;B Producers</span> */}
                            </div>
                          </div>
                          <div className="blue-btn-full w-full">
                            {(data?.user_data?.user_id !== marketplaceProduct?.user_id) ?
                              <Link to={`/user-profile/${marketplaceProduct && marketplaceProduct.user_id}`} className="btn btnPrimary">{lang?.View_profile}</Link>
                              : <Link to="/profile" className="btn btnPrimary">{lang?.View_profile}</Link>
                            }
                          </div>
                        </div>
                        <hr />
                        <div className="rating__review">
                          <div className="flex justify-between">
                            <h4 className="font-20 mt--0">{lang?.Ratings_and_reviews}</h4>
                            <Link to='#' className="font-20" onClick={() => getAllReviews(marketplaceProduct.marketplace_product_id)}>{lang?.See_All}</Link>
                          </div>
                          <div className="review mt-2 items-center">
                            <div className='ratingBlock'>
                              <span className="font-20">{marketplaceProduct?.avg_rating}</span>
                              {isRatings &&
                                <ReactStars
                                  edit={false}
                                  isHalf={true}
                                  value={parseFloat(marketplaceProduct?.avg_rating)}
                                  activeColor="#eb8a2f"
                                  color="rgb(74 72 72)"
                                  count="5"
                                />

                              }
                            </div>
                            {/* </p> */}
                            <p className='text-right'>{marketplaceProduct.total_reviews} {(marketplaceProduct.total_reviews <= 1) ? lang?.review : lang?.reviews}</p>
                          </div>
                          <div className="review rightArea mt-2 items-center">
                            <p className="flex items-center mt-2">
                              <span className='rateCount'>
                                5 &nbsp;<i className="fa fa-star" aria-hidden="true" /> &nbsp;
                              </span>
                              <span className='progressBar'>
                                <div className="prgressBarBg" style={{ width: calculateRatingPercentage(marketplaceProduct.total_five_star, marketplaceProduct.total_reviews) + "%" }}></div>
                              </span>
                              <span className='progressCount'>{calculateRatingPercentage(marketplaceProduct.total_five_star, marketplaceProduct.total_reviews) + "%"}</span>
                            </p>
                            <p className="flex items-center mt-2">
                              <span className='rateCount'>
                                4 &nbsp;<i className="fa fa-star" aria-hidden="true" />
                              </span>
                              <span className='progressBar'>
                                <div className="prgressBarBg" style={{ width: calculateRatingPercentage(marketplaceProduct.total_four_star, marketplaceProduct.total_reviews) + "%" }}></div>
                              </span>
                              <span className='progressCount'>{calculateRatingPercentage(marketplaceProduct.total_four_star, marketplaceProduct.total_reviews) + "%"}</span>
                            </p>
                            <p className="flex items-center mt-2">
                              <span className='rateCount'>
                                3 &nbsp;<i className="fa fa-star" aria-hidden="true" />
                              </span>
                              <span className='progressBar'>
                                <div className="prgressBarBg" style={{ width: calculateRatingPercentage(marketplaceProduct.total_three_star, marketplaceProduct.total_reviews) + "%" }}></div>
                              </span>
                              <span className='progressCount'>{calculateRatingPercentage(marketplaceProduct.total_three_star, marketplaceProduct.total_reviews) + "%"}</span>
                            </p>
                            <p className="flex items-center mt-2">
                              <span className='rateCount'>
                                2 &nbsp;<i className="fa fa-star" aria-hidden="true" />
                              </span>
                              <span className='progressBar'>
                                <div className="prgressBarBg" style={{ width: calculateRatingPercentage(marketplaceProduct.total_two_star, marketplaceProduct.total_reviews) + "%" }}></div>
                              </span>
                              <span className='progressCount'>{calculateRatingPercentage(marketplaceProduct.total_two_star, marketplaceProduct.total_reviews) + "%"}</span>
                            </p>
                            <p className="flex items-center mt-2">
                              <span className='rateCount'>
                                1 &nbsp;<i className="fa fa-star" aria-hidden="true" />
                              </span>
                              <span className='progressBar'>
                                <div className="prgressBarBg" style={{ width: calculateRatingPercentage(marketplaceProduct.total_one_star, marketplaceProduct.total_reviews) + "%" }}></div>
                              </span>
                              <span className='progressCount'>{calculateRatingPercentage(marketplaceProduct.total_one_star, marketplaceProduct.total_reviews) + "%"}</span>
                            </p>
                          </div>
                          {marketplaceProduct?.latest_review?.rating && <div className="review mt-2 items-center">
                            <p>
                              <span className="flex font-20 justify-start">{marketplaceProduct?.latest_review?.user?.review_name}</span>
                              {isRatings &&
                                <ReactStars
                                  edit={false}
                                  isHalf={true}
                                  value={parseFloat(marketplaceProduct?.latest_review?.rating)}
                                  activeColor="#eb8a2f"
                                  color="rgb(74 72 72)"
                                  count="5"
                                />
                              }

                            </p>
                            <p>{moment(marketplaceProduct?.latest_review?.created_at)?.format("MMM D, YYYY")}</p>
                          </div>}
                          <p>
                            {marketplaceProduct?.latest_review?.review}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* right block  end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Incoterm Poppup */}
      {incoTerm &&
        <IncoTermModal lang={lang} incoTerm={incoTerm} setIncoTerm={setIncoTerm}/>
      }

      {/* user photos view */}
      {
        isOpenImage &&
        <div className='image--light--pop'>
          <ReactImageVideoLightbox
            data={userPhotosData}
            startIndex={imageIndex}
            showResourceCount={true}
            onCloseCallback={() => {
              setIsOpenImage(false);
              setUserPhotosData([]);
              setImageIndex(0);
            }}
          />
        </div>
      }
    </Fragment >
  );
}