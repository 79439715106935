/** @format */

import React, { useEffect, useState } from "react";
import { Link,useHistory,useLocation } from "react-router-dom";
import { getMyDisputes } from "../modules/marketplace";
import PaginatedComponentView from "../../../components/PaginatedComponent/PaginatedComponentView";
import moment from "moment";
import firebase from "../../../config//firebase";
import language from "../../../helpers/language";
export default function Dispute() {

  const [lang] = useState(language.getLang());
  const [disputes, setDisputes] = useState([]);
  const [disputeId, setDisputeId] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [newNotification, setNewNotification] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("USER_TYPE")) ?? null;
  const userId = currentUser?.user_id;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history=useHistory()
  const id = searchParams.get('id');


  // useEffect(()=>{

  // })


  useEffect(() => {
    myDisputeListing(1, disputeId, filterStatus);
  }, [filterStatus, id]);
  // get all my offers listing
  const myDisputeListing = async (pageNum, requestId, searchStatus) => {
    const myDisputesRes = await getMyDisputes(pageNum, requestId, searchStatus);
    console.log(myDisputesRes,"dispute")
    if (myDisputesRes?.success === 200) {
      // let data=myDisputesRes?.disputes?.data.find((dispute)=>dispute.id==id)
      // if(data?.producer_view==1){
      //   history.push(`/marketplace/view-dispute/${id}`)
      //  localStorage.setItem("tab", "dispute");
      // }
      // if(myDisputesRes?.disputes?.data)
    
      await setDisputes(myDisputesRes?.disputes);
      // localStorage.setItem("tab", "dispute");
    } else {
      await setDisputes("");
    }
  };

  // paginated  order page
  const handleRecentOrderPageClick = async (e) => {
    const selectedPage = e.selected + 1;
    await myDisputeListing(selectedPage, disputeId, filterStatus);
  };

  const searchByRequestId = async () => {
    await myDisputeListing(1, disputeId, filterStatus);
  };

  const resetSearch = async() => {
    await setDisputeId('');
    await myDisputeListing(1, '', filterStatus);
  }
  return (
    <>
      <div className="servics_blocks middle-long__section-block middleBlockBg disputes">
        {/* <div className="product-title">
          <h4>Disputes</h4>
        </div> */}
        <div className="productFilter mb--30">
          <div className="filterDataLeft flexBox gap-4">
            <div className="searchBar">
              <input
                type="text"
                className="search form--group"
                name="customer"
                placeholder={lang?.RequestPlaceholder}
                value={disputeId}
                onChange={(event) => {
                  setDisputeId(event.target.value);
                }}
              />
              {/* <span className="searchIcon">
              <i className="fa fa-search" aria-hidden="true"></i>
            </span> */}
            </div>
            <div className="btn btn--gray">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  searchByRequestId();
                }}
              >
                {lang?.Search}
              </button>
            </div>
            <div className="btn btn--gray">
              <button type="button" className="btn" onClick={() => resetSearch()}>Reset</button>
            </div>
          </div>
          <div className="filterDataRight">
            <div className="flexBox form__item items-center gap-2 mb-0">
              <label className="mb-0">{lang?.Status}</label>
              <div className="select-item">
                <select
                  onChange={(event) => {
                    setFilterStatus(event.target.value);
                  }}
                >
                  <option value="">{lang?.allStatus}</option>
                  <option value="pending">{lang?.sPending}</option>
                  <option value="resolved">{lang?.sResolved}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* <h1>My Offers</h1> */}
        <div className="product-list">
          <div className="recentOrder secBlockGap">
            <div className="itemTable itemTableScroll customer__table">
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{lang?.subj}</th>
                    <th>{lang?.Order_id}</th>
                    <th>{lang?.Importer_name}</th>
                    <th>{lang?.orderPurchaseDate}</th>
                    <th>{lang?.Createdat}</th>
                    <th>{lang?.LastActivity}</th>
                    <th>{lang?.Status}</th>
                    <th>{lang?.Action}</th>
                  </tr>
                </thead>
                <tbody>
                  {disputes?.data?.length > 0 &&
                    disputes?.data?.map(
                      (
                        {
                          id,
                          title,
                          subject,
                          status,
                          order_id,
                          added_time,
                          updated_time,
                          order,
                          buyer,
                          newly
                        },
                        i
                      ) => {
                        return (
                          <tr>
                            <td>{id}</td>
                            <td>
                              <div className="newSubject">
                                <span>{subject}</span>{" "}
                                {newly ? (
                                  <span className="New">New</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                            <td>{order_id}</td>
                            <td>{buyer?.company_name}</td>
                            <td>
                              {moment(
                                new Date(order?.created_at).toLocaleString()
                              ).format("DD-MM-YYYY")}
                            </td>
                            <td>{added_time}</td>
                            <td>{updated_time}</td>
                            <td>
                              <span
                                className={`orderStatus ${
                                  status === "resolved" ? "resolved" : ""
                                }`}
                              >
                                {status}
                              </span>
                            </td>
                            <td>
                              <div className="iconGroup">
                                <span className="">
                                  <Link
                                    to={`/marketplace/view-dispute/${id}`}
                                    title="View"
                                  >
                                    <svg
                                      width="40"
                                      height="40"
                                      viewBox="0 0 40 40"
                                      fill="none"
                                    >
                                      <rect
                                        width="40.0001"
                                        height="40"
                                        rx="6.48599"
                                        fill="#004577"
                                      />
                                      <path
                                        d="M19.9937 13C14.8056 13 10.2343 16.732 9.0216 21.6224C8.97625 21.8057 9.00293 22.0002 9.09577 22.1631C9.1886 22.326 9.33999 22.444 9.51663 22.491C9.69327 22.5381 9.88068 22.5104 10.0376 22.4141C10.1946 22.3177 10.3083 22.1606 10.3536 21.9773C11.3986 17.7634 15.4469 14.427 19.9937 14.427C24.5405 14.427 28.6017 17.7645 29.6464 21.9773C29.6917 22.1606 29.8054 22.3177 29.9624 22.4141C30.1193 22.5104 30.3067 22.5381 30.4834 22.491C30.66 22.444 30.8114 22.326 30.9042 22.1631C30.9971 22.0002 31.0237 21.8057 30.9784 21.6224C29.7654 16.7309 25.1818 13 19.9937 13ZM20.0009 16.8053C17.5626 16.8053 15.5716 18.8716 15.5716 21.4022C15.5716 23.9328 17.5626 26 20.0009 26C22.4392 26 24.4311 23.9328 24.4311 21.4022C24.4311 18.8716 22.4392 16.8053 20.0009 16.8053ZM20.0009 18.2323C21.6961 18.2323 23.0561 19.6428 23.0561 21.4022C23.0561 23.1616 21.6961 24.573 20.0009 24.573C18.3057 24.573 16.9466 23.1616 16.9466 21.4022C16.9466 19.6428 18.3057 18.2323 20.0009 18.2323Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </Link>
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
              <PaginatedComponentView
                lastPage={disputes?.last_page}
                handlePageClick={handleRecentOrderPageClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
