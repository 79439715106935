import { Fragment, useState } from "react";
import React from "react"
import REACT_APP_API from "../../config/environment"
import language from "../../helpers/language";

export function Footer(params) {
  const [lang] = useState(language.getLang());
  return (
    <Fragment>
      <div className="site__footer">
        <div className="site__width">
          <div className="site-align">
          
            <div className="footer-social--nav page forDesktop">
              <ul className="topFooterList justify-between">
                <li>
                  {lang?.Copyright}
                </li>
                <li>
                  <a href="https://alysei.com/contacts/" target="_blank">{lang?.Contact_us}</a>
                </li>
                <li>
                  <a href={`${REACT_APP_API.ENV.REACT_APP}/privacy-policy`} target="_blank">{lang?.Privacy_Policy}</a>
                </li>
                <li>
                  <a href={`${REACT_APP_API.ENV.REACT_APP}/terms`} target="_blank">{lang?.Terms_of_Use}</a>
                </li>
                <li>
                  <a href="https://www.facebook.com/AlyseiB2B/" target="_blank">
                    <i className="fab fa-facebook-square" /> {lang?.Facebook}
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/alyseilaunch2020/" target="_blank">
                    <i className="fab fa-instagram" /> {lang?.Instagram}
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCLS2XGoIFJcqhBCxm9K7OEg" target="_blank">
                    <i className="fab fa-youtube-square" /> {lang?.Youtube}
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/alysei/" target="_blank">
                    <i className="fab fa-linkedin" /> {lang?.Linkedin}
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-social--nav page forMob">
              <ul className="topFooterList">
                <li>
                 {lang?.Copyright}
                </li>
                <li>
                  <a href="https://alysei.com/contacts/" target="_blank">{lang?.Contact_us}</a>
                </li>
                <li>
                  <a href={`${REACT_APP_API.ENV.REACT_APP}/privacy-policy`} target="_blank">{lang?.Privacy_Policy}</a>
                </li>
                <li>
                  <a href={`${REACT_APP_API.ENV.REACT_APP}/terms`} target="_blank">{lang?.Terms_of_Use}</a>
                </li>
              </ul>
              <ul className="socialFooter">
                <li>
                  <a href="https://www.facebook.com/AlyseiB2B/" target="_blank">
                    <i className="fab fa-facebook-square" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/alyseilaunch2020/" target="_blank">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCLS2XGoIFJcqhBCxm9K7OEg" target="_blank">
                    <i className="fab fa-youtube-square" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/alysei/" target="_blank">
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Footer