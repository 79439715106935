import React from "react";
import { renderRoutes } from "react-router-config";
import { useLocation } from "react-router-dom";
import Header from "../LandingPage/component/Header";
import Footer from "../LandingPage/component/Footer";

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function LandingLayout({ children, route }) {
  const { pathname } = useLocation();

  // Define the paths where the header and footer should be visible
  const pathsWithHeaderFooter = [
    '/',
    '/about',
    '/faq',
    '/signIn',
    '/login',
    '/signup',
    '/register/:role',
    '/login',
    '/unsubscribe'
  ];

  // Check if the current pathname matches any of the allowed paths
  const showHeaderFooter = pathsWithHeaderFooter.some((path) => {
    // pathname.match(new RegExp(`^${path.replace(/:\w+/g, '\\w+')}$`)) 
    const regexPath = new RegExp('^' + path.replace(/:\w+/g, '[\\w-]+') + '$');
    return regexPath.test(pathname);
  }
  );

  return (
    <>
      <ScrollToTop />
      {showHeaderFooter && <Header />}
      {children}
      {renderRoutes(route.routes)}
      {showHeaderFooter && <Footer />}
    </>
  );
}

export default LandingLayout;
