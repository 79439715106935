import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import language from '../../helpers/language';
import '../../assets/marketplacecss/mrktplace-style.css'
import BuyerHeader from '../../routes/Buyer/components/DashboardView/BuyerHeader';
import SideBar from '../../routes/Buyer/components/SideBar';
import Modal from 'react-modal';

export default function BuyerThankYou() {
  const [lang] = useState(language.getLang());
  const[thankYou, setThankYou] = useState(true);
  return (
    <>
      <section className='buyerDashboard'>
        <div className='dashboardLayout'>
          <div className='headerBlock'>
            <BuyerHeader
              pageTitle=""
            />
          </div>
          <div className='sideBarBlock'>
            <SideBar />
          </div>
         
          <Modal
                    className="buyerModal"
                    ariaHideApp={false}
                    isOpen={thankYou}
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.75)',
                        },
                        content: {
                            position: 'absolute',
                            top: '0',
                            left: '0%',
                            right: '0%',
                            bottom: '200px',
                            border: '1px solid #ccc',
                            background: '#fff',
                            overflow: 'auto',
                            WebkitOverflowScrolling: 'touch',
                            borderRadius: '4px',
                            padding: '0',
                        },
                    }}
                >
                    <div className="popup_header flex justify-between items-center">
                        <h4 className="page__title">Send a Request</h4>
                        <Link className="close_btn--modal" to="/buyer-dashboard">{lang?.close}</Link>
                    </div>
                    <div className='modal-body'>
                        <div className="card card--block modal_box modalLessGap">
                            <div className="marketplace submitation">
                              <div className="main__content">
                                <div className="section__wrapper">
                                  <div className="page-padding">
                                    <div className="width--submitation">
                                      <div className="icon">
                                        <i className="fa fa-smile-o" aria-hidden="true" />
                                      </div>
                                      <h3>Thank you for submitting your information for admin review</h3>
                                      <p className="mt-5 mb-5">
                                        {lang?.will_resp}
                                      </p>
                                    
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </Modal>
        </div>
      </section>
    </>
  )
}
