import React, { Fragment, useEffect, useRef } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import Cropper from "react-cropper";
import Modal from "react-modal";
import { Multiselect } from 'multiselect-react-dropdown';
import { getRegion } from '../../register/modules/register';
import FormError from '../../../components/Errors/FormError';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import language from '../../../helpers/language';

import { fetchTripsCountry, fetchAllIntensity, } from '../../home/modules/HomeModule';
import { setFormError, createTrips, fetchTripsById, updateTrips, fetchAdventurType } from '../modules/DiscoverModule';
import CustomUseEffect from '../../../components/constants';

export default function CreateTrip() {
    const myRef = useRef(null);
    const [travelAgency, setTravelAgency] = useState('');
    const [website, setWebsite] = useState('');
    const [country, setCountry] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [imageView, setImageView] = useState('');
    const [regionTo, setRegionTo] = useState([]);
    const [duration, setDuration] = useState('');
    const [intensity, setIntensity] = useState('');
    const [description, setDescription] = useState('');
    const [adventureType, setAdventureType] = useState('');
    const [currency, setCurrency] = useState('');
    const [price, setPrice] = useState('');
    const [packegeorTripName, setTripOrPackageName] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [lang] = useState(language.getLang());
    const [days, setDays] = useState([]);

    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams(); 

    const [isCropped, setIsCropped] = useState(false);
    const [openModal, setOpenModal] = useState('');
    const [image, setImage] = useState('');
    const [fileName, setFileName] = useState('');
    const [cropData, setCropData] = useState("");
    const cropper = React.createRef(null);

    const { regions } = useSelector((state) => state.register);
    const { tripsCountryData, intensityData } = useSelector((state) => state.activityFeedStatus);
    const { profileData: { data } } = useSelector((state) => state.memberProfileData);
    const { errMessage, awardSpinner, tripDataById, adventureTypes } = useSelector(state => state.discoverReducerState);

    CustomUseEffect(async () =>{
        let daysArray = [];
        for(let i = 1; i<=31; i++){
            daysArray.push(i);
        }
        await setDays(daysArray)
    },[])
    
    CustomUseEffect(async () => {
        window.scrollTo(0, 0)
        await dispatch(fetchTripsCountry('trips'));
        adventureTypes?.length <= 0 && await dispatch(fetchAdventurType());
        await dispatch(fetchAllIntensity());
    }, []);

    CustomUseEffect(async () => {
        id !== undefined && await dispatch(fetchTripsById(id));
    }, [id]);

    CustomUseEffect(async () => {
        await setTravelAgency(data?.user_data?.company_name);
        await setWebsite(data?.contact_tab?.website);
    }, [data]);

    CustomUseEffect(async () => {
        if (Object.keys(tripDataById)?.length > 0) {
            tripDataById?.country?.id && await dispatch(getRegion(tripDataById?.country?.id))
            await setTripOrPackageName(tripDataById?.trip_name);
            await setCountry(tripDataById?.country?.id);
            await setPrice(tripDataById?.price);
            await setDescription(tripDataById?.description);
            await setCurrency(tripDataById?.currency);
            await setAdventureType(tripDataById?.adventure_type);
            await setRegionTo(tripDataById?.region?.map(({id})=>id));
            await setDuration(tripDataById?.duration);
            await setIntensity(tripDataById?.intensity?.intensity_id);
            await setImageView(`${tripDataById?.attachment?.base_url}${tripDataById?.attachment?.attachment_url}`);
        }
    }, [tripDataById]);


    const scrollToRef = (ref) => {
        window.scroll(0, ref.current && ref.current.offsetTop - 200);
    };

    const handleImage = async (e) => {
        let file = e.target.files[0];
        if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
            dispatch(setFormError(lang?.Imagetype, "eventimage"));
            scrollToRef(myRef);
        } else {
            // await setImageFile(file);
            // const img = URL.createObjectURL(file)
            // await setImageView(img);
            await setOpenModal(true)
            await setFileName(e.target.files[0].name);
            await setImage(URL.createObjectURL(e.target.files[0]));
            return e.target.value = null
        }
    }

    const CreateTrips = async () => {
        if (packegeorTripName === '') {
            await dispatch(setFormError(`${lang?.Please_Enter} ${lang?.Trip} ${lang?.name}`, "packegeorTripName"));
            await (myRef && myRef.content);
            scrollToRef(myRef);
        } else if (country === '' || country === "-1") {
            await dispatch(setFormError(`${lang?.Please_Select} ${lang?.only_country}`, "country"));

        }
        else if (imageView === '' && imageFile === '') {
            await dispatch(setFormError(`${lang?.Please_Select} ${lang?.Image}`, "imageView"));
        }
        else if (regionTo?.length === 0) {
            await dispatch(setFormError(`${lang?.SelectRegion}`, "region"));
        }
        else if (adventureType === '' || adventureType === "-1") {
            await dispatch(setFormError(`${lang?.Please_Select} ${lang?.Adventure_Type}`, "adventureType"));
        }

        else if (duration === '' || duration === "-1") {
            await dispatch(setFormError(`${lang?.Please_Select} ${lang?.Duration}`, "duration"));
        }
        else if (intensity === '' || intensity === '-1') {
            await dispatch(setFormError(`${lang?.Please_Select} ${lang?.intensity}`, "intensity"));
        }
        else if (currency == -1 || currency === '') {
            await dispatch(setFormError(`${lang?.Please_Select} ${lang?.Currency_type}`, "currency"));
            await (myRef && myRef.content);
            scrollToRef(myRef);
        }
        else if (price === '' || price < 1) {
            await dispatch(setFormError(`${lang?.Please_Enter} ${lang?.price}`, "price"));
            await (myRef && myRef.content);
            scrollToRef(myRef);
        }
        else if (description === '' || description.length > 300) {
            await dispatch(setFormError(`${lang?.Please_Enter} ${lang?.Description}`, "description"));
            await (myRef && myRef.content);
            scrollToRef(myRef);
        }
        else {
            await setIsSuccess(true);
            const trip_data = {
                trip_name: packegeorTripName,
                travel_agency: travelAgency,
                image_id: imageFile,
                adventure_type: adventureType,
                region :regionTo,
                duration,
                intensity,
                website,
                price,
                currency,
                description,
                country,
            }
            if (id !== undefined) {
                trip_data.trip_id = id;
                const response = await dispatch(updateTrips(trip_data));
                if (response.success === 200) {
                    setIsSuccess(false);
                    history.push("/profile");
                }
                else {
                    setIsSuccess(false);
                }
            } else {
                const response = await dispatch(createTrips(trip_data));
                if (response.success === 200) {
                    setIsSuccess(false);
                    history.push("/profile");
                }
                else {
                    setIsSuccess(false);
                }
            }

        }
        setTimeout(() => {
            dispatch(setFormError('', ''));
        }, 3000)
    }

    const handleCountryChange = async (e) => {
        let country = await e.target.value;
        await setCountry(country);
        country != -1 && await dispatch(getRegion(country))
    }

    // Start Cropping your Image
    const getCropData = async () => {
        await setIsCropped(true);
        if (typeof cropper !== "undefined") {
            setCropData(cropper.current.cropper.getCroppedCanvas().toDataURL());
            await setImageView(cropper.current.cropper.getCroppedCanvas().toDataURL());
            await fetch(cropper.current.cropper.getCroppedCanvas().toDataURL())
                .then(res => res.blob()
                    .then(async (blob) => {
                        const file = await new File([blob], `${fileName}`, { type: "image/png" });
                        await setImageFile(file);
                    })
                );
            await setIsCropped(false);
            await setOpenModal(false);
        }
    };
    const closeModal = async () => {
        await setCropData('');
        await setImage('');
        await setCropData('');
        await setFileName('');
        await setOpenModal(false);
    }

    //select region 
  const onSelect = (list,selectedItem) => {
    const { id } = selectedItem;
    setRegionTo([...regionTo, id]);
  };

  //remove region 
  const onRemove = (list,selectedItem) => {
    const { id } = selectedItem;
    setRegionTo(regionTo.filter((itemId) => itemId !== id));
  };

    return (
        <Fragment>
            <div className="discover_page forCreate">
                <div className="page_top_form">
                    <div className="flex justify-between items-center">
                        <div className='leftArrowBlock'>
                            <Link to="#" onClick={() => history.push('/profile')}><i className="fa fa-arrow-left pr-2" aria-hidden="true"></i> </Link>
                            {id !== undefined ? `${lang?.Edit}` : `${lang?.Create}`} {lang?.Trip}
                        </div>
                        <div className="editProfileBtn">
                            {/* {id !== undefined && <Link to="#" className='btn btnPrimary' onClick={() => UpdateTrips()}>Update</Link>} */}
                            <button className='btn btnPrimary' onClick={() => CreateTrips()}>{isSuccess ? <FormSpinner /> : id === undefined ? `${lang?.Save}` : `${lang?.Update}`}</button>
                        </div>
                    </div>
                </div>
                {awardSpinner ? <FormSpinner /> : <div className="discover__events create">
                    <form>
                        <div className='createForm mt-4'>
                            <div className='md:w-2/5 sm:w-full xl:mb-0 lg:mb-0 md:mb-0 sm:mb-4'>
                                <div className='colLeftBlock'>
                                    <div className="fileUpload">
                                        <label className="hide-input">
                                            <span>
                                                <i className="fa fa-camera" aria-hidden="true"></i> &nbsp; {lang?.Trip} {lang?.Image}
                                            </span>
                                            <input type="file" id="gallery_images" accept="image/png,image/jpeg" onChange={(e) => handleImage(e)} />
                                            {(imageView !== '' || id !== undefined) && <img src={imageView !== '' ? imageView : `${tripDataById?.attachment?.base_url}${tripDataById?.attachment?.attachment_url}`} alt="image_url" />}
                                        </label>
                                    </div>

                                </div>
                                {errMessage.field == "imageView" && <div ref={myRef}> <FormError msg={errMessage.msg} /></div>}
                            </div>
                            <div className='md:w-3/5 sm:w-full'>
                                <div className='colRightBlock'>
                                    <div className="form__item">
                                        <label>{lang?.Trip_Name_Package_Name} *</label>
                                        <input type="text" placeholder='' value={packegeorTripName} onChange={(e) => setTripOrPackageName(e.target.value)} />
                                    </div>
                                    {errMessage.field == "packegeorTripName" && <div ref={myRef}> <FormError msg={errMessage.msg} /></div>}
                                    <div className="form__item">
                                        <label>{lang?.Travel_Agency}</label>
                                        <input type="text" placeholder='' value={travelAgency} readOnly />
                                    </div>
                                    <div className="form__item">
                                        <label>{lang?.Country} *</label>
                                        <select id="country" onChange={(event) => handleCountryChange(event)} value={country?.id}>
                                            <option value="-1">{lang?.Select_Country}</option>
                                            {tripsCountryData && tripsCountryData.map((item, index) => {
                                                return <option key={index} value={item.id} selected={item.id === tripDataById?.country?.id ? true : false} >{item.name}</option>
                                            })
                                            }
                                        </select>
                                    </div>
                                    {errMessage.field == "country" && <div ref={myRef}> <FormError msg={errMessage.msg} /></div>}
                                    
                                    <div className="form__item">
                                        <label>{lang?.RegionTo} *</label>
                                        <Multiselect
                                            options={regions}
                                            displayValue="name"
                                            placeholder={`${lang?.Select } ${lang?.Region}`}
                                            onSelect={onSelect}
                                            onRemove={onRemove}
                                            selectedValues={tripDataById?.region ?? []}
                                            // hidePlaceholder={true}
                                            closeOnSelect={false}
                                        />
                                    </div>
                                    {errMessage.field == "region" && <div ref={myRef}> <FormError msg={errMessage.msg} /></div>}
                                    <div className="form__item">
                                        <label>{lang?.Adventure} *</label>
                                        <select onChange={(e) => setAdventureType(e.target.value)} value={adventureType}>
                                            <option value="-1">{lang?.Adventure_Type}</option>
                                            {adventureTypes?.map((item, index) => {
                                                return (
                                                    <option value={item?.adventure_type_id} key={index} selected={item?.adventure_type_id == tripDataById?.adventure?.adventure_type_id ? true : false}>{item?.adventure_type}</option>
                                                )

                                            }
                                            )}
                                        </select>
                                    </div>
                                    {errMessage.field == "adventureType" && <div ref={myRef}> <FormError msg={errMessage.msg} /></div>}
                                    <div className="form__item">
                                        <label>{lang?.Duration} *</label>
                                        <select onChange={(e) => setDuration(e.target.value)} value={duration}>
                                            <option value="-1" >{lang?.Select} {lang?.Duration}</option>
                                            {days.length > 0 && days.map((dy) => {
                                                if(dy === 1){
                                                   return (<option value={`${dy} day`}>{dy} {lang?.day}</option>)     
                                                }
                                                else{
                                                    return (<option value={`${dy} days`}>{dy} {lang?.days}</option>)     
                                                }
                                                
                                            })}
                                        </select>
                                    </div>
                                    {errMessage.field == "duration" && <div ref={myRef}> <FormError msg={errMessage.msg} /></div>}
                                    <div className="form__item">
                                        <label>{lang?.intensity} *</label>
                                        <select onChange={(e) => setIntensity(e.target.value)} value={intensity?.intensity_id}>
                                            <option value={"-1"} >{lang?.Select} {lang?.intensity}</option>
                                            {intensityData && intensityData.map((item, index) => {
                                                return <option key={index} value={item.intensity_id} selected={item.intensity_id === tripDataById?.intensity?.intensity_id ? true : false}>{item.intensity}</option>
                                            })
                                            }
                                        </select>
                                    </div>
                                    {errMessage.field == "intensity" && <div ref={myRef}> <FormError msg={errMessage.msg} /></div>}
                                    <div className="form__item">
                                        <label>{lang?.Website}</label>
                                        <input type="text" placeholder='' value={website} onChange={(e)=>setWebsite(e.target.value)} />
                                    </div>
                                    <div className='flex flex-wrap items-center'>
                                        <div className='md:col-48 sm:w-full mr-4'>
                                            <div className="form__item">
                                                <label>{lang?.Currency_type} *</label>
                                                <select onChange={(e) => setCurrency(e.target.value)} value={currency}>
                                                    <option value="-1">{lang?.currencyType}</option>
                                                    <option value={"Euro"}>{lang?.Euro}</option>
                                                    <option value={"USD"}>{lang?.USD}</option>
                                                </select>
                                            </div>
                                        </div>
                                        {errMessage.field == "currency" && <div ref={myRef}> <FormError msg={errMessage.msg} /></div>}
                                        <div className='md:col-48 sm:w-full'>
                                            <div className="form__item">
                                                <label>{lang?.price} *</label>
                                                <input
                                                    type="text"
                                                    placeholder=''
                                                    min="1"
                                                    value={price}
                                                    onChange={(e) => setPrice(e.target.value)}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    {errMessage.field == "price" && <div ref={myRef}> <FormError msg={errMessage.msg} /></div>}
                                    <div className="form__item">
                                        <label>{lang?.Description} *</label>
                                        <textarea placeholder={lang?.Description} value={description} onChange={(e) => setDescription(e.target.value)} maxLength={300} />
                                        {description?.length > 0 && <p>{`${description.length}/300 Character`}</p>}
                                    </div>
                                    {errMessage.field == "description" && <div ref={myRef}> <FormError msg={errMessage.msg} /></div>}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>}
            </div>
            {/* crop modal  */}
            {openModal && <Modal className=""
                ariaHideApp={false}
                isOpen={openModal}
                style={{
                    overlay: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.75)",
                    },
                    content: {
                        position: "absolute",
                        top: "0",
                        left: "0%",
                        right: "0%",
                        bottom: "200px",
                        border: "1px solid #ccc",
                        background: "#fff",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "4px",
                        padding: "0px",
                        // outline: 'none',
                    },
                }}
            >
                <div className="popup_header flex justify-between items-center">
                    <h4 className="page__title">{lang?.Crop_image}</h4>
                    <button className="close_btn--modal" onClick={() => closeModal()} > {lang?.close}</button>
                </div>
                <div className="card card--block modal_box">
                    <div className="crop_img_block">
                        <Cropper
                        zoomTo={0}
                        style={{ width: '100%' }}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        dragMode={'move'}
                        cropBoxMovable={false}
                        responsive={true}
                        cropBoxResizable={false}
                        ref={cropper}
                        initialAspectRatio={2/2}
                        center={true}
                        guides={false}
                        />
                    </div>
                    <div className="form__action form__item mt-4">
                        <button onClick={() => getCropData()}> {isCropped ? <FormSpinner /> : `${lang?.Crop_image}`} </button>
                    </div>
                </div>
                {/* {cropData !== '' && <img style={{ width: "100%" }} src={cropData} alt="cropped" />} */}
            </Modal>}
            {/* crop modal end */}
        </Fragment>
    )
}
