import React, { Fragment, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import MemberType from '../../../components/MemeberType';
import NotFound from '../../../components/NetworkConnection/NotFound';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import constant from '../../../helpers/constant';
import language from '../../../helpers/language';
import { fetchBlockUser, unblockUserRequest } from '../module/UserSetting';
import SettingLeftNavigation from './SettingLeftNavigation'
import CustomUseEffect from '../../../components/constants';

export default function Blocking() {
    const dispatch = useDispatch();
    const [lang] = useState(language.getLang());
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [isTrue, setIsTrue] = useState(true);
    const { blockUserSpinner, blockUserData } = useSelector((state) => state.userSettingState)

    CustomUseEffect(async () => { 
        //window.fcWidget.show(); 
         await dispatch(fetchBlockUser(page)) }, [])

    const unBlockRequest = async (blockUserId) => {
        await dispatch(unblockUserRequest({ block_user_id: blockUserId }));
    }
    const handlePageClick = async (e) => {
        let pageNo = e.selected + 1
        await setPage(page);
        await dispatch(fetchBlockUser(pageNo));
    };
    return (
        <div>
            {/* Layout Content */}
            <div className="home__page b2b settings blockingPage">
                <div className="servics_blocks">
                    <div className="site-align top-align">
                        <SettingLeftNavigation />
                        <div className="servics_blocks middle-long__section-block middleBlockBg">
                            {!blockUserSpinner && blockUserData && blockUserData.errors ?
                                <Fragment><div className="medium_title"><h1 className="font-18 text-black">{lang?.Blocking}</h1></div>
                                    {/* <NotFound errors={blockUserData.errors.exception} /> */}
                                    <NotFound />
                                    <h4 className='font-18 black mt-4 text-center'>There is no user found in your block list.</h4>
                                </Fragment>
                                : <div className="medium_title"><h1 className="font-18 text-black">{lang?.Blocking}</h1></div>
                            }

                            {blockUserSpinner ? <FormSpinner /> :
                                <div className='card--section site-align top-align flex-wrap'>
                                    {blockUserData?.data?.data?.map((item, index) => {
                                        return (
                                            <div className="card card--block blocking" key={index}>
                                                <div className="hubUser" onClick={() => history.push(`${"/visitor-profile/"}${item && item.block_user_id}`)}>
                                                    <div className="feed-user-info mb-2">
                                                        <div className="user__pic">
                                                            {item && item.blockuser && item.blockuser.avatar_id ?
                                                                <img src={`${item.blockuser.avatar_id.base_url}${item.blockuser.avatar_id.attachment_url}`} alt="User Image" /> :
                                                                <img src="https://alysei.s3.us-west-1.amazonaws.com/uploads/2021/12/6971696IMG_1640327050627.jpg" alt="User Image" />
                                                            }
                                                        </div>
                                                        <div className="user__detail-data blocking">
                                                            <span className="user__name">
                                                                <Link to="#">
                                                                    {(item && item.blockuser && item.blockuser.first_name && item && item.blockuser && item.blockuser.last_name &&
                                                                        `${item && item.blockuser && item.blockuser.first_name} ${item && item.blockuser && item.blockuser.last_name}`) || 
                                                                        (item && item.blockuser && item.blockuser.company_name) || (item && item.blockuser && item.blockuser.restaurant_name)}
                                                                </Link>
                                                                <MemberType item={item?.blockuser} />
                                                                {/* <div className="flex"> */}
                                                                {/* {item && item.user.role_id === constant.rolesId.producer && (<span>Italian F&B Producers </span>)}
                                                                    {item && item.user.role_id === constant.rolesId.importer && (<span>Importer </span>)}
                                                                    {item && item.user.role_id === constant.rolesId.distributer && (<span>Distributor </span>)}
                                                                    {item && item.user.role_id == constant.rolesId["importer&distributer"] && <span>Importer & Distributor </span>}
                                                                    {item && item.user.role_id === constant.rolesId.voiceOfExpert && (<span>Voice Of Experts </span>)}
                                                                    {item && item.user.role_id === constant.rolesId.travelAgencies && (<span>Travel Agencies </span>)}
                                                                    {item && item.user.role_id === constant.rolesId.restaurant && (<span>Italian Restaurants in US </span>)}
                                                                    {item && item.user.role_id === constant.rolesId.voyagers && (<span>Voyagers ,</span>)} */}
                                                                {/* <span className="text-blue"> {item.user.follower_count} Followers </span> */}
                                                            </span>
                                                        </div>
                                                        <div className='unLock' onClick={() => unBlockRequest(item.block_user_id)}> <Link to="#">{lang?.Click_to_Unblock}</Link> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }

                                </div>
                            }
                            {blockUserData && blockUserData?.data && blockUserData?.data?.data.length > 0 &&
                                    <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={Math.ceil((blockUserData?.data?.total) / (blockUserData?.data?.per_page))}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    nextPageRel={'1'} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
