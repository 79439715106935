import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import Map from '../../../components/Map/DiscoverRestaurentMap';
import { fetchAllHubs, fetchTitle } from '../../B2B/modules/B2B';
import { getRegion } from '../../register/modules/register';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import ReactPaginate from 'react-paginate';
import { fetchAdventurType } from '../modules/DiscoverModule';
import language from '../../../helpers/language';
import { Multiselect } from 'multiselect-react-dropdown';

import {
  fetchAllDiscoverAlysei,
  fetchAllIntensity,
  fetchAllRestaurantType,
  fetchAllSpecialization,
  fetchTripsCountry,
  filterDiscoverBlogs,
  filterDiscoverEvents,
  filterDiscoverRestaurent,
  filterDiscoverTrips,
  setUpdateViewBubbles
} from '../../home/modules/HomeModule';
import CustomUseEffect from '../../../components/constants';

export default function Discover(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const restaurantFilterType = useRef(null);
  const eventRegistrationType = useRef(null);
  const eventFilterType = useRef(null);
  const SpecializationFilter = useRef(null);
  const onlyTitleFilter = useRef(null);
  const hubFilter = useRef(null);
  const countryFilter = useRef(null);
  const RegionFilter = useRef(null);
  const durationFilter = useRef(null);
  const intensityFilter = useRef(null);
  const AdventureFilter = useRef(null);
  const [date, setDate] = useState('');
  const [hubs, setHubs] = useState('');
  const [price, setPrice] = useState('');
  const [title, setTitle] = useState('');

  const [region, setRegion] = useState([]);
  const [country, setCountry] = useState('');
  const [duration, setDuration] = useState('');
  const [intensity, setIntensity] = useState('');
  const [eventType, setEventType] = useState('');

  const [restList, setRestList] = useState(false);
  const [formToggle, setFormToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [isTrue, setIsTrue] = useState(false);
  const [registration, setRegistration] = useState('');
  const [adventureType, setAdventureType] = useState('');
  const [specialization, setSpecialization] = useState('');
  const [restaurantType, setRestaurantType] = useState('');
  const [mapPosition, setMapPosition] = useState('');
  const [lang] = useState(language.getLang());
  const { regions } = useSelector((state) => state.register);
  const { getAllHubs, getAllTitle } = useSelector((state) => state.b2bState);
  const [isPageName, setIsPageName] = useState('');
  const [restaurentTypeArray, setRestaurantTypeData] = useState([]);
  const [specializationDataArray, setSpecializationDataArray] = useState([]);
  const [getAllTitleArray, setGetAllTitleArray] = useState([]);
  const [getAllHubsArray, setGetAllHubsArray] = useState([]);
  const [tripsCountryDataArray, setTripsCountryDataArray] = useState([]);
  const [regionsArray, setRegionsArray] = useState([]);
  const [intensityDataArray, setIntensityDataArray] = useState([]);
  const [adventureTypesArray, setAdventureTypesArray] = useState([]);
  const [durations, setDurations] = useState([]);

  const {
    discoverAlyseiData,
    reataurentTypeData,
    intensityData,
    tripsCountryData,
    discoverSpinner,
    discoverFilterSpinner,
    specializationData,
    discover_filters,
    discover_alysei
  } = useSelector((state) => state.activityFeedStatus);
  const { adventureTypes } = useSelector((state) => state.discoverReducerState);

  CustomUseEffect(async () => {
    if (params.name&& discover_alysei?.some((item) => item?.new_update && !item?.category)) {
        await setUpdateViewBubbles(params.name);
    }
  }, []);
  
  CustomUseEffect(async () => {
    if (params.name === 'trips') {
      await setIsPageName(lang?.Trips);
    } else if (params.name === 'restaurants') {
      await setIsPageName(lang?.Restaurants);
    } else if (params.name === 'blogs') {
      await setIsPageName(lang?.Blogs);
    } else if (params.name === 'events') {
      await setIsPageName(lang?.Events);
    }
    if (discover_filters != '') {
      if (params.name === 'trips') {
        await setPrice(discover_filters.price);
        await setRegion(discover_filters.region);
        await setDuration(discover_filters.duration);
        await setIntensity(discover_filters.intensity);
        await setAdventureType(discover_filters.adventure_type);
        await setCountry(discover_filters.country);

        const model = {
          type: params.name,
          price: discover_filters.price,
          region: discover_filters.region,
          duration: discover_filters.duration,
          intensity: discover_filters.intensity,
          adventure_type: discover_filters.adventure_type,
          country: discover_filters.country,
        };
        await dispatch(filterDiscoverTrips(model));
      } else if (params.name === 'restaurants') {
        await setRestaurantType(discover_filters.restaurant_type);
        await setHubs(discover_filters.hubs);
        const model = {
          type: params.name,
          restaurant_type: discover_filters.restaurant_type,
          hubs: discover_filters.hubs,
        };
        await dispatch(filterDiscoverRestaurent(model));
      } else if (params.name === 'blogs') {
        await setTitle(discover_filters.title);
        await setSpecialization(discover_filters.specialization);
        const model = {
          type: params.name,
          title: discover_filters.title,
          specialization: discover_filters.specialization,
        };
        await dispatch(filterDiscoverBlogs(model));
      } else if (params.name === 'events') {
        await setDate(discover_filters.date);
        await setEventType(discover_filters.event_type);
        await setRegistration(discover_filters.registration_type);
        await setRestaurantType(discover_filters.restaurant_type);
        const model = {
          type: params.name,
          date: discover_filters.date,
          event_type: discover_filters.event_type,
          registration_type: discover_filters.registration_type,
          restaurant_type: discover_filters.restaurant_type,
        };
        await dispatch(filterDiscoverEvents(model));
      } else {
        await dispatch(fetchAllDiscoverAlysei(params.name, page));
      }
    } else {
      await dispatch(fetchAllDiscoverAlysei(params.name, page));
    }
    await setIsTrue(true);
  }, []);


  CustomUseEffect(async () => {
    for (let i = 1; i <= 31; i++) {
      if (i === 1) {
        const dayArray = {
          key: i + ' ' + lang?.day,
          value: `${i} day`,
        }
        await setDurations((durations) => [
          ...durations,
          dayArray,
        ]);
      }
      else {
        const dayArray = {
          key: i + ' ' + lang?.days,
          value: `${i} days`,
        }
        await setDurations((durations) => [
          ...durations,
          dayArray,
        ]);
      }

    }
  }, [])

  CustomUseEffect(async () => {
    if (restaurentTypeArray.length === 0) {
      reataurentTypeData && reataurentTypeData.sort(dynamicSort("option")).map(async (item, index) => {
        const restaurant = {
          key: item.option,
          value: item.user_field_option_id,
        }
        await setRestaurantTypeData((restaurentTypeArray) => [...restaurentTypeArray, restaurant]);
      });
    }

    if (specializationDataArray.length === 0) {
      specializationData && specializationData.sort(dynamicSort("option")).map(async (item, index) => {
        const specialization = {
          key: item.option,
          value: item.user_field_option_id,
        }
        await setSpecializationDataArray((specializationDataArray) => [...specializationDataArray, specialization]);
      });
    }

    if (getAllTitleArray.length === 0) {
      getAllTitle && getAllTitle?.options?.sort(dynamicSort("option")).map(async (item, index) => {
        const allTitles = {
          key: item.option,
          value: item.user_field_option_id,
        }
        await setGetAllTitleArray((getAllTitleArray) => [...getAllTitleArray, allTitles]);
      });
    }

    if (getAllHubsArray.length === 0) {
      getAllHubs && getAllHubs.sort(dynamicSort("title")).map(async (item, index) => {
        const allHubs = {
          key: item.title,
          value: item.id,
        }
        await setGetAllHubsArray((getAllHubsArray) => [...getAllHubsArray, allHubs]);
      });
    }

    if (tripsCountryDataArray.length === 0) {
      tripsCountryData && tripsCountryData.sort(dynamicSort("name")).map(async (item, index) => {
        const trips = {
          key: item.name,
          value: item.id,
        }
        await setTripsCountryDataArray((tripsCountryDataArray) => [...tripsCountryDataArray, trips]);
      });
    }

    if (regionsArray.length === 0) {
      regions && regions.sort(dynamicSort("name")).map(async (item, index) => {
        const region = {
          key: item.name,
          value: item.id,
        }
        await setRegionsArray((regionsArray) => [...regionsArray, region]);
      });
    }

    if (intensityDataArray.length === 0) {
      intensityData && intensityData.map(async (item, index) => {
        const intensities = {
          key: item.intensity,
          value: item.intensity_id,
        }
        await setIntensityDataArray((intensityDataArray) => [...intensityDataArray, intensities]);
      });
    }

    if (adventureTypesArray.length === 0) {
      adventureTypes && adventureTypes.sort(dynamicSort("adventure_type")).map(async (item) => {
        const adventure = {
          key: item.adventure_type,
          value: item.adventure_type_id,
        }
        await setAdventureTypesArray((adventureTypesArray) => [...adventureTypesArray, adventure]);
      });
    }
  }, [reataurentTypeData, specializationData, getAllTitle, getAllHubs, tripsCountryData, regions, intensityData, adventureTypes]);

  CustomUseEffect(async () => {
    if (params.name === 'trips') await dispatch(fetchAllIntensity());
  }, []);
  CustomUseEffect(async () => {
    if (params.name === 'restaurants') await dispatch(fetchAllHubs());
  }, []);
  CustomUseEffect(async () => {
    if (params.name === 'blogs') await dispatch(fetchAllSpecialization());
    await dispatch(fetchTitle(12));
  }, []);
  CustomUseEffect(async () => {
    if (params.name === 'trips') await dispatch(fetchTripsCountry('trips'));
    await dispatch(fetchAdventurType('all'));
  }, []);
  CustomUseEffect(async () => {
    if (params.name === 'events' || params.name === 'restaurants')
      await dispatch(fetchAllRestaurantType());
  }, []);

  CustomUseEffect(async () => {
    const result = await discoverAlyseiData?.data?.data[0];
    setMapPosition(result);
  }, [discoverAlyseiData]);

  const handlePageClick = async (e) => {
    let pageNo = e.selected + 1;
    await setPage(page);
    await dispatch(fetchAllDiscoverAlysei(params.name, pageNo));
  };
  const handleCountry = async (event) => {
    setCountry(event[0].value);
    await dispatch(getRegion(event[0].value));
  };

  const handleSearch = async () => {
    if (params.name === 'blogs') {
      const model = {
        type: params.name,
        title: title,
        specialization: specialization,
      };
      await dispatch(filterDiscoverBlogs(model));
    } else if (params.name === 'restaurants') {
      const model = {
        type: params.name,
        restaurant_type: restaurantType,
        hubs: hubs,
      };
      await dispatch(filterDiscoverRestaurent(model));
    } else if (params.name === 'events') {
      const model = {
        type: params.name,
        date: date,
        event_type: eventType,
        registration_type: registration,
        restaurant_type: restaurantType,
      };
      await dispatch(filterDiscoverEvents(model));
    } else if (params.name === 'trips') {
      const model = {
        type: params.name,
        price: price,
        region: region?.toString(),
        duration: duration,
        intensity: intensity,
        adventure_type: adventureType,
        country,
      };
      await dispatch(filterDiscoverTrips(model));
    }
  };

  const clearFilter = async () => {
    await setDate('');
    await setHubs('');
    await setTitle('');
    await setPrice('');
    await setRegion('');
    await setCountry('');
    await setDuration('');
    await setRestList('');
    await setEventType('');
    await setIntensity('');
    await setRegistration('');
    await setRestaurantType('');
    await setSpecialization('');
    await setAdventureType('');
    await setSpecialization('');
    await dispatch(fetchAllDiscoverAlysei(params.name, page));
    if (params.name === "restaurants") {
      restaurantFilterType.current.resetSelectedValues();
      hubFilter.current.resetSelectedValues();
    }
    else if (params.name === "events") {
      eventRegistrationType.current.resetSelectedValues();
      eventFilterType.current.resetSelectedValues();
      restaurantFilterType.current.resetSelectedValues();
    }
    else if (params.name === "trips") {
      countryFilter.current.resetSelectedValues();
      RegionFilter.current.resetSelectedValues();
      durationFilter.current.resetSelectedValues();
      intensityFilter.current.resetSelectedValues();
      AdventureFilter.current.resetSelectedValues();
    }
    else {
      SpecializationFilter.current.resetSelectedValues();
      onlyTitleFilter.current.resetSelectedValues();
    }

    document.getElementById('fda__certificate_input').placeholder =
      lang?.select;

  };

  const dynamicSort = (property) => {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  // select/Remove region 
  const changeConservationMethods = (selectedRegionList) => {
    if (selectedRegionList.length > 1) {
      document.getElementById('region_select_input').placeholder =
        selectedRegionList[0].key +
        ' +' +
        (selectedRegionList.length - 1) +
        ' more';
    } else if (selectedRegionList.length == 1) {
      document.getElementById('region_select_input').placeholder =
        selectedRegionList[0].key;
    } else {
      document.getElementById('region_select_input').placeholder =
        lang?.select;
    }
    let data = selectedRegionList.map((item, i) => {
      return item.value;
    });
    if (data.length > 0) {
      setRegion(data.join());
    } else {
      setRegion('');
    }
  };

  return (
    <div className="discover_page sm:mb-1 createTrip">
      {/* filter */}
      <div className="page_top_form">
        <div className="flex justify-between items-center">
          <div className="leftArrowBlock flex items-center">
            {/* <Link to="/home"> <i className="fa fa-arrow-left pr-2" aria-hidden="true"></i> </Link>
            <h4 className="font-18 text-black">Discover Alysei</h4> */}
            <Link to="/home">
              {" "}
              <i className="fa fa-arrow-left pr-2" aria-hidden="true"></i>
            </Link>
            <h4>
              {lang?.Discovery} {isPageName}
            </h4>
            {/* {params.name === "news" && <h4>{lang?.Alysei} {params.name.charAt(0).toUpperCase() + params.name.slice(1)}</h4>} */}
          </div>
          <div className="colRightBlock rest">
            {
              <div className="click_from">
                <div
                  className="discover_map_list"
                  onClick={() => setRestList(!restList)}
                >
                  {params.name === "restaurants" && (
                    <Link className="btn">
                      {" "}
                      {restList ? "List" : "Map"}
                      {!restList ? (
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      ) : (
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.45172 0.625626L1.68797 2.8675L1.03984 2.29375L0.136719 3.31375L1.32672 4.37625L1.86859 4.85438L2.31484 4.28063L4.52484 1.45438L3.45172 0.625626ZM5.45984 2.06V3.42H14.6398V2.06H5.45984ZM3.45172 5.38563L1.68797 7.6275L1.03984 7.05375L0.136719 8.07375L1.32672 9.13625L1.86859 9.61438L2.31484 9.04063L4.52484 6.21438L3.45172 5.38563ZM5.45984 6.82V8.18H14.6398V6.82H5.45984ZM3.45172 10.1456L1.68797 12.3875L1.03984 11.8138L0.136719 12.8338L1.32672 13.8963L1.86859 14.3744L2.31484 13.8006L4.52484 10.9744L3.45172 10.1456ZM5.45984 11.58V12.94H14.6398V11.58H5.45984Z"
                            fill="white"
                          />
                        </svg>
                      )}
                    </Link>
                  )}
                </div>
              </div>
            }
            {
              <div
                className="click_from"
                onClick={() => setFormToggle(!formToggle)}
              >
                {formToggle ? (
                  <div>
                    {" "}
                    <i className="fa fa-times" aria-hidden="true"></i>{" "}
                  </div>
                ) : (
                  <div className="discover_map_list">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.139 25.3133H0V21.9383H2.1405C2.43612 21.4238 2.8622 20.9964 3.37575 20.6992C3.8893 20.402 4.47215 20.2455 5.0655 20.2455C5.65885 20.2455 6.2417 20.402 6.75525 20.6992C7.2688 20.9964 7.69488 21.4238 7.9905 21.9383H27V25.3133H7.986C7.68977 25.827 7.26347 26.2536 6.75 26.5502C6.23653 26.8468 5.65399 27.003 5.061 27.003C4.46801 27.003 3.88547 26.8468 3.372 26.5502C2.85853 26.2536 2.43223 25.827 2.136 25.3133H2.139ZM10.5765 15.1883H0V11.8133H10.575C10.8712 11.2996 11.2975 10.8729 11.811 10.5763C12.3245 10.2797 12.907 10.1235 13.5 10.1235C14.093 10.1235 14.6755 10.2797 15.189 10.5763C15.7025 10.8729 16.1288 11.2996 16.425 11.8133H27V15.1883H16.422C16.1261 15.7023 15.6999 16.1293 15.1864 16.4263C14.6729 16.7232 14.0902 16.8795 13.497 16.8795C12.9038 16.8795 12.3211 16.7232 11.8076 16.4263C11.2941 16.1293 10.8679 15.7023 10.572 15.1883H10.5765ZM19.014 5.06325H0V1.68825H19.0125C19.3087 1.17455 19.735 0.747931 20.2485 0.451303C20.762 0.154675 21.3445 -0.00149536 21.9375 -0.00149536C22.5305 -0.00149536 23.113 0.154675 23.6265 0.451303C24.14 0.747931 24.5663 1.17455 24.8625 1.68825H27V5.06325H24.8595C24.5636 5.57734 24.1374 6.00434 23.6239 6.30126C23.1104 6.59817 22.5277 6.75451 21.9345 6.75451C21.3413 6.75451 20.7586 6.59817 20.2451 6.30126C19.7316 6.00434 19.3054 5.57734 19.0095 5.06325H19.014Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
        {formToggle && (
          <form className="mt-2">
            <div className="flex justify-between items-center mb-2">
              <h4 className="page__title">{lang?.Filter}</h4>{" "}
              <Link to="#" onClick={() => clearFilter()}>
                {lang?.Reset_Filter}
              </Link>
            </div>
            <div className="gridCol5 items-center banner-form-bg">
              {/* ---------------EVENTS---------------------------- */}
              {params.name === "events" && (
                <div className="formCol">
                  <div className="form__item">
                    <label for="">
                      {lang?.Select} {lang?.Date}
                    </label>
                    <input
                      type="date"
                      id="date"
                      name="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                </div>
              )}
              {params.name === "events" && (
                <div className="formCol">
                  <div className="form__item">
                    <label>
                      {lang?.Event} {lang?.Type}
                    </label>
                    <Multiselect
                      options={[{ key: lang?.Public, value: 'public' }, { key: lang?.Private, value: 'private' }]}
                      displayValue="key"
                      onSelect={(event) => setEventType(event[0].value)}
                      id={'fda__certificate'}
                      placeholder={lang?.select}
                      singleSelect
                      ref={eventFilterType}
                    />
                  </div>
                </div>
              )}

              {params.name === "events" && (
                <div className="formCol">
                  <div className="form__item">
                    <label>{lang?.Event_Registration_Type}</label>
                    <Multiselect
                      options={[{ key: lang?.Free, value: 'free' }, { key: lang?.Paid, value: 'paid' }, { key: lang?.buy_invitation, value: 'By Invitation' }]}
                      displayValue="key"
                      placeholder={lang?.select}
                      onSelect={(event) => setRegistration(event[0].value)}
                      id={'fda__certificate'}
                      singleSelect
                      ref={eventRegistrationType}
                    />
                  </div>
                </div>
              )}
              {(params.name === "events" || params.name === "restaurants") && (
                <div className="formCol">
                  <div className="form__item">
                    <label>
                      {lang?.Restaurant} {lang?.Type}
                    </label>
                    <Multiselect
                      options={restaurentTypeArray}
                      displayValue="key"
                      placeholder={lang?.select}
                      onSelect={(event) => setRestaurantType(event[0].value)}
                      id={'fda__certificate'}
                      singleSelect
                      ref={restaurantFilterType}
                    />
                  </div>
                </div>
              )}

              {/* -----------------restaurent------------- */}
              {params.name === "blogs" && (
                <div className="formCol">
                  <div className="form__item">
                    <label>{lang?.Specialization}</label>
                    <Multiselect
                      options={specializationDataArray}
                      displayValue="key"
                      placeholder={lang?.select}
                      onSelect={(event) => setSpecialization(event[0].value)}
                      id={'fda__certificate'}
                      singleSelect
                      ref={SpecializationFilter}
                    />
                  </div>
                </div>
              )}
              {params.name === "blogs" && (
                <div className="formCol">
                  <div className="form__item">
                    <label>{lang?.only_title}</label>
                    <Multiselect
                      options={getAllTitleArray}
                      displayValue="key"
                      placeholder={lang?.select}
                      onSelect={(event) => setTitle(event[0].value)}
                      id={'fda__certificate'}
                      singleSelect
                      ref={onlyTitleFilter}
                    />

                    {/* <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} /> */}
                  </div>
                </div>
              )}

              {/* -----------------restaurent------------- */}
              {params.name === "restaurants" && (
                <div className="formCol">
                  <div className="form__item">
                    <label>{lang?.Hubs}</label>
                    <Multiselect
                      options={getAllHubsArray}
                      displayValue="key"
                      onSelect={(event) => setHubs(event[0].value)}
                      id={'fda__certificate'}
                      placeholder={lang?.select}
                      singleSelect
                      ref={hubFilter}
                    />
                  </div>
                </div>
              )}
              {/* -----------------trips------------- */}
              {params.name === "trips" && (
                <div className="formCol">
                  <div className="form__item">
                    <label htmlFor="comp">{lang?.Country}</label>
                    <Multiselect
                      options={tripsCountryDataArray}
                      displayValue="key"
                      placeholder={lang?.select}
                      onSelect={(event) => handleCountry(event)}
                      id={'fda__certificate'}
                      singleSelect
                      ref={countryFilter}
                    />

                  </div>
                </div>
              )}
              {params.name === "trips" && (

                <div className="form__item selectRegion">
                  <label>{lang?.RegionTo}</label>
                  <Multiselect
                    options={regionsArray}
                    displayValue="key"
                    placeholder={lang?.select}
                    onSelect={changeConservationMethods}
                    onRemove={changeConservationMethods}
                    showCheckbox={true}
                    closeOnSelect={false}
                    id={'region_select'}
                    ref={RegionFilter}
                  />

                  {/* </div> */}
                </div>
              )}

              {params.name === "trips" && (
                <div className="formCol">
                  <div className="form__item">
                    <label>{lang?.Duration}</label>
                    <Multiselect
                      options={durations}
                      displayValue="key"
                      placeholder={lang?.select}
                      onSelect={(event) => setDuration(event[0].value)}
                      id={'fda__certificate'}
                      singleSelect
                      ref={durationFilter}
                    />

                  </div>
                </div>
              )}
              {params.name === "trips" && (
                <div className="formCol">
                  <div className="form__item">
                    <label>{lang?.intensity}</label>
                    <Multiselect
                      options={intensityDataArray}
                      displayValue="key"
                      placeholder={lang?.select}
                      onSelect={(event) => setIntensity(event[0].value)}
                      id={'fda__certificate'}
                      singleSelect
                      ref={intensityFilter}
                    />

                  </div>
                </div>
              )}
              {params.name === "trips" && (
                <div className="formCol">
                  <div className="form__item">
                    <label>
                      {lang?.Adventure} {lang?.Type}
                    </label>
                    <Multiselect
                      options={adventureTypesArray}
                      displayValue="key"
                      placeholder={lang?.select}
                      onSelect={(event) => setAdventureType(event[0].value)}
                      id={'fda__certificate'}
                      singleSelect
                      ref={AdventureFilter}
                    />

                  </div>
                </div>
              )}
              {params.name === "trips" && (
                <div className="formCol">
                  <div className="form__item">
                    <label>{lang?.price}</label>
                    <input
                      type="text"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                </div>
              )}
              <div className="formCol">
                <div className="btn-blue-bg">
                  {" "}
                  <button
                    type="button"
                    className="btn"
                    onClick={() => handleSearch()}
                  >
                    {" "}
                    {discoverFilterSpinner ? <FormSpinner /> : lang?.Apply}{" "}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>

      {/* {params.name === "news" && <NewsStatus />} */}
      {/* listing */}
      {discoverAlyseiData && discoverAlyseiData.errors && (
        <h4 className="text-center font-18 text-black mt-16">
          {discoverAlyseiData.errors.exception}
        </h4>
      )}
      {discoverSpinner ? (
        <FormSpinner />
      ) : (
        <Fragment>
          {/* <div style={{ margin: "right" }}>  {params.name === "news" && <NewsStatus />} </div> */}
          {/* restaurent map */}
          {params.name === "restaurants" && restList && (
            <div className="locationMap">
              <Map
                google={props.google}
                center={{
                  lat: parseFloat(mapPosition && mapPosition.lattitude),
                  lng: parseFloat(mapPosition && mapPosition.longitude),
                }}
                height="300px"
                zoom={1}
                address={mapPosition && mapPosition.address}
                markerData={discoverAlyseiData?.data?.data}
              />
            </div>
          )}

          {/* restaurent list */}
          {params.name === "restaurants" && !restList && (
            <div className="discover__events">
              <ul className="event__list">
                {discoverAlyseiData?.data?.data?.length > 0 ? (
                  discoverAlyseiData?.data?.data?.map((item, index) => {
                    return (
                      <li className="w-full md:w-1/4" key={index}>
                        <div
                          className="event__card"
                          onClick={() =>
                            history.push(`/user-profile/${item?.user_id}`)
                          }
                        >
                          <div className="event__img">
                            {item.cover_id && params.name === "restaurants" && (
                              <img
                                src={
                                  item.cover_id
                                    ? `${item.cover_id?.base_url}${item.cover_id?.attachment_medium_url}`
                                    : "https://holydelights.com/blog/wp-content/uploads/2016/08/img_3409.jpg"
                                }
                                alt="Event Image"
                              />
                            )}
                          </div>
                          <div className="event__deatil">
                            <div className="feed-user-info">
                              <div className="user__pic">
                                <img
                                  src={
                                    item.avatar_id
                                      ? `${item.avatar_id.base_url}${item.avatar_id.attachment_url}`
                                      : "https://holydelights.com/blog/wp-content/uploads/2016/08/img_3409.jpg"
                                  }
                                  alt="Event Image"
                                />
                              </div>
                              <div className="user__detail-data">
                                <span className="user__name">
                                  {item.restaurant_name}
                                </span>
                                {item.restaurant_type && (
                                  <p className="Host">
                                    <span>
                                      {/* {lang?.Restaurant} {lang?.Type}: */}
                                      {item.restaurant_type}
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <h4 className="text-center font-18 text-black mt-16">
                    {lang?.No_data_found}
                  </h4>
                )}
              </ul>
            </div>
          )}
          {params.name == "restaurants" &&
            isTrue &&
            discoverAlyseiData?.data?.total > 10 && (
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(
                  discoverAlyseiData?.data?.total /
                  discoverAlyseiData?.data?.per_page
                )}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}

          {params.name !== "restaurants" && (
            <div className="discover__events">
              {discoverAlyseiData?.data?.data?.length === 0 && (
                <h4 className="text-center font-18 text-black mt-16">
                  {lang?.No_data_found}
                  {/* No data found */}
                </h4>
              )}
              <ul className="event__list">
                {discoverAlyseiData?.data?.data?.length > 0 &&
                  discoverAlyseiData.data.data.map((item, index) => {
                    return (
                      <li className="w-full md:w-1/4" key={index}>
                        <div
                          className="event__card"
                          onClick={() => {
                            params.name === "blogs" &&
                              history.push({
                                pathname: `${"/view-"}${params.name}/${item.blog_id
                                  }/${item.user && item.user.user_id}`,
                                state: { isState: 2 },
                              });
                            params.name !== "blogs" &&
                              history.push(
                                `${"/view-"}${params.name}/${item.event_id || item.trip_id || item.user_id
                                }`
                              );
                          }}
                        >
                          <div className="event__img">
                            <img
                              src={
                                item.attachment
                                  ? `${item.attachment.base_url}${item.attachment.attachment_medium_url}`
                                  : "https://holydelights.com/blog/wp-content/uploads/2016/08/img_3409.jpg"
                              }
                              alt="Event Image"
                            />
                          </div>
                          <div className="event__deatil">
                            <h5 className="event-title">
                              {item.event_name ||
                                item.trip_name ||
                                item.title ||
                                item.restaurant_name}
                            </h5>
                            {item.host_name && (
                              <p className="Host">
                                <span>{lang?.Host}: </span>
                                {item.host_name}
                              </p>
                            )}
                            {item.restaurant_type && (
                              <p className="Host">
                                <span>{lang?.Restaurant_SearchBar_Type}: </span>
                                {item.restaurant_type}
                              </p>
                            )}
                            {item.description && (
                              <div className="Host Description">
                                <div className="desTitle">
                                  {lang?.Description}:
                                  {item.description.length < 40 && (
                                    <span className="desPara">
                                      {item.description}
                                    </span>
                                  )}
                                </div>
                                {item.description.length >= 40 && (
                                  <span>
                                    {item.description.slice(0, 70)}
                                    <Link to="#">...{lang?.Read_More}</Link>
                                  </span>
                                )}
                              </div>
                            )}
                            {item.location && (
                              <p className="Host">
                                <span>{lang?.Location}: </span>
                                {item.location}
                              </p>
                            )}
                            {item.travel_agency && (
                              <p className="Host">
                                <span>{lang?.Travel_Agency}: </span>
                                {item.travel_agency}
                              </p>
                            )}
                            {item.region && (
                              <p className="Host">
                                <span>{lang?.RegionTo}: </span>
                                {item?.region?.map(({ name }, i) => item?.region?.length > i + 1 ? `${name},  ` : name)}
                              </p>
                            )}

                            {item.duration && (
                              <p className="Host">
                                <span>{lang?.Duration}: </span>
                                {item.duration}
                              </p>
                            )}
                            {item.date && (
                              <p className="Host">
                                <span>{lang?.Date}: </span>
                                {moment(item.date).format("MMM D, YYYY")}
                              </p>
                            )}
                            {item.time && params.name !== "blogs" && (
                              <p className="Host">
                                <span>{lang?.Time}: </span>
                                {moment(item.time, "HH:mm").format("hh:mm A")}
                              </p>
                            )}
                            {item.price && (
                              <p className="Host">
                                <span>{lang?.Trip_Price}: </span>
                                {item.currency?.toString() === "USD"
                                  ? `$${item.price}`
                                  : `€${item.price}`}
                              </p>
                            )}

                            {/* {item.intensity && ( */}
                            {params.name === "trips" && (
                              <p className="Host intensity flex">
                                <span>{lang?.intensity}: </span>
                                {[...Array(5)].map((v, i) => {
                                  return (
                                    <div className="ml-2 intensityIcon">
                                      <span>
                                        <i
                                          className={
                                            item?.intensity?.intensity_id > i
                                              ? "fa fa-circle"
                                              : "fa fa-circle gray"
                                          }
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </div>
                                  );
                                })}
                              </p>
                            )}
                            {/* )} */}
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
        </Fragment>
      )}
      {params.name !== "restaurants" &&
        isTrue &&
        discoverAlyseiData?.data?.total > 10 && (
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(
              discoverAlyseiData?.data?.total /
              discoverAlyseiData?.data?.per_page
            )}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        )}
    </div>
  );
}
